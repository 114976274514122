import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { addEditBatch } from "../store/actions/adders";

class RatingModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            currentBatch: props.currentBatch,
            currentTask: props.currentTask
        }
    }

    submitRating = async (rate) => {
        console.log(rate);
        let { currentBatch, currentTask } = this.state;
        this.setState({ openRatingModal: false }, async () => {

            currentBatch.tasks[currentTask].rating = rate;
            await addEditBatch({ id: currentBatch.id, batch: currentBatch });
            this.props.closeModal()
        })
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.modal} tabIndex={-1}>
                <Grid containerspacing={16}>
                    <Typography variant={'h4'}>Rate this batch?</Typography>
                    <Grid container direction={'row'} justify={'space-between'} style={{ marginTop: '7%' }}>
                        {new Array(5).fill(1).map((each, i) => (
                            <Grid container className={classes.rate} alignItems={'center'} justify={'center'} onClick={() => this.submitRating(i + 1)}>
                                <Typography variant={'h5'} className={classes.rateNumber}>{i + 1}</Typography>
                            </Grid>
                        ))}
                    </Grid>
                    <Grid onClick={() => this.props.closeModal()} container justify={'flex-end'} style={{ cursor: 'pointer', marginTop: 15 }}>
                        <Typography variant={'subtitle2'}>SKIP</Typography>
                    </Grid>
                </Grid>
            </div>
        )
    }
}
export default RatingModal;


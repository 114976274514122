import moment from 'moment';
import { deepCopyObject, sortFunction } from '../utils';
import APP_SETTINGS from '../appSettings';

const {
  HARVEST_TYPES
} = APP_SETTINGS;

function sortBatchesByDate(batchA, batchB) {

  const dateA = batchA.growthCycle.harvestType === HARVEST_TYPES.ONCE ?
    batchA.growthCycle.harvest.date :
    batchA.growthCycle.harvest.date[batchA.growthCycle.harvest.date.length - 1];

  const dateB = batchB.growthCycle.harvestType === HARVEST_TYPES.ONCE ?
    batchB.growthCycle.harvest.date :
    batchB.growthCycle.harvest.date[batchB.growthCycle.harvest.date.length - 1];

  if (moment(dateA).isBefore(dateB)) {
    return -1;
  }
  if (moment(dateA).isAfter(dateB)) {
    return 1;
  }
  return 0;
}

export function createPlotsFromBatches({ batches, varieties }) {
  let plots = {};
  let varietyLegend = {};

  function addKeyIfNeeded(key) {
    if (!plots[key]) {
      plots[key] = [];
    }
  }

  let committedKeys = [];
  let unCommittedKeys = [];

  for (let batchID in batches) {
    if (batches[batchID].isCommitted) {
      committedKeys.push(batchID);
    } else {
      unCommittedKeys.push(batchID);
    }

    // batches[batchID] = batchID;
    const varID = batches[batchID].variety;

    batches[batchID] = {
      ...batches[batchID],
      id: batchID
    }

    if (!varietyLegend[varID]) {
      let initiationDate = moment(batches[batchID].seedDate)
      let harvestDate = moment(batches[batchID].harvestDate)
      let fsDate = moment(batches[batchID].fsDate);
      let currentDay = moment(new Date())
      let HDdays = harvestDate.diff(initiationDate, 'days') + 1;
      let FSdays = fsDate.diff(initiationDate, 'days') + 1;
      let currentDayFromInitiationDate = currentDay.diff(initiationDate, 'days') + 1;


      if (isNaN(HDdays) && isNaN(FSdays)) {
      }
      else {
        let totalDays = HDdays + FSdays

        if (currentDayFromInitiationDate > totalDays) {
          // varietyLegend[varID] = {
          //   name: varieties[varID].name,
          //   color: varieties[varID].color
          // }
        }

      }
      if (varieties[varID]) {
        varietyLegend[varID] = {
          name: varieties[varID].name,
          color: varieties[varID].color
        }
      }
    }
  }

  committedKeys = committedKeys.sort((a, b) => sortBatchesByDate(batches[a], batches[b]));
  unCommittedKeys = unCommittedKeys.sort((a, b) => sortBatchesByDate(batches[a], batches[b]));

  const batchKeys = committedKeys.concat(unCommittedKeys);

  for (let i = 0; i < batchKeys.length; i++) {
    const batch = batches[batchKeys[i]];

    const { growthCycle } = batch;
    const variety = varieties[batch.variety];

    if (!growthCycle) {
      return
    }
    const isMultipleHarvest = growthCycle.harvestType === HARVEST_TYPES.REPEAT;
    if (variety) {
      var cellObj = {
        color: variety.color,
        variety: variety.name,
        varietyCode: variety.varietyCode,
        harvestDate: isMultipleHarvest ? growthCycle.harvest.date[growthCycle.harvest.date.length - 1] : growthCycle.harvest.date,
        batch: batchKeys[i],
        isCommitted: batch.isCommitted,
        fsDate: batch.fsDate,
        hdDate: batch.hdDate,
        seedDate: batch.seedDate,
        growthCycle: batch.growthCycle,
        finishingLocation: batch.finishingLocation,
        propLocation: batch.propLocation,
        propLocation1: batch.propLocation1,
        cycleDetail: batch.cycleDetail || {}
      };
    }
    // push Propogation days
    if (growthCycle.prop.days > 0) {
      for (let j = 0; j < growthCycle.prop.days; j++) {
        let date = moment(growthCycle.prop.date).add(j, 'days').format();
        addKeyIfNeeded(date);
        plots[date] = (plots[date] || []).concat({
          stage: 'prop',
          batchID: batch.id || '',
          data: cellObj,
          graphic: {
            ...cellObj.growthCycle.prop,
            endRafts: cellObj.cycleDetail?.hdEndRafts || cellObj.growthCycle.hd.endRafts,
            color: cellObj.color,
            isCommitted: cellObj.isCommitted,
            variety: cellObj.variety,
            harvestDate: cellObj.harvestDate,
            seedDate: cellObj.seedDate,
            varietyCode: cellObj.varietyCode,
            propLocation1: cellObj.propLocation1,
            finishingLocation: cellObj.finishingLocation,
            propLocation: cellObj.propLocation
          }
        });
      }
    }

    // push HD days
    if (growthCycle.hd.days > 0) {
      for (let j = 0; j < growthCycle.hd.days; j++) {
        let date = moment(growthCycle.hd.date).add(j, 'days').format();
        addKeyIfNeeded(date);
        plots[date] = (plots[date] || []).concat({
          stage: 'hd',
          batchID: batch.id || '',
          data: cellObj,
          graphic: {
            ...cellObj.growthCycle.hd,
            color: cellObj.color,
            isCommitted: cellObj.isCommitted,
            seedDate: cellObj.seedDate,
            variety: cellObj.variety,
            harvestDate: cellObj.harvestDate,
            varietyCode: cellObj.varietyCode,
            finishingLocation: cellObj.finishingLocation,
            propLocation1: cellObj.propLocation1,
          }
        });
      }
    }

    // push FS days
    for (let k = 0; k < growthCycle.fs.days; k++) {
      let date = moment(growthCycle.fs.date).add(k, 'days').format();
      addKeyIfNeeded(date);

      plots[date] = (plots[date] || []).concat({
        stage: 'fs',
        batchID: batch.id || '',
        data: cellObj,
        graphic: {
          ...cellObj.growthCycle.fs,
          color: cellObj.color,
          isCommitted: cellObj.isCommitted,
          variety: cellObj.variety,
          seedDate: cellObj.seedDate,
          harvestDate: cellObj.harvestDate,
          varietyCode: cellObj.varietyCode,
          finishingLocation: cellObj.finishingLocation,
          propLocation1: cellObj.propLocation1,
        }
      });
    }

    // push multiHarvest
    // if (growthCycle.harvestType === HARVEST_TYPES.REPEAT) {
    //   let date = moment(growthCycle.harvest.date[0]).format();
    //   while (!moment(date).isSame(growthCycle.harvest.date[growthCycle.harvest.date.length - 1], 'days')) {
    //     addKeyIfNeeded(date);
    //     let cellObjArr = new Array(growthCycle.fs.endRafts).fill(cellObj);
    //     if (batch.isCommitted) {
    //       plots[date] = cellObjArr.concat(plots[date]);
    //     } else {
    //       plots[date] = plots[date].concat(cellObjArr);
    //     }
    //     date = moment(date).add(1, 'days').format();
    //   }
    // }

  }
  return { plots, varietyLegend };
}

export function checkCapacity(plots) {
  let batchID = null;
  let overflowDate = '';
  const totalPlotSpaces = APP_SETTINGS.PLOTS.COLUMNS * APP_SETTINGS.PLOTS.ROWS * APP_SETTINGS.PLOTS.NUM_PLOTS;
  for (let key in plots) {
    if (plots[key].length > totalPlotSpaces) {
      batchID = plots[key][totalPlotSpaces].batch;
      overflowDate = key;
      break;
    }
  }
  return { overflowBatch: batchID, overflowDate };
}

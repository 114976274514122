import firebase from 'firebase/app';
import 'firebase/firestore';
import moment from 'moment';
import { calculateBatchMetrics } from '../../utils/appFunctions/batches';
import { createPlotsFromBatches } from '../../utils/appFunctions/plots';
import {
  getMinDateInterval,
  recalcDatesInGrowthCycle
} from '../../utils/appFunctions/growthCycles';
import { deepCopyObject } from '../../utils/utils';
import APP_SETTINGS from '../../utils/appSettings';

const {HARVEST_TYPES, TASK_NAMES, TASK_TYPES, STAGES, UNITS} = APP_SETTINGS;


export async function importData(data, clearData = false) {
  const db = firebase.firestore();
  const collectionList = ['crops', 'varieties', 'growthCycles', 'batches', 'perpetualBatches', 'rafts', 'misc'];
  const miscDocs = ['cropTypes', 'lowInventory', 'reasons'];

  if (clearData) {
    for (let i = 0; i < collectionList.length; i++) {
      const collection = collectionList[i];
      await db.collection(collection).get()
        .then(snapshot => {
          snapshot.forEach(async doc => {
            await db.collection(collection).doc(doc.id).delete();
          });
        });
    }
  }

  let modifiedBatches = {};
  for (let batchID in data.batches) {
    let batch = data.batches[batchID];

    const returnedBatch = processBatch({batch, batchID, rafts: data.rafts});
    if (returnedBatch.harvest.length > 0 && !returnedBatch.avgWeightPerHead) {
      throw new Error(`batch ${batchID} did not calculate correctly`);
    }
    modifiedBatches[batchID] = returnedBatch;
  }

  data.batches = modifiedBatches;
  // console.log({batches: data.batches});

  // let batch = data.batches["0Cw9Zoa6iBmHQcGcDzLb"];
  // console.log({batch});
  // console.log(processBatch({batch, batchID: "0Cw9Zoa6iBmHQcGcDzLb", rafts: data.rafts}));


  for (let i = 0; i < collectionList.length; i++) {
    const collection = collectionList[i];
    if (data[collection]) {
      if (collection === 'misc') {
        // redux store has more keys than we need in the database for 'misc'
        for (let j = 0; j < miscDocs.length; j++) {
          const id = miscDocs[j];
          if (data.misc[id]) {
            await db.collection('misc').doc(id).set({list: data.misc[id]});
          }
        }
      } else {
        for (let id in data[collection]) {
          await db.collection(collection).doc(id).set(data[collection][id]);
        }
      }
    }
  }

  return true;
}

function processBatch({batch, batchID, rafts}) {
  const {
    crop,
    variety,
    GRFlot,
    seedLot = '',
    seedDate = null,
    initiationDate = null,
    isFinalized = false,
    propDate = null,
    hdDate = null,
    fsDate = null,
  } = batch;

  let {
    growthCycle,
    QtyToSeed,
    current,
    tasks,
    harvestDate,
    harvest = [],
    disposal = [],
  } = deepCopyObject(batch);

  const user = {id: 'ildqKl77mQOX89FUuoksD29GcBE2'};

  const hasHDStage = growthCycle.hd.days && growthCycle.hd.days > 0;
  const isMultipleHarvest = growthCycle.harvestType === HARVEST_TYPES.REPEAT;

  harvestDate = harvestDate ? harvestDate : ( isMultipleHarvest ? [] : null );

  /* GROWTH CYCLE */
  let growthCycleDidChange = false;

  // handle germ stage
  if (propDate && moment(propDate).diff(initiationDate, 'days') !== growthCycle.germ.days) {
    console.log('germ days different');
    growthCycle.germ.days = moment(propDate).diff(initiationDate, 'days');
    growthCycleDidChange = true;
  }

  // handle HD stage (allow for no HD stage)
  if (hasHDStage) {
    if (hdDate && moment(hdDate).diff(propDate, 'days') !== growthCycle.prop.days) {
      console.log('prop days different');
      growthCycle.prop.days = moment(hdDate).diff(propDate, 'days');
      growthCycleDidChange = true;
    }
    if (fsDate && moment(fsDate).diff(hdDate, 'days') !== growthCycle.hd.days) {
      console.log('hd days different');
      growthCycle.hd.days = moment(fsDate).diff(hdDate, 'days');
      growthCycleDidChange = true;
    }
  } else {
    if (fsDate && moment(fsDate).diff(propDate, 'days') !== growthCycle.prop.days) {
      console.log('prop days different');
      growthCycle.prop.days = moment(fsDate).diff(propDate, 'days');
      growthCycleDidChange = true;
    }
  }

  // handle harvest stage; allow for single or multiple harvest date
  if (isMultipleHarvest) {

    if (harvestDate[0] && moment(harvestDate[0]).diff(fsDate, 'days') !== growthCycle.fs.days) {
      console.log('fs days different');
      growthCycle.fs.days = moment(harvestDate[0]).diff(fsDate, 'days');
      growthCycleDidChange = true;
    }

    // push any entered dates to growthCycle
    harvestDate.forEach((date, ind) => {
      growthCycle.harvest.date[ind] = date;
    });

    const minInterval = getMinDateInterval(harvestDate);
    if (minInterval && minInterval !== growthCycle.harvestInterval) {
      growthCycle.harvestInterval = minInterval;
      growthCycleDidChange = true;
    }
  } else {

    if (harvestDate && moment(harvestDate).diff(fsDate, 'days') !== growthCycle.fs.days) {
      console.log('fs days different');
      growthCycle.fs.days = moment(harvestDate).diff(fsDate, 'days');
      growthCycleDidChange = true;
    }
  }

  growthCycle = recalcDatesInGrowthCycle(growthCycle, initiationDate, growthCycle.seed.date);

  if (growthCycleDidChange) {
    throw new Error(`growthCycle changed in batch ${batchID}`)
  }

  /* TASKS */

  // handle seed/start vs seed&start
  if (growthCycle.seed.date === growthCycle.germ.date) {
    // remove seed and start if found; add seedAndStart if needed
    delete tasks[TASK_NAMES.SEED];
    delete tasks[TASK_NAMES.START];
    if (!tasks[TASK_NAMES.SEED_AND_START]) {
      tasks[TASK_NAMES.SEED_AND_START] = {
        crop, variety, GRFlot,
        date: growthCycle.germ.date,
        type: TASK_TYPES.SEED_AND_START,
        batchStage: STAGES.GERM,
        quant: growthCycle.germ.cells,
        unit: UNITS.CELLS,
        priorTask: null,
        nextTask: TASK_NAMES.TRANSFER_PROP
      };
    } else {
      tasks[TASK_NAMES.SEED_AND_START].priorTask = null;
      tasks[TASK_NAMES.SEED_AND_START].nextTask = TASK_NAMES.TRANSFER_PROP;
      tasks[TASK_NAMES.SEED_AND_START].batchStage = STAGES.GERM;
    }
    tasks[TASK_NAMES.TRANSFER_PROP].priorTask = TASK_NAMES.SEED_AND_START;
  } else {
    // remove seedAndStart if found; add seed and start if needed
    delete tasks[TASK_NAMES.SEED_AND_START];
    if (!tasks[TASK_NAMES.SEED]) {
      tasks[TASK_NAMES.SEED] = {
        crop, variety, GRFlot,
        date: growthCycle.seed.date,
        type: TASK_TYPES.SEED,
        batchStage: STAGES.SEED,
        quant: growthCycle.germ.cells,
        unit: UNITS.CELLS,
        priorTask: null,
        nextTask: TASK_NAMES.START
      };
    } else {
      tasks[TASK_NAMES.SEED].priorTask = null;
      tasks[TASK_NAMES.SEED].nextTask = TASK_NAMES.START;
      tasks[TASK_NAMES.SEED].batchStage = STAGES.SEED;
    }
    if (!tasks[TASK_NAMES.START]) {
      tasks[TASK_NAMES.START] = {
        crop, variety, GRFlot,
        date: growthCycle.germ.date,
        type: TASK_TYPES.START,
        batchStage: STAGES.GERM,
        quant: growthCycle.germ.cells,
        unit: UNITS.CELLS,
        priorTask: TASK_NAMES.SEED,
        nextTask: TASK_NAMES.TRANSFER_PROP
      };
    } else {
      tasks[TASK_NAMES.START].priorTask = TASK_NAMES.SEED;
      tasks[TASK_NAMES.START].nextTask = TASK_NAMES.TRANSFER_PROP;
      tasks[TASK_NAMES.START].batchStage = STAGES.GERM;
    }
    tasks[TASK_NAMES.TRANSFER_PROP].priorTask = TASK_NAMES.START;
  }

  // prop stage
  tasks[TASK_NAMES.TRANSFER_PROP].batchStage = STAGES.PROP;

  // handle remove or add of HD stage
  if (hasHDStage) {
    if (!tasks[TASK_NAMES.TRANSFER_HD]) {
      tasks[TASK_NAMES.TRANSFER_HD] = {
        crop, variety, GRFlot,
        date: growthCycle.hd.date,
        type: TASK_TYPES.TRANSFER,
        batchStage: STAGES.HD,
        transferTo: STAGES.HD,
        quant: batch.cycleDetail?.hdEndRafts || growthCycle.hd.endRafts,
        unit: UNITS.RAFTS,
        priorTask: TASK_NAMES.TRANSFER_PROP,
        nextTask: TASK_NAMES.TRANSFER_FS
      };
    } else {
      tasks[TASK_NAMES.TRANSFER_HD].batchStage = STAGES.HD;
    }
    tasks[TASK_NAMES.TRANSFER_PROP].nextTask = TASK_NAMES.TRANSFER_HD;
    tasks[TASK_NAMES.TRANSFER_FS].priorTask = TASK_NAMES.TRANSFER_HD;
  } else {
    delete tasks[TASK_NAMES.TRANSFER_HD];
    tasks[TASK_NAMES.TRANSFER_PROP].nextTask = TASK_NAMES.TRANSFER_FS;
    tasks[TASK_NAMES.TRANSFER_FS].priorTask = TASK_NAMES.TRANSFER_PROP;
  }

  // FS stage
  tasks[TASK_NAMES.TRANSFER_FS].batchStage = STAGES.FS;

  // handle multiple Harvest vs. single Harvest
  if (isMultipleHarvest) {
    delete tasks[TASK_NAMES.HARVEST];
    growthCycle.harvest.date.forEach((date, ind) => {
      const isLastHarvestTask = ind === growthCycle.harvest.date.length - 1;
      if (!tasks[`${TASK_NAMES.HARVEST}-${ind}`]) {
        tasks[`${TASK_NAMES.HARVEST}-${ind}`] = {
          crop, variety, GRFlot,
          date,
          type: TASK_TYPES.HARVEST,
          batchStage: isLastHarvestTask ? STAGES.COMPLETED : STAGES.HARVEST,
          quant: batch.cycleDetail?.fsEndRafts || growthCycle.fs.endRafts,
          unit: UNITS.RAFTS,
          priorTask: ind === 0 ? TASK_NAMES.TRANSFER_FS : `${TASK_NAMES.HARVEST}-${ind - 1}`,
          nextTask: isLastHarvestTask ? null : `${TASK_NAMES.HARVEST}-${ind + 1}`,
          isLastTask: isLastHarvestTask ? true : false
        };
      } else {
        tasks[`${TASK_NAMES.HARVEST}-${ind}`].batchStage = isLastHarvestTask ? STAGES.COMPLETED : STAGES.HARVEST;
        tasks[`${TASK_NAMES.HARVEST}-${ind}`].priorTask = ind === 0 ? TASK_NAMES.TRANSFER_FS : `${TASK_NAMES.HARVEST}-${ind - 1}`;
        tasks[`${TASK_NAMES.HARVEST}-${ind}`].nextTask = isLastHarvestTask ? null : `${TASK_NAMES.HARVEST}-${ind + 1}`;
        tasks[`${TASK_NAMES.HARVEST}-${ind}`].isLastTask = isLastHarvestTask ? true : false;
      }
    });
    tasks[TASK_NAMES.TRANSFER_FS].nextTask = `${TASK_NAMES.HARVEST}-0`;
  } else {
    for (let taskName in tasks) {
      if (taskName !== TASK_NAMES.HARVEST && taskName.includes(TASK_NAMES.HARVEST)) {
        delete tasks[taskName];
      }
    }
    if (!tasks[TASK_NAMES.HARVEST]) {
      tasks[TASK_NAMES.HARVEST] = {
        crop, variety, GRFlot,
        date: growthCycle.harvest.date,
        type: TASK_TYPES.HARVEST,
        batchStage: STAGES.COMPLETED,
        quant: batch.cycleDetail?.fsEndRafts || growthCycle.fs.endRafts,
        unit: UNITS.RAFTS,
        priorTask: TASK_NAMES.TRANSFER_FS,
        nextTask: null,
        isLastTask: true
      };
    } else {
      tasks[TASK_NAMES.HARVEST].priorTask = TASK_NAMES.TRANSFER_FS;
      tasks[TASK_NAMES.HARVEST].nextTask = null;
      tasks[TASK_NAMES.HARVEST].batchStage = STAGES.COMPLETED;
      tasks[TASK_NAMES.HARVEST].isLastTask = true;
    }
    tasks[TASK_NAMES.TRANSFER_FS].nextTask = TASK_NAMES.HARVEST;
  }

  let stageMap = {
    [TASK_NAMES.SEED]: {plannedDate: growthCycle.seed.date, actualDate: seedDate, quant: growthCycle.germ.cells},
    [TASK_NAMES.START]: {plannedDate: growthCycle.germ.date, actualDate: initiationDate, quant: growthCycle.germ.cells},
    [TASK_NAMES.SEED_AND_START]: {plannedDate: growthCycle.germ.date, actualDate: initiationDate, quant: growthCycle.germ.cells},
    [TASK_NAMES.TRANSFER_PROP]: {plannedDate: growthCycle.prop.date, actualDate: propDate, quant: growthCycle.germ.cells},
    [TASK_NAMES.TRANSFER_HD]: {plannedDate: growthCycle.hd.date, actualDate: hdDate, quant: batch.cycleDetail?.hdEndRafts || growthCycle.hd.endRafts},
    [TASK_NAMES.TRANSFER_FS]: {plannedDate: growthCycle.fs.date, actualDate: fsDate, quant: batch.cycleDetail?.fsEndRafts || growthCycle.fs.endRafts},
    [TASK_NAMES.HARVEST]: {plannedDate: growthCycle.harvest.date, actualDate: harvestDate}
  };
  if (isMultipleHarvest) {
    growthCycle.harvest.date.forEach((date, ind) => {
      stageMap[`${[TASK_NAMES.HARVEST]}-${ind}`] = {plannedDate: date, actualDate: harvestDate[ind]};
    });
  }

  let latestTaskComplete = '';
  let shouldTrackTaskComplete = true;

  // assign dates and quantities
  Object.keys(stageMap).forEach(stage => {
    let task = tasks[stage];
    // console.log(stage, {task});
    if (task) {

      // set first task in the order to isNext
      if (stage === TASK_NAMES.SEED || stage === TASK_NAMES.SEED_AND_START) {
        task.isNext = true;
      }

      // push planned date; does not force change 'completedBy' or 'completedDate'
      task.date = stageMap[stage].plannedDate;
    
      // push actual(completed) date if it's been entered
      if (stageMap[stage].actualDate) {
        task.isComplete = true;
        task.isNext = false;
        latestTaskComplete = stage;
        // if the actual date from state matches the old actual date, don't change the completedBy
        if (!task.completedDate || !moment(task.completedDate).isSame(stageMap[stage].actualDate, 'days')) {
          task.completedDate = stageMap[stage].actualDate;
          task.completedBy = user.id;
        }
      } else {
        // set task as not complete if no actual date
        task.isComplete = false;
        task.completedDate = null;
        task.completedBy = '';
        if (shouldTrackTaskComplete) {
          task.isNext = true;
          shouldTrackTaskComplete = false;
        }
      }
      // if planned quant has changed, update quant and completedBy
      if (stageMap[stage].quant) {
        if (!(task.quant === stageMap[stage].quant)) {
          task.quant = stageMap[stage].quant;
          task.completedBy = user.id;
        }
      }
    }
  });

  // remove any harvest tasks past the number of harvests
  if (isMultipleHarvest) {
    for (let taskName in tasks) {
      let harvestInd = taskName.split('-')[1];
      if (harvestInd && parseInt(harvestInd) >= growthCycle.harvests) {
        delete tasks[taskName];
      }
    }
  }


  /* CURRENT */
  
  let currentStageMap = {
    [TASK_NAMES.SEED]: STAGES.SEED,
    [TASK_NAMES.START]: STAGES.GERM,
    [TASK_NAMES.SEED_AND_START]: STAGES.GERM,
    [TASK_NAMES.TRANSFER_PROP]: STAGES.PROP,
    [TASK_NAMES.TRANSFER_HD]: STAGES.HD,
    [TASK_NAMES.TRANSFER_FS]: STAGES.FS,
    [TASK_NAMES.HARVEST]: STAGES.COMPLETED
  };
  if (isMultipleHarvest) {
    growthCycle.harvest.date.forEach((date, ind) => {
      currentStageMap[`${TASK_NAMES.HARVEST}-${ind}`] = ind === growthCycle.harvest.date.length - 1 ? STAGES.COMPLETED : STAGES.HARVEST;
    });
  }
  if (latestTaskComplete) {
    current.stage = currentStageMap[latestTaskComplete];
    const quant = tasks[latestTaskComplete].quant;
    current.cells = tasks[latestTaskComplete].unit === UNITS.CELLS ? quant : null;
    current.rafts = tasks[latestTaskComplete].unit === UNITS.RAFTS ? quant : null;
  } else {
    current = {stage: STAGES.PLANNED, cells: growthCycle.germ.cells};
  }

  // update harvests and disposals to new style
  for (let i = 0; i < harvest.length; i++) {
    const item = harvest[i];
    harvest[i].count = item.count || item.rafts;
    harvest[i].units = item.units || UNITS.RAFTS;
  }

  for (let i = 0; i < disposal.length; i++) {
    const item = disposal[i];
    disposal[i].count = item.count || item.rafts;
    disposal[i].units = item.units || UNITS.RAFTS;
  }

  let returnedBatch = {
    ...batch,
    growthCycle,
    current,
    tasks,
    seedLot,
    seedDate,
    initiationDate,
    harvest,
    disposal,
    isFinalized,
    propDate,
    hdDate,
    fsDate,
    harvestDate,
    ...calculateBatchMetrics({
      harvest,
      disposal,
      growthCycle,
      raftSites: growthCycle.fs.raftType ? rafts[growthCycle.fs.raftType].sites :'',
      isRockWool : growthCycle.growMedia === 'Rockwool',
      QtyToSeed,
      isComplete: current.stage === STAGES.COMPLETED,
      cycleDetail: batch.cycleDetail || {}
    })
  };
  
  // delete 'cells' and 'propCells' property
  delete returnedBatch.cells;
  delete returnedBatch.propCells;


  return returnedBatch;
}
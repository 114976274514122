import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';
import red from '@material-ui/core/colors/red';
import blue from '@material-ui/core/colors/blue';
import APP_SETTINGS from '../utils/appSettings';
import Divider from '@material-ui/core/Divider';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import RaftModal from './RaftModal';
import FarmModal from './FarmModal';
import GrowbedsModal from './GrowbedsModal';

const { COLLECTIONS } = APP_SETTINGS;


const styles = theme => ({
    modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '60%',
        minWidth: 580,
        maxWidth: 800,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
        '&:focus': {
            outline: 'none'
        },
        overflow: 'auto',
        maxHeight: 700,
        minHeight: 700,
    },
    container: {
        width: '100%',
    },
    input: {
        width: '100%'
    },
    farmName: {
        color: '#000',
        marginTop: 40,
        fontWeight: 'bold'
    },
    buttonContainer: {
        textAlign: 'right',
        marginTop: '1.5rem'
    },
    button: {
        margin: '0.5rem 0rem'
    },
    paper: {
        width: '100%',
        marginBottom: '2rem'
    },
    sectionTitle: {
        marginTop: '2rem'
    },
    link: {
        color: blue[500],
        fontSize: 15,
        marginTop: 20,
        cursor: 'pointer'
    },
    actionIcons: {
        marginRight: '1.5rem',
        cursor: 'pointer',
        '&:last-child': {
            marginRight: 0
        },
        fontSize: '1.5rem'
    },
    blue: {
        color: blue[500]
    },
    red: {
        color: red[500]
    },
    grey: {
        color: theme.palette.grey[300]
    },
    editingCell: {
        color: theme.palette.grey[300]
    },
    fab: {
    },
    tabContainer: {
        margin: '3rem 0 1rem'
    },
    tabs: {
        width: '100%',
        minWidth: 400,
        '& button': {
            width: '35%',
            maxWidth: 'none'
        }
    },
    tab: {
        fontSize: '1.1rem'
    },
    divider: {
        width: '100%',
        marginTop: '-1px'
    }
});

class SetupModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            location: '',
            notes: '',
            deleteRaft: null,
            editIsActive: false,
            editRaft: null,
            dialogOpen: false,
            ponds: [],
            addRow: true,
            currentOpen: -1,
            id: '',
            nameError: false,
            locationError: false,
            openPrompt: false,
            hasBeenEdited: false,
            tabValue: 0
        };
        this.handleChange = this.handleChange.bind(this);
        this.deleteRaft = this.deleteRaft.bind(this);
        this.openDialog = this.openDialog.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
    }

    componentDidMount = () => {

    }

    handleTabChange = (e, tabValue) => {
        this.setState({ tabValue });
    };

    handleChange = name => e => {

        if (name === 'name') {
            this.setState({ nameError: false })
        }
        if (name === 'location') {
            this.setState({ locationError: false })
        }
        this.setState({
            [name]: e.target.value,
            hasBeenEdited: true
        }, () => this.props.hasBeenEdited());
    };

    handleChange1 = (name, i) => e => {

        this.state.ponds[i][name] = e.target.value
        this.setState({
            ponds: this.state.ponds,
            hasBeenEdited: true
        }, () => this.props.hasBeenEdited())

    };

    addSave = (e) => {

        e.preventDefault();
        let { name, location, notes, ponds } = this.state

        if (name.trim() === '') {
            this.setState({ nameError: true })
        }
        if (location.trim() === '') {
            this.setState({ locationError: true })
        }

        if ((name.trim() !== '') && (location.trim() !== '')) {
            let farmToAdd = {
                name,
                location,
                notes,
                ponds
            }

            this.props.z(farmToAdd, this.state.id);
            this.setState({ id: '', ponds: [], name: '', location: '', notes: '' })
        }
    }

    addFarm = () => {
        let newFarm = { name: '', width: '', length: '' }
        this.state.ponds.push(newFarm);
        this.setState({ ponds: this.state.ponds, addRow: false, currentOpen: this.state.ponds.length - 1 })
    }
    addFarm1 = () => {
        this.setState({ addRow: true, currentOpen: -1 })
    }

    editFarm = (farmId) => {
        let mainFarm = {}
        this.props.Farms.map(farm => {
            if (farmId === farm.id) {
                mainFarm = farm
            }
        })
        const { name, location, notes, ponds, id } = mainFarm;
        this.setState({ name, location, notes, id, ponds, editIsActive: true });
    }

    deleteRaft() {
        this.props.deleteItem({ collection: COLLECTIONS.RAFTS, id: this.state.deleteRaft });
    }

    openDialog(id) {
        this.setState({ dialogOpen: true, deleteRaft: id });
    }

    closeDialog(userAnswer) {
        if (userAnswer && userAnswer === 'yes') {
            this.deleteRaft();
        }
        this.setState({ dialogOpen: false });
    }

    cloneFarm = (farm) => {
        let { location, name, notes, ponds } = farm;
        this.setState({ location, name, notes, ponds }, () => this.props.hasBeenEdited())
    }


    render() {
        const { classes, Farms } = this.props;
        return (
            <div className={classes.modal} tabIndex={-1}>

                <Grid container className={classes.tabContainer} justify="flex-start">
                    <Tabs
                        value={this.state.tabValue}
                        onChange={this.handleTabChange}
                        indicatorColor="secondary"
                        textColor="secondary"
                        centered
                        className={classes.tabs}
                    >
                        <Tab label="My Farms" className={classes.tab} />
                        <Tab label="Manage Rafts" className={classes.tab} />
                        <Tab label="Manage Grow Media" className={classes.tab} />
                    </Tabs>
                    <Divider className={classes.divider} />
                </Grid>

                {this.state.tabValue === 0 &&
                    <FarmModal
                    deleteItem={this.props.deleteFarmData}
                    Farms={this.props.Farms}
                    onSave={this.props.saveFarms}
                    hasBeenEdited={this.props.hasBeenEditedStatus}
                  />
                }
                {this.state.tabValue === 1 &&
                    <RaftModal
                        addEditItem={this.props.addEditItem}
                        deleteItem={this.props.deleteItem}
                        rafts={this.props.rafts}
                        onDone={this.props.closeModal}
                    />
                }
                {this.state.tabValue === 2 &&
                    <GrowbedsModal
                    deleteGrowBed={this.props.deleteGrowBed}
                    saveGrowBed={this.props.saveGrowBed}
                    onSave={this.props.saveFarms}
                    growBeds={this.props.growBeds}
                  />
                }



            </div>
        )
    }
};

SetupModal.propTypes = {
    classes: PropTypes.object.isRequired,
    rafts: PropTypes.object.isRequired,
    addEditItem: PropTypes.func.isRequired,
    deleteItem: PropTypes.func.isRequired,
    onDone: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
};

export default withStyles(styles)(SetupModal);

const APP_SETTINGS = {
  FIREBASE_CONFIG: {
    apiKey: "AIzaSyCCpJi4cLVLmztJNKshENQUSoYEzd_J_nI",
    authDomain: "greener-roots.firebaseapp.com",
    databaseURL: "https://greener-roots.firebaseio.com",
    projectId: "greener-roots",
    storageBucket: "greener-roots.appspot.com",
    messagingSenderId: "67642299709"
  },
  FIREBASE_PLAYGROUND_CONFIG: {
    apiKey: "AIzaSyCSRN15vrhJfL9ZDwGRxOs-yL5C4SD0yMs",
    authDomain: "greener-roots-playground.firebaseapp.com",
    databaseURL: "https://greener-roots-playground.firebaseio.com",
    projectId: "greener-roots-playground",
    storageBucket: "greener-roots-playground.appspot.com",
    messagingSenderId: "280571180011"
  },
  FIREBASE_NEW_CONFIG: {
    apiKey: "AIzaSyD3W17WAQeOQMAkelXqZEd-KoAdEBtF3KM",
    authDomain: "greener-roots-1.firebaseapp.com",
    databaseURL: "https://greener-roots-1.firebaseio.com",
    projectId: "greener-roots-1",
    storageBucket: "greener-roots-1.appspot.com",
    messagingSenderId: "740740257426",
    appId: "1:740740257426:web:34a1637f7c05d2e732b84b",
    measurementId: "G-4TR5X46JPE"
  },
  FIREBASE_PRODUCTION: {
    apiKey: "AIzaSyBxgY2WazT0g-bzoru-eT5zzqMLTWnXqKQ",
    authDomain: "greener-roots-new.firebaseapp.com",
    projectId: "greener-roots-new",
    storageBucket: "greener-roots-new.appspot.com",
    messagingSenderId: "185144759222",
    appId: "1:185144759222:web:491118f4b250322e516717",
    measurementId: "G-EW291EW9PG"
  },
  MENU_ITEMS: [
    { name: 'Home', link: '/', icon: 'fas fa-home' },
    { name: 'Grow', link: '/grow', icon: 'fas fa-seedling' },
    { name: 'Planning', link: '/planning', icon: 'fas fa-calendar' },
    { name: 'Inventory', link: '/inventory', icon: 'fas fa-box-open' },
    { name: 'Analytics', link: '/analytics', icon: 'fas fa-chart-line' },
    { name: 'Ratings', link: '/ratings', icon: 'fas fa-star-half-alt' },
    { name: 'Setup', link: '/setup', icon: 'fas fa-cog' },
    null,
    { name: 'User Info', link: '/users', icon: 'fas fa-user' },
  ],
  PLOTS: {
    COLUMNS: 10,
    ROWS: 27,
    NUM_PLOTS: 2
  },
  TASK_NAMES: {
    SEED: 'seed',
    START: 'start',
    SEED_AND_START: 'seedAndStart',
    TRANSFER_PROP: 'transferProp',
    TRANSFER_HD: 'transferHD',
    TRANSFER_FS: 'transferFS',
    HARVEST: 'harvest'
  },
  TASK_NAMES_1: {
    SEED: 'seed',
    START: 'start',
    SEED_AND_START: 'seedAndStart',
    TRANSFER_PROP: 'transferProp',
    HARVEST: 'harvest'
  },
  TASK_TYPES: {
    SEED: 'seed',
    START: 'start',
    SEED_AND_START: 'seedAndStart',
    TRANSFER: 'transfer',
    HARVEST: 'harvest'
  },
  STAGES: {
    PLANNED: 'planned',
    SEED: 'seed',
    GERM: 'germ',
    PROP: 'prop',
    HD: 'hd',
    FS: 'fs',
    HARVEST: 'harvest',
    COMPLETED: 'completed'
  },
  ASSIGN_STAGES: {
    SEED: 'Seeding',
    HD: 'HD',
    FS: 'FS',
    COMPLETED: 'Completed'
  },
  UNITS: {
    RAFTS: 'rafts',
    CELLS: 'cells',
    TRAYS: 'trays'
  },
  COLLECTIONS: {
    CROPS: 'crops',
    VARIETIES: 'varieties',
    GROWTH_CYCLES: 'growthCycles',
    RAFTS: 'rafts',
    BATCHES: 'batches',
    PERPETUAL_BATCHES: 'perpetualBatches',
    USERS: 'users',
    MISC: 'misc',
    ASSIGN_TASK: 'assignTask',
    FARMS: 'farms',
    BUTTON: 'button',
    GROW_BEDS: 'growBeds',
    CROP_TYPES: 'cropTypes',
    VARIETY_CODES: 'varietyCodes'
  },
  HARVEST_TYPES: {
    ONCE: 'once',
    REPEAT: 'repeat'
  },
  SEED_TYPES: {
    PELLETED: 'pelleted',
    RAW: 'raw'
  },
  INVENTORY_ACTIONS: {
    SEED: 'seed',
    UNSEED: 'unSeed',
    ADD: 'add',
    TRASH: 'trash',
    CANCEL_BATCH: 'cancelBatch',
  },
  USER_ROLES: {
    ADMIN: 'admin',
    USER: 'user'
  },
  USER_POSITIONS: {
    MANAGER: 'manager',
    GROWER: 'grower',
    INTERN: 'intern',
    FRIEND: 'friend'
  },
  SEED_DAYS: {
    M: 'M',
    T: 'T',
    W: 'W',
    Th: 'Th',
    F: 'F',
    Sa: 'Sa',
    Su: 'Su'
  },
  DAYS: [
    'Su', 'M', 'T', 'W', 'Th', 'F', 'Sa'
  ],
  REPEAT_TYPE: [
    // {
    //   name : 'Does not repeat',
    //   value:1
    // },
    {
      name: 'Every week',
      value: 2
    },
    {
      name: 'Every other week',
      value: 3
    },
    {
      name: 'Every three weeks',
      value: 4
    },
    {
      name: 'Every Month',
      value: 5
    },
  ],
  SENSOR_URL: 'https://api.30mhz.com/api/',
  SENSOR_APIS: {
    aggregate: 'stats/aggregate',
    fromto : 'stats/from/'
  },
  token: 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJqdGkiOiI5MmI4YWUzNC1mMWM5LTQ5NDEtODE2Yy00OGFiZjAwMWRjZWIiLCJpYXQiOjE2NDk3Njk4NzYsInN1YiI6ImF1dGgwfDYwODJmMGI2MDhmNDg1MDA3NDQwZjJhMCIsImlzcyI6Imh0dHBzOi8vYXBpLjMwbWh6LmNvbSIsImF1ZCI6Imh0dHBzOi8vYXBpLjMwbWh6LmNvbSIsImVtYWlsIjoiamVmZnJleUBncmVlbmVycm9vdHMuY29tIn0.qLog2zzPvVeYARSNd2mcrSdl1qPlT29t0X1gFiwFxOo'
};

export default APP_SETTINGS;
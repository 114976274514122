import moment from 'moment';
import { deepCopyObject, fillNumberStringToLength, sortFunction } from '../utils';
import { calculateBatchMetrics } from './batches';
import {
  recalcDatesInGrowthCycle,
  getMinDateInterval,
  findGrowthCycleIDInVariety,
  createNewGrowthCycleObj,
  createNewName
} from './growthCycles';
import { checkHasBeenUsed } from './setup';
import APP_SETTINGS from '../appSettings';
import { addEditItem } from '../../store/actions/adders';

const {
  TASK_NAMES,
  TASK_NAMES_1,
  TASK_TYPES,
  STAGES,
  UNITS,
  HARVEST_TYPES,
  INVENTORY_ACTIONS,
  COLLECTIONS,
} = APP_SETTINGS;

export function generateTasks({ crop, germCells=0, cycleDetail, variety, GRFlot, growthCycleObj, assignUser, QtyToSeed }) {
  let tasks = {};

  const hasSeparateSeedDate = !moment(growthCycleObj.seed.date).isSame(growthCycleObj.germ.date, 'days');
  const hasHDStage = growthCycleObj.hd.days && growthCycleObj.hd.days !== 0;
  const isMultipleHarvest = growthCycleObj.harvestType === HARVEST_TYPES.REPEAT;

  const predefinedTasklist = QtyToSeed ? TASK_NAMES_1 : TASK_NAMES;
  for (let key in predefinedTasklist) {
    const taskName = TASK_NAMES[key];
    switch (taskName) {
      case TASK_NAMES.SEED:
        if (!hasSeparateSeedDate) {
          break;
        }
        tasks[TASK_NAMES.SEED] = {
          crop, variety, GRFlot,
          date: growthCycleObj.seed.date,
          type: TASK_TYPES.SEED,
          batchStage: STAGES.SEED,
          quant: germCells, // before it was growthCycleObj.germ.cells
          unit: QtyToSeed ? UNITS.TRAYS : UNITS.CELLS,
          isNext: true,
          priorTask: null,
          nextTask: TASK_NAMES.START,
          rating: 0,
          notes: '',
          user: assignUser || ''
        };
        break;

      case TASK_NAMES.START:
        if (!hasSeparateSeedDate) {
          break;
        }
        tasks[TASK_NAMES.START] = {
          crop, variety, GRFlot,
          date: growthCycleObj.germ.date,
          type: TASK_TYPES.START,
          batchStage: STAGES.GERM,
          quant: germCells, // before it was growthCycleObj.germ.cells
          unit: QtyToSeed ? UNITS.TRAYS : UNITS.CELLS,
          priorTask: TASK_NAMES.SEED,
          nextTask: TASK_NAMES.TRANSFER_PROP,
          rating: 0,
          notes: '',
          user: assignUser || ''
        };
        break;

      case TASK_NAMES.SEED_AND_START:
        if (hasSeparateSeedDate) {
          break;
        }
        tasks[TASK_NAMES.SEED_AND_START] = {
          crop, variety, GRFlot,
          date: growthCycleObj.germ.date,
          type: TASK_TYPES.SEED_AND_START,
          batchStage: STAGES.GERM,
          quant: germCells, // before it was growthCycleObj.germ.cells
          unit: QtyToSeed ? UNITS.TRAYS : UNITS.CELLS,
          isNext: true,
          priorTask: null,
          nextTask: TASK_NAMES.TRANSFER_PROP,
          rating: 0,
          notes: '',
          user: assignUser || ''
        };
        break;

      case TASK_NAMES.TRANSFER_PROP:
        tasks[TASK_NAMES.TRANSFER_PROP] = {
          crop, variety, GRFlot,
          date: growthCycleObj.prop.date,
          type: TASK_TYPES.TRANSFER,
          batchStage: STAGES.PROP,
          transferTo: STAGES.PROP,
          quant: germCells, // before it was growthCycleObj.germ.cells
          unit: QtyToSeed ? UNITS.TRAYS : UNITS.CELLS,
          priorTask: hasSeparateSeedDate ? TASK_NAMES.START : TASK_NAMES.SEED_AND_START,
          nextTask: QtyToSeed ? isMultipleHarvest ? `${TASK_NAMES.HARVEST}-0` : TASK_NAMES.HARVEST : hasHDStage ? TASK_NAMES.TRANSFER_HD : TASK_NAMES.TRANSFER_FS,
          rating: 0,
          notes: '',
          user: assignUser || ''
        };
        break;

      case TASK_NAMES.TRANSFER_HD:
        if (!hasHDStage) {
          break;
        }
        tasks[TASK_NAMES.TRANSFER_HD] = {
          crop, variety, GRFlot,
          date: growthCycleObj.hd.date,
          type: TASK_TYPES.TRANSFER,
          batchStage: STAGES.HD,
          transferTo: STAGES.HD,
          quant: cycleDetail?.hdEndRafts || growthCycleObj.hd.endRafts,
          unit: UNITS.RAFTS,
          priorTask: TASK_NAMES.TRANSFER_PROP,
          nextTask: TASK_NAMES.TRANSFER_FS,
          rating: 0,
          notes: '',
          user: assignUser || ''
        };
        break;

      case TASK_NAMES.TRANSFER_FS:
        tasks[TASK_NAMES.TRANSFER_FS] = {
          crop, variety, GRFlot,
          date: growthCycleObj.fs.date,
          type: TASK_TYPES.TRANSFER,
          batchStage: STAGES.FS,
          transferTo: STAGES.FS,
          quant: cycleDetail?.fsEndRafts || growthCycleObj.fs.endRafts,
          unit: UNITS.RAFTS,
          priorTask: hasHDStage ? TASK_NAMES.TRANSFER_HD : TASK_NAMES.TRANSFER_PROP,
          nextTask: isMultipleHarvest ? `${TASK_NAMES.HARVEST}-0` : TASK_NAMES.HARVEST,
          rating: 0,
          notes: '',
          user: assignUser || ''
        };
        break;

      case TASK_NAMES.HARVEST:
        if (isMultipleHarvest) {
          growthCycleObj.harvest.date.forEach((date, ind) => {
            tasks[`${TASK_NAMES.HARVEST}-${ind}`] = {
              crop, variety, GRFlot,
              date,
              type: TASK_TYPES.HARVEST,
              batchStage: ind === growthCycleObj.harvest.date.length - 1 ? STAGES.COMPLETED : STAGES.HARVEST,
              quant: cycleDetail?.fsEndRafts || growthCycleObj.fs.endRafts,
              unit: QtyToSeed ? UNITS.TRAYS : UNITS.RAFTS,
              priorTask: QtyToSeed ? TASK_NAMES.TRANSFER_PROP : ind === 0 ? TASK_NAMES.TRANSFER_FS : `${TASK_NAMES.HARVEST}-${ind - 1}`,
              nextTask: ind === growthCycleObj.harvest.date.length - 1 ? null : `${TASK_NAMES.HARVEST}-${ind + 1}`,
              isLastTask: ind === growthCycleObj.harvest.date.length - 1 ? true : false,
              rating: 0,
              notes: '',
              user: assignUser || ''
            };
          });
        } else {
          const julianDate = fillNumberStringToLength(moment(growthCycleObj.harvest.date).dayOfYear(), 3);
          let lotNo = GRFlot.concat(julianDate)
          tasks[TASK_NAMES.HARVEST] = {
            crop, variety, GRFlot: lotNo,
            date: growthCycleObj.harvest.date,
            type: TASK_TYPES.HARVEST,
            batchStage: STAGES.COMPLETED,
            quant: cycleDetail?.fsEndRafts || growthCycleObj.fs.endRafts,
            unit: QtyToSeed ? UNITS.TRAYS : UNITS.RAFTS,
            priorTask: QtyToSeed ? TASK_NAMES.TRANSFER_PROP :TASK_NAMES.TRANSFER_FS,
            nextTask: null,
            isLastTask: true,
            rating: 0,
            notes: '',
            user: assignUser || ''
          };
        }

        break;

      default:
        break;
    }
  };
  return tasks;
};

export function validateTasksForCompletion({
  taskList = [],
  seedLot,
  usePriorSeedLots = false,
  tasks,
  varieties,
  ListTask
}) {

  let validationResult = {
    isNextError: false,
    seedLotError: false,
    priorDateError: false,
    dateIsDifferent: true, // always show popup clients requirement
    hasLastUsedSeedLot: true,
    lastUsedSeedLot: []
  };

  for (let i = 0; i < taskList.length; i++) {
    const task = tasks[taskList[i]];

    const [batchID, taskName] = taskList[i].split('-');
    const priorTask = tasks[`${batchID}-${task.priorTask}`];
    validationResult.lastUsedSeedLot.push((ListTask[taskList[i]] && ListTask[taskList[i]].seedLot) || varieties[task.variety].lastUsedSeedLot || null);

    // error if task is not marked as isNext
    if (!task.isNext) {
      validationResult.isNextError = true;
    }

    if (ListTask[taskList[i]] && ListTask[taskList[i]].seedLot) {
      seedLot = ListTask[taskList[i]].seedLot
    }

    // error if no seedLot
    if ((taskName === TASK_NAMES.SEED || taskName === TASK_NAMES.SEED_AND_START) && !seedLot && !usePriorSeedLots) {
      validationResult.seedLotError = true;

      if (!varieties[task.variety].lastUsedSeedLot && (ListTask[taskList[i]].seedLot === '')) {
        validationResult.hasLastUsedSeedLot = false;
      }
    }

    // find if today is not planned date
    if (task.date !== moment().format('YYYY-MM-DD')) {
      validationResult.dateIsDifferent = true;
      // error if today is before the completion date of the prior task
      if (priorTask && moment().isBefore(priorTask.completedDate)) {
        validationResult.priorDateError = true;
      }
    }
  }

  return validationResult;
}

export function updateBatchesForTaskCompletion({
  taskList = [],
  taskCompletedDate,
  user,
  seedLotList = [],
  growthCycles = [],
  varieties = [],
  harvest,
  disposal,
  adjustGrowthCycle = false,
  batches,
  rafts,
  undo
}) {

  let updatedBatches = {};


  for (let i = 0; i < taskList.length; i++) {
    // debugger;
    const [batchID, taskName, harvestNum] = taskList[i].split('-');
    let batch = deepCopyObject(batches[batchID]);
    const isMultipleHarvest = batch.growthCycle.harvestType === HARVEST_TYPES.REPEAT;
    let tasks = batch.tasks;
    let growthCycleID = batch.growthCycleID;
    let ArrangedTasks = Object.keys(deepCopyObject(tasks)).sort((a, b) => sortFunction(tasks[a], tasks[b], 'asc', 'date'))

    console.log('taskList1',batch)
    let current = batch.current;
    let isFinalized = batch.current.stage === 'completed' ? true : batch.isFinalized || false;
    let growthCycle = batch.growthCycle;
    let GRFlot = batch.GRFlot;
    const taskID = (!isMultipleHarvest || !harvestNum) ? taskName : taskName.concat('-', harvestNum);
    let task = tasks[taskID];
    let nextTask = task.nextTask ? tasks[task.nextTask] : null;
    let priorTask = task.priorTask ? tasks[task.priorTask] : null;
    taskCompletedDate = taskCompletedDate || task.date;

    // mark current task as complete, mark next one as isNext
    task.isComplete = undo ? false : true;
    task.user = user.id;
    task.completedBy = undo ? '' : user.id;
    task.completedDate = undo ? '' : taskCompletedDate;
    task.isNext = false;

    if (nextTask && (undo === undefined)) {
      nextTask.isNext = true;
    }

    // update 'current' field of the batch
    current.cells = task.unit === UNITS.CELLS ? task.quant : null;
    current.rafts = task.unit === UNITS.RAFTS ? task.quant : null;
    current.stage = task.batchStage;


    // for manage UNDO cases
    if (undo) {
      task.isNext = false


      // if (task.priorTask.includes('seed') || task.priorTask.includes('germ') || task.priorTask.includes('start')) {
      //   batch.fsDate = '';
      //   batch.fsRafts = ''
      // }

      console.log('undoFunc',task)

      if (task.priorTask) {
        current.stage = task.priorTask;
        tasks[task.priorTask].isNext = true;
        tasks[task.priorTask].isComplete = false;
        tasks[task.priorTask].completedDate = '';
        tasks[task.priorTask].completedBy = '';
      }
      else {
        current.stage = 'planned'
      }
    }


    // set batch as finalized if the completed task is the last one
    if (task.isLastTask) {
      isFinalized = true;
    }

    // change lot number if all task completed
    if(isFinalized){
      const julianDate = fillNumberStringToLength(moment(task.completedDate).dayOfYear(), 3);
      GRFlot = GRFlot.concat(julianDate);
      tasks[TASK_NAMES.HARVEST].GRFlot = GRFlot;
    }

    // set other relevant Batch attributes based on task completed
    let harvestDateArr = [];
    if (isMultipleHarvest && harvestNum) {
      harvestDateArr = batch.harvestDate.slice();
      harvestDateArr[harvestNum] = taskCompletedDate;
    }
    let taskMap = {
      [TASK_NAMES.SEED]: { seedDate: taskCompletedDate, seedLot: seedLotList[i] },
      [TASK_NAMES.START]: { initiationDate: taskCompletedDate },
      [TASK_NAMES.SEED_AND_START]: { seedDate: taskCompletedDate, initiationDate: taskCompletedDate, seedLot: seedLotList[i] },
      [TASK_NAMES.TRANSFER_PROP]: { propDate: taskCompletedDate, propCells: task.quant },
      [TASK_NAMES.TRANSFER_HD]: { hdDate: taskCompletedDate, hdRafts: task.quant },
      [TASK_NAMES.TRANSFER_FS]: { fsDate: taskCompletedDate, fsRafts: task.quant },
      [TASK_NAMES.HARVEST]: {
        harvest: harvest || batch.harvest ||  [],
        disposal: disposal || batch.disposal || [],
        harvestDate: isMultipleHarvest ? harvestDateArr : taskCompletedDate
      },
    };
    let batchAttributes = taskMap[taskName];
    // recalc batch metrics on harvest task completion
    const batchMetrics = calculateBatchMetrics({
      harvest: harvest || batch.harvest,
      disposal: disposal || batch.disposal,
      growthCycle: batch.growthCycle,
      isRockWool : batch.growthCycle.growMedia === 'Rockwool',
      QtyToSeed : batch.QtyToSeed,
      raftSites: batch.growthCycle.fs.raftType ? rafts[batch.growthCycle.fs.raftType].sites : '',
      isComplete: current.stage === STAGES.COMPLETED,
      cycleDetail: batch.cycleDetail || {}
    });

    // adjust growthCycle if needed
    if (adjustGrowthCycle) {
      let candidateCycle = deepCopyObject(batch.growthCycle);

      if (taskName === TASK_NAMES.SEED) {
        // seedDate change doesn't create a new growthCycle or necessitate date recalculation
        growthCycle.seed.date = taskCompletedDate;
        tasks.seed.date = candidateCycle.seed.date;

      } else {
        // start and startAndSeed should only change the germ.date
        if (taskName === TASK_NAMES.START || taskName === TASK_NAMES.SEED_AND_START) {
          candidateCycle.germ.date = taskCompletedDate;
        } else {
          // calculate difference in dates
          if (isMultipleHarvest && harvestNum > 0) {
            candidateCycle.harvest.date[harvestNum] = taskCompletedDate;
            candidateCycle.harvestInterval = getMinDateInterval(candidateCycle.harvest.date);
          } else {
            const diff = moment(task.completedDate).diff(task.date, 'days');
            candidateCycle[priorTask.batchStage].days += diff;
          }
        }

        growthCycle = recalcDatesInGrowthCycle(candidateCycle, candidateCycle.germ.date, candidateCycle.seed.date);
        if(growthCycles && Object.keys(growthCycles).length > 0 &&  Object.keys(varieties).length > 0 && varieties[task.variety]){
          growthCycle = createBrandNewGrowthCycle(growthCycle, varieties[task.variety], growthCycles);
        }
        // assign new dates to tasks
        if (tasks[TASK_NAMES.START]) { tasks[TASK_NAMES.START].date = growthCycle.germ.date; }
        if (tasks[TASK_NAMES.SEED_AND_START]) { tasks[[TASK_NAMES.SEED_AND_START]].date = growthCycle.germ.date; }
        tasks[TASK_NAMES.TRANSFER_PROP].date = growthCycle.prop.date;
        if (tasks[TASK_NAMES.TRANSFER_HD]) { tasks[TASK_NAMES.TRANSFER_HD].date = growthCycle.hd.date; }
        if(tasks[TASK_NAMES.TRANSFER_FS] && growthCycle.fs.date){ tasks[TASK_NAMES.TRANSFER_FS].date = growthCycle.fs.date };
        if (isMultipleHarvest) {
          for (let i = 0; i < growthCycle.harvests; i++) {
            tasks[`${TASK_NAMES.HARVEST}-${i}`].date = growthCycle.harvest.date[i];
          }
        } else {
          tasks[TASK_NAMES.HARVEST].date = growthCycle.harvest.date;
        }
      }
    }

    updatedBatches[batchID] = {
      ...batch,
      tasks,
      current,
      isFinalized,
      GRFlot,
      ...batchAttributes,
      ...batchMetrics,
      growthCycle,
      growthCycleID
    };
    console.log('<<<<<<<<<<<<<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>>>>>>>>');
    console.log('<<<<<<<updatedBatches>>>>', updatedBatches ,'>>>>>>>');
    console.log('<<<<<<<<<<<<<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>>>>>>>>');
  }

  return updatedBatches;
}

export function updateVarietiesForTaskCompletion({ taskList = [], taskCompletedDate, seedLot, tasks, varieties }) {
  let updatedVarieties = {};

  for (let i = 0; i < taskList.length; i++) {
    const task = tasks[taskList[i]];
    let variety = updatedVarieties[task.variety] || deepCopyObject(varieties[task.variety]);

    // update the variety if trackStock is true; this will only return varieties that were actually modified
    if (variety.trackStock) {
      // update currentStock
      variety.currentStock = parseInt(variety.currentStock) - parseInt(task.quant);

      // update stockTransactions
      variety.stockTransactions = variety.stockTransactions || [];
      variety.stockTransactions.push({
        date: taskCompletedDate || task.date,
        action: INVENTORY_ACTIONS.SEED,
        quant: -task.quant
      });
    }

    // update lastUsedSeedLot
    variety.lastUsedSeedLot = seedLot || variety.lastUsedSeedLot;

    updatedVarieties[task.variety] = variety;
  }

  return updatedVarieties;
}

export function createBrandNewGrowthCycle(growthCycle,variety,growthCycles) {
  let newGrowthCycle = createNewGrowthCycleObj(growthCycle);
    const nameList = variety.growthCycles.map(gcID => growthCycles[gcID].name);
    newGrowthCycle.name = createNewName(growthCycle.name, nameList);
  return newGrowthCycle; 
}

export function updateGrowthCyclesForTaskCompletion({ batch, batchID, variety, growthCycles, batches }) {
  let modifiedGrowthCycles = {};
  let newGrowthCycle = null;

  const foundID = findGrowthCycleIDInVariety({ variety, growthCycles, compareCycle: batch.growthCycle });

  if (foundID) {
    if (!growthCycles[foundID].hasBeenUsed) {
      modifiedGrowthCycles[foundID] = {
        ...growthCycles[foundID],
        hasBeenUsed: true
      };
    }

    const oldCycleHasBeenUsed = checkHasBeenUsed({
      collectionSingular: 'growthCycle',
      collectionItemID: batch.growthCycleID,
      batches,
      currentBatchID: batchID
    });
    if (!oldCycleHasBeenUsed) {
      modifiedGrowthCycles[batch.growthCycleID] = {
        ...growthCycles[batch.growthCycleID],
        hasBeenUsed: false
      };
    }

  } else if (!foundID) {
    newGrowthCycle = createBrandNewGrowthCycle(batch.growthCycle, variety, growthCycles);
  }

  return { modifiedGrowthCycles, newGrowthCycle };
}
import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import NewBatchFormRow from './NewBatchFormRow';
import PasswordModal from './PasswordModal';
import FullScreenLoader from '../components/FullScreenLoader';


import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, withStyles } from '@material-ui/core';
import {
  calcHarvestDate,
  calcInitiationDate
} from '../utils/appFunctions/growthCycles';
import {
  calcTotalBatchesFromPerpetual,
  createBatchesFromRowData,
} from '../utils/appFunctions/batches';
import {
  createPlotsFromBatches,
  checkCapacity
} from '../utils/appFunctions/plots';
import { checkArray, deepCopyObject } from '../utils/utils';
import {
  pushBatch,
  pushPlots,
  pushVarietyLegend,
  addEditBatch,
  pushBatches,
  setShouldCalculatePlots,
  startPlotCalcAfterNumber,
} from '../store/actions/adders';
import { removeBatchThunk } from '../store/actions/deleters';
import { connect } from 'react-redux';
import { Prompt } from 'react-router-dom';
import moment from 'moment';
import { CSVLink } from "react-csv";
import RLDD from 'react-list-drag-and-drop/lib/RLDD';
import Switch from '@material-ui/core/Switch';
import BatchModal from './BatchModal';
import FarmModel from '../Models/farmModel';
import APP_SETTINGS from '../utils/appSettings';

const { STAGES } = APP_SETTINGS;


const styles = theme => ({
  title: {
    display: 'inline',
    paddingRight: '1rem',
    width: 'max-content'
  },
  formRow: {
    marginBottom: '1.5rem'
  },
  addRowButton: {
    fontSize: '1.2rem',
    cursor: 'pointer',
    color: green[300]
  },
  buttonContainer: {
    marginTop: '2rem',
    paddingRight: '0.5rem',
    marginBottom: '3rem'
  },
  button: {
    position: 'relative',
    marginLeft: '1.5rem'
  },
  buttonLoader: {
    color: 'white'
  },
  commitError: {
    position: 'absolute',
    bottom: -21,
    left: 1,
    color: red[400],
    fontSize: '0.7rem',
    fontWeight: 400,
    textTransform: 'initial',
    width: 'max-content',
  }
});

class NewBatchForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasBeenEdited: false,
      rows: [this.rowTemplate],
      commitErrorMessage: '',
      isCommitting: false,
      snackbarOpen: false,
      snackbarMessage: '',
      modalOpen: false,
      currentModal: '',
      data: [],
      draggable: false,
      openPrompt: false,
      overfilledMessage: '',
      showLoader: false,

    };
    this.removeRow = this.removeRow.bind(this);
    this.closeSnackBar = this.closeSnackBar.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.recalcData = this.recalcData.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.handleCommitClick = this.handleCommitClick.bind(this);
    this.renderModal = this.renderModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  csvLink = React.createRef()


  rowTemplate1 = {
    pond: '',
    noOfRaft: '',
    name: ''
  }

  rowTemplate = {
    id: 0,
    crop: '',
    variety: "",
    growthCycle: '',
    germCells: '',
    hdRafts: '',
    hdRemainder: '',
    hdEndRafts: '',
    fsRafts: '',
    fsRemainder: '',
    fsEndRafts: '',
    growthCycleObj: {},
    seedDate: null,
    initiationDate: null,
    harvestDate: null,
    assignUser: '',
    propLocation: {
      farm: '',
      pond: ''
    },
    propLocation1: {
      farm: '',
      pond: {
        prop: [this.rowTemplate1]
      }
    },
    finishingLocation: {
      farm: '',
      pond: {
        hd: [this.rowTemplate1],
        fs: [this.rowTemplate1],
      }
    },


    cells: '',
    totalBatchWeight: '',
    endRafts: '',
    tag: '',
    isPerpetual: false,
    perpetualEndDate: null,
    perpetualSeedDays: { Su: false, M: false, T: false, W: false, Th: false, F: false, Sa: false },
    perpetualStartDays: { Su: false, M: false, T: false, W: false, Th: false, F: false, Sa: false },
    totalBatches: 1,
    totalProjectedWeight: null,
    RepeatType: 2,
    errors: {
      seedDate: false,
      startDate: false,
      cells: false,
      germCells: false,
      capacity: false
    },
    errorMessages: {
      seedDate: '',
      startDate: '',
      cells: '',
      germCells: '',
      capacity: ''
    },
    isReadyToCommit: false,
  }

  promisedSetState = (newState) => {
    return new Promise((resolve) => {
      this.setState(newState, () => {
        resolve()
      });
    });
  }

  handleClose = () => {
    this.setState({ openPrompt: false, overfilledMessage: '' });
    this.setState({ modalOpen: true, currentModal: 'password' });
  }

  showOrCloseLoader = (isShow = false) =>{
    // console.log("showOrCloseLoaderrrr",isShow)
    this.setState({ showLoader: isShow });
  }


  componentWillUnmount() {
    let batchesHaveBeenAdded = false;
    this.state.rows.forEach(row => {
      if (row.isReadyToCommit) {
        batchesHaveBeenAdded = true;
      }
    });
    if (batchesHaveBeenAdded) {
      this.state.rows.forEach(async (row, ind) => {
        await this.props.removeBatchThunk(ind);
      });
      this.refreshPlots();
    }
  }

  addRow = (clone) => {
    let rows = deepCopyObject(this.state.rows);
    let arr = [...rows];
    let newData = deepCopyObject(clone || this.rowTemplate);
    newData.id = arr.length
    arr.push(newData);
    this.setState({ rows: arr });

    this.showOrCloseLoader(true);

    setTimeout(() => {
      this.recalcData('', arr.length - 1, '')
    }, 1);
  }

  async removeRow(ind) {
    let rows = deepCopyObject(this.state.rows);
    rows.splice(ind, 1);
    await this.promisedSetState({ rows });
    await this.props.removeBatchThunk(ind);

    this.showOrCloseLoader(true);
    /* commented bcos app was getting slow */
    setTimeout(() => {
      const plots = this.refreshPlots();
      this.setCapacityErrors(plots, rows);
      this.showOrCloseLoader(false);
    }, 100);
  }

  closeSnackBar() {
    this.setState({ snackbarOpen: false, snackbarMessage: '' });
  }

  handleChangePond = batchInd => (ind, pondType, finalPond) => async e => {

    let rows = deepCopyObject(this.state.rows);
    const farmLocation = pondType === 'prop' ? 'propLocation1' : 'finishingLocation'

    if (finalPond) {
      let pondData = finalPond.find(element => element.id === e.target.value);
      rows[batchInd][farmLocation].pond[pondType][ind].name = pondData.name;
      rows[batchInd][farmLocation].pond[pondType][ind][e.target.name] = e.target.value;

    }
    else {
      rows[batchInd][farmLocation].pond[pondType][ind][e.target.name] = e.target.value;
    }

    const selectedPond = rows?.[batchInd]?.[farmLocation]?.pond?.[pondType];
    if(selectedPond && e.target.name == "noOfRaft"){
      if(pondType == "hd"){
        let newHdRafts = rows[batchInd].hdRafts;
        if(selectedPond.length > 0){
          newHdRafts = selectedPond.reduce(function (sum,current) {
            return sum + parseFloat(current?.[e.target.name] || 0)
          },0)
        }
        rows[batchInd].hdRafts = newHdRafts;
        rows[batchInd].hdRemainder = parseFloat(rows[batchInd].hdEndRafts - newHdRafts).toFixed(2);
      }
      if(pondType == "fs"){
        let newFsRafts = rows[batchInd].fsRafts;
        if(selectedPond.length > 0){
          newFsRafts = selectedPond.reduce(function (sum,current) {
            return sum + parseFloat(current?.[e.target.name] || 0)
          },0)
        }
        rows[batchInd].fsRafts = newFsRafts;
        rows[batchInd].fsRemainder = parseFloat(rows[batchInd].fsEndRafts - newFsRafts).toFixed(2);
      }
    }

    this.setState({ rows })

    await this.promisedSetState({
      rows,
      hasBeenEdited: true,
      commitErrorMessage: '',
      seedCommitErrorMessage: ''
    });

    this.showOrCloseLoader(true);

    setTimeout(() => {
      this.recalcData('', batchInd, '')
    }, 1);

  }

  handleCompleteRowChange = batchInd => async (updatedRow) => {
    console.log('updatedRowsadas',updatedRow);
    let rows = deepCopyObject(this.state.rows);
    rows[batchInd] = updatedRow;
    this.setState({ rows })
    await this.promisedSetState({
      rows,
      hasBeenEdited: true,
      commitErrorMessage: '',
      seedCommitErrorMessage: ''
    });
    this.showOrCloseLoader(true);

    setTimeout(() => {
      this.recalcData('', batchInd, '')
    }, 1);
  }

  calculateDataForAllBatchs = async (isHandleCommit = true) => {
    let rows = deepCopyObject(this.state.rows);
    // console.log("stateRowssss111",rows)
    // rows = rows.filter(p=>p?.isReCalData !== true);
    let reCalRowIndxs = [];
    rows.map((batchOj, batchIndx)=>{
      if(batchOj?.isReCalData !== true){
        reCalRowIndxs.push(batchIndx);
      }
    })
    // console.log("rowwww33311999",reCalRowIndxs,moment().format("HH:mm:ss"))

    if(checkArray(reCalRowIndxs)){
      const pots = await reCalRowIndxs.map(async (batchInd)=>{
          // console.log("rowwww33344999",batchInd,moment().format("HH:mm:ss"))
          return await this.recalcData('', batchInd, '',true, false)
      });
      // console.log("rowwww33309999",pots,moment().format("HH:mm:ss"))
      Promise.all(pots).then((values) => {
        // console.log("rowwww333011222",values,moment().format("HH:mm:ss"))
          reCalRowIndxs.map((batchInd)=>{
            rows[batchInd].isReCalData = true;
          });
          this.setState({rows});
          this.showOrCloseLoader(false);
          this.handleCommitClick(isHandleCommit);
      });
    }else{
      this.showOrCloseLoader(false);
      this.handleCommitClick(isHandleCommit)
    }
  }

  

  deletePond = batchInd => (ind, pondType) => {
    const farmLocation = pondType === 'prop' ? 'propLocation1' : 'finishingLocation'
    let rows = deepCopyObject(this.state.rows);
    let arr = [...rows[batchInd][farmLocation].pond[pondType]];
    arr[ind] = null;
    rows[batchInd][farmLocation].pond[pondType] = arr.filter(Boolean);
    this.setState({ rows })
  }
  addRowInPond = batchInd => (pondType) => {
    const farmLocation = pondType === 'prop' ? 'propLocation1' : 'finishingLocation'
    let rows = deepCopyObject(this.state.rows);
    let arr = [...rows[batchInd][farmLocation].pond[pondType]];
    arr.push(this.rowTemplate1);
    rows[batchInd][farmLocation].pond[pondType] = arr;
    this.setState({ rows })
  }

  reCalRafts = (ind,prevRows=null,newHdRaft = undefined) => {
    let rows = (prevRows === null)?deepCopyObject(this.state.rows):prevRows;
    let germVal = rows[ind].germCells || 0;
    let hdRaft = this.props.rafts[rows[ind].growthCycleObj.hd.raftType];
    let fsRaft = this.props.rafts[rows[ind].growthCycleObj.fs.raftType];
    let hdRafts = hdRaft && germVal ? (germVal / hdRaft.sites) : '';
    let hdEndRaft = (newHdRaft !== undefined)? newHdRaft : hdRaft && germVal ? (germVal / hdRaft.sites) : '';
    let hdRem = hdRafts ? germVal - (hdRafts * hdRaft.sites) : '';
    let hdEndSites = hdRafts ? (hdEndRaft || rows[ind].hdEndRafts) * hdRaft.sites : '';
    // console.log("tsetttas:",hdEndSites,fsRaft.sites )

    let fsRafts = '';
    let fsEndRaft = '';
    let fsRem = '';
    if (germVal && fsRaft) {
      const newFsRafts =(rows[ind].growthCycleObj.hd.days && hdRaft && rows[ind].hdEndRafts) ? parseFloat(hdEndSites / fsRaft.sites) :
      parseFloat(germVal / fsRaft.sites); 
      fsRafts = newFsRafts;
      fsEndRaft = newFsRafts;
      fsRem = (rows[ind].growthCycleObj.hd.days && hdRaft && rows[ind].hdEndRafts) ? hdEndSites - (fsRafts * fsRaft.sites) :
        germVal - (fsRafts * fsRaft.sites);
    }
    // rows[ind].hdRafts = parseFloat(hdRafts).toFixed(2);
    rows[ind].hdRafts = parseFloat(hdRafts).toFixed(2);
    // rows[ind].hdRemainder = parseFloat(hdRem).toFixed(2);
    rows[ind].hdRemainder = 0;
    rows[ind].hdEndRafts =  (newHdRaft !== undefined)? newHdRaft : parseFloat(hdEndRaft).toFixed(2);
    // rows[ind].fsRafts = parseFloat(fsRafts).toFixed(2);
    rows[ind].fsRafts = parseFloat(fsRafts).toFixed(2);
    // rows[ind].fsRemainder = parseFloat(fsRem).toFixed(2);
    rows[ind].fsRemainder = 0;
    rows[ind].fsEndRafts = parseFloat(fsRafts).toFixed(2);
    rows[ind].germCells = germVal;
    return rows;
  }

  handleChange = ind => (name, isReCalData = false, group) => async e => {
    let rows = deepCopyObject(this.state.rows);
    let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    if(name && name.includes('Date')){
      value = moment(e.target.value).format('MM/DD/YYYY')
    }
    if (name === 'PropFarm') {
      const farm = new FarmModel({ farm: value, pond: '' });
      rows[ind].propLocation = farm;
    }
    else if (name === 'PropPond') {
      if (group) {
        let pondData = group.find(element => element.id === e.target.value);
        const farm = new FarmModel({ farm: rows[ind].propLocation.farm, pond: value, name: pondData.name });
        rows[ind].propLocation = farm;
      }
      else {
        const farm = new FarmModel({ farm: rows[ind].propLocation.farm, pond: value });
        rows[ind].propLocation = farm;
      }
    }
    else if (name === 'FinishingLocation') {
      if (rows[ind].growthCycleObj.growMedia === 'Rockwool') {
        const farm = new FarmModel({ farm: value, pond: { hd: [this.rowTemplate1], fs: [this.rowTemplate1] } });
        rows[ind].finishingLocation = farm;
      }
      else {
        const farm = new FarmModel({ farm: value, pond: { prop: [this.rowTemplate1] } });
        rows[ind].propLocation1 = farm;
      }
    }
    else if (name === 'germCells' && rows[ind].growthCycleObj.growMedia === 'Rockwool') {
      rows[ind].germCells = value;
    } else {
      if (group) {
        rows[ind][group][name] = value;
      } else {

        if ((name.toLowerCase().includes('date') && name !== 'perpetualEndDate') || (name === 'crop') || (name === 'variety') || (name === 'growthCycle')) {

          value = value || null
        }

        if (rows[ind]) {
          if (name === 'assignUser') {
            rows[ind][name] = value.id;
          }
          else rows[ind][name] = value;
        }
      }
      // manage crop and variety selection and the available child options
      if (name === 'crop') {
        rows[ind].variety = '';
        rows[ind].growthCycle = '';
        rows[ind].QtyToSeed = 0
      }
      if (name === 'variety') {
        if(rows[ind]?.isClone === true && value !== ""){
          const firstVarCycle = this.props.varieties?.[value]?.growthCycles?.[0];
          if(firstVarCycle){
            if(this.props.growthCycles[firstVarCycle].growMedia === 'Rockwool' && !(rows[ind]?.finishingLocation?.farm && rows[ind].finishingLocation.farm !== "")){
              rows[ind].finishingLocation.farm = rows[ind]?.propLocation1?.farm || ''
            }
            if(this.props.growthCycles[firstVarCycle].growMedia !== 'Rockwool' && !(rows[ind]?.propLocation1?.farm && rows[ind].propLocation1.farm !== "")){
              rows[ind].propLocation1.farm = rows[ind]?.finishingLocation?.farm || ''
            }
            rows[ind].growthCycle = firstVarCycle;
            rows[ind].growthCycleObj = this.props.growthCycles[firstVarCycle];
          }else{
            rows[ind].growthCycle = '';
            rows[ind].growthCycleObj = {};
          }
        }else{
          rows[ind].growthCycle = '';
          rows[ind].QtyToSeed = 0;
        }
      }

    }

    // calculate rafts
    if ((name === 'germCells' || name === 'hdEndRafts') && rows[ind].growthCycleObj.growMedia === 'Rockwool') {
      rows = this.reCalRafts(ind,rows, (name === 'hdEndRafts'? value : undefined));
    }
    if (name === 'fsRafts' && rows[ind].growthCycleObj.growMedia === 'Rockwool') {
      rows[ind].fsRafts = value;
      rows[ind].fsEndRafts = value;
    }
    // From now New data (finishing location) added to structure which contains (FS farm + HD Farm)



    await this.promisedSetState({
      rows,
      hasBeenEdited: true,
      commitErrorMessage: '',
      seedCommitErrorMessage: ''
    });


    if(isReCalData){
      this.showOrCloseLoader(true);
    }
    setTimeout(() => {
      this.recalcData(name, ind, group, isReCalData)
    }, 1);



  }

  formatDate = ind => (name, group) => async e => {
    let rows = deepCopyObject(this.state.rows);
    let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    if (value === null) {
      return
    }
    if (group) {
      if (!moment(value).isValid()) {
        return value = moment().format('YYYY-MM-DD')
      }

      let date = value.split('/')
      if (date.length === 2) {
        if (date[0].length < 2) {
          value = '0' + date[0]
        }
      }
      else if (date.length === 3) {
        var test0 = date[0] + '/';
        var test1 = date[1] + '/';
        if (date[0].length < 2) {
          test0 = '0' + test0
        }
        if (date[1].length < 2) {
          test1 = '0' + test1
        }
        value = test0 + test1 + date[2]
      }
      rows[ind][name] = value;
    } else {
      if (name.toLowerCase().includes('date')) {
        value = value ? moment(value).format('YYYY-MM-DD') : null;
      }
      rows[ind][name] = value;
    }
    // manage crop and variety selection and the available child options
    if (name === 'crop') {
      rows[ind].variety = '';
      rows[ind].growthCycle = '';
    }
    if (name === 'variety') {
      if(rows[ind]?.isClone === true && value !== ""){
        const firstVarCycle = this.props.varieties?.[value]?.growthCycles?.[0];
        if(firstVarCycle){
          if(this.props.growthCycles[firstVarCycle].growMedia === 'Rockwool' && !(rows[ind]?.finishingLocation?.farm && rows[ind].finishingLocation.farm !== "")){
            rows[ind].finishingLocation.farm = rows[ind]?.propLocation1?.farm || ''
          }
          if(this.props.growthCycles[firstVarCycle].growMedia !== 'Rockwool' && !(rows[ind]?.propLocation1?.farm && rows[ind].propLocation1.farm !== "")){
            rows[ind].propLocation1.farm = rows[ind]?.finishingLocation?.farm || ''
          }
          rows[ind].growthCycle = firstVarCycle;
          rows[ind].growthCycleObj = this.props.growthCycles[firstVarCycle];
        }else{
          rows[ind].growthCycle = '';
          rows[ind].growthCycleObj = {};
        }
      }else{
        rows[ind].growthCycle = '';
        rows[ind].QtyToSeed = 0;
      }
    }

    await this.promisedSetState({
      rows,
      hasBeenEdited: true,
      commitErrorMessage: '',
      seedCommitErrorMessage: ''
    });

    setTimeout(() => {
      this.recalcData(name, ind, group)
    }, 1);
  }

  async recalcData(name, ind, group, isReCalData = true, hideLoader = true) {
    let rows = deepCopyObject(this.state.rows);
    let row = rows[ind];
    const { varieties, growthCycles } = this.props;


    function checkSeedDate() {
      if (row.seedDate && moment(row.seedDate).isAfter(row.initiationDate, 'days')) {
        row.errors.seedDate = true;
        row.errorMessages.seedDate = 'Must be before Start Date';
      } else {
        row.errors.seedDate = false;
        row.errorMessages.seedDate = '';
      }
    }

    function checkGermCells() {
      row.cells = row?.germCells || growthCycles[row.growthCycle]?.germ.cells;
      row.endRafts = row?.fsEndRafts || growthCycles[row.growthCycle]?.fs.endRafts;
      if (row.growthCycle &&  growthCycles[row.growthCycle] && growthCycles[row.growthCycle].growMedia === 'Rockwool' && (row.germCells == "" || row.germCells == 0)) {
        row.errors.germCells = true;
        row.errorMessages.germCells = 'Please enter cells';
      } else {
        row.errors.germCells = false;
        row.errorMessages.germCells = '';
      }
    }

    function checkInitiationDate() {
      if (row.initiationDate && moment(row.initiationDate).isBefore(moment(), 'days')) {
        row.errors.initiationDate = true;
        row.errorMessages.initiationDate = 'Warning: Date is before today';
      } else {
        row.errors.initiationDate = false;
        row.errorMessages.initiationDate = '';
      }
    }

    function checkStockAvailability() {
      const variety = varieties[row.variety];
      if (!variety || !variety.trackStock) { return; }
      if (variety.currentStock - (row.cells * row.totalBatches) < 0) {
        row.errors.cells = true;
        row.errorMessages.cells = 'Quantity exceeds current stock.'
      } else {
        row.errors.cells = false;
        row.errorMessages.cells = '';
      }
    }

    // if name has a current error, clear it
    if (row.errors[name]) {
      row.errors[name] = false;
      row.errorMessages[name] = '';
    }
    if (group === 'perpetualStartDays' || name === 'perpetualEndDate' || name === 'RepeatType') {
      if (row.growthCycle && row.initiationDate && row.perpetualEndDate && row.RepeatType) {
        let { initiationDate, perpetualEndDate, perpetualStartDays, RepeatType } = row;
        row.totalBatches = calcTotalBatchesFromPerpetual({ initiationDate, perpetualEndDate, perpetualStartDays, RepeatType });
        row.totalProjectedWeight = row.totalBatchWeight ? row.totalBatchWeight * row.totalBatches : null;
      } else {
        row.totalBatches = 1;
        row.totalProjectedWeight = null
      }
      checkStockAvailability();
    }

    checkGermCells();
    console.log("nameeeeeeeeeee",name,isReCalData)
    switch (name) {
      case 'seedDate':
        if (!row.initiationDate) {
          row.initiationDate = row.seedDate;
          checkInitiationDate();
          if (row.growthCycle) {
            const harvestDate = calcHarvestDate({ initiationDate: row.initiationDate, growthCycle: this.props.growthCycles[row.growthCycle] });
            row.harvestDate = harvestDate;
          }
        }
        checkSeedDate();
        break;

      case 'initiationDate':
        checkInitiationDate();
        if (!row.seedDate) {
          row.seedDate = row.initiationDate;
        }
        checkSeedDate();
        if (row.growthCycle && row.initiationDate && row.RepeatType) {
          row.totalBatches = calcTotalBatchesFromPerpetual({ initiationDate: row.initiationDate, perpetualEndDate: row.perpetualEndDate, perpetualStartDays: row.perpetualStartDays, RepeatType: row.RepeatType });
          row.totalProjectedWeight = row.totalBatchWeight ? row.totalBatchWeight * row.totalBatches : null;
        }
        if (row.growthCycle) {
          row.harvestDate = calcHarvestDate({ initiationDate: row.initiationDate, growthCycle: this.props.growthCycles[row.growthCycle] });
        }
        checkStockAvailability();
        break;

      case 'harvestDate':
        if (row.growthCycle) {
          row.initiationDate = calcInitiationDate({ harvestDate: row.harvestDate, growthCycle: this.props.growthCycles[row.growthCycle] });
          checkSeedDate();
          checkInitiationDate();
        }
        break;

      case 'growthCycle':
        // if (row.initiationDate && !row.harvestDate) {
        if (row.initiationDate) {
          row.harvestDate = calcHarvestDate({ initiationDate: row.initiationDate, growthCycle: this.props.growthCycles[row.growthCycle] });
        }
        if (row.harvestDate && !row.initiationDate) {
          row.initiationDate = calcInitiationDate({ harvestDate: row.harvestDate, growthCycle: this.props.growthCycles[row.growthCycle] });
          checkInitiationDate();
        }
        const growthCycle = this.props.growthCycles[row.growthCycle];

        row.cells = row?.germCells || growthCycle.germ.cells;
        row.endRafts = row?.fsEndRafts || growthCycle.fs.endRafts;
        row.totalBatchWeight = growthCycle.avgWeightPerRaft ? growthCycle.avgWeightPerRaft * row.endRafts : '';
        row.growthCycleObj = growthCycle;

        if (row.isPerpetual && row.initiationDate && row.perpetualEndDate && row.RepeatType) {
          row.totalBatches = calcTotalBatchesFromPerpetual({ initiationDate: row.initiationDate, perpetualEndDate: row.perpetualEndDate, perpetualStartDays: row.perpetualStartDays, RepeatType: row.RepeatType });
          row.totalProjectedWeight = row.totalBatchWeight ? row.totalBatchWeight * row.totalBatches : null;
        }
        checkStockAvailability();
        break;
      case 'perpetualEndDate':
      case 'isPerpetual':
        // don't let perpetualEndDate go before initiationDate
        if (name === 'perpetualEndDate' && moment(row.perpetualEndDate).isBefore(row.initiationDate, 'days')) {
          row.perpetualEndDate = row.initiationDate;
        }

        if (row.isPerpetual && row.initiationDate && row.RepeatType) {
          row.totalBatches = calcTotalBatchesFromPerpetual({ initiationDate: row.initiationDate, perpetualEndDate: row.perpetualEndDate, perpetualStartDays: row.perpetualStartDays, RepeatType: row.RepeatType });
          row.totalProjectedWeight = row.totalBatchWeight ? row.totalBatchWeight * row.totalBatches : null;
        } else {
          row.totalBatches = 1;
          row.totalProjectedWeight = null;
        }

        checkStockAvailability();
        break;

      default:
        break;
    }

    // set row as ready to commit if ready
    const wasReadyToCommit = row.isReadyToCommit;
    if (row.crop && row.variety && row.growthCycle && row.seedDate && row.initiationDate && row.harvestDate && row.growthCycleObj) {

      if (row.growthCycleObj.growMedia === 'Rockwool' && row.finishingLocation.farm && row.finishingLocation.pond) {
        row.isReadyToCommit = true;
      }
      else if (row.growthCycleObj.growMedia !== 'Rockwool' && (row.QtyToSeed > 0) && row.propLocation1.farm) {
        row.isReadyToCommit = true;
      }

    }
    else {
      row.isReadyToCommit = false;
    }

    if (row.isPerpetual && !row.perpetualEndDate) {
      row.isReadyToCommit = false;
    }

    // await this.promisedSetState({ rows });
    if ((row.isReadyToCommit || wasReadyToCommit) && 
        (name == "growthCycle" || name == "QtyToSeed" ||name == "initiationDate" ||
        name == "harvestDate" || name == "seedDate" || name == "FinishingLocation"|| name == "germCells")
      ) {
      row.isReCalData = false;
    }

    if(isReCalData){
      console.log("isReCalDataaaa1111",row.isReCalData)
      if (row.isReadyToCommit) {
        // this.showOrCloseLoader(true);
        await this.addBatchesToState(ind);
        const plots = await this.refreshPlots();
        this.setCapacityErrors(plots, rows);
        
        /* commented bcos app was getting slow */
        // setTimeout(() => {
        //   const plots = this.refreshPlots();
        //   this.setCapacityErrors(plots, rows);
        //   this.showOrCloseLoader(false);
        // }, 100);
  
      } else if (wasReadyToCommit) {
        await this.props.removeBatchThunk(ind);
        await this.addBatchesToState(ind);
        const plots = await this.refreshPlots();
        this.setCapacityErrors(plots, rows);
        /* commented bcos app was getting slow */
        // setTimeout(() => {
        //   const plots = this.refreshPlots();
        //   this.setCapacityErrors(plots, rows);
        //   this.showOrCloseLoader(false);
        // }, 100);
      }
      if(hideLoader){
        this.showOrCloseLoader(false);
      }
      row.isReCalData = true;
    }
    console.log("isReCalDataaaa2222",row.isReCalData, ind)
    await this.promisedSetState({ rows });

    return rows;

  }

  async addBatchesToState(ind) {
    // create batches from row, push to redux store
    this.props.removeBatchThunk(ind);
    const currentRow = this.state.rows[ind];

    console.log('errorsssss', currentRow.errors)

    const { batches } = createBatchesFromRowData({ row: currentRow, ind, rafts:this.props.rafts, varietyCode: this.props.varieties[currentRow.variety].varietyCode, isCommitted: false });
    // console.log('batchesbatches',batches)
    this.props.pushBatches(batches);
    setTimeout(() => {
      for (const key in batches) {
        let row = batches[key];
        let modifiedRow = { ...row };
        modifiedRow.growthCycle = row.growthCycleID
        // if (modifiedRow.PropFarm && row.PropPond) this.props.refreshVarieties(modifiedRow, STAGES.PROP);
        // if (modifiedRow.HDFarm && row.HDPond) this.props.refreshVarieties(modifiedRow, STAGES.HD);
        // if (modifiedRow.FSFarm && row.FSPond) this.props.refreshVarieties(modifiedRow, STAGES.FS);
      }
    }, 500);

  }

  async refreshPlots(newBatches) {
    // create plots, push to redux store
    let batches = { ...this.props.batches, ...newBatches };

    const { plots, varietyLegend } = createPlotsFromBatches({ batches, varieties: this.props.varieties });
    await this.props.pushPlots(plots);
    await this.props.pushVarietyLegend(varietyLegend);
    return plots;
  }

  setCapacityErrors(plots, rows) {
    // set errors on rows if there's overflow
    const { overflowBatch, overflowDate } = checkCapacity(plots);
    if (overflowBatch) {
      let currentRowList = new Array(this.state.rows.length);
      for (let i = 0; i < currentRowList.length; i++) {
        currentRowList[i] = i.toString();
      }
      const overflowIndex = overflowBatch.split('-')[0];
      if (currentRowList.includes(overflowIndex)) {
        for (let row in rows) {
          if (row === overflowIndex || moment(rows[row].initiationDate).isSameOrAfter(overflowDate)) {
            rows[row].errors.capacity = true;
            rows[row].errorMessages.capacity = `Planned batch will exceed capacity as of ${moment(overflowDate).format('MM/DD/YYYY')}`;
          }
        }
        this.setState({ rows });
      } else {
      }
    } else {
      // turn off all errors
      const rows = this.state.rows.map(row => ({
        ...row,
        errors: {
          ...row.errors,
          capacity: false
        },
        errorMessages: {
          ...row.errorMessages,
          capacity: ''
        }
      }));
      this.setState({ rows });
    }
  }


  async onSubmit() {
    await this.promisedSetState({ isCommitting: true, modalOpen: false, currentModal: '' });

    let batchCount = 0;

    // // remove uncommitted/temp batches from store

    this.state.rows.forEach((row, ind) => {
      batchCount = batchCount + (row.isPerpetual ? row.totalBatches : 1);
      this.props.removeBatchThunk(ind);
    });

    // stop calculation of plots until all batches are received back in database listener
    this.props.setShouldCalculatePlots(false);
    this.props.startPlotCalcAfterNumber(batchCount);

    setTimeout(async () => {
      // create all the new Batches and Perpetual Batches, save to db
      let newBatches = {};


      // let arr = [
      //   ["Start Date", "Variety", "Number to be seeded", "Date it is split to HD", "Date is is split to FS", "Harvest date"]
      // ];

      let tasklist = [];
      let taskUser = '';


      for (let ind = 0; ind < this.state.rows.length; ind++) {
        const row = this.state.rows[ind];
        const varietyCode = this.props.varieties[row.variety].varietyCode;
        const varietyAlias = this.props.varieties[row.variety].varietyAlias;

        let { batches } = createBatchesFromRowData({ row, ind, rafts:this.props.rafts, varietyCode, isCommitted: true, varietyAlias, variety: this.props.varieties[row.variety] });
        let idList = [];

        // console.log('createBatchesFromRowData1',batches)

        for (let batchID in batches) {
          let updatedBatch = batches[batchID];
          let each = updatedBatch;
          const id = await addEditBatch({ batch: updatedBatch });
          // alotTask({ id: each.assignUser, title: 'Crop Assigned', taskDetails: 'Crop assigned in' + ' ' + each.current.stage + ' ' + 'stage', completeByDate: '', task: id, type: 'crop' });

          tasklist.push(id);
          taskUser = each.assignUser;
          // arr.push([moment(each.growthCycle.seed.date).format('YYYY-MM-DD'),
          // this.props.varieties[each.variety].varietyCode + ' - ' + this.props.varieties[each.variety].name,
          // each.current.cells,
          // moment(each.growthCycle.seed.date).add(each.growthCycle.prop.days + each.growthCycle.germ.days, 'days').format('YYYY-MM-DD'),
          // moment(each.growthCycle.seed.date).add(each.growthCycle.prop.days + each.growthCycle.germ.days + each.growthCycle.hd.days, 'days').format('YYYY-MM-DD'),
          // moment(each.growthCycle.harvest.date).format('YYYY-MM-DD')
          // ])

          idList.push(id);
          newBatches[id] = updatedBatch;

        }

        // if (row.isPerpetual) {
        //   perpetualBatch.batches = idList;
        //   addEditPerpetualBatch({ perpetualBatch, id: perpetualBatchID });
        // }
      }
      this.setState({ snackbarOpen: true, snackbarMessage: 'Batches have been committed!', isCommitting: false, rows: [this.rowTemplate], hasBeenEdited: false });
      // return

      this.refreshPlots(newBatches);
    }, 1);
  }


  async handleClear() {
    // remove uncommitted/temp batches from store
    this.state.rows.forEach(async (row, ind) => {
      await this.props.removeBatchThunk(ind);
    });

    // clear state
    await this.promisedSetState({ isCommitting: false, rows: [this.rowTemplate], hasBeenEdited: false });
    return;
  }

  async handleCommitClick(isHandleCommit) {
    // check all rows are ready to commit
    let readyToCommit = true;
    this.state.rows.forEach(row => {
      if (!row.isReadyToCommit || row.errors.seedDate || row.errors.germCells) {
        readyToCommit = false;
      }
    });
    if (!readyToCommit) {
      this.setState({ commitErrorMessage: 'Check required fields' });
      return;
    }

    let overfill = false;

    for (let ind = 0; ind < this.state.rows.length; ind++) {
      const row = this.state.rows[ind];
      const varietyCode = this.props.varieties[row.variety].varietyCode;
      const varietyAlias = this.props.varieties[row.variety].varietyAlias;

      let { batches } = createBatchesFromRowData({ row, ind, rafts:this.props.rafts, varietyCode, isCommitted: true, varietyAlias, variety: this.props.varieties[row.variety] });

      // console.log('totalPondSquaresssss000',batches)

      for (const batch in batches) {
        const copyBatch = deepCopyObject(batches[batch]);
        copyBatch.growthCycleObj = batches[batch].growthCycle;

        if (copyBatch.growthCycleObj.growMedia === 'Rockwool') {

          // check Prop overfill 
          const propDetails = await this.props.checkAvailabilityPond({ batchData: copyBatch, pondData: copyBatch.propLocation, mode: STAGES.PROP })
          if (propDetails && propDetails.SpaceAvailable === false) {
            overfill = true
            this.setState({ openPrompt: true, overfilledMessage: propDetails.OverfilledPond + ' is overfilled on ' + propDetails.OverfilledDate })
            return
          }
          // console.log('createBatchesFromRowData2222',propDetails)

          // check hd overfill 

          for (const pond of copyBatch.finishingLocation.pond[STAGES.HD]) {
            const hdDetails = await this.props.checkAvailabilityPond({ batchData: copyBatch, pondData: pond, mode: STAGES.HD })
            if (hdDetails && hdDetails.SpaceAvailable === false) {
              overfill = true
              this.setState({ openPrompt: true, overfilledMessage: hdDetails.OverfilledPond + ' is overfilled on ' + hdDetails.OverfilledDate })
              return
            }
            // console.log('createBatchesFromRowData3333',hdDetails)
          }

          // check fs overfill
          for (const pond of copyBatch.finishingLocation.pond[STAGES.FS]) {
            const fsDetails = await this.props.checkAvailabilityPond({ batchData: copyBatch, pondData: pond, mode: STAGES.FS })
            if (fsDetails && fsDetails.SpaceAvailable === false) {
              overfill = true
              this.setState({ openPrompt: true, overfilledMessage: fsDetails.OverfilledPond + ' is overfilled on ' + fsDetails.OverfilledDate })
              return
            }
            // console.log('createBatchesFromRowData4444',fsDetails)
          }


        }
        else {
          // console.log('createBatchesFromRowData2222',copyBatch.propLocation1.pond[STAGES.PROP])
          for (const pond of copyBatch.propLocation1.pond[STAGES.PROP]) {
            const propDetails = await this.props.checkAvailabilityPond({ batchData: copyBatch, pondData: pond, mode: STAGES.PROP })
            if (propDetails && propDetails.SpaceAvailable === false) {
              overfill = true
              this.setState({ openPrompt: true, overfilledMessage: propDetails.OverfilledPond + ' is overfilled on ' + propDetails.OverfilledDate })
              return
            }
          }
        }
      }
    }
    if (!overfill && isHandleCommit) this.setState({ modalOpen: true, currentModal: 'password' });

  }

  renderModal() {
    switch (this.state.currentModal) {
      case 'password':
        return (
          <PasswordModal
            title="Confirm commit batches"
            handleSubmit={this.onSubmit}
            handleCancel={this.closeModal}
          />
        );

      case 'cloneBatch':
        return (
          <BatchModal
            batches={this.props.batches}
            crops={this.props.crops}
            users={this.props.users}
            varieties={this.props.varieties}
            clone
            farm={this.props.farm}
            onClickClone={(data) => {

              let newData = {};
              Object.keys(this.rowTemplate).map(key => {
                newData[key] = data[key] || this.rowTemplate[key]
              })
              newData['growthCycleID'] = data['growthCycleID'];
              newData['growthCycleObj'] = data['growthCycle'];

              setTimeout(() => {
                newData['growthCycle'] = data['growthCycleID'];
                newData['assignUser'] = data['assignUser'];
                newData.isReadyToCommit = true;
                this.addRow(newData);
                setTimeout(() => {
                  this.recalcData('', this.state.rows.length - 1, '')
                }, 1);
                this.closeModal()
              }, 1);

            }}
          />
        );

      default:
        return <div>This is not the modal you're looking for.</div>
    }
  }


  handleRLDDChange = (newItems) => {
    console.log(newItems)
    this.setState({ rows: newItems });
  }

  closeModal() {
    this.setState({ modalOpen: false, currentModal: '' });
  }


  render() {
    const { classes, crops, varieties, growthCycles, allFarms } = this.props;
    return (this.state.showLoader)? <FullScreenLoader /> : (
      <div>
        <Button variant="outlined" size="small" color="secondary" className={classes.topButton} onClick={() => { this.setState({ modalOpen: true, currentModal: 'cloneBatch' }) }}>
          batches
        </Button>
        <Grid container direction='row' xs={12} style={{ marginBottom: '1.5rem' }}>
          <Grid item container direction='row' alignItems="center" xs={6}>
            <Typography variant="h5" className={classes.title}>Add New Batches</Typography>
            <Tooltip placement="top" title="Add Row">
              <i className={`fas fa-plus fa-2x ${classes.addRowButton}`} onClick={() => this.addRow()}></i>
            </Tooltip>
          </Grid>
          <Grid item container direction='row' justify={'flex-end'} alignItems="center" xs={6}>
            <Switch
              checked={this.state.draggable}
              onChange={() => this.setState({ draggable: !this.state.draggable })}
              color="primary"
              name="checkedB"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
            <Typography variant="h5" className={classes.title}>Draggable List</Typography>
          </Grid>
        </Grid>

        {this.state.draggable ?
          <RLDD
            items={this.state.rows}
            itemRenderer={(row, ind) => {
              return (
                <NewBatchFormRow
                  key={ind}
                  className={classes.formRow}
                  allFarms={allFarms || []}
                  data={row}
                  users={this.props.users}
                  rowInd={ind}
                  crops={crops}
                  varieties={varieties}
                  growthCycles={growthCycles}
                  onChange={this.handleChange(ind)}
                  handleChangePond={this.handleChangePond(ind)}
                  handleCompleteRowChange={this.handleCompleteRowChange(ind)}
                  deletePond={this.deletePond(ind)}
                  addRowInPond={this.addRowInPond(ind)}
                  addRow={this.addRow}
                  formatDate={this.formatDate(ind)}
                  deleteRow={() => this.removeRow(ind)}
                  checkAvailabilityPond={this.props.checkAvailabilityPond}
                />
              );
            }}
            onChange={this.handleRLDDChange}
          /> :
          this.state.rows.map((row, ind) => {
            return (
              <NewBatchFormRow
                key={ind}
                className={classes.formRow}
                allFarms={allFarms || []}
                data={row}
                users={this.props.users}
                rowInd={ind}
                crops={crops}
                rafts={this.props.rafts}
                varieties={varieties}
                growthCycles={growthCycles}
                onChange={this.handleChange(ind)}
                handleChangePond={this.handleChangePond(ind)}
                handleCompleteRowChange={this.handleCompleteRowChange(ind)}
                deletePond={this.deletePond(ind)}
                addRowInPond={this.addRowInPond(ind)}
                addRow={this.addRow}
                formatDate={this.formatDate(ind)}
                deleteRow={() => this.removeRow(ind)}
                checkAvailabilityPond={this.props.checkAvailabilityPond}
              />
            );
          })}

        <Grid container justify="flex-end" spacing={16} className={classes.buttonContainer}>
          <Button
            className={classes.button}
            variant="contained"
            color="default"
            onClick={this.handleClear}
          >
            Clear All
          </Button>
          <div>
            
          </div>
          <div>
          <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              onClick={async() => {
                this.showOrCloseLoader(true);
                setTimeout(async() => {
                  await this.calculateDataForAllBatchs(false);
                }, 1);
              }}
              style={{ minWidth: 90 }}
              disabled={this.state.isCommitting}
            >
              Check for Overfill
            <div className={classes.commitError}>{this.state.commitErrorMessage}</div>
            </Button>
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              onClick={async() => {
                this.showOrCloseLoader(true);
                setTimeout(async() => {
                  await this.calculateDataForAllBatchs();
                }, 1);
              }}
              style={{ minWidth: 90 }}
              disabled={this.state.isCommitting}
            >
              {this.state.isCommitting ?
                <CircularProgress className={classes.buttonLoader} size={21} />
                :
                'Commit'
              }
              <div className={classes.commitError}>{this.state.commitErrorMessage}</div>
            </Button>
            <CSVLink
              data={this.state.data}
              filename={moment().format('YYYY-MM-DD') + '.csv'}
              className="hidden"
              ref={this.csvLink}
              target="_blank"
            />
          </div>
        </Grid>

        <Prompt
          when={this.state.hasBeenEdited}
          message="You will lose all unsaved data. Leave anyway?"
        />

        <Modal
          aria-labelledby="modal-title"
          open={this.state.modalOpen}
          onClose={this.closeModal}
        >
          {this.renderModal()}
        </Modal>

        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={this.state.snackbarOpen}
          autoHideDuration={3000}
          onClose={this.closeSnackBar}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{this.state.snackbarMessage}</span>}
          action={
            [
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                className={classes.close}
                onClick={this.closeSnackBar}
              >
                <i className="fas fa-times"></i>
              </IconButton>
            ]
          }
        />

        <Dialog
          open={this.state.openPrompt}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Overfilled</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {this.state.overfilledMessage}
            </DialogContentText>
            <DialogContentText style={{ color: '#000', marginTop: 15 }} id="alert-dialog-description">Are you sure you want to proceed with this overfull condition?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary" autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>

      </div>
    );
  }
}

NewBatchForm.propTypes = {
  classes: PropTypes.object.isRequired,
  crops: PropTypes.object.isRequired,
  varieties: PropTypes.object.isRequired,
  growthCycles: PropTypes.object.isRequired,
  batches: PropTypes.object.isRequired,
  allFarms: PropTypes.array.isRequired,
  pushBatch: PropTypes.func.isRequired,
  pushBatches: PropTypes.func.isRequired,
  removeBatchThunk: PropTypes.func.isRequired,
  pushPlots: PropTypes.func.isRequired,
  setShouldCalculatePlots: PropTypes.func.isRequired,
  startPlotCalcAfterNumber: PropTypes.func.isRequired,
  farm: PropTypes.object
};

export default withStyles(styles)(connect(null, {
  pushBatch,
  pushBatches,
  removeBatchThunk,
  pushPlots,
  pushVarietyLegend,
  setShouldCalculatePlots,
  startPlotCalcAfterNumber
})(NewBatchForm));

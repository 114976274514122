import moment from 'moment';
import { fillNumberStringToLength, roundToHalf } from '../utils';
import { calcHarvestDate, recalcDatesInGrowthCycle } from './growthCycles';
import { generateTasks } from './tasks';
import APP_SETTINGS from '../appSettings';
import uuid from 'uuid/v1';

const {
  STAGES,
  UNITS,
  HARVEST_TYPES,
  DAYS
} = APP_SETTINGS;

export function calculateBatchMetrics({ isRockWool, harvest, disposal, growthCycle, raftSites, isComplete, QtyToSeed, revert, cycleDetail }) {
  let weight = 0;
  let harvestRafts = 0;
  let disposeRafts = 0;


  if (harvest && harvest.length > 0) {
    harvest.forEach(item => {
      if (item.weight) {
        weight += item.weight;
        harvestRafts += (item.units === UNITS.RAFTS ? item.count : item.units === UNITS.TRAYS ? item.rafts : Math.round((item.count / raftSites) * 10000) / 10000);
      }
    });
  }
  if (disposal && disposal.length > 0) {
    disposal.forEach(item => {
      disposeRafts += (item.units === UNITS.RAFTS ? item.count : item.units === UNITS.TRAYS ? item.rafts : Math.round((item.count / raftSites) * 10000) / 10000);
    });
  }

  const remainingTrays = isComplete ? 0 : isRockWool ? 0 : roundToHalf((QtyToSeed - harvestRafts - disposeRafts));
  const remainingRafts = isComplete ? 0 : isRockWool ? roundToHalf((cycleDetail?.fsEndRafts || growthCycle.fs.endRafts) - harvestRafts - disposeRafts) : 0;
  const estTotalBatchWeight = harvestRafts > 0 ? Math.round(((weight / harvestRafts) * ((cycleDetail?.fsEndRafts || growthCycle.fs.endRafts) - disposeRafts)) * 100) / 100 : null;
  const avgWeightPerHead = harvestRafts > 0 ? Math.round((weight / (harvestRafts * raftSites)) * 100) / 100 : null;
  const avgWeightPerHeadGrams = avgWeightPerHead ? Math.round((avgWeightPerHead / 0.0022046) * 100) / 100 : null;
  const totalRaftsHarvested = isComplete ? (cycleDetail?.fsEndRafts || growthCycle.fs.endRafts) - disposeRafts : null;
  const totalRaftsLogged = harvestRafts;
  const totalWeightLogged = weight;

  return { avgWeightPerHead, avgWeightPerHeadGrams, remainingRafts, remainingTrays, totalRaftsLogged, totalWeightLogged, totalRaftsHarvested, estTotalBatchWeight };
}
export function createBatchesFromRowData({ row, ind, rafts, varietyCode, isCommitted, varietyAlias, variety }) {
  console.log({ row, ind, varietyCode, isCommitted, varietyAlias });
  let batches = {};

  console.log('rowwwww',row)
  console.log('raftssssssssss',rafts)
  let GRFlot = getGRFlot({ initiationDate: row.initiationDate, varietyCode, varietyAlias });
  console.log('GRFlot2',GRFlot)

  // if (row.QtyToSeed) {
  //   //one raft 4 beds
  //   row.growthCycleObj.prop.endRafts = Number(row.QtyToSeed / 4).toString()
  // }
  if (!row.isPerpetual) {
    const cycleDetail = {
      germCells: row.germCells || 0,
      hdRafts: row.hdRafts || '',
      hdRemainder: row.hdRemainder || '',
      hdEndRafts: row.hdEndRafts || '',
      fsRafts: row.fsRafts || '',
      fsRemainder: row.fsRemainder || '',
      fsEndRafts: row.fsEndRafts || '',
    }
    const batches = {
      [ind]: createNewBatchObject({
        crop: row.crop,
        variety: row.variety,
        growthCycle: row.growthCycleObj,
        growthCycleID: row.growthCycle,
        initiationDate: row.initiationDate,
        seedDate: row.seedDate,
        isCommitted,
        cycleDetail: cycleDetail,
        GRFlot,
        propLocation: row.propLocation,
        propLocation1: row.propLocation1,
        finishingLocation: row.finishingLocation,
        isPerpetual: false,
        seedLot: variety ? variety.lotNo : '',
        assignUser: row.assignUser,
        QtyToSeed: row.QtyToSeed || '',
        harvestDate: row.harvestDate,
      })
    };

    return { batches, perpetualBatch: null };

  } else {
    // get all initiation dates in an array

    //////////////////////////////////////////////////////////////////////////////////////////////////
    const totalNoOfDays = moment(row.perpetualEndDate).diff(moment(row.seedDate), 'days');


    let perpetualBatch = {
      batches: [],
      initiationDate: row.initiationDate,
      perpetualSeedDays: row.perpetualSeedDays,
      perpetualEndDate: row.perpetualEndDate
    };
    const perpetualBatchID = uuid();

    let firstBatchSeedDate = moment(row.seedDate).format();
    const cycleDetail = {
      germCells: row.germCells || 0,
      hdRafts: row.hdRafts || '',
      hdRemainder: row.hdRemainder || '',
      hdEndRafts: row.hdEndRafts || '',
      fsRafts: row.fsRafts || '',
      fsRemainder: row.fsRemainder || '',
      fsEndRafts: row.fsEndRafts || '',
    }
    // because we need extra batch other then perpitual that user has entered.
    batches[`${ind}-${Object.keys(batches).length}`] = createNewBatchObject({
      crop: row.crop,
      variety: row.variety,
      growthCycle: row.growthCycleObj,
      growthCycleID: row.growthCycle,
      seedDate: row.seedDate,
      isCommitted,
      cycleDetail: cycleDetail,
      GRFlot,
      rafts,
      perpetualBatchID,
      isPerpetual: true,
      seedLot: variety ? variety.lotNo : '',
      assignUser: row.assignUser,
      propLocation: row.propLocation,
      propLocation1: row.propLocation1,
      finishingLocation: row.finishingLocation,
      QtyToSeed: row.QtyToSeed || '',
      initiationDate: row.initiationDate,
      harvestDate: row.harvestDate,
    });
    perpetualBatch.batches.push(`${ind}-${Object.keys(batches).length}`);
    ////// ////// ////// ////// ////// ////// ////// ////// ////// //////

    // for perpitual batches :

    const firsrtWeek = moment(row.initiationDate).week();

    let registerMonth = [];
    let registerMonthWeek = [];

    let seedDate = moment(row.seedDate).format();
    let validInitiationDate = false;

    let currentSeedDate = moment(seedDate).format();
    let nextSeedDate = row.RepeatType > 4 ?  moment(seedDate).add(1,'month').format()  : moment(seedDate).add((row.RepeatType - 1) * 7, 'days').format();
    for (let i = 0; i <= totalNoOfDays; i++) {

      validInitiationDate = false;
      const dayToCheck = moment(row.seedDate).add(i, 'days').day();
      let initiationDate = moment(row.seedDate).add(i, 'days').format();
      const currentMonth = moment(row.seedDate).add(i, 'days').month();
      const sameYear = moment(initiationDate).year() === moment().year();


      console.log('ceckLoop>>', initiationDate, currentSeedDate, nextSeedDate)

      const initiationDateWeek = moment(initiationDate).week();
      const lastWeekOfYear = (currentMonth === 11) && (initiationDateWeek === 1);
      // for the last week of the year as moment gives week 1 for last week
      let { perpetualStartDays, RepeatType } = row;

      if (moment(initiationDate).isAfter(nextSeedDate)) {
        currentSeedDate = moment(nextSeedDate).format();
        nextSeedDate = RepeatType > 4 ?  moment(nextSeedDate).add(1,'month').format() : moment(nextSeedDate).add((RepeatType - 1) * 7, 'days').format()
      }

      if (perpetualStartDays[DAYS[dayToCheck]]) {
        validInitiationDate = true;
        seedDate = currentSeedDate

        if (RepeatType === 2) {
          // Every Week
        }
        else if ((RepeatType === 3) && ((initiationDateWeek - firsrtWeek) % 2 === 0)) {
          // Every Other Week
          console.log('initiationDateinitiationDate', initiationDate)
        }
        else if ((RepeatType === 4) && ((initiationDateWeek - firsrtWeek) % 3 === 0)) {
          // Every Three Week
        }
        else if ((RepeatType === 5) && ((initiationDateWeek - firsrtWeek) % 4 === 0) && (registerMonth.indexOf(currentMonth) === -1) && (registerMonthWeek.indexOf(initiationDateWeek) !== -1)) {
          // Once in month
          registerMonth.push(currentMonth);
          registerMonthWeek.push(initiationDateWeek)
        }
      }



      // DO NOT DELETE 
      // OLD YEAR CODE  BELOW ////////////////////////////////////////////////////////////////////////////////////////////////

      // if (row.RepeatType === 1) {
      //   validInitiationDate = true;
      // }
      // else if (row.RepeatType === 2 && row.perpetualSeedDays[DAYS[dayToCheck]] && (initiationDateWeek !== firsrtWeek)) {
      //   validInitiationDate = true;
      //   seedDate = initiationDate;
      // }
      // if ((row.RepeatType === 3 || row.RepeatType === 4) && row.perpetualSeedDays[DAYS[dayToCheck]] && (initiationDateWeek !== firsrtWeek)) {  // 1 for next week // %3 and  +1 for alternate 3 weeks  
      //   if (row.RepeatType === 3 && (initiationDateWeek - firsrtWeek - 1) % 2 === 0) {
      //     // for every other week repeatation type
      //     validInitiationDate = true;
      //     seedDate = initiationDate;
      //   }
      //   else if (row.RepeatType === 4 && ((initiationDateWeek - firsrtWeek - (lastWeekOfYear ? 0 : sameYear ? 1 : 0)) % 3 === 0)) {
      //     // for every three week repeatation type
      //     validInitiationDate = true;
      //     seedDate = initiationDate;
      //   }
      // }
      // else if (row.RepeatType === 5 && row.perpetualSeedDays[DAYS[dayToCheck]] && (initiationDateWeek !== firsrtWeek)) {
      //   if ((registerMonth.indexOf(currentMonth) === -1) || ((registerMonth.indexOf(currentMonth) !== -1) && (registerMonthWeek.indexOf(initiationDateWeek) !== -1))) {
      //     registerMonth.push(currentMonth);
      //     registerMonthWeek.push(initiationDateWeek)
      //     validInitiationDate = true;
      //     seedDate = initiationDate;
      //   }
      // }
      console.log('RepeatType1', initiationDateWeek, firsrtWeek, validInitiationDate, moment(seedDate).format('YYYY-MM-DD'), moment(initiationDate).format('YYYY-MM-DD'),)

      if (validInitiationDate && row.perpetualStartDays[DAYS[dayToCheck]] && moment(initiationDate).isSameOrAfter(moment(row.initiationDate))) {

        seedDate && console.log('ceckLoop>>1', seedDate)


        let harvestDate = calcHarvestDate({ initiationDate, growthCycle: row.growthCycleObj });
        if (moment(seedDate).isSame(firstBatchSeedDate) && moment(row.initiationDate).isSame(initiationDate)) { }
        else {
          console.log('ceckLoop>>2', seedDate)
          const cycleDetail = {
            germCells: row.germCells || 0,
            hdRafts: row.hdRafts || '',
            hdRemainder: row.hdRemainder || '',
            hdEndRafts: row.hdEndRafts || '',
            fsRafts: row.fsRafts || '',
            fsRemainder: row.fsRemainder || '',
            fsEndRafts: row.fsEndRafts || '',
          }
          batches[`${ind}-${Object.keys(batches).length}`] = createNewBatchObject({
            crop: row.crop,
            variety: row.variety,
            growthCycle: row.growthCycleObj,
            growthCycleID: row.growthCycle,
            isCommitted,
            rafts,
            cycleDetail: cycleDetail,
            GRFlot:getGRFlot({ initiationDate: initiationDate, varietyCode, varietyAlias }),
            perpetualBatchID,
            isPerpetual: true,
            seedLot: variety ? variety.lotNo : '',
            assignUser: row.assignUser,
            PropFarm: row.PropFarm,
            PropPond: row.PropPond,
            propLocation: row.propLocation,
            propLocation1: row.propLocation1,
            finishingLocation: row.finishingLocation,
            QtyToSeed: row.QtyToSeed || '',
            initiationDate,
            seedDate,
            harvestDate: harvestDate,
          });
        }
        perpetualBatch.batches.push(`${ind}-${Object.keys(batches).length}`);
      }
    }

    // OLD LOGIN  BELOW ////////////////////////////////////////////////////////////////////////////////////////////////

    // let initiationDateArr = [row.initiationDate];

    // for (let day in row.perpetualSeedDays) {
    //   if (row.perpetualSeedDays[day]) {
    //     let countStartDate;
    //     const dayOfWeekDiff = dayCounter - initiationDayOfWeek; // negative if before, positive if after initiationDayOfWeek
    //     if (dayOfWeekDiff >= 0) {
    //       countStartDate = moment(row.initiationDate).add(dayOfWeekDiff, 'days');
    //     } else {
    //       countStartDate = moment(row.initiationDate).subtract(Math.abs(dayOfWeekDiff), 'days').add(7, 'days');
    //     }
    //     // const daysInRange = moment(row.perpetualEndDate).diff(countStartDate, 'days');
    //     let batchCounter = dayOfWeekDiff === 0 ? 1 : 0;
    //     while (moment(countStartDate).add(batchCounter * 7, 'days').isSameOrBefore(row.perpetualEndDate)) {
    //       initiationDateArr.push(moment(countStartDate).add(batchCounter * 7, 'days').format('YYYY-MM-DD'));
    //       batchCounter++;
    //     }
    //   }
    //   dayCounter++;
    // }

    // // sort array by date
    // initiationDateArr.sort((a, b) => {
    //   if (moment(a).isBefore(b)) { return -1; }
    //   if (moment(a).isAfter(b)) { return 1; }
    //   return 0;
    // });

    // console.log({ initiationDateArr });

    // perpetualBatch = {
    //   batches: [],
    //   initiationDate: row.initiationDate,
    //   perpetualSeedDays: row.perpetualSeedDays,
    //   perpetualEndDate: row.perpetualEndDate
    // };
    // const perpetualBatchID = uuid();

    // for (let i = 0; i < initiationDateArr.length; i++) {


    //   const GRFlot = getGRFlot({ initiationDate: initiationDateArr[i], varietyCode });
    //   batches[`${ind}-${i}`] = createNewBatchObject({
    //     crop: row.crop,
    //     variety: row.variety,
    //     growthCycle: row.growthCycleObj,
    //     growthCycleID: row.growthCycle,
    //     initiationDate: initiationDateArr[i],
    //     seedDate: i === 0 ? row.seedDate : initiationDateArr[i],
    //     isCommitted,
    //     GRFlot,
    //     perpetualBatchID,
    //     isPerpetual: true,
    //     seedLot: variety ? variety.lotNo : '',
    //     assignUser: row.assignUser,
    //     PropFarm: row.PropFarm,
    //     PropPond: row.PropPond,
    //     propLocation: row.propLocation,
    //     finishingLocation: row.finishingLocation,
    //     QtyToSeed: row.QtyToSeed || '',
    //     seedDate: row.seedDate,
    //     harvestDate: row.harvestDate,
    //   });
    //   perpetualBatch.batches.push(`${ind}-${i}`);
    // }
    return { batches, perpetualBatch, perpetualBatchID };
  }
}
export function getGRFlot({ initiationDate, varietyCode, varietyAlias }) {
  const julianDate = fillNumberStringToLength(moment(initiationDate).dayOfYear(), 3);
  const finalCode = (varietyAlias || '') + varietyCode.concat(julianDate);
  return finalCode
}


export function calcTotalBatchesFromPerpetual({ initiationDate, perpetualEndDate, perpetualStartDays, RepeatType }) {

  console.log('calcTotalBatchesFromPerpetual', initiationDate, perpetualEndDate, perpetualStartDays, RepeatType);


  if (!initiationDate || !perpetualEndDate || !perpetualStartDays) {
    console.log('Error in calcTotalBatchesFromPerpetual: required input not received');
    console.log({ initiationDate, perpetualEndDate, perpetualStartDays });
    return 1;
  }

  let initiationDay = DAYS[moment(initiationDate).day()]


  let totalBatches = 0;
  const totalNoOfDays = moment(perpetualEndDate).diff(moment(initiationDate), 'days');


  const firsrtWeek = moment(initiationDate).week();

  for (let i = 0; i <= totalNoOfDays; i++) {
    const dayToCheck = moment(initiationDate).add(i, 'days').day();
    const weekToCheck = moment(initiationDate).add(i, 'days').week();
    const currentMonth = moment(initiationDate).add(i, 'days').month();
    // const currentDate = moment(initiationDate).add(i, 'days').format('DD MMM YYYY'); // for show only

    let registerMonth = [];

    if (perpetualStartDays[DAYS[dayToCheck]]) {
      if ((RepeatType === 3) && ((weekToCheck - firsrtWeek) % 2 === 0)) {
        ++totalBatches;
      }
      else if ((RepeatType === 4) && ((weekToCheck - firsrtWeek) % 3 === 0)) {
        ++totalBatches;
      }
      else if ((RepeatType === 5) && ((weekToCheck - firsrtWeek) % 4 === 0) && (registerMonth.indexOf(currentMonth) === -1)) {
        registerMonth.push(currentMonth);
        ++totalBatches;
      }
      else {
        ++totalBatches;
      }
    }
  }

  return perpetualStartDays[initiationDay] ? totalBatches - 1 : totalBatches + 1;
  //this logic is to avoid repetation of batches 
}



function createNewBatchObject({ crop, rafts, cycleDetail, variety, growthCycle, growthCycleID, initiationDate, seedDate, isCommitted, GRFlot, isPerpetual, assignUser, propLocation, propLocation1, finishingLocation, seedLot, QtyToSeed, harvestDate }) {
  growthCycle = recalcDatesInGrowthCycle(growthCycle, initiationDate, seedDate);
  let newBatch = {
    crop,
    variety,
    growthCycle,
    growthCycleID,
    isCommitted,
    GRFlot,
    isPerpetual,
    assignUser,
    seedLot,
    propLocation,
    propLocation1,
    finishingLocation,
    QtyToSeed: QtyToSeed || '',
    harvestDate,
    seedDate,
    initiationDate,
  };
  if (isCommitted) {
    const tasks = generateTasks({ GRFlot,germCells: cycleDetail?.germCells, cycleDetail, crop: crop, variety: variety, growthCycleObj: growthCycle, assignUser, QtyToSeed });
    newBatch = {
      ...newBatch,
      tasks,
      harvest: [],
      disposal: [],
      harvestDate: growthCycle.harvestType === HARVEST_TYPES.REPEAT ? [] : harvestDate,
      current: { stage: STAGES.PLANNED, cells: cycleDetail?.germCells },
      cycleDetail
    };
    // let hdRaft = rafts[growthCycle.hd.raftType];
    // let fsRaft = rafts[growthCycle.fs.raftType];
    // let hdRafts = hdRaft && germCells ? (germCells / hdRaft.sites) : '';
    // let hdEndRaft = hdRaft && germCells ? (germCells / hdRaft.sites) : '';
    // let hdRem = hdRafts ? germCells - (hdRafts * hdRaft.sites) : '';
    // let hdEndSites = hdRafts ? (hdEndRaft || growthCycle.hd.endRafts) * hdRaft.sites : '';


    // let fsRafts = '';
    // let fsEndRaft = '';
    // let fsRem = '';
    // if (germCells && fsRaft) {
    //   fsRafts = (growthCycle.hd.days && hdRaft && growthCycle.hd.endRafts) ? Math.floor(hdEndSites / fsRaft.sites) :
    //     Math.floor(germCells / fsRaft.sites);
        

    //   fsEndRaft = (growthCycle.hd.days && hdRaft && growthCycle.hd.endRafts) ? (hdEndSites / fsRaft.sites) :
    //     (germCells / fsRaft.sites);
    //   fsRem = (growthCycle.hd.days && hdRaft && growthCycle.hd.endRafts) ? hdEndSites - (fsRafts * fsRaft.sites) :
    //     germCells - (fsRafts * fsRaft.sites);
    // }


    console.log("testttttttraftttt",newBatch)
  }
  return newBatch;
}

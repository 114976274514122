import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core';
import { sortFunction } from '../utils/utils';
import classNames from 'classnames';

const styles = theme => ({
  legendContainer: {
    padding: '1rem 1.5rem',
    // border: '1px solid',
    borderColor: theme.palette.grey[400],
    borderRadius: '5px',
  },
  legendRow: {
    marginBottom: '0.5rem',
    '&:last-of-type': {
      marginBottom: 0
    },
  },
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  colorSwatch: {
    minWidth: 20,
    height: 20,
    borderRadius: 2,
    marginRight: '0.7rem'
  },
  varietyName: {
    color: theme.palette.grey[700],
    fontSize: '1rem',
    lineHeight: '20px'
  }
});

const PlotLegend = props => {
  const { classes, className, legend, orientation, batches, varieties } = props;

  // let uniqueVariety = new Array();

  // for (const key in legend) {

  // }
  // legend.forEach(each => {
  //   if (batches[each] && uniqueVariety.indexOf(batches[each].variety) === -1) {
  //     uniqueVariety.push(batches[each].variety)
  //   }
  // });

  return (
    <Grid
      container
      direction="row"
      item
      className={classNames(className, classes.legendContainer)}
    >
      {Object.keys(legend).length && Object.keys(legend).map((variety,i) => (
        <Grid
          key={i}
          item
          xs={2}
          className={classes.legendRow}
          style={{
            marginRight: orientation === 'vertical' ? 0 : '2rem',
            maxWidth: orientation === 'vertical' ? '100%' : 'max-content'
          }}
        >
          <div className={classes.container}>
            <div className={classes.colorSwatch} style={{ backgroundColor: legend[variety].color }}></div>
            <Typography variant="body2" className={classes.varietyName}>{legend[variety].variety}</Typography>
          </div>
        </Grid>
      ))}
    </Grid>
  );
};

PlotLegend.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  legend: PropTypes.array,
  orientation: PropTypes.string.isRequired,
  batches: PropTypes.object.isRequired,
  varieties: PropTypes.object.isRequired,

}

export default withStyles(styles)(PlotLegend);
import { LOAD_GROWTH_CYCLES, ADD_EDIT_GROWTH_CYCLE, DELETE_GROWTH_CYCLE } from '../actionTypes';
import { deepCopyObject } from '../../utils/utils';

export default (state = {}, action) => {
  switch (action.type) {
    case LOAD_GROWTH_CYCLES:
      return {...action.growthCycles};

    case ADD_EDIT_GROWTH_CYCLE:
      // merges received key-val pairs with existing ones for the item in question
      const key = Object.keys(action.growthCycle)[0];
      let currGrowthCycleObj = state[key] ? deepCopyObject(state[key]) : {};
      currGrowthCycleObj = {...currGrowthCycleObj, ...action.growthCycle[key]};
      return {...state, [key]: currGrowthCycleObj};

    case DELETE_GROWTH_CYCLE:
      let newState = deepCopyObject(state);
      delete newState[action.id];
      return newState;

    default:
      return state;
  }
};
import React from 'react';
import PropTypes from 'prop-types';
import GrowCurrentSort from '../components/GrowCurrentSort';
import BatchCard from '../components/BatchCard';
import CurrentBatchModal from '../components/CurrentBatchModal';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import { DatePicker } from 'material-ui-pickers';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import MomentUtils from '@date-io/moment';
import Modal from '@material-ui/core/Modal';
import ModalManager from '../components/ModalManager';
import Snackbar from '@material-ui/core/Snackbar';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Masonry from 'react-masonry-component';
import moment from 'moment';
import { withStyles } from '@material-ui/core';
import { addEditBatch, addReason, addEditItem, pushLowInventoryItem } from '../store/actions/adders';
import { deleteBatch, removeInventoryAlert } from '../store/actions/deleters';
import { connect } from 'react-redux';
import { sortFunction, deepCopyObject, objectsAreEqual, checkNull } from '../utils/utils';
import APP_SETTINGS from '../utils/appSettings';
import { checkHasBeenUsed } from '../utils/appFunctions/setup';
import UserContext from '../context_providers/UserContext';

const { COLLECTIONS, TASK_TYPES, UNITS, STAGES } = APP_SETTINGS;

const styles = theme => ({
  sortBar: {
    marginBottom: '1rem'
  },
  seedScheduleRow: {
    marginBottom: '1rem',
    minHeight: 70
  },
  topButton: {
    marginRight: '1.5rem',
    minHeight: 49
  },
  buttonIcon: {
    marginRight: '0.6rem'
  },
  seedDate: {
    marginTop: 0
  },
  title: {
    marginBottom: '1rem',
  },
  cropContainer: {
    marginBottom: '1rem',
    [theme.breakpoints.up('xs')]: {
      width: '100%'
    },
    [theme.breakpoints.up('lg')]: {
      minWidth: '50%',
      paddingRight: '3%'
    }
  },
  batchCard: {
    width: '100%',
    marginBottom: '1rem'
  }
});


class GrowCurrent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      batchIDList: [],
      suggestionList: [],
      modalOpen: false,
      currentModal: '',
      currentBatch: '',
      snackbarOpen: false,
      snackbarMessage: '',
      snackBarUndo: false,
      dialogOpen: false,
      dialogMessage: '',
      currentDialog: '',
      dialogButtons: [],
      editsMade: false,
      selectedBatches: [],
      seedDate: null,
      selectAllIsChecked: false,
      isSettingSeedDate: false
    };
    this.handleBatchClick = this.handleBatchClick.bind(this);
    this.handleSelectAll = this.handleSelectAll.bind(this);
    this.handleScheduleSeedDate = this.handleScheduleSeedDate.bind(this);
    this.handleSeedDateChange = this.handleSeedDateChange.bind(this);
    this.handleBatchSelect = this.handleBatchSelect.bind(this);
    this.setEditsMade = this.setEditsMade.bind(this);
    this.saveBatchEdits = this.saveBatchEdits.bind(this);
    this.saveBatchEdits1 = this.saveBatchEdits1.bind(this);
    this.deleteBatch = this.deleteBatch.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.closeSnackBar = this.closeSnackBar.bind(this);
    this.renderModal = this.renderModal.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.handleExport = this.handleExport.bind(this);
    this.saveBatchNotes = this.saveBatchNotes.bind(this);
  }

  promisedSetState = (newState) => {
    return new Promise((resolve) => {
      this.setState(newState, () => {
        resolve()
      });
    });
  }

  componentDidMount() {
    this.buildBatchIDList();
    this.buildSuggestionList();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.filterState.currentFilter !== this.props.filterState.currentFilter ||
      prevProps.filterState.currentSort !== this.props.filterState.currentSort ||
      prevProps.filterState.currentSearch !== this.props.filterState.currentSearch ||
      prevProps.filterState.currentStartDate !== this.props.filterState.currentStartDate ||
      prevProps.filterState.currentEndDate !== this.props.filterState.currentEndDate ||
      prevProps.batches !== this.props.batches
    ) {
      this.buildBatchIDList();
    }

    if (
      !objectsAreEqual(prevProps.crops, this.props.crops) ||
      !objectsAreEqual(prevProps.varieties, this.props.varieties) ||
      !objectsAreEqual(prevProps.batches, this.props.batches)
    ) {
      this.buildSuggestionList();
    }
  }

  buildBatchIDList() {
    const { batches, filterState } = this.props;

    // parse search result
    let searchTerm = null;
    if (filterState.currentSearch) {
      searchTerm = this.state.suggestionList.find(item => item.label === filterState.currentSearch);
    }

    // filter and sort Batches based on user selections
    const batchSortArr = filterState.currentSort.split('-');
    const batchIDList = Object.keys(batches)
      .filter(batchID => this.filterBatches(batches[batchID], searchTerm))
      .sort((a, b) => batchSortArr[0] === 'initiationDate' ?
        sortFunction(batches[a].growthCycle.germ, batches[b].growthCycle.germ, batchSortArr[1], 'date') :
        sortFunction(batches[a], batches[b], batchSortArr[1], batchSortArr[0])
      );
    this.setState({ batchIDList });
  }

  filterBatches(batch, searchItem) {
    let filterIsTrue = true;
    let searchIsTrue = true;
    let dateFilterIsTrue = true;
    switch (this.props.filterState.currentFilter) {
      case STAGES.COMPLETED:
        filterIsTrue = batch.current.stage === STAGES.COMPLETED;
        break;

      case STAGES.PLANNED:
        filterIsTrue = batch.current.stage === STAGES.PLANNED;
        break;

      case 'current':
        filterIsTrue = batch.current.stage !== STAGES.COMPLETED && batch.current.stage !== STAGES.PLANNED;
        break;

      case 'all':
        filterIsTrue = true;
        break;

      default:
        filterIsTrue = true;
        break;
    }

    if (searchItem) {
      switch (searchItem.collection) {
        case COLLECTIONS.CROPS:
          searchIsTrue = batch.crop === searchItem.id;
          break;

        case COLLECTIONS.VARIETIES:
          searchIsTrue = batch.variety === searchItem.id;
          break;

        case COLLECTIONS.BATCHES:
          searchIsTrue = batch.GRFlot === searchItem.label;
          break;

        default:
          searchIsTrue = true;
          break;
      }
    }

    if (
      !moment(batch.growthCycle.germ.date).isSameOrAfter(this.props.filterState.currentStartDate, 'days') ||
      !moment(batch.growthCycle.germ.date).isSameOrBefore(this.props.filterState.currentEndDate, 'days')
    ) {
      dateFilterIsTrue = false;
    }

    return filterIsTrue && searchIsTrue && dateFilterIsTrue;
  }

  buildSuggestionList() {
    // create list for search box on Current tab
    const { crops, varieties, batches } = this.props;
    let suggestionList = [];
    Object.keys(crops).forEach(cropID => {
      suggestionList.push({ label: crops[cropID].name, collection: COLLECTIONS.CROPS, id: cropID });
    });
    Object.keys(varieties).forEach(varID => {
      suggestionList.push({ label: varieties[varID].name, collection: COLLECTIONS.VARIETIES, id: varID });
    });
    Object.keys(batches).forEach(batchID => {
      suggestionList.push({ label: batches[batchID].GRFlot, collection: COLLECTIONS.BATCHES, id: batchID });
    });

    this.setState({ suggestionList });
  }

  handleBatchClick(id) {
    this.setState({ modalOpen: true, currentModal: 'batch', currentBatch: id });
  }

  handleSelectAll = e => {
    const value = e.target.checked;


    this.setState({ selectAllIsChecked: value });
    if (value === true) {
      let selectedBatches = this.state.batchIDList.slice();
      // remove batches that have an initiationDate already
      // selectedBatches.forEach((batchID) => {
      //   if (this.props.batches[batchID].initiationDate) {
      //     selectedBatches.splice(selectedBatches.indexOf(batchID), 1);
      //   }
      // });
      this.setState({ selectedBatches: selectedBatches });
    } else {
      this.setState({ selectedBatches: [] });
    }
  }

  async handleScheduleSeedDate() {
    let { selectedBatches } = this.state;

    // if any batch has an initiationDate before the seedDate, disallow
    let seedDateValidated = true;
    for (let i = 0; i < selectedBatches.length; i++) {
      const batchID = selectedBatches[i];
      const initiationDate = this.props.batches[batchID].growthCycle.germ.date;
      if (moment(this.state.seedDate).isAfter(initiationDate, 'days')) {
        this.setState({
          dialogOpen: true,
          dialogMessage: 'Cannot make Seed Date after the Start Date of any selected batch.',
          currentDialog: 'seedDateWarning',
          dialogButtons: [{ label: 'Okay', answer: 'okay', color: 'primary' }]
        });
        seedDateValidated = false;
        break;
      }
    }

    // assign new seedDate to each batch
    if (seedDateValidated) {
      await this.promisedSetState({ isSettingSeedDate: true });
      setTimeout(async () => {
        for (let i = 0; i < selectedBatches.length; i++) {
          const batchID = selectedBatches[i];
          const batch = this.props.batches[batchID];
          const tasks = this.getTasksForBatch(batch, this.state.seedDate);
          await addEditBatch({
            batch: {
              tasks,
              growthCycle: {
                ...batch.growthCycle,
                seed: { date: this.state.seedDate }
              }
            },
            id: batchID
          });
        }
        this.setState({
          snackbarOpen: true,
          snackbarMessage: 'Seed dates scheduled',
          selectedBatches: [],
          selectAllIsChecked: false
        });
      }, 1);
    }
  }

  getTasksForBatch(batch, seedDate) {
    const { crop, variety, GRFlot, growthCycle } = batch;
    let tasks = batch.tasks;
    const germCells = batch?.cycleDetail?.germCells?growthCycle.germ.cells:"";
    // handle seed/start vs seed&start
    if (seedDate === growthCycle.germ.date) {
      // remove seed and start if found; add seedAndStart if needed
      delete tasks.seed;
      delete tasks.start;

      tasks.seedAndStart = {
        date: growthCycle.germ.date,
        type: TASK_TYPES.SEED_AND_START,
        crop, variety, GRFlot,
        quant: germCells,
        unit: UNITS.CELLS,
        isNext: true
      };
    } else {
      // remove seedAndStart if found; add seed and start if needed
      delete tasks.seedAndStart;
      tasks.seed = {
        date: seedDate,
        type: TASK_TYPES.SEED,
        crop, variety, GRFlot,
        quant: germCells,
        unit: UNITS.CELLS,
        isNext: true
      };
      tasks.start = {
        date: growthCycle.germ.date,
        type: TASK_TYPES.START,
        crop, variety, GRFlot,
        quant: germCells,
        unit: UNITS.CELLS
      };
    }
    return tasks;
  }

  handleBatchSelect = id => e => {
    let selectedBatches = this.state.selectedBatches.slice();
    if (e.target.checked) {
      selectedBatches.push(id);
    } else {
      selectedBatches.splice(selectedBatches.indexOf(id), 1);
    }
    this.setState({ selectedBatches });
  }

  handleSeedDateChange = id => date => {
    if (id === 'seedDate') {
      const value = date ? moment(date).format('YYYY-MM-DD') : null;
      this.setState({ seedDate: value });
      return;
    }

    const initiationDate = this.props.batches[id].growthCycle.germ.date;
    if (moment(date).isAfter(initiationDate, 'days')) {
      this.setState({
        dialogOpen: true,
        dialogMessage: 'Cannot make Seed Date after Start Date.',
        currentDialog: 'seedDateWarning',
        dialogButtons: [{ label: 'Okay', answer: 'okay', color: 'primary' }]
      });
      return;
    }
    const value = moment(date).format('YYYY-MM-DD');
    const batch = this.props.batches[id];
    const tasks = this.getTasksForBatch(batch, value);
    const growthCycle = {
      ...batch.growthCycle,
      seed: { date: value }
    };
    addEditBatch({ batch: { tasks, growthCycle }, id });
  }

  setEditsMade(bool) {
    this.setState({ editsMade: bool });
  }

  async saveBatchEdits(batch) {
    // add a new Reason(s) to the database if necessary
    const reasonList = batch.disposal.map(item => item.reason);
    reasonList.forEach(reason => {
      if (!this.props.misc.reasons.includes(reason)) {
        addReason(reason);
      }
    });
    console.log("checkkkk333",batch, this.state.currentBatch);
    await addEditBatch({ batch, id: this.state.currentBatch });
    this.setState({ snackbarOpen: true, snackbarMessage: 'Batch saved!', snackBarUndo: false });
  }

  async saveBatchNotes(batch, batchID) {
    console.log("checkkkk444",batch, batchID);
    await addEditBatch({ batch, id: batchID });
    this.setState({ snackbarOpen: true, snackbarMessage: 'Batch saved!', snackBarUndo: false });
  }

  async saveBatchWithoutGrowthCycle(batch, batchID) {
    let shouldUpdate = false;
    if(batch.variety && this.props.varieties[batch.variety] && this.props.varieties[batch.variety].growthCycles[0]){
      shouldUpdate = true;
      batch.growthCycleID = this.props.varieties[batch.variety].growthCycles[0];
    }else if(this.props.varieties && Object.keys(this.props.varieties)[0]){
      const varietieKey = Object.keys(this.props.varieties)[0];
      if(this.props.varieties[batch.variety].growthCycles[0]){
        shouldUpdate = true;
        batch.variety = varietieKey;
        batch.growthCycleID = this.props.varieties[batch.variety].growthCycles[0];
      }
    }
    console.log("saveBatchWithoutGrowthCycle",batch, Object.keys(this.props.varieties)[0]);
    if(shouldUpdate){
      await addEditBatch({ batch, id: batchID });
    }
  }

  async saveBatchEdits1(batch) {
    // add a new Reason(s) to the database if necessary
    const reasonList = batch.disposal.map(item => item.reason);
    reasonList.forEach(reason => {
      if (!this.props.misc.reasons.includes(reason)) {
        addReason(reason);
      }
    });

    await addEditBatch({ batch, id: this.state.currentBatch });
    // this.setState({snackbarOpen: true, snackbarMessage: 'Batch saved!', snackBarUndo: false});
  }

  async deleteBatch() {
    const batch = this.props.batches[this.state.currentBatch]
    const variety = this.props.varieties[batch.variety];

    // delete from db
    await deleteBatch(this.state.currentBatch);

    // check if any other batches use the crop, variety, growthCycle used in this one; update those items they are no longer used
    const cropIsUsed = checkHasBeenUsed({ collectionSingular: 'crop', collectionItemID: batch.crop, batches: this.props.batches, currentBatchID: this.state.currentBatch });
    const varietyIsUsed = checkHasBeenUsed({ collectionSingular: 'variety', collectionItemID: batch.variety, batches: this.props.batches, currentBatchID: this.state.currentBatch });
    const growthCycleIsUsed = checkHasBeenUsed({ collectionSingular: 'growthCycle', collectionItemID: batch.growthCycleID, batches: this.props.batches, currentBatchID: this.state.currentBatch });

    if (!cropIsUsed) {
      addEditItem({ collection: COLLECTIONS.CROPS, id: batch.crop, item: { hasBeenUsed: false } });
    }
    if (!varietyIsUsed) {
      addEditItem({ collection: COLLECTIONS.VARIETIES, id: batch.variety, item: { hasBeenUsed: false } });
    }
    if (!growthCycleIsUsed) {
      addEditItem({ collection: COLLECTIONS.GROWTH_CYCLES, id: batch.growthCycleID, item: { hasBeenUsed: false } });
    }

    // update stock for the variety
    const germCells = batch?.cycleDetail?.germCells?batch.growthCycle.germ.cells:"";
    const currentStock = variety.currentStock + germCells;
    let stockTransactions = variety.stockTransactions ? deepCopyObject(variety.stockTransactions) : [];
    stockTransactions.push({ date: moment().format('YYYY-MM-DD'), action: 'cancelBatch', quant: batch.growthCycle.germ.cells });
    await addEditItem({ collection: COLLECTIONS.VARIETIES, id: batch.variety, item: { currentStock, stockTransactions } });

    if (currentStock < variety.minStock) {
      await pushLowInventoryItem(batch.variety);
    } else if (currentStock >= variety.minStock) {
      await removeInventoryAlert(batch.variety)
    }
    this.setState({ modalOpen: false, currentModal: '', currentBatch: '', snackbarOpen: true, snackbarMessage: 'Batch deleted', snackBarUndo: false });
  }

  closeModal() {
    if (this.state.editsMade) {
      this.setState({
        dialogOpen: true,
        dialogMessage: 'You will lose any changes made if you close now. Are you sure?',
        currentDialog: 'close',
        dialogButtons: [{ label: 'Cancel', answer: 'no' }, { label: 'Okay', color: 'primary', answer: 'yes' }]
      });
    } else {
      this.setState({ modalOpen: false, currentModal: null, currentBatch: null });
    }
  }

  closeSnackBar() {
    this.setState({ snackbarOpen: false, snackbarMessage: '', snackBarUndo: false });
  }

  renderModal() {
    switch (this.state.currentModal) {
      case 'batch':
        const batch = this.props.batches[this.state.currentBatch];
        return (
          batch ?
            <CurrentBatchModal
              batchID={this.state.currentBatch}
              batches={this.props.batches}
              batch={batch}
              showTrays={!!batch.QtyToSeed}
              growthCycles={this.props.growthCycles}
              crop={this.props.crops[batch.crop]}
              variety={this.props.varieties[batch.variety]}
              reasons={this.props.misc.reasons}
              rafts={this.props.rafts}
              editsMade={this.state.editsMade}
              setEditsMade={this.setEditsMade}
              onSave={this.saveBatchEdits}
              onSaveHD={this.saveBatchEdits1}
              onClose={this.closeModal}
              onDelete={this.deleteBatch}
            />
            :
            <div></div>
        );
      default:
        return <div>This is not the modal you're looking for.</div>
    }
  }

  closeDialog(answer) {
    switch (this.state.currentDialog) {
      case 'close':
        if (answer === 'yes') {
          this.setState({
            modalOpen: false,
            currentModal: null,
            currentBatch: null,
            dialogOpen: false,
            dialogMessage: '',
            currentDialog: '',
            dialogButtons: []
          });
        }
        break;

      case 'seedDateWarning':
        break;

      default:
        break;
    }
    this.setState({ dialogOpen: false, dialogMessage: '', currentDialog: '', dialogButtons: [] });
  }

  handleExport() {
    // TODO
  }

  getPaginationContainer = (cropList) => {
    let res = null;
    const lastPage = Object.keys(cropList).length > 0 ? Math.ceil(Object.keys(cropList).length/this.props.pageSize):1;
    // console.log('lastPage',lastPage,Object.keys(cropList).length);
    if (
      checkNull(lastPage, false) &&
      lastPage > 1
    ) {
      const pagesArr = Array.from(
        { length: lastPage },
        (_, i) => i + 1
      );
      res = (
        <nav aria-label="Page navigation example">
          <ul class="pagination">
            {pagesArr.map((pageNo) => {
              return (
                <li
                  class={`page-item${
                    pageNo == this.props.pageNo
                      ? " active"
                      : ""
                  }`}
                  onClick={() => {
                    if (
                      !(pageNo == this.props.pageNo)
                    ) {
                      this.props.handlePageNoChange("current",pageNo)
                    }
                  }}
                >
                  <a class="page-link">{pageNo}</a>
                </li>
              );
            })}
          </ul>
        </nav>
      );
    }
    return res;
  };

  render() {
    const { classes, crops, varieties, batches, growthCycles, filterState, handleChange, formatDate, handleSearchSelect, farm, pageNo, pageSize, handlePageSizeChange } = this.props;

    // sort Batches into crop buckets
    let cropList = []; // used as an ordering index for crops
    let cropListObj = {}; // keys are crop names, values are [batchID]
    this.state.batchIDList.length > 0 && this.state.batchIDList.forEach(batchID => {
      const batch = batches[batchID];

      if (batch && crops[batch.crop]) {
        const cropName = crops[batch.crop].name;
        if (!cropList.includes(cropName)) {
          cropList.push(cropName);
          cropListObj[cropName] = [];
        }
        cropListObj[cropName].push(batchID);
      }
    });
    cropList.sort();
    return (
      <div>
        <GrowCurrentSort
          className={classes.sortBar}
          sort={filterState.currentSort}
          filter={filterState.currentFilter}
          startDate={filterState.currentStartDate}
          endDate={filterState.currentEndDate}
          handleChange={handleChange}
          handleExport={this.handleExport}
          onSearchSelect={handleSearchSelect}
          suggestionList={this.state.suggestionList}
          pageSize={pageSize}
          handlePageSizeChange={handlePageSizeChange}
          formatDate={formatDate}
        />

        <Grid container spacing={16} className={classes.seedScheduleRow}>
          <Grid item xs={6} md={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.selectAllIsChecked}
                  onChange={this.handleSelectAll}
                  value="selectAll"
                  indeterminate={!this.state.selectAllIsChecked ? false : this.state.selectedBatches.length < this.state.batchIDList.length}
                />
              }
              label={this.state.selectAllIsChecked ? 'De-Select All' : 'Select All Visible'}
            />  
          </Grid>
          {this.state.selectedBatches.length > 0 &&
            <React.Fragment>
              <Grid item xs={6} md={2}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DatePicker
                    label="Seed Date"
                    variant="outlined"
                    value={this.state.seedDate}
                    format="MM/DD/YYYY"
                    mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                    onChange={(date) => this.handleSeedDateChange('seedDate')(date)}
                    animateYearScrolling
                    autoOk
                    margin="dense"
                    fullWidth
                    className={classes.seedDate}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} md={6}>
                <Button variant="outlined" size="small" color="primary" className={classes.topButton} onClick={this.handleScheduleSeedDate}>
                  <i className={`${classes.buttonIcon} far fa-calendar fa-2x`}></i>
                  Schedule Selected for Seeding
                </Button>
              </Grid>
            </React.Fragment>
          }
        </Grid>


        <Masonry>
          {cropList && cropList.map(cropName => {
            return (
              <>
                <Grid item xs={12} lg={6} key={cropName} className={classes.cropContainer}>
                  <Typography variant="h5" className={classes.title}>{cropName}</Typography>
                  {cropListObj[cropName].slice(((pageNo-1) * pageSize), (pageNo) * pageSize).map(batchID => {
                    const batch = batches[batchID];
                    if(batch.growthCycleID && growthCycles[batch.growthCycleID]){
                      return (
                        <BatchCard
                          key={batchID}
                          batchID={batchID}
                          className={classes.batchCard}
                          batch={batch}
                          farm={farm}
                          growthCycleName={growthCycles[batch.growthCycleID].name}
                          varietyName={varieties[batch.variety].name}
                          avatarURL={varieties[batch.variety].avatarURL}
                          isSelected={this.state.selectedBatches.includes(batchID)}
                          isCheckDisabled={!!batch.seedDate}
                          handleCheckBox={this.handleBatchSelect(batchID)}
                          handleClick={() => this.handleBatchClick(batchID)}
                          handleDateChange={this.handleSeedDateChange(batchID)}
                          saveBatchNotes={this.saveBatchNotes}
                        />
                      )
                    } else{
                      this.saveBatchWithoutGrowthCycle(batch, batchID);
                    }              
                  })}
                </Grid>
                <Grid item xs={12} lg={12} key={`${cropName}_pag`} className={classes.cropContainer}>
                  {this.getPaginationContainer(cropListObj[cropName])}
                </Grid>
              </>
            );
          })}
        </Masonry>

        <Modal
          aria-labelledby="modal-title"
          open={this.state.modalOpen}
          onClose={this.closeModal}
          manager={new ModalManager()}
          disableEnforceFocus
        >
          {this.renderModal()}
        </Modal>

        <Dialog
          open={this.state.dialogOpen}
          onClose={this.closeDialog}
          aria-describedby="confirmation-dialog"
        >
          <DialogContent>
            <DialogContentText id="confirmation-dialog">
              {this.state.dialogMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {this.state.dialogButtons.map(button => (
              <Button key={button.label} onClick={() => this.closeDialog(button.answer)} color={button.color} autoFocus={button.autoFocus}>
                {button.showLoader ?
                  (button.showLoader ?
                    <CircularProgress className={classes.submitButtonLoader} size={21} />
                    :
                    button.label
                  )
                  :
                  button.label
                }
              </Button>
            ))}
          </DialogActions>
        </Dialog>

        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={this.state.snackbarOpen}
          autoHideDuration={3000}
          onClose={this.closeSnackBar}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{this.state.snackbarMessage}</span>}
          action={this.state.snackBarUndo ?
            [
              <Button key="undo" color="primary" size="small" onClick={this.handleUndo}>UNDO</Button>,
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                className={classes.close}
                onClick={this.closeSnackBar}
              >
                <i className="fas fa-times"></i>
              </IconButton>
            ]
            :
            [
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                className={classes.close}
                onClick={this.closeSnackBar}
              >
                <i className="fas fa-times"></i>
              </IconButton>
            ]
          }
        />


      </div>
    )
  }
}

GrowCurrent.contextType = UserContext;

GrowCurrent.propTypes = {
  classes: PropTypes.object.isRequired,
  filterState: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSearchSelect: PropTypes.func.isRequired,
  crops: PropTypes.object.isRequired,
  varieties: PropTypes.object.isRequired,
  growthCycles: PropTypes.object.isRequired,
  rafts: PropTypes.object.isRequired,
  misc: PropTypes.object.isRequired,
  batches: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    crops: state.crops,
    varieties: state.varieties,
    growthCycles: state.growthCycles,
    rafts: state.rafts,
    misc: state.misc,
    batches: state.batches,
    farm: state.farm,
  }
}

export default withStyles(styles)(connect(mapStateToProps)(GrowCurrent));
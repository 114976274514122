import { ADD_EDIT_PERPETUAL_BATCH, DELETE_PERPETUAL_BATCH } from '../actionTypes';
import { deepCopyObject } from '../../utils/utils';

export default (state = {}, action) => {
  switch (action.type) {
    case ADD_EDIT_PERPETUAL_BATCH:
      // merges received key-val pairs with existing ones for the item in question
      const key = Object.keys(action.perpetualBatch)[0];
      let currBatchObj = state[key] ? deepCopyObject(state[key]) : {};
      currBatchObj = {...currBatchObj, ...action.perpetualBatch[key]};
      return {...state, [key]: currBatchObj};

    case DELETE_PERPETUAL_BATCH:
      let newState = deepCopyObject(state);
      for (let key in newState) {
        let [id] = key.split('-');
        if (id === action.id) {
          delete newState[action.id];
        }
      }
      return newState;
  
    default:
      return state;
  }
};
import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import { DatePicker } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';

const styles = theme => ({
  mainContainer: {
    width: 205
  },
  arrowContainer: {},
  icon: {
    fontSize: '1.5rem',
    cursor: 'pointer',
    color: theme.palette.grey[600]
  },
  input: {
    maxWidth: 155
  }
});

const DateSelector = props => {
  const {classes, className, value, onChange, onArrowClick} = props;
  return (
    <Grid container justify="space-between" alignItems="center" className={classNames(className, classes.mainContainer)}>
      <div className={classes.arrowContainer}>
        <i className={`fas fa-caret-left ${classes.icon}`} onClick={() => onArrowClick('left')}></i>
      </div>
      
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DatePicker
          value={value}
          format="ddd MM/DD/YYYY"
          mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
          onChange={(date) => onChange(date)}
          animateYearScrolling
          autoOk
          clearable
          margin="dense"
          variant="outlined"
          className={classes.input}
        />
      </MuiPickersUtilsProvider>

      <div className={classes.arrowContainer}>
        <i className={`fas fa-caret-right ${classes.icon}`} onClick={() => onArrowClick('right')}></i>
      </div>
    </Grid>
  );
};

DateSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onArrowClick: PropTypes.func.isRequired
};

export default withStyles(styles)(DateSelector);
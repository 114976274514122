import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Checkbox, Divider, FormControl, Grid, InputBase, ListItem, ListItemText, MenuItem, Modal, Paper, Select, Tab, Tabs, Typography, withStyles } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import { CSVLink } from "react-csv";
import moment from 'moment'
import ApiCalendar from 'react-google-calendar-api';
import InputLabel from '@material-ui/core/InputLabel';
import Icon from '@material-ui/core/Icon';
import DeleteIcon from '@material-ui/icons/Delete';
import red from '@material-ui/core/colors/red';
import FullScreenLoader from '../components/FullScreenLoader';

import { objectsAreEqual, sortFunction } from '../utils/utils';
import APP_SETTINGS from '../utils/appSettings';
import { updateBatchesForTaskCompletion, updateVarietiesForTaskCompletion } from '../utils/appFunctions/tasks';
import UserContext from '../context_providers/UserContext';
import { addEditBatch, addEditButton, addEditItem, changeTaskStatus, deleteButton, getButtonList, pushLowInventoryItem } from '../store/actions/adders';
import { deleteLowInventoryItem } from '../store/actions/deleters';
import AddButtonModal from '../components/AddButtonModal';


const {
  TASK_NAMES,
  TASK_TYPES,
  COLLECTIONS
} = APP_SETTINGS;

const icons = {
  seed: 'fas fa-seedling',
  transfer: 'fas fa-arrow-right',
  start: 'fas fa-tint',
  seedAndStart: 'fas fa-tint',
  harvest: 'fas fa-cut'
};

const taskNames = {
  seed: 'Seed',
  transfer: 'Transfer to',
  start: 'Start',
  'seedAndStart': 'Seed & Start',
  harvest: 'Harvest'
};


const destinations = {
  prop: 'Prop',
  hd: 'HD',
  fs: 'FS'
};

const styles = theme => ({
  iconContainer: {
    textAlign: 'center',
    width: '100%',
    '& i': {
      color: theme.palette.secondary.main,
      lineHeight: '45px'
    },
    '&:last-child': {
      marginBottom: '-0.3rem'
    }
  },
  rate: {
    height: 25,
    width: 25,
    borderRadius: 12.5,
    backgroundColor: '#42b883',
    cursor: 'pointer',
    marginLeft: 10,
    justifyContent: 'center',
  },
  rateNumber: {
    color: '#fff',
    marginTop: 2.5
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  iconSmall: {
    fontSize: 20,
  },
  delete: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
})

const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing.unit * 3,
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    width: 'auto',
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);


class Home extends React.Component {

  static contextType = UserContext
  constructor(props) {
    super(props);
    this.state = {
      newTaskList: [],
      collapse: '',
      collapse1: '',
      selectedTask: [],
      selectedAssignTask: [],
      data: [],
      showAddButton: false,
      buttonList: [],
      mode: '',
      type: 1,

      name: '',
      link: '',
      isSaving: false,
      errors: {
        name: false
      },
      selectedId: '',
      tab: 1,
      showCompleted: false
    }
  }


  async componentDidMount() {
    await Promise.all([this.createNewTaskList(this.props), this.getButtons()]).then(resp => {
      this.setState({isLoaded: true})
    });
  }

  getButtons = () => {
    getButtonList().then(buttonList => {
      let arr = buttonList.filter(each => each.userId === this.context.user.id);
      this.setState({ buttonList: arr })
    })
  }



  csvLink = React.createRef();

  shouldComponentUpdate(nextProps, nextState) {
    const propsEqual = !objectsAreEqual(this.props.tasks, nextProps.tasks)

    if (propsEqual) {
      this.createNewTaskList(nextProps)
    }
    return true;
  }

  handleItemClick = (e, name) => {
    if (name === 'sign-in') {
      ApiCalendar.handleAuthClick();
    } else if (name === 'sign-out') {
      ApiCalendar.handleSignoutClick();
    }
  }

  createNewTaskList = (props) => {
    const { tasks } = this.props;
    let newTaskList = [];
    if (!props.tasks) return
    for (const key in props.tasks) {
      const batch = key.split('-')[0];
      const stage = key.split('-')[1];
      const mainBatch = this.props.batches[batch];
      if (mainBatch && mainBatch.tasks) {
        for (const key1 in mainBatch.tasks) {
          const task = mainBatch.tasks[key1];
          if (task.isNext && stage === key1) {
            newTaskList.push(key)
          }
        }
      }
    }

    this.setState({ newTaskList: newTaskList.sort((a, b) => sortFunction(tasks[a], tasks[b], 'asc', 'date')) })
  }


  //   submitRating = async (rate) => {
  //     let { currentBatch, currentTask } = this.state;
  //     this.setState({ openRatingModal: false }, async () => {

  //         currentBatch.tasks[currentTask].rating = rate;
  //         await addEditBatch({ id: currentBatch.id, batch: currentBatch });
  //         this.props.closeModal()
  //     })
  // }


  completeAssignedTasks = async () => {

    const { selectedAssignTask } = this.state;

    selectedAssignTask.forEach(each => {
      let task = {
        user: this.context.user.id,
        groupId: each,
        newStatus: 'completed',
        completeTime: moment().format('MM/DD/YYYY HH:mm:ss')
        // + ' ' + new Date().getTimezoneOffset()
      }
      changeTaskStatus(task);
    })


    this.context.updateUser();

  }



  completeMarkedTasks = async (user) => {

    let taskList = [];
    let seedLotList = [];

    this.state.selectedTask.forEach(index => {
      taskList.push(this.state.newTaskList[index])
      if (this.state.newTaskList[index].seedLot) {
        seedLotList.push(this.state.newTaskList[index].seedLot)
      }
      else {
        const id = this.state.newTaskList[index].split('-');
        seedLotList.push(this.props.batches[id[0]].seedLot);
      }
    })
    const seedTasksList = this.state.selectedTask.filter(index => (
      this.props.tasks[this.state.newTaskList[index]] === TASK_TYPES.SEED ||
      this.props.tasks[this.state.newTaskList[index]] === TASK_TYPES.SEED_AND_START
    ));

    // funtion for getting list stucture of all task
    let updatedBatches = updateBatchesForTaskCompletion({
      taskList,
      user,
      taskCompletedDate: null,
      seedLotList,
      adjustGrowthCycle: false,
      growthCycles: this.props.growthCycles,
      varieties: this.props.varieties,
      batches: this.props.batches,
      rafts: this.props.rafts,
      maintasks: this.props.tasks
    });



    let updatedVarieties = updateVarietiesForTaskCompletion({
      taskList: seedTasksList,
      tasks: this.props.tasks,
      varieties: this.props.varieties
    });


    let undoState = {
      batches: {},
      varieties: {},
      growthCycles: {},
      newGrowthCycle: {
        id: null,
        parentID: null
      }
    };
    Object.keys(updatedBatches).forEach(batchID => {
      undoState.batches[batchID] = this.props.batches[batchID];
    });
    Object.keys(updatedVarieties).forEach(varID => {
      undoState.varieties[varID] = this.props.varieties[varID];
    });

    for (const key in this.props.tasks) {
      const [batchID, task] = key.split('-');
      if (updatedBatches[batchID] && this.props.tasks[key].rating) {
        updatedBatches[batchID].tasks[task].rating = this.props.tasks[key].rating;
      }
    }

    // update batch and variety in database
    for (let batchID in updatedBatches) {
      const batch = updatedBatches[batchID];
      await addEditBatch({
        id: batchID,
        batch
      });
    }

    for (let varID in updatedVarieties) {
      const variety = updatedVarieties[varID];
      await addEditItem({
        collection: COLLECTIONS.VARIETIES,
        id: varID,
        item: variety,
      });

      if (variety.trackStock && variety.currentStock < variety.minStock) {
        await pushLowInventoryItem(varID);
      } else {
        await deleteLowInventoryItem(varID);
      }
    }

    this.setState({
      newTaskList: new Array(),
      collapse: '',
      selectedTask: new Array()
    }, () => {
      this.createNewTaskList(this.props)
    })
  }


  onClickCheckBox = (i) => {
    let { selectedTask } = this.state;
    let indexOf = selectedTask.indexOf(i);
    if (indexOf === -1) selectedTask.push(i)
    else selectedTask.splice(indexOf, 1)
    this.setState({ selectedTask })
  }

  onClickCheckBox1 = (key) => {
    let { selectedAssignTask } = this.state;
    let indexOf = selectedAssignTask.indexOf(key);
    if (indexOf === -1) selectedAssignTask.push(key)
    else selectedAssignTask.splice(indexOf, 1)
    this.setState({ selectedAssignTask })
  }

  apiTest = () =>{

  }

  exportCSV = (list) => {

    let arr = [
      ["Seed Date", 'Start Date', 'Lot No.', "Variety", "Number to be seeded", "Date it is split to HD", "Date it is split to FS", "Harvest date", 'Finishing Location']
    ];

    list.forEach(batchId => {
      arr.push([
        this.props.batches[batchId].growthCycle.seed.date,
        moment(this.props.batches[batchId].initiationDate).format('YYYY-MM-DD'),
        this.props.batches[batchId].seedLot,
        this.props.varieties[this.props.batches[batchId].variety].name,
        this.props.batches[batchId].current.cells,
        this.props.batches[batchId].growthCycle.hd.date,
        this.props.batches[batchId].growthCycle.fs.date,
        this.props.batches[batchId].growthCycle.harvest.date,
        this.props.farm[this.props.batches[batchId].finishingLocation.farm || this.props.batches[batchId].propLocation1.farm].name

      ])
    });
    this.setState({ data: arr }, () => {
      this.setState({ collapse: '' })
      setTimeout(() => {
        this.csvLink.current.link.click()
      }, 1000);
    })
  };

  isValidHttpUrl = (str) => {
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
  }

  handleChange = name => e => {
    if (this.state.errors[name]) {
      this.setState({ errors: { ...this.state.error, [name]: false } });
    }
    this.setState({
      [name]: e.target.value
    });
  };

  handleSave = e => {

    e.preventDefault();

    this.setState({ isSaving: true }, () => {


      const requiredFields = ['name', 'link'];
      let errorsFound = false;

      requiredFields.forEach((name, i) => {
        if (!this.state[name] || this.state[name] === '') {
          errorsFound = true;
          this.setState({ errors: { ...this.state.errors, [name]: true } });
        }
      });
      if (errorsFound) {
        this.setState({ isSaving: false });
        return;
      }
      addEditButton({
        buttonName: this.state.name,
        link: this.state.link,
        userId: this.context.user.id || ''
      }, this.state.selectedId).then(r => {
        this.getButtons();
        this.setState({ isSaving: false, showAddButton: false, selectedId: '', mode: '', type: 1 });
      })
        .catch(error => {
          this.setState({ selectedId: '', isSaving: false })
          console.log(error)
        })
    })
  }

  buttonDelete = (id) => {
    deleteButton(id).then(r => {
      this.getButtons();
      console.log('button deleted successfully')
    })
      .catch(error => {
        console.log(error)
      })
  }

  handleChangeTab = (event, tab) => {
    this.setState({ tab, selectedTask: [], selectedAssignTask: [] });
  };

  render() {

    const { newTaskList, collapse, collapse1, selectedTask, selectedAssignTask, data, buttonList, mode, showAddButton, tab, showCompleted } = this.state;
    const { batches, tasks, crops, varieties, classes } = this.props;
    return (
      <div>
        {this.state.isLoaded ?
          <UserContext.Consumer>
            {({ user }) =>
              <div>
                <Grid container justify="flex-end">
                  <Typography variant="h6" >{moment().dayOfYear()}</Typography>
                </Grid>
                <Grid container style={{ alignItems: 'center' }}>
                  <FormControl className={classes.margin}>
                    <InputLabel htmlFor="age-customized-select" className={classes.bootstrapFormLabel}>
                      Mode
                    </InputLabel>
                    <Select
                      value={this.state.type}
                      input={<BootstrapInput name="age" id="age-customized-select" />}
                    >
                      <MenuItem value={1}
                        onClickCapture={() => {
                          this.apiTest()
                          this.setState({ showAddButton: true, mode: '', type: 1, name: '', link: '' })}}>
                        <Button size='medium' variant="contained" color="primary"
                          style={{ marginRight: 15 }}
                        >
                          Add
                        </Button>
                      </MenuItem>
                      <MenuItem value={2} onClickCapture={() => this.setState({ mode: 'edit', type: 2 })}>
                        <Button size='medium' variant="contained" color="" style={{ width: 200 }}
                          // onClick={() => this.setState({ mode: 'edit',type:2 })}
                          style={{ marginRight: 15 }}
                        >
                          Edit
                        </Button>
                      </MenuItem>
                      <MenuItem value={3} onClickCapture={() => this.setState({ mode: 'delete', type: 3 })}>
                        <Button size='medium' variant="contained"
                          onClick={() => this.setState({ mode: 'delete', type: 3 })}
                          style={{ marginRight: 15 }}
                          className={classes.delete}
                        >
                          Delete
                        </Button>
                      </MenuItem>
                    </Select>
                  </FormControl>



                  {/* <Button size='medium' variant="contained" color="red" className={classes.button}
             onClick={() => this.setState({ showAddButton: true })}
             style={{marginRight:15}}
            >
              Delete button
            </Button> */}
                  <Grid style={{ marginTop: 22.5, marginLeft: 15 }}>
                    {buttonList.map(button => (
                      <Button size='medium' variant="contained" color={mode === 'edit' ? "default" : "primary"} style={{ padding: 8, margin: 10, marginLeft: 0, cursor: 'pointer' }}
                        className={mode === 'delete' ? classes.delete : {}}
                        onClick={() => {
                          if (mode === 'edit') {
                            this.setState({
                              name: button.buttonName,
                              link: button.link,
                              selectedId: button.id,
                              showAddButton: true
                            })
                          }
                          else if (mode === 'delete') {
                            this.buttonDelete(button.id)
                          }
                          else {
                            if (this.isValidHttpUrl(button.link)) {
                              window.open(button.link, "_blank")
                            }
                            else {
                              alert('Invalid URL')
                            }
                          }
                        }}
                        style={{ marginRight: 15 }}
                      >
                        {button.buttonName}
                        {mode === 'edit' ?
                          <Icon style={{ fontSize: 18 }} className={classes.rightIcon}>edit_icon</Icon>
                          : mode === 'delete' ?
                            <DeleteIcon style={{ fontSize: 18 }} className={classes.rightIcon} />
                            : null}
                      </Button>

                    ))}
                  </Grid>
                </Grid>

                <Grid container direction='row' justify='space-between' style={{ marginTop: '40px' }}>
                  {/* <Paper square >
                    <Tabs
                      value={tab}
                      indicatorColor="primary"
                      textColor="primary"
                      onChange={this.handleChangeTab}
                      style={{ width: '100%' }}
                    >
                      <Tab label="Tasks" />
                      <Tab label="Assigned Tasks" />
                    </Tabs>
                  </Paper> */}
                   <Typography variant={"body1"}>Assigned Tasks</Typography>
                  <Grid>
                    {!tab && Object.keys(batches).length ? <Button size='medium' variant="contained" color="secondary" className={classes.button}
                      onClick={() => this.exportCSV(Object.keys(batches))}
                      style={{ marginRight: 15 }}
                    >
                      CSV <i className="fa fa-download" style={{ marginLeft: 10 }}></i>
                    </Button> : null}
                    {(selectedTask.length || selectedAssignTask.length) ? <Button onClick={() => tab ? this.completeAssignedTasks() : this.completeMarkedTasks(user)} size={'small'} variant="contained" color="primary">Mark as Complete</Button> : null}
                    {tab ? <Button onClick={() => this.setState({ showCompleted: !showCompleted })} style={{ marginLeft: 5 }} size={'small'} variant="contained" color="primary">{!showCompleted ? 'Show Completed' : 'Hide Completed'}</Button> : null}
                  </Grid>
                </Grid>
                <Divider style={{ marginTop: 15 }} />
                <Grid style={{ marginTop: 10 }} />

                {tab ? user.allotedTask && Object.keys(user.allotedTask).map((key, i) => {
                  let isCompleted = user.allotedTask[key].status === 'completed'

                  if (showCompleted ? true : !isCompleted) {
                    return (
                      <Grid container direction='column' xs={12} style={{ marginTop: i === 0 ? 10 : 2 }} >
                        <Grid xs={10} container direction='row' >
                          <Grid xs={1}>
                            {!isCompleted ? <Checkbox checked={selectedAssignTask.indexOf(key) !== -1} onChange={() => this.onClickCheckBox1(key)} name="checked" color={'primary'} /> : null}
                          </Grid>
                          <Grid xs={9}>
                            <ListItem key={i} dense button container direction='row' justify='space-between' onClick={() => this.setState({ collapse1: collapse1 === key ? '' : key })}>
                              <ListItemText id={i} primary={user.allotedTask[key].title} />
                              <Typography variant={"body1"} style={{ marginRight: 10 }}>
                                {isCompleted ? 'Completed on :' : 'Complete by :'}
                              </Typography>
                              <Typography variant={"body1"} color="primary">
                                {isCompleted ? moment(user.allotedTask[key].completeTime).format('YYYY-MM-DD') : user.allotedTask[key].completeByDate}
                              </Typography>
                            </ListItem>
                          </Grid>
                        </Grid>
                        <Collapse in={collapse1 === key}>
                          <Grid xs={12} container direction='row'>
                            <Grid xs={1} />
                            <Typography variant={"body1"}>
                              {user.allotedTask[key].taskDetails}
                            </Typography>
                          </Grid>
                        </Collapse>

                      </Grid>

                    )
                  }
                }) : null}
                {!tab && newTaskList.map((key, i) => {

                  let forceHide = false;
                  const eachTask = tasks[key];
                  const batch = key.split('-')[0];
                  const task = key.split('-')[1];
                  // const mainBatch = batches[batch];

                  if ((task === TASK_NAMES.SEED_AND_START) || (task === TASK_NAMES.START) || (task === TASK_NAMES.SEED)) forceHide = true;
                  const mainCrop = crops[eachTask.crop];
                  const mainVariety = varieties[eachTask.variety];

                  if(eachTask.user === user.id)
                  return (
                    <Grid container direction='row' xs={12} style={{ marginTop: 10 }}>
                      <Grid style={{ marginTop: 15 }}>
                        <Checkbox checked={selectedTask.indexOf(i) !== -1} onChange={() => this.onClickCheckBox(i)} name="checked" color={'primary'} />
                      </Grid>
                      <Grid xs={10}>
                        <ListItem key={i} dense button onClick={() => this.setState({ collapse: collapse === key ? '' : key })}>
                          <Grid alignItems={'center'} xs={3}>
                            <div className={classes.iconContainer}>
                              <i className={`fas ${icons[eachTask.type]} fa-3x`}></i>
                            </div>
                            <div className={classes.iconContainer}>
                              <Typography variant={"body1"} color="secondary">
                                {taskNames[eachTask.type]} {eachTask.type === TASK_TYPES.TRANSFER && destinations[eachTask.transferTo]}
                              </Typography>
                            </div>
                          </Grid>
                          <ListItemText style={{ marginLeft: 0, width: 200 }} id={i} primary={mainCrop.name + ' - ' + mainVariety.name} />
                          <ListItemText style={{ marginLeft: 0 }} id={i} primary={'Planned Date : ' + new Date(eachTask.date).toLocaleDateString()} />

                          <Button size='medium' variant="contained" color="secondary" className={classes.button}
                            onClick={() => this.exportCSV([batch])}
                          // style={{ marginLeft: 180, marginTop: 20 }}
                          >
                            CSV <i className="fa fa-download" style={{ marginLeft: 10 }}></i>
                          </Button>
                        </ListItem>

                        <Collapse in={collapse === key}>
                          <Grid xs={12} container direction='row' style={{ marginTop: 10 }}>
                            <Grid xs={1} />
                            <Grid xs={3}>
                              <Typography>Lot no  : {eachTask.GRFlot}</Typography>
                            </Grid>
                            <Grid xs={2}>
                              <Typography>{eachTask.quant} cells</Typography>
                            </Grid>
                            {!forceHide ? <Grid xs={5} container direction='row' style={{ marginLeft: 50 }}>
                              {new Array(5).fill(1).map((each, j) => (
                                <Grid onClick={() => {
                                  eachTask.rating = j + 1
                                  this.setState({ refreshing: true })
                                }} container className={classes.rate} style={{ backgroundColor: eachTask.rating !== j + 1 ? 'gray' : '#42b883' }}>
                                  <Typography variant={'subtitle2'} className={classes.rateNumber} >{j + 1}</Typography>
                                </Grid>
                              ))}
                            </Grid> : null}
                          </Grid>
                        </Collapse>

                        <Divider style={{ marginTop: 10 }} />

                      </Grid>

                    </Grid>
                  )
                })}

                <CSVLink
                  data={data}
                  filename={moment().format('YYYY-MM-DD') + '.csv'}
                  className="hidden"
                  ref={this.csvLink}
                  target="_blank"
                />
                <Modal
                  aria-labelledby="modal-title"
                  open={showAddButton}
                  onClose={this.closeModal}
                >
                  <AddButtonModal
                    state={this.state}
                    handleChange={this.handleChange}
                    handleSave={this.handleSave}
                    closeModal={() => this.setState({ showAddButton: false })}
                  />
                </Modal>
              </div>}
          </UserContext.Consumer>
          : <FullScreenLoader />}
      </div>
    )
  }

};

Home.propTypes = {
  classes: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    crops: state.crops,
    varieties: state.varieties,
    growthCycles: state.growthCycles,
    batches: state.batches,
    varietyLegend: state.varietyLegend,
    users: state.users,
    farm: state.farm,
    tasks: state.tasks,
    misc: state.misc,
    rafts: state.rafts,
  }
}

export default withStyles(styles)(connect(mapStateToProps)(Home));

import { LOAD_CROPS, ADD_EDIT_CROP, DELETE_CROP } from '../actionTypes';
import { deepCopyObject } from '../../utils/utils';

export default (state = {}, action) => {
  switch (action.type) {
    case LOAD_CROPS:
      return {...action.crops};

    case ADD_EDIT_CROP:
      // merges received key-val pairs with existing ones for the item in question
      const key = Object.keys(action.crop)[0];
      let currCropObj = state[key] ? deepCopyObject(state[key]) : {};
      currCropObj = {...currCropObj, ...action.crop[key]};
      return {...state, [key]: currCropObj};

    case DELETE_CROP:
      let newState = deepCopyObject(state);
      delete newState[action.id];
      return newState;

    default:
      return state;
  }
};
import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { withStyles, Tooltip, DialogTitle, Checkbox, TableSortLabel } from '@material-ui/core';

import classNames from 'classnames';
import red from '@material-ui/core/colors/red';
import blue from '@material-ui/core/colors/blue';
import APP_SETTINGS from '../utils/appSettings';

import Modal from '@material-ui/core/Modal';

import { deleteBatch, removeBatchThunk } from '../store/actions/deleters';
import PasswordModal from './PasswordModal';
import { green } from '@material-ui/core/colors';
import { addEditBatch, pushPlots, pushVarietyLegend } from '../store/actions/adders';
import moment from 'moment';
import { connect } from 'react-redux';
import { createPlotsFromBatches } from '../utils/appFunctions/plots';
import { fillNumberStringToLength } from '../utils/utils';
const { STAGES } = APP_SETTINGS;


const styles = theme => ({
  modal: {
    width: '90%',
    marginLeft: '5%',
    marginTop: '2%',
    marginBottom: '1%',
    minHeight: '90%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    '&:focus': {
      outline: 'none'
    },
    // overflow: 'auto',
    maxHeight: '80%',
  },
  topButton: {
    color: red[400],
    borderColor: red[400],
    '&:hover': {
      backgroundColor: red[50]
    },
    marginRight: 10
  },
  container: {
    width: '100%',
  },
  input: {
    width: '100%'
  },
  farmName: {
    color: '#000',
    marginTop: 40,
    fontWeight: 'bold'
  },
  buttonContainer: {
    textAlign: 'right',
    marginTop: '1.5rem'
  },
  button: {
    margin: '0.5rem 0rem'
  },
  paper: {
    width: '100%',
    marginBottom: '2rem'
  },
  sectionTitle: {
    // marginTop: '2rem'
  },
  formControl: {
    minWidth: 160,
    marginLeft: 15
  },
  link1: {
    marginTop: '2.25rem',
    color: blue[500],
    fontSize: 15,
    cursor: 'pointer',
  },
  link: {
    color: blue[500],
    fontSize: 15,
    marginTop: 20,
    cursor: 'pointer'
  },
  actionIcons: {
    marginRight: '0.5rem',
    cursor: 'pointer',
    '&:last-child': {
      marginRight: 0
    },
    fontSize: '1.5rem'
  },
  blue: {
    color: blue[500]
  },
  green: {
    color: green[500]
  },
  red: {
    color: red[500]
  },
  grey: {
    color: theme.palette.grey[300]
  },
  editingCell: {
    color: theme.palette.grey[300]
  },
  fab: {
  },
  tabContainer: {
    margin: '3rem 0 1rem'
  },
  tabs: {
    width: '100%',
    minWidth: 400,
    '& button': {
      width: '35%',
      maxWidth: 'none'
    }
  },
  tab: {
    fontSize: '1.1rem'
  },
  divider: {
    width: '100%',
    marginTop: '-1px'
  },

  thead: { position: 'sticky', top: 0, background: '#fff', zIndex: 9},
  tableContainer: { height: '500px', overflow: 'auto'}
});

class BatchModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      batches: Object.keys(props.batches),
      batchID: '',
      editMode: false,
      propLocation: '',
      finishingLocation: '',
      PropPond: '',
      HDPond: '',
      FSPond: '',
      propPonds: [],
      finishingPonds: [],
      modalIsOpen: false,
      currentModal: null,
      currentModalAction: '',
      selectedBatches: [],
      sortingObj: {},
      active: '',
      multiDelete: false
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal(currentModal, currentModalAction) {
    this.setState({ modalIsOpen: true, currentModal, currentModalAction });
  }

  renderModal() {
    switch (this.state.currentModal) {
      case 'confirmDelete':
        return (
          <PasswordModal
            title="Confirm batch delete"
            handleSubmit={this.deleteBatch}
            handleCancel={this.closeModal}
          />
        );
        break;
    }
  }

  closeModal() {
    this.setState({ modalIsOpen: false, currentModal: null, currentModalAction: '' });
  }

  handleClose = (type) => {
    if (type) {
      this.openModal('confirmDelete');
    }
    this.setState({ openPrompt: false })
  }

  componentDidMount() {
    // Promise.all([getFarms()]).then(resp => {
    //   this.setState({ allFarms: resp[0] })
    // })
  }

  componentWillReceiveProps(nextProps) {
    if (Object.keys(nextProps.batches).length !== Object.keys(this.props.batches).length) {
      this.setState({ batches: Object.keys(nextProps.batches) })
    }
  }



  async getPond(id, type) {
    this.props.farm && this.setState({ [type]: this.props.farm[id].ponds })
    // this.state.allFarms && this.state.allFarms.forEach(resp => {
    //   if (resp.id === id) {
    //     this.setState({ [type]: resp.ponds })
    //   }
    // })
  }


  onClickCheckBox = (i) => {
    let { selectedBatches } = this.state;
    let indexOf = selectedBatches.indexOf(i);
    if (indexOf === -1) selectedBatches.push(i)
    else selectedBatches.splice(indexOf, 1)
    this.setState({ selectedBatches })
  }

  handleSaveClick = async () => {
    const { currentbatch, currentbatchId, propLocation, finishingLocation, PropPond, HDPond, FSPond } = this.state;

    // currentbatch.FSFarm = finishingLocation;
    // currentbatch.HDFarm = finishingLocation;
    // currentbatch.PropFarm = propLocation;

    // currentbatch.FSPond = FSPond;
    // currentbatch.HDPond = HDPond;
    // currentbatch.PropPond = PropPond;

    let batch = currentbatch;
    let id = currentbatchId;

    await addEditBatch({ batch, id })
    this.props.showSnakbar()
  }

  getStartBatchDate = (batch, showSeedDate = false) => {
    let res = '';
    if (batch && batch.tasks && batch.tasks.seedAndStart && batch.tasks.seedAndStart.date) {
      res = batch.tasks.seedAndStart.date;
    } else if (showSeedDate && batch && batch.tasks && batch.tasks.seed && batch.tasks.seed.date) {
      res = batch.tasks.seed.date;
    } else if (showSeedDate === false && batch && batch.tasks && batch.tasks.start && batch.tasks.start.date) {
      res = batch.tasks.start.date;
    }
    return res;
  }

  onClickClone = () => {
    const { onClickClone } = this.props;

    let { batches, selectedBatches } = this.state;

    selectedBatches.forEach(each => {
      onClickClone(this.props.batches[batches[each]])
    })
  }

  onClickDelete = () => {
    this.setState({ multiDelete: true })
    this.openModal('confirmDelete');
  }


  deleteBatch = async () => {
    let { batches, selectedBatches } = this.state;

    if (this.state.multiDelete) {
      selectedBatches.forEach(async (each) => {
        await deleteBatch(batches[each]);
        this.props.removeBatchThunk(batches[each])
      })
      this.setState({ selectedBatches: [] }, () => {
        this.closeModal();
        setTimeout(() => {
          this.refreshPlots()
        }, 2000);
      })
    }
    else {
      await deleteBatch(this.state.batchID);
      this.props.removeBatchThunk(this.state.batchID)
      this.closeModal();
    }

    setTimeout(() => {
      this.refreshPlots()
    }, 2000);

  }


  sortFunction(a = {}, b = {}, direction = '', attribute = '') {

    // handle undefined objects
    if (!a || !b) {
      return 0;
    }

    const multiplier = direction === 'asc' ? 1 : -1;

    if (attribute.toLowerCase().includes('date')) {
      if (moment(a[attribute]).isBefore(b[attribute])) { return -1 * multiplier; }
      if (moment(a[attribute]).isAfter(b[attribute])) { return 1 * multiplier; }
      return 0;
    } else {
      if (attribute === 'farm') {
        let dynamicKey1 = a.propLocation1.farm ? 'propLocation1' : 'finishingLocation'
        let dynamicKey2 = b.propLocation1.farm ? 'propLocation1' : 'finishingLocation'

        if (a[dynamicKey1][attribute] < b[dynamicKey2][attribute]) { return -1 * multiplier; }
        if (a[dynamicKey1][attribute] > b[dynamicKey2][attribute]) { return 1 * multiplier; }
      }
      else {
        if (a[attribute] < b[attribute]) { return -1 * multiplier; }
        if (a[attribute] > b[attribute]) { return 1 * multiplier; }
      }

      return 0;
    }
  }

  refreshPlots() {
    // create plots, push to redux store
    let batches = { ...this.props.batches };

    const { plots, varietyLegend } = createPlotsFromBatches({ batches, varieties: this.props.varieties });
    this.props.pushPlots(plots);
    this.props.pushVarietyLegend(varietyLegend);
    return plots;
  }

  createSortHandler = (type) => {
    const { batches } = this.props;
    const { sortingObj } = this.state;
    const direction = sortingObj[type] === 'asc' ? 'dsc' : 'asc';

    let batchesOrder = this.state.batches.sort((a, b) => this.sortFunction(batches[a], batches[b], direction, type))
    this.setState({ batches: batchesOrder, active: type, selectedBatches: [], sortingObj: { ...sortingObj, [type]: direction } })
  }

  render() {

    const { classes, varieties, crops, users, clone, onClickClone, farm } = this.props;
    let { batches, sortingObj, active, editMode, allFarms, propLocation, finishingLocation, finishingPonds, PropPond, HDPond, FSPond, propPonds, selectedBatches } = this.state;
    return (
      <div className={classes.modal} tabIndex={-1}>

        <Grid container className={classes.container} spacing={16}>
          <Grid container direction='row' justify='space-between' item xs={12}>
            <Grid container direction='row' justify='space-between' alignItems='center'>
              <Typography variant="h6" className={classes.sectionTitle}>Batches</Typography>
              <Grid>
                <Button onClick={this.onClickDelete} size={'small'} variant={'outlined'} disabled={!selectedBatches.length} className={classes.topButton}>Delete Batches</Button>
                {clone && <Button onClick={this.onClickClone} size={'small'} variant="contained" disabled={!selectedBatches.length} color={'secondary'}>Clone Batches</Button>}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classNames(classes.paper,classes.tableContainer)}>
              
              <Table className={classes.table}>
                <TableHead className={classes.thead}>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>GRF Lot</TableCell>
                    <TableCell>
                      <Tooltip
                        title="Sort"
                        placement={'bottom-start'}
                        enterDelay={300}
                        onClick={() => this.createSortHandler('crop')}
                      >
                        <TableSortLabel
                          active={active === 'crop'}
                          direction={sortingObj['crop'] === 'asc' ? 'asc' : 'dsc'}
                        >Crop
                        </TableSortLabel>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Tooltip
                        title="Sort"
                        placement={'bottom-start'}
                        enterDelay={300}
                        onClick={() => this.createSortHandler('variety')}
                      >
                        <TableSortLabel
                          active={active === 'variety'}
                          direction={sortingObj['variety'] === 'asc' ? 'asc' : 'dsc'}
                        >Variety
                        </TableSortLabel>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Tooltip
                        title="Sort"
                        placement={'bottom-start'}
                        enterDelay={300}
                        onClick={() => this.createSortHandler('seedDate')}
                      >
                        <TableSortLabel
                          active={active === 'seedDate'}
                          direction={sortingObj['seedDate'] === 'asc' ? 'asc' : 'dsc'}
                        >Seed Date
                        </TableSortLabel>
                      </Tooltip>
                    </TableCell>
                    {/* <TableCell>Seed Date</TableCell> */}
                    <TableCell>Start Date</TableCell>
                    <TableCell>
                      <Tooltip
                        title="Sort"
                        placement={'bottom-start'}
                        enterDelay={300}
                        onClick={() => this.createSortHandler('assignUser')}>
                        <TableSortLabel
                          active={active === 'assignUser'}
                          direction={sortingObj['assignUser'] === 'asc' ? 'asc' : 'dsc'}
                        >User Assigned
                        </TableSortLabel>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Tooltip
                        title="Sort"
                        placement={'bottom-start'}
                        enterDelay={300}
                        onClick={() => this.createSortHandler('seedLot')}>
                        <TableSortLabel
                          active={active === 'seedLot'}
                          direction={sortingObj['seedLot'] === 'asc' ? 'asc' : 'dsc'}
                        >Seed Lot
                        </TableSortLabel>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Tooltip
                        title="Sort"
                        placement={'bottom-start'}
                        enterDelay={300}
                        onClick={() => this.createSortHandler('farm')}>
                        <TableSortLabel
                          active={active === 'farm'}
                          direction={sortingObj['farm'] === 'asc' ? 'asc' : 'dsc'}
                        >Finishing Location
                        </TableSortLabel>
                      </Tooltip>
                    </TableCell>
                    <TableCell>{clone ? `Clone` : `Delete`}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {batches.map((each, i) => {
                    let batch = this.props.batches[each];
                    let grfLotNo = batch.GRFlot;
                    if(batch.current && ((batch.current.stage === STAGES.FS)||(batch.current.stage === STAGES.COMPLETED))){
                      grfLotNo += fillNumberStringToLength(moment(batch.harvestDate).dayOfYear(), 3) 
                    }

                    if (batch && (each.length > 3)) return (
                      <TableRow key={i}>
                        <TableCell className={classNames({ [classes.editingCell]: false })}>
                          <Checkbox checked={selectedBatches.indexOf(i) !== -1} onChange={() => this.onClickCheckBox(i)} name="checked" color={'primary'} />
                        </TableCell>
                        <TableCell className={classNames({ [classes.editingCell]: false })}>{grfLotNo}</TableCell>
                        <TableCell className={classNames({ [classes.editingCell]: false })}>{crops[batch.crop] ? crops[batch.crop].name : ''}</TableCell>
                        <TableCell className={classNames({ [classes.editingCell]: false })}>{varieties[batch.variety] ? varieties[batch.variety].name : ''}</TableCell>
                        <TableCell className={classNames({ [classes.editingCell]: false })}>{this.getStartBatchDate(batch, true)}</TableCell>
                        <TableCell className={classNames({ [classes.editingCell]: false })}>{this.getStartBatchDate(batch)}</TableCell>
                        <TableCell className={classNames({ [classes.editingCell]: false })}>{users[batch.assignUser] ? (users[batch.assignUser].firstName + ' ' + users[batch.assignUser].lastName) : ''}</TableCell>
                        <TableCell className={classNames({ [classes.editingCell]: false })}>{batch.seedLot}</TableCell>
                        <TableCell className={classNames({ [classes.editingCell]: false })}>{farm[batch.finishingLocation.farm || batch.propLocation1.farm] ? farm[batch.finishingLocation.farm || batch.propLocation1.farm].name : ''}</TableCell>

                        {clone ?
                          <TableCell className={classNames({ [classes.editingCell]: false })}>
                            <Tooltip placement="top" title="Clone batch">
                              <i
                                className={`far fa-clone fa-2x ${classNames(classes.actionIcons, classes.green, { [classes.grey]: false })}`}
                                onClick={() => {
                                  onClickClone(batch)
                                }}
                              >
                              </i>
                            </Tooltip>
                          </TableCell>
                          : <TableCell className={classNames({ [classes.editingCell]: false })}>
                            {/* <Tooltip placement="top" title="Edit batch">
                              <i
                                className={`far fa-edit fa-2x ${classNames(classes.actionIcons, classes.green, { [classes.grey]: false })}`}
                                onClick={() => {
                                  this.setState({ editMode: true, currentbatch: batch, currentbatchId: each, propLocation: batch.propLocation.farm, finishingLocation: batch.finishingLocation.farm, PropPond: batch.propLocation.pond });
                                  this.getPond(batch.propLocation.farm, 'propPonds');
                                  this.getPond(batch.finishingLocation.farm, 'finishingPonds');
                                }}
                              >
                              </i>
                            </Tooltip> */}
                            <Tooltip placement="top" title="Delete batch">
                              <i
                                className={`far fa-times-circle fa-2x ${classNames(classes.actionIcons, classes.red, { [classes.grey]: false })}`}
                                onClick={() => this.setState({ openPrompt: true, batchID: each, multiDelete: false })}
                              >
                              </i>
                            </Tooltip>
                          </TableCell>}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Paper>
            <Dialog
              open={this.state.openPrompt}
              onClose={() => this.handleClose(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              {/* <DialogTitle id="alert-dialog-title">{"Alert"}</DialogTitle> */}
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Delete this batch? This cannot be undone
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => this.handleClose(false)} color="primary">
                  No
                </Button>
                <Button onClick={() => this.handleClose(true)} color="primary" autoFocus>
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
            <Modal
              aria-labelledby="modal-title"
              open={this.state.modalIsOpen}
              onClose={this.closeModal}
            >
              {this.renderModal()}
            </Modal>
          </Grid>
        </Grid>

        {/* <Grid container className={classes.container} justify="flex-end">
                    <Button variant="contained" color="secondary" className={classes.button} onClick={this.props.onDone}>Done</Button>
                </Grid> */}


      </div>
    )
  }
};

BatchModal.propTypes = {
  classes: PropTypes.object.isRequired,
  deleteItem: PropTypes.func.isRequired,
  showSnakbar: PropTypes.func.isRequired,
  batches: PropTypes.object.isRequired,
  crops: PropTypes.object.isRequired,
  users: PropTypes.object.isRequired,
  varieties: PropTypes.object.isRequired,
  clone: PropTypes.bool,
  onClickClone: PropTypes.func,
  removeBatchThunk: PropTypes.func.isRequired,

};

export default withStyles(styles)(connect(null, {
  // pushBatch,
  // pushBatches,
  removeBatchThunk,
  pushPlots,
  pushVarietyLegend,
  // setShouldCalculatePlots,
  // startPlotCalcAfterNumber
})(BatchModal));

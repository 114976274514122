import {
  LOAD_CROP_TYPES,
  ADD_CROP_TYPE,
  LOAD_REASONS,
  ADD_REASON,
  LOAD_INVENTORY_ALERTS,
  ADD_INVENTORY_ALERT,
  DELETE_INVENTORY_ALERT,
  DELETE_ALL_INVENTORY_ALERTS,
  SET_HAS_INITIALLY_LOADED,
  SET_SHOULD_CALCULATE_PLOTS,
  SET_PLOT_CALC_AFTER_NUMBER,
  SET_PLOT_CALC_SUSPEND_COUNTER,
  SET_SHOULD_UPDATE_ON_DATABASE_CHANGE,
  LOAD_VARIETY_CODES,
  LOAD_PEST_LIST
} from '../actionTypes';

export default (state = { shouldCalculatePlots: true, calcPlotAfterNumber: 0, plotCalcSuspendCounter: 0 }, action) => {
  switch (action.type) {
    case LOAD_CROP_TYPES:
      return { ...state, cropTypes: action.list }; // 1D array
    case LOAD_VARIETY_CODES:
      return { ...state, varietyCodes: action.list }; // 1D array

    case ADD_CROP_TYPE:
      let cropTypeList = state.cropTypes.slice();
      cropTypeList.push(action.cropType).sort();
      return { ...state, cropTypes: cropTypeList };

    case LOAD_REASONS:
      return { ...state, reasons: action.list }; // 1D array

    case LOAD_PEST_LIST:
      return { ...state, pestList: action.list }; // 1D array

    case ADD_REASON:
      let reasonList = state.reasons.slice();
      reasonList.push(action.reason);
      return { ...state, reasons: reasonList };


    case LOAD_INVENTORY_ALERTS:
      return { ...state, lowInventory: action.list };

    case ADD_INVENTORY_ALERT:
      let lowInventory = state.lowInventory.slice();
      lowInventory.push(action.varID);
      return { ...state, lowInventory };

    case DELETE_INVENTORY_ALERT:
      lowInventory = state.lowInventory.slice();
      lowInventory.splice(lowInventory.indexOf(action.varID, 1));
      return { ...state, lowInventory };

    case DELETE_ALL_INVENTORY_ALERTS:
      return { ...state, lowInventory: [] };


    case SET_HAS_INITIALLY_LOADED:
      return { ...state, hasInitiallyLoaded: action.state };

    case SET_SHOULD_CALCULATE_PLOTS:
      return { ...state, shouldCalculatePlots: action.state };

    case SET_PLOT_CALC_AFTER_NUMBER:
      return { ...state, calcPlotAfterNumber: action.number };

    case SET_PLOT_CALC_SUSPEND_COUNTER:
      return { ...state, plotCalcSuspendCounter: action.number };


    case SET_SHOULD_UPDATE_ON_DATABASE_CHANGE:
      return { ...state, shouldUpdateOnDatabaseChange: action.state };

    default:
      return state;
  }
};
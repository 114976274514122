import { combineReducers } from 'redux';
import crops from './crops';
import varieties from './varieties';
import growthCycles from './growthCycles';
import rafts from './rafts';
import misc from './misc';
import batches from './batches';
import perpetualBatches from './perpetualBatches';
import tasks from './tasks';
import users from './users';
import plots from './plots';
import varietyLegend from './varietyLegend';
import farm from './farm';


const rootReducer = combineReducers({
  crops,
  varieties,
  growthCycles,
  rafts,
  misc,
  batches,
  perpetualBatches,
  tasks,
  users,
  plots,
  varietyLegend,
  farm
});

export default rootReducer;
import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { MuiPickersUtilsProvider, DatePicker } from "material-ui-pickers";
import MomentUtils from "@date-io/moment";
import {
  withStyles,
  Tooltip,
  DialogTitle,
  Checkbox,
  TableSortLabel,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Input,
} from "@material-ui/core";

import classNames from "classnames";
import red from "@material-ui/core/colors/red";
import blue from "@material-ui/core/colors/blue";
import APP_SETTINGS from "../utils/appSettings";

import Modal from "@material-ui/core/Modal";

import { deleteBatch, removeBatchThunk } from "../store/actions/deleters";
import PasswordModal from "./PasswordModal";
import { green } from "@material-ui/core/colors";
import {
  addEditBatch,
  pushPlots,
  pushVarietyLegend,
} from "../store/actions/adders";
import moment from "moment";
import { connect } from "react-redux";
import { createPlotsFromBatches } from "../utils/appFunctions/plots";
import {
  fillNumberStringToLength,
  sortFunction,
  groupByArray,
  deepCopyObject,
  checkArray,
} from "../utils/utils";
const { TASK_TYPES, UNITS, STAGES } = APP_SETTINGS;

const styles = (theme) => ({
  modal: {
    width: "90%",
    marginLeft: "5%",
    marginTop: "2%",
    marginBottom: "1%",
    minHeight: "90%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    "&:focus": {
      outline: "none",
    },
    overflow: "auto",
    maxHeight: "80%",
  },
  container: {
    width: "100%",
    position: "relative",
  },
  sectionTitle: {
    // marginTop: '2rem'
  },
  deleteRowButton: {
    position: "absolute",
    top: 11,
    right: 15,
    fontSize: "1.7rem",
    cursor: "pointer",
    color: red[300],
  },
  formRow: {
    minHeight: "max-content",
  },
  button: {
    position: "relative",
    marginLeft: "1.5rem",
  },
  buttonMarTop: {
    marginTop: "0.7rem",
  },
  card: {
    width: "100%",
    // maxWidth: 510,
    minHeight: 118,
    overflow: "hidden",
    transition: theme.transitions.create(["max-width", "min-height"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuHeading: {
    padding: "10px 0 0",
  },
  cardContent: {
    paddingBottom: "0px !important",
    marginBottom: "10px !important",
    width: "100%",
  },
  topRow: {
    // width: 850 - (cardPadding * 2),
    width: "100%",
  },
  iconContainer: {
    textAlign: "center",
    width: "100%",
    "& i": {
      color: theme.palette.secondary.main,
      lineHeight: "45px",
    },
    "&:last-child": {
      marginBottom: "-0.3rem",
    },
  },
  title: {
    display: "inline-block",
    maxWidth: 225,
    height: "1.5rem",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  bigTitle: {
    display: "inline-block",
    maxWidth: 225,
    height: "2rem",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  mainContent: {
    width: "90%",
  },
  date: {
    display: "inline-block",
    overflow: "hidden",
    whiteSpace: "nowrap",
    marginTop: 5,
  },
  bottomMargin: {
    marginBottom: "0.05rem",
  },
  grey: {
    color: theme.palette.grey[700],
  },
  formControl: {
    // margin: theme.spacing.unit,
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    width: "100%",
  },
  dateInput: {
    marginTop: "5px !important",
    width: "100%",
    "& div": {
      width: "100%",
    },
  },
  sumContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "end",
    alignItems: "center",
  },
  sumText: {
    display: "flex",
    justifyContent: "end",
    width: "90%",
    position: "relative",
    top: "15px",
    right: "5px",
    "& p": {
      fontSize: "1.25rem",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      fontWeight: "500",
    },
  },
});

const taskNames = {
  seed: "Seed",
  transfer: "Transfer to",
  start: "Start",
  seedAndStart: "Seed & Start",
  harvest: "Harvest",
};

const destinations = {
  prop: "Prop",
  hd: "HD",
  fs: "FS",
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

class TodayPredHarvest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // batches: Object.keys(props.batches),
      crop: "",
      groupByBatches: null,
      selectedFarm: [],
      selectedVarieties: [],
      varietyOptVal: [],
      isVarietySelectAll: false,
      manualEntries: null,
      customAvgEntries: null,
      todayDate: new Date(),
      fullSumAvg: 0,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.crop !== this.state.crop) {
      const newVarietyOptVal = this.renderVarietyVal(this.state.crop);
      this.setState({
        selectedVarieties: [...["All"], ...newVarietyOptVal],
        isVarietySelectAll: true,
        groupByBatches: null,
      });
    }
  }

  calculateHarvestData() {
    const { crop, selectedVarieties, selectedFarm, todayDate } = this.state;
    const { batches, crops, farm } = this.props;
    const copyBatch = deepCopyObject(batches);
    const batchesArr = Object.keys(copyBatch).map(
      (batcheId) => copyBatch[batcheId]
    );
    const totalLocations = Object.keys(farm).map((key) => key);
    let selectedBatches = [];
    if (
      crop !== "" &&
      crops?.[crop]?.varieties &&
      crops?.[crop]?.varieties.length > 0
    ) {
      const allowedFarms = checkArray(selectedFarm)
        ? selectedFarm
        : totalLocations;
      const cropVarieties = checkArray(selectedVarieties)
        ? selectedVarieties.filter((p) => p !== "All")
        : crops?.[crop]?.varieties;
      selectedBatches = batchesArr.filter((p) => {
        const batchLocation =
          p?.growthCycle?.growMedia == "Rockwool"
            ? p?.finishingLocation?.farm
            : p?.propLocation1?.farm;
        if (
          cropVarieties.indexOf(p.variety) > -1 &&
          allowedFarms.indexOf(batchLocation) > -1 &&
          p?.tasks?.harvest?.isComplete !== true &&
          moment(p?.tasks?.harvest?.date).format("yyyy-MM-DD") ==
            moment(todayDate).format("yyyy-MM-DD")
        ) {
          return true;
        }
      });
    }
    console.log("selectedBatchessssss", selectedBatches);
    const groupByBatches =
      selectedBatches.length > 0
        ? groupByArray(selectedBatches, "variety")
        : null;
    this.setState({ groupByBatches });
  }

  renderVariety = (cropVal) => {
    const { crops, varieties } = this.props;
    let varietyList = [];
    let varietyOptList = [];
    if (cropVal === "1") {
      for (const key in crops) {
        crops[key].varieties.forEach((key1) => {
          varietyOptList.push(key1);
          varietyList.push(
            <MenuItem key={key1} value={key1}>
              {varieties[key1].name}
            </MenuItem>
          );
        });
      }
    } else if (cropVal && crops[cropVal]) {
      const newVarietyList = crops[cropVal].varieties
        .filter((p) => varieties?.[p]?.isArchived !== true)
        .map((key) => {
          varietyOptList.push(key);
          return (
            <MenuItem key={key} value={key}>
              {varieties[key].name}
            </MenuItem>
          );
        });
      varietyList = [...varietyList, ...newVarietyList];
    }
    if (varietyList.length > 0) {
      const selectAll = <MenuItem value={"All"}>All</MenuItem>;
      varietyList.unshift(selectAll);
    }
    return varietyList;
  };

  renderVarietyVal = (cropVal) => {
    const { crops, varieties } = this.props;
    let varietyOptList = [];
    if (cropVal === "1") {
      for (const key in crops) {
        crops[key].varieties.forEach((key1) => {
          varietyOptList.push(key1);
        });
      }
    } else if (cropVal && crops[cropVal]) {
      varietyOptList = crops[cropVal].varieties.map((key) => {
        return key;
      });
    }
    this.setState({ varietyOptVal: varietyOptList });
    return varietyOptList;
  };

  getCalculatedTable() {
    const { classes, crops, varieties, batches, farm } = this.props;
    const {
      groupByBatches,
      crop,
      selectedVarieties,
      manualEntries,
      customAvgEntries,
    } = this.state;
    const copyBatch = deepCopyObject(batches);
    const batchesArr = Object.keys(copyBatch).map(
      (batcheId) => copyBatch[batcheId]
    );
    const cropVarieties = checkArray(selectedVarieties)
      ? selectedVarieties.filter((p) => p !== "All")
      : crops?.[crop]?.varieties;
    let res = null;
    let totalAvgSum = 0;
    if (groupByBatches !== null) {
      res = Object.keys(groupByBatches).map((groupId) => {
        const harvestDataCon = groupByBatches?.[groupId].map(
          (batch, batchInx) => {
            const batchLocation =
              batch?.growthCycle?.growMedia == "Rockwool"
                ? batch?.finishingLocation?.farm
                : batch?.propLocation1?.farm;
            const task = batch?.tasks?.harvest;
            const cropName = crops[task.crop] ? crops[task.crop].name : "";
            const varietyName = varieties[task.variety]
              ? varieties[task.variety].name
              : "";
            let avgHarvest = 0;
            const latestDateHarvests = batchesArr
              .filter(
                (p) =>
                  cropVarieties.indexOf(p.variety) > -1 &&
                  batch.variety === p.variety &&
                  checkArray(p.harvest)
              )
              .sort(function(a, b) {
                const latestOneIndex = Object.keys(a?.harvest).length - 1;
                const latestTwoIndex = Object.keys(b?.harvest).length - 1;
                return (
                  moment(b?.harvest?.[latestTwoIndex].datetime).unix() -
                  moment(a?.harvest?.[latestOneIndex].datetime).unix()
                );
              });
            if (checkArray(latestDateHarvests)) {
              const latestVarietyHarvest = latestDateHarvests?.[0];
              const laHarvestLastInx = latestVarietyHarvest.harvest.length - 1;
              let totalWeight = 0;
              let totalRafts = 0;
              latestVarietyHarvest.harvest
                .filter(
                  (p) =>
                    moment(p.datetime).format("DD-MM-yyyy") ==
                    moment(
                      latestVarietyHarvest.harvest[laHarvestLastInx].datetime
                    ).format("DD-MM-yyyy")
                )
                .map((laHarvest) => {
                  totalWeight =
                    totalWeight + parseFloat(laHarvest?.weight || 0);
                  totalRafts = totalRafts + parseFloat(laHarvest?.rafts || 0);
                });
              avgHarvest = parseFloat(totalWeight / totalRafts).toFixed(3);
            } else if (customAvgEntries?.[batch?.id]) {
              avgHarvest = parseFloat(customAvgEntries?.[batch?.id]).toFixed(3);
            }
            const sumAvg = avgHarvest * (batch.QtyToSeed || task.quant);
            totalAvgSum += sumAvg;
            return (
              <Card
                className={classes.card}
                // style={{ height: 'auto' || this.state.containerHeight, overflow: this.state.overflow }}
              >
                <CardContent className={classes.cardContent}>
                  <Grid
                    container
                    justify={"space-between"}
                    alignItems="center"
                    className={classes.topRow}
                  >
                    <Grid item className={classes.mainContent}>
                      <Grid
                        container
                        justify="space-between"
                        alignItems="stretch"
                        spacing={16}
                      >
                        <Grid item xs={6}>
                          {" "}
                          {/* middle column */}
                          <Grid
                            container
                            direction="column"
                            justify="center"
                            style={{ height: "100%" }}
                          >
                            <Typography
                              variant="h6"
                              className={classNames(classes.title)}
                            >{`${cropName} - ${varietyName}`}</Typography>
                            {task.date ? (
                              <Typography
                                variant="body2"
                                className={classNames(classes.date)}
                              >
                                Planned Date:{" "}
                                {moment(task.date).format("DD MMM YYYY")}
                              </Typography>
                            ) : null}
                            {batch && batch.seedDate ? (
                              <Typography
                                variant="body2"
                                className={classNames(classes.date)}
                                style={{ marginTop: "0px" }}
                              >
                                Start Date:{" "}
                                {moment(batch.initiationDate).format(
                                  "DD MMM YYYY"
                                )}
                              </Typography>
                            ) : null}
                            <Typography
                              variant="body2"
                              className={classNames(classes.date)}
                              style={{ marginTop: "0px" }}
                            >
                              Location: {farm?.[batchLocation]?.name || "N/A"}
                            </Typography>
                            <Grid
                              container
                              justify="space-between"
                              className={classes.bottomMargin}
                            >
                              <Grid item xs={7}>
                                <Typography
                                  variant="body1"
                                  className={classes.grey}
                                >
                                  Lot:{" "}
                                  {task.updatedGRFlot && task.isLastTask
                                    ? task.updatedGRFlot
                                    : task.GRFlot}
                                </Typography>
                              </Grid>
                              <Grid item xs={5}>
                                <Typography
                                  variant="body1"
                                  className={classes.grey}
                                >
                                  {batch.QtyToSeed || task.quant} {task.unit}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={2}>
                          {" "}
                          {/* middle column */}
                          <Grid
                            container
                            direction="column"
                            justify="center"
                            style={{ height: "100%" }}
                          >
                            <Typography
                              variant="h6"
                              className={classNames(classes.bigTitle)}
                            >{`Avg : ${avgHarvest} lbs`}</Typography>
                          </Grid>
                        </Grid>
                        {avgHarvest == 0 && (
                          <>
                            <Grid item xs={2}>
                              {" "}
                              {/* end column */}
                              <Grid
                                container
                                direction="column"
                                justify="center"
                                style={{ height: "100%" }}
                              >
                                <TextField
                                  required
                                  id={`manual-entry-${batchInx}`}
                                  label="Average Weight"
                                  value={
                                    this.state.manualEntries?.[batch.id] || ""
                                  }
                                  onChange={(e) => {
                                    const newEntryObj = {
                                      ...this.state.manualEntries,
                                    };
                                    newEntryObj[batch.id] =
                                      e?.target?.value || 0;
                                    this.setState({
                                      manualEntries: newEntryObj,
                                    });
                                  }}
                                  margin="dense"
                                  type="number"
                                />
                              </Grid>
                            </Grid>
                            <Grid item xs={2}>
                              {" "}
                              {/* end column */}
                              <Grid
                                container
                                direction="column"
                                justify="center"
                                style={{ height: "100%" }}
                              >
                                <Button
                                  className={classNames(
                                    classes.button,
                                    classes.buttonMarTop
                                  )}
                                  variant="contained"
                                  color="primary"
                                  onClick={() => {
                                    const newEntryObj = {
                                      ...this.state.customAvgEntries,
                                    };
                                    newEntryObj[batch.id] =
                                      this.state?.manualEntries?.[batch.id] ||
                                      0;
                                    this.setState({
                                      customAvgEntries: newEntryObj,
                                    });
                                  }}
                                >
                                  Re-Calculate
                                </Button>
                              </Grid>
                            </Grid>
                          </>
                        )}
                        {avgHarvest !== 0 && (
                          <Grid item xs={4}>
                            {" "}
                            {/* middle column */}
                            <Grid container className={classes.sumContainer}>
                              <Typography
                                variant="h6"
                                className={classNames(classes.bigTitle)}
                              >{`Sum : ${parseFloat(sumAvg).toFixed(
                                3
                              )} lbs`}</Typography>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            );
          }
        );

        return (
          <>
            <Grid>
              <Typography variant="h6" className={classes.sectionTitle}>
                {varieties?.[groupId]?.name}
              </Typography>
            </Grid>
            {harvestDataCon}
          </>
        );
      });
      if (document.getElementById("total-sum")) {
        document.getElementById(
          "total-sum"
        ).innerHTML = `Total Sum : ${parseFloat(totalAvgSum).toFixed(3)} lbs`;
      }
    }
    return res;
  }

  render() {
    const { classes, closeModal, crops, farm } = this.props;
    const { crop, selectedVarieties, selectedFarm, varietyOptVal } = this.state;
    return (
      <div className={classes.modal} tabIndex={-1}>
        <Grid container className={classes.container} spacing={16}>
          <Grid container direction="row" justify="space-between" item xs={12}>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Typography variant="h6" className={classes.sectionTitle}>
                Today's Predicted Harvest
              </Typography>
              <Grid>
                <Tooltip placement="top" title="Close">
                  <i
                    className={`far fa-times-circle fa-2x ${
                      classes.deleteRowButton
                    }`}
                    onClick={() => closeModal()}
                  />
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container className={classes.formRow} spacing={16}>
              <Grid item xs={4} md={2}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <Grid container className={classes.dateInput}>
                    <DatePicker
                      // margin="normal"
                      label="Date"
                      name="Date"
                      minDate={new Date()}
                      format="MM/DD/YYYY"
                      value={this.state.todayDate}
                      onChange={(todayDate) => this.setState({ todayDate })}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={4} md={2}>
                <TextField
                  required
                  select
                  label="Crop"
                  margin="dense"
                  value={crop}
                  onChange={(e) => this.setState({ crop: e?.target?.value })}
                  fullWidth
                >
                  {Object.keys(crops)
                    .sort((a, b) =>
                      sortFunction(crops[a], crops[b], "asc", "name")
                    )
                    .map((cropID) => {
                      const crop = crops[cropID];
                      if (!crop.isArchived)
                        return (
                          <MenuItem
                            className={classes.formRow}
                            key={`${cropID}`}
                            value={cropID}
                          >
                            {crop.name}
                          </MenuItem>
                        );
                    })}
                </TextField>
              </Grid>
              <Grid item xs={4} md={2}>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="select-multiple-variety">
                    Variety
                  </InputLabel>
                  <Select
                    multiple
                    value={selectedVarieties}
                    input={<Input id="select-multiple-variety" />}
                    fullWidth
                    onChange={(e) => {
                      if (
                        e.target.value.indexOf("All") > -1 ||
                        (selectedVarieties.indexOf("All") == -1 &&
                          e.target.value.indexOf("All") == -1 &&
                          e.target.value.length == varietyOptVal.length)
                      ) {
                        this.setState({
                          selectedVarieties: [...["All"], ...varietyOptVal],
                          isVarietySelectAll: true,
                        });
                      } else {
                        if (selectedVarieties.indexOf("All") > -1) {
                          this.setState({
                            selectedVarieties: [],
                            isVarietySelectAll: false,
                          });
                        } else {
                          this.setState({
                            selectedVarieties: e.target.value,
                            isVarietySelectAll: false,
                          });
                        }
                      }
                    }}
                    MenuProps={MenuProps}
                    margin="dense"
                  >
                    {this.renderVariety(crop)}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4} md={2}>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="select-multiple-farm">Farm</InputLabel>
                  <Select
                    multiple
                    value={selectedFarm}
                    input={<Input id="select-multiple-farm" />}
                    label={"Farm"}
                    fullWidth
                    onChange={(e) => {
                      this.setState({ selectedFarm: e.target.value });
                    }}
                    MenuProps={MenuProps}
                    margin="dense"
                  >
                    {Object.keys(farm).map((key) => (
                      <MenuItem key={key} value={key}>
                        {farm[key].name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4} md={2}>
                <Button
                  className={classNames(classes.button, classes.buttonMarTop)}
                  variant="contained"
                  color="primary"
                  onClick={() => this.calculateHarvestData()}
                >
                  Calculate
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid className={classes.sumText}>
              <Typography
                variant="body2"
                className={classNames(classes.date)}
                style={{ marginTop: "0px" }}
                id="total-sum"
              />
            </Grid>
            <Grid container className={classes.formRow} spacing={16}>
              {this.getCalculatedTable()}
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

TodayPredHarvest.propTypes = {
  classes: PropTypes.object.isRequired,
  deleteItem: PropTypes.func.isRequired,
  showSnakbar: PropTypes.func.isRequired,
  batches: PropTypes.object.isRequired,
  crops: PropTypes.object.isRequired,
  users: PropTypes.object.isRequired,
  varieties: PropTypes.object.isRequired,
  clone: PropTypes.bool,
  onClickClone: PropTypes.func,
  removeBatchThunk: PropTypes.func.isRequired,
};

export default withStyles(styles)(
  connect(
    null,
    {
      // pushBatch,
      // pushBatches,
      removeBatchThunk,
      pushPlots,
      pushVarietyLegend,
      // setShouldCalculatePlots,
      // startPlotCalcAfterNumber
    }
  )(TodayPredHarvest)
);

import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';
import red from '@material-ui/core/colors/red';
import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';
import { sortFunction } from '../utils/utils';
import APP_SETTINGS from '../utils/appSettings';

const { HARVEST_TYPES } = APP_SETTINGS;

const styles = (theme) => ({
  card: {
    minWidth: '390px',
    maxWidth: '450px',
    width: '100%',
  },
  cardContent: {
    paddingBottom: '1rem !important',
  },
  growthCycleTitle: {
    display: 'inline-block',
    maxWidth: '185px',
    height: '1.9rem',
    marginRight: '1rem',
    marginBottom: '-0.5rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  primaryCycle: {
    color: theme.palette.primary.main,
    fontSize: '1.5rem'
  },
  nonPrimaryCycle: {
    color: theme.palette.grey[100],
    fontSize: '1.5rem'
  },
  actionIcons: {
    marginRight: '1.5rem',
    cursor: 'pointer',
    '&:last-child': {
      marginRight: 0
    },
    fontSize: '1.5rem'
  },
  blue: {
    color: blue[300]
  },
  red: {
    color: red[300]
  },
  green: {
    color: green[300]
  },
  secondaryColor: {
    color: theme.palette.secondary.light
  },
  divider: {
    margin: '0.8rem 0'
  },
  cycleInfoContainer: {
    width: '65%'
  },
  cycleStep: {
    fontWeight: 'bold',
    marginTop: '0.15rem',
    color: theme.palette.grey[700]
  },
  cycleInfo: {
    marginTop: '0.15rem',
    color: theme.palette.grey[700]
  },
  seedButtonContainer: {
    width: '110px',
    marginBottom: '-8px'
  },
  iconContainer: {
    border: '1px solid',
    borderColor: theme.palette.secondary.main,
    borderRadius: '50%',
    height: '90px',
    width: '90px',
    textAlign: 'center',
    cursor: 'pointer',
    '& i': {
      color: theme.palette.secondary.main,
      lineHeight: '90px'
    }
  },
  autoHeight: {
    height: 'auto'
  }
});

class GrowthCycleCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      isDuplicating: false
    };
    this.handleDuplicateClick = this.handleDuplicateClick.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }

  handleDuplicateClick(e) {
    this.setState({ anchorEl: e.currentTarget })
  }

  async closeMenu(varID) {
    if (varID) {
      this.setState(() => ({ isDuplicating: true, anchorEl: null }));
      await this.props.handleDuplicate({ varID, gcID: this.props.id });
      setTimeout(() => {
        this.setState({ isDuplicating: false });
      }, 500);
    } else {
      this.setState({ anchorEl: null });
    }
  }

  render() {
    const { classes, id, growthCycle, name, className, varieties } = this.props;
    const { germ, prop, hd, fs, avgWeightPerRaft, isPrimary, harvests, harvestInterval, harvestType } = growthCycle;
    return (
      <Card className={classNames(classes.card, className)}>
        <CardContent className={classes.cardContent}>
          <Grid container justify="space-between" alignItems="center">

            <div>
              <Typography color="secondary" variant="h5" className={classes.growthCycleTitle}>{name || growthCycle.name}</Typography>
              {this.props.handlePrimaryClick ?
                <Tooltip placement="top" title={isPrimary ? "Default Growth Cycle" : "Click to make this the Default Cycle"}>
                  <i
                    className={`fas fa-check-circle fa-2x ${isPrimary ? classes.primaryCycle : classes.nonPrimaryCycle}`}
                    onClick={this.props.handlePrimaryClick}
                    style={{ cursor: 'pointer' }}
                  >
                  </i>
                </Tooltip>
                :
                <i
                  className={`fas fa-check-circle fa-2x ${isPrimary ? classes.primaryCycle : classes.nonPrimaryCycle}`}
                  style={{ cursor: 'default' }}
                >
                </i>
              }
            </div>

            {this.props.handleSelectNew &&
              <div>
                <Button size="small" color="primary" variant="text" onClick={this.props.handleSelectNew}>Select New</Button>
              </div>
            }


            <div>
              {this.props.handleEdit &&
                <Tooltip placement="top" title="Edit">
                  <i className={`far fa-edit fa-2x ${classNames(classes.actionIcons, classes.blue)}`} onClick={() => this.props.handleEdit(id)}></i>
                </Tooltip>
              }
              {!growthCycle.isArchived && this.props.handleDelete &&
                <Tooltip placement="top" title={growthCycle.hasBeenUsed ? 'Archive' : 'Delete'}>
                  <i className={`far fa-times-circle fa-2x ${classNames(classes.actionIcons, classes.red)}`} onClick={() => this.props.handleDelete(id)}></i>
                </Tooltip>
              }
              {growthCycle.isArchived && this.props.handleUnArchive &&
                <Tooltip placement="top" title="Un-Archive">
                  <i className={`fas fa-box-open fa-2x ${classNames(classes.actionIcons, classes.secondaryColor)}`} onClick={() => this.props.handleUnArchive(id)}></i>
                </Tooltip>
              }
              {this.props.handleDuplicate &&
                <span>
                  <Tooltip placement="top" title="Duplicate">
                    {this.state.isDuplicating ?
                      <CircularProgress className={classes.green} size={21} />
                      :
                      <i className={`far fa-copy fa-2x ${classNames(classes.actionIcons, classes.green)}`} onClick={this.handleDuplicateClick}></i>
                    }
                  </Tooltip>
                  <Menu
                    id="duplicateMenu"
                    open={!!this.state.anchorEl}
                    anchorEl={this.state.anchorEl}
                    onClose={() => this.closeMenu()}
                    PaperProps={{
                      style: {
                        minWidth: 120
                      }
                    }}
                  >
                    {Object.keys(varieties).sort((a, b) => sortFunction(varieties[a], varieties[b], 'asc', 'name')).map(varID => {
                      return (
                        <MenuItem key={varID} onClick={() => this.closeMenu(varID)}>{varieties[varID].name}</MenuItem>
                      )
                    })}
                  </Menu>
                </span>
              }
            </div>

          </Grid>
          <Divider className={classes.divider} />

          <Grid container justify="space-between" alignItems="center">
            <div className={classes.cycleInfoContainer}> {/* left side */}
              <Grid container>
                {/* backgroundColor: '#E8E8E8' */}
                {/* <div style={{ width: '100%', margin: 10,backgroundColor:'#F5F5F5' }}>

                  <div style={{ flexDirection: 'row' }}>
                      <text style={{ fontWeight: 'bold', fontSize: 12 }}>{growthCycle.name}</text>
                      <text style={{ fontWeight: 'normal', fontSize: 12,marginLeft:10 }}>Germ:</text>
                      <text style={{ fontWeight: 'normal', fontSize: 12 }}>{germ.days} days</text>
                      <text style={{ fontWeight: 'normal', fontSize: 12 }}>{germ.cells} cells</text>
                  </div>
                  <div style={{ flexDirection: 'row' }}>
                    <text style={{ fontWeight: 'normal', fontSize: 12 }}>Prop:</text>
                    <text style={{ fontWeight: 'normal', fontSize: 12 }}>{prop.days} days</text>
                  </div>
                </div> */}
                <Grid item xs={3}>
                    <Typography variant="body2" className={classes.cycleStep}>Germ:</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body2" className={classes.cycleInfo}>{germ.days} days.</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography variant="body2" className={classes.cycleInfo}>{germ.cells} cells</Typography>
                  </Grid>

                <Grid item xs={3}>
                  <Typography variant="body2" className={classes.cycleStep}>Prop:</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="body2" className={classes.cycleInfo}>{prop.days} days</Typography>
                </Grid>

                {hd.days > 0 &&
                  <React.Fragment>
                    <Grid item xs={3}>
                      <Typography variant="body2" className={classes.cycleStep}>HD:</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="body2" className={classes.cycleInfo}>{hd.days ? `${hd.days} days` : ''}</Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography variant="body2" className={classes.cycleInfo}>{hd.endRafts ? `${hd.endRafts} rafts` : ''}</Typography>
                    </Grid>
                  </React.Fragment>
                }

                <Grid item xs={3}>
                  <Typography variant="body2" className={classes.cycleStep}>FS:</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body2" className={classes.cycleInfo}>{fs.days} days</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="body2" className={classes.cycleInfo}>{fs.endRafts} rafts</Typography>
                </Grid>

                {harvestType === HARVEST_TYPES.REPEAT &&
                  <React.Fragment>
                    <Grid item xs={4}>
                      <Typography variant="body2" className={classes.cycleStep}>Harvests:</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="body2" className={classes.cycleInfo}>{harvests}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography variant="body2" className={classes.cycleStep}>Interval:</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography variant="body2" className={classes.cycleInfo}>{harvestInterval} days</Typography>
                    </Grid>
                  </React.Fragment>
                }

                <Grid item xs={6}>
                  <Typography variant="body2" className={classes.cycleStep}>Avg weight/raft:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" className={classes.cycleInfo}>{avgWeightPerRaft ? `${avgWeightPerRaft} lbs` : 'n/a'}</Typography>
                </Grid>

              </Grid>
            </div> {/* End left side (cycleInfoContainer) */}

            <div className={classes.seedButtonContainer}> {/* Seed now button */}
              {this.props.handleSeedNow &&
                <Grid container justify="center" alignItems="center">
                  <div className={classes.iconContainer} onClick={this.props.handleSeedNow}><i className="fas fa-seedling fa-3x"></i></div>
                  <Button variant="text" color="secondary" onClick={this.props.handleSeedNow}>Seed Now</Button>
                </Grid>
              }
            </div>

          </Grid>

        </CardContent>
      </Card>
    );
  }
};

GrowthCycleCard.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  id: PropTypes.string,
  varID: PropTypes.string,
  growthCycle: PropTypes.object.isRequired,
  varieties: PropTypes.object,
  handlePrimaryClick: PropTypes.func,
  handleEdit: PropTypes.func,
  handleDelete: PropTypes.func,
  handleUnArchive: PropTypes.func,
  handleDuplicate: PropTypes.func,
  handleSeedNow: PropTypes.func,
};

export default withStyles(styles)(GrowthCycleCard);
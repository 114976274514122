import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Menu from '@material-ui/core/Menu';
import CircularProgress from '@material-ui/core/CircularProgress';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import { DatePicker } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import { FormControl, InputLabel, Select, withStyles } from '@material-ui/core';
import APP_SETTINGS from '../utils/appSettings';
import writeXlsxFile from 'write-excel-file'
import { checkArray, deepCopyObject, groupByArray, isNumberic } from '../utils/utils';
import moment from 'moment';
import readXlsxFile from 'read-excel-file'
import { updateMultipleBatches } from '../store/actions/adders';

const { TASK_NAMES, UNITS } = APP_SETTINGS;


const styles = theme => ({
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2
  },
  input: {
    width: '100%'
  },
  labelContainer: {
    textAlign: 'center'
  },
  label: {
    fontSize: '1.3rem'
  },
  menuDiv: {
    outline: 'none',
    '&:focus': {
      outline: 'none'
    }
  },
  showCompleted: {
    marginLeft: 0
  },
  buttonContainer: {
    marginBottom: '0.3rem'
  },
  button: {
    marginLeft: '0.5rem',
  },
  topButton: {
    marginRight: '1.5rem'
  },
  buttonIcon: {
    marginRight: '0.6rem'
  },
  buttonLoader: {
    color: theme.palette.primary.main
  },
  formControl: {
    margin: 10,
    minWidth: 120,
  },
});

class GrowToDoSort extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      anchorEl1: null,
      groupByBatches: [],
      showDateRange: props.filterDate === 'daterange',
      show: props.filterDate === 'seeall' ? 3 : props.filterDate === 'today' ? 1 : props.filterDate === 'thisweek' ? 2 : 0
    };
    this.inputRef = React.createRef();
  }

  closeMenu(id) {
    if (id) {
      this.props.handleUserSelect(id)
    }
    setTimeout(() => {
      this.setState({ anchorEl: null });
    }, 200);
  }

  closeMenu1(id) {
    if (id) {
      this.props.handleUserSelect(id)
    }
    setTimeout(() => {
      this.setState({ anchorEl1: null });
    }, 200);
  }

  render() {
    const {
      classes,
      handleChange,
      formatDate,
      startDate,
      endDate,
      handleTodayClick,
      handleThisWeekClick,
      handleSeeAllClick,
      handleCompleteAllClick,
      handleDateRangeClick,
      isAssigningUsers,
      isMultiCompleting,
      filter,
      sort,
      showCompleted,
      selectAll,
      showCompleteAll,
      handleCheckBoxChange,
      className,
      users,
      user,
      onSelectAll,
      markedTasks,
      farm,
      selectedFarm,
      selectedPond,
      changeLocation,
      handlePageSizeChange,
      pageSize,
      allPonds,
      csvDownload,
      openSnackBar,
      varieties,
      crops,
      rafts,
      batches
    } = this.props;

    const excelObj = (val="", isEmpty = false, isHeader = false, colSpan=0) => {
      let res = null;
      if(!isEmpty){
        res = {
          type: String,
          value: val,
          fontStyle: isHeader?'italic':'',
          align: 'center',
          alignVertical: 'bottom',
          height: 20,
          fontSize: 8,
        }
        if(colSpan > 0){
          res.span = colSpan;
        }
      }
      return res;
    }
    const tableExcelObj = (val="", isEmpty = false, isHeader = false, colSpan=0, bgColor = true, borderBtm = false) => {
      let res = null;
      if(!isEmpty){
        res = {
          type: String,
          value: val,
          fontStyle: isHeader?'italic':'',
          align: 'center',
          alignVertical: 'bottom',
          height: 20,
          fontSize: 8,
          backgroundColor: bgColor?'#bfc8d6':'',
          leftBorderColor: "#000000",
          rightBorderColor: "#000000",
          topBorderColor: "#000000",
        }
        if(borderBtm){
          res.bottomBorderColor = "#000000";
        }
        if(colSpan > 0){
          res.span = colSpan;
        }
      }
      return res;
    }

    const createCropHeader = (cropId) => {
      let res = [];
      const cropHeader = crops?.[cropId];
      if(cropHeader){
         res = [
          excelObj('BatchId', false, true),
          excelObj('Lot Number', false, true),
          excelObj('Seed Date', false, true),
          excelObj(cropHeader?.name || '', false, true, 11),
        ];
      }
      console.log("selectedBatchessssss1111:",res);
      return res;
    }

    const getIteratedRows = (noRow, multipleBy = 1, isEmpty = false, bgColor = true, borderBtm = false) => {
      let res = [];
      const checkIndx = 10* (multipleBy-1)
      const checkRow = noRow + checkIndx;
      console.log("selectedBatchessssss5555:", checkIndx, checkRow, noRow, multipleBy);
      for (let index = checkIndx; index < checkRow; index++) {
        res.push(isEmpty?tableExcelObj(``, false, true,1,bgColor,borderBtm):tableExcelObj(`${index+1}`, false, true,1,bgColor,borderBtm));
      }
      return res;
    }


    const HEADER_ROW = [
      {
        value: 'Name',
        fontWeight: 'bold'
      },
      {
        value: 'Date of Birth',
        fontWeight: 'bold'
      },
      {
        value: 'Cost',
        fontWeight: 'bold'
      },
      {
        value: 'Paid',
        fontWeight: 'bold'
      }
    ]
    
    const DATA_ROW_1 = [
      // "Name"
      {
        type: String,
        value: 'John Smith',
        borderColor: "#000000",
        borderStyle: "thick",
      },
    
      // "Date of Birth"
      {
        type: Date,
        value: new Date(),
        format: 'mm/dd/yyyy'
      },
    
      // "Cost"
      {
        type: Number,
        value: 1800
      },
    
      // // "Paid"
      // {
      //   type: Boolean,
      //   value: true
      // }
    ]
    
    const data = [
      HEADER_ROW,
      DATA_ROW_1,
    ];

    const getTodayToBeHarvestData = () => {
      const todayDate = new Date();
      const copyBatch = deepCopyObject(batches);
      const batchesArr = Object.keys(copyBatch).map(
        (batcheId) => copyBatch[batcheId]
      );
      const totalLocations = Object.keys(farm).map((key) => key);
      let selectedBatches = [];
      if (
        varieties && checkArray(Object.keys(varieties))
      ) {
        const allowedFarms = totalLocations;
        selectedBatches = batchesArr.filter((p) => {
          const batchLocation =
            p?.growthCycle?.growMedia == "Rockwool"
              ? p?.finishingLocation?.farm
              : p?.propLocation1?.farm;
          if (
            Object.keys(varieties).indexOf(p.variety) > -1 &&
            allowedFarms.indexOf(batchLocation) > -1 &&
            p?.tasks?.harvest?.isComplete !== true &&
            moment(p?.tasks?.harvest?.date).format("yyyy-MM-DD") ==
              moment(todayDate).format("yyyy-MM-DD")
          ) {
            return true;
          }
        });
      }
      const groupByBatches =
        selectedBatches.length > 0
          ? groupByArray(selectedBatches, "crop")
          : null;
      
      return groupByBatches;
      this.setState({ groupByBatches });
    }

    // console.log('markedTasks11',markedTasks)

    const handleHarvestFileSelect = async(e) => {
      const fileData = e?.target?.files[0];
      // try {
        readXlsxFile(fileData).then(async(rows) => {
          let updatedBatches = {};
          if (rows && checkArray(rows)) {
            rows.splice(0, 3);
            console.log("testtttDataaaa:",rows);
            const batchesCopy = deepCopyObject(batches);
            for (let index = 0; index < rows.length; index++) {
              const batchEl = rows[index];
              const prevBatchEl = rows?.[index-1];
              const batchObjId = batchEl?.[0];
              const batchObjVal = batchesCopy?.[batchObjId];
              if(batchObjVal){
                const isRockWool = batchObjVal.growthCycle.growMedia === 'Rockwool';
                const harvestUnit = batchObjVal?.tasks?.harvest?.unit || "rafts";
                const harvestArr = [];
                // console.log("testtttDataaaa2222:",batchObjVal,batchEl);
                for (let innerIdx = 4; innerIdx < batchEl.length; innerIdx++) {
                  const innerEl = batchEl?.[innerIdx];
                  const prevInnerEl = prevBatchEl?.[innerIdx];
                  const harvestRafts = isNumberic(prevInnerEl);
                  const harvestWeight = isNumberic(innerEl);
                  const pondObjIndx = allPonds.findIndex((p)=>p?.id == selectedPond);
                  if(harvestRafts !== null && harvestWeight !== null && pondObjIndx > -1){
                    const pondObj = allPonds?.[pondObjIndx];
                    const raftSites = isRockWool ? rafts[batchObjVal.growthCycle.fs.raftType].sites : parseFloat(batchObjVal.QtyToSeed);

                    console.log("testtttDataaaa4444",raftSites);
                    const calculatedRafts = harvestUnit === UNITS.RAFTS ? 1 : harvestUnit === UNITS.TRAYS ? 1 / 4 : Math.round((1 / raftSites) * 100) / 100;
                    harvestArr.push({
                      count: harvestRafts,
                      datetime: moment().format(),
                      rafts: calculatedRafts,
                      units: harvestUnit,
                      pond: pondObj,
                      weight: harvestWeight
                    });
                  }
                }
                // console.log("testtttDataaaa3333:",harvestArr);
                batchObjVal.harvest = harvestArr;
                if(isRockWool){
                  batchObjVal.remainingRafts = parseFloat(batchObjVal.remainingRafts - harvestArr.length)>= 0?batchObjVal.remainingRafts - harvestArr.length:0;
                }else{
                  batchObjVal.remainingTrays = parseFloat(batchObjVal.remainingTrays - harvestArr.length)>= 0?batchObjVal.remainingTrays - harvestArr.length:0;
                }
                updatedBatches[batchObjVal.id] = batchObjVal;
                console.log("testtttDataaaa444:",updatedBatches,batchObjVal);
              }
            }
          }
          // console.log("testtttDataaaa555  :",updatedBatches,Object.keys(updatedBatches));

          if(checkArray(Object.keys(updatedBatches))){
            await updateMultipleBatches(updatedBatches);
            openSnackBar("Successfully updated batches!");
          }else{
            openSnackBar("Nothing to update!");
          }
        })
      // } catch (error) {
      //   alert("Invalid file selected");
      // }
    }

    return (
      <Grid container alignItems="center" spacing={16} className={className || ""}>
        <Grid item xs={12} md={7}>
          <Paper className={classes.paper} elevation={1}>
            <Grid container alignItems="flex-end" spacing={16} >
              <Grid item xs={8} className={classes.buttonContainer} style={{ padding: '0.5rem 0' }}>
                <Button
                  variant="text"
                  color="primary"
                  size="small"
                  style={{ backgroundColor: this.state.show === 0 ? 'rgba(0,156,93,0.2)' : '#fff' }}
                  className={classes.button}
                  onClickCapture={() => this.setState({ showDateRange: true, show: 0 })}
                  onClick={handleDateRangeClick}
                >
                  Date Range
                </Button>
                <Button
                  variant="text"
                  color="primary"
                  size="small"
                  style={{ backgroundColor: this.state.show === 1 ? 'rgba(0,156,93,0.2)' : '#fff' }}
                  className={classes.button}
                  onClickCapture={() => this.setState({ showDateRange: false, show: 1 })}
                  onClick={handleTodayClick}
                >
                  Today
                </Button>
                <Button
                  variant="text"
                  color="primary"
                  size="small"
                  style={{ backgroundColor: this.state.show === 2 ? 'rgba(0,156,93,0.2)' : '#fff' }}
                  className={classes.button}
                  onClickCapture={() => this.setState({ showDateRange: false, show: 2 })}
                  onClick={handleThisWeekClick}
                >
                  This Week
                </Button>
                <Button
                  variant="text"
                  color="primary"
                  size="small"
                  style={{ backgroundColor: this.state.show === 3 ? 'rgba(0,156,93,0.2)' : '#fff' }}
                  className={classes.button}
                  onClickCapture={() => this.setState({ showDateRange: false, show: 3 })}
                  onClick={handleSeeAllClick}
                >
                  See All
                </Button>
              </Grid>
            </Grid>
            {this.state.showDateRange ?
              <Grid container direction='row' xs={8} >
                <Grid item xs >
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DatePicker
                      label="Start Date"
                      name='todoStartDate'
                      value={startDate}
                      format="MM/DD/YYYY"
                      // mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                      onChange={(date) => handleChange('startDate')({ target: { value: date } })}
                      onBlur={(e) => formatDate(e)}
                      animateYearScrolling
                      autoOk
                      margin="dense"
                      keyboard
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs style={{ marginLeft: 10 }}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DatePicker
                      label="End Date"
                      name='todoEndDate'
                      value={endDate}
                      format="MM/DD/YYYY"
                      // mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                      onChange={(date) => handleChange('endDate')({ target: { value: date } })}
                      onBlur={(e) => formatDate(e)}
                      animateYearScrolling
                      autoOk
                      margin="dense"
                      keyboard
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
              : null}

            <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-label">Select Farm</InputLabel>
              <Select
                // labelId="demo-simple-select-label"
                id="demo-simple-select"
                name='selectedFarm'
                value={selectedFarm}
                onChange={changeLocation}
              >
                <MenuItem value={''}>{'-'}</MenuItem>
                {farm && Object.keys(farm).map((each) => (
                  <MenuItem value={farm[each].id}>{farm[each].name}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-label">Select Pond</InputLabel>
              <Select
                // labelId="demo-simple-select-label"
                id="demo-simple-select"
                name={'selectedPond'}
                disabled={!selectedFarm}
                onChange={changeLocation}
                value={selectedPond}
              >
                <MenuItem value={''}>{'-'}</MenuItem>
                {allPonds && allPonds.map((pond) => (
                  <MenuItem value={pond.id}>{pond.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-label">Select length</InputLabel>
              <Select
                // labelId="demo-simple-select-label"
                id="demo-simple-select"
                name={'selectedPond'}
                onChange={(e)=>handlePageSizeChange(e.target.value)}
                value={pageSize}
              >
                {[5,10,15,20].map((size) => (
                  <MenuItem value={size}>{size}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Paper>
        </Grid>

        <Grid item xs={12} md={5}>
          <Paper className={classes.paper} elevation={1}>
            <Grid container alignItems="flex-end" spacing={16}>
              <Grid item xs={4}>
                <TextField
                  select
                  id="filter"
                  label="Crop Status"
                  value={filter}
                  onChange={handleChange('filter')}
                  margin="dense"
                  className={classes.input}
                >
                  <MenuItem value={"all"}>All</MenuItem>
                  <MenuItem value={TASK_NAMES.SEED}>Seed</MenuItem>
                  <MenuItem value={TASK_NAMES.START}>Start</MenuItem>
                  <MenuItem value={TASK_NAMES.TRANSFER_PROP}>Transfer Prop</MenuItem>
                  <MenuItem value={TASK_NAMES.TRANSFER_HD}>Transfer HD</MenuItem>
                  <MenuItem value={TASK_NAMES.TRANSFER_FS}>Transfer FS</MenuItem>
                  <MenuItem value={TASK_NAMES.HARVEST}>Harvest</MenuItem>

                </TextField>
              </Grid>

              <Grid item xs={4}>
                <TextField
                  select
                  id="filter"
                  label="Users"
                  value={user}
                  onChange={handleChange('user')}
                  margin="dense"
                  className={classes.input}
                >
                  <MenuItem key={''} value={''}>{''}</MenuItem>
                  {users && Object.keys(users).map(userID => {
                    const userName = `${users[userID].firstName} ${users[userID].lastName}`;
                    return (
                      <MenuItem key={userID} value={userID}>{userName}</MenuItem>
                    );
                  })}

                </TextField>
              </Grid>

              <Grid item xs={4}>
                <TextField
                  select
                  id="sort"
                  label="Sort"
                  value={sort}
                  onChange={handleChange('sort')}
                  margin="dense"
                  className={classes.input}
                >
                  <MenuItem value={"asc"}>Date &nbsp;&nbsp;<i className="fas fa-caret-up"></i></MenuItem>
                  <MenuItem value={"desc"}>Date &nbsp;&nbsp;<i className="fas fa-caret-down"></i></MenuItem>
                </TextField>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6} md={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showCompleted}
                    onChange={handleCheckBoxChange('showCompleted')}
                    value="showCompleted"
                  />
                }
                label="Show Completed"
                className={classes.showCompleted}
              />
              {/* //filter !== TASK_NAMES.HARVEST && */}
              { showCompleteAll && !showCompleted &&
                <Grid container xs={12} direction='row' style={{ minWidth: 800 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectAll}
                        onChange={onSelectAll}
                        value="selectAll"
                      />
                    }
                    label="Select All"
                    className={classes.showCompleted}
                  />

                  {markedTasks.length ? (filter !== TASK_NAMES.HARVEST) && showCompleteAll && !showCompleted &&
                    <Grid item>
                      <Button
                        variant="outlined"
                        size="small"
                        color="primary"
                        className={classes.topButton}
                        style={{ minWidth: 202, minHeight: 42 }}
                        onClick={(e) => this.setState({ anchorEl: e.currentTarget })}
                      >
                        {isAssigningUsers ?
                          <CircularProgress size={21} className={classes.buttonLoader} />
                          :
                          <React.Fragment>
                            <i className={`${classes.buttonIcon} fas fa-user fa-2x`}></i>
                            Assign Tasks to User
                          </React.Fragment>
                        }
                      </Button>
                    </Grid> : null}
                  {/* {console.log('markedTasks12',markedTasks,showCompleteAll,showCompleted)} */}
                  {markedTasks.length ?  showCompleteAll && !showCompleted &&      //(filter !== TASK_NAMES.HARVEST) &&
                    <React.Fragment>
                      <Grid item >
                        <Button
                          variant="outlined"
                          size="small"
                          color="primary"
                          className={classes.topButton}
                          onClick={handleCompleteAllClick}
                          style={{ minWidth: 204, minHeight: 42 }}
                        >
                          {isMultiCompleting ?
                            <CircularProgress size={21} className={classes.buttonLoader} />
                            :
                            <React.Fragment>
                              <i className={`${classes.buttonIcon} fas fa-check fa-2x`}></i>
                              Complete all marked
                            </React.Fragment>
                          }
                        </Button>
                      </Grid>

                      {markedTasks.length ? <Grid item>
                        <Button size='medium' variant="contained" color="secondary" className={classes.button}
                          onClick={csvDownload}
                          style={{ marginRight: 15 }}
                        >
                          CSV <i className="fa fa-download" style={{ marginLeft: 10 }}></i>
                        </Button>
                      </Grid> : null}

                      <Menu
                        id="userSelectMenu"
                        open={!!this.state.anchorEl}
                        anchorEl={this.state.anchorEl}
                        onClose={() => this.closeMenu()}
                      >
                        {users && Object.keys(users).map(userID => {
                          const user = users[userID];
                          return (
                            <MenuItem key={userID} onClick={() => this.closeMenu(userID)}>{`${user.firstName} ${user.lastName}`}</MenuItem>
                          );
                        })}
                      </Menu>
                    </React.Fragment> : null}
                    <Button
                      variant="text"
                      color="primary"
                      size="small"
                      style={{ backgroundColor: '#fff' }}
                      className={classes.button}
                      onClick={async()=>{
                        let excelData = [];
                        const groupByBatches = getTodayToBeHarvestData();
                        if(groupByBatches && checkArray(Object.keys(groupByBatches))){
                          Object.keys(groupByBatches).map((groupByBatch, groupByBatchIndx)=>{
                            const cropHead = createCropHeader(groupByBatch);
                            if(checkArray(cropHead)){
                              // excelData = [...excelData,...cropHead]
                              excelData.push([]);
                              excelData.push([]);
                              excelData.push(cropHead);
                              excelData.push([]);
                            }
                            const batchArr = groupByBatches?.[groupByBatch];
                            if(batchArr && checkArray(batchArr)){
                              batchArr.map((batchObj)=>{
                                const isRockWool = batchObj.growthCycle.growMedia === 'Rockwool';
                                const remainingRafts = isRockWool === true
                                    ? batchObj?.remainingRafts 
                                    : batchObj?.remainingTrays;
                                const checkRemainingRafts = Math.ceil(remainingRafts/10);    
                                const ceilRemainingRafts = Math.ceil(remainingRafts);    
                                console.log("selectedBatchessssss2222", batchObj);
                                for (let index = 1; index <= checkRemainingRafts; index++) {
                                  const checkIsLastRow = groupByBatchIndx === Object.keys(groupByBatches).length - 1 && index === checkRemainingRafts;
                                  const iteratedVal = getIteratedRows(index === checkRemainingRafts? parseInt((ceilRemainingRafts+10) - (checkRemainingRafts*10)) : 10, index);
                                  const emptyIteratedVue = getIteratedRows(index === checkRemainingRafts?10 - parseInt((ceilRemainingRafts+10) - (checkRemainingRafts*10)):0, 1, true);
                                  console.log("selectedBatchessssss4444", emptyIteratedVue, iteratedVal);
                                  
                                  excelData.push([
                                    excelObj('', true),
                                    excelObj('', true),
                                    excelObj('', true),
                                    tableExcelObj('# TBH', false, true,1,true,checkIsLastRow),
                                    ...iteratedVal,
                                    ...emptyIteratedVue
                                  ])
                                  const emptyIteratedVal = getIteratedRows(10, 1, true, false,checkIsLastRow);
                                  excelData.push([
                                    excelObj(batchObj?.id || '', false, true),
                                    excelObj(batchObj?.tasks?.harvest?.GRFlot || '', false, true),
                                    excelObj(batchObj?.seedDate || '', false, true),
                                    tableExcelObj(varieties?.[batchObj?.variety]?.name || '', false, true,1,false,checkIsLastRow),
                                    ...emptyIteratedVal
                                  ])
                                }    
                              })
                            }
                          });
                          // excelData.push(DATA_ROW_1)
                          console.log("selectedBatchessssss0000", excelData, data);

                          await writeXlsxFile(excelData, {
                            fileName: `today-harvest-crop-${moment().format("YYYY-DD-MM")}.xlsx`
                          })
                        }else{
                          alert("No harvest data available today!");
                        }
                      }}
                    >
                      Today's Harvest
                    </Button> 
                    <span>
                      <input
                        type="file"
                        id="data-import"
                        name="data-import"
                        accept=".xlsx"
                        style={{ display: 'none' }}
                        ref={this.inputRef}
                        onChange={(e)=> handleHarvestFileSelect(e)}
                      />
                      <Button variant="outlined" size="small" color="secondary" className={classes.topButton} onClick={() => {
                          if(!selectedPond){
                            return alert("Please select pond!");
                          }
                          this.inputRef.current.click()
                        }}>
                        <i className={`${classes.buttonIcon} fas fa-plus-circle fa-2x`}></i>
                        Import Harvest data
                      </Button>
                    </span> 
                </Grid>
              }
            </Grid>


          </Grid>
        </Grid>


      </Grid>
    );
  }
};

GrowToDoSort.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  farm: PropTypes.any,
  users: PropTypes.object.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  filter: PropTypes.string.isRequired,
  sort: PropTypes.string.isRequired,
  showCompleted: PropTypes.bool.isRequired,
  showCompleteAll: PropTypes.bool.isRequired,
  isAssigningUsers: PropTypes.bool.isRequired,
  isMultiCompleting: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleCheckBoxChange: PropTypes.func.isRequired,
  handleTodayClick: PropTypes.func.isRequired,
  handleThisWeekClick: PropTypes.func.isRequired,
  handleSeeAllClick: PropTypes.func.isRequired,
  handleCompleteAllClick: PropTypes.func.isRequired,
  allPonds: PropTypes.array.isRequired,

};

export default withStyles(styles)(GrowToDoSort);
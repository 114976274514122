import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import AvatarInput from './AvatarInput';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import Collapse from '@material-ui/core/Collapse';
import ComboBox from '../components/ComboBox';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ChromePicker } from 'react-color';
import { withStyles, Modal, FormControl, InputLabel, Select } from '@material-ui/core';
import classNames from 'classnames';
import { deepCopyObject, fillNumberStringToLength } from '../utils/utils';

import APP_SETTINGS from '../utils/appSettings';
import UserContext from '../context_providers/UserContext';
import PasswordModal from './PasswordModal';
import { blue } from '@material-ui/core/colors';

const { SEED_TYPES, USER_ROLES } = APP_SETTINGS;


const styles = theme => ({
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    minWidth: 650,
    maxWidth: 700,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    '&:focus': {
      outline: 'none'
    },
    overflow: 'auto',
    maxHeight: 600,
  },
  container: {
    width: '100%'
  },
  title: {
    marginBottom: '1rem',
    display: 'inline'
  },
  cropName: {
    color: theme.palette.grey[500],
    marginLeft: '1rem'
  },
  input: {
    width: '100%'
  },
  buttonContainer: {
    textAlign: 'right',
    marginTop: '1.5rem'
  },
  avatar: {
    width: 95,
    height: 95,
    marginTop: '1rem',
    cursor: 'pointer'
  },
  slider: {
    width: 95,
    margin: '1rem 0'
  },
  avatarSave: {
    marginTop: '1.2rem'
  },
  colorSquare: {
    width: 56,
    height: 56,
    borderRadius: 5,
    marginTop: '1rem',
    cursor: 'pointer'
  },
  link1: {
    color: blue[500],
    fontSize: 15,
    cursor: 'pointer',
  },
  picker: {
    boxShadow: 'none !important'
  },
  submitButtonLoader: {
    color: 'white'
  },
  button: {
    marginLeft: '1rem'
  }
});

class VarietyModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      varietyCode: '',
      notes: '',
      avatarURL: 'https://firebasestorage.googleapis.com/v0/b/greener-roots.appspot.com/o/avatars%2Fdefault-avatar.jpg?alt=media&token=cb7449fe-370b-4ab9-bf37-410b035b1ce2',
      newAvatar: null,
      newAvatarObject: null,
      color: '#36712F',
      crop: '',
      cropName: '',
      cropType: '',
      seedType: '',
      growthCycles: [],
      trackStock: false,
      minStock: 0,
      action: 'new',
      colorPickIsOpen: false,
      isSaving: false,
      errors: {
        name: false,
        seedType: false,
        varietyCode: false,
        alias: false,
        code: false
      },
      errorMessages: {
        name: '',
        seedType: '',
        varietyCode: '',
        alias: '',
        code: ''
      },
      alias: '',
      code: '',
      lotNo: '',
      modalOpen: false,
      newVariety: false,
      selectedVarietyCode: -1,

      //new fields ,
      currentStockWeight: 0,
      trackByCells: true,
      trackByWeight: false,
      weightType: 'Gram',

    };
    this.handleChange = this.handleChange.bind(this);
    this.validateVarietyCode = this.validateVarietyCode.bind(this);
    this.handleComboBoxChange = this.handleComboBoxChange.bind(this);

    this.saveAvatar = this.saveAvatar.bind(this);
    this.openColorPicker = this.openColorPicker.bind(this);
    this.handleColorChange = this.handleColorChange.bind(this);
    this.closeColorPicker = this.closeColorPicker.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  componentDidMount() {
    // if a variety object is received, assign to state, set action as 'edit';
    if (this.props.variety) {
      const { name, varietyCode, notes, avatarURL, color, cropType, seedType, trackStock, minStock, lotNo, growthCycles, trackByCells } = this.props.variety;
      this.setState({ name, varietyCode, notes, color, cropType, seedType, trackStock, minStock, lotNo, action: 'edit', growthCycles, trackByCells, trackByWeight: !trackByCells });
      if (avatarURL) {
        this.setState({ avatarURL });
      }
    }
    const crop = this.props.crop.id;
    const cropName = this.props.crop.name;
    this.setState({ crop, cropName });
  }

  handleChange = name => e => {

    if (this.state.errors[name]) {
      this.setState({
        errors: { ...this.state.error, [name]: false },
        errorMessages: { ...this.state.errorMessages, [name]: '' }
      });
    }
    if (e.target.type === 'checkbox') {
      this.setState({ [name]: e.target.checked })
    } else {
      const val = e.target.type === "number" && e.target.value ? parseInt(e.target.value) : e.target.value;
      this.setState({ [name]: val });
    }
  };

  validateVarietyCode() {
    this.setState({ varietyCode: fillNumberStringToLength(this.state.varietyCode, 2) });
  }

  handleComboBoxChange = (changes, name) => {
    if (changes.hasOwnProperty('selectedItem')) {
      this.setState({ [name]: changes.selectedItem })
    } else if (changes.hasOwnProperty('inputValue')) {
      this.setState({ [name]: changes.inputValue })
    }
  }

  openColorPicker() {
    this.setState({ colorPickIsOpen: true });
  }

  handleColorChange(color) {
    this.setState({ color: color.hex });
  }

  closeColorPicker() {
    this.setState({ colorPickIsOpen: false });
  }

  saveAvatar(canvasElement) {
    this.setState({ newAvatarObject: canvasElement });
    canvasElement.toBlob(blob => {
      const url = URL.createObjectURL(blob);
      this.setState({ newAvatar: url });
    });
  }

  async handleSave(e) {
    e.preventDefault();

    // Check for required fields
    const requiredFields = this.state.newVariety ? ['name', 'seedType', 'alias', 'code'] : ['name', 'seedType', 'varietyCode'];
    let errorsFound = false;
    let errors = deepCopyObject(this.state.errors);
    requiredFields.forEach((name) => {
      if (!this.state[name] || this.state[name] === '') {
        errorsFound = true;
        errors[name] = true;
      }
    });

    if (errorsFound) {
      this.setState({ errors });
      return;
    }

    // Make sure variety code hasn't already been used
    if (
      // this.props.variety &&
      // this.props.variety.varietyCode !== this.state.varietyCode &&
      this.state.newVariety ? this.props.varietyAliasIsAlreadyUsed(this.state.alias, fillNumberStringToLength(this.state.code, 2)) : false
    ) {
      this.setState({
        errors: { ...this.state.errors, code: true, alias: true },
        errorMessages: { ...this.state.errorMessages, varietyCode: 'Already used' }
      });
      return;
    }
    // this.setState({ modalOpen: true, currentModal: 'password' });

    setTimeout(() => {
      this.onSubmit()

    }, 400);

    return

  }

  closeModal = () => {
    this.setState({ modalOpen: false, currentModal: '' })
  }

  onSubmit = async () => {

    this.setState({ isSaving: true });
    const {
      name,
      varietyCode,
      notes,
      avatarURL,
      newAvatarObject,
      color,
      crop,
      cropType,
      seedType,
      trackStock,
      minStock,
      lotNo,
      growthCycles

    } = this.state;
    let finalQty = minStock;
    if (this.state.trackByCells === false) {
      if (this.state.weightType === 'Ounce') {
        finalQty = finalQty * 28.3495
      }
      else if (this.state.weightType === 'Pound') {
        finalQty = finalQty * 453.592
      }
    }

    const variety = {
      name,
      varietyCode,
      notes,
      avatarURL,
      newAvatarObject,
      color,
      crop,
      cropType,
      seedType,
      trackStock,
      minStock :  parseFloat(finalQty).toFixed(2),
      lotNo,
      growthCycles,
      currentStock: this.props.variety ? (this.props.variety.currentStock || 0) : 0,
      currentStockWeight: this.props.variety ? (this.props.variety.currentStockWeight || 0) : 0,
      trackByCells: this.state.trackByCells
    };
    // add new cropType if a new one has been entered
    if (!this.props.cropTypes.includes(cropType) && cropType !== '') {
      this.props.saveCropType(cropType);
    }

    // save the Variety
    if (this.state.newVariety) { variety.varietyCode = this.state.alias + this.state.code }
    let _varietyCode = { name: this.state.name, code: fillNumberStringToLength(this.state.code, 2), alias: this.state.alias, alreadyUsed: true }
    await this.props.onSave(variety, this.state.newVariety, this.state.newVariety ? _varietyCode : this.state.selectedVarietyCode);
  }

  renderModal() {
    switch (this.state.currentModal) {
      case 'password':
        return (
          <PasswordModal
            title="Confirm changes"
            handleSubmit={this.onSubmit}
            handleCancel={this.closeModal}
          />
        );

      default:
        return <div>This is not the modal you're looking for.</div>
    }
  }
  getVaritycodes = (data) => {

    let arr = [];
    arr = data.map(each => {
      return each.alias + each.code + ' ' + each.name
    })

    return arr;
  }
  render() {
    const { classes, cropTypes, closeModal, varietyCodes } = this.props;

    const action = this.state.action.replace(/\b\w/g, l => l.toUpperCase());
    return (
      <div className={classes.modal} tabIndex={-1}>
        <form onSubmit={this.handleSave}>
          <UserContext.Consumer>
            {({ user }) =>
              <>
                <Grid container className={classes.container} alignItems="baseline">
                  <Typography variant="h4" id="modal-title" className={classes.title}>{action} Variety</Typography>
                  <Typography variant="h5" className={classNames(classes.title, classes.cropName)}><i>({this.state.cropName})</i></Typography>
                </Grid>

                <Grid container className={classes.container} spacing={16} style={{ paddingLeft: 0 }}>
                  <Grid item xs={3} style={{ paddingLeft: 0 }}>
                    <Grid container justify="center" alignItems="center">
                      <AvatarInput
                        avatarURL={this.state.newAvatar || this.state.avatarURL}
                        alt={`${this.state.name}`}
                        onSave={this.saveAvatar}
                        dimensionSize={100}
                        editBorder={10}
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={9}>
                    <Grid container className={classes.container} spacing={16}>
                      <Grid item xs={6}></Grid>
                      <Grid item xs={6}>
                        <FormControlLabel
                          onClick={() => this.setState({ newVariety: !this.state.newVariety })}
                          control={
                            <Typography variant="h6" className={classes.link1}>{this.state.newVariety ? `Select variety Code` : `New Variety Code`}</Typography>
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid container className={classes.container} spacing={16}>
                      <Grid item xs={5}>
                        <TextField
                          required
                          autoFocus
                          error={this.state.errors.name}
                          variant="filled"
                          id="name"
                          label="Name"
                          value={this.state.name}
                          onChange={this.handleChange('name')}
                          margin="normal"
                          className={classes.input}
                        />
                      </Grid>
                      {/* <Grid item xs={1}></Grid> */}
                      <Grid item xs={4}>
                        {!this.state.newVariety ? <TextField
                          required
                          error={this.state.errors.varietyCode}
                          select
                          disabled={this.state.action === 'Edit'}
                          variant="filled"
                          id="varietyCode"
                          label="Variety Code"
                          value={this.state.varietyCode}
                          onChange={this.handleChange('varietyCode')}
                          margin="normal"
                          className={classes.input}
                        >
                          {varietyCodes && varietyCodes.map((each, i) => { return (<MenuItem onClickCapture={() => this.setState({ selectedVarietyCode: i })} value={each.alias + each.code}>{each.name}-{each.alias + each.code}</MenuItem>) })}
                        </TextField> :
                          <TextField
                            required
                            error={this.state.errors.code}
                            disabled={this.props.variety ? this.props.variety.hasBeenUsed : false}
                            variant="filled"
                            id="code"
                            label="Code"
                            helperText={this.state.errorMessages.code}
                            value={this.state.code}
                            onChange={this.handleChange('code')}
                            margin="normal"
                            type={'number'}
                            onInput={(e) => {
                              e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 2)
                            }}
                            className={classes.input}
                          />}

                      </Grid>

                      <Grid item xs={2}>
                        <div style={{ background: this.state.color }} className={classes.colorSquare} onClick={this.openColorPicker}></div>
                        <Dialog
                          open={this.state.colorPickIsOpen}
                          onClose={this.closeColorPicker}

                        >
                          <ChromePicker
                            name='color'
                            defaultValue='#000'
                            color={this.state.color}
                            disableAlpha
                            onChangeComplete={this.handleColorChange}
                            className={classes.picker}
                          />

                          <DialogActions>
                            <Button onClick={this.closeColorPicker} color="primary">
                              OK
                            </Button>
                          </DialogActions>
                        </Dialog>

                      </Grid>
                    </Grid> {/* end first row of inputs */}


                    <Grid container className={classes.container} spacing={16}>

                      <Grid item xs={4}>
                        <Collapse in={this.state.newVariety}>
                          <TextField
                            required
                            autoFocus
                            error={this.state.errors.alias}
                            variant="filled"
                            id="name"
                            inputProps={{ maxLength: 2 }}
                            label="Alias"
                            value={this.state.alias}
                            onChange={this.handleChange('alias')}
                            margin="normal"
                            className={classes.input}
                          />
                        </Collapse>
                      </Grid>
                    </Grid>

                    <Grid container className={classes.container} spacing={16}>

                      <Grid item xs={5}>
                        <TextField
                          required
                          error={this.state.errors.seedType}
                          select
                          variant="filled"
                          id="seedType"
                          label="Seed Type"
                          value={this.state.seedType}
                          onChange={this.handleChange('seedType')}
                          margin="normal"
                          className={classes.input}
                        >
                          <MenuItem value={SEED_TYPES.PELLETED}>Pelleted</MenuItem>
                          <MenuItem value={SEED_TYPES.RAW}>Raw</MenuItem>

                        </TextField>
                      </Grid>
                      {console.log('useruser', user)}
                      <Grid item xs={6}>
                        <TextField
                          // type="number"
                          error={this.state.errors.lotNo}
                          variant="filled"
                          disabled={user.role !== USER_ROLES.ADMIN}
                          id="lotNo"
                          label="Lot No"
                          value={this.state.lotNo}
                          onChange={this.handleChange('lotNo')}
                          margin="normal"
                          className={classes.input}
                        />
                        {/* this.handleChange('lotNo') */}
                      </Grid>
                      {/* <Grid item xs={1}></Grid> */}

                    </Grid> {/* End second row of inputs */}
                  </Grid>

                </Grid> {/* End container for avatar and first two rows of inputs */}


                <Grid container className={classes.container} spacing={16}>
                  <Grid item xs={3}></Grid>
                  <Grid item xs={4}>
                    <ComboBox
                      id="cropType"
                      label="Crop Type"
                      variant="filled"
                      items={cropTypes}
                      onChange={this.handleComboBoxChange}
                      value={this.state.cropType}
                    />
                  </Grid>
                </Grid>
                <Grid container className={classes.container} spacing={16}>
                  <Grid item xs={1}></Grid>
                  <Grid item xs={4}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.trackStock}
                          onChange={this.handleChange('trackStock')}
                          value="trackStock"
                        />
                      }
                      label="Track for Inventory"
                    />
                  </Grid>
                  {this.state.trackStock ?
                    <Grid container xs={7} alignItems={'center'} style={{ borderWidth: 1, padding: 5 }}>
                      <Grid item xs={4}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.trackByCells}
                              onChange={() => {
                                this.setState({ trackByCells: !this.state.trackByCells })
                                this.setState({ trackByWeight: !this.state.trackByWeight })
                              }}
                              value="trackByCells"
                            />
                          }
                          label="by Cells"
                        />
                      </Grid>
                      <Grid item xs={7}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.trackByWeight}
                              onChange={() => {
                                this.setState({ trackByWeight: !this.state.trackByWeight })
                                this.setState({ trackByCells: !this.state.trackByCells })
                              }}
                              value="trackByWeight"
                            />
                          }
                          label="by Weight"
                        />
                      </Grid>
                    </Grid> : null}
                </Grid>
                {action === 'Edit' ? null : <Grid container className={classes.container} spacing={16}>
                  <Grid item xs={1}></Grid>
                  <Grid item xs={8}>
                    <Collapse in={this.state.trackStock}>
                      <TextField
                        type="number"
                        id="minStock"
                        label={`Minimum ${this.state.trackByCells ? 'Stock' : 'Weight'}`}
                        value={this.state.minStock}
                        onChange={this.handleChange('minStock')}
                        margin="normal"

                      />
                      {this.state.trackByCells ? null : <FormControl style={{ marginLeft: '15px', marginTop: '16px' }}>
                        <InputLabel id="demo-simple-select-label">Type</InputLabel>
                        <Select
                          // labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={this.state.weightType}
                          onChange={(e) => {
                            this.setState({ weightType: e.target.value })
                          }}
                        >
                          <MenuItem value='Gram'>Gram</MenuItem>
                          <MenuItem value='Ounce'>Ounce</MenuItem>
                          <MenuItem value='Pound'>Pound</MenuItem>
                        </Select>
                      </FormControl>}
                    </Collapse>
                  </Grid>
                </Grid>}

                <Grid container className={classes.container} spacing={16}>
                  <Grid item xs={12}>
                    <TextField
                      multiline
                      variant="outlined"
                      id="notes"
                      label="Notes"
                      value={this.state.notes}
                      onChange={this.handleChange('notes')}
                      margin="dense"
                      rowsMax="4"
                      className={classes.smallInput}
                      fullWidth
                    />
                  </Grid>

                </Grid> {/* end form inputs */}

                <Grid container className={classes.container} justify="flex-end" spacing={16}>
                  <Grid item xs={6} className={classes.buttonContainer}>
                    <Button variant="contained" color="default" onClick={closeModal} className={classes.button}>
                      Cancel
                    </Button>
                    <Button variant="contained" color="secondary" type="submit" onClick={this.handleSave} className={classes.button}>
                      {this.state.isSaving ?
                        <CircularProgress className={classes.submitButtonLoader} size={21} />
                        :
                        'Save'
                      }
                    </Button>
                  </Grid>
                </Grid>
                <Modal
                  aria-labelledby="modal-title"
                  open={this.state.modalOpen}
                  onClose={this.closeModal}
                >
                  {this.renderModal()}
                </Modal>
              </>
            }
          </UserContext.Consumer>
        </form>
      </div>
    )
  }
};

VarietyModal.defaultProps = {
  cropTypes: []
};

VarietyModal.propTypes = {
  classes: PropTypes.object.isRequired,
  variety: PropTypes.object,
  crop: PropTypes.object,
  cropTypes: PropTypes.array.isRequired,
  varietyCodes: PropTypes.array.isRequired,
  varietyCodeIsAlreadyUsed: PropTypes.func.isRequired,
  varietyAliasIsAlreadyUsed: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  saveCropType: PropTypes.func.isRequired
};

export default withStyles(styles)(VarietyModal);
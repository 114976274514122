import { LOAD_TASKS, ADD_TASKS, EDIT_TASK, DELETE_TASKS, DELETE_TASK } from '../actionTypes';
import { deepCopyObject } from '../../utils/utils';

export default (state = {}, action) => {
  switch (action.type) {
    case LOAD_TASKS:
      let newState = deepCopyObject(state);
      for (let taskID in state) {
        if (taskID.includes(action.batchID)) {
          delete newState[taskID];
        }
      }
      for (let taskID in action.tasks) {
        newState[`${action.batchID}-${taskID}`] = action.tasks[taskID];
      }
      return newState;

    case ADD_TASKS:
      return {...state, ...action.tasks};
    
    case EDIT_TASK:
      return {...state, ...action.task};

    case DELETE_TASK:
      newState = deepCopyObject(state);
      delete newState[action.taskID];
      return newState;

    case DELETE_TASKS:
      newState = deepCopyObject(state);
      for (let taskID in state) {
        if (taskID.includes(action.batchID)) {
          delete newState[taskID];
        }
      }
      return newState;

    default:
      return state;
  }
};
import { LOAD_FARMS, ADD_EDIT_FARM, DELETE_FARM } from '../actionTypes';
import { deepCopyObject } from '../../utils/utils';

export default (state = {}, action) => {
  switch (action.type) {
    case LOAD_FARMS:
      return {...action.farms};

    case ADD_EDIT_FARM:
      // merges received key-val pairs with existing ones for the item in question
      const key = Object.keys(action.farm)[0];
      let currFarmObj = state[key] ? deepCopyObject(state[key]) : {};
      currFarmObj = {...currFarmObj, ...action.farm[key]};
      return {...state, [key]: currFarmObj};

    case DELETE_FARM:
      let newState = deepCopyObject(state);
      delete newState[action.id];
      return newState;

    default:
      return state;
  }
};
import { Grid, Typography } from '@material-ui/core';
import React, { Component } from 'react';
class FinishingPondCard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            checked: false,
            available: false
        };
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.pondType){
            this.setState({checked: false})
        }
    }

    render() {

        const { each, checkAvailabilityPond, pondType, data } = this.props;
        const { checked, available } = this.state;
        return (
            each.pond ? each.noOfRaft ? !checked ? <Grid item xs={4} md={3} style={{ marginLeft: 25 }}>
                <Typography onClick={() => {
                    checkAvailabilityPond({
                        batchData: data,
                        pondData: each,
                        mode: pondType,
                        // toFill: parseInt(each.noOfRaft || 0)
                    }).then(available => {
                        this.setState({ checked: true, available  : available.SpaceAvailable })
                    })

                }} style={{ color: 'blue', cursor: 'pointer', marginTop: 25 }}>Check pond availability</Typography>
            </Grid>
                :
                <Grid container xs={4} md={3} direction={'row'} style={{ marginTop: 25 }}>
                    <Typography variant="body1" style={{color:available ? 'green' : 'red'}}>{checked ? available ? 'Available' : 'Overfill' : ''}</Typography>
                    <Typography variant='body1' onClick={() => {
                        checkAvailabilityPond({
                            batchData: data,
                            pondData: each,
                            mode: pondType,
                            // toFill: parseInt(each.noOfRaft || 0)
                        }).then(available => {
                            this.setState({ checked: true, available  : available.SpaceAvailable })
                        })
                    }} style={{ marginLeft: 15, color: 'blue', cursor: 'pointer' }}>refresh</Typography>

                </Grid>
                : <></> : <></>
        )

    }

}

export default FinishingPondCard
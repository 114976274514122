import React from 'react';
import './App.css'
import { Provider } from 'react-redux';
import { configureStore } from './store';
import { BrowserRouter as Router } from 'react-router-dom';
import Menu from './containers/Menu';
import CssBaseline from '@material-ui/core/CssBaseline';
import Main from './containers/Main';
import FullScreenLoader from './components/FullScreenLoader';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
// import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import * as firebaseui from 'firebaseui';
import { loadDB } from './store/actions/loaders';
import { importData } from './store/actions/import';
import UserContext from './context_providers/UserContext';
import DevContext from './context_providers/DevContext';
import APP_SETTINGS from './utils/appSettings';
import * as env from './env.json';
import users from './store/reducers/users';
import { addEditUser } from './store/actions/adders';
import {
  Button,
  TextField, 
  Grid,
  Paper,
  AppBar,
  Typography,
  Toolbar,
  Link,
} from "@material-ui/core";
const { COLLECTIONS } = APP_SETTINGS;

const styles = {
  loginForm: {
    justifyContent: 'center',
    minHeight: '90vh',
  },
  appBar: {
    backgroundColor: '#269E5D'
  },
  buttonBlock: {
    width: '100%',
    backgroundColor: '#269E5D'
  },
  loginBackground: {
    justifyContent: 'center',
    minHeight: '20vh',
    minWidth: '50vh',
    maxWidth: '100vh',
    padding: '50px',
  },
  heading: {
    color: '#fff'
  },
  marginTop: { marginTop: '20px' },
  error: {
    color: 'red',
    marginTop: '15px'
  },
  forgotText: {
    color: 'blue',
    cursor: 'pointer'
  }
};

// when devMode is true, the 'playground' firebase database is used; also shows helpful buttons on Grow page
const devMode = env.devMode;

const store = configureStore();

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    //http://paletton.com/#uid=72t0V0klPr8ciFchCv3pZnsttiF
    primary: { light: '#8DC63F', main: '#329E57' },
    secondary: { main: '#15919C' }, // or 2D7586?
    background: { default: '#fff' },
  },
  status: {
    danger: '#EF0050',
  },
});


const app = firebase.initializeApp(devMode ? APP_SETTINGS.FIREBASE_NEW_CONFIG  : APP_SETTINGS.FIREBASE_PRODUCTION);

// const app = firebase.initializeApp(devMode ? APP_SETTINGS.FIREBASE_PLAYGROUND_CONFIG : APP_SETTINGS.FIREBASE_CONFIG);

const auth = app.auth();
auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);

const uiConfig = {
  callbacks: {
    signInSuccessWithAuthResult: function () {
      return false;
    }
  },
  signInOptions: [
    firebase.auth.EmailAuthProvider.PROVIDER_ID
  ],
  signInSuccessUrl: '/',
  credentialHelper: firebaseui.auth.CredentialHelper.NONE
}

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      drawerOpen: true,
      loggedIn: false,
      isLoaded: false,
      user: null,
      username: "", password: "",
      authflag: 1,
      error: "",
      signUp: false,
      choosePassword: "",
      confirmPassword: "",
      showPassword: false,
      usernameError: false,
      signinError: false,
      forgotPasswordCase: false
    };
    this.toggleDrawer = this.toggleDrawer.bind(this);
    this.handleDataImport = this.handleDataImport.bind(this);
  }

  async componentDidMount() {
    // let a = localStorage.getItem('batches');

    
    this.getUserData()
  }

  handleChange = (event) => {
    this.setState({ username: event.state.username, password: event.state.password });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const { password, username, showPassword, signUp, choosePassword, confirmPassword, forgotPasswordCase } = this.state;

    if (forgotPasswordCase) {
      return auth.sendPasswordResetEmail(username)
        .then((user) => {
          alert(`Please check your email ${username}`)
          this.setState({ forgotPasswordCase: false })
        }).catch(function (e) {
          console.log(e)
        })
    }

    if (signUp) {
      if (choosePassword !== confirmPassword) {
        return this.setState({ error: 'password and confirm password must match', signinError: true })
      }
    }

    // const { user } = await auth.createUserWithEmailAndPassword(email, password);
    auth.signInWithEmailAndPassword(username, password || ' ').then(resp => {

      console.log(resp)

      if (resp.user.uid) {
        // this.props.history.push('/')
      }
    }).catch((error) => {

      console.log(error)
      if (error.code === "auth/wrong-password") {
        if (password) {
          this.setState({ error: error.message, signinError: true })
        }
        else {
          if (!showPassword) {
            this.setState({ showPassword: true, usernameError: false })
          }
          else {
            this.setState({ signinError: true, error: error.message, })
          }
        }
      }
      else if (error.code === "auth/user-not-found") {
        if (signUp) {
          auth.createUserWithEmailAndPassword(username, choosePassword).then(async (user) => {
            const { displayName, email, uid } = user.user

            await addEditUser({
              user: {
                firstName: this.state.name.split(' ').slice(0, -1).join(' '),
                lastName: this.state.name.split(' ').slice(-1).join(' '),
                role: 'user',
                position: '',
                email: email,
                phone: null,
                avatarURL: null,
                approved: false,
                alottedTask: []
              },
              id: uid
            }).then(resp => {
              this.setState({
                username: "",
                password: "",
                authflag: 1,
                error: "",
                signUp: false,
                choosePassword: "",
                confirmPassword: "",
                showPassword: false,
                usernameError: false,
                signinError: false
              })
              setTimeout(() => {
                alert('Account has been created although pending for admin approval')
              }, 250);
            });

            // auth.signInWithEmailAndPassword(username, choosePassword).catch(function (error) {
            // });
          }).catch((error) => {
            console.log(error)
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            if (errorCode == 'email-already-in-use') {
              return this.setState({ error: 'You already have an account with that email.', signinError: true })
            } else if (errorCode == 'auth/invalid-email') {
              return this.setState({ error: 'Please provide a valid email', signinError: true })
            } else if (errorCode == 'auth/weak-password') {
              return this.setState({ error: 'The password is too weak.', signinError: true })
            } else {
              return this.setState({ error: errorMessage, signinError: true })
            }
            console.log(error);
          });
        }
        else this.setState({ signUp: true, signinError: false })
      }
      else {
        this.setState({ error: error.message, usernameError: true })
      }
    });

  }
  getUserData = async () => {

    // initialize Firestore
    const db = firebase.firestore();


    // return
    const settings = { timestampsInSnapshots: true };
    db.settings(settings);

    this.unregisterAuthObserver = await auth.onAuthStateChanged(async user => {

      if (this.state.signUp) {
        return
      }




      // get full user info from database
      if (user) {

        this.setState({
          username: "",
          password: "",
          authflag: 1,
          error: "",
          signUp: false,
          choosePassword: "",
          confirmPassword: "",
          showPassword: false,
          usernameError: false,
          signinError: false
        })
        try {
          const doc = await db.collection(COLLECTIONS.USERS).doc(user.uid).get();
          this.user = doc.data();
          if (this.user) {
            if (this.user.role === 'admin') {
              this.setState({ loggedIn: !!user });
              this.user.id = user.uid;
            }
            else if (!this.user.approved) {
              auth.signOut();
              return this.setState({ error: 'Your account status is still pending.', signinError: true,showPassword:false })
            }
            else {
              this.setState({ loggedIn: !!user });
              this.user.id = user.uid;
            }
          }
          else {
            // if (user.uid) {
            //   await addEditUser({
            //     user: {
            //       firstName: user.displayName,
            //       lastName: '',
            //       role: '',
            //       position: '',
            //       email: user.email,
            //       phone: null,
            //       avatarURL: null,

            //     },
            //     id: user.uid
            //   }).then(resp => {

            //   });
            // }
          }
        } catch (err) {
          console.log(err);
        }

        this.unsubscribers = loadDB(store);

        setTimeout(() => {
          // set state as logged in, end loading screen
          this.setState({ isLoaded: true, user: this.user }, () => {
            if (this.user && this.user.approved) {
              this.setState({ loggedIn: !!user });
            }
          });
        }, 1);

        // this.unsubscribe = store.subscribe(() => {
        //   this.compareUser();
        // });
      } else {
        this.setState({ loggedIn: !!user });
      }
    });
  }

  componentWillUnmount() {
    // unmount Firebase Auth observer when App unmounts
    this.unregisterAuthObserver();

    // unsubscribe from all Firestore listeners
    this.unsubscribers.unSubCrops();
    this.unsubscribers.unSubVarieties();
    this.unsubscribers.unSubGrowthCycles();
    this.unsubscribers.unSubRafts();
    this.unsubscribers.unSubBatches();
    this.unsubscribers.unSubPerpetualBatches();
    this.unsubscribers.unSubUsers();
    this.unsubscribers.unSubMisc();

    // unmount redux store observer when App unmounts
    // this.unsubscribe();
  }

  // compareUser() {
  //   let previousValue = this.user || null;
  //   const users = store.getState().users;
  //   this.state.user && console.log('state.user.id:', this.state.user.id);
  //   this.state.user && console.log('users:', store.getState().users);
  //   this.state.user && console.log('user from redux:', users[this.state.user.id]);
  //   const currentValue = (this.state.user && users[this.state.user.id]) ? users[this.state.user.id] : null;
  //   console.log({ currentValue, previousValue });
  //   if (previousValue !== currentValue && currentValue && currentValue !== {}) {
  //     console.log('updating current user');
  //     this.user = { ...this.user, ...currentValue };
  //     this.setState({ user: this.user });
  //   }
  // }

  toggleDrawer() {
    const newOpenState = !this.state.drawerOpen;
    this.setState({ drawerOpen: newOpenState });
  }

  async handleDataImport(jsonFile) {

    await importData(jsonFile, true);

    await setTimeout(() => {
      return;
    }, 500);

    return;
  }

  onClickForgotPassword = () => {
    this.setState({ forgotPasswordCase: true, signinError: false, error: '' })
  }
  // <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />

  render() {
    const { showPassword, signUp, signinError, forgotPasswordCase } = this.state;

    // return(
    //   <Provider store={store}>
    //   <UserContext.Provider value={{ user: this.state.user, updateUser: () => this.getUserData() }}>
    //     <DevContext.Provider value={devMode}>
    //       <Router>
    //         <MuiThemeProvider theme={theme}>
    //           <CssBaseline />
    //           <Menu isOpen={this.state.drawerOpen} />
    //           <Main toggleDrawer={this.toggleDrawer} isOpen={this.state.drawerOpen} handleDataImport={this.handleDataImport} />
    //         </MuiThemeProvider>
    //       </Router>
    //     </DevContext.Provider>
    //   </UserContext.Provider>
    // </Provider>
    // )


    return (
      !this.state.loggedIn ?
        <div>
          <AppBar position="static" alignitems="center" style={styles.appBar}>
            <Toolbar>
              <Grid container justify="center" wrap="wrap">
                <Grid item>
                  <Typography variant="h6" style={styles.heading}>{'GREENER ROOTS'}</Typography>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          <Grid container spacing={0} justify="center" direction="row">
            <Grid item>
              <Grid
                container
                direction="column"
                justify="center"
                spacing={2}
                style={styles.loginForm}
              >
                <Paper
                  variant="elevation"
                  elevation={2}
                  style={styles.loginBackground}
                >
                  <Grid item>
                    <Typography component="h1" variant="h5">
                      {forgotPasswordCase ? 'Recover password' : signUp ? `Email` : `Sign in with email`}
                    </Typography>

                    {forgotPasswordCase ? <Typography variant="body2">
                      Get instructions sent to this email that explain how to reset your password
                    </Typography> : null}
                  </Grid>
                  <Grid item>
                    <form onSubmit={this.handleSubmit}>
                      <Grid container direction="column" spacing={2}>
                        <Grid item style={styles.marginTop}>
                          <TextField
                            type="email"
                            placeholder="Email"
                            fullWidth
                            error={this.state.usernameError}
                            name="username"
                            variant="outlined"
                            value={this.state.username}
                            onChange={(event) =>
                              this.setState({
                                [event.target.name]: event.target.value,
                              })
                            }
                            required
                            autoFocus
                          />
                        </Grid>
                        {(showPassword && !forgotPasswordCase) && <Grid item sty style={styles.marginTop}>
                          <TextField
                            type="password"
                            placeholder="Password"
                            fullWidth
                            name="password"
                            variant="outlined"
                            value={this.state.password}
                            onChange={(event) =>
                              this.setState({
                                [event.target.name]: event.target.value,
                              })
                            }
                            required
                          />
                        </Grid>}

                        {showPassword && !forgotPasswordCase && <Grid item alignItems='flex-end' style={styles.marginTop} onClick={() => this.onClickForgotPassword()}>
                          <Typography variant="body2" style={styles.forgotText}>Trouble signing in?</Typography>
                        </Grid>}

                        {signUp && <Grid item sty style={styles.marginTop}>
                          <TextField
                            type="name"
                            placeholder="First & last name"
                            fullWidth
                            name="name"
                            variant="outlined"
                            value={this.state.name}
                            onChange={(event) =>
                              this.setState({
                                [event.target.name]: event.target.value,
                              })
                            }
                            required
                          />
                        </Grid>}

                        {signUp && <Grid item sty style={styles.marginTop}>
                          <TextField
                            type="password"
                            placeholder="Choose password"
                            fullWidth
                            name="choosePassword"
                            variant="outlined"
                            value={this.state.choosePassword}
                            onChange={(event) =>
                              this.setState({
                                [event.target.name]: event.target.value,
                              })
                            }
                            required
                          />
                        </Grid>}

                        {signUp && <Grid item sty style={styles.marginTop}>
                          <TextField
                            type="password"
                            placeholder="Confirm password"
                            fullWidth
                            name="confirmPassword"
                            variant="outlined"
                            value={this.state.confirmPassword}
                            onChange={(event) =>
                              this.setState({
                                [event.target.name]: event.target.value,
                              })
                            }
                            required
                          />
                        </Grid>}
                        {signinError && <Typography variant="" style={styles.error}>{this.state.error}</Typography>}
                        <Grid item style={styles.marginTop}>
                          <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            style={styles.buttonBlock}
                            onClick={this.handleSubmit}
                          >
                            {forgotPasswordCase ? 'SEND' : showPassword ? `SUBMIT` : `NEXT`}
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Grid>
                  <Grid item>
                    {/* <Link href="#" variant="body2">
                      Forgot Password?
</Link> */}
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </div>
        :
        !this.state.isLoaded ?
          <FullScreenLoader />
          :
          <Provider store={store}>
            <UserContext.Provider value={{ user: this.state.user, updateUser: () => this.getUserData() }}>
              <DevContext.Provider value={devMode}>
                <Router>
                  <MuiThemeProvider theme={theme}>
                    <CssBaseline />
                    <Menu isOpen={this.state.drawerOpen} />
                    <Main toggleDrawer={this.toggleDrawer} isOpen={this.state.drawerOpen} handleDataImport={this.handleDataImport} />
                  </MuiThemeProvider>
                </Router>
              </DevContext.Provider>
            </UserContext.Provider>
          </Provider>

    );
  }
};

export default App;

import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import red from '@material-ui/core/colors/red';
import { FormControl, MenuItem, Select, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import APP_SETTINGS from '../utils/appSettings';
import { addEditItem } from '../store/actions/adders';

const { INVENTORY_ACTIONS, COLLECTIONS } = APP_SETTINGS;

const styles = theme => ({
  cropTitle: {
    marginBottom: '0.5rem'
  },
  varietyTitle: {
    paddingTop: '0.5rem',
    color: theme.palette.grey[700]
  },
  currentStock: {
    paddingBottom: '1rem',
    cursor: 'pointer'
  },
  currentLowStock: {
    color: red[400]
  },
  manualInput: {
    marginTop: '-0.9rem'
  },
  quantInput: {
    width: '50%',
    marginRight: '1rem',
    marginTop: '0.4rem'
  },
  varietyDivider: {
    marginTop: '1rem'
  },
  form: {
    width: '100%',
    marginTop: 10
  },
  buttonLoader: {
    color: 'white'
  },
  cancelButton: {
    backgroundColor: red[400],
    color: 'white',
    '&:hover': {
      backgroundColor: red[700]
    }
  },
  detailTitle: {
    textDecoration: 'underline'
  },
  formControl: {
    marginRight: '10px'
  }

});

class InventoryCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      add: '',
      trash: '',
      manualEdit: '',
      manualQuantity: '',
      quantityWeight: '',
      addWeight: '',
      weightType: 'Gram',
      trashWeight: '',
      lotNo: props.variety.lotNo,
      lotEdit: false,
      isAdding: false,
      isTrashing: false,
      isSettingManual: false,
      Stock: props.variety.trackByCells ? props.variety.currentStock : props.variety.currentStockWeight,
      viewType: 'Gram',
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleManual = this.handleManual.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.handleTrash = this.handleTrash.bind(this);
  }

  promisedSetState = (newState) => {
    return new Promise((resolve) => {
      this.setState(newState, () => {
        resolve();
      });
    });
  }

  componentWillReceiveProps(newProps) {
    this.setState({ Stock: newProps.variety.trackByCells ? newProps.variety.currentStock : newProps.variety.currentStockWeight })
  }

  handleChange(e) {
    const value = e.target.value === '' ? '' : Number(e.target.value);
    this.setState({ [e.target.id]: value });
  }

  updateLot = () => {
    addEditItem({ collection: COLLECTIONS.VARIETIES, item: { ...this.props.variety , lotNo : this.state.lotNo }, id: this.props.id });
    this.setState({lotEdit : false})
  }

  async handleManual(e) {
    e.preventDefault();
    await this.promisedSetState({ isSettingManual: true });
    const { currentStock, currentStockWeight, trackByCells } = this.props.variety;
    const diff = this.state.manualQuantity - (trackByCells === false ? currentStockWeight : currentStock);


    if (diff > 0) {
      this.handleAdd(null, diff);
    } else {
      this.handleTrash(null, diff);
    }
  }

  async handleAdd(e, quant) {

    if (e) { e.preventDefault(); }
    if (this.state.add === '' && !quant) return
    if (!quant) {
      this.setState({ isAdding: true });
    }

    let finalQty = this.state.add || quant;

    if (this.props.variety.trackByCells === false) {
      if (this.state.weightType === 'Ounce') {
        finalQty = finalQty * 28.3495
      }
      else if (this.state.weightType === 'Pound') {
        finalQty = finalQty * 453.592
      }
    }

    await this.props.handleStockTransaction(finalQty, INVENTORY_ACTIONS.ADD);
    this.setState({ add: '', manualEdit: false, manualQuantity: '', isAdding: false, isSettingManual: false });
  }

  async handleTrash(e, quant) {
    if (e) { e.preventDefault(); }

    if (this.state.trash === '' && !quant) return
    if (!quant) {
      this.setState({ isTrashing: true });
    }
    let finalQty = -(this.state.trash) || quant;

    if (this.props.variety.trackByCells === false) {
      if (this.state.weightType === 'Ounce') {
        finalQty = finalQty * 28.3495;
      }
      else if (this.state.weightType === 'Pound') {
        finalQty = finalQty * 453.592
      }
    }
    await this.props.handleStockTransaction(finalQty, INVENTORY_ACTIONS.TRASH);
    this.setState({ trash: '', manualEdit: false, manualQuantity: '', isTrashing: false, isSettingManual: false });
  }

  render() {
    const { classes, variety, className } = this.props;
    const { Stock, viewType, lotEdit, lotNo } = this.state;
    let stock = variety.trackByCells === false ? variety.currentStockWeight : variety.currentStock
    const isLowOnStock = stock < variety.minStock;

    return (
      <Grid container spacing={0} className={className}>
        <Grid item xs={12} >
          <Grid container direction='row' alignItems='center'>
            <Typography variant="h5" className={classes.varietyTitle}>{variety.name} - Lot No: </Typography>
            {lotEdit ?
              <Grid container xs={8} direction='row' alignItems='center'>
                <TextField
                  variant="outlined"
                  id="lotNo"
                  label={"Lot No"}
                  value={lotNo}
                  margin="dense"
                  className={classes.quantInput}
                  onChange={e => this.setState({ lotNo: e.target.value })}
                  inputProps={{
                    min: 0
                  }}
                  style={{ width: 100, marginLeft: 15 }}
                />
                <Grid item xs={1}>
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    type="submit"
                    form="manual-edit-form1"
                    onClick={this.updateLot}
                  >
                    Update
                  </Button>
                </Grid>
              </Grid>

              : <Typography
                variant="h5"
                className={classes.varietyTitle}
                style={{ marginLeft: '5px', cursor: 'pointer' }}
                onClick={() => this.setState({ lotEdit: true })}>
                {lotNo}
              </Typography>}

          </Grid>
          {/* {lotEdit ? null : <Typography variant="h5" className={classes.varietyTitle}>{variety.name} {this.state.lotNo ? `- Lot No: ${this.state.lotNo}` : ''}</Typography>} */}
        </Grid>
        <Grid item xs={12}>

          <form id="manual-edit-form" onSubmit={this.handleManual} className={classes.form}>
            <Grid container spacing={16} className={classes.manualEditContainer}>
              <Grid item xs={4}>
                <Tooltip title={variety.trackByCells ? "Click to set manually" : ""} placement="top">
                  <Typography
                    variant="h6"
                    color="secondary"
                    className={classes.currentStock}
                    onClick={() => {
                      if (variety.trackByCells) {
                        this.setState({ manualEdit: !this.state.manualEdit })
                      }
                    }}
                  >
                    Current Stock:
                  </Typography>
                </Tooltip>
              </Grid>

              <Grid item xs={3}>
                {this.state.manualEdit ?
                  <TextField
                    id="manualQuantity"
                    label={variety.trackByCells === false ? "Weight" : "Quantity"}
                    type="number"
                    value={this.state.manualQuantity}
                    margin="dense"
                    className={classes.manualInput}
                    onChange={this.handleChange}
                    inputProps={{
                      min: 0
                    }}
                  />
                  :
                  <Grid container direction='row' alignItems='center'>
                    <Typography
                      variant="h6"
                      color="secondary"
                      className={classNames(classes.currentStock, { [classes.currentLowStock]: isLowOnStock })}
                      onClick={() => {
                        if (variety.trackByCells) {
                          this.setState({ manualEdit: !this.state.manualEdit })
                        }
                      }}
                    >
                      {variety.trackByCells ? Stock : (((viewType === 'Gram' ? Stock : viewType === 'Ounce' ? Stock * 0.035274 : Stock * 0.0022) || 0).toFixed(2))}
                      {/* {variety.trackByCells === false ? Stock.toLocaleString('en-US') : Stock.toLocaleString('en-US') || 0} */}

                    </Typography>
                    {variety.trackByCells ? null : <Typography
                      variant="body2"
                      className={classNames(classes.currentStock, { [classes.currentLowStock]: isLowOnStock }, classes.detailTitle)}
                      color="primary"
                      style={{ marginLeft: '10px', marginTop: '5px' }}
                      onClick={() => {
                        this.setState({ viewType: viewType === 'Gram' ? 'Ounce' : viewType === 'Ounce' ? 'Pound' : 'Gram' })
                      }}
                    >
                      {viewType}
                    </Typography>}
                  </Grid>
                }
              </Grid>

              {this.state.manualEdit &&
                <Grid item xs={2}>
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    type="submit"
                    form="manual-edit-form"
                    onClick={this.handleManual}
                  >
                    Set
                  </Button>
                </Grid>
              }
              {this.state.manualEdit &&
                <Grid item xs={2}>
                  <Button
                    variant="contained"
                    size="small"
                    className={classes.cancelButton}
                    onClick={() => this.setState({ manualEdit: !this.state.manualEdit })}
                  >
                    Cancel
                  </Button>
                </Grid>
              }
            </Grid>
          </form>

        </Grid>

        <Grid item xs={6}>
          <form id="add-form" onSubmit={this.handleAdd} className={classes.form}>
            <Grid container xs={12} alignItems="center" direction={'row'}>

              <TextField
                variant="outlined"
                id="add"
                label={variety.trackByCells === false ? "Weight" : "Add Quant"}
                type="number"
                value={this.state.add}
                margin="dense"
                className={classes.quantInput}
                onChange={this.handleChange}
                inputProps={{
                  min: 0
                }}
                style={{ width: variety.trackByCells === false ? 120 : 100 }}
              />


              <Grid style={{ marginLeft: 10 }}>
                <Button variant="contained" size="small" type="submit" form="add-form" onClick={this.handleAdd}>
                  {(!this.state.isSettingManual && this.state.isAdding) ?
                    <CircularProgress className={classes.buttonLoader} size={21} />
                    :
                    'Add'
                  }
                </Button>
              </Grid>

              {variety.trackByCells === false ? <FormControl style={{ marginLeft: '15px' }}>
                <InputLabel id="demo-simple-select-label">Type</InputLabel>
                <Select
                  // labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={this.state.weightType}
                  onChange={(e) => {
                    this.setState({ weightType: e.target.value })
                  }}
                >
                  <MenuItem value='Gram'>Gram</MenuItem>
                  <MenuItem value='Ounce'>Ounce</MenuItem>
                  <MenuItem value='Pound'>Pound</MenuItem>
                </Select>
              </FormControl> : null}

            </Grid>

          </form>
        </Grid>

        <Grid item xs={6}>
          <form id="trash-form" onSubmit={this.handleTrash} className={classes.form}>
            <Grid container alignItems="center">

              <TextField
                variant="outlined"
                id="trash"
                label={variety.trackByCells === false ? "Weight" : "Trash Quant"}
                type="number"
                value={this.state.trash}
                margin="dense"
                className={classes.quantInput}
                onChange={this.handleChange}
                inputProps={{
                  min: 0
                }}
                style={{ width: 120 }}
              />

              <Grid>
                <Button variant="contained" size="small" type="submit" onClick={this.handleTrash}>
                  {(!this.state.isSettingManual && this.state.isTrashing) ?
                    <CircularProgress className={classes.buttonLoader} size={21} />
                    :
                    'Trash'
                  }
                </Button>
              </Grid>
            </Grid>

          </form>
        </Grid>

        <Grid item xs={11}>
          <Divider className={classes.varietyDivider} />
        </Grid>
      </Grid>
    );
  }
}

InventoryCard.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  variety: PropTypes.object.isRequired,
  varieties: PropTypes.object.isRequired,
  id: PropTypes.string,

  handleStockTransaction: PropTypes.func.isRequired
};

export default withStyles(styles)(InventoryCard);
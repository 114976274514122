import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core';
import { addEditButton } from '../store/actions/adders';

const styles = theme => ({
    modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        minWidth: 350,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
        '&:focus': {
            outline: 'none'
        }
    },
    container: {
        width: '100%'
    },
    input: {
        width: '100%'
    },
    buttonContainer: {
        textAlign: 'right',
        marginTop: '1.5rem'
    },
    button: {
        marginLeft: '1rem'
    },
    submitButtonLoader: {
        color: 'white'
    }
});

class AddButtonModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            action: 'new',
        };

    }


    render() {
        const { classes, closeModal, handleSave, handleChange } = this.props;
        const { errors, name, link, isSaving } = this.props.state
        const action = this.state.action.replace(/\b\w/g, l => l.toUpperCase());
        return (
            <div className={classes.modal} tabIndex={-1}>
                <form onSubmit={handleSave}>
                    <Grid container className={classes.container} justify="center">
                        <Grid item xs={6}>
                            <Typography variant="h4" id="modal-title">{action} Button</Typography>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.container} justify="center">
                        <Grid item xs={6}>
                            <TextField
                                required
                                autoFocus
                                error={errors.name}
                                variant="filled"
                                id="name"
                                label="Button"
                                value={name}
                                onChange={handleChange('name')}
                                margin="normal"
                                className={classes.input}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className={classes.container} justify="center">
                        <Grid item xs={6}>
                            <TextField
                                required
                                variant="filled"
                                id="link"
                                label="Link"
                                value={link}
                                error={link.name}
                                onChange={handleChange('link')}
                                margin="normal"
                                rowsMax="4"
                                className={classes.input}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className={classes.container} justify="center">
                        <Grid item xs={6} className={classes.buttonContainer}>
                            <Button variant="contained" color="default" onClick={closeModal} className={classes.button}>
                                Cancel
                            </Button>
                            <Button variant="contained" color="secondary" type="submit" onClick={handleSave} className={classes.button}>
                                {isSaving ?
                                    <CircularProgress className={classes.submitButtonLoader} size={21} />
                                    :
                                    'Save'
                                }
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </div>
        )
    }
};

AddButtonModal.propTypes = {
    classes: PropTypes.object.isRequired,
    onSave: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    handleSave: PropTypes.func.isRequired,
    state: PropTypes.any
};

export default withStyles(styles)(AddButtonModal);
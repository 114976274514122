import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Collapse from '@material-ui/core/Collapse';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';
import { deepCopyObject, roundToHalf } from '../utils/utils';
import APP_SETTINGS from '../utils/appSettings';

const { HARVEST_TYPES } = APP_SETTINGS;


const styles = theme => ({
  modal: {
    position: 'absolute',
    top: '47%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    maxHeight: '80vh',
    minWidth: 650,
    maxWidth: 800,
    overflow: 'visible',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    '&:focus': {
      outline: 'none'
    }
  },
  formContainer: {
    maxHeight: 'calc(80vh - 6.5rem)',
    overflow: 'scroll',
    paddingBottom: '2rem',
    padding: '2rem 1.6rem 2rem 2rem'
  },
  buttonContainerOuter: {
    width: '100%',
    height: '4.5rem',
    padding: '0 2rem',
  },
  buttonContainerInner: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '1rem 0',
    borderTop: `1px solid ${theme.palette.grey[300]}`
  },
  container: {
    width: '100%'
  },
  button: {
    marginLeft: '1rem',
    height: 'max-height'
  },
  formRow: {
    marginBottom: '0.5rem'
  },
  rowLabel: {
    fontSize: '1.15rem',
    flexBasis: '10%',
    maxWidth: '10%',
    '& p': {
      fontSize: '1.15rem',
    }
  },
  raftTypeContainer: {
    flexBasis: '20%',
    maxWidth: '20%'
  },
  smallInput: {
    margin: 0,
    width: '90%',
    '& legend': {
      background: 'white'
    }
  },
  narrowInput: {
    width: '42%',
    marginRight: '0.8rem',
    '& input': {
      paddingRight: '5px'
    }
  },
  narrowPadding: {
    paddingRight: '5px'
  },
  paper: {
    width: '100%',
    marginBottom: '2rem'
  },
  sectionTitle: {
    marginTop: '1rem'
  },
  submitButtonLoader: {
    color: 'white'
  }
});

class GrowthCycleModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      notes: '',
      harvestType: '',
      growMedia: '',
      germ: { days: '', cells: '', weight: '', unit: '' },
      prop: { days: '' },
      hd: { days: '', raftType: '', calcRafts: '', remainder: '', endRafts: '' },
      fs: { days: '', raftType: '', calcRafts: '', remainder: '', endRafts: '' },
      harvests: 1,
      harvestInterval: '',
      hasBeenUsed: false,
      action: 'new',
      errors: {
        name: false,
        harvestType: false
      },
      isSaving: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  componentDidMount() {
    // if a growthCycle object is received, assign to state, set action as 'edit'
    if (this.props.growthCycle) {
      const { name, harvestType, germ, prop, hd, fs, harvests, harvestInterval, notes, hasBeenUsed, growMedia } = this.props.growthCycle;
      this.setState({ name, harvestType, germ, prop, hd, fs, harvests, harvestInterval, notes, growMedia, action: 'edit' });
      if (hasBeenUsed) {
        this.setState({ hasBeenUsed });
      }
    }
  }

  handleChange = name => e => {
    const val = ((e.target.type === "number") && (name !== 'hd-endRafts') && (name !== 'fs-endRafts') && (name !== 'germ-weight')) ? roundToHalf(e.target.value) : e.target.value;
    let newStateObj = {};
    if (name.includes('-')) {
      const nameArr = name.split('-');
      newStateObj = {
        [nameArr[0]]: {
          ...this.state[nameArr[0]],
          [nameArr[1]]: val
        }
      };
    } else {
      newStateObj = {
        [name]: val
      };
    };

    if (name === 'harvestType' && val === HARVEST_TYPES.ONCE) {
      newStateObj = { ...newStateObj, harvests: 1, harvestInterval: '' };
    }

    if (name === 'growMedia') {
      this.setState({
        germ: { days: '', cells: '', weight: '', unit: '' },
        prop: { days: '' },
        hd: { days: '', raftType: '', calcRafts: '', remainder: '', endRafts: '' },
        fs: { days: '', raftType: '', calcRafts: '', remainder: '', endRafts: '' },
      })
    }

    this.setState(newStateObj, () => {
      if (name === 'hd-raftType' || name === 'fs-raftType') {
        this.recalculate();
      }
    });
  }

  onBlur() {
    this.recalculate();
  }

  recalculate() {

    // run auto calculations
    let hdRaft = this.props.rafts[this.state.hd.raftType];
    let fsRaft = this.props.rafts[this.state.fs.raftType];


    let hdRafts = hdRaft && this.state.germ.cells ? (this.state.germ.cells / hdRaft.sites) : '';
    let hdEndRaft = hdRaft && this.state.germ.cells ? (this.state.germ.cells / hdRaft.sites) : '';
    let hdRem = hdRafts ? this.state.germ.cells - (hdRafts * hdRaft.sites) : '';
    let hdEndSites = hdRafts ? (hdEndRaft || this.state.hd.endRafts) * hdRaft.sites : '';


    let fsRafts = '';
    let fsEndRaft = '';
    let fsRem = '';
    if (this.state.germ.cells && fsRaft) {
      fsRafts = (this.state.hd.days && hdRaft && this.state.hd.endRafts) ? Math.floor(hdEndSites / fsRaft.sites) :
        Math.floor(this.state.germ.cells / fsRaft.sites);
        

      fsEndRaft = (this.state.hd.days && hdRaft && this.state.hd.endRafts) ? (hdEndSites / fsRaft.sites) :
        (this.state.germ.cells / fsRaft.sites);
      fsRem = (this.state.hd.days && hdRaft && this.state.hd.endRafts) ? hdEndSites - (fsRafts * fsRaft.sites) :
        this.state.germ.cells - (fsRafts * fsRaft.sites);
    }
    this.setState({
      hd: { ...this.state.hd, calcRafts: hdRafts, remainder: hdRem, endRafts: hdEndRaft ? Number(hdEndRaft).toFixed(2) : '' },
      fs: { ...this.state.fs, calcRafts: fsRafts, remainder: fsRem, endRafts: fsEndRaft ? Number(fsEndRaft).toFixed(2) : '' }
    });

  }

  handleSave(e) {
    e.preventDefault();

    // Check for required fields
    const requiredFields = ['name', 'harvestType', 'growMedia'];
    let errorsFound = false;
    let errors = deepCopyObject(this.state.errors);
    requiredFields.forEach((name) => {
      if (!this.state[name] || this.state[name] === '') {
        errorsFound = true;
        errors[name] = true;
      }
    });
    if (errorsFound) {
      this.setState({ errors });
      return;
    }

    this.setState({ isSaving: true });
    let { name, harvestType, germ, prop, hd, fs, harvests, harvestInterval, notes, growMedia } = this.state;
    if (!hd.days || hd.days === 0) {
      hd.days = 0;
      hd.raftType = '';
      hd.calcRafts = '';
      hd.remainder = '';
      hd.endRafts = '';
    }
    const growthCycle = { name, harvestType, germ, prop, hd, fs, harvests, harvestInterval, notes, growMedia };

    this.props.onSave(growthCycle);
  }

  render() {
    const { classes, rafts, editAllowedOverride, closeModal, growBeds } = this.props;
    const action = this.state.action.replace(/\b\w/g, l => l.toUpperCase());
    const raftOptions = Object.keys(rafts).map(raftID => {
      const raft = rafts[raftID];
      return (
        <MenuItem key={raftID} value={raftID}>{raft.name}</MenuItem>
      );
    });

    const hasBeenUsed = this.state.hasBeenUsed;

    return (
      <div className={classes.modal} tabIndex={-1}>
        <form onSubmit={this.handleSave}>

          <div className={classes.formContainer}>
            <Grid container className={classes.container} spacing={16}>
              <Grid item xs={12}>
                <Typography variant="h4" id="modal-title">{action} Growth Cycle</Typography>
              </Grid>
            </Grid>
            <Grid container className={classes.container} spacing={16}>
              <Grid item xs={4}>
                <TextField
                  required
                  autoFocus
                  error={this.state.errors.name}
                  variant="filled"
                  id="name"
                  label="Name"
                  value={this.state.name}
                  onChange={this.handleChange('name')}
                  margin="normal"
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  required
                  error={this.state.errors.harvestType}
                  disabled={editAllowedOverride ? false : hasBeenUsed}
                  select
                  variant="filled"
                  id="harvestType"
                  name="harvestType"
                  label="Harvest Type"
                  value={this.state.harvestType}
                  onChange={this.handleChange('harvestType')}
                  margin="normal"
                  fullWidth
                >
                  <MenuItem value={HARVEST_TYPES.ONCE}>Once</MenuItem>
                  <MenuItem value={HARVEST_TYPES.REPEAT}>Cut and come again</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  required
                  error={this.state.errors.growMedia}
                  disabled={editAllowedOverride ? false : hasBeenUsed}
                  select
                  variant="filled"
                  id="growMedia"
                  name="growMedia"
                  label="Grow Media"
                  value={this.state.growMedia}
                  onChange={this.handleChange('growMedia')}
                  margin="normal"
                  fullWidth
                >
                  {growBeds && growBeds.map(resp => (
                    <MenuItem value={resp.name}>{resp.name}</MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>

            <Grid container className={classes.container} spacing={16}>
              <Grid item xs={12}>
                <Typography variant="h6" className={classes.sectionTitle}>Growth Stages</Typography>
              </Grid>

              <Grid item xs={12}>
                <Grid container className={classes.formRow} alignItems="center">
                  <Grid item xs={1} className={classes.rowLabel}><Typography variant="body2">Germ.</Typography></Grid>
                  <Grid item xs={2}>
                    <TextField
                      disabled={editAllowedOverride ? false : hasBeenUsed}
                      type="number"
                      id="germ-days"
                      label="Days"
                      variant="outlined"
                      value={this.state.germ.days}
                      inputProps={{
                        min: 0
                      }}
                      onChange={this.handleChange('germ-days')}
                      margin="dense"
                      className={classes.smallInput}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  {this.state.growMedia === 'Rockwool' ?
                    <Grid item xs={2}>
                      {/* <TextField
                        disabled={editAllowedOverride ? false : hasBeenUsed}
                        type="number"
                        id="germ-cells"
                        label="Cells"
                        variant="outlined"
                        value={this.state.germ.cells}
                        inputProps={{
                          min: 0
                        }}
                        onChange={this.handleChange('germ-cells')}
                        onBlur={this.onBlur}
                        margin="dense"
                        className={classes.smallInput}
                        InputLabelProps={{ shrink: true }}
                      /> */}
                    </Grid> :
                    <Grid item xs={2}>
                      <TextField
                        disabled={editAllowedOverride ? false : hasBeenUsed}
                        type="number"
                        id="germ-weight"
                        label="Weight"
                        variant="outlined"
                        value={this.state.germ.weight}
                        inputProps={{
                          min: 0
                        }}
                        onChange={this.handleChange('germ-weight')}
                        onBlur={this.onBlur}
                        margin="dense"
                        className={classes.smallInput}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>}

                  {this.state.growMedia !== 'Rockwool' ?
                    <Grid item xs={2}>
                      <TextField
                        select
                        disabled={editAllowedOverride ? false : hasBeenUsed}
                        id="germ-unit"
                        label="Unit"
                        variant="outlined"
                        value={this.state.germ.unit}
                        onChange={this.handleChange("germ-unit")}
                        margin="dense"
                        className={classes.smallInput}
                      >
                        <MenuItem key={'grams'} value={'GRAMS'}>GRAMS</MenuItem>
                        <MenuItem key={'ounce'} value={'OUNCE'}>OUNCE</MenuItem>
                      </TextField>
                    </Grid> : null}
                </Grid> {/* end Germ row container */}

                <Grid container className={classes.formRow} alignItems="center">
                  <Grid item xs={1} className={classes.rowLabel}><Typography variant="body2">Prop.</Typography></Grid>
                  <Grid item xs={2}>
                    <TextField
                      disabled={editAllowedOverride ? false : hasBeenUsed}
                      type="number"
                      id="prop-days"
                      label="Days"
                      variant="outlined"
                      value={this.state.prop.days}
                      inputProps={{
                        min: 0
                      }}
                      onChange={this.handleChange('prop-days')}
                      margin="dense"
                      className={classes.smallInput}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                </Grid> {/* end Prop row container */}

                {this.state.growMedia === 'Rockwool' ? <Grid container className={classes.formRow} alignItems="center">
                  <Grid item xs={1} className={classes.rowLabel}><Typography variant="body2">HD</Typography></Grid>
                  <Grid item xs={2}>
                    <TextField
                      disabled={editAllowedOverride ? false : hasBeenUsed}
                      type="number"
                      id="hd-days"
                      label="Days"
                      variant="outlined"
                      value={this.state.hd.days}
                      inputProps={{
                        min: 0
                      }}
                      onChange={this.handleChange('hd-days')}
                      margin="dense"
                      className={classes.smallInput}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                   <Grid item xs={2} className={classes.raftTypeContainer}>
                    <TextField
                      select
                      disabled={editAllowedOverride ? false : hasBeenUsed}
                      id="hd-raftType"
                      label="Raft Type"
                      variant="outlined"
                      value={this.state.hd.raftType}
                      onChange={this.handleChange('hd-raftType')}
                      margin="dense"
                      className={classes.smallInput}
                    >
                      {raftOptions}
                    </TextField>
                  </Grid>
                  {/* <Grid item xs={3}>
                    <TextField
                      type="number"
                      disabled
                      id="hd-calcRafts"
                      label="Rafts"
                      variant="outlined"
                      value={this.state.hd.calcRafts}
                      margin="dense"
                      className={classNames(classes.smallInput, classes.narrowInput)}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        classes: {
                          root: classes.narrowPadding
                        }
                      }}
                    />
                    <TextField
                      type="number"
                      disabled
                      id="hd-remainder"
                      label="Rem."
                      variant="outlined"
                      value={this.state.hd.remainder}
                      margin="dense"
                      className={classNames(classes.smallInput, classes.narrowInput)}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        classes: {
                          root: classes.narrowPadding
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      disabled={editAllowedOverride ? false : hasBeenUsed}
                      type="number"
                      id="hd-endRafts"
                      label="Expected Rafts"
                      variant="outlined"
                      value={this.state.hd.endRafts}
                      inputProps={{
                        min: 0,
                        step: 0.5
                      }}
                      onChange={this.handleChange('hd-endRafts')}
                      // onBlur={this.onBlur}
                      margin="dense"
                      className={classes.smallInput}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid> */}
                </Grid> : null} {/* end HD row container */}

                {this.state.growMedia === 'Rockwool' ? <Grid container className={classes.formRow} alignItems="center">
                  <Grid item xs={1} className={classes.rowLabel}><Typography variant="body2">FS</Typography></Grid>
                  <Grid item xs={2}>
                    <TextField
                      disabled={editAllowedOverride ? false : hasBeenUsed}
                      type="number"
                      id="fs-days"
                      label="Days"
                      variant="outlined"
                      value={this.state.fs.days}
                      inputProps={{
                        min: 0
                      }}
                      onChange={this.handleChange('fs-days')}
                      margin="dense"
                      className={classes.smallInput}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                   <Grid item xs={2} className={classes.raftTypeContainer}>
                    <TextField
                      select
                      disabled={editAllowedOverride ? false : hasBeenUsed}
                      id="fs-raftType"
                      label="Raft Type"
                      variant="outlined"
                      value={this.state.fs.raftType}
                      onChange={this.handleChange('fs-raftType')}
                      margin="dense"
                      className={classes.smallInput}
                    >
                      {raftOptions}
                    </TextField>
                  </Grid>
                  {/* <Grid item xs={3}>
                    <TextField
                      disabled
                      type="number"
                      id="fs-calcRafts"
                      label="Rafts"
                      variant="outlined"
                      value={this.state.fs.calcRafts}
                      margin="dense"
                      className={classNames(classes.smallInput, classes.narrowInput)}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        classes: {
                          root: classes.narrowPadding
                        }
                      }}
                    />
                    <TextField
                      disabled
                      type="number"
                      id="fs-remainder"
                      label="Rem."
                      variant="outlined"
                      value={this.state.fs.remainder}
                      margin="dense"
                      className={classNames(classes.smallInput, classes.narrowInput)}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{
                        className: classes.narrowPadding
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      disabled={editAllowedOverride ? false : hasBeenUsed}
                      type="number"
                      id="fs-endRafts"
                      label="Expected Rafts"
                      variant="outlined"
                      value={this.state.fs.endRafts}
                      inputProps={{
                        min: 0,
                        step: 0.5
                      }}
                      onChange={this.handleChange('fs-endRafts')}
                      // onBlur={this.onBlur}
                      margin="dense"
                      className={classes.smallInput}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid> */}
                </Grid> : null} {/* end FS row container */}

              </Grid> {/* end growth stages form fields container */}

              <Grid item xs={12}>
                <Collapse in={this.state.harvestType === HARVEST_TYPES.REPEAT}>
                  <Grid container alignItems="center" className={classes.formRow}>
                    <Grid item xs={4}>
                      <Typography variant="body1">How many harvests?</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        disabled={editAllowedOverride ? false : hasBeenUsed}
                        type="number"
                        id="harvests"
                        variant="outlined"
                        value={this.state.harvests}
                        onChange={this.handleChange('harvests')}
                        margin="dense"
                        className={classes.smallInput}
                      />
                    </Grid>
                  </Grid>

                  <Grid container alignItems="center" className={classes.formRow}>
                    <Grid item xs={4}>
                      <Typography variant="body1">Days between harvest</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        disabled={editAllowedOverride ? false : hasBeenUsed}
                        type="number"
                        id="harvestInterval"
                        variant="outlined"
                        value={this.state.harvestInterval}
                        onChange={this.handleChange('harvestInterval')}
                        margin="dense"
                        className={classes.smallInput}
                      />
                    </Grid>
                  </Grid>
                </Collapse>
              </Grid> {/* end optional harvest info container */}

              <Grid item xs={12}>
                <TextField
                  multiline
                  variant="outlined"
                  id="notes"
                  label="Notes"
                  value={this.state.notes}
                  onChange={this.handleChange('notes')}
                  margin="dense"
                  rowsMax="4"
                  className={classes.smallInput}
                  fullWidth
                />
              </Grid>

            </Grid>

          </div> {/* end form container */}

          <div className={classes.buttonContainerOuter}>
            <div className={classes.buttonContainerInner}>
              <Button variant="contained" color="default" className={classes.button} onClick={closeModal}>
                Cancel
              </Button>
              <Button variant="contained" color="secondary" type="submit" className={classes.button} onClick={this.handleSave}>
                {this.state.isSaving ?
                  <CircularProgress className={classes.submitButtonLoader} size={21} />
                  :
                  'Save'
                }
              </Button>
            </div>
          </div>

        </form>
      </div>
    )
  }
};

GrowthCycleModal.propTypes = {
  classes: PropTypes.object.isRequired,
  growthCycle: PropTypes.object,
  rafts: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired
};

export default withStyles(styles)(GrowthCycleModal);
import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    minWidth: 350,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    '&:focus': {
      outline: 'none'
    }
  },
  container: {
    width: '100%'
  },
  input: {
    width: '100%'
  },
  buttonContainer: {
    textAlign: 'right',
    marginTop: '1.5rem'
  },
  button: {
    marginLeft: '1rem'
  },
  submitButtonLoader: {
    color: 'white'
  }
});

class CropModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      notes: '',
      action: 'new',
      isSaving: false,
      errors: {
        name: false
      }
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  componentDidMount() {
    // if a crop object is received, assign to state, set action as 'edit'
    if (this.props.crop) {
      const {name, notes} = this.props.crop;
      this.setState({name, notes, action: 'edit'});
    }
  }

  handleChange = name => e => {
    if (this.state.errors[name]) {
      this.setState({errors: {...this.state.error, [name]: false}});
    }
    this.setState({
      [name]: e.target.value
    });
  };

  async handleSave(e) {
    e.preventDefault();

    // Check for required fields
    const requiredFields = ['name'];
    let errorsFound = false;
    requiredFields.forEach((name, i) => {
      if (!this.state[name] || this.state[name] === '') {
        errorsFound = true;
        this.setState({errors: {...this.state.errors, [name]: true}});
      }
    });
    if (errorsFound) {
      this.setState({isSaving: false});
      return;
    }

    // Save information
    this.setState({isSaving: true});
    const crop = {name: this.state.name, notes: this.state.notes , varieties:[]};
    await this.props.onSave(crop);
  }

  render() {
    const {classes, closeModal} = this.props;
    const action = this.state.action.replace(/\b\w/g, l => l.toUpperCase());
    return (
      <div className={classes.modal} tabIndex={-1}>
      <form onSubmit={this.handleSave}>
        <Grid container className={classes.container} justify="center">
          <Grid item xs={6}>
            <Typography variant="h4" id="modal-title">{action} Crop</Typography>
          </Grid>
        </Grid>
        <Grid container className={classes.container} justify="center">
          <Grid item xs={6}>
            <TextField
              required
              autoFocus
              error={this.state.errors.name}
              variant="filled"
              id="name"
              label="Name"
              value={this.state.name}
              onChange={this.handleChange('name')}
              margin="normal"
              className={classes.input}
            />
          </Grid>
        </Grid>
        <Grid container className={classes.container} justify="center">
          <Grid item xs={6}>
            <TextField
              multiline
              variant="filled"
              id="notes"
              label="Notes"
              value={this.state.notes}
              onChange={this.handleChange('notes')}
              margin="normal"
              rowsMax="4"
              className={classes.input}
            />
          </Grid>
        </Grid>
        <Grid container className={classes.container} justify="center">
          <Grid item xs={6} className={classes.buttonContainer}>
            <Button variant="contained" color="default" onClick={closeModal} className={classes.button}>
              Cancel
            </Button>
            <Button variant="contained" color="secondary" type="submit" onClick={this.handleSave} className={classes.button}>
              {this.state.isSaving ?
                <CircularProgress className={classes.submitButtonLoader} size={21} />
                :
                'Save'
              }
            </Button>
          </Grid>
        </Grid>
      </form>
      </div>
    )
  }
};

CropModal.propTypes = {
  classes: PropTypes.object.isRequired,
  crop: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired
};

export default withStyles(styles)(CropModal);
import React from 'react';
import { connect } from 'react-redux';

const Export = props => {
  const jsonObj = {
    crops: props.crops,
    varieties: props.varieties,
    growthCycles: props.growthCycles,
    batches: props.batches,
    perpetualBatches: props.perpetualBatches,
    rafts: props.rafts,
    users: props.users,
    misc: props.misc,
  };
  return (
    <React.Fragment>
      {JSON.stringify(jsonObj)}
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  crops: state.crops,
  varieties: state.varieties,
  growthCycles: state.growthCycles,
  batches: state.batches,
  perpetualBatches: state.perpetualBatches,
  rafts: state.rafts,
  users: state.users,
  misc: state.misc,
});

export default connect(mapStateToProps)(Export);

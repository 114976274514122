import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { withStyles, Tooltip } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';

import classNames from 'classnames';
import red from '@material-ui/core/colors/red';
import blue from '@material-ui/core/colors/blue';
import APP_SETTINGS from '../utils/appSettings';

import { v4 as uuidv4 } from 'uuid';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
const { COLLECTIONS } = APP_SETTINGS;

const re = /^(?!$|.*\'[^\x22]+$)(?:([0-9]+)\')?(?:([1-9]|1[0-1]+)\x22?)?$/

const styles = theme => ({
  modal: {
    // position: 'absolute',
    // top: '50%',
    // left: '50%',
    // transform: 'translate(-50%, -50%)',
    width: '100%',
    // minWidth: 580,
    // maxWidth: 800,
    backgroundColor: theme.palette.background.paper,
    // boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    '&:focus': {
      outline: 'none'
    },
    overflow: 'auto',
    // maxHeight: 700,
  },
  container: {
    width: '100%',
  },
  input: {
    width: '100%'
  },
  farmName: {
    color: '#000',
    marginTop: 40,
    fontWeight: 'bold'
  },
  buttonContainer: {
    textAlign: 'right',
    marginTop: '1.5rem'
  },
  button: {
    margin: '0.5rem 0rem'
  },
  paper: {
    width: '100%',
    marginBottom: '2rem'
  },
  sectionTitle: {
    marginTop: '2rem'
  },
  textType: {
    marginTop: '2rem',
    fontWeight: '400'
  },
  formControl: {
    minWidth: 120,
  },
  link1: {
    marginTop: '2.25rem',
    color: blue[500],
    fontSize: 15,
    cursor: 'pointer',
  },
  link: {
    color: blue[500],
    fontSize: 15,
    marginTop: 20,
    cursor: 'pointer'
  },
  actionIcons: {
    marginRight: '0.5rem',
    cursor: 'pointer',
    '&:last-child': {
      marginRight: 0
    },
    fontSize: '1.5rem'
  },
  blue: {
    color: blue[500]
  },
  red: {
    color: red[500]
  },
  grey: {
    color: theme.palette.grey[300]
  },
  editingCell: {
    color: theme.palette.grey[300]
  },
  fab: {
  },
  tabContainer: {
    margin: '3rem 0 1rem'
  },
  tabs: {
    width: '100%',
    minWidth: 400,
    '& button': {
      width: '35%',
      maxWidth: 'none'
    }
  },
  tab: {
    fontSize: '1.1rem'
  },
  divider: {
    width: '100%',
    marginTop: '-1px'
  }
});

class FarmModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      location: '',
      notes: '',
      sensor1: '',
      sensor2: '',
      deleteRaft: null,
      editIsActive: false,
      editRaft: null,
      dialogOpen: false,
      ponds: [],
      addRow: true,
      currentOpen: -1,
      id: '',
      nameError: false,
      locationError: false,
      openPrompt: false,
      hasBeenEdited: false,
      tabValue: 0,
      addFarm: false,
      openMenu: null,
      pondToDelete: '',
      selectedFarm: '',
      pondType: 'portrait'
    };
    this.handleChange = this.handleChange.bind(this);
    this.deleteRaft = this.deleteRaft.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
  }

  componentDidMount = () => {
  }

  handleTabChange = (e, tabValue) => {
    this.setState({ tabValue });
  };

  handleChange = name => e => {

    if (name === 'name') {
      this.setState({ nameError: false })
    }
    if (name === 'location') {
      this.setState({ locationError: false })
    }
    this.setState({
      [name]: e.target.value,
      hasBeenEdited: true
    });
  };

  handleChange1 = (name, i) => e => {

    if (name.includes('length') || name.includes('width')) {
      ///^[+]?([0-9]+(?:[\"]*[0-9]+[\'"])?|\.[0-9]+)$/

      // if ((e.target.value === '') || (re.test(e.target.value))) {

      // if((name === 'lengthI' || name === 'widthI') && parseInt(e.target.value) > 11){
      //   return
      // }

      this.state.ponds[i][name] = e.target.value
      this.setState({
        ponds: this.state.ponds,
        hasBeenEdited: true
      })
      // }

    }
    else {
      this.state.ponds[i][name] = e.target.value
      this.setState({
        ponds: this.state.ponds,
        hasBeenEdited: true
      })

    }

  };

  addSave = (type) => {
    let { name, location, notes, ponds, sensor1, sensor2 } = this.state;

    if (name.trim() === '') {
      this.setState({ nameError: true })
    }
    if (location.trim() === '') {
      this.setState({ locationError: true })
    }

    if ((name.trim() !== '') && (location.trim() !== '')) {

      ponds.forEach(each => {

        if (each.lengthF === '' || isNaN(each.lengthF)) {
          each.lengthF = 0
        }

        if (each.lengthI === '' || isNaN(each.lengthI)) {
          each.lengthI = 0
        }
        else {
          each.lengthF = Number(each.lengthF || 0) + Math.floor(Number(each.lengthI) / 12)
          each.lengthI = Number(each.lengthI) % 12;
        }

        if (each.widthF === '' || isNaN(each.widthF)) {
          each.widthF = 0
        }
        if (each.widthI === '' || isNaN(each.widthI)) {
          each.widthI = 0
        }
        else {
          each.widthF = Number(each.widthF || 0) + (Number(each.widthI) ? Math.floor(Number(each.widthI) / 12) : 0)
          each.widthI = Number(each.widthI) ? (Number(each.widthI) % 12) : 0
        }

        each['length'] = Number(each.lengthF ? each.lengthF * 12 : 0) + Number(each.lengthI ? each.lengthI : 0)
        each['width'] = Number(each.widthF ? each.widthF * 12 : 0) + Number(each.widthI ? each.widthI : 0)



      })


      let farmToAdd = {
        name,
        location,
        notes,
        ponds,
        sensor1,
        sensor2
      }

      this.props.onSave(farmToAdd, this.state.id);

      if (type) {
        setTimeout(() => {
          this.setState({ id: '', ponds: [], name: '', location: '', notes: '', sensor1: '', sensor2: '' });
        }, 500);
      }
    }
  }

  updateFarm = () => {

  }

  handlDublicate = (i, selectdeFarm) => {

    this.setState({ openMenu: null })
    let updatedFarm = selectdeFarm;

    let newPond = {
      id: uuidv4(),
      length: this.state.toBeClonnedRow.length,
      lengthF: this.state.toBeClonnedRow.lengthF || "0",
      lengthI: this.state.toBeClonnedRow.lengthI || "0",

      name: this.state.toBeClonnedRow.name,
      width: this.state.toBeClonnedRow.width,
      widthF: this.state.toBeClonnedRow.widthF || "0",
      widthI: this.state.toBeClonnedRow.widthI || "0",
      pondType: this.state.toBeClonnedRow.pondType || 'portrait',
    }

    updatedFarm.ponds.push(newPond)
    this.props.onSave(updatedFarm, updatedFarm.id, true);
  }

  addFarm = () => {
    this.addSave(false)
    let newFarm = { name: '', width: '', length: '', id: uuidv4(), pondType: 'portrait' }
    this.state.ponds.push(newFarm);
    this.setState({ ponds: this.state.ponds.sort(this.compare), addRow: false, currentOpen: 0 })
  }
  addFarm1 = () => {
    this.setState({ addRow: true, currentOpen: -1, ponds: this.state.ponds.sort(this.compare) })
  }

  editFarm = (farmId) => {

    let mainFarm = {}
    this.props.Farms.map(farm => {
      if (farmId === farm.id) {
        mainFarm = farm
      }
    })
    const { name, location, notes, ponds, id, sensor1, sensor2 } = mainFarm;

    this.setState({ id, name, location, notes, sensor1, sensor2, ponds: ponds.sort(this.compare), editIsActive: true }, () => {
      this.props.hasBeenEdited()
    });
  }

  deleteRaft() {
    this.props.deleteItem({ collection: COLLECTIONS.RAFTS, id: this.state.deleteRaft });
  }

  openDialog(id) {
    this.setState({ dialogOpen: true, deleteRaft: id });
  }

  closeDialog(userAnswer) {
    if (userAnswer && userAnswer === 'yes') {
      this.deleteRaft();
    }
    this.setState({ dialogOpen: false });
  }

  cloneFarm = (farm) => {

    let { location, name, notes, ponds, pondType, sensor1, sensor2 } = farm;

    let NewPonds = [];

    ponds.map(pond => {
      pond.id = uuidv4();
      NewPonds.push(pond)
    })
    this.setState({ location, name, notes, pondType, sensor1, sensor2, ponds: ponds.sort(this.compare) }, () => {
      this.setState({ addFarm: true })
      this.props.hasBeenEdited()
    })
  }

  handleClick = (event) => {
    this.setState({ openMenu: event.currentTarget });
  };

  deletePond = () => {

    this.state.ponds.splice(this.state.pondToDelete, 1);

    this.setState({ ponds: this.state.ponds.sort(this.compare) })

  }

  handleClose = (type) => {
    if (type) {
      this.deletePond()
    }
    this.setState({ openPrompt: false })
  }

  UNSAFE_componentWillReceiveProps = (newProps) => {

    if (newProps !== this.props) {
      if (newProps.Farms.length > this.props.Farms.length) {
        let data = newProps.Farms.filter((obj) => {

          let present = false;

          this.props.Farms.map(each => {
            if (each.id === obj.id) {
              present = true;
            }
          })
          return !present;

        });
        const { name, location, notes, ponds, id, sensor1, sensor2 } = data[0];

        this.setState({ id, name, location, notes, sensor1, sensor2, ponds: ponds.sort(this.compare), editIsActive: true }, () => {
          this.props.hasBeenEdited()
        });

      }
      let mainFarm = {}
      newProps.Farms.map(farm => {
        if (this.state.id === farm.id) {
          const { name, location, notes, ponds, id , sensor1, sensor2} = farm;

          this.setState({ name, location, notes, id,sensor1 ,sensor2, ponds: ponds.sort(this.compare), editIsActive: true });
        }
      })

    }

  }

  compare = (a, b) => {
    // Use toUpperCase() to ignore character casing
    const bandA = a.name.toUpperCase();
    const bandB = b.name.toUpperCase();

    let comparison = 0;
    if (bandA > bandB) {
      comparison = 1;
    } else if (bandA < bandB) {
      comparison = -1;
    }
    return comparison;
  }



  render() {
    let { classes, Farms } = this.props;

    return (
      <div className={classes.modal} tabIndex={-1}>
        {this.state.addFarm ?
          <form>
            <Grid container className={classes.container} spacing={16}>
              <Grid item xs={10}>
                <TextField
                  required
                  id="name"
                  label="Name"
                  multiline
                  error={this.state.nameError}
                  value={this.state.name}
                  onChange={this.handleChange('name')}
                  fullWidth
                  className={classes.textField}
                />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  required
                  id="location"
                  label="Location"
                  multiline
                  error={this.state.locationError}
                  value={this.state.location}
                  onChange={this.handleChange('location')}
                  fullWidth
                  className={classes.textField}
                />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  id="notes"
                  label="Notes"
                  multiline
                  value={this.state.notes}
                  onChange={this.handleChange('notes')}
                  fullWidth
                  className={classes.textField}
                />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  id="sensor1"
                  label="Humidity/Temperature Sensor ID"
                  multiline
                  value={this.state.sensor1}
                  onChange={this.handleChange('sensor1')}
                  fullWidth
                  className={classes.textField}
                />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  id="sensor2"
                  label="API/PAR Sensor ID"
                  multiline
                  value={this.state.sensor2}
                  onChange={this.handleChange('sensor2')}
                  fullWidth
                  className={classes.textField}
                />
              </Grid>

            </Grid>
            <Typography variant="h6" className={classes.sectionTitle}>Grow beds</Typography>
            {this.state.ponds ? this.state.ponds.length ? this.state.ponds.map((farm, i) => {
              if (this.state.currentOpen === i) {
                return (
                  <Grid style={{ marginTop: 10 }}>
                    <Grid item xs={10}>
                      <TextField
                        required
                        id="name"
                        label="Name"
                        multiline
                        value={this.state.ponds[i].name}
                        style={{ marginTop: 20 }}
                        onChange={this.handleChange1('name', i)}
                        fullWidth
                        className={classes.textField}
                      />
                    </Grid>

                    <Typography variant={"body1"} className={classes.textType}>Length</Typography>
                    <Grid item xs={10}>
                      <TextField
                        required
                        id="length"
                        label="Feet"
                        type="number"
                        value={this.state.ponds[i].lengthF}
                        // style={{ marginTop: 10 }}
                        onChange={this.handleChange1('lengthF', i)}
                        // fullWidth
                        className={classes.textField}
                      />
                      <TextField
                        required
                        id="length"
                        label="Inches"
                        type="number"
                        value={this.state.ponds[i].lengthI}
                        style={{ marginLeft: 15 }}
                        onChange={this.handleChange1('lengthI', i)}
                        // fullWidth
                        className={classes.textField}
                      />
                    </Grid>

                    <Typography variant="body1" className={classes.textType}>Width</Typography>
                    <Grid item xs={10}>
                      <TextField
                        required
                        id="width"
                        label="Feet"
                        type="number"
                        value={this.state.ponds[i].widthF}
                        // style={{ marginTop: 20 }}
                        onChange={this.handleChange1('widthF', i)}
                        // fullWidth
                        className={classes.textField}
                      />
                      <TextField
                        required
                        id="width"
                        label="Inches"
                        type="number"
                        value={this.state.ponds[i].widthI}
                        style={{ marginLeft: 15 }}
                        onChange={this.handleChange1('widthI', i)}
                        // fullWidth
                        className={classes.textField}
                      />
                    </Grid>

                    <Grid item xs={10} style={{ marginTop: 20 }}>
                      <FormControl component="fieldset">
                        <FormLabel component="legend">Raft orientation</FormLabel>
                        <RadioGroup aria-label="pondType" value={this.state.ponds[i].pondType} onChange={this.handleChange1('pondType', i)} row>
                          <FormControlLabel value="portrait" control={<Radio />} labelPlacement="end" label="Portrait" />
                          <FormControlLabel value="landscape" control={<Radio />} labelPlacement="end" label="Landscape" />
                        </RadioGroup>
                      </FormControl>
                    </Grid>


                  </Grid>
                )
              }
              else return (
                <Grid container direction='row' alignItems='center'>
                  <Grid item xs={4}>
                    <Typography variant="span" style={{ marginTop: 25, maxWidth: 200 }}>{farm.name} ({farm.lengthF || 0}'{farm.lengthI || 0}" x {farm.widthF || 0}'{farm.widthI || 0}")</Typography>
                  </Grid>
                  <Tooltip placement="top" title="Edit">
                    <i
                      className={`far fa-edit fa-2x ${classNames(classes.actionIcons, classes.blue, { [classes.grey]: false })}`}
                      style={{ marginLeft: 15, marginTop: 20 }}
                      onClick={() => {
                        this.setState({ currentOpen: i, addRow: false })
                      }}
                    ></i>
                  </Tooltip>

                  <Tooltip placement="top" title="Delete">
                    <i
                      className={`far fa-times-circle fa-2x ${classNames(classes.actionIcons, classes.red, { [classes.grey]: false })}`}
                      onClick={() => this.setState({ pondToDelete: i, openPrompt: true })}
                      style={{ marginTop: 25 }}
                    >
                    </i>
                  </Tooltip>
                  <Tooltip placement="top" title="Duplicate">
                    <i
                      className={`far fa-clone fa-2x ${classNames(classes.actionIcons, classes.blue, { [classes.grey]: false })}`}
                      style={{ marginLeft: 5, marginTop: 20 }}
                      onClick={(e) => {
                        this.handleClick(e)
                        this.setState({ toBeClonnedRow: farm, _i: i })
                      }}
                    ></i>
                  </Tooltip>
                  {/* 
                  <Tooltip placement="top" title="Add pond Below">
                    <i
                      className={`fas fa-plus fa-2x ${classNames(classes.actionIcons, classes.blue, { [classes.grey]: false })}`}
                      style={{ marginLeft: 5, marginTop: 20 }}
                      onClick={() => {
                        this.state.ponds.splice(i + 1, 0, { name: '', width: '', length: '' });
                        setTimeout(() => {
                          this.setState({ currentOpen: i + 1, addRow: false })
                        }, 100);
                      }}
                    ></i>
                  </Tooltip> */}



                </Grid>
              )


            }) : null : null
            }

            {this.state.addRow ?
              <Typography variant="h6" onClick={() => this.addFarm()} className={classes.link}>Add Pond</Typography> :
              <Button variant="outlined" type="submit" color="primary" className={classes.button} onClick={() => this.addFarm1()}>
                {'Add'}
              </Button>}


            <Menu
              id="simple-menu"
              keepMounted
              open={Boolean(this.state.openMenu)}
              anchorEl={this.state.openMenu}
              onClose={() => this.setState({ openMenu: null })}
              PaperProps={{
                style: {
                  minWidth: 120
                }
              }}
            >
              {Farms.map((selectedFarm, j) => {
                return (
                  <MenuItem key={j} onClick={() => {
                    this.handlDublicate(this.state._i, selectedFarm)
                  }}>{selectedFarm.name}</MenuItem>
                )
              })}
            </Menu>



            <Grid container className={classes.container} justify="flex-end">
              {/* <Button variant="outlined" type="submit" color="primary" className={classes.button} onClick={() => this.addSave(true)}>
                {'Save'}
              </Button> */}

              <Button variant={'outlined'} color={'primary'} onClick={() => { this.addSave(true) }}>
                {'Save'}
              </Button>
            </Grid>
          </form>
          : null}
        <Grid container className={classes.container} spacing={16}>
          <Grid container direction='row' justify='space-between' item xs={12}>
            <Grid >
              <Typography variant="h6" className={classes.sectionTitle}>My Farms</Typography>
            </Grid>
            {!this.state.addFarm ? <Grid>
              <Typography variant="h6" onClick={() => this.setState({ addFarm: true }, () => this.props.hasBeenEdited(true))} className={classes.link1}>Add Farms</Typography>
            </Grid> : null}
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell numeric>Location</TableCell>
                    <TableCell numeric>Notes</TableCell>
                    <TableCell>Edit/Clone/Delete</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Farms.map((farm, i) => {
                    return (
                      <TableRow key={i}>
                        <TableCell component="th" scope="raft" className={classNames({ [classes.editingCell]: false })}>
                          {farm.name}
                        </TableCell>
                        <TableCell numeric className={classNames({ [classes.editingCell]: false })}>{farm.location}</TableCell>
                        <TableCell numeric className={classNames({ [classes.editingCell]: false })}>{farm.notes}</TableCell>
                        <TableCell>
                          <Tooltip placement="top" title="Edit Farm">
                            <i
                              className={`far fa-edit fa-2x ${classNames(classes.actionIcons, classes.blue, { [classes.grey]: false })}`}
                              onClick={() => {
                                this.setState({ addFarm: true })
                                this.editFarm(farm.id)
                                this.props.hasBeenEdited(true)
                                // window.scrollTo({ top: 0, behavior: 'smooth' })
                              }}
                            >
                            </i>
                          </Tooltip>
                          <Tooltip placement="top" title="Clone Farm">
                            <i
                              className={`far fa-clone fa-2x ${classNames(classes.actionIcons, classes.blue, { [classes.grey]: false })}`}
                              onClick={() => {
                                this.cloneFarm(farm)
                              }}
                            >
                            </i>
                          </Tooltip>
                          <Tooltip placement="top" title="Delete Farm">
                            <i
                              className={`far fa-times-circle fa-2x ${classNames(classes.actionIcons, classes.red, { [classes.grey]: false })}`}
                              onClick={() => this.props.deleteItem(farm.id)}
                            >
                            </i>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Paper>

            <Dialog
              open={this.state.openPrompt}
              onClose={() => this.handleClose(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              {/* <DialogTitle id="alert-dialog-title">{"Alert"}</DialogTitle> */}
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Delete this grow bed? This cannot be undone
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => this.handleClose(false)} color="primary">
                  No
                </Button>
                <Button onClick={() => this.handleClose(true)} color="primary" autoFocus>
                  Yes
                </Button>
              </DialogActions>
            </Dialog>

          </Grid>
        </Grid>

        {/* <Grid container className={classes.container} justify="flex-end">
                    <Button variant="contained" color="secondary" className={classes.button} onClick={this.props.onDone}>Done</Button>
                </Grid> */}


      </div>
    )
  }
};

FarmModal.propTypes = {
  classes: PropTypes.object.isRequired,
  rafts: PropTypes.object.isRequired,
  addEditItem: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default withStyles(styles)(FarmModal);

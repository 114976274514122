import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Avatar from '@material-ui/core/Avatar';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Modal from '@material-ui/core/Modal';
import CircularProgress from '@material-ui/core/CircularProgress';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import { DatePicker } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import { Tooltip, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import GrowthCycleCard from './GrowthCycleCard';
import GrowthCycleModal from './GrowthCycleModal';
import HarvestModule from './HarvestModule';
import FullScreenLoader from './FullScreenLoader';
import PasswordModal from './PasswordModal';
import moment from 'moment';
import red from '@material-ui/core/colors/red';
import blue from '@material-ui/core/colors/blue';
import {
  recalcDatesInGrowthCycle,
  getNewOrExistingCycleAndID,
  growthCyclesAreEqual,
  getMinDateInterval
} from '../utils/appFunctions/growthCycles';
import {
  calculateBatchMetrics,
  getGRFlot
} from '../utils/appFunctions/batches';
import { checkNull, deepCopyObject, roundToHalf } from '../utils/utils';
import {
  addEditItem,
  batchTransaction,
  getGrowBeds,
  pushLowInventoryItem
} from '../store/actions/adders';
import { deleteLowInventoryItem } from '../store/actions/deleters';
import UserContext from '../context_providers/UserContext';
import APP_SETTINGS from '../utils/appSettings';

const { STAGES, HARVEST_TYPES, TASK_TYPES, UNITS, TASK_NAMES, INVENTORY_ACTIONS, COLLECTIONS } = APP_SETTINGS;

const styles = theme => ({
  modal: {
    position: 'absolute',
    top: '47%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    maxHeight: '80vh',
    minWidth: 700,
    maxWidth: 800,
    overflow: 'visible',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    '&:focus': {
      outline: 'none'
    }
  },
  formContainer: {
    maxHeight: 'calc(80vh - 6.5rem)',
    overflow: 'scroll',
    paddingBottom: '2rem',
    padding: '2rem 1.6rem 2rem 2rem'
  },
  buttonContainerOuter: {
    width: '100%',
    height: '4.5rem',
    padding: '0 2rem',
  },
  buttonContainerInner: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '1rem 0',
    borderTop: `1px solid ${theme.palette.grey[300]}`
  },
  button: {
    marginLeft: '1rem',
    height: 'max-height'
  },
  container: {
    marginBottom: '1.5rem'
  },
  avatarContainer: {
    maxWidth: '12%',
    flexBasis: '12%'
  },
  avatar: {
    width: 60,
    height: 60
  },
  topButton: {
    color: red[400]
  },
  perpetualButton: {

  },
  deleteButtonLoader: {
    color: red[400],
    margin: '0 2.3rem'
  },
  buttonIcon: {
    marginRight: '0.6rem'
  },
  seedRow: {
    marginBottom: '0.5rem'
  },
  seedLotLink: {
    position: 'absolute',
    bottom: -6,
    left: 8,
    fontSize: '0.7rem',
    color: blue[500],
    textDecoration: 'underline',
    cursor: 'pointer',
    transition: 'color 200ms',
    '&:hover': {
      color: blue[700]
    }
  },
  input: {
    width: '100%'
  },
  alignCenter: {
    textAlign: 'center'
  },
  varietyIcon: {
    color: theme.palette.secondary.main,
    lineHeight: '68px'
  },
  subtitle: {
    color: theme.palette.grey[700]
  },
  red: {
    backgroundColor: red[500],
    color: 'white',
    '&:hover': {
      backgroundColor: red[700]
    }
  },
  submitButtonLoader: {
    color: 'white'
  },
  menuItem: {
    '& li': {
      minHeight: 'max-content',
      padding: '0.5rem'
    }
  },
  saveError: {
    position: 'absolute',
    bottom: -21,
    left: 1,
    color: red[400],
    fontSize: '0.7rem',
    fontWeight: 400,
    textTransform: 'initial',
    width: 'max-content',
  },
  rate: {
    height: 25,
    width: 25,
    borderRadius: 12.5,
    backgroundColor: '#42b883',
    cursor: 'pointer',
    marginLeft: 10,
    justifyContent: 'center',
  },
  rateNumber: {
    color: '#fff',
    marginTop: 2.5
  },
  notRateNumber: {
    color: '#d32f2f',
    paddingLeft: '0.3rem'
  },
  remRaftsContainer: {
    marginBottom: '1rem',
    marginTop: '1rem',
    paddingLeft: '0.5rem'
  },
  ratingCon: {
    alignSelf: "center",
    paddingLeft: '0.5rem'
  },
  bigRatingCon: {
    marginBottom : "3px",
    position : "relative",
    height: "35px"
  },
  deleteRowButtonCon: {
    position: "absolute",
    top: "-3px",
    right: "2px"
  },

  deleteRowButton: {
    // position: 'absolute',
    // top: 11,
    // right: 15,
    fontSize: '1.7rem',
    cursor: 'pointer',
    color: red[300]
  },
});

class CurrentBatchModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      crop: '',
      variety: '',
      growthCycle: null,
      growthCycleID: '',
      cells: '',
      seedDate: null,
      initiationDate: null,
      plannedHarvestDate: null,
      harvestDate: null,
      tag: '',
      seedLot: '',
      GRFlot: '',
      propDate: null,
      hdDate: null,
      fsDate: null,
      fsRafts: '',
      harvest: [],
      disposal: [],
      remainingRafts: null,
      remainingTrays: null,
      avgWeightPerHead: null,
      avgWeightPerHeadGrams: null,
      totalRaftsLogged: null,
      totalWeightLogged: null,
      totalRaftsHarvested: null,
      estTotalBatchWeight: null,
      isFinalized: false,
      hadBeenSeeded: false,
      errors: {
        seedLot: false,
      },
      errorMessages: {
        seedLot: null,
        save: null
      },
      isDisabled: {
        seedLot: true,
        propDate: true,
        hdDate: true,
        fsDate: true,
        harvestDate: true
      },
      isLoading: true,
      isSaving: false,
      isSavingHarvest: false,
      isFinalizing: false,
      isDeleting: false,
      readyToFinalize: false,
      dialogOpen: false,
      dialogMessage: '',
      currentDialog: '',
      dialogButtons: [],
      anchorEl: null,
      modalIsOpen: false,
      currentModal: null,
      currentModalAction: '',
      type1: '',
      ind1: '',
      allGrowBeds: []
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleGrowthCycleSelect = this.handleGrowthCycleSelect.bind(this);
    this.handleGrowthCycleEdit = this.handleGrowthCycleEdit.bind(this);
    this.addHarvestOrDisposal = this.addHarvestOrDisposal.bind(this);
    this.deleteHarvestOrDisposal = this.deleteHarvestOrDisposal.bind(this);
    this.updateHarvestOrDisposal = this.updateHarvestOrDisposal.bind(this);
    this.deleteHarvestOrDisposal1 = this.deleteHarvestOrDisposal1.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleFinalize = this.handleFinalize.bind(this);
    this.handleDeleteBatchClick = this.handleDeleteBatchClick.bind(this);
    this.deleteBatch = this.deleteBatch.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.openMenu = this.openMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleSeedLotLinkClick = this.handleSeedLotLinkClick.bind(this);
  }

  promisedSetState = (newState) => {
    return new Promise((resolve) => {
      this.setState(newState, () => {
        resolve()
      });
    });
  }

  async getAllGrowBeds() {
    getGrowBeds().then(growbeds => {
      this.setState({ allGrowBeds: growbeds })
    })
  }
  async componentDidMount() {
    const batch = this.props.batches[this.props.batchID];
    await this.promisedSetState({
      ...this.state,
      ...batch,
      plannedHarvestDate: batch.growthCycle.harvest.date,
      isLoading: false,
      hadBeenSeeded: batch.current.stage !== STAGES.PLANNED
    });

    this.getAllGrowBeds()

    this.enableFields(this.state);
    const { harvest, disposal, growthCycle, QtyToSeed } = this.state;
    const raftSites = growthCycle.fs.raftType ? this.props.rafts[growthCycle.fs.raftType].sites : '';
    const {
      avgWeightPerHead,
      avgWeightPerHeadGrams,
      remainingRafts,
      remainingTrays,
      totalRaftsLogged,
      totalWeightLogged,
      totalRaftsHarvested,
      estTotalBatchWeight
    } = calculateBatchMetrics({
      harvest,
      disposal,
      growthCycle,
      raftSites,
      QtyToSeed,
      isRockWool: growthCycle.growMedia === 'Rockwool',
      isComplete: batch.current.stage === STAGES.COMPLETED,
      cycleDetail: batch.cycleDetail || {}
    });

    this.setState({
      avgWeightPerHead,
      avgWeightPerHeadGrams,
      remainingRafts,
      remainingTrays,
      totalRaftsLogged,
      totalWeightLogged,
      totalRaftsHarvested,
      estTotalBatchWeight,
      growthCycle: {
        ...this.state.growthCycle,
        name: this.props.growthCycles[this.state.growthCycleID].name
      }
    });
  }

  enableFields(state) {
    let stateObj = {
      isDisabled: {
        seedLot: true,
        propDate: true,
        hdDate: true,
        fsDate: true,
        harvestDate: true
      },
      readyToFinalize: false
    };
    if (state.seedDate || state.initiationDate) {
      stateObj.isDisabled = { ...stateObj.isDisabled, seedLot: false };
    }
    if (state.initiationDate) {
      stateObj.isDisabled = { ...stateObj.isDisabled, propDate: false };
    }

    // allow no HD stage
    if (state.growthCycle.hd.days && state.growthCycle.hd.days > 0) {
      if (state.propDate) {
        stateObj.isDisabled = { ...stateObj.isDisabled, hdDate: false };
      }
      if (state.hdDate) {
        stateObj.isDisabled = { ...stateObj.isDisabled, fsDate: false };
      }
    } else {
      if (state.propDate) {
        stateObj.isDisabled = { ...stateObj.isDisabled, fsDate: false };
      }
    }

    if (state.fsDate) {
      stateObj.isDisabled = { ...stateObj.isDisabled, harvestDate: false };
    }
    if (state.harvestDate) {
      stateObj.readyToFinalize = true;
    }

    this.setState(stateObj);
  }

  noteHandleChange = (name, value) => {
    const copyState = {...this.state};
    if(copyState.tasks && copyState.tasks[name]){
      copyState.tasks[name].notes = value;
    }
    this.setState(copyState);
  }

  quantHandleChange = (name, value) => {
    const copyState = {...this.state};
    if(copyState.tasks && copyState.tasks[name]){
      copyState.tasks[name].quant = value;
    }
    this.setState(copyState);
  }

  ratingHandleChange = (name, value) => {
    const copyState = {...this.state};
    if(copyState.tasks && copyState.tasks[name]){
      copyState.tasks[name].rating = value;
    }
    this.setState(copyState);
  }

  handleChange = (name, type) => e => {

    let stateObj = {};
    switch (type) {
      case 'raft':
        stateObj[name] = roundToHalf(e.target.value);
        break;

      case 'number':
        stateObj[name] = (e.target.value === '' || e.target.value === null) ? '' : Number(e.target.value);
        break;

      case 'date':
        let value = e.target.value;
        const harvestNum = name.split('-')[1];
        if (!value) {
          if (harvestNum) {
            let dateArr = this.state.harvestDate.slice();
            dateArr.splice(harvestNum);
            stateObj.harvestDate = dateArr;
          } else {
            stateObj[name] = e.target.value;
          }
          break;
        }

        value = moment(e.target.value);
        const hasHD = this.state.growthCycle.hd.days && this.state.growthCycle.hd.days > 0;

        // ensure the date can't be before the prior one or after the subsequent one
        let dateMap = {
          seedDate: { before: null, after: this.state.initiationDate },
          initiationDate: { before: this.state.seedDate, after: this.state.propDate },
          propDate: { before: this.state.initiationDate, after: hasHD ? this.state.hdDate : this.state.fsDate },
          hdDate: { before: this.state.propDate, after: this.state.fsDate },
          fsDate: {
            before: hasHD ? this.state.hdDate : this.state.propDate,
            after: this.state.growthCycle.harvestType === HARVEST_TYPES.ONCE ? this.state.harvestDate : this.state.harvestDate[0]
          },
          plannedHarvestDate: { before: this.state.fsDate || this.state.growthCycle.fs.date, after: null },
          harvestDate: { before: this.state.fsDate, after: null }
        };
        if (harvestNum) {
          dateMap[`plannedHarvestDate-${harvestNum}`] = {
            before: (harvestNum === '0' || !harvestNum) ? this.state.fsDate : this.state.harvestDate[harvestNum - 1],
            after: this.state.harvestDate[parseInt(harvestNum) + 1] || null
          }
          dateMap[`harvestDate-${harvestNum}`] = {
            before: (harvestNum === '0' || !harvestNum) ? this.state.fsDate : this.state.harvestDate[harvestNum - 1],
            after: this.state.harvestDate[parseInt(harvestNum) + 1] || null
          }
        }

        if (moment(value).isBefore(dateMap[name].before, 'days')) {
          value = dateMap[name].before;
        } else if (dateMap[name].after && moment(value).isAfter(dateMap[name].after, 'days')) {
          value = dateMap[name].after;
        } else {
          value = moment(value).format('YYYY-MM-DD');
        }

        // cancel if the above results in no change
        const prevValue = harvestNum ? this.state.harvestDate[harvestNum] : this.state[name];
        if (moment(value).isSame(prevValue, 'days')) {
          return;
        }

        // push value to stateObj, accounting for multi-harvest
        if (harvestNum) {
          let dateArr = this.state.harvestDate.slice();
          dateArr[harvestNum] = value;
          stateObj.harvestDate = dateArr;
        } else {
          stateObj[name] = value;
        }

        // set seedDate equal to initiationDate if there's no current seedDate
        if (name === 'initiationDate' && !this.state.seedDate) {
          stateObj.seedDate = value;
          stateObj.growthCycle = { ...this.state.growthCycle, seed: { date: value } };
        }

        // push seedDate to growthcycle
        if (name === 'seedDate') {
          stateObj.growthCycle = { ...this.state.growthCycle, seed: { date: value } };
        }

        // recalculate growthCycle dates if initiationDate changes
        if (name === 'initiationDate') {
          if ((!this.state.initiationDate && value !== this.state.growthCycle.germ.date) || this.state.initiationDate) {
            const growthCycle = recalcDatesInGrowthCycle(this.state.growthCycle, value, this.state.growthCycle.seed.date);
            stateObj.growthCycle = growthCycle;
          }
        }

        break;

      default:
        value = e.target.value;
        stateObj[name] = value;
        break;
    }

    if (this.state.errors[name]) {
      stateObj = { ...stateObj, errors: { ...this.state.errors, [name]: false }, errorMessages: { ...this.state.errorMessages, [name]: null, save: null } };
    }

    this.setState({ ...stateObj });

    setTimeout(() => {
      this.props.setEditsMade(true);
      this.enableFields({ ...this.state, ...stateObj });
    }, 1);
  };

  handleDeleteBatchClick() {
    this.setState({ modalIsOpen: true, currentModal: 'confirmDelete', currentModalAction: 'delete' });
  }

  deleteBatch() {
    this.setState({ isDeleting: true });
    this.closeModal();
    this.props.onDelete();
  }

  handleSeedLotLinkClick() {
    this.setState({ seedLot: this.props.variety.lastUsedSeedLot });
    this.props.setEditsMade(true);
  }

  handleGrowthCycleSelect(gcID) {
    if (gcID === this.state.growthCycleID) { return; }

    // re-calculate dates from new growth cycle
    let growthCycle = recalcDatesInGrowthCycle(this.props.growthCycles[gcID], this.state.growthCycle.germ.date, this.state.growthCycle.seed.date);

    // change harvestDate in state if necessary
    let harvestDate = this.state.harvestDate === null ? null : this.state.harvestDate.slice();
    if (this.state.growthCycle.harvestType !== growthCycle.harvestType) {
      harvestDate = growthCycle.harvestType === HARVEST_TYPES.ONCE ? null : [];
    }

    this.setState({ growthCycle, growthCycleID: gcID, plannedHarvestDate: growthCycle.harvest.date, harvestDate });
    this.props.setEditsMade(true);
  }

  async handleGrowthCycleEdit(receivedGrowthCycle) {

    if (
      growthCyclesAreEqual(receivedGrowthCycle, this.state.growthCycle) &&
      receivedGrowthCycle.harvests === this.state.growthCycle.harvests
    ) {
      this.closeModal();
      return;
    }

    // re-calculate dates from new growth cycle
    let growthCycle = recalcDatesInGrowthCycle(receivedGrowthCycle, this.state.growthCycle.germ.date, this.state.growthCycle.seed.date);

    // change harvestDate in state if harvestType changes
    let harvestDate = this.state.harvestDate === null ? null : this.state.harvestDate.slice();
    if (this.state.growthCycle.harvestType !== growthCycle.harvestType) {
      harvestDate = growthCycle.harvestType === HARVEST_TYPES.ONCE ? null : [];
    }

    // truncate state.harvestDate to number of harvests
    if (growthCycle.harvestType === HARVEST_TYPES.REPEAT) {
      harvestDate.splice(growthCycle.harvests);
    }

    this.setState({ growthCycle, plannedHarvestDate: growthCycle.harvest.date, harvestDate });
    this.props.setEditsMade(true);
    this.closeModal();
  }

  addHarvestOrDisposal = async ({ type, item }) => {


    console.log('addHarvestOrDisposal',)
    const isRockWool = this.props.batch.growthCycle.growMedia === 'Rockwool';

    const raftSites = isRockWool ? this.props.rafts[this.state.growthCycle.fs.raftType].sites : parseFloat(this.props.batch.QtyToSeed);
    let itemArr = this.state[type].slice();


    const calculatedRafts = item.units === UNITS.RAFTS ? item.count : item.units === UNITS.TRAYS ? item.count / 4 : Math.round((item.count / raftSites) * 100) / 100;

    console.log('addHarvestOrDisposal1',calculatedRafts , this.state.remainingRafts)

    if (calculatedRafts > (isRockWool ? this.state.remainingRafts : this.state.remainingTrays)) return;

    itemArr.push({
      ...item,
      rafts: calculatedRafts
    });
    await this.promisedSetState({ [type]: itemArr });

    const batch = this.props.batches[this.props.batchID];





    this.setState({
      ...calculateBatchMetrics({
        harvest: this.state.harvest,
        disposal: this.state.disposal,
        growthCycle: this.state.growthCycle,
        raftSites,
        QtyToSeed: batch.QtyToSeed,
        isRockWool: batch.growthCycle.growMedia === 'Rockwool',
        isComplete: batch.current.stage === STAGES.COMPLETED,
        cycleDetail: batch.cycleDetail || {}
      })
    },()=>{
      setTimeout(() => {
        this.handleSave()
      }, 500);
    });

    this.setState({ isSavingHarvest: true })
    this.handleSaveHD();
    this.props.setEditsMade(true);
  }

  async deleteHarvestOrDisposal1({ type, ind }) {

    this.setState({
      dialogOpen: true,
      dialogMessage: 'Are you sure you want to delete it?',
      currentDialog: 'DeleteHD',
      dialogButtons: [{ label: 'Cancel', answer: 'no' }, { label: 'Okay', color: 'primary', answer: 'yes' }]
    });
    this.setState({ type1: type, ind1: ind })
  }

  async updateHarvestOrDisposal(updatedVal) {

    var type = updatedVal.type;
    var ind = updatedVal.index;

    let itemArr = this.state[type].slice();
    itemArr[ind].weight = updatedVal.weight;
    // itemArr.splice(ind, 1);
    await this.promisedSetState({ [type]: itemArr });

    const batch = this.props.batches[this.props.batchID];
    const raftSites = this.props.rafts[this.state.growthCycle.fs.raftType].sites;
    this.setState({
      ...calculateBatchMetrics({
        harvest: this.state.harvest,
        disposal: this.state.disposal,
        growthCycle: this.state.growthCycle,
        raftSites,
        QtyToSeed: batch.QtyToSeed,
        isRockWool: batch.growthCycle.growMedia === 'Rockwool',
        isComplete: batch.current.stage === STAGES.COMPLETED,
        cycleDetail: batch.cycleDetail || {}
      })
    });
    this.handleSaveHD()
    this.props.setEditsMade(true);
  }

  async deleteHarvestOrDisposal() {

    var type = this.state.type1;
    var ind = this.state.ind1

    let itemArr = this.state[type].slice();
    itemArr.splice(ind, 1);
    await this.promisedSetState({ [type]: itemArr });

    const batch = this.props.batches[this.props.batchID];
    const raftSites = this.props.rafts[this.state.growthCycle.fs.raftType].sites;
    this.setState({
      ...calculateBatchMetrics({
        harvest: this.state.harvest,
        disposal: this.state.disposal,
        growthCycle: this.state.growthCycle,
        raftSites,
        QtyToSeed: batch.QtyToSeed,
        isRockWool: batch.growthCycle.growMedia === 'Rockwool',
        isComplete: batch.current.stage === STAGES.COMPLETED,
        cycleDetail: batch.cycleDetail || {}
      })
    });
    this.handleSaveHD()
    this.props.setEditsMade(true);
  }

  openMenu(e) {
    this.setState({ anchorEl: e.currentTarget });
  }

  closeDialog(answer) {
    switch (this.state.currentDialog) {
      case 'DeleteHD':
        if (answer === 'yes') {
          this.deleteHarvestOrDisposal()

        }
        break;

      default:
        break;
    }
    this.setState({ dialogOpen: false, dialogMessage: '', currentDialog: '' });
  }

  closeMenu(gcID) {
    this.setState({ anchorEl: null });
    if (gcID) {
      this.handleGrowthCycleSelect(gcID);
    }
  }

  openModal(currentModal, currentModalAction) {
    this.setState({ modalIsOpen: true, currentModal, currentModalAction });
  }

  renderModal() {
    switch (this.state.currentModal) {
      case 'growthCycle':
        return (
          <GrowthCycleModal
            growthCycle={this.state.growthCycle}
            rafts={this.props.rafts}
            onSave={this.handleGrowthCycleEdit}
            editAllowedOverride={true}
            growBeds={this.state.allGrowBeds}

          />
        );

      case 'confirmDelete':
        return (
          <PasswordModal
            title="Confirm batch delete"
            handleSubmit={this.deleteBatch}
            handleCancel={this.closeModal}
          />
        );

      case 'confirmUnfinalize':
        return (
          <PasswordModal
            title="Confirm Un-Finalize"
            handleSubmit={this.handleFinalize}
            handleCancel={this.closeModal}
          />
        );

      default:
        break;
    }
  }

  closeModal() {
    this.setState({ modalIsOpen: false, currentModal: null, currentModalAction: '' });
  }

  async handleSave(e) {
    if (e) { e.preventDefault(); }

    await this.promisedSetState({ isSaving: true });

    setTimeout(async () => {
      let {
        GRFlot,
        growthCycleID,
        growthCycle,
        cells,
        seedLot,
        seedDate,
        initiationDate,
        plannedHarvestDate,
        harvestDate,
        current,
        harvest,
        disposal,
        isFinalized,
        propDate,
        hdDate,
        fsDate,
        fsRafts,
        tasks,
        crop,
        variety,
        QtyToSeed
      } = deepCopyObject(this.state);

      var dummyData = {
        GRFlot,
        growthCycleID,
        growthCycle,
        cells,
        seedLot,
        seedDate,
        initiationDate,
        plannedHarvestDate,
        harvestDate,
        current,
        harvest,
        disposal,
        isFinalized,
        propDate,
        hdDate,
        fsDate,
        fsRafts,
        tasks,
        crop,
        variety
      } = deepCopyObject(this.state);

      const originalBatch = this.props.batches[this.props.batchID];
      const user = this.context.user;

      const hasHDStage = this.state.growthCycle.hd.days && this.state.growthCycle.hd.days > 0;
      const isMultipleHarvest = this.state.growthCycle.harvestType === HARVEST_TYPES.REPEAT;

      /* FORM VALIDATION */
      if ((initiationDate && !seedLot) || (seedDate && !seedLot)) {
        this.setState({
          isSaving: false,
          errors: {
            ...this.state.errors,
            seedLot: true
          },
          errorMessages: {
            ...this.state.errorMessages,
            seedLot: 'Cannot be blank',
            save: 'Errors found'
          }
        });
        return;
      }

      /* UPDATE GRFlot IF NECESSARY */
      if (initiationDate && initiationDate !== originalBatch.initiationDate) {
        GRFlot = getGRFlot({ initiationDate, varietyCode: this.props.variety.varietyCode });
      }

      /* GROWTH CYCLE */
      let growthCycleDidChange = false;

      // accounts for newly selected or edited growthCycle, regardless of whether the user-inputted dates require a new one
      if (!growthCyclesAreEqual(growthCycle, originalBatch.growthCycle)) {
        growthCycleDidChange = true;
      }

      // handle germ stage
      if (propDate && moment(propDate).diff(initiationDate, 'days') !== growthCycle.germ.days) {
        growthCycle.germ.days = moment(propDate).diff(initiationDate, 'days');
        growthCycleDidChange = true;
      }

      // handle HD stage (allow for no HD stage)
      if (hasHDStage) {
        if (hdDate && moment(hdDate).diff(propDate, 'days') !== growthCycle.prop.days) {
          growthCycle.prop.days = moment(hdDate).diff(propDate, 'days');
          growthCycleDidChange = true;
        }
        if (fsDate && moment(fsDate).diff(hdDate, 'days') !== growthCycle.hd.days) {
          growthCycle.hd.days = moment(fsDate).diff(hdDate, 'days');
          growthCycleDidChange = true;
        }
      } else {
        if (fsDate && moment(fsDate).diff(propDate, 'days') !== growthCycle.prop.days) {
          growthCycle.prop.days = moment(fsDate).diff(propDate, 'days');
          growthCycleDidChange = true;
        }
      }

      // handle harvest stage; allow for single or multiple harvest date
      if (isMultipleHarvest) {
        if (moment(plannedHarvestDate[0]).diff(fsDate || growthCycle.fs.date, 'days') !== growthCycle.fs.days) {
          growthCycle.fs.days = moment(plannedHarvestDate[0]).diff(fsDate, 'days');
          growthCycleDidChange = true;
        }
        if (harvestDate[0] && moment(harvestDate[0]).diff(fsDate, 'days') !== growthCycle.fs.days) {
          growthCycle.fs.days = moment(harvestDate[0]).diff(fsDate, 'days');
          growthCycleDidChange = true;
        }

        // push any changed planned harvest dates to growthCycle
        plannedHarvestDate.forEach((date, ind) => {
          growthCycle.harvest.date[ind] = date;
        });

        // push any entered dates to growthCycle
        harvestDate.forEach((date, ind) => {
          growthCycle.harvest.date[ind] = date;
        });

        const minInterval = getMinDateInterval(harvestDate);
        if (minInterval && minInterval !== growthCycle.harvestInterval) {
          growthCycle.harvestInterval = minInterval;
          growthCycleDidChange = true;
        }
      } else {
        if (moment(plannedHarvestDate).diff(fsDate || growthCycle.fs.date, 'days') !== growthCycle.fs.days) {
          growthCycle.fs.days = moment(plannedHarvestDate).diff(fsDate || growthCycle.fs.date, 'days');
          growthCycleDidChange = true;
        }

        if (harvestDate && moment(harvestDate).diff(fsDate, 'days') !== growthCycle.fs.days) {
          growthCycle.fs.days = moment(harvestDate).diff(fsDate, 'days');
          growthCycleDidChange = true;
        }
      }

      growthCycle = recalcDatesInGrowthCycle(growthCycle, initiationDate, growthCycle.seed.date);

      if (growthCycleDidChange) {
        const data = await getNewOrExistingCycleAndID({
          growthCycle,
          growthCycleID,
          growthCycles: this.props.growthCycles,
          variety: this.props.variety,
          varietyID: variety,
          batchID: this.props.batchID,
          batches: this.props.batches
        });
        growthCycle = data.growthCycle;
        growthCycleID = data.growthCycleID;
      }

      /* TASKS */

      // handle seed/start vs seed&start

      const isRockWool = growthCycle.growMedia === 'Rockwool';
      const germCells = (tasks[TASK_NAMES.SEED])?tasks[TASK_NAMES.SEED].quant:((tasks[TASK_NAMES.START])?tasks[TASK_NAMES.START].quant:"");
      if (growthCycle.seed.date === growthCycle.germ.date) {
        // remove seed and start if found; add seedAndStart if needed
        delete tasks[TASK_NAMES.SEED];
        delete tasks[TASK_NAMES.START];
        if (!tasks[TASK_NAMES.SEED_AND_START]) {
          tasks[TASK_NAMES.SEED_AND_START] = {
            crop, variety, GRFlot,
            date: growthCycle.germ.date,
            type: TASK_TYPES.SEED_AND_START,
            batchStage: STAGES.GERM,
            quant: germCells,
            unit: UNITS.CELLS,
            priorTask: null,
            nextTask: TASK_NAMES.TRANSFER_PROP
          };
        }
        tasks[TASK_NAMES.TRANSFER_PROP].priorTask = TASK_NAMES.SEED_AND_START;
      } else {
        // remove seedAndStart if found; add seed and start if needed
        delete tasks[TASK_NAMES.SEED_AND_START];
        if (!tasks[TASK_NAMES.SEED]) {
          tasks[TASK_NAMES.SEED] = {
            crop, variety, GRFlot,
            date: growthCycle.seed.date,
            type: TASK_TYPES.SEED,
            batchStage: STAGES.SEED,
            quant: germCells,
            unit: UNITS.CELLS,
            priorTask: null,
            nextTask: TASK_NAMES.START
          };
        }
        if (!tasks[TASK_NAMES.START]) {
          tasks[TASK_NAMES.START] = {
            crop, variety, GRFlot,
            date: growthCycle.germ.date,
            type: TASK_TYPES.START,
            batchStage: STAGES.GERM,
            quant: germCells,
            unit: UNITS.CELLS,
            priorTask: TASK_NAMES.SEED,
            nextTask: TASK_NAMES.TRANSFER_PROP
          };
        }
        tasks[TASK_NAMES.TRANSFER_PROP].priorTask = TASK_NAMES.START;
      }

      // handle remove or add of HD stage
      if (isRockWool && hasHDStage) {
        if (!tasks[TASK_NAMES.TRANSFER_HD]) {
          tasks[TASK_NAMES.TRANSFER_HD] = {
            crop, variety, GRFlot,
            date: growthCycle.hd.date,
            type: TASK_TYPES.TRANSFER,
            batchStage: STAGES.HD,
            transferTo: STAGES.HD,
            quant:  originalBatch.cycleDetail?.hdEndRafts || growthCycle.hd.endRafts,
            unit: this.props.showTrays ? UNITS.TRAYS : UNITS.RAFTS,
            priorTask: TASK_NAMES.TRANSFER_PROP,
            nextTask: TASK_NAMES.TRANSFER_FS
          };
        }
        tasks[TASK_NAMES.TRANSFER_PROP].nextTask = TASK_NAMES.TRANSFER_HD;
        tasks[TASK_NAMES.TRANSFER_FS].priorTask = TASK_NAMES.TRANSFER_HD;
      } else if(isRockWool) {
        delete tasks[TASK_NAMES.TRANSFER_HD];
        tasks[TASK_NAMES.TRANSFER_PROP].nextTask = TASK_NAMES.TRANSFER_FS;
        tasks[TASK_NAMES.TRANSFER_FS].priorTask = TASK_NAMES.TRANSFER_PROP;
      }

      // handle multiple Harvest vs. single Harvest
      if (isMultipleHarvest) {
        delete tasks[TASK_NAMES.HARVEST];
        growthCycle.harvest.date.forEach((date, ind) => {
          if (!tasks[`${TASK_NAMES.HARVEST}-${ind}`]) {
            tasks[`${TASK_NAMES.HARVEST}-${ind}`] = {
              crop, variety, GRFlot,
              date,
              type: TASK_TYPES.HARVEST,
              batchStage: ind === growthCycle.harvest.date.length - 1 ? STAGES.COMPLETED : STAGES.HARVEST,
              quant: originalBatch.cycleDetail?.fsEndRafts || growthCycle.fs.endRafts,
              unit: this.props.showTrays ? UNITS.TRAYS : UNITS.RAFTS,
              priorTask: ind === 0 ? TASK_NAMES.TRANSFER_FS : `${TASK_NAMES.HARVEST}-${ind - 1}`,
              nextTask: ind === growthCycle.harvest.date.length - 1 ? null : `${TASK_NAMES.HARVEST}-${ind + 1}`,
              isLastTask: ind === growthCycle.harvest.date.length - 1 ? true : false
            };
          }
        });
        tasks[TASK_NAMES.TRANSFER_FS].nextTask = `${TASK_NAMES.HARVEST}-0`;
      } else {
        for (let taskName in tasks) {
          if (taskName !== TASK_NAMES.HARVEST && taskName.includes(TASK_NAMES.HARVEST)) {
            delete tasks[taskName];
          }
        }
        if (!tasks[TASK_NAMES.HARVEST]) {
          tasks[TASK_NAMES.HARVEST] = {
            crop, variety, GRFlot,
            date: growthCycle.harvest.date,
            type: TASK_TYPES.HARVEST,
            batchStage: STAGES.COMPLETED,
            quant: originalBatch.cycleDetail?.fsEndRafts || growthCycle.fs.endRafts,
            unit: this.props.showTrays ? UNITS.TRAYS : UNITS.RAFTS,
            priorTask: TASK_NAMES.TRANSFER_FS,
            nextTask: null,
            isLastTask: true
          };
        }
        if(isRockWool) tasks[TASK_NAMES.TRANSFER_FS].nextTask = TASK_NAMES.HARVEST;
      }

      let stageMap = {
        [TASK_NAMES.SEED]: { plannedDate: growthCycle.seed.date, actualDate: seedDate, quant: germCells },
        [TASK_NAMES.START]: { plannedDate: growthCycle.germ.date, actualDate: initiationDate, quant: germCells },
        [TASK_NAMES.SEED_AND_START]: { plannedDate: growthCycle.germ.date, actualDate: initiationDate, quant: germCells },
        [TASK_NAMES.TRANSFER_PROP]: { plannedDate: growthCycle.prop.date, actualDate: propDate, quant: germCells },
        [TASK_NAMES.TRANSFER_HD]: { plannedDate: growthCycle.hd.date, actualDate: hdDate, quant: growthCycle.hd.endRafts },
        [TASK_NAMES.TRANSFER_FS]: { plannedDate: growthCycle.fs.date, actualDate: fsDate, quant: growthCycle.fs.endRafts },
        [TASK_NAMES.HARVEST]: { plannedDate: growthCycle.harvest.date, actualDate: harvestDate }
      };
      if (isMultipleHarvest) {
        growthCycle.harvest.date.forEach((date, ind) => {
          stageMap[`${[TASK_NAMES.HARVEST]}-${ind}`] = { plannedDate: date, actualDate: harvestDate[ind] };
        });
      }

      let latestTaskComplete = '';
      let shouldTrackTaskComplete = true;

      // assign dates and quantities
      Object.keys(stageMap).forEach(stage => {
        let task = tasks[stage];
        if (task) {

          // set first task in the order to isNext
          if (stage === TASK_NAMES.SEED || stage === TASK_NAMES.SEED_AND_START) {
            task.isNext = true;
          }

          // push planned date; does not force change 'completedBy' or 'completedDate'
          task.date = stageMap[stage].plannedDate;

          // push actual(completed) date if it's been entered
          if (task.isComplete || checkNull(stageMap[stage].actualDate, false) && moment(stageMap[stage].actualDate).isSameOrBefore(new Date())) {
            task.isComplete = true;
            task.isNext = false;
            latestTaskComplete = stage;
            // if the actual date from state matches the old actual date, don't change the completedBy
            if (!task.completedDate || !moment(task.completedDate).isSame(stageMap[stage].actualDate, 'days')) {
              task.completedDate = stageMap[stage].actualDate;
              task.completedBy = user.id;
            }
          } else {
            // set task as not complete if no actual date
            task.isComplete = false;
            task.completedDate = null;
            task.completedBy = '';
            if (shouldTrackTaskComplete) {
              task.isNext = true;
              shouldTrackTaskComplete = false;
            }
          }
          // if planned quant has changed, update quant and completedBy
          if (stageMap[stage].quant) {
            if (!(task.quant === stageMap[stage].quant)) {
              task.quant = stageMap[stage].quant;
              task.completedBy = user.id;
            }
          }
        }
      });

      // remove any harvest tasks past the number of harvests
      if (isMultipleHarvest) {
        for (let taskName in tasks) {
          let harvestInd = taskName.split('-')[1];
          if (harvestInd && parseInt(harvestInd) >= growthCycle.harvests) {
            delete tasks[taskName];
          }
        }
      }


      /* CURRENT */

      let currentStageMap = {
        [TASK_NAMES.SEED]: STAGES.SEED,
        [TASK_NAMES.START]: STAGES.GERM,
        [TASK_NAMES.SEED_AND_START]: STAGES.GERM,
        [TASK_NAMES.TRANSFER_PROP]: STAGES.PROP,
        [TASK_NAMES.TRANSFER_HD]: STAGES.HD,
        [TASK_NAMES.TRANSFER_FS]: STAGES.FS,
        [TASK_NAMES.HARVEST]: STAGES.COMPLETED
      };
      if (isMultipleHarvest) {
        growthCycle.harvest.date.forEach((date, ind) => {
          currentStageMap[`${TASK_NAMES.HARVEST}-${ind}`] = ind === growthCycle.harvest.date.length - 1 ? STAGES.COMPLETED : STAGES.HARVEST;
        });
      }
      if (latestTaskComplete) {
        current.stage = currentStageMap[latestTaskComplete];
        const quant = tasks[latestTaskComplete].quant;
        current.cells = tasks[latestTaskComplete].unit === UNITS.CELLS ? quant : null;
        current.rafts = tasks[latestTaskComplete].unit === UNITS.RAFTS ? quant : null;
      } else {
        current = { stage: STAGES.PLANNED, cells: germCells };
      }


      const batch = {
        GRFlot,
        growthCycleID,
        growthCycle,
        cells,
        seedLot,
        seedDate,
        initiationDate,
        harvestDate,
        current,
        harvest,
        disposal,
        isFinalized,
        propDate,
        hdDate,
        fsDate,
        fsRafts,
        tasks,
        ...calculateBatchMetrics({
          harvest,
          disposal,
          QtyToSeed,
          isRockWool: growthCycle.growMedia === 'Rockwool',
          growthCycle,
          raftSites: growthCycle.fs.raftType ? this.props.rafts[growthCycle.fs.raftType].sites : '',
          isComplete: current.stage === STAGES.COMPLETED,
          cycleDetail: batch.cycleDetail || {}
        })
      };

      /* VARIETY */
      const varietyObj = this.props.variety;

      // update stock level if necessary
      if (varietyObj.trackStock) {
        if (this.state.hadBeenSeeded && current.stage === STAGES.PLANNED) {
          // put seeds back in stock
          const currentStock = parseInt(varietyObj.currentStock) + parseInt(germCells);
          let stockTransactions = varietyObj.stockTransactions ? deepCopyObject(varietyObj.stockTransactions) : [];
          stockTransactions.push({
            date: seedDate,
            action: INVENTORY_ACTIONS.UNSEED,
            quant: germCells
          });
          addEditItem({
            collection: COLLECTIONS.VARIETIES,
            id: variety,
            item: { currentStock, stockTransactions },
          });
          if (currentStock < varietyObj.minStock) {
            pushLowInventoryItem(variety);
          } else {
            deleteLowInventoryItem(variety);
          }
        } else if (!this.state.hadBeenSeeded && current.stage !== STAGES.PLANNED) {
          // remove seeds from stock        
          const currentStock = parseInt(varietyObj.currentStock) - parseInt(germCells);
          let stockTransactions = varietyObj.stockTransactions ? deepCopyObject(varietyObj.stockTransactions) : [];
          stockTransactions.push({
            date: seedDate,
            action: INVENTORY_ACTIONS.SEED,
            quant: -germCells
          });
          addEditItem({
            collection: COLLECTIONS.VARIETIES,
            id: variety,
            item: { currentStock, stockTransactions },
          });
          if (currentStock < varietyObj.minStock) {
            pushLowInventoryItem(variety);
          } else {
            deleteLowInventoryItem(variety);
          }
        }
      }

      // push last used seed lot
      if (seedLot && !variety.lastUsedSeedLot) {
        addEditItem({
          collection: COLLECTIONS.VARIETIES,
          id: variety,
          item: { lastUsedSeedLot: seedLot }
        });
      }

      // batch.harvest = [];
      // batch.disposal = [];

      // Edit by Chandan yadav @ 30 July

      await this.props.onSave(batch);
      // await this.promisedSetState({...this.state, isFinalizing: false, isSaving: false, ...batch});
      this.setState({isSaving: false})
      this.props.setEditsMade(false);
      // this.props.onClose();
    }, 1);
  }

  async handleSaveHD(e) {
    if (e) { e.preventDefault(); }

    // await this.promisedSetState({isSaving: true});

    var batch = this.props.batches[this.props.batchID];

    let {
      harvest,
      disposal,
    } = deepCopyObject(this.state);

    batch.harvest = harvest;
    batch.disposal = disposal;


    await this.props.onSaveHD(batch);
    this.props.setEditsMade(false);

  }

  async handleFinalize() {
    if (this.state.isFinalized) {
      this.setState({ isFinalizing: true });
      await batchTransaction(this.props.batchID, { isFinalized: false });
      this.setState({ isFinalized: false, isFinalizing: false });
      this.closeModal();
    } else {
      if (this.props.editsMade) {
        this.handleSave();
      } else {
        this.setState({ isFinalizing: true });
        await batchTransaction(this.props.batchID, { isFinalized: true });
        this.setState({ isFinalizing: false, isFinalized: true });
      }
    }
  }

  formatDate = (e) => {
    let name = e.target.name
    let oldDate = e.target.value;

    if (!moment(oldDate).isValid()) {
      return this.setState({ [name]: moment().format('YYYY-MM-DD') })
    }
    let date = oldDate.split('/')
    if (date.length === 2) {
      if (date[0].length < 2) {
        oldDate = '0' + date[0]
      }
    }
    else if (date.length === 3) {
      var test0 = date[0] + '/';
      var test1 = date[1] + '/';
      if (date[0].length < 2) {
        test0 = '0' + test0
      }
      if (date[1].length < 2) {
        test1 = '0' + test1
      }
      oldDate = test0 + test1 + date[2]
    }
    this.setState({ [name]: moment(oldDate).format('YYYY-MM-DD') })
  }

  getRatingContainer = (cropType) => {
    const { classes} = this.props;
    let res = null;
    let ratVal = 0;
    if(cropType == "seedAndStart" || cropType == "seed"){
      cropType = (this.state.tasks.seedAndStart)?"seedAndStart":((this.state.tasks.seed)?"seed":"");
      if(cropType !== ""){
        ratVal = this.state.tasks[cropType].rating || 0;
        res = (<Grid container xs={6} md={3} className={classes.ratingCon}>
        <Grid container direction='row' className={classes.bigRatingCon}>
          <Typography variant="body2">
            Rating  
          </Typography>
          {ratVal > 0 && <Grid onClick={(e) =>this.ratingHandleChange(cropType, 0)} className={classes.deleteRowButtonCon}>
            <Tooltip placement="top" title="Remove rating">
              <i className={`far fa-times-circle fa-2x ${classes.deleteRowButton}`}></i>
            </Tooltip>
          </Grid>}
        </Grid>
        {(<Grid container direction='row'>
            {new Array(5).fill(1).map((each, j) => (
              <Grid onClick={(e) =>this.ratingHandleChange(cropType, j+1)} container className={classes.rate} style={{ backgroundColor: ratVal !== j + 1 ? 'gray' : '#42b883' }}>
                <Typography variant={'subtitle2'} className={classes.rateNumber} >{j + 1}</Typography>
              </Grid>
            ))}
          </Grid>)}
      </Grid>)
      }
    }else{
      if(this.state.tasks[cropType]){
        ratVal = this.state.tasks[cropType].rating || 0;
        res = (<Grid container xs={6} md={3} className={classes.ratingCon}>
          <Grid container direction='row' className={classes.bigRatingCon}>
            <Typography variant="body2">
              Rating  
            </Typography>
            {ratVal > 0 && <Grid onClick={(e) =>this.ratingHandleChange(cropType, 0)} className={classes.deleteRowButtonCon}>
              <Tooltip placement="top" title="Remove rating">
                <i className={`far fa-times-circle fa-2x ${classes.deleteRowButton}`}></i>
              </Tooltip>
            </Grid>}
          </Grid>
          {(<Grid container direction='row'>
            {new Array(5).fill(1).map((each, j) => (
              <Grid onClick={(e) =>this.ratingHandleChange(cropType, j+1)} container className={classes.rate} style={{ backgroundColor: ratVal !== j + 1 ? 'gray' : '#42b883' }}>
                <Typography variant={'subtitle2'} className={classes.rateNumber} >{j + 1}</Typography>
              </Grid>
            ))}
          </Grid>)}
        </Grid>)
      }
    }
    return res;
  }

  getAvrageRating = () => {
    let res = 0;
    let totalCount = 0;
    let totalRating = 0;
    const totalTasks = this.state.tasks;

    if(totalTasks && Object.keys(totalTasks).length > 0){
      const taskAary = Object.keys(totalTasks);
      taskAary.map((taskArrId)=>{
        if (
          totalTasks[taskArrId] && 
          totalTasks[taskArrId].rating && 
          typeof totalTasks[taskArrId].rating === "number" && 
          totalTasks[taskArrId].rating > 0
        ) {
          totalCount += 1;
          totalRating += totalTasks[taskArrId].rating;
        }
      });
    }
    if(totalCount > 0){
      res = (totalRating/totalCount);
    }
    return parseFloat(res).toFixed(1);
  }

  render() {
    const { classes, crop, variety, growthCycles, onClose } = this.props;
    const user = this.context.user;

    const isRockWool = this.props.batch.growthCycle.growMedia === 'Rockwool';

      console.log('this.props.batch',this.props.batch)

    return (
      !this.state.isLoading ?
        <div className={classes.modal} tabIndex={-1}>

          <div className={classes.formContainer}>
            <Grid container className={classes.container} spacing={16}>
              <Grid item className={classes.avatarContainer}>
                <Grid container justify="flex-start" alignItems="center" style={{ height: '100%' }}>
                  {variety.avatarURL ?
                    <Avatar alt={variety.name} src={variety.avatarURL} className={classes.avatar} />
                    :
                    <div><i className={`fas fa-leaf fa-3x ${classes.varietyIcon}`}></i></div>
                  }
                </Grid>
              </Grid>
              <Grid item xs={7}>
                <Typography variant="h4" id="modal-title">{crop.name} - {variety.name}</Typography>
                <Typography variant="h6" className={classes.subtitle}>Lot: {this.state.GRFlot}</Typography>
                <Typography variant="h6" className={classes.subtitle}>Overall Rating: {this.getAvrageRating()}</Typography>
              </Grid>
              {user.role === 'admin' &&
                <Grid item xs={3} style={{ textAlign: 'right' }}>
                  <Button variant="outlined" size="small" className={classes.topButton} onClick={this.handleDeleteBatchClick}>
                    <i className={`${classes.buttonIcon} far fa-trash-alt fa-2x`}></i>
                    {this.state.isDeleting ?
                      <CircularProgress className={classes.deleteButtonLoader} size={21} />
                      :
                      'Delete Batch'
                    }
                  </Button>
                </Grid>
              }
            </Grid>

            {this.props.batches[this.props.batchID].isPerpetual &&
              <Grid container className={classes.container} spacing={16}>
                <Button
                  variant="outlined"
                  size="small"
                  color="secondary"
                  className={classes.perpetualButton}
                  onClick={this.openPerpetualBatch}
                >
                  <i className={`${classes.buttonIcon} far fa-edit fa-2x`}></i>
                  Edit Perpetual Batch
                </Button>
              </Grid>
            }

            <form id="current-batch-form" onSubmit={this.handleSave}>

              <Grid container className={classes.container} spacing={16}>
                <Grid item xs={3}>
                  <Typography variant="h6" style={{ fontSize: '1.2rem', fontWeight: 400 }}>Growth Cycle:</Typography>
                </Grid>
                <Grid item xs={9}>
                  {this.state.growthCycle &&
                    <GrowthCycleCard
                      growthCycle={this.state.growthCycle}
                      handleEdit={this.state.isFinalized ? null : (() => this.openModal('growthCycle'))}
                      handleSelectNew={this.state.isFinalized ? null : this.openMenu}
                    />
                  }
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h5">Seeding</Typography>
                </Grid>
                <Grid item xs={6} md={3} className={classes.seedRow}>
                  <TextField
                    disabled
                    id="plannedStartDate"
                    label="Planned Start Date"
                    margin="dense"
                    value={moment(this.state.growthCycle.germ.date).format('MM/DD/YYYY')}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} md={3} className={classes.seedRow}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DatePicker
                      disabled={this.state.isFinalized}
                      label="Seed Date"
                      name="seedDate"
                      value={this.state.seedDate}
                      format="MM/DD/YYYY"
                      // mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                      onChange={(date) => this.handleChange('seedDate', 'date')({ target: { value: date } })}
                      onBlur={(e) => this.formatDate(e)}
                      animateYearScrolling
                      autoOk
                      clearable
                      keyboard
                      margin="dense"
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={6} md={3} className={classes.seedRow}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DatePicker
                      disabled={this.state.isFinalized}
                      label="Start Date"
                      name="initiationDate"
                      value={this.state.initiationDate}
                      format="MM/DD/YYYY"
                      // mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                      onChange={(date) => this.handleChange('initiationDate', 'date')({ target: { value: date } })}
                      onBlur={(e) => this.formatDate(e)}
                      animateYearScrolling
                      autoOk
                      clearable
                      keyboard
                      margin="dense"
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={6} md={3} className={classes.seedRow} style={{ position: 'relative' }}>
                  <TextField
                    disabled={this.state.isDisabled.seedLot || this.state.isFinalized}
                    error={this.state.errors.seedLot}
                    helperText={this.state.errorMessages.seedLot}
                    id="seedLot"
                    label="Seed Lot"
                    margin="dense"
                    value={this.state.seedLot}
                    onChange={this.handleChange('seedLot')}
                    fullWidth
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                  {variety.lastUsedSeedLot && !this.state.isDisabled.seedLot &&
                    <Typography variant="body1" className={classes.seedLotLink} onClick={this.handleSeedLotLinkClick}>Use last lot # used</Typography>
                  }
                </Grid>
                <Grid item xs={6} md={3} className={classes.seedRow}>
                  <TextField
                    disabled={this.state.isFinalized}
                    id="newDisposalDetails"
                    label="Cells"
                    margin="dense"
                    value={(this.state.tasks.seedAndStart)?this.state.tasks.seedAndStart.quant:((this.state.tasks.seed)?this.state.tasks.seed.quant:"")}
                    onChange={(e) => (this.state.tasks.seedAndStart)?this.quantHandleChange('seedAndStart',e.target.value):((this.state.tasks.seed)?this.quantHandleChange('seed',e.target.value):"")}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={6} md={3} className={classes.seedRow}>
                <TextField
                  disabled={this.state.isFinalized}
                  id="newDisposalDetails"
                  label="Notes"
                  margin="dense"
                  value={(this.state.tasks.seedAndStart)?this.state.tasks.seedAndStart.notes:((this.state.tasks.seed)?this.state.tasks.seed.notes:"")}
                  onChange={(e) => (this.state.tasks.seedAndStart)?this.noteHandleChange('seedAndStart',e.target.value):((this.state.tasks.seed)?this.noteHandleChange('seed',e.target.value):"")}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
                </Grid>
                {this.getRatingContainer("seedAndStart")} 

                
                
                <Menu
                  id="growthCycleSelectMenu"
                  open={!!this.state.anchorEl}
                  anchorEl={this.state.anchorEl}
                  onClose={() => this.closeMenu()}
                  className={classes.menuItem}
                >
                  {variety.growthCycles && variety.growthCycles.map(gcID => {
                    const growthCycle = growthCycles[gcID];
                    return (
                      <MenuItem key={gcID} onClick={() => this.closeMenu(gcID)}>
                        <GrowthCycleCard
                          growthCycle={growthCycle}
                        />
                      </MenuItem>
                    );
                  })}
                </Menu>
              </Grid>

              <Typography variant="h5">Prop</Typography>
              <Grid container className={classes.container} spacing={16}>
                <Grid item xs={6} md={3}>
                  <TextField
                    disabled
                    id="plannedPropDate"
                    label="Planned Date"
                    margin="dense"
                    value={moment(this.state.growthCycle.prop.date).format('MM/DD/YYYY')}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} md={3}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DatePicker
                      disabled={this.state.isDisabled.propDate || this.state.isFinalized}
                      label="Transfer Date"
                      name="propDate"
                      value={this.state.propDate}
                      format="MM/DD/YYYY"
                      // mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                      onChange={(date) => this.handleChange('propDate', 'date')({ target: { value: date } })}
                      onBlur={(e) => this.formatDate(e)}
                      animateYearScrolling
                      autoOk
                      clearable
                      keyboard
                      margin="dense"
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={6} md={3} className={classes.seedRow}>
                  <TextField
                    disabled={this.state.isFinalized}
                    id="newDisposalDetails"
                    label="Notes"
                    margin="dense"
                    value={(this.state.tasks.transferProp)?this.state.tasks.transferProp.notes:""}
                    onChange={(e) => (this.state.tasks.transferProp)?this.noteHandleChange('transferProp',e.target.value):""}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                {this.getRatingContainer("transferProp")}
              </Grid>

              {this.state.growthCycle.hd.days ?
                <div>
                  <Typography variant="h5">HD</Typography>
                  <Grid container className={classes.container} spacing={16}>
                    <Grid item xs={6} md={3}>
                      <TextField
                        disabled
                        id="plannedHDDate"
                        label="Planned Date"
                        margin="dense"
                        value={this.state.growthCycle.hd.days ? moment(this.state.growthCycle.hd.date).format('MM/DD/YYYY') : null}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DatePicker
                          disabled={this.state.isDisabled.hdDate || this.state.isFinalized}
                          label="Transfer Date"
                          name="hdDate"
                          value={this.state.hdDate}
                          format="MM/DD/YYYY"
                          // mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                          onChange={(date) => this.handleChange('hdDate', 'date')({ target: { value: date } })}
                          onBlur={(e) => this.formatDate(e)}
                          animateYearScrolling
                          autoOk
                          clearable
                          keyboard
                          margin="dense"
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={6} md={3} className={classes.seedRow}>
                      <TextField
                        disabled={this.state.isFinalized}
                        id="newDisposalDetails"
                        label="Notes"
                        margin="dense"
                        value={(this.state.tasks.transferHD)?this.state.tasks.transferHD.notes:""}
                        onChange={(e) => (this.state.tasks.transferHD)?this.noteHandleChange('transferHD',e.target.value):""}
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    {this.getRatingContainer("transferHD")}
                  </Grid>
                </div>
                :
                null
              }
              {isRockWool ? <>
              <Typography variant="h5">FS</Typography>
              <Grid container className={classes.container} spacing={16}>
                <Grid item xs={6} md={3}>
                  <TextField
                    disabled
                    id="plannedFSDate"
                    label="Planned Date"
                    margin="dense"
                    value={moment(this.state.growthCycle.fs.date).format('MM/DD/YYYY')}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} md={3}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DatePicker
                      disabled={this.state.isDisabled.fsDate || this.state.isFinalized}
                      label="Transfer Date"
                      name="fsDate"
                      value={this.state.fsDate}
                      format="MM/DD/YYYY"
                      // mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                      onChange={(date) => this.handleChange('fsDate', 'date')({ target: { value: date } })}
                      onBlur={(e) => this.formatDate(e)}
                      animateYearScrolling
                      autoOk
                      clearable
                      keyboard
                      margin="dense"
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={6} md={3} className={classes.seedRow}>
                  <TextField
                    disabled={this.state.isFinalized}
                    id="newDisposalDetails"
                    label="Notes"
                    margin="dense"
                    value={(this.state.tasks.transferFS)?this.state.tasks.transferFS.notes:""}
                    onChange={(e) => (this.state.tasks.transferFS)?this.noteHandleChange('transferFS',e.target.value):""}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                {this.getRatingContainer("transferFS")}
              </Grid>
              </>:null}
            </form>

            <Typography variant="h5">Harvest</Typography>
            {this.state.growthCycle.harvestType === HARVEST_TYPES.ONCE ?
              <Grid container spacing={16} alignItems="center">
                <Grid item xs={6} md={3}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DatePicker
                      disabled={this.state.isDisabled.plannedHarvestDate || this.state.isFinalized}
                      label="Planned Date"
                      value={this.state.plannedHarvestDate}
                      format="MM/DD/YYYY"
                      name="plannedHarvestDate"
                      // mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                      onChange={(date) => this.handleChange('plannedHarvestDate', 'date')({ target: { value: date } })}
                      onBlur={(e) => this.formatDate(e)}
                      animateYearScrolling
                      autoOk
                      clearable
                      keyboard
                      margin="dense"
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={6} md={3}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DatePicker
                      disabled={this.state.isDisabled.harvestDate || this.state.isFinalized}
                      label="Harvest Date"
                      name="harvestDate"
                      value={this.state.harvestDate}
                      format="MM/DD/YYYY"
                      // mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                      onChange={(date) => this.handleChange('harvestDate', 'date')({ target: { value: date } })}
                      onBlur={(e) => this.formatDate(e)}
                      animateYearScrolling
                      autoOk
                      clearable
                      keyboard
                      margin="dense"
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={6} md={3}>
                  <TextField
                    disabled={this.state.isFinalized}
                    id="newDisposalDetails"
                    label="Notes"
                    margin="dense"
                    value={(this.state.tasks.harvest)?this.state.tasks.harvest.notes:""}
                    onChange={(e) => (this.state.tasks.harvest)?this.noteHandleChange('harvest',e.target.value):""}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                {this.getRatingContainer("harvest")}
              </Grid>
              :
              this.state.growthCycle.harvest.date.map((date, i) => (
                <Grid container spacing={16} alignItems="center" key={`${TASK_NAMES.HARVEST}-${i}`}>
                  <Grid item xs={6} md={3}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <DatePicker
                        disabled={this.state.isDisabled.plannedHarvestDate || this.state.isFinalized}
                        label="Planned Date"
                        name={`plannedHarvestDate-${i}`}
                        value={this.state.plannedHarvestDate[i]}
                        format="MM/DD/YYYY"
                        // mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                        onChange={(date) => this.handleChange(`plannedHarvestDate-${i}`, 'date')({ target: { value: date } })}
                        onBlur={(e) => this.formatDate(e)}
                        animateYearScrolling
                        autoOk
                        clearable
                        keyboard
                        margin="dense"
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <DatePicker
                        disabled={this.state.isDisabled.harvestDate || this.state.isFinalized}
                        label="Harvest Date"
                        name={`harvestDate-${i}`}
                        value={this.state.harvestDate[i] || null}
                        format="MM/DD/YYYY"
                        // mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                        onChange={(date) => this.handleChange(`harvestDate-${i}`, 'date')({ target: { value: date } })}
                        onBlur={(e) => this.formatDate(e)}
                        animateYearScrolling
                        autoOk
                        clearable
                        keyboard
                        margin="dense"
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <TextField
                      disabled={this.state.isFinalized}
                      id="newDisposalDetails"
                      label="Notes"
                      margin="dense"
                      value={(this.state.tasks.harvest)?this.state.tasks.harvest.notes:""}
                      onChange={(e) => (this.state.tasks.harvest)?this.noteHandleChange('harvest',e.target.value):""}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  {this.getRatingContainer("harvest")}
                </Grid>
              ))
            }


            <HarvestModule
              harvest={this.state.harvest}
              disposal={this.state.disposal}
              reasons={this.props.reasons}
              harvestIsActive={isRockWool  ? !!this.state.fsDate : !!this.state.propDate}
              
              isFinalized={this.state.isFinalized}
              showDates={true}
              showTrays={this.props.showTrays}
              isSavingHarvest={this.state.isSavingHarvest}
              remainingRafts={this.state.remainingRafts}
              remainingTrays={this.state.remainingTrays}

              avgWeightPerHead={this.state.avgWeightPerHead}
              avgWeightPerHeadGrams={this.state.avgWeightPerHeadGrams}
              totalRaftsHarvested={this.state.totalRaftsHarvested}
              totalWeightLogged={this.state.totalWeightLogged}
              totalRaftsLogged={this.state.totalRaftsLogged}
              estTotalBatchWeight={this.state.estTotalBatchWeight}
              addHarvestOrDisposal={this.addHarvestOrDisposal}
              deleteHarvestOrDisposal={this.deleteHarvestOrDisposal1}
              updateHarvestOrDisposal={this.updateHarvestOrDisposal}
            />
          </div>

          <div className={classes.buttonContainerOuter}>
            <div className={classes.buttonContainerInner}>
              <Button
                variant="contained"
                className={classNames(classes.red, classes.button)}
                onClick={onClose}
              >
                Close
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={this.state.isFinalized}
                form="current-batch-form"
                className={classes.button}
                onClick={this.handleSave}
                style={{ minWidth: 67 }}
              >
                {this.state.isSaving ?
                  <CircularProgress className={classes.submitButtonLoader} size={21} />
                  :
                  'Save'
                }
                <div className={classes.saveError}>{this.state.errorMessages.save}</div>
              </Button>
              <Button
                variant="contained"
                color="secondary"
                disabled={this.state.isFinalized ? false : !this.state.readyToFinalize}
                className={classes.button}
                onClick={this.state.isFinalized ? () => this.openModal('confirmUnfinalize') : this.handleFinalize}
                style={{ minWidth: 94 }}
              >
                {this.state.isFinalizing ?
                  <CircularProgress className={classes.submitButtonLoader} size={21} />
                  :
                  this.state.isFinalized ? 'Un-Finalize' : 'Finalize'
                }
              </Button>
            </div>
          </div>




          <Dialog
            open={this.state.dialogOpen}
            onClose={this.closeDialog}
            aria-describedby="confirmation-dialog"
          >
            <DialogContent>
              <DialogContentText id="confirmation-dialog">
                {this.state.dialogMessage}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              {this.state.dialogButtons.map(button => (
                <Button key={button.label} onClick={() => this.closeDialog(button.answer)} color={button.color} autoFocus={button.autoFocus}>
                  {button.showLoader ?
                    (button.showLoader ?
                      <CircularProgress className={classes.submitButtonLoader} size={21} />
                      :
                      button.label
                    )
                    :
                    button.label
                  }
                </Button>
              ))}
            </DialogActions>
          </Dialog>

          <Modal
            aria-labelledby="modal-title"
            open={this.state.modalIsOpen}
            onClose={this.closeModal}
          >
            {this.renderModal()}
          </Modal>


        </div>
        :
        <div className={classes.modal} tabIndex={-1}>
          <FullScreenLoader />
        </div>
    );
  }
};

CurrentBatchModal.contextType = UserContext;

CurrentBatchModal.propTypes = {
  classes: PropTypes.object.isRequired,
  batchID: PropTypes.string.isRequired,
  batches: PropTypes.object.isRequired,
  growthCycles: PropTypes.object.isRequired,
  crop: PropTypes.object.isRequired,
  variety: PropTypes.object.isRequired,
  reasons: PropTypes.array.isRequired,
  rafts: PropTypes.object.isRequired,
  editsMade: PropTypes.bool.isRequired,
  setEditsMade: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

export default withStyles(styles)(CurrentBatchModal);
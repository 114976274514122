import { LOAD_RAFTS, ADD_EDIT_RAFT, DELETE_RAFT } from '../actionTypes';
import { deepCopyObject } from '../../utils/utils';

export default (state = {}, action) => {
  switch (action.type) {
    case LOAD_RAFTS:
      return {...action.rafts};
    
    case ADD_EDIT_RAFT:
      // merges received key-val pairs with existing ones for the item in question
      const key = Object.keys(action.raft)[0];
      let currRaftObj = state[key] ? deepCopyObject(state[key]) : {};
      currRaftObj = {...currRaftObj, ...action.raft[key]};
      return {...state, [key]: currRaftObj};

    case DELETE_RAFT:
      let newState = deepCopyObject(state);
      delete newState[action.id];
      return newState;

    default:
      return state;
  }
};
import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core';

const styles = () => ({
  title: {
    marginBottom: '3rem'
  }
});

const Title = props => {
  return (
    <Grid item xs={12} className={props.classes.title}>
      <Typography variant="h3">{props.title}</Typography>
    </Grid>
  );
};

Title.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired
};

export default withStyles(styles)(Title);
import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Fab, Icon, Modal, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import moment from 'moment';
import HarvestModule from './HarvestModule';
import { calculateBatchMetrics } from '../utils/appFunctions/batches';
import APP_SETTINGS from '../utils/appSettings';
import EditFinishingPonds from './EditFinishingPonds';
import { deepCopyObject, fix2 } from '../utils/utils';
import { getFarms } from '../store/actions/adders';

const { TASK_TYPES, UNITS, STAGES } = APP_SETTINGS;


const cardPadding = 24;
const styles = theme => ({
  fullContainer: {
    display: 'flex',
  },
  checkboxContainer: {
    width: '50px',
    display: 'flex',
    alignItems: 'center'
  },
  taskCard: {
    width: `calc(100% - 20px)`
  },
  card: {
    width: '100%',
    // maxWidth: 510,
    minHeight: 118,
    overflow: 'hidden',
    transition: theme.transitions.create(['max-width', 'min-height'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  link: {
    cursor: 'pointer'
  },
  cardExpanded: {
    // maxWidth: 1200,
    minHeight: 365,
    transition: theme.transitions.create(['max-width', 'min-height'], {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  cardContent: {
    paddingBottom: '0px !important',
    width: '100%',
  },
  topRow: {
    // width: 850 - (cardPadding * 2),
    width: '100%',

  },
  mainContent: {
    width: '40%',
  },
  title: {
    display: 'inline-block',
    maxWidth: 225,
    height: '1.5rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  date: {
    display: 'inline-block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    marginTop: 5
  },
  delayContainer: {
    width: '60%',
  },
  delayInput: {
    // marginTop: '-0.4rem'
  },
  delayButton: {
    // marginTop: '-13px',
    marginLeft: '8px',
    marginRight: '8px'
  },
  revertButton: {
    // marginTop: '-13px',
  },
  iconContainer: {
    textAlign: 'center',
    width: '100%',
    '& i': {
      color: theme.palette.secondary.main,
      lineHeight: '45px'
    },
    '&:last-child': {
      marginBottom: '-0.3rem'
    }
  },
  user: {
    cursor: 'pointer',
    transition: 'color 200ms',
    '&:hover': {
      color: theme.palette.primary.dark
    },
    '& i': {
      marginLeft: '0.7rem'
    }
  },
  userSelect: {
    fontStyle: 'italic'
  },
  userDisabled: {
    display: 'inline-block',
    width: 'max-content',
    overflow: 'visible',
    marginTop: '0.1rem'
  },
  grey: {
    color: theme.palette.grey[700]
  },
  green: {
    color: theme.palette.primary.main
  },
  bottomMargin: {
    marginBottom: '0.05rem'
  },
  inputColumn: {
    // marginTop: '1.2rem',
    marginRight: '10px'
    // marginBottom: '-0.4rem'
  },
  buttonContainer: {
    marginTop: '1.5rem',
    marginBottom: '1.5rem'
  },
  button: {
    marginRight: '0.5rem'
  },
  saveButton: {
    marginRight: '0.5rem',
    marginBottom: '0.5rem',
    width: 68,
    height: 36,
  },
  submitButtonLoader: {
    color: 'white'
  },
  rate: {
    height: 25,
    width: 25,
    borderRadius: 12.5,
    backgroundColor: '#42b883',
    cursor: 'pointer',
    marginLeft: 10,
    justifyContent: 'center',
  },
  rateNumber: {
    color: '#fff',
    marginTop: 2.5
  },
  fab: {
    maxWidth: 36,
    maxHeight: 36
  },
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: 'none',
    marginTop: '13%',
    marginLeft: '40%'
  },
  poolName: {
    maxWidth: '100%',
  },
  poolName1: {
    color: '#42b883'
  }
});

const taskNames = {
  seed: 'Seed',
  transfer: 'Transfer to',
  start: 'Start',
  'seedAndStart': 'Seed & Start',
  harvest: 'Harvest'
};

const icons = {
  seed: 'fas fa-seedling',
  transfer: 'fas fa-arrow-right',
  start: 'fas fa-tint',
  'seedAndStart': 'fas fa-tint',
  harvest: 'fas fa-cut'
};

const destinations = {
  prop: 'Prop',
  hd: 'HD',
  fs: 'FS'
};

class TaskCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      seedLot: '',
      delayDays: null,
      anchorEl: null,
      allFarms: [],
      harvest: [],
      disposal: [],
      growthCycle: {},
      remainingRafts: null,
      remainingTrays: null,
      avgWeightPerHead: null,
      avgWeightPerHeadGrams: null,
      totalRaftsLogged: null,
      totalWeightLogged: null,
      totalRaftsHarvested: null,
      estTotalBatchWeight: null,
      containerHeight: 0,
      overflow: 'hidden',
      isWorking: false,
      isSaving: false,
      open: false,
      locationModal: false,
      newHarvestLocation:'',
      notes: '',
      pondModal: false,
      copyBatch: null,
      errors: {
        hd: false,
        fs: false
      },
      errorMessages: {
        hd: 'Please enter valid details.',
        fs: 'Please enter valid details.',
      },
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleTaskSelect = this.handleTaskSelect.bind(this);
    this.handleDelayClick = this.handleDelayClick.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleChangePond = this.handleChangePond.bind(this);
    this.openMenu = this.openMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.addHarvestOrDisposal = this.addHarvestOrDisposal.bind(this);
    this.deleteHarvestOrDisposal = this.deleteHarvestOrDisposal.bind(this);
    this.updateHarvestOrDisposal = this.updateHarvestOrDisposal.bind(this);
  }

  promisedSetState = (newState) => {
    return new Promise((resolve) => {
      this.setState(newState, () => {
        resolve()
      });
    });
  }

  async componentDidMount() {
    this.getAllFarms();
    if (this.props.batch) {
      const {
        harvest,
        disposal,
        growthCycle,
        seedLot,
        avgWeightPerHead,
        avgWeightPerHeadGrams,
        remainingRafts,
        remainingTrays,
        totalRaftsLogged,
        totalWeightLogged,
        totalRaftsHarvested,
        estTotalBatchWeight,
        QtyToSeed
      } = this.props.batch;

      await this.promisedSetState({
        harvest,
        disposal,
        growthCycle,
        seedLot: seedLot || '',
        avgWeightPerHead: avgWeightPerHead || null,
        avgWeightPerHeadGrams: avgWeightPerHeadGrams || null,
        remainingRafts: remainingRafts || null,
        remainingTrays: remainingTrays || QtyToSeed,
        totalRaftsLogged: totalRaftsLogged || null,
        totalWeightLogged: totalWeightLogged || null,
        totalRaftsHarvested: totalRaftsHarvested || null,
        copyBatch: this.props.batch || null,
        estTotalBatchWeight: estTotalBatchWeight || null
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isExpanded !== this.props.isExpanded) {
      const containerHeight = this.props.isExpanded ? 'auto' : 0;
      if (containerHeight === 0) {
        this.setState({ overflow: 'hidden' });
        setTimeout(() => {
          this.setState({ containerHeight });
        }, 225);
      } else {
        setTimeout(() => {
          this.setState({ containerHeight, overflow: 'visible' });
        }, 225);
      }
    }
    if (this.props.batch && prevProps.batch !== this.props.batch) {
      const {
        harvest,
        disposal,
        growthCycle,
        seedLot,
        avgWeightPerHead,
        avgWeightPerHeadGrams,
        remainingRafts,
        remainingTrays,
        totalRaftsLogged,
        totalWeightLogged,
        totalRaftsHarvested,
        estTotalBatchWeight,
        QtyToSeed
      } = this.props.batch;
      this.setState({
        harvest,
        disposal,
        growthCycle,
        // seedLot: seedLot || '',
        avgWeightPerHead: avgWeightPerHead || null,
        avgWeightPerHeadGrams: avgWeightPerHeadGrams || null,
        remainingRafts: remainingRafts || null,
        remainingTrays: remainingTrays || null,
        totalRaftsLogged: totalRaftsLogged || null,
        totalWeightLogged: totalWeightLogged || null,
        totalRaftsHarvested: totalRaftsHarvested || null,
        estTotalBatchWeight: estTotalBatchWeight || null
      });
    }
  }

  async getAllFarms() {
    Promise.all([getFarms()]).then(resp => {
      this.setState({ allFarms: resp[0], isLoaded: true })
    })
  }

  

  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value }, () => this.props.handleChange(this.props.id)(this.state.seedLot));

  }

  handleClose = () => {
    this.setState({ open: false })
  }

  handleLocationModalClose = () => {
    this.setState({ locationModal: false })
  }

  handlePondModalClose = () => {
    this.setState({ pondModal: false })
  }

  handleClick(e) {
    e.stopPropagation();
  }

  handleDelayClick(e) {
    e.stopPropagation();
    e.preventDefault();
    this.setState({ isWorking: true }, () => {
      this.props.handleDelayClick(this.props.id, this.state.delayDays);
      setTimeout(() => {
        this.setState({ isWorking: false });
      }, 500);
    });
  }

  openMenu(e) {
    e.stopPropagation();
    this.setState({ anchorEl: e.currentTarget });
  }

  closeMenu(id) {
    this.setState({ anchorEl: null });
    if (id) {
      this.props.handleUserSelect(id)
    }
  }

  handleTaskSelect(e) {
    e.preventDefault();
    if (this.props.task.type === TASK_TYPES.SEED || this.props.task.type === TASK_TYPES.SEED_AND_START) {
      this.props.handleTaskSelect(e, { seedLot: this.state.seedLot });
    } else if (this.props.task.type === TASK_TYPES.HARVEST) {
      this.props.handleTaskSelect(e, { harvest: this.state.harvest, disposal: this.state.disposal });
    } else {
      this.props.handleTaskSelect(e, {});
    }
  }

  handleRevertClick = (e) => {
    this.props.RevertTask(this.props.id)
  }

  addHarvestOrDisposal = async ({ type, item }) => {

    const isRockWool = this.props.batch.growthCycle.growMedia === 'Rockwool';
    const raftSites = isRockWool ? this.props.rafts[this.state.growthCycle.fs.raftType].sites : parseFloat(this.props.batch.QtyToSeed);
    console.log('addHarvestOrDisposal', type, item);
    // push new harvest or disposal to component state

    const calculatedRafts = item.units === UNITS.RAFTS ? item.count : item.units === UNITS.TRAYS ? item.count / 4 : Math.round((item.count / raftSites) * 100) / 100;
    if (calculatedRafts > (isRockWool ? this.state.remainingRafts : this.state.remainingTrays)) return;
    let itemArr = this.state[type].slice();

    itemArr.push({
      ...item,
      rafts: calculatedRafts
    });

    await this.promisedSetState({ [type]: itemArr });


    // recalculate metrics
    this.setState({
      ...calculateBatchMetrics({
        harvest: this.state.harvest,
        disposal: this.state.disposal,
        growthCycle: this.state.growthCycle,
        raftSites,
        QtyToSeed: this.props.batch.QtyToSeed,
        isComplete: this.props.batch.current.stage === STAGES.COMPLETED,
        cycleDetail: this.props.batch.cycleDetail || {}
      })
    }, () => {
      setTimeout(() => {
        this.handleSave()
      }, 500);
    });
  }

  deleteHarvestOrDisposal = async ({ type, ind }) => {
    // remove harvest or disposal from component state
    let itemArr = this.state[type].slice();
    itemArr.splice(ind, 1);
    await this.promisedSetState({ [type]: itemArr });
    const isRockWool = this.props.batch.growthCycle.growMedia === 'Rockwool';

    // recalculate metrics
    const raftSites = isRockWool ? this.props.rafts[this.state.growthCycle.fs.raftType].sites : parseFloat(this.props.batch.QtyToSeed);

    let customData = calculateBatchMetrics({
      isRockWool,
      harvest: this.state.harvest,
      disposal: this.state.disposal,
      growthCycle: this.state.growthCycle,
      raftSites,
      QtyToSeed: this.props.batch.QtyToSeed,
      isComplete: this.props.batch.current.stage === STAGES.COMPLETED,
      revert: true,
      cycleDetail: this.props.batch.cycleDetail || {}
    })

    console.log('deleteHarvestOrDisposal', customData)

    await this.promisedSetState({...customData});
    await this.props.onSave({
      id: this.props.id,
      harvest: this.state.harvest,
      disposal: this.state.disposal,
      saveType: false
    });
  }

  updateHarvestOrDisposal = async (updatedVal) => {

    var type = updatedVal.type;
    var ind = updatedVal.index;

    let itemArr = this.state[type].slice();
    itemArr[ind].weight = updatedVal.weight;
    await this.promisedSetState({ [type]: itemArr });
    const isRockWool = this.props.batch.growthCycle.growMedia === 'Rockwool';

    // recalculate metrics
    const raftSites = isRockWool ? this.props.rafts[this.state.growthCycle.fs.raftType].sites : parseFloat(this.props.batch.QtyToSeed);

    let customData = calculateBatchMetrics({
      isRockWool,
      harvest: this.state.harvest,
      disposal: this.state.disposal,
      growthCycle: this.state.growthCycle,
      raftSites,
      QtyToSeed: this.props.batch.QtyToSeed,
      isComplete: this.props.batch.current.stage === STAGES.COMPLETED,
      revert: true,
      cycleDetail: this.props.batch.cycleDetail || {}
    })

    console.log('deleteHarvestOrDisposal', customData)

    await this.promisedSetState({...customData});
    await this.props.onSave({
      id: this.props.id,
      harvest: this.state.harvest,
      disposal: this.state.disposal,
    });
  }

  handleSave = async (e) => {
    // e.preventDefault();
    // e.stopPropagation();
    await this.promisedSetState({ isSaving: true });
    setTimeout(async () => {
      await this.props.onSave({
        id: this.props.id,
        harvest: this.state.harvest,
        disposal: this.state.disposal
      });
      this.setState({ isSaving: false });
    }, 1);
  }

  handlePondSave = async () => {
    // e.preventDefault();
    // e.stopPropagation();
    await this.promisedSetState({ isSaving: true });
    setTimeout(async () => {
      await this.props.onPondSave({
        id: this.props.id,
        propLocation1: this.state.copyBatch.propLocation1,
        finishingLocation: this.state.copyBatch.finishingLocation,
      });
      this.setState({ isSaving: false });
    }, 1);
  }

  handleChangePond = (ind, farm, type, value) => {
    const newCopyBatch = {...this.state.copyBatch};
    if(value !== ""){
      newCopyBatch[farm].pond[type][ind].name = value.name;
      newCopyBatch[farm].pond[type][ind].pond = value.id;
    }
    this.setState({ copyBatch: newCopyBatch });
  }
  handleChangeSize = (ind, farm, type, value = 0) => {
    const newCopyBatch = {...this.state.copyBatch};
    console.log("testtttttInputttttt:handleChangeSize",newCopyBatch);
    const sum = newCopyBatch[farm].pond[type].reduce((acc, a) => {
      return acc + parseFloat(a.noOfRaft > 0 ? a.noOfRaft : 0 || 0)
    }, 0);

    let totalRaft = 0;

    if (newCopyBatch.QtyToSeed) {
      totalRaft = parseInt(newCopyBatch.QtyToSeed);
    }
    else {
      if(type == "fs"){
        totalRaft = newCopyBatch.cycleDetail?.fsEndRafts || newCopyBatch.growthCycle[type].endRafts;
      }else if(type == "hd"){
        totalRaft = newCopyBatch.cycleDetail?.hdEndRafts || newCopyBatch.growthCycle[type].endRafts;
      }else if(type == "prop"){
        totalRaft = newCopyBatch.growthCycle[type].endRafts;
      }
    }
    const remainingNewRafts = parseFloat(parseFloat(totalRaft || 0) - (sum || 0))
    if(value > remainingNewRafts){
      value = remainingNewRafts;
    }
    newCopyBatch[farm].pond[type][ind].noOfRaft = value;
    this.setState({ copyBatch: newCopyBatch });
  }

  deletePond = (ind, type) => {
    console.log("testtttttInputttttt:deletePond",ind, type)
  }

  addRowInPond = () => {
    console.log("testtttttInputttttt:addRowInPond")
  }

  checkAvailabilityPond = async ({ batchData, pondData, mode }) => {
    let SpaceAvailable = true;
    let OverfilledDate = '';
    let OverfilledPond = '';
    const { initiationDate, growthCycle: { germ, prop, hd, growMedia }, harvestDate, finishingLocation, propLocation1 } = batchData;
    const germDays = germ.days;
    const propDays = prop.days;
    const hdDays = (hd && hd.days) || 0;

    const isRockWool = growMedia === 'Rockwool'

    const farmData = isRockWool ? finishingLocation : propLocation1
    const pondType = isRockWool ? 'finishingLocation' : 'propLocation1'

    let pondContent;
    this.state.allFarms.forEach(farm => {
      if (farm.id === farmData.farm) {
        farm.ponds.forEach(pond => {
          if (pond.id === pondData.pond) {
            pondContent = pond;
            OverfilledPond = pond.name;
          }
        })
      }
    })

    if (!pondContent) return;
    const pondWidth = parseFloat(pondContent['widthF'] || 0);
    const pondLength = parseFloat(pondContent['lengthF'] || 0);
    const totalPondSquares = (pondWidth / 2) * (pondLength / 4);
    let fromDate, toDate;
    if (mode === STAGES.PROP) {
      fromDate = moment(initiationDate).add(germDays, 'days');
      toDate = moment(initiationDate).add(germDays + propDays, 'days');
    }
    else if (mode === STAGES.HD) {
      fromDate = moment(initiationDate).add(germDays + propDays, 'days');
      toDate = moment(initiationDate).add(germDays + propDays + hdDays, 'days');
    }
    else {
      fromDate = moment(initiationDate).add(germDays + propDays + hdDays, 'days');
      toDate = moment(harvestDate);
    }
    let newFromDate = new Date(fromDate);
    newFromDate.setHours(0);
    newFromDate.setMinutes(0);
    newFromDate = moment(newFromDate);

    let newToDate = new Date(toDate);
    newToDate.setHours(0);
    newToDate.setMinutes(0);
    newToDate = moment(newToDate);


    //day wise loop to check vacant space
    while (moment(newFromDate).isBefore(newToDate)) {
      let totalOccupy = 0;
      let currentData = deepCopyObject(this.props.plots[newFromDate.format()]);
      currentData.length && currentData.forEach(each => {
        let stage = each.stage;

        if ((stage === STAGES.PROP) && isRockWool) {
          totalOccupy += fix2((each.data.growthCycle.germ.cells / 200) / 4 || 0) || 0
        }
        else if (farmData.farm === each.graphic[pondType].farm) {
          each.graphic[pondType].pond[stage].forEach(each1 => {
            if (each1.pond === pondData.pond) {
              totalOccupy += isRockWool ? fix2(each1.noOfRaft) : each1.noOfRaft ? fix2((Number(each1.noOfRaft) / 4) || 0) : 0
            }
          })
        }
      })
      if (totalPondSquares < totalOccupy) {
        SpaceAvailable = false;
        if (!OverfilledDate) {
          OverfilledDate = newFromDate.format('DD MM YYYY');
        }
      }

      newFromDate.add(1, 'days');
    }

    return { SpaceAvailable, OverfilledDate, OverfilledPond }
  }

  validateFinishingPonds = () => {
    const farmLocation = this.state.copyBatch.growthCycle.growMedia === 'Rockwool' ? 'finishingLocation' :'propLocation1';
    const ponds = this.state.copyBatch[farmLocation].pond;
    const { errorMessages } = this.state;

    let errors = {
      hd: false,
      fs: false,
      prop : false
    }

    if(this.state.copyBatch.growthCycle.hd.days === 0){
      delete errors.hd;
      delete ponds.hd;

    }
    if(this.state.copyBatch.growthCycle.growMedia !== 'Rockwool'){
      delete errors.hd;
      delete errors.fs;
    }

    if (!ponds) return
    let valid = true;

    for (const pond in ponds) {

      // eslint-disable-next-line no-loop-func
      ponds[pond].forEach((each, ind) => {
        if (!each.pond && !each.noOfRaft) {
          if (ind === 0) {
            errors[pond] = true;
            errorMessages[pond] = 'Please enter details.';
            valid = false;
            return
          }
        }
        else if (!each.pond || !each.noOfRaft) {
          errors[pond] = true;
          errorMessages[pond] = 'Please enter valid details.';
          valid = false;
          return
        }
      })
    }
    this.setState({ errors, errorMessages })
    if (!valid) {
      console.log('invalid')
    }
    else {
      console.log('valid')
      this.handlePondSave();
      this.handlePondModalClose();
    }

  }

  getSeedOrStartDate = (selBatch) => {
    const {classes} = this.props;
    let res = null;
    if(selBatch?.initiationDate && selBatch?.seedDate && selBatch?.initiationDate == selBatch?.seedDate){
      res = <Typography variant="body2" className={classNames(classes.date)} style={{ marginTop: '0px' }}>Seed And Start Date: {moment(selBatch.initiationDate).format('DD MMM YYYY')}</Typography>
    }else{
      res = <>
        {(selBatch?.seedDate) && <Typography variant="body2" className={classNames(classes.date)} style={{ marginTop: '0px' }}>Seed Date: {moment(selBatch.seedDate).format('DD MMM YYYY')}</Typography>}
        {(selBatch?.initiationDate) && <Typography variant="body2" className={classNames(classes.date)} style={{ marginTop: '0px' }}>Start Date: {moment(selBatch.initiationDate).format('DD MMM YYYY')}</Typography>}
      </>
    }
    return res;
  }

  render() {
    const {
      classes,
      id,
      task,
      batch,
      isExpanded,
      cropName,
      varietyName,
      users,
      reasons,
      userIsAdmin,
      onClick,
      isSelected,
      className,
      selectAll,
      isSelectedAssign,
      handleTaskSeedLot,
      user,
      showOnly,
      forceHide,
      farm,
      finishingLocationDetails = {},
      currentLocationDetails = {},
      poolName = '',
      showCurrentLocation = true
    } = this.props;

    let checkIsDisabled = task.isComplete;
    console.log('task>>',task);
    console.log('batch>>',batch);
    return (
      task &&
      <div className={classNames(classes.fullContainer, className)}>
        <div className={classes.checkboxContainer}>
          <Checkbox checked={isSelectedAssign || isSelected} onChange={() => this.props.markTask(id)} />
        </div>
        <div className={classes.taskCard}>
          <Card
            className={classNames(classes.card, { [classes.link]: !!onClick, [classes.cardExpanded]: isExpanded })}
            style={{ height: 'auto' || this.state.containerHeight, overflow: this.state.overflow }}
          >

            <CardContent className={classes.cardContent}> {/* always larger width, but components within are determined-width */}

              <Grid container justify={'space-between'} alignItems="center" className={classes.topRow}> {/* standard items: main content, delay button (hidden unless card is expanded) */}

                <Grid item className={classes.mainContent} onClick={onClick ? () => onClick(id) : null}>
                  <Grid container justify="space-between" alignItems="stretch" spacing={16}>
                    <Grid item xs={2}> {/* icon and task type */}
                      <Grid container justify="center" alignItems="center" direction="column" style={{ height: '100%' }}>
                        <div className={classes.iconContainer}><i className={`fas ${icons[task.type]} fa-3x`}></i></div>
                        <div className={classes.iconContainer}>
                          <Typography variant={"body1"} color="secondary">
                            {taskNames[task.type]} {task.type === TASK_TYPES.TRANSFER && destinations[task.transferTo]}
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid item xs={6}> {/* middle column */}
                      <Grid container direction="column" justify="center" style={{ height: '100%' }}>
                        <Typography variant="h6" className={classNames(classes.title)}>{`${cropName} - ${varietyName}`}</Typography>
                        {/* {((taskNames['seed'] === taskNames[task.type]) || (taskNames['seedAndStart'] === taskNames[task.type])) && <Typography variant="body2" className={classNames(classes.title)}>Seed Date: {moment(task.date).format('DD MMM YYYY')}</Typography>} */}
                        {task.date ? <Typography variant="body2" className={classNames(classes.date)}>Planned Date: {moment(task.date).format('DD MMM YYYY')}</Typography> : null}
                        {this.getSeedOrStartDate(this.props.batch)}
                        {showCurrentLocation && currentLocationDetails.name ? <Typography variant="body2" className={classNames(classes.date)} style={{ marginTop: '0px' }}>Current Location: {currentLocationDetails.name}</Typography> : null}
                        {poolName ? <Typography variant="body2" className={classNames(classes.poolName)} style={{ marginTop: '0px' }}>

                          Next Pond Locations:
                          <Typography variant="body2" className={classNames(classes.poolName1)} style={{ cursor: 'pointer', marginTop: '0px' }} onClick={()=>this.setState({ pondModal: true })} >{poolName}</Typography></Typography> : null}

                        <Typography variant="body2" className={classNames(classes.date)} style={{ marginTop: '0px' }}>Finishing Location: {finishingLocationDetails.name}</Typography>


                        <Grid container justify="space-between" className={classes.bottomMargin}>
                          <Grid item xs={7}>
                            <Typography variant="body1" className={classes.grey}>Lot: {(task.updatedGRFlot && task.isLastTask) ? task.updatedGRFlot : task.GRFlot}</Typography>
                          </Grid>
                          <Grid item xs={5}>
                            <Typography variant="body1" className={classes.grey}>{batch.QtyToSeed || task.quant} {task.unit}</Typography>
                          </Grid>
                        </Grid>
                        {task.isComplete ?
                          <Typography variant="body1" className={classNames(classes.userDisabled, classes.grey)}>
                            Completed <span className={classes.green}>{moment(task.completedDate).format('MM/DD/YYYY')}</span> by <span className={classes.green}>{`${users[task.completedBy].firstName} ${users[task.completedBy].lastName}`}</span>
                          </Typography>
                          :
                          <Tooltip title="Click to assign user" placement="top-start">
                            <Typography
                              variant="body1"
                              color="primary"
                              className={classNames(classes.user, { [classes.userSelect]: !task.user })}
                              onClick={!task.isComplete ? this.openMenu : this.handleClick}
                            >
                              {task.user ? `${users[task.user] ? users[task.user].firstName : ''} ${users[task.user] ? users[task.user].lastName : ''}` : userIsAdmin ? 'Select User' : ''}{userIsAdmin ? <i className="fas fa-caret-right"></i> : ''}
                            </Typography>
                          </Tooltip>
                        }
                        <Menu
                          id="userSelectMenu"
                          open={!!this.state.anchorEl}
                          anchorEl={this.state.anchorEl}
                          onClose={() => this.closeMenu()}
                          onClick={this.handleClick}
                        >
                          {users && Object.keys(users).map(userID => {
                            const user = users[userID];
                            return (
                              <MenuItem key={userID} onClick={() => this.closeMenu(userID)}>{`${user.firstName} ${user.lastName}`}</MenuItem>
                            );
                          })}
                        </Menu>
                      </Grid>

                    </Grid>
                    <Grid container item xs={3} className={classes.inputColumn} alignItems="center"> {/* seedLot entry */}
                      {!showOnly && (task.type === TASK_TYPES.SEED || task.type === TASK_TYPES.SEED_AND_START) &&
                        <div>
                          <TextField
                            disabled={task.isComplete}
                            id="seedLot"
                            label="Seed Lot"
                            value={this.state.seedLot}
                            margin="dense"
                            variant="outlined"
                            onChange={this.handleChange}
                          />
                        </div>
                      }
                    </Grid>


                  </Grid>
                </Grid> {/* end mainContent */}

                <Grid item className={classes.delayContainer}>
                  <form id="delay-form" onSubmit={this.handleDelayClick}>
                    <Grid xs={12} direction={'row'} container alignItems="center" >
                      {!showOnly && <Grid item xs={2} >
                        <TextField
                          disabled={!task.isNext}
                          id="delayDays"
                          type="number"
                          label="Delay (days)"
                          margin="dense"
                          variant="outlined"
                          onChange={this.handleChange}
                          onClick={this.handleClick}
                          className={classes.delayInput}
                          inputProps={{
                            min: 0
                          }}
                        />
                      </Grid>}
                      {!showOnly && <Grid item xs={2}>
                        <Button
                          disabled={!task.isNext}
                          type="submit"
                          color="secondary"
                          variant="outlined"
                          form="delay-form"
                          className={classes.delayButton}
                          onClick={this.handleDelayClick}
                        >
                          {this.state.isWorking ?
                            <CircularProgress className={classes.submitButtonLoader} size={21} />
                            :
                            'Delay'
                          }
                        </Button>
                      </Grid>}
                      {!showOnly ? task.priorTask ? !this.props.showCompleted ? <Grid item xs={2}>
                        <Button onClick={this.handleRevertClick} variant="outlined" color="primary" className={classes.revertButton}>Revert</Button>
                      </Grid> : <Grid item xs={2} /> : <Grid item xs={2} /> : <Grid item xs={2} />}
                      {!forceHide ? <Grid xs={2} container direction='row'>
                        {new Array(5).fill(1).map((each, j) => (
                          <Grid onClick={(e) => {
                            task.rating = j + 1;
                            this.setState({ refreshing: true }, () => this.props.handleChangeRating(task.rating))
                          }} container className={classes.rate} style={{ backgroundColor: task.rating !== j + 1 ? 'gray' : '#42b883' }}>
                            <Typography variant={'subtitle2'} className={classes.rateNumber} >{j + 1}</Typography>
                          </Grid>
                        ))}
                      </Grid>
                        : <Grid xs={2} container direction='row' />}
                      <Grid xs={4} direction={'row'} container alignItems="center">
                        <Grid xs={2} onClick={() => this.setState({ open: true, notes: task.notes })}>
                          {task.notes ? <Typography variant={'subtitle2'} style={{ marginLeft: '15px', marginRight: '10px' }}>{task.notes}</Typography>
                            : <Tooltip title="Add Notes" aria-label="Add Notes" style={{ marginLeft: '15px' }}>
                              <Fab color="secondary" aria-label="Edit" className={classes.fab} onClick={() => this.setState({ open: true })}>
                                <Icon>edit_icon</Icon>
                              </Fab>
                            </Tooltip>}
                        </Grid>
                        <Grid xs={2} style={{ marginLeft: '5px' }} onClick={() => this.setState({ locationModal: true, newHarvestLocation:finishingLocationDetails.id })}>
                            <Tooltip title="Edit Location" aria-label="Edit Location" style={{ marginLeft: '15px' }}>
                              <Fab color="secondary" aria-label="Edit" className={classes.fab} onClick={() => this.setState({ locationModal: true })}>
                                <Icon>edit_location</Icon>
                              </Fab>
                            </Tooltip>
                        </Grid>
                      </Grid>  
                      

                      <Modal
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                        open={this.state.open}
                        onClose={this.handleClose}
                      >
                        <div className={classes.paper}>
                          <Typography variant="h6" id="modal-title">
                            Notes
                          </Typography>
                          <Grid container style={{ marginTop: '15px' }}>
                            <TextField
                              id="outlined-textarea"
                              label="Add Notes"
                              value={this.state.notes}
                              placeholder="Notes"
                              multiline
                              fullWidth
                              autoFocus
                              onChange={(e) => this.setState({ notes: e.target.value })}
                            />
                          </Grid>
                          <Grid container direction='row' justify='space-between'>
                            <Grid />
                            <Button
                              onClick={() => {
                                this.props.saveNotes(this.state.notes)
                                this.setState({ open: false })
                              }}
                              variant="contained"
                              component="span"
                              className={classes.button}
                              color="primary"
                              style={{ marginTop: '15px' }}
                            >
                              Submit
                            </Button>
                          </Grid>

                        </div>
                      </Modal>

                      {/* Edit Location Model */}
                      <Modal
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                        open={this.state.locationModal}
                        onClose={this.handleLocationModalClose}
                      >
                        <div className={classes.paper}>
                          <Typography variant="h6" id="modal-title">
                            Location
                          </Typography>
                          <Grid container style={{ marginTop: '15px' }}>
                            <TextField
                              select
                              id="newHarvestLocation"
                              label="Location"
                              value={this.state.newHarvestLocation}
                              onChange={(e) => this.setState({ newHarvestLocation: e.target.value })}
                              margin="dense"
                              fullWidth
                            >
                              {farm && (Object.values(farm)||[]).map((resp)=><MenuItem value={resp.id}>{resp.name}</MenuItem>)}
                            </TextField>
                          </Grid>
                          <Grid container direction='row' justify='space-between'>
                            <Grid />
                            <Button
                              onClick={() => {
                                this.props.editLocation(this.state.newHarvestLocation)
                                this.setState({ locationModal: false })
                              }}
                              variant="contained"
                              component="span"
                              className={classes.button}
                              color="primary"
                              style={{ marginTop: '15px' }}
                            >
                              Submit
                            </Button>
                          </Grid>

                        </div>
                      </Modal>
                      {/* Edit Location Model END */}

                      {/* Edit Location Model */}
                      <Modal
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                        open={this.state.pondModal}
                        onClose={this.handlePondModalClose}
                      > 
                        {this.state.copyBatch !== null && (<EditFinishingPonds
                          active={1}
                          handleSubmit={this.validateFinishingPonds}
                          data={this.state.copyBatch}
                          errors={this.state.errors}
                          checkAvailabilityPond={this.checkAvailabilityPond}
                          errorMessages={this.state.errorMessages}
                          handleChangePond={this.handleChangePond}
                          handleChangeSize={this.handleChangeSize}
                          deletePond={this.deletePond}
                          growthCyclesType={this.state.copyBatch.growthCycle.growMedia}
                          addRowInPond={this.addRowInPond}
                          finishingFarm={finishingLocationDetails}
                          handleCancel={this.handlePondModalClose}
                        />)}
                        
                      </Modal>
                    </Grid>
                  </form>
                </Grid>
              </Grid> {/* end top row */}
              {/* {console.log('this.state.remainingRafts', this.state.remainingRafts, this.state.remainingTrays)} */}
              {isExpanded &&
                <div onClick={this.handleClick}>
                  <HarvestModule
                    harvest={this.state.harvest}
                    disposal={this.state.disposal}
                    reasons={reasons}
                    showTrays={!!this.props.batch.QtyToSeed}
                    harvestIsActive={task.isComplete ? false : batch.growthCycle.growMedia === 'Rockwool' ? !!batch.fsDate : !!batch.propDate}
                    isFinalized={batch.isFinalized || false}
                    showDates={false}
                    growthCycle={batch.growthCycle}
                    finishingLocation={batch.finishingLocation}
                    propLocation1={batch.propLocation1}
                    remainingRafts={this.state.remainingRafts || 0}
                    remainingTrays={this.state.remainingTrays || 0}
                    avgWeightPerHead={this.state.avgWeightPerHead}
                    avgWeightPerHeadGrams={this.state.avgWeightPerHeadGrams}
                    totalRaftsHarvested={this.state.totalRaftsHarvested}
                    totalWeightLogged={this.state.totalWeightLogged}
                    totalRaftsLogged={this.state.totalRaftsLogged}
                    finishingLocationDetails={finishingLocationDetails}
                    estTotalBatchWeight={this.state.estTotalBatchWeight}
                    addHarvestOrDisposal={this.addHarvestOrDisposal}
                    deleteHarvestOrDisposal={this.deleteHarvestOrDisposal}
                    updateHarvestOrDisposal={this.updateHarvestOrDisposal}
                  />
                  <Grid container className={classes.buttonContainer} spacing={16} justify="flex-end">
                    <Button
                      disabled={batch.isFinalized}
                      variant="contained"
                      color="primary"
                      type="submit"
                      form="current-batch-form"
                      className={classes.saveButton}
                      onClick={this.handleSave}
                    >
                      {this.state.isSaving ?
                        <CircularProgress className={classes.submitButtonLoader} size={21} />
                        :
                        'Save'
                      }
                    </Button>
                  </Grid>
                </div>
              }

            </CardContent>
          </Card>
        </div>
      </div>
    );
  }
};

TaskCard.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  task: PropTypes.object.isRequired,
  batch: PropTypes.object.isRequired,
  users: PropTypes.object.isRequired,
  reasons: PropTypes.array.isRequired,
  rafts: PropTypes.object.isRequired,
  cropName: PropTypes.string.isRequired,
  varietyName: PropTypes.string.isRequired,
  userIsAdmin: PropTypes.bool.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  handleTaskSelect: PropTypes.func.isRequired,
  handleUserSelect: PropTypes.func.isRequired,
  handleDelayClick: PropTypes.func.isRequired,
  changeStatusUser: PropTypes.func,
  onSave: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  forceHide: PropTypes.bool.isRequired,
  finishingLocationDetails: PropTypes.object.isRequired,
};

export default withStyles(styles)(TaskCard);
import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { withStyles, Tooltip } from '@material-ui/core';
import classNames from 'classnames';
import red from '@material-ui/core/colors/red';
import blue from '@material-ui/core/colors/blue';

const styles = theme => ({
    modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        minWidth: 580,
        maxWidth: 800,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
        '&:focus': {
            outline: 'none'
        },
        overflow: 'auto',
        maxHeight: 600,
    },
    container: {
        width: '100%',
    },
    input: {
        width: '100%'
    },
    farmName: {
        color: '#000',
        marginTop: 40,
        fontWeight: 'bold'
    },
    buttonContainer: {
        textAlign: 'right',
        marginTop: '1.5rem'
    },
    button: {
        margin: '0.5rem 0rem'
    },
    paper: {
        width: '100%',
        marginBottom: '2rem'
    },
    sectionTitle: {
        marginTop: '2rem'
    },
    link1: {
        marginTop: '2.25rem',
        color: blue[500],
        fontSize: 15,
        cursor: 'pointer',
    },
    link: {
        color: blue[500],
        fontSize: 15,
        marginTop: 20,
        cursor: 'pointer'
    },
    actionIcons: {
        marginRight: '1.5rem',
        cursor: 'pointer',
        '&:last-child': {
            marginRight: 0
        },
        fontSize: '1.5rem'
    },
    blue: {
        color: blue[500]
    },
    red: {
        color: red[500]
    },
    grey: {
        color: theme.palette.grey[300]
    },
    editingCell: {
        color: theme.palette.grey[300]
    },
    fab: {
    },
    tabContainer: {
        margin: '3rem 0 1rem'
    },
    tabs: {
        width: '100%',
        minWidth: 400,
        '& button': {
            width: '35%',
            maxWidth: 'none'
        }
    },
    tab: {
        fontSize: '1.1rem'
    },
    divider: {
        width: '100%',
        marginTop: '-1px'
    }
});

class PestListModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            currentOpen: -1,
            id: '',
            nameError: false,
            addCropType: false,
            openPrompt: false

        };
    }

    componentDidMount = () => {

    }

    handleChange = e => {
        this.setState({
            name: e.target.value,
        });
    };

    handleClose = (type) => {
        if (type) {
            this.props.deletePestType(this.state.currentOpen)
        }
        this.setState({ openPrompt: false })
        setTimeout(() => {
            this.setState({ currentOpen: -1, addCropType: false })
        }, 500);
    }

    onSave = (e) => {
        e.preventDefault();
        this.state.addCropType ? this.props.onSave(this.state.name) : this.props.onEdit(this.state.name, this.state.currentOpen);
        setTimeout(() => {
            this.setState({ currentOpen: -1, addCropType: false })
        }, 100);
    }

    render() {
        const { classes, pestList } = this.props;

        return (
            <div className={classes.modal} tabIndex={-1}>
                {(this.state.currentOpen >= 0) || (this.state.addCropType) ?
                    <form onSubmit={this.saveRaft}>
                        <Grid container className={classes.container} spacing={16}>
                            <Grid item xs={10}>
                                <TextField
                                    required
                                    id="name"
                                    label="Name"
                                    multiline
                                    error={this.state.nameError}
                                    value={this.state.name}
                                    onChange={this.handleChange}
                                    fullWidth
                                    className={classes.textField}
                                />
                            </Grid>
                        </Grid>

                        <Grid container className={classes.container} justify="flex-end">
                            <Button variant="outlined" type="submit" color="primary" className={classes.button} onClick={this.onSave}>
                                Save
                            </Button>
                        </Grid>
                    </form>
                    : null}
                <Grid container className={classes.container} spacing={16}>
                    <Grid container direction='row' justify='space-between' item xs={12}>
                        <Grid>
                            <Typography variant="h6" className={classes.sectionTitle}>Pest List</Typography>
                        </Grid>
                        {this.state.currentOpen < 0 ? <Grid>
                            <Typography variant="h6" onClick={() => this.setState({ addCropType: true, name: '' })} className={classes.link1}>Add Pest Type</Typography>
                        </Grid> : null}
                    </Grid>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <Table className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Edit/Delete</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {pestList.map((cropType, i) => {
                                        return (
                                            <TableRow key={i}>
                                                <TableCell component="th" scope="raft" className={classNames({ [classes.editingCell]: false })}>
                                                    {cropType}
                                                </TableCell>

                                                <TableCell>
                                                    <Tooltip placement="top" title="Edit Farm">
                                                        <i
                                                            className={`far fa-edit fa-2x ${classNames(classes.actionIcons, classes.blue, { [classes.grey]: false })}`}
                                                            onClick={() => {
                                                                this.setState({ currentOpen: i, name: cropType })
                                                            }}
                                                        >
                                                        </i>
                                                    </Tooltip>
                                                    <Tooltip placement="top" title="Delete Farm">
                                                        <i
                                                            className={`far fa-times-circle fa-2x ${classNames(classes.actionIcons, classes.red, { [classes.grey]: false })}`}
                                                            onClick={() => this.setState({ currentOpen: i, openPrompt: true })}
                                                        >
                                                        </i>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </Paper>

                        <Dialog
                            open={this.state.openPrompt}
                            onClose={() => this.handleClose(false)}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            {/* <DialogTitle id="alert-dialog-title">{"Alert"}</DialogTitle> */}
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Delete this Pest type? This cannot be undone
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => this.handleClose(false)} color="primary">
                                    No
                                </Button>
                                <Button onClick={() => this.handleClose(true)} color="primary" autoFocus>
                                    Yes
                                </Button>
                            </DialogActions>
                        </Dialog>

                    </Grid>
                </Grid>

                {/* <Grid container className={classes.container} justify="flex-end">
                    <Button variant="contained" color="secondary" className={classes.button} onClick={this.props.onDone}>Done</Button>
                </Grid> */}


            </div>
        )
    }
};

PestListModal.propTypes = {
    classes: PropTypes.object.isRequired,
    rafts: PropTypes.object.isRequired,
    addEditItem: PropTypes.func.isRequired,
    deleteItem: PropTypes.func.isRequired,
    onDone: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
};

export default withStyles(styles)(PestListModal);

import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core';
import UserContext from '../context_providers/UserContext';
import { initializeSecondaryFirebaseApp } from '../utils/appFunctions/global';
import DevContext from '../context_providers/DevContext';

const styles = theme => ({
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    minWidth: 250,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    '&:focus': {
      outline: 'none'
    }
  },
  buttonContainer: {
    textAlign: 'right',
    marginTop: '1.5rem'
  },
  button: {
    marginLeft: '1rem'
  },
  submitButtonLoader: {
    color: 'white'
  }
});

class PasswordModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      errors: {
        password: false
      },
      errorMessages: {
        password: ''
      },
      isWorking: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  promisedSetState = (newState) => {
    return new Promise((resolve) => {
      this.setState(newState, () => {
        resolve()
      });
    });
  }

  handleChange = name => e => {
    if (this.state.errors[name]) {
      this.setState({
        errors: { ...this.state.error, [name]: false },
        errorMessages: { ...this.state.errorMessages, [name]: false }
      });
    }
    this.setState({
      [name]: e.target.value
    });
  };

  async handleSubmit(e, devMode) {
    e.preventDefault();
    await this.promisedSetState({ isWorking: true });

    // check password; show error if login fails, call parent's submit on success
    const user = this.context.user;    

    const secondaryFirebaseApp = initializeSecondaryFirebaseApp(devMode);
    try {
      await secondaryFirebaseApp.auth().signInWithEmailAndPassword(user.email, this.state.password);
      this.props.handleSubmit();
    } catch (err) {
      this.setState({
        isWorking: false,
        errors: { ...this.state.errors, password: true },
        errorMessages: { ...this.state.errorMessages, password: 'Incorrect password' }
      });
    }
    return;
  }

  render() {
    const { classes, title, handleCancel } = this.props;
    return (
      <div className={classes.modal} tabIndex={-1}>
        <DevContext.Consumer>
          {devMode =>
            <form onSubmit={(e) => this.handleSubmit(e, devMode)}>
              <Typography variant="h5" id="modal-title">{title}</Typography>
              <Grid container className={classes.container} justify="center">
                <Grid item xs={6}>
                  <TextField
                    required
                    autoFocus
                    type="password"
                    id="password"
                    label="Password"
                    error={this.state.errors.password}
                    value={this.state.password}
                    helperText={this.state.errorMessages.password}
                    onChange={this.handleChange('password')}
                    margin="normal"
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container className={classes.buttonContainer} justify="flex-end">
                <Button variant="contained" onClick={handleCancel} className={classes.button}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={(e) => this.handleSubmit(e, devMode)}
                  disabled={this.state.isWorking}
                  className={classes.button}
                >
                  {this.state.isWorking ?
                    <CircularProgress className={classes.submitButtonLoader} size={21} />
                    :
                    'Okay'
                  }
                </Button>
              </Grid>
            </form>
          }
        </DevContext.Consumer>
      </div>
    );
  }
};

PasswordModal.contextType = UserContext;

PasswordModal.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired
};

export default withStyles(styles)(PasswordModal);
import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

import { Button, MenuItem, TextField, Typography, withStyles } from '@material-ui/core';
import red from '@material-ui/core/colors/red';
import blue from '@material-ui/core/colors/blue';
import Divider from '@material-ui/core/Divider';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import classNames from 'classnames';

import FinishingPondCard from './FinishingPondCard'

const styles = theme => ({
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    minWidth: 580,
    maxWidth: 800,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    '&:focus': {
      outline: 'none'
    },
    overflow: 'auto',
    maxHeight: 600,
    minHeight: 500,
  },
  container: {
    width: '100%',
  },
  button: {
    margin: '0.5rem 0rem'
  },
  paper: {
    width: '100%',
    marginBottom: '2rem'
  },
  actionIcons: {
    marginRight: '0.5rem',
    cursor: 'pointer',
    '&:last-child': {
      marginRight: 0
    },
    fontSize: '1.5rem',
    marginTop: '1.25rem'
  },
  blue: {
    color: blue[500]
  },
  red: {
    color: red[500]
  },
  tabContainer: {
    margin: '0 0 1rem'
  },
  tabs: {
    width: '90%',
    minWidth: 400,
    '& button': {
      width: '35%',
      maxWidth: 'none'
    },
    alignSelf: 'center',
    marginLeft: '5%'
  },
  tab: {
    fontSize: '1.1rem'
  },
  divider: {
    width: '60%',
    marginTop: '-1px',
    marginLeft: '20%'
  }
});

class HarvestUnit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 1
    };
  }

  handleTabChange = (e, tabValue) => {
    this.setState({ tabValue });
  };

  // UNSAFE_componentWillReceiveProps = (newProps) =>{
  //   const { errors } = newProps;

  //   if(errors.hd){
  //     this.setState({tabValue:0})
  //   }
  //   else if(errors.fs){
  //     this.setState({tabValue:1})
  //   }

  // }




  render() {
    const { classes,finishingFarm, data, handleChangePond, addRowInPond, deletePond,  handleSubmit, errors, errorMessages } = this.props;
    const { tabValue } = this.state;
    const pondType = 'prop';
    const farmLocation = 'finishingLocation';

    return (
      <div>
        <div className={classes.modal} tabIndex={-1}>

          <Grid container className={classes.tabContainer} justify="flex-start">
          <Tabs
                value={this.state.tabValue}
                onChange={this.handleTabChange}
                indicatorColor="secondary"
                textColor="secondary"
                centered
                className={classes.tabs}
              >
                <Tab label="Propogation Pond" className={classes.tab} />
              </Tabs>

            <Divider className={classes.divider} />
          </Grid>

          <div>
            <Typography variant="h6">Total </Typography>
            <Typography variant="body1">Total Available</Typography>
          </div>

          <div style={{ flex: 1 }}>
          {data[farmLocation].pond[pondType].map((each, ind) => (
              <Grid container xs={12} direction={'row'}>
                <Grid item xs={1} style={{ marginTop: 25 }}>
                  <Typography variant="h5">{ind + 1}. </Typography>
                </Grid>
                <Grid item xs={4} md={3}>
                  <TextField
                    required
                    select
                    id={`selct-pond`}
                    label="Select Pond"
                    name={'pond'}
                    value={each.pond}
                    margin="dense"
                    onChange={handleChangePond(ind, pondType ,finishingFarm.ponds)}
                    fullWidth
                    //  let finalPond = finishingFarm.pondsfinalPond

                  >
                    {finishingFarm && finishingFarm.ponds.map(each => (
                      <MenuItem key={each.id} value={each.id}>{each.name}</MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={4} md={3} style={{ marginLeft: 20 }}>
                  <TextField
                    type="number"
                    margin="dense"
                    label={'No of Trays'}
                    name='noOfRaft'
                    onChange={handleChangePond(ind, pondType)}
                    value={each.noOfRaft}
                  />
                </Grid>

                {ind ? <Grid>
                  <i
                    className={`far fa-times-circle fa-2x ${classNames(classes.actionIcons, classes.red, { [classes.grey]: false })}`}
                    onClick={() => deletePond(ind, pondType)}>
                  </i>
                </Grid> : null}

                {/* <FinishingPondCard {...{ each, checkAvailabilityPond, pondType, data }} /> */}


              </Grid>
              ))}
            {parseFloat(parseFloat(0)).toFixed(2) > 0 && <Button onClick={() => addRowInPond()} variant={'contained'} style={{ marginTop: 25 }}>Add Pond</Button>}
          </div>
          {errors[pondType] && <Typography style={{ color: 'red', marginTop: 15 }}>{errorMessages[pondType]}</Typography>}

          <div style={{ width: '100%' }}>
            <Button color="primary" variant={'contained'} onClick={handleSubmit} style={{ marginTop: 25, }}>Submit</Button>
          </div>
        </div>
      </div>
    )
  }
};

HarvestUnit.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default withStyles(styles)(HarvestUnit);

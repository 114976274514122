import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import { DatePicker } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import MenuItem from '@material-ui/core/MenuItem';
import red from '@material-ui/core/colors/red';
import blue from '@material-ui/core/colors/blue';
import ComboBox from './ComboBox';
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';
import moment from 'moment';
import { roundToHalf, capitalize } from '../utils/utils';
import APP_SETTINGS from '../utils/appSettings';
import HarvestUnit from './HarvestUnit';
import { getFarms } from '../store/actions/adders';

const { UNITS } = APP_SETTINGS;

const styles = theme => ({
  harvestContainer: {
    position: 'relative',
    border: '1px solid',
    borderColor: theme.palette.grey[300],
    borderRadius: '5px',
    margin: '0.6rem 0 1.1rem 0.1rem',
    width: '100%',
    padding: '0.7rem 1rem',
    '& .containerLabel': {
      position: 'absolute',
      top: -10,
      left: '1.4rem',
      background: 'white',
      fontSize: '0.8rem',
      color: theme.palette.grey[600],
      padding: '0 0.2rem'
    }
  },
  container: {
    cursor: 'default'
  },
  rowButton: {
    marginTop: '10px'
  },
  updateButton: {
    width: 50, marginTop: "-10px", marginRight: "3px"
  },
  countContainer: {
    // [theme.breakpoints.up('md')]: {
    //   maxWidth: '10%',
    //   flexBasis: '10%'
    // }
  },
  unit: {
    position: 'absolute',
    bottom: 19,
    right: 20,
    fontSize: '1rem',
    color: blue[500],
    textDecoration: 'underline',
    cursor: 'pointer',
    transition: 'color 200ms',
    '&:hover': {
      color: blue[700]
    }
  },
  table: {
    border: '1px solid',
    borderColor: theme.palette.grey[300],
    borderRadius: '5px',
    marginBottom: '1.2rem',
    marginTop: '0.6rem',
    cursor: 'default'
  },
  tableHead: {
    backgroundColor: theme.palette.grey[200]
  },
  tableHeadCell: {
    fontSize: '0.9rem',
    color: 'rgba(0,0,0,0.7)',
    paddingRight: '30px'
  },
  tableRowCell: {
    paddingRight: '30px'
  },
  info: {
    color: theme.palette.grey[700],
    marginLeft: '0.5rem'
  },
  actionIcons: {
    cursor: 'pointer',
    color: theme.palette.grey[300],
    '&:last-child': {
      marginRight: 0
    },
    fontSize: '1.5rem'
  },
  inactiveActionIcon: {
    cursor: 'default',
  },
  red: {
    color: red[500]
  },
  blue: {
    color: blue[500]
  },
  unitSpan: {
    color: blue[500],
    textDecoration: 'underline',
    cursor: 'pointer',
    transition: 'color 200ms',
    '&:hover': {
      color: blue[700]
    }
  },
  remRaftsContainer: {
    marginBottom: '1rem',
    marginTop: '1rem',
    paddingLeft: '0.5rem'
  }
});

class HarvestModule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      currentModal: '',
      allFarms: [],
      errors: {
        hd: false,
        fs: false
      },
      errorMessages: {
        hd: 'Please enter valid details.',
        fs: 'Please enter valid details.',
      },
      newHarvestCount: '',
      newHarvestUnits: props.showTrays ? UNITS.TRAYS : UNITS.RAFTS,
      newHarvestWeight: '',
      newHarvestLocation: '',
      newHarvestDate: null,
      newDisposalCount: '',
      newDisposalUnits: props.showTrays ? UNITS.TRAYS : UNITS.RAFTS,
      newDisposalReason: '',
      newDisposalDetails: '',
      newDisposalDate: null,
      currentUnits: 'lbs',
      updateHarvestOrDisposalObj: null,
      isDisabled: {
        newHarvestDate: true,
        newHarvestCount: true,
        newHarvestUnits: true,
        newHarvestLocation: true,
        newHarvestWeight: true,
        newHarvestButton: true,
        newDisposalDate: true,
        newDisposalCount: true,
        newDisposalUnits: true,
        newDisposalReason: true,
        newDisposalDetails: true,
        newDisposeButton: true
      },
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleComboBoxChange = this.handleComboBoxChange.bind(this);
    this.addHarvestOrDisposal = this.addHarvestOrDisposal.bind(this);
    this.deleteHarvestOrDisposal = this.deleteHarvestOrDisposal.bind(this);
    this.updateHarvestOrDisposal = this.updateHarvestOrDisposal.bind(this);
    this.cycleWeightUnits = this.cycleWeightUnits.bind(this);
    this.handleChangePond = this.handleChangePond.bind(this);
    this.deletePond = this.deletePond.bind(this);
    this.addRowInPond = this.addRowInPond.bind(this);
  }

  promisedSetState = (newState) => {
    return new Promise((resolve) => {
      this.setState(newState, () => {
        resolve()
      });
    });
  }

  componentDidMount() {
    this.enableFields();
    this.getAllFarms();
  }

  async getAllFarms() {
    Promise.all([getFarms()]).then(resp => {
      this.setState({ allFarms: resp[0], isLoaded: true })
    })
  }

  componentDidUpdate(prevProps) {
    if ((this.props.harvestIsActive !== prevProps.harvestIsActive) || (this.props.isFinalized !== prevProps.isFinalized)) {
      this.enableFields();
    }
  }

  enableFields() {
    console.log('this.props.harvestIsActive',this.props.harvestIsActive)
    let stateObj = {
      isDisabled: {
        newHarvestDate: true,
        newHarvestCount: true,
        newHarvestUnits: true,
        newHarvestLocation: true,
        newHarvestWeight: true,
        newHarvestButton: true,
        newDisposalDate: false,
        newDisposalCount: true,
        newDisposalUnits: true,
        newDisposalReason: true,
        newDisposalDetails: true,
        newDisposeButton: true
      }
    };
    if (this.props.isFinalized) {
      this.setState(stateObj);
      return;
    }

    if (this.props.harvestIsActive) {
      stateObj.isDisabled = { ...stateObj.isDisabled, newHarvestDate: false };
      if (this.state.newHarvestDate || !this.props.showDates) {
        stateObj.isDisabled = { ...stateObj.isDisabled, newHarvestCount: false, newHarvestUnits: false,newHarvestLocation: false, newHarvestWeight: false };
        if (this.state.newHarvestCount && this.state.newHarvestLocation) {
          stateObj.isDisabled = { ...stateObj.isDisabled, newHarvestButton: false };
        }
      }
    }
    if (this.state.newDisposalDate || !this.props.showDates) {
      stateObj.isDisabled = { ...stateObj.isDisabled, newDisposalCount: false, newDisposalUnits: false, newDisposalReason: false, newDisposalDetails: false };
      if (this.state.newDisposalCount && this.state.newDisposalReason) {
        stateObj.isDisabled = { ...stateObj.isDisabled, newDisposeButton: false };
      }
    }

    console.log('isFinalized>', stateObj)


    this.setState(stateObj);
  }

  handleChange = (name, type) => e => {
    let value;
    switch (type) {
      case 'count':
        if (
          (name === 'newHarvestCount' && this.state.newHarvestUnits === UNITS.RAFTS) ||
          (name === 'newDisposalCount' && this.state.newDisposalUnits === UNITS.RAFTS)
        ) {
          value = roundToHalf(e.target.value);
        } else {
          value = parseInt(e.target.value);
        }
        break;

      case 'number':
        value = (e.target.value === '' || e.target.value === null) ? '' : Number(e.target.value);
        break;

      case 'date':
        value = !e.target.value ? e.target.value : moment(e.target.value).format('YYYY-MM-DD');
        break;

      default:
        value = e.target.value;
        break;
    }
    this.setState({ [name]: value }, this.enableFields);
  }
  handleChangePond = (ind, type, value) => {
    console.log("testtttttInputttttt:handleChangePond",ind, type, value)
  }
  deletePond = (ind, type) => {
    console.log("testtttttInputttttt:deletePond",ind, type)
  }
  addRowInPond = () => {
    console.log("testtttttInputttttt:addRowInPond")
  }

  handleComboBoxChange = (changes, name) => {
    if (changes.hasOwnProperty('selectedItem')) {
      this.setState({ [name]: changes.selectedItem }, this.enableFields);
    } else if (changes.hasOwnProperty('inputValue')) {
      this.setState({ [name]: changes.inputValue }, this.enableFields);
    }
  }

  async addHarvestOrDisposal(type, e) {
    e.preventDefault();
    // if (this.state.newHarvestCount > this.props.remainingRafts) return
    const item = type === 'harvest' ?
      {
        datetime: this.state.newHarvestDate || moment().format(),
        count: this.state.newHarvestCount,
        units: this.state.newHarvestUnits,
        pond: this.state.newHarvestLocation,
        weight: this.state.newHarvestWeight || ''
      }
      :
      {
        datetime: this.state.newDisposalDate || moment().format(),
        count: this.state.newDisposalCount,
        units: this.state.newDisposalUnits,
        reason: this.state.newDisposalReason,
        details: this.state.newDisposalDetails
      };

    this.props.addHarvestOrDisposal({ type, item });

    await this.promisedSetState({
      newHarvestDate: null,
      newHarvestCount: '',
      newHarvestUnits: UNITS.RAFTS,
      newHarvestLocation: '',
      newHarvestWeight: '',
      newDisposalDate: null,
      newDisposalCount: '',
      newDisposalUnits: UNITS.RAFTS,
      newDisposalReason: '',
      newDisposalDetails: ''
    });
    this.enableFields();
  }

  deleteHarvestOrDisposal = (id) => {
    let idArr = id.split('-');
    this.props.deleteHarvestOrDisposal({ type: idArr[0], ind: idArr[1] });
  }

  editHarvestOrDisposal = (id) => {
    let idArr = id.split('-');
    const checkHarvestOrdisposalObj = (idArr[0] == "harvest")?this.props.harvest[idArr[1]]:this.props.disposal[idArr[1]];
    const newHarvestOrdisposalObj = {
      type: idArr[0],
      index: idArr[1],
      weight: checkHarvestOrdisposalObj.weight
    }
    this.setState({updateHarvestOrDisposalObj: newHarvestOrdisposalObj})
    // this.props.deleteHarvestOrDisposal({ type: idArr[0], ind: idArr[1] });
  }

  updateHarvestOrDisposal = () => {
    const updatedVal = this.state.updateHarvestOrDisposalObj;
    this.props.updateHarvestOrDisposal(updatedVal);
    this.setState({updateHarvestOrDisposalObj:null})
  }

  cycleWeightUnits() {
    const unitList = ['lbs', 'grams', 'oz'];
    const currentInd = unitList.indexOf(this.state.currentUnits);
    const currentUnits = unitList[currentInd + 1] || unitList[0];
    this.setState({ currentUnits });
  }

  formatDate = (e) => {
    let name = e.target.name
    let oldDate = e.target.value;

    if (!moment(oldDate).isValid()) {
      return this.setState({ [name]: moment().format('YYYY-MM-DD') })
    }
    let date = oldDate.split('/')
    if (date.length === 2) {
      if (date[0].length < 2) {
        oldDate = '0' + date[0]
      }
    }
    else if (date.length === 3) {
      var test0 = date[0] + '/';
      var test1 = date[1] + '/';
      if (date[0].length < 2) {
        test0 = '0' + test0
      }
      if (date[1].length < 2) {
        test1 = '0' + test1
      }
      oldDate = test0 + test1 + date[2]
    }
    this.setState({ [name]: moment(oldDate).format('YYYY-MM-DD') })
  }

  getPondName(pond){
    let res = '';
    if(pond){
      res = pond.name || "";
    }
    return res;
  }

  getPondsOptions(){
    let res = null;
    const pondIds = [];
    // (this.state.allFarms.[]||[]).map((resp)=><MenuItem value={resp.id}>{resp.name}</MenuItem>)}
    
    /* NOT IN USE */
    
    // if(this.props.growthCycle){
    //   if(this.props.growthCycle.growMedia === 'Rockwool' && this.props.finishingLocation){
    //     if(this.props.finishingLocation.pond && this.props.finishingLocation.pond.fs && this.props.finishingLocation.pond.fs.length > 0){
    //       this.props.finishingLocation.pond.fs.map(fspond=>{
    //         if(fspond.pond !== ""){
    //           pondIds.push(fspond.pond);
    //         }
    //       })
    //     }
    //     if(this.props.finishingLocation.pond && this.props.finishingLocation.pond.hd && this.props.finishingLocation.pond.hd.length > 0){
    //       this.props.finishingLocation.pond.hd.map(hdpond=>{
    //         if(hdpond.pond !== ""){
    //           pondIds.push(hdpond.pond);
    //         }
    //       })
    //     }
    //   }else if(this.props.propLocation1 && this.props.propLocation1.pond && this.props.propLocation1.pond.prop && this.props.propLocation1.pond.prop.length > 0){
    //     this.props.propLocation1.pond.prop.map(propL=>{
    //       if(propL.pond !== ""){
    //         pondIds.push(propL.pond);
    //       }
    //     })
    //   }
    // }
    if(res == null && this.props.finishingLocationDetails && this.props.finishingLocationDetails.ponds){
      res = this.props.finishingLocationDetails.ponds.map((resp)=><MenuItem value={resp}>{resp.name}</MenuItem>)
    }
    return res;
  }

  render() {
    const { classes, isFinalized, reasons, remainingRafts, remainingTrays, totalWeightLogged, totalRaftsLogged, avgWeightPerHead, avgWeightPerHeadGrams, totalRaftsHarvested, estTotalBatchWeight, onClick, showDates, showTrays } = this.props;
    let weightPerHead;
    let oz;
    switch (this.state.currentUnits) {
      case 'lbs':
        weightPerHead = avgWeightPerHead;
        break;

      case 'grams':
        weightPerHead = avgWeightPerHeadGrams;
        break;

      case 'oz':
        weightPerHead = Math.floor(avgWeightPerHead);
        oz = Math.floor((avgWeightPerHead - Math.floor(avgWeightPerHead)) * 16 * 10) / 10;
        break;

      default:
        break;
    }

    return (
      <div onClick={onClick}>
        <Grid container className={classes.remRaftsContainer} spacing={16}>
          {totalRaftsHarvested ?
            <Typography variant="body2">
              Total Rafts Harvested:
              <span className={classes.info}>{totalRaftsHarvested}</span>
            </Typography>
            :
            <Typography variant="body2">
              Remaining {showTrays ? 'Trays' : 'Rafts'}:
              <span className={classes.info}>{(remainingTrays || remainingRafts) < 0 ? 'loading...' :(remainingTrays || remainingRafts)}</span>
            </Typography>
          }
        </Grid>
          <Grid container className={classes.harvestContainer} spacing={16} alignItems="center">
            <Typography variant="body1" className="containerLabel">Input new harvests / disposals</Typography>
            <form id="harvest-add-form" onSubmit={(e) => this.addHarvestOrDisposal('harvest', e)} style={{ width: '100%' }}>
              <Grid item xs={12}>
                <Grid container spacing={16}>
                  {showDates &&
                    <Grid item xs={6} md={3}>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DatePicker
                          disabled={this.state.isDisabled.newHarvestDate}
                          label="Harvest Date"
                          name={"newHarvestDate"}
                          value={this.state.newHarvestDate}
                          format="MM/DD/YYYY"
                          // mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                          onChange={(date) => this.handleChange('newHarvestDate', 'date')({ target: { value: date } })}
                          onBlur={(e) => this.formatDate(e)}
                          animateYearScrolling
                          autoOk
                          clearable
                          keyboard
                          margin="dense"
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                  }
                  <Grid item xs={6} md={2}>
                    <TextField
                      disabled={this.state.isDisabled.newHarvestCount}
                      id="newHarvestCount"
                      type="number"
                      label="#"
                      margin="dense"
                      value={this.state.newHarvestCount}
                      onChange={this.handleChange('newHarvestCount', 'count')}
                      fullWidth
                      inputProps={{
                        min: 0,
                        step: 0.5
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} md={2}>
                    <TextField
                      select
                      disabled={this.state.isDisabled.newHarvestUnits}
                      id="newHarvestUnits"
                      label="Units"
                      value={this.state.newHarvestUnits}
                      onChange={this.handleChange('newHarvestUnits')}
                      margin="dense"
                      fullWidth
                    >
                      {showTrays ? <MenuItem value={UNITS.TRAYS}>{capitalize(UNITS.TRAYS)}</MenuItem> : null}
                      <MenuItem value={UNITS.RAFTS}>{capitalize(UNITS.RAFTS)}</MenuItem>
                      <MenuItem value={UNITS.CELLS}>{capitalize(UNITS.CELLS)}</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={6} md={2}>
                    <TextField
                      disabled={this.state.isDisabled.newHarvestWeight}
                      id="newHarvestWeight"
                      type="number"
                      label="Weight (lbs)"
                      margin="dense"
                      value={this.state.newHarvestWeight}
                      onChange={this.handleChange('newHarvestWeight', 'number')}

                      inputProps={{
                        min: 0
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} md={2}>
                    <TextField
                      select
                      disabled={this.state.isDisabled.newHarvestLocation}
                      id="newHarvestLocation"
                      label="Location"
                      value={this.state.newHarvestLocation}
                      onChange={this.handleChange('newHarvestLocation')}
                      margin="dense"
                      fullWidth
                    >
                      {this.getPondsOptions()}
                    </TextField>
                  </Grid>
                  <Grid item xs={6} md={3} style={{ textAlign: 'center' }}>
                    <Button
                      variant="outlined"
                      type="submit"
                      disabled={this.state.isDisabled.newHarvestButton}
                      form="harvest-add-form"
                      color="primary"
                      onClick={(e) => this.addHarvestOrDisposal('harvest', e)}
                      className={classes.rowButton}
                    >
                      Harvest
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </form>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <form id="dispose-add-form" onSubmit={(e) => this.addHarvestOrDisposal('disposal', e)} style={{ width: '100%' }}>
              <Grid item xs={12}>
                <Grid container spacing={16}>
                  {showDates &&
                    <Grid item xs={6} md={2}>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DatePicker
                          disabled={this.state.isDisabled.newDisposalDate}
                          label="Disp. Date"
                          name="newDisposalDate"
                          value={this.state.newDisposalDate}
                          format="MM/DD/YYYY"
                          // mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                          onChange={(date) => this.handleChange('newDisposalDate', 'date')({ target: { value: date } })}
                          onBlur={(e) => this.formatDate(e)}
                          animateYearScrolling
                          autoOk
                          clearable
                          margin="dense"
                          keyboard
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                  }
                  <Grid item xs={6} md={showDates ? 1 : 2}>
                    <TextField
                      disabled={this.state.isDisabled.newDisposalCount}
                      id="newDisposalCount"
                      type="number"
                      label="#"
                      margin="dense"
                      value={this.state.newDisposalCount}
                      onChange={this.handleChange('newDisposalCount', 'count')}
                      fullWidth
                      inputProps={{
                        min: 0,
                        step: 0.5
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} md={2}>
                    <TextField
                      select
                      disabled={this.state.isDisabled.newDisposalUnits}
                      id="newDisposalUnits"
                      label="Units"
                      value={this.state.newDisposalUnits}
                      onChange={this.handleChange('newDisposalUnits')}
                      margin="dense"
                      fullWidth
                    >
                      {showTrays ? <MenuItem value={UNITS.TRAYS}>{capitalize(UNITS.TRAYS)}</MenuItem> : null}
                      <MenuItem value={UNITS.RAFTS}>{capitalize(UNITS.RAFTS)}</MenuItem>
                      <MenuItem value={UNITS.CELLS}>{capitalize(UNITS.CELLS)}</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <ComboBox
                      disabled={this.state.isDisabled.newDisposalReason}
                      id="newDisposalReason"
                      label="Reason"
                      margin="dense"
                      items={reasons}
                      onChange={this.handleComboBoxChange}
                      value={this.state.newDisposalReason}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6} md={showDates ? 2 : 3}>
                    <TextField
                      disabled={this.state.isDisabled.newDisposalDetails}
                      id="newDisposalDetails"
                      label="Details"
                      margin="dense"
                      value={this.state.newDisposalDetails}
                      onChange={this.handleChange('newDisposalDetails')}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={6} md={2} style={{ textAlign: 'center' }}>
                    <Button
                      variant="outlined"
                      type="submit"
                      disabled={this.state.isDisabled.newDisposeButton}
                      form="dispose-add-form"
                      color="primary"
                      onClick={(e) => this.addHarvestOrDisposal('disposal', e)}
                      className={classes.rowButton}
                    >
                      Dispose
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Grid>

        {((this.props.harvest && this.props.harvest.length > 0) || (this.props.disposal && this.props.disposal.length > 0)) &&
          <Table className={classes.table}>
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell className={classes.tableHeadCell}>Datetime</TableCell>
                <TableCell className={classes.tableHeadCell}># Rafts</TableCell>
                <TableCell className={classes.tableHeadCell}>Weight/Reason</TableCell>
                <TableCell className={classes.tableHeadCell}>Location</TableCell>
                <TableCell className={classes.tableHeadCell}>Action</TableCell>
                <TableCell className={classes.tableHeadCell}>Update/Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.harvest.map((item, i) => (
                <TableRow key={`harvest${i}`}>
                  {console.log("itemmmm",this.state.updateHarvestOrDisposalObj)}
                  <TableCell className={classes.tableRowCell}>{moment(item.datetime).format('MM/DD/YYYY, h:mm a')}</TableCell>
                  <TableCell className={classes.tableRowCell}>{item.rafts}</TableCell>
                  <TableCell className={classes.tableRowCell}>{(this.state.updateHarvestOrDisposalObj !== null && this.state.updateHarvestOrDisposalObj.type == "harvest" && this.state.updateHarvestOrDisposalObj.index == i)?(
                    <TextField
                      id="udpateHarvestCount"
                      type="number"
                      label="#"
                      margin="dense"
                      value={this.state.updateHarvestOrDisposalObj.weight}
                      onChange={(e)=>{
                        const newUpdateObj = {...this.state.updateHarvestOrDisposalObj};
                        newUpdateObj.weight = e.target.value;
                        this.setState({updateHarvestOrDisposalObj: newUpdateObj})
                      }}
                      // fullWidth
                      inputProps={{
                        min: 0,
                        step: 0.5
                      }}
                    />):item.weight} lbs</TableCell>
                  <TableCell className={classes.tableRowCell}>{this.getPondName(item.pond||'')}</TableCell>
                  <TableCell className={classes.tableRowCell}>Harvest</TableCell>
                  <TableCell className={classes.tableRowCell}>
                    {(this.state.updateHarvestOrDisposalObj !== null && this.state.updateHarvestOrDisposalObj.type == "harvest" && this.state.updateHarvestOrDisposalObj.index == i)?(
                      <Button
                        variant="outlined"
                        type="button"
                        form="dispose-add-form"
                        color="primary"
                        size="small"
                        onClick={() => this.updateHarvestOrDisposal()}
                        className={classes.updateButton}
                      >
                        Save
                      </Button>
                    ):(
                      <i
                        className={`far fa-edit fa-2x ${classNames(classes.actionIcons, classes.blue, { [classes.inactiveActionIcon]: isFinalized })}`}
                        onClick={isFinalized ? null : (() => this.editHarvestOrDisposal(`harvest-${i}`))}
                      >
                      </i>
                    )}
                    <i
                      className={`far fa-times-circle fa-2x ${classNames(classes.actionIcons, classes.red, { [classes.inactiveActionIcon]: isFinalized })}`}
                      onClick={isFinalized ? null : (() => this.deleteHarvestOrDisposal(`harvest-${i}`))}
                    >
                    </i>
                  </TableCell>
                </TableRow>
              ))}
              {this.props.disposal.map((item, i) => (
                <TableRow key={`disposal-${i}`}>
                  <TableCell className={classes.tableRowCell}>{moment(item.datetime).format('MM/DD/YYYY, h:mm a')}</TableCell>
                  <TableCell className={classes.tableRowCell} >{item.rafts}</TableCell>
                  <TableCell className={classes.tableRowCell}>
                    <Tooltip title={item.details}>
                      <span>{item.reason}</span>
                    </Tooltip>
                  </TableCell>
                  <TableCell className={classes.tableRowCell}>Dispose</TableCell>
                  <TableCell className={classes.tableRowCell}>
                    <i
                      className={`far fa-edit fa-2x ${classNames(classes.actionIcons, classes.blue, { [classes.inactiveActionIcon]: isFinalized })}`}
                      onClick={isFinalized ? null : (() => this.editHarvestOrDisposal(`disposal-${i}`))}
                    >
                    </i>
                    <i
                      className={`far fa-times-circle fa-2x ${classNames(classes.actionIcons, classes.red, { [classes.inactiveActionIcon]: isFinalized })}`}
                      onClick={isFinalized ? null : (() => this.deleteHarvestOrDisposal(`disposal-${i}`))}
                    >
                    </i>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        }

        <Grid container className={classes.container} spacing={16}>
          <Grid item xs={6} md={4}>
            <Typography variant="body2">Total rafts logged: <span className={classes.info}>{totalRaftsLogged || 'n/a'}</span></Typography>
          </Grid>
          <Grid item xs={6} md={4}>
            <Typography variant="body2">Total weight logged:
              <span className={classes.info}>
                {totalWeightLogged ? `${totalWeightLogged} lbs` : 'n/a'}
              </span>
            </Typography>
          </Grid>
          <Grid item xs={6} md={4}>
            <Typography variant="body2">Avg weight/head:
              <span className={classes.info}>
                {avgWeightPerHead ?
                  (oz ? `${weightPerHead} lb, ${oz}` : weightPerHead) :
                  'n/a'
                }&nbsp;

                {avgWeightPerHead &&
                  <span
                    className={classes.unitSpan}
                    onClick={this.cycleWeightUnits}
                  >
                    {this.state.currentUnits}
                  </span>
                }
              </span>
            </Typography>
          </Grid>
          <Grid item xs={6} md={4}>
            <Typography variant="body2">Est. Total Weight:
              <span className={classes.info}>
                {estTotalBatchWeight ? `${estTotalBatchWeight} lbs` : 'n/a'}
              </span>
            </Typography>
          </Grid>
        </Grid>
      </div>
    )

  }
}

HarvestModule.propTypes = {
  classes: PropTypes.object.isRequired,
  harvest: PropTypes.array.isRequired,
  disposal: PropTypes.array.isRequired,
  reasons: PropTypes.array.isRequired,
  harvestIsActive: PropTypes.bool.isRequired,
  isFinalized: PropTypes.bool.isRequired,
  showDates: PropTypes.bool.isRequired,
  remainingRafts: PropTypes.number,
  remainingTrays: PropTypes.number,
  avgWeightPerHead: PropTypes.number,
  avgWeightPerHeadGrams: PropTypes.number,
  totalRaftsHarvested: PropTypes.number,
  totalWeightLogged: PropTypes.number,
  totalRaftsLogged: PropTypes.number,
  estTotalBatchWeight: PropTypes.number,
  addHarvestOrDisposal: PropTypes.func.isRequired,
  deleteHarvestOrDisposal: PropTypes.func.isRequired
};

export default withStyles(styles)(HarvestModule);
import React from 'react';
import PropTypes from 'prop-types';
import AvatarInput from '../components/AvatarInput';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Title from '../components/Title';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import ToolTip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import MaskedInput from 'react-text-mask';
import { withStyles } from '@material-ui/core';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import { addEditUser } from '../store/actions/adders';
import { initializeSecondaryFirebaseApp } from '../utils/appFunctions/global';
import UserContext from '../context_providers/UserContext';
import APP_SETTINGS from '../utils/appSettings';
import DevContext from '../context_providers/DevContext';

const { USER_ROLES, USER_POSITIONS, COLLECTIONS } = APP_SETTINGS;


const styles = {
  formContainer: {
    padding: '2rem'
  },
  input: {
    width: '95%',
    marginBottom: 0
  },
  pwInput: {
    marginTop: 0,
    marginBottom: 0,
    width: '95%'
  },
  formDivider: {
    marginTop: '1.5rem'
  },
  submitButton: {
    marginTop: '2rem',
    width: '8rem'
  },
  submitButtonLoader: {
    color: 'white'
  }
};

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={inputRef}
      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      showMask
    />
  );
}

class UserEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      role: '',
      position: '',
      email: '',
      phone: '(  )    -    ',
      newPassword: '',
      confirmNewPassword: '',
      avatarURL: 'https://firebasestorage.googleapis.com/v0/b/greener-roots.appspot.com/o/avatars%2Fdefault-avatar.jpg?alt=media&token=cb7449fe-370b-4ab9-bf37-410b035b1ce2',
      newAvatar: null,
      newAvatarObject: null,
      firstNameError: false,
      lastNameError: false,
      roleError: false,
      positionError: false,
      emailError: false,
      newPasswordError: false,
      newPasswordHelperText: null,
      confirmNewPasswordError: false,
      confirmNewPasswordHelperText: null,
      changedFields: [],
      isSaving: false,
      snackbarOpen: false,
      dialogOpen: false,
      dialogMessage: '',
      dialogButtons: [],
      currentDialog: ''
    };
    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);
    this.saveAvatar = this.saveAvatar.bind(this);
    this.snackbarClose = this.snackbarClose.bind(this);
  }

  promisedSetState = newState => {
    return new Promise(resolve => {
      this.setState(newState, () => {
        resolve();
      });
    });
  }

  componentDidMount() {
    const { location, match, history, action } = this.props;
    const user = this.context.user;

    // redirect to user's page if they're not an admin
    if (user.role !== USER_ROLES.ADMIN && location.pathname === "/users/new") {
      history.push(`/users/edit/${user.id}`);
    }

    // populate fields with the user object in props if available
    if (action === 'New') {
      return;
    }
    if (match.params.userID === user.id) {
      const { firstName, lastName, role, position, email, phone, avatarURL } = user;
      this.setState({
        firstName,
        lastName,
        role,
        position,
        email,
        phone: phone ? phone : '(  )    -    ',
      });
      if (avatarURL) {
        this.setState({ avatarURL });
      }
    } else {
      // otherwise pull info from database
      const db = firebase.firestore();
      db.collection(COLLECTIONS.USERS).doc(match.params.userID).get()
        .then(doc => {
          const { firstName, lastName, role, position, email, phone, avatarURL } = doc.data();
          this.setState({
            firstName,
            lastName,
            role,
            position,
            email,
            phone: phone ? phone : '(  )    -    ',
          });
          if (avatarURL) {
            this.setState({ avatarURL });
          }
        });
    }
  }

  handleChange = name => e => {
    // push field to changedFields to keep track of what's edited
    let changedFields = this.state.changedFields.slice();
    if (!changedFields.includes(name)) {
      changedFields.push(name);
    }

    // set the new value
    this.setState({
      [name]: e.target.value,
      changedFields
    });

    // remove error on a field if it starts to be re-edited
    if (this.state[`${name}Error`]) {
      this.setState({ [`${name}Error`]: false });
    }
  };

  saveAvatar(canvasElement) {
    let changedFields = this.state.changedFields.slice();
    changedFields.push('newAvatar');
    this.setState({ newAvatarObject: canvasElement, changedFields });
    canvasElement.toBlob(blob => {
      const url = URL.createObjectURL(blob);
      this.setState({ newAvatar: url });
    });
  }

  closeDialog(answer) {
    switch (this.state.currentDialog) {
      case 'recent-login':
        if (answer === 'login') {
          firebase.auth().signOut();
        }
        this.setState({
          dialogOpen: false,
          dialogMessage: '',
          dialogButtons: [],
          currentDialog: ''
        });
        break;

      default:
        this.setState({
          dialogOpen: false,
          dialogMessage: '',
          dialogButtons: [],
          currentDialog: ''
        });
        break;
    }
  }

  snackbarClose() {
    this.setState({ snackbarOpen: false });
  }

  getCanvasBlob(canvas) {
    return new Promise(function (resolve, reject) {
      canvas.toBlob(function (blob) {
        resolve(blob)
      })
    })
  }

  formHasErrors() {
    // check all non-password fields

    const fieldNames = this.context.user.role === USER_ROLES.ADMIN ? ['firstName', 'lastName', 'role', 'position', 'email'] : ['firstName', 'lastName', 'role', 'email'];
    let errorsFound = false;
    fieldNames.forEach((name, i) => {
      if (this.state[name] === '') {
        const errorName = `${name}Error`;
        errorsFound = true;
        this.setState({ [errorName]: true });
      }
    });



    // if (this.context.user.role !== USER_ROLES.ADMIN) {
    // check password fields
    if (this.state.newPassword !== '' || this.state.confirmNewPassword !== '') {
      // check the new passwords match
      if (this.state.newPassword !== this.state.confirmNewPassword) {
        errorsFound = true;
        this.setState({ confirmNewPasswordError: true, confirmNewPasswordHelperText: 'Passwords must match' });
      }
      // check password length
      if (this.state.newPassword.length < 6) {
        errorsFound = true;
        this.setState({ newPasswordError: true, newPasswordHelperText: 'Password must be at least 6 characters' })
      }
    }
    else {
      // if (this.state.newPassword === '') {
      //   errorsFound = true;
      //   this.setState({ newPasswordError: true, newPasswordHelperText: 'Please enter password.' });
      // }
    }
    // }

    return errorsFound;
  }

  async submit(e, devMode) {
    e.preventDefault();


    if (this.formHasErrors()) {
      return;
    }


    await this.promisedSetState({ isSaving: true });


    let userID = this.props.action === 'New' ? null : this.props.match.params.userID;
    const secondaryFirebaseApp = initializeSecondaryFirebaseApp(devMode);
    let { firstName, lastName, role, position, email, phone, avatarURL } = this.state;

    // If new user, set up new account with Auth, get new userID
    if (this.props.action === 'New') {
      try {
        const userCred = await secondaryFirebaseApp.auth()
          .createUserWithEmailAndPassword(this.state.email, '123456');

        userID = userCred.user.uid;
      } catch (err) {
        console.log(err)
      }
    }

    // Send new image to storage if necessary
    if (this.state.newAvatar) {
      await this.getCanvasBlob(this.state.newAvatarObject)
        .then(blob => firebase.storage().ref().child(`avatars/${userID}-avatar.png`).put(blob))
        .then(snapshot => snapshot.ref.getDownloadURL())
        .then(url => {
          this.setState({ avatarURL: url });
          return avatarURL = url;
        });
    }

    // push edited fields to database
    if (this.state.changedFields.length > 0) {
      await addEditUser({
        user: {
          firstName,
          lastName,
          role,
          position,
          email,
          phone: phone === '(  )    -    ' ? null : phone,
          avatarURL,
        },
        id: userID
      });
    }

    // set new email and/or password with Firebase Auth if necessary
    // NOTE: only the person who's account it is can change the email or password
    if (
      this.props.action !== 'New' &&
      (this.state.changedFields.includes('email') || this.state.changedFields.includes('confirmNewPassword'))
    ) {
      const user = await firebase.auth().currentUser;

      if (this.state.changedFields.includes('email')) {
        try {
          await user.updateEmail(this.state.email);
        } catch (err) {
          console.log(err);
          if (err.code === 'auth/requires-recent-login') {
            this.setState({
              isSaving: false,
              dialogOpen: true,
              dialogMessage: 'This requires a recent login. Click "Log In" to log back in.',
              dialogButtons: [{ label: 'Cancel', color: 'default' }, { label: 'Log In', color: 'primary', answer: 'login' }],
              currentDialog: 'recent-login'
            });
            return;
          } else {
            alert(`Error saving new password: ${err}`);
            this.setState({ isSaving: false });
            return;
          }
        }
      }

      if (this.state.changedFields.includes('confirmNewPassword')) {
        try {
          await user.updatePassword(this.state.newPassword);
        } catch (err) {
          console.log(err);
          if (err.code === 'auth/requires-recent-login') {
            this.setState({
              isSaving: false,
              dialogOpen: true,
              dialogMessage: 'This requires a recent login. Click "Log In" to log back in.',
              dialogButtons: [{ label: 'Cancel', color: 'default' }, { label: 'Log In', color: 'primary', answer: 'login' }],
              currentDialog: 'recent-login'
            });
            return;
          } else {
            alert(`Error saving new password: ${err}`);
            this.setState({ isSaving: false });
            return;
          }
        }
      }
    }

    // clear the state
    this.setState({
      isSaving: false,
      snackbarOpen: true,
      newPassword: '',
      confirmNewPassword: '',
      newPasswordHelperText: '',
      confirmNewPasswordHelperText: '',
      newAvatar: null,
      newAvatarObject: null,
    });

    if (this.props.action === 'New') {
      this.props.history.push('/users');
    }
  }

  sendResetPasswordLink = () => {
    const auth = firebase.auth();

    auth.sendPasswordResetEmail(this.state.email)
    .then(function (user) {
      alert(`Reset password link sent successfully`)
    }).catch(function (e) {
      console.log(e)
    })
  }

  render() {
    const { classes, match } = this.props;
    const user = this.context.user;
    const action = this.props.action ? this.props.action : 'Edit';
    return (
      <div>
        <DevContext.Consumer>
          {devMode =>
            <React.Fragment>

              <Title title={`${action} User`} />

              <Typography variant="h5" color="secondary">{this.state.firstName} {this.state.lastName}</Typography>
              <Divider />

              <form onSubmit={(e) => this.submit(e, devMode)}>
                <Grid container spacing={32} className={classes.formContainer} style={{ paddingLeft: 0 }}>
                  <Grid item xs={12} md={3} style={{ paddingLeft: 0 }}>
                    <AvatarInput
                      avatarURL={this.state.newAvatar || this.state.avatarURL}
                      alt={`${this.state.firstName} ${this.state.lastName}`}
                      onSave={this.saveAvatar}
                      dimensionSize={170}
                      editBorder={10}
                    />
                  </Grid>

                  <Grid item xs={12} md={9}>
                    <Grid container spacing={16}>
                      <Grid item xs={12} md={5}>
                        <TextField
                          required
                          error={this.state.firstNameError}
                          id="firstName"
                          label="First Name"
                          value={this.state.firstName}
                          onChange={this.handleChange('firstName')}
                          margin="normal"
                          className={classes.input}
                        />
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <TextField
                          required
                          error={this.state.lastNameError}
                          id="lastName"
                          label="Last Name"
                          value={this.state.lastName}
                          onChange={this.handleChange('lastName')}
                          margin="normal"
                          className={classes.input}
                        />
                      </Grid>
                      <Grid item xs={6} md={4}>
                        <TextField
                          required
                          error={this.state.roleError}
                          select
                          disabled={user.role !== USER_ROLES.ADMIN}
                          id="role"
                          label="Role"
                          value={this.state.role}
                          onChange={this.handleChange('role')}
                          margin="normal"
                          className={classes.input}
                        >
                          {Object.keys(USER_ROLES).map((key, ind) => (
                            <MenuItem key={`userRole-${ind}`} value={USER_ROLES[key]}>
                              {USER_ROLES[key].replace(/\b\w/g, l => l.toUpperCase())}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={6} md={4}>
                        <TextField
                          required
                          error={this.state.positionError}
                          select
                          disabled={user.role !== USER_ROLES.ADMIN}
                          id="position"
                          label="Position"
                          value={this.state.position}
                          onChange={this.handleChange('position')}
                          margin="normal"
                          className={classes.input}
                        >
                          {Object.keys(USER_POSITIONS).map((key, ind) => (
                            <MenuItem key={`userPosition-${ind}`} value={USER_POSITIONS[key]}>
                              {USER_POSITIONS[key].replace(/\b\w/g, l => l.toUpperCase())}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <TextField
                          required
                          disabled={action !== 'New' && match.params.userID !== user.id}
                          error={this.state.emailError}
                          id="email"
                          label="Email"
                          type="email"
                          value={this.state.email}
                          onChange={this.handleChange('email')}
                          margin="normal"
                          className={classes.input}
                        />
                      </Grid>
                      <Grid item xs={6} md={4}>
                        <FormControl className={classes.input} margin="normal">
                          <InputLabel htmlFor="phone">Phone</InputLabel>
                          <Input
                            id="phone"
                            type="tel"
                            value={this.state.phone}
                            onChange={this.handleChange('phone')}
                            inputComponent={TextMaskCustom}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12}>
                        <Divider className={classes.formDivider} />
                      </Grid>
                      {match.params.userID === user.id ? <>
                        <Grid item xs={12}>
                          <Typography variant="h6">Change Password</Typography>
                        </Grid>

                        <Grid item xs={12} md={5}>
                          <ToolTip title="Password must be at least 6 characters">
                            <TextField
                              required
                              disabled={action !== 'New' && match.params.userID !== user.id}
                              error={this.state.newPasswordError}
                              id="newPassword"
                              type='password'
                              label="New Password"
                              value={this.state.newPassword}
                              onChange={this.handleChange('newPassword')}
                              margin="normal"
                              className={classes.pwInput}
                              helperText={this.state.newPasswordHelperText}
                            />
                          </ToolTip>

                        </Grid>
                        <Grid item xs={5}></Grid>
                        <Grid item xs={12} md={5}>
                          <TextField
                            required
                            disabled={action !== 'New' && match.params.userID !== user.id}
                            error={this.state.confirmNewPasswordError}
                            id="confirmNewPassword"
                            type='password'
                            label="Confirm New Password"
                            value={this.state.confirmNewPassword}
                            onChange={this.handleChange('confirmNewPassword')}
                            margin="normal"
                            className={classes.pwInput}
                            helperText={this.state.confirmNewPasswordHelperText}
                          />
                        </Grid>
                      </> :
                        <>
                          <Grid item xs={12} onClick={() => this.sendResetPasswordLink()}>
                            <Typography style={{ color: 'blue', cursor: 'pointer' }} variant="body2">Send reset password link</Typography>
                          </Grid>
                        </>
                      }
                    </Grid>
                  </Grid> {/* end right-side form container */}

                  <Grid item xs={12}>
                    <Grid container justify="flex-end">
                      <Button
                        variant="contained"
                        type="submit"
                        color="secondary"
                        className={classes.submitButton}
                        onClick={(e) => this.submit(e, devMode)}
                      >
                        {this.state.isSaving ?
                          <CircularProgress className={classes.submitButtonLoader} size={21} /> :
                          <span>Save</span>
                        }
                      </Button>
                    </Grid>
                  </Grid>

                </Grid> {/* end form container */}
              </form>
            </React.Fragment>
          }
        </DevContext.Consumer>

        <Dialog
          open={this.state.dialogOpen}
          onClose={this.closeDialog}
          aria-describedby="confirm-action-dialog"
        >
          <DialogContent>
            <DialogContentText id="confirm-action-dialog">
              {this.state.dialogMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {this.state.dialogButtons.map(button => (
              <Button key={button.label} onClick={() => this.closeDialog(button.answer)} color={button.color} autoFocus={button.autoFocus}>
                {button.showLoader ?
                  (button.showLoader ?
                    <CircularProgress className={classes.submitButtonLoader} size={21} />
                    :
                    button.label
                  )
                  :
                  button.label
                }
              </Button>
            ))}
          </DialogActions>
        </Dialog>

        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={this.state.snackbarOpen}
          autoHideDuration={3000}
          onClose={this.snackbarClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">Saved!</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.snackbarClose}
            >
              <i className="fas fa-times"></i>
            </IconButton>
          ]}
        />

      </div>
    );
  }
}

UserEdit.propTypes = {
  classes: PropTypes.object,
  action: PropTypes.string,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

UserEdit.contextType = UserContext;

export default withStyles(styles)(UserEdit);
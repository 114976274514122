import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { Switch, Route } from 'react-router-dom';
import Home from './Home';
import Users from './Users';
import Analytics from './Analytics';
import Grow from './Grow';
import Inventory from './Inventory';
import Planning from './Planning';
import Setup from './Setup';
import UserEdit from './UserEdit';
import Export from '../components/Export';
import classNames from 'classnames';
import Ratings from './Ratings';

const drawerWidth = 245;
const styles = (theme) => ({
  grid: {
    padding: '1.5rem',
    backgroundColor: theme.palette.background.paper,
    maxWidth: '100%',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  gridShift: {
    flexGrow: 1,
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  toggleButton: {
    position: 'absolute',
    top: '1.5rem',
    left: 0,
    minWidth: 0,
    width: '1rem',
    transition: theme.transitions.create(['left'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  toggleButtonShift: {
    left: drawerWidth,
    transition: theme.transitions.create(['left'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  title: {
    marginBottom: '3rem'
  }
});



const Main = props => {
  const {classes, /* toggleDrawer, */ isOpen, handleDataImport} = props;
  return (
    <div className={classNames(classes.grid, { [classes.gridShift]: isOpen })}>

      {/* <Button color="secondary" variant="contained" className={classNames(classes.toggleButton, {[classes.toggleButtonShift]: isOpen})} onClick={toggleDrawer}>
        {isOpen ? <i className="fas fa-caret-left"></i> : <i className="fas fa-caret-right"></i>}
      </Button> */}
      <div style={{width: '100%'}}>
        <Switch>
          <Route exact path="/grow" render={props => <Grow handleDataImport={handleDataImport} {...props} /> } />
          <Route exact path="/planning" component={Planning} />
          <Route exact path="/inventory" component={Inventory} />
          <Route exact path="/analytics" component={Analytics} />
          <Route exact path="/ratings" component={Ratings} />
          <Route exact path="/setup" component={Setup} />
          <Route exact path="/users/edit/:userID" render={(props) => <UserEdit action="Edit" {...props} />} />
          <Route exact path="/users/new" render={(props) => <UserEdit action="New" {...props} />} />
          <Route exact path="/users" component={Users} />
          <Route exact path="/export" component={Export} />
          
          <Route path="/" component={Home} />
        </Switch>
      </div>
    </div>
  );
};

Main.propTypes = {
  classes: PropTypes.object,
  isOpen: PropTypes.bool.isRequired
};

export default withStyles(styles)(Main);
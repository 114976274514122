import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Downshift from 'downshift';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
  comboboxContainer: {
    position: 'relative'
  },
  menuPaperContainer: {
    position: 'relative'
  },
  menuPaper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
    top: '-15px',
    overflowY: 'scroll',
    maxHeight:'200px',
  },
  inputAdornment: {
    color: theme.palette.grey[600],
    cursor: 'pointer',
    fontSize: '1.5rem'
  }
});

const ComboBox = props => {
  const {classes, items, id, label, variant, onChange, value, className, margin, disabled} = props;
  return (
    <Downshift selectedItem={value} id={`downshift-${id}`} onStateChange={(changes) => onChange(changes, id)} className={className || ''}>
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        getToggleButtonProps,
        highlightedIndex,
        // inputValue,
        isOpen,
        selectedItem,
        clearSelection
      }) => (
        <div className={classes.comboboxContainer}>
          <TextField
            disabled={disabled}
            variant={variant}
            id={id}
            label={label}
            margin={margin}
            className={classes.input}
            InputProps={{
              endAdornment: (
                selectedItem ? 
                <InputAdornment variant={variant} position="end">
                  <i className={`fas fa-times fa-2x ${classes.inputAdornment}`} onClick={clearSelection}></i>
                </InputAdornment>
                :
                <InputAdornment variant={variant} position="end" {...getToggleButtonProps()}>
                  <i className={`fas fa-2x ${isOpen ? 'fa-angle-up' : 'fa-angle-down'} ${classes.inputAdornment}`}></i>
                </InputAdornment>
                  
              ),
              ...getInputProps({})
            }}
          />
          <div className={classes.menuPaperContainer} {...getMenuProps()}>
          <Paper className={classes.menuPaper} square elevation={3}>
            {isOpen ? 
              items.map((item, index) => (
                <MenuItem
                  key={item}
                  selected={highlightedIndex === index}
                  style={{fontWeight: selectedItem === item ? 500 : 400,backgroundColor:'£fff'}}
                  {...getItemProps({
                    item,
                    index
                  })}
                >
                  {item}
                </MenuItem>
                )
              )
              : null
            }
            </Paper>
          
          </div>
        </div>
      )}
    </Downshift>
  );
}

ComboBox.defaultProps = {
  margin: 'normal',
  disabled: false,
  variant: 'standard',
  items: []
};

ComboBox.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  margin: PropTypes.string.isRequired,
  id: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(ComboBox);
import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import { withStyles, Button } from '@material-ui/core';
import UserContext from '../context_providers/UserContext';
import { sortFunction } from '../utils/utils';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import { DatePicker } from 'material-ui-pickers';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment'

const styles = theme => ({
    modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '40%',
        minWidth: 350,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
        '&:focus': {
            outline: 'none'
        }
    },
    table: {
        minWidth: 650,
    },
    button: {
        marginTop: 20,
        justify: "flex-end"
    },
    textField: {
        marginTop: '1rem'
    }
});

class AssignTaskModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedUser: '',
            selectedUserError: false,
            userList: [],
            title: '',
            titleError: false,
            taskDetails: '',
            taskDetailsError: false,
            completeByDate: null,
            completeByDateError: false
        };
    }

    componentDidMount() {

        const { users } = this.props
        let userList = [];

        Object.keys(users).sort((a, b) => sortFunction(users[a], users[b], 'asc', 'lastName'))
            .forEach(userID => {
                const userObject = {
                    ...users[userID],
                    id: userID
                };
                userList.push(userObject);
            })
        this.setState({ userList });

        // if a crop object is received, assign to state, set action as 'edit'

    }

    handleChange1 = name => e => {

        this.setState({
            [name]: e.target.value
        });
    };

    handleSave = async (e, updateUser) => {
        e.preventDefault();

        let { selectedUser,
            title,
            taskDetails,
            completeByDate } = this.state;

        let error = false;

        if (selectedUser === '') {
            error = true;
            this.setState({ selectedUserError: true });
        }
        else {
            this.setState({ selectedUserError: false })
        }
        if (title === '') {
            error = true;
            this.setState({ titleError: true });
        }
        else {
            this.setState({ titleError: false })
        }
        if (taskDetails === '') {
            error = true;
            this.setState({ taskDetailsError: true });
        }
        else {
            this.setState({ taskDetailsError: false })
        }

        if (completeByDate === null) {
            error = true;
            this.setState({ completeByDateError: true });
        }
        else {
            this.setState({ completeByDateError: false });
        }



        setTimeout(() => {
            if (!error) {
                this.setState({ isSaving: true });

                const task = { id: this.state.selectedUser.id, title, taskDetails, completeByDate: moment(completeByDate).format("MM/DD/YYYY") };
                this.props.onSave(task);
                // updateUser()
                this.props.closeModal()
            }
        }, 1000);


        // Save information

    }

    handleChange = (event) => {
        this.setState({ selectedUser: event.target.value, selectedUserError: false })
    };

    formatDate = (e, name) => {
        let oldDate = e.target.value;
        if (!moment(oldDate).isValid()) {
            return this.setState({ [name]: moment().format('YYYY-MM-DD') })
        }
        let date = oldDate.split('/')
        if (date.length === 2) {
            if (date[0].length < 2) {
                oldDate = '0' + date[0]
            }
        }
        else if (date.length === 3) {
            var test0 = date[0] + '/';
            var test1 = date[1] + '/';
            if (date[0].length < 2) {
                test0 = '0' + test0
            }
            if (date[1].length < 2) {
                test1 = '0' + test1
            }
            oldDate = test0 + test1 + date[2]
        }
        this.setState({ [name]: oldDate })
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.modal} tabIndex={-1}>
                <UserContext.Consumer>
                    {({ user, updateUser }) =>
                        <>
                            <Typography variant="h6" id="modal-title">Assign Task</Typography>
                            <TextField
                                required
                                select
                                id={`user-id`}
                                label="Select User"
                                onChange={this.handleChange}
                                margin="dense"
                                value={this.state.selectedUser}
                                error={this.state.selectedUserError}
                                fullWidth
                                className={classes.textField}
                            >
                                {this.state.userList.map(user => (
                                    <MenuItem key={user.id} value={user}>{user.firstName + ' ' + user.lastName}</MenuItem>
                                ))}
                            </TextField>

                            <TextField
                                required
                                id="task-title"
                                label="Title"
                                value={this.state.title}
                                onChange={this.handleChange1('title')}
                                margin="dense"
                                error={this.state.titleError}
                                fullWidth
                                className={classes.textField}
                            />

                            <TextField
                                required
                                id="task-details"
                                label="Task Details"
                                multiline
                                value={this.state.taskDetails}
                                onChange={this.handleChange1('taskDetails')}
                                error={this.state.taskDetailsError}
                                fullWidth
                                className={classes.textField}
                            />
                            {/* <Grid item xs={4} sm={4} md={2}> */}
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <DatePicker
                                    required
                                    label="Complete by Date"
                                    value={this.state.completeByDate}
                                    format="MM/DD/YYYY"
                                    error={this.state.completeByDateError}
                                    minDate={new Date()}
                                    // mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                    onChange={(date) => this.setState({ completeByDate: date })}
                                    onBlur={(e) => this.formatDate(e, 'completeByDate')}
                                    animateYearScrolling
                                    autoOk
                                    clearable
                                    keyboard
                                    margin="dense"
                                    style={{ marginTop: '1.5rem', width: '50%' }}
                                />
                            </MuiPickersUtilsProvider>
                            {/* </Grid> */}
                            <Grid container alignItems="flex-start" justify="flex-end" direction="row">
                                <Button
                                    size='medium' variant="contained" color="primary" className={classes.button}
                                    onClick={(e) => this.handleSave(e, updateUser)}
                                >
                                    SAVE
                                </Button>
                            </Grid>
                        </>
                    }
                </UserContext.Consumer>
            </div>
        )
    }
};

AssignTaskModal.propTypes = {
    classes: PropTypes.object.isRequired,
    onSave: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired
};

export default withStyles(styles)(AssignTaskModal);
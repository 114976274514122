import React from 'react';
import PropTypes from 'prop-types';
import Title from '../components/Title';
import FullScreenLoader from '../components/FullScreenLoader';
import BasicSortBar from '../components/BasicSortBar';
import CropModal from '../components/CropModal';
import VarietyModal from '../components/VarietyModal';
import GrowthCycleModal from '../components/GrowthCycleModal';
import RaftModal from '../components/RaftModal';
import GrowthCycleCard from '../components/GrowthCycleCard';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Modal from '@material-ui/core/Modal';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';
import red from '@material-ui/core/colors/red';
import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';
import { addEditItem, addCropType, removeCropType, pushPlots, alotTask, saveFarm, getFarms, deleteFarm, getGrowBeds, saveGrowBed, deleteGrowBed, addVarietyCode, removeVarietyCode } from '../store/actions/adders';
import { deleteItem, archiveItem, unArchiveItem } from '../store/actions/deleters';
import { connect } from 'react-redux';
import Masonry from 'react-masonry-component';
import { deepCopyObject, sortFunction } from '../utils/utils';
import { createPlotsFromBatches } from '../utils/appFunctions/plots';
import APP_SETTINGS from '../utils/appSettings';
import LotNoModal from '../components/LotNoModal';
import AssignTaskModal from '../components/AssignTaskModal';
import FarmModal from '../components/FarmModal';
import PasswordModal from '../components/PasswordModal';
import DialogTitle from '@material-ui/core/DialogTitle';
import SetupModal from '../components/SetupModal';
import CropTypesModal from '../components/CropTypesModal';
import VarietyCodesModal from '../components/VarietyCodesModal';
import UserContext from '../context_providers/UserContext';

const { COLLECTIONS } = APP_SETTINGS;

const styles = (theme) => ({
  sortBar: {
    marginBottom: 0
  },
  showArchived: {
    marginTop: '0.5rem',
    marginLeft: 0,
    marginBottom: '2rem'
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
  topButtonContainer: {
    marginBottom: '2rem'
  },
  topButton: {
    marginRight: '1rem'
  },
  buttonIcon: {
    marginRight: '0.6rem'
  },
  deleteButton: {
    color: red[300],
    backgroundColor: "white",
    borderColor: red[300],
    '&:hover': {
      backgroundColor: red[50],
    },
  },
  purgeButtonLoader: {
    color: red[300]
  },
  cropContainer: {
    marginBottom: '1rem',
    [theme.breakpoints.up('xs')]: {
      width: '100%'
    },
    [theme.breakpoints.up('lg')]: {
      width: '50%'
    },
  },
  cropTitle: {
    marginBottom: '0.5rem',
    display: 'inline',
    paddingRight: '1rem',
    width: 'max-content'
  },
  varietyContainer: {
    paddingLeft: '1.5rem',
    marginBottom: '1rem'
  },
  varietyTitle: {
    display: 'inline',
    paddingRight: '1rem',
    width: 'max-content',
    color: theme.palette.grey[700]
  },
  gcContainer: {
    marginBottom: '1rem',
    paddingLeft: '1.5rem'
  },
  actionIcons: {
    marginLeft: '1rem',
    fontSize: '1.2rem',
    cursor: 'pointer'
  },
  loweredActionIcon: {
    marginBottom: '-6px'
  },
  spanDiv: {
    color: 'lightgray',
    marginLeft: '1rem',
    fontSize: '2rem',
    height: '42px'
  },
  titleIcons: {
    height: '27px'
  },
  subTitleIcons: {
    height: '22px'
  },
  checkboxContainer: {
    width: '50px',
    marginTop: '0.6rem',
  },
  growthCycleCard: {
    marginTop: '0.1rem',
    width: '90%'
  },
  blue: {
    color: blue[300]
  },
  red: {
    color: red[300]
  },
  green: {
    color: green[300]
  },
  secondaryColor: {
    color: theme.palette.secondary.light
  },
  divider: {
    borderRightWidth: 2,
  },

});

class Setup extends React.Component {
  static contextType = UserContext

  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      isPurging: false,
      cropSort: 'asc',
      varietySort: 'asc',
      search: '',
      showArchived: false,
      modalOpen: false,
      currentCollection: null,
      currentEditID: null,
      currentParentID: null,
      dialogOpen: false,
      currentDialog: '',
      dialogMessage: '',
      dialogButtons: [],
      snackbarOpen: false,
      snackbarMessage: '',
      allVarieties: [],
      allFarms: [],
      modalOpen2: false,
      modalOpen3: false,
      deleteFarmId: '',
      openPrompt: false,
      hasBeenEdited: false,
      allGrowBeds: [],
      deleteGrowBedId: ""
    };
    this.handleChange = this.handleChange.bind(this);
    this.openModal = this.openModal.bind(this);
    this.renderModal = this.renderModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.snackbarClose = this.snackbarClose.bind(this);
    this.saveItem = this.saveItem.bind(this);
    this.saveCropType = this.saveCropType.bind(this);
    this.openConfirmDeleteDialog = this.openConfirmDeleteDialog.bind(this);
    this.openConfirmPurgeDialog = this.openConfirmPurgeDialog.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
    this.handleUnArchive = this.handleUnArchive.bind(this);
    this.handlePrimaryClick = this.handlePrimaryClick.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.handleGrowthCycleDuplicate = this.handleGrowthCycleDuplicate.bind(this);
    this.handleSeedNow = this.handleSeedNow.bind(this);
    this.handleSearchSelect = this.handleSearchSelect.bind(this);
    this.varietyCodeIsAlreadyUsed = this.varietyCodeIsAlreadyUsed.bind(this);
    this.varietyAliasIsAlreadyUsed = this.varietyAliasIsAlreadyUsed.bind(this);
    this.saveFarms = this.saveFarms.bind(this);

  }

  promisedSetState = (newState) => {
    return new Promise((resolve) => {
      this.setState(newState, () => {
        resolve()
      });
    });
  }

  async componentDidMount() {
    await Promise.all([getFarms(), getGrowBeds()]).then(resp => {
      this.setState({ allFarms: resp[0], allGrowBeds: resp[1], isLoaded: true })
    })
  }

  async getAllFarms() {
    getFarms().then(farms => {
      this.setState({ allFarms: farms })
    })
  }

  async getAllGrowBeds() {
    getGrowBeds().then(growbeds => {
      this.setState({ allGrowBeds: growbeds })
    })
  }

  handleChange = name => e => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setState({
      [name]: value
    });
  };

  openModal(modal, id, parentID) {
    this.setState({ modalOpen: true, currentCollection: modal, currentEditID: id, currentParentID: parentID });
  }

  renderModal1() {
    return (
      <LotNoModal
        allLotNo={this.state.allVarieties}
        closeModal={this.closeModal}
      />

    )
  }

  renderModal2() {
    return (
      <PasswordModal
        title="Confirm changes"
        handleSubmit={this.onSubmit}
        handleCancel={() => this.setState({ modalOpen2: false })}
      />
    );
  }

  renderModal3() {
    return (
      <SetupModal
        deleteFarmData={this.deleteFarmData}
        Farms={this.state.allFarms}
        saveFarms={this.saveFarms}
        // onDone={() => this.setState({ openPrompt: true })}
        hasBeenEditedStatus={this.hasBeenEditedStatus}
        growBeds={this.state.allGrowBeds}
        saveGrowBed={this.saveGrowBed}
        addEditItem={addEditItem}
        deleteItem={this.deleteItem}
        deleteGrowBed={this.deleteGrowBed}
        rafts={this.props.rafts}
        onDone={this.closeModal}
      />
    );
  }

  onSubmit = () => {

    this.setState({ modalOpen2: false })
    if (this.state.deleteGrowBedId) {
      deleteGrowBed(this.state.deleteGrowBedId)
      setTimeout(() => {
        this.getAllGrowBeds();
      }, 500);
    }
    else if (this.state.deleteVariety) {
      this.deleteItem();
    }
    else {
      deleteFarm(this.state.deleteFarmId);
      setTimeout(() => {
        this.getAllFarms();
      }, 500);
    }
    setTimeout(() => {
      this.setState({
        deleteGrowBedId: '',
        deleteFarmId: '',
        snackbarOpen: true,
        snackbarMessage: `Deleted successfully!`
      });
    }, 250);
  }

  deleteGrowBed = (id) => {
    deleteGrowBed(id)
    setTimeout(() => {
      this.getAllGrowBeds();
    }, 500);
    setTimeout(() => {
      this.setState({
        deleteGrowBedId: '',
        deleteFarmId: '',
        snackbarOpen: true,
        snackbarMessage: `Deleted successfully!`
      });
    }, 1000);
  }

  renderModal() {
    switch (this.state.currentCollection) {
      case COLLECTIONS.CROPS:
        const crop = this.props.crops[this.state.currentEditID];
        return (
          <CropModal
            onSave={this.saveItem}
            crop={crop}
            closeModal={this.closeModal}
          />
        )

      case COLLECTIONS.VARIETIES:
        const variety = this.props.varieties[this.state.currentEditID];
        const parentCrop = { id: this.state.currentParentID, name: this.props.crops[this.state.currentParentID].name };
        return (
          <VarietyModal
            variety={variety}
            crop={parentCrop}
            cropTypes={this.props.misc.cropTypes}
            varietyCodes={this.props.misc.varietyCodes}
            varietyCodeIsAlreadyUsed={this.varietyCodeIsAlreadyUsed}
            varietyAliasIsAlreadyUsed={this.varietyAliasIsAlreadyUsed}
            onSave={this.saveItem}
            saveCropType={this.saveCropType}
            closeModal={this.closeModal}
          />
        )

      case COLLECTIONS.GROWTH_CYCLES:
        const growthCycle = this.props.growthCycles[this.state.currentEditID];
        return (
          <GrowthCycleModal
            growthCycle={growthCycle}
            growBeds={this.state.allGrowBeds}
            rafts={this.props.rafts}
            onSave={this.saveItem}
            closeModal={this.closeModal}
          />
        )

      case COLLECTIONS.RAFTS:
        return (
          <RaftModal
            addEditItem={addEditItem}
            deleteItem={this.deleteItem}
            rafts={this.props.rafts}
            onDone={this.closeModal}
          />
        )
      case COLLECTIONS.ASSIGN_TASK:
        return (
          <AssignTaskModal
            users={this.props.users}
            onSave={this.saveTask}
            closeModal={this.closeModal}
          />
        )
      case COLLECTIONS.FARMS:
        return (
          <FarmModal
            // addEditItem={addEditItem}
            deleteItem={this.deleteFarmData}
            Farms={this.state.allFarms}
            onSave={this.saveFarms}
            onDone={() => this.setState({ openPrompt: true })}
            hasBeenEdited={this.hasBeenEditedStatus}
          />
        )

      case COLLECTIONS.CROP_TYPES:
        return (
          <CropTypesModal
            cropTypes={this.props.misc.cropTypes}
            deleteItem={this.deleteFarmData}
            Farms={this.state.allFarms}
            onSave={this.saveCropType}
            onEdit={this.onEditCropType}
            deleteCropType={this.deleteCropType}
            onDone={() => this.setState({ openPrompt: true })}
            hasBeenEdited={this.hasBeenEditedStatus}
          />
        )

      case COLLECTIONS.VARIETY_CODES:
        return (
          <VarietyCodesModal
            varietyCodes={this.props.misc.varietyCodes}
            deleteItem={this.deleteFarmData}
            onSave={this.saveVarietyCode}
            onEdit={this.onEditVarietyCode}
            varietyAliasIsAlreadyUsed={this.varietyAliasIsAlreadyUsed}
            deleteCropType={this.deleteVarietyCode}
            onDone={() => this.setState({ openPrompt: true })}
            hasBeenEdited={this.hasBeenEditedStatus}
          />
        )


      default:
        return <div>This is not the modal you're looking for.</div>
    }
  }

  closeModal(type) {
    this.setState({ modalOpen: false, currentCollection: null, currentEditID: null, currentParentID: null, modalOpen1: false, modalOpen2: false, openPrompt: false, modalOpen3: false });
  }

  snackbarClose() {
    this.setState({ snackbarOpen: false, snackbarMessage: '' });
  }

  async closeDialog(answer) {
    switch (this.state.currentDialog) {
      case 'delete':
        if (answer === 'yes') {
          if (this.state.deleteVariety) {
            this.setState({
              modalOpen2: true,
              deleteVariety: true,

            })
          }
          else {
            this.deleteItem();
            this.setState({
              cycleEditing: null,
              currentCollection: null,
              currentEditID: null,
              currentParentID: null
            });
          }
        }

        this.setState({
          dialogOpen: false,
          currentDialog: '',
          dialogMessage: '',
          dialogButtons: [],
        })

        break;

      case 'purge':
        await this.promisedSetState({
          dialogOpen: false,
          currentDialog: '',
          dialogMessage: '',
          dialogButtons: [],
          cycleEditing: null,
          currentCollection: null,
          currentEditID: null,
          currentParentID: null
        });
        if (answer === 'yes') {
          this.runPurge();
        }
        break;

      default:
        this.setState({
          dialogOpen: false,
          currentDialog: '',
          dialogMessage: '',
          dialogButtons: [],
          cycleEditing: null,
          currentCollection: null,
          currentEditID: null,
          currentParentID: null
        });
        break;
    }
  }

  saveTask = async (task) => {
    let { id, title, taskDetails, completeByDate } = task
    alotTask({ id, title, taskDetails, completeByDate, task: '', type: 'task' })
    this.closeModal();
    setTimeout(() => {
      this.context.updateUser();
    }, 1000);
  }

  async saveFarms(farm, id) {
    setTimeout(() => {
      this.setState({
        snackbarOpen: true,
        snackbarMessage: `Saved successfully!`,
      });
      this.setState({ hasBeenEdited: false });

    }, 500);
    saveFarm(farm, id)
    setTimeout(() => {
      this.getAllFarms();
    }, 2000);
  }

  saveGrowBed = async (growbed, id, snack) => {
    this.setState({
      snackbarOpen: true,
      snackbarMessage: `Saved successfully!`
    });
    saveGrowBed(growbed, id)
    setTimeout(() => {
      this.getAllGrowBeds();
    }, 1000);
  }

  hasBeenEditedStatus = () => {
    this.setState({ hasBeenEdited: true })
  }

  deleteFarmData = async (id) => {

    this.setState({ modalOpen2: true, deleteFarmId: id })

  }

  async saveItem(item, type, saveVariety) {
    const id = this.state.currentEditID;
    const collection = this.state.currentCollection;
    const parentID = this.state.currentParentID;

    if (type) { addVarietyCode(saveVariety) }
    else if (type === false) {
      addVarietyCode(false, saveVariety, true);
    }


    if (collection === COLLECTIONS.GROWTH_CYCLES) {
      // auto-set a new Growth Cycle as primary if it's the first to be added to a Variety
      const { varieties } = this.props;
      if (!varieties[parentID].growthCycles || varieties[parentID].growthCycles.length === 0) {
        item.isPrimary = true;
      }

      // set the rafts in the growthCycle to hasBeenUsed
      if (item.hd.raftType) {
        if (!this.props.rafts[item.hd.raftType].hasBeenUsed) {
          addEditItem({ collection: COLLECTIONS.RAFTS, item: { hasBeenUsed: true }, id: item.hd.raftType });
        }
      }
      if (item.fs.raftType) {
        if (!this.props.rafts[item.fs.raftType].hasBeenUsed) {
          addEditItem({ collection: COLLECTIONS.RAFTS, item: { hasBeenUsed: true }, id: item.fs.raftType });
        }
      }
    }


    // recalc plots if color from existing variety has changed
    if (collection === COLLECTIONS.VARIETIES &&
      id &&
      this.props.varieties[id].color !== item.color) {
      const plots = createPlotsFromBatches({
        batches: this.props.batches,
        varieties: {
          ...this.props.varieties,
          [id]: item
        }
      });
      this.props.pushPlots(plots);
    }
    await addEditItem({ collection, item, id, parentID });
    this.setState({ modalOpen: false, currentCollection: null, currentEditID: null, currentParentID: null });
    return;
  }

  saveCropType(cropType) {
    addCropType(cropType);
  }
  onEditCropType(cropType, index) {
    addCropType(cropType, index);
  }
  deleteCropType(index) {
    removeCropType(index)
  }


  saveVarietyCode(varietyCode) {
    addVarietyCode(varietyCode);
  }
  onEditVarietyCode(varietyCode, index) {
    addVarietyCode(varietyCode, index);
  }
  deleteVarietyCode(index) {
    removeVarietyCode(index)
  }

  varietyCodeIsAlreadyUsed(code) {
    let alreadyUsed = false;
    Object.keys(this.props.varieties).forEach(varID => {
      if (this.props.varieties[varID].varietyCode === code) {
        alreadyUsed = true;
      }
    });
    return alreadyUsed;
  }

  varietyAliasIsAlreadyUsed(alias, code) {
    let alreadyUsed = false;

    this.props.misc.varietyCodes && this.props.misc.varietyCodes.forEach(each => {
      if ((each.alias == alias) && (each.code == code)) {
        alreadyUsed = true;
      }
    });
    console.log(alias, code, alreadyUsed)
    return alreadyUsed;
  }

  childMap = {
    [COLLECTIONS.CROPS]: COLLECTIONS.VARIETIES,
    [COLLECTIONS.VARIETIES]: COLLECTIONS.GROWTH_CYCLES,
  }

  parentMap = {
    [COLLECTIONS.VARIETIES]: COLLECTIONS.CROPS,
    [COLLECTIONS.GROWTH_CYCLES]: COLLECTIONS.VARIETIES
  }

  titleMap = {
    [COLLECTIONS.CROPS]: 'crop',
    [COLLECTIONS.VARIETIES]: 'variety',
    [COLLECTIONS.GROWTH_CYCLES]: 'growth cycle'
  }

  async openConfirmDeleteDialog(collection, id, parentID) {

    if (COLLECTIONS.VARIETIES === collection) {
      this.setState({ deleteVariety: true })
    }
    const childCollection = this.childMap[collection];
    const collectionName = this.titleMap[collection];
    const childCollectionName = childCollection ? this.titleMap[childCollection] : null;
    const actionType = this.props[collection][id].hasBeenUsed ? 'Archive' : 'Delete';

    const allChildrenAreArchived = (collection, id, childCollection) => {
      let allAreArchived = true;
      this.props[collection][id][childCollection].forEach(childID => {
        if (!(this.props[childCollection] && this.props[childCollection][childID] && this.props[childCollection][childID].isArchived)) {
          allAreArchived = false;
        }
      });
      return allAreArchived;
    }

    // ensure a collection is empty of child collections before deleting
    if (!childCollectionName ||
      !this.props[collection][id][childCollection] ||
      this.props[collection][id][childCollection].length === 0 ||
      allChildrenAreArchived(collection, id, childCollection)
    ) {
      this.setState({
        dialogOpen: true,
        currentDialog: 'delete',
        dialogMessage: `${actionType} this ${collectionName}?`,
        dialogButtons: [{ label: 'No', color: 'primary' }, { label: 'Yes', color: 'primary', answer: 'yes' }],
        currentCollection: collection,
        currentEditID: id,
        currentParentID: parentID,
      });
    } else {
      this.setState({
        snackbarOpen: true,
        snackbarMessage: `Can not ${actionType.toLowerCase()} a ${collectionName} that has ${childCollectionName} assigned to it.`
      });
    }
  }

  openConfirmPurgeDialog() {
    this.setState({
      dialogOpen: true,
      currentDialog: 'purge',
      dialogMessage: 'This will delete all unused items in Setup. Proceed?',
      dialogButtons: [{ label: 'No', color: 'primary' }, { label: 'Yes', color: 'primary', answer: 'yes' }]
    });
  }

  async deleteItem({ collection = null, id = null } = {}) {
    // arguments are only passed to this function when rafts are being deleted.

    const { currentParentID } = this.state;
    const currentCollection = collection || this.state.currentCollection;
    const currentEditID = id || this.state.currentEditID;
    const collectionName = this.titleMap[currentCollection] ? this.titleMap[currentCollection].replace(/\b\w/g, l => l.toUpperCase()) : '';
    const actionType = this.props[currentCollection][currentEditID].hasBeenUsed ? 'archived' : 'deleted';

    console.log(actionType);

    if (actionType === 'archived') {
      await archiveItem({ collection: currentCollection, id: currentEditID });
    } else {
      await deleteItem({ collection: currentCollection, id: currentEditID, parentID: currentParentID });
    }

    if (currentCollection !== COLLECTIONS.RAFTS) {
      this.setState({
        snackbarOpen: true,
        snackbarMessage: `${collectionName} successfully ${actionType}.`,
        currentCollection: null,
        currentEditID: null,
        currentParentID: null,
        dialogOpen: false
      });
    }
    else {
      setTimeout(() => {
        this.setState({ dialogOpen: false })
      }, 200);

    }
  }

  handleUnArchive(collection, id) {
    // un-archive any parents of the item as well
    let varID = '';
    let cropID = '';

    if (collection === COLLECTIONS.GROWTH_CYCLES) {
      for (let variety in this.props.varieties) {
        if (this.props.varieties[variety].growthCycles.includes(id)) {
          varID = variety;
          break;
        }
      }
    }
    if (collection === COLLECTIONS.GROWTH_CYCLES || collection === COLLECTIONS.VARIETIES) {
      if (collection === COLLECTIONS.VARIETIES) {
        varID = id;
      }
      for (let crop in this.props.crops) {
        if (this.props.crops[crop].varieties.includes(varID)) {
          cropID = crop;
          break;
        }
      }
    }

    if (collection === COLLECTIONS.GROWTH_CYCLES) {
      unArchiveItem({ collection, id });
      unArchiveItem({ collection: COLLECTIONS.VARIETIES, id: varID });
      unArchiveItem({ collection: COLLECTIONS.CROPS, id: cropID });
    } else if (collection === COLLECTIONS.VARIETIES) {
      unArchiveItem({ collection, id });
      unArchiveItem({ collection: COLLECTIONS.CROPS, id: cropID });
    } else {
      unArchiveItem({ collection, id });
    }

    const collectionName = this.titleMap[collection].replace(/\b\w/g, l => l.toUpperCase());

    this.setState({
      snackbarOpen: true,
      snackbarMessage: `${collectionName} successfully un-archived.`
    });
  }

  runPurge() {
    this.setState({ isPurging: true });

    setTimeout(async () => {
      let crops = deepCopyObject(this.props.crops);
      let varieties = deepCopyObject(this.props.varieties);
      let growthCycles = deepCopyObject(this.props.growthCycles);
      let deletedCycles = [];
      let deletedVarieties = [];
      let deletedCrops = [];

      // find all unused items and delete
      for (let gcID in growthCycles) {
        const growthCycle = growthCycles[gcID];
        if (!growthCycle.hasBeenUsed) {
          delete growthCycles[gcID];
          deletedCycles.push(gcID);
        }
      }

      for (let varID in varieties) {
        let variety = varieties[varID];
        variety.growthCycles = variety.growthCycles ? variety.growthCycles.filter(gcID => !deletedCycles.includes(gcID)) : [];
        if (!variety.hasBeenUsed && variety.growthCycles.length === 0) {
          delete varieties[varID];
          deletedVarieties.push(varID);
        }
      }

      for (let cropID in crops) {
        let crop = crops[cropID];
        crop.varieties = crop.varieties.filter(varID => !deletedVarieties.includes(varID));
        if (!crop.hasBeenUsed && crop.varieties.length === 0) {
          delete crops[cropID];
          deletedCrops.push(cropID);
        }
      }

      // save modified crops and varieties to database
      for (let varID in varieties) {
        await addEditItem({ collection: COLLECTIONS.VARIETIES, id: varID, item: varieties[varID] });
      }
      for (let cropID in crops) {
        await addEditItem({ collection: COLLECTIONS.CROPS, id: cropID, item: crops[cropID] });
      }

      // delete any unused items from database
      for (let ind in deletedCrops) {
        await deleteItem({ collection: COLLECTIONS.CROPS, id: deletedCrops[ind] });
      }
      for (let ind in deletedVarieties) {
        await deleteItem({ collection: COLLECTIONS.VARIETIES, id: deletedVarieties[ind] });
      }
      for (let ind in deletedCycles) {
        await deleteItem({ collection: COLLECTIONS.GROWTH_CYCLES, id: deletedCycles[ind] });
      }

      this.setState({
        snackbarOpen: true,
        snackbarMessage: 'Items successfully purged.',
        isPurging: false
      });
    }, 1);
  }

  async handlePrimaryClick(gcID, varID) {
    if (this.props.growthCycles[gcID].isPrimary) {
      return;
    } else {
      // find the old primary Growth Cycle and set is as not primary
      const growthCycleIDs = this.props.varieties[varID].growthCycles;
      growthCycleIDs.forEach(async id => {
        if (this.props.growthCycles[id].isPrimary) {
          let item = this.props.growthCycles[id];
          item.isPrimary = false;
          await addEditItem({ collection: COLLECTIONS.GROWTH_CYCLES, item, id });
        }
      });

      // set the selected Growth Cycle as Primary
      let growthCycle = deepCopyObject(this.props.growthCycles[gcID]);
      growthCycle.isPrimary = true;
      addEditItem({ collection: COLLECTIONS.GROWTH_CYCLES, item: growthCycle, id: gcID });
    }
  }

  async handleGrowthCycleDuplicate({ varID, gcID }) {
    // create new growth cycle under the selected variety
    await this.setState({ currentCollection: COLLECTIONS.GROWTH_CYCLES, currentParentID: varID, isDuplicating: true }, async () => {
      let item = deepCopyObject(this.props.growthCycles[gcID]);
      item.isPrimary = false;
      item.isArchived = false;
      item.hasBeenUsed = false;
      return this.saveItem(item);
    });
  }

  handleSeedNow(id) {
    // redirect to Planning/New?
  }

  handleSearchSelect(selection) {
    this.setState({ search: selection });
  }


  handleClickOpen = () => {
    this.setState({ openPrompt: true })
  }

  handleClose = (type) => {
    if (type) {
      this.setState({ modalOpen3: false, openPrompt: false, hasBeenEdited: false })
    }
    else {
      this.setState({ openPrompt: false })
    }
  }




  render() {
    const { classes, crops, varieties, growthCycles } = this.props;
    return (
      this.state.isLoaded ?
        <div>
          <Title title="Setup" />

          <Grid container className={classes.topButtonContainer}>
            <Button variant="outlined" size="small" color="secondary" className={classes.topButton} onClick={() => this.openModal(COLLECTIONS.CROPS)}>
              <i className={`${classes.buttonIcon} fas fa-plus-circle fa-2x`}></i>
              Add Crop
            </Button>
            {/* <Button variant="outlined" size="small" color="secondary" className={classes.topButton} onClick={() => this.openModal(COLLECTIONS.RAFTS)}>
              <i className={`${classes.buttonIcon} fas fa-cog fa-2x`}></i>
            Manage Rafts
          </Button> */}
            <Button
              variant="outlined"
              size="small"
              className={classNames(classes.topButton, classes.deleteButton)}
              onClick={this.openConfirmPurgeDialog}
              style={{ minWidth: 134 }}
            >
              {this.state.isPurging ?
                <CircularProgress className={classes.purgeButtonLoader} size={21} />
                :
                <React.Fragment>
                  <i className={`${classes.buttonIcon} fas fa-broom fa-2x`}></i>
                  Run Purge
                </React.Fragment>
              }
            </Button>
            <Button variant="outlined" size="small" color="secondary" className={classes.topButton} onClick={() => this.openModal(COLLECTIONS.ASSIGN_TASK)}>
              <i className={`${classes.buttonIcon} fas fa-plus-circle fa-2x`}></i>
              Assign Task
            </Button>
            <Button variant="outlined" size="small" color="secondary" className={classes.topButton} onClick={() => this.setState({ modalOpen3: true })}>
              <i className={`${classes.buttonIcon} fas fa-cog fa-2x`}></i>
              Manage Farms
            </Button>
            <Button variant="outlined" size="small" color="secondary" className={classes.topButton} onClick={() => this.openModal(COLLECTIONS.CROP_TYPES)}>
              <i className={`${classes.buttonIcon} fas fa-cog fa-2x`}></i>
              Crop Types
            </Button>
            <Button variant="outlined" size="small" color="secondary" className={classes.topButton} onClick={() => this.openModal(COLLECTIONS.VARIETY_CODES)}>
              <i className={`${classes.buttonIcon} fas fa-cog fa-2x`}></i>
              Variety Codes
            </Button>

          </Grid> {/* end top button container */}

          <BasicSortBar
            varieties={varieties}
            cropSort={this.state.cropSort}
            varietySort={this.state.varietySort}
            handleChange={this.handleChange}
            className={classes.sortBar}
            onSearchSelect={this.handleSearchSelect}
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.showCompleted}
                onChange={this.handleChange('showArchived')}
                value="showArchived"
              />
            }
            label="Show Archived"
            className={classes.showArchived}
          />

          <Masonry> {/* contains all crop-blocks */}
            {crops && Object.keys(crops).sort((a, b) => sortFunction(crops[a], crops[b], this.state.cropSort, 'name')).map(cropID => {
              const crop = crops[cropID];


              // hide crop block if crop is archived
              if (!crop || (crop.isArchived && !this.state.showArchived)) { return null; }

              // hide crop block if there's a variety serach active and this crop doesn't have the variety
              let hasSelectedVariety = false;
              crop.varieties && crop.varieties.forEach(varID => {
                if (varieties[varID] && varieties[varID].name === this.state.search) { hasSelectedVariety = true; }
              })
              if (this.state.search && !hasSelectedVariety) { return null; }

              this.onClickInfo = (crop) => {

                let arr = [];
                crop.varieties && crop.varieties.sort((a, b) => sortFunction(varieties[a], varieties[b], this.state.varietySort, 'name')).map(varID => {
                  const variety = varieties[varID];

                  arr.push(variety)

                })
                this.setState({ modalOpen1: true, allVarieties: arr })
              }


              return (
                <Grid item xs={12} lg={6} key={`crop${cropID}`} className={classes.cropContainer}>
                  <Grid container alignItems="center">
                    <Typography variant="h4" color="primary" className={classes.cropTitle}>{crop.name}</Typography>
                    <Tooltip placement="top" title="Edit Crop">
                      <i className={`far fa-edit fa-2x ${classNames(classes.actionIcons, classes.titleIcons, classes.blue)}`} onClick={() => this.openModal(COLLECTIONS.CROPS, cropID)}></i>
                    </Tooltip>
                    {crop.isArchived &&
                      <Tooltip placement="top" title="Un-Archive Crop">
                        <i className={`fas fa-box-open fa-2x ${classNames(classes.actionIcons, classes.titleIcons, classes.secondaryColor)}`} onClick={() => this.handleUnArchive(COLLECTIONS.CROPS, cropID)}></i>
                      </Tooltip>
                    }
                    {!crop.isArchived &&
                      <Tooltip placement="top" title={crop.hasBeenUsed ? "Archive Crop" : "Delete Crop"}>
                        <i className={`far fa-times-circle fa-2x ${classNames(classes.actionIcons, classes.titleIcons, classes.red)}`} onClick={() => this.openConfirmDeleteDialog(COLLECTIONS.CROPS, cropID)}></i>
                      </Tooltip>
                    }
                    {!crop.isArchived &&
                      <React.Fragment>
                        <span className={classes.spanDiv}>|</span>
                        <Tooltip placement="top" title="Add Variety">
                          <i className={`fas fa-plus fa-2x ${classNames(classes.actionIcons, classes.titleIcons, classes.green)}`} onClick={() => this.openModal(COLLECTIONS.VARIETIES, null, cropID)}></i>
                        </Tooltip>
                      </React.Fragment>
                    }
                    {!crop.isArchived &&
                      <React.Fragment>
                        <span className={classes.spanDiv}>|</span>
                        <Tooltip placement="top" title="Lot Nos">
                          <i className={`fas fa-info-circle fa-2x ${classNames(classes.actionIcons, classes.titleIcons, classes.green)}`} onClick={() => this.onClickInfo(crop)}></i>
                        </Tooltip>
                      </React.Fragment>
                    }
                  </Grid>

                  {crop.varieties && crop.varieties.sort((a, b) => sortFunction(varieties[a], varieties[b], this.state.varietySort, 'name')).map(varID => {

                    const variety = varieties[varID];

                    // hide variety if it's archived
                    if (!variety || (variety.isArchived && !this.state.showArchived)) { return null; }

                    // hide variety if a variety search is active and this isn't the searched variety
                    if (this.state.search && this.state.search !== variety.name) { return null; }



                    return (
                      variety &&
                      <Grid container className={classes.varietyContainer} key={`variety${varID}`}>
                        <Grid item xs={12} className={classes.varietyTitle}>
                          <Grid container alignItems="center">
                            <Typography variant="h6" className={classes.varietyTitle}>{variety.name}</Typography>

                            <Tooltip placement="top" title="Edit Variety">
                              <i className={`far fa-edit fa-2x ${classNames(classes.actionIcons, classes.subTitleIcons, classes.blue)}`} onClick={() => this.openModal(COLLECTIONS.VARIETIES, varID, cropID)}></i>
                            </Tooltip>
                            {variety.isArchived &&
                              <Tooltip placement="top" title="Un-Archive Variety">
                                <i className={`fas fa-box-open fa-2x ${classNames(classes.actionIcons, classes.titleIcons, classes.secondaryColor, classes.loweredActionIcon)}`} onClick={() => this.handleUnArchive(COLLECTIONS.VARIETIES, varID)}></i>
                              </Tooltip>
                            }
                            {!variety.isArchived &&
                              <Tooltip placement="top" title={variety.hasBeenUsed ? "Archive Variety" : "Delete Variety"}>
                                <i className={`far fa-times-circle fa-2x ${classNames(classes.actionIcons, classes.subTitleIcons, classes.red)}`} onClick={() => this.openConfirmDeleteDialog(COLLECTIONS.VARIETIES, varID, cropID)}></i>
                              </Tooltip>
                            }
                            {!variety.isArchived &&
                              <React.Fragment>
                                <span className={classes.spanDiv}>|</span>
                                <Tooltip placement="top" title="Add Growth Cycle">
                                  <i className={`fas fa-plus fa-2x ${classNames(classes.actionIcons, classes.subTitleIcons, classes.green)}`} onClick={() => this.openModal(COLLECTIONS.GROWTH_CYCLES, null, varID)}></i>
                                </Tooltip>
                              </React.Fragment>
                            }
                          </Grid>


                          {variety.growthCycles && variety.growthCycles.map(gcID => {
                            const growthCycle = growthCycles[gcID];
                            console.log("growthCycle::",growthCycle)
                            // hide growthCycle if it's archived
                            if (!growthCycle || (growthCycle.isArchived && !this.state.showArchived)) { return null; }

                            return (
                              growthCycle &&
                              <Grid container key={`gc${gcID}`} className={classes.gcContainer}>
                                <GrowthCycleCard
                                  className={classes.growthCycleCard}
                                  id={gcID}
                                  varID={varID}
                                  growthCycle={growthCycle}
                                  varieties={this.props.varieties}
                                  handlePrimaryClick={() => this.handlePrimaryClick(gcID, varID)}
                                  handleEdit={() => this.openModal(COLLECTIONS.GROWTH_CYCLES, gcID, varID)}
                                  handleDelete={() => this.openConfirmDeleteDialog(COLLECTIONS.GROWTH_CYCLES, gcID, varID)}
                                  handleUnArchive={() => this.handleUnArchive(COLLECTIONS.GROWTH_CYCLES, gcID)}
                                  handleDuplicate={this.handleGrowthCycleDuplicate}
                                  handlSeedNow={this.handleSeedNow}
                                />
                              </Grid>
                            )
                          })}
                        </Grid>
                      </Grid>
                    )
                  })}
                </Grid>
              )
            })}
          </Masonry>

          <Modal
            aria-labelledby="modal-title"
            open={this.state.modalOpen}
            onClose={this.closeModal}
          >
            {this.renderModal()}
          </Modal>
          <Modal
            aria-labelledby="modal-title"
            open={this.state.modalOpen1}
            onClose={this.closeModal}
          >
            {this.renderModal1()}
          </Modal>

          <Modal
            aria-labelledby="modal-title"
            open={this.state.modalOpen2}
            onClose={this.closeModal}
          >
            {this.renderModal2()}
          </Modal>

          <Modal
            aria-labelledby="modal-title"
            open={this.state.modalOpen3}
            onClose={() => {
              if (!this.state.hasBeenEdited) {
                this.setState({ modalOpen3: false })
              }
              else this.setState({ openPrompt: true })
            }}
          >
            {this.renderModal3()}
          </Modal>
          <Dialog
            open={this.state.dialogOpen}
            onClose={this.closeDialog}
            aria-describedby="confirm-action-dialog"
          >
            <DialogContent>
              <DialogContentText id="confirm-action-dialog">
                {this.state.dialogMessage}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              {this.state.dialogButtons.map(button => (
                <Button key={button.label} onClick={() => this.closeDialog(button.answer)} color={button.color} autoFocus={button.autoFocus}>
                  {button.showLoader ?
                    (button.showLoader ?
                      <CircularProgress className={classes.submitButtonLoader} size={21} />
                      :
                      button.label
                    )
                    :
                    button.label
                  }
                </Button>
              ))}
            </DialogActions>
          </Dialog>

          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={this.state.snackbarOpen}
            autoHideDuration={3000}
            onClose={this.snackbarClose}
            ContentProps={{
              'aria-describedby': 'message-id',
            }}
            message={<span id="message-id">{this.state.snackbarMessage}</span>}
            action={[
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                className={classes.close}
                onClick={this.snackbarClose}
              >
                <i className="fas fa-times"></i>
              </IconButton>
            ]}
          />

          <div>
            <Dialog
              open={this.state.openPrompt}
              onClose={() => this.handleClose(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{"Alert"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  You will lose all unsaved data. Leave anyway?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => this.handleClose(false)} color="primary">
                  Disagree
                </Button>
                <Button onClick={() => this.handleClose(true)} color="primary" autoFocus>
                  Agree
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
        :
        <FullScreenLoader />
    );
  }
};

Setup.propTypes = {
  classes: PropTypes.object.isRequired,
  crops: PropTypes.object.isRequired,
  varieties: PropTypes.object.isRequired,
  growthCycles: PropTypes.object.isRequired,
  rafts: PropTypes.object.isRequired,
  misc: PropTypes.object.isRequired,
  batches: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  pushPlots: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    crops: state.crops,
    varieties: state.varieties,
    growthCycles: state.growthCycles,
    rafts: state.rafts,
    misc: state.misc,
    batches: state.batches,
    users: state.users
  }
}

export default withStyles(styles)(connect(mapStateToProps, { pushPlots })(Setup));
import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { withStyles, InputLabel, DialogTitle } from '@material-ui/core';
import classNames from 'classnames';
import red from '@material-ui/core/colors/red';
import blue from '@material-ui/core/colors/blue';
import APP_SETTINGS from '../utils/appSettings';
import Select from '@material-ui/core/Select';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
const { COLLECTIONS } = APP_SETTINGS;


const styles = theme => ({
    modal: {
        // position: 'absolute',
        // top: '50%',
        // left: '50%',
        // transform: 'translate(-50%, -50%)',
        width: '100%',
        // minWidth: 580,
        // maxWidth: 800,
        backgroundColor: theme.palette.background.paper,
        // boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
        '&:focus': {
            outline: 'none'
        },
        overflow: 'auto',
        // maxHeight: 700,
    },
    container: {
        width: '100%',
    },
    input: {
        width: '100%',
        marginTop: '0.2rem'
    },
    farmName: {
        color: '#000',
        marginTop: 40,
        fontWeight: 'bold'
    },
    buttonContainer: {
        textAlign: 'right',
        marginTop: '1.5rem'
    },
    button: {
        margin: '0.5rem 0rem'
    },
    paper: {
        width: '100%',
        marginBottom: '2rem'
    },
    sectionTitle: {
        marginTop: '2rem'
    },
    link1: {
        marginTop: '2.25rem',
        color: blue[500],
        fontSize: 15,
        cursor: 'pointer',
    },
    link: {
        color: blue[500],
        fontSize: 15,
        marginTop: 20,
        cursor: 'pointer'
    },
    actionIcons: {
        marginRight: '1.5rem',
        cursor: 'pointer',
        '&:last-child': {
            marginRight: 0
        },
        fontSize: '1.5rem'
    },
    blue: {
        color: blue[500]
    },
    red: {
        color: red[500]
    },
    grey: {
        color: theme.palette.grey[300]
    },
    editingCell: {
        color: theme.palette.grey[300]
    },
    fab: {
    },
    tabContainer: {
        margin: '3rem 0 1rem'
    },
    tabs: {
        width: '100%',
        minWidth: 400,
        '& button': {
            width: '35%',
            maxWidth: 'none'
        }
    },
    tab: {
        fontSize: '1.1rem'
    },
    divider: {
        width: '100%',
        marginTop: '-1px'
    }
});

class GrowbedsModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            seedingType: 'Select One',
            squareFeet: '',
            deleteRaft: null,
            editIsActive: false,
            editRaft: null,
            dialogOpen: false,
            ponds: [],
            addRow: true,
            currentOpen: -1,
            id: '',
            nameError: false,
            seedingTypeError: false,
            squareFeetError: false,
            openPrompt: false,
            hasBeenEdited: false,
            addFarm: false,
            growBedIdTobeDeleted: ''

        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleTabChange = (e, tabValue) => {
        this.setState({ tabValue });
    };

    handleChange = name => e => {

        if (name === 'name') {
            this.setState({ nameError: false })
        }
        this.setState({
            [name]: e.target.value,
            hasBeenEdited: true
        })
    };

    addSave = (e) => {
        e.preventDefault();

        const { name, seedingType, squareFeet } = this.state
        let growbed = {
            name: this.state.name,
            seedingType: this.state.seedingType,
            squareFeet: this.state.squareFeet
        }
        if (name.trim() === '') {
            this.setState({ nameError: true })
        }
        else {
            this.setState({ nameError: false })
        }
        if (seedingType === 'Select One') {
            this.setState({ seedingTypeError: true })
        }
        else {
            this.setState({ seedingTypeError: false })
        }
        if (squareFeet.trim() === '') {
            this.setState({ squareFeetError: true })
        }
        else {
            this.setState({ squareFeetError: false })
        }

        if ((name.trim() !== '') && (seedingType !== 'Select One') && (squareFeet.trim() !== '')) {
            this.setState({ addFarm: false })
            this.props.saveGrowBed(growbed, this.state.id)
            this.setState({ id: '', name: '', seedingType: 'Select One', squareFeet: '' })
        }

    }


    editGrowBed = (growbed) => {

        const { name, id, seedingType, squareFeet } = growbed;
        this.setState({ name, id, editIsActive: true, seedingType, squareFeet });
    }


    handleClose = (type) => {
        if (type) {
            this.props.deleteGrowBed(this.state.growBedIdTobeDeleted)
        }
        this.setState({ openPrompt: false })
    }

    render() {
        const { classes, growBeds } = this.props;
        return (
            <div className={classes.modal} tabIndex={-1}>
                {this.state.addFarm ?
                    <form>
                        <Grid container className={classes.container} spacing={16}>
                            <Grid container item xs={10} >
                                <TextField
                                    required
                                    id="name"
                                    label="Name"
                                    multiline
                                    error={this.state.nameError}
                                    value={this.state.name}
                                    onChange={this.handleChange('name')}
                                    className={classes.input}
                                />
                            </Grid>
                            <Grid container direction='row' xs={10} justify='space-between' style={{ marginTop: '1.5rem', padding: '8px' }}>
                                <Grid direction='column' item xs={6}>
                                    <InputLabel id="seedingType">Seeding Type</InputLabel>
                                    <Select
                                        required
                                        type="number"
                                        id="seedingType"
                                        label="Sq. Ft."
                                        error={this.state.seedingTypeError}
                                        value={this.state.seedingType}
                                        onChange={this.handleChange('seedingType')}
                                        margin="normal"

                                        className={classes.input}
                                    >
                                        <MenuItem key={`1`} value={'Select One'}>Select One</MenuItem>
                                        <MenuItem key={`2`} value={'Broadcast'}>Broadcast</MenuItem>
                                        <MenuItem key={`3`} value={'Single cell'}>Single cell</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        required
                                        type="number"
                                        id="squareFeet"
                                        label="Sq. Ft."
                                        value={this.state.squareFeet}
                                        error={this.state.squareFeetError}
                                        onChange={this.handleChange('squareFeet')}
                                        margin="normal"
                                        className={classes.input}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                    : null
                }
                {this.state.addFarm && <Grid container className={classes.container} justify="flex-end">
                    <Button variant="outlined" type="submit" color="primary" className={classes.button} onClick={this.addSave}>
                        {'Save'}
                    </Button>
                </Grid>}
                <Grid container className={classes.container} spacing={16}>
                    <Grid container direction='row' justify='space-between' item xs={12}>
                        <Grid >
                            <Typography variant="h6" className={classes.sectionTitle}>Manage Grow media</Typography>
                        </Grid>
                        {!this.state.addFarm ? <Grid>
                            <Typography variant="h6" onClick={() => this.setState({ addFarm: true })} className={classes.link1}>Add Grow media</Typography>
                        </Grid> : null}
                    </Grid>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <Table className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Seeding Type</TableCell>
                                        <TableCell>Sq. Ft.</TableCell>
                                        <TableCell>Edit/Delete</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {growBeds ? growBeds.length ? growBeds.map((growbed, i) => {
                                        return (
                                            <TableRow key={i}>
                                                <TableCell component="th" scope="raft" className={classNames({ [classes.editingCell]: false })}>
                                                    {growbed.name}
                                                </TableCell>
                                                <TableCell component="th" scope="raft" className={classNames({ [classes.editingCell]: false })}>
                                                    {growbed.seedingType}
                                                </TableCell>
                                                <TableCell component="th" scope="raft" className={classNames({ [classes.editingCell]: false })}>
                                                    {growbed.squareFeet}
                                                </TableCell>
                                                <TableCell>
                                                    <i
                                                        className={`far fa-edit fa-2x ${classNames(classes.actionIcons, classes.blue, { [classes.grey]: false })}`}
                                                        onClick={() => {
                                                            this.setState({ addFarm: true })
                                                            this.editGrowBed(growbed)
                                                        }}
                                                    >
                                                    </i>
                                                    <i
                                                        className={`far fa-times-circle fa-2x ${classNames(classes.actionIcons, classes.red, { [classes.grey]: false })}`}
                                                        onClick={() => this.setState({ growBedIdTobeDeleted: growbed.id, openPrompt: true })}
                                                    >
                                                    </i>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    }) : null : null}
                                </TableBody>
                            </Table>
                        </Paper>
                    </Grid>
                </Grid>


                <Dialog
                    open={this.state.openPrompt}
                    onClose={() => this.handleClose(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    {/* <DialogTitle id="alert-dialog-title">{"Alert"}</DialogTitle> */}
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                        Delete this grow media? This cannot be undone
                     </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.handleClose(false)} color="primary">
                            No
                     </Button>
                        <Button onClick={() => this.handleClose(true)} color="primary" autoFocus>
                            Yes
                     </Button>
                    </DialogActions>
                </Dialog>
            </div >
        )
    }
};

GrowbedsModal.propTypes = {
    classes: PropTypes.object.isRequired,
    rafts: PropTypes.object.isRequired,
    addEditItem: PropTypes.func.isRequired,
    deleteItem: PropTypes.func.isRequired,
    onDone: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
};

export default withStyles(styles)(GrowbedsModal);

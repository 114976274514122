import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import red from '@material-ui/core/colors/red';
import yellow from '@material-ui/core/colors/yellow';
import classNames from 'classnames';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import firebase from 'firebase/app';
import 'firebase/auth';
import UserContext from '../context_providers/UserContext';
import APP_SETTINGS from '../utils/appSettings';

const {USER_ROLES, MENU_ITEMS} = APP_SETTINGS;

const styles = () => ({
  icon: {
    fontSize: '2rem',
    margin: "auto"
  },
  red: {
    color: red[500]
  },
  listItem: {
    padding: '0.3rem 3rem'
  },
  alertListItem: {
    backgroundColor: yellow[100]
  },
  listIcon: {
    width: '1.5rem',
    marginRight: '0.5rem',
    justifyContent: 'center'
  },
  listText: {
    lineHeight: '2rem'
  },
  spacer: {
    height: '1.5rem'
  },
  logoContainer: {
    textAlign: 'center',
    margin: '2rem 0'
  },
  logo: {
    width: '90%',
    height: 'auto',
  },
  menuLink: {
    textDecoration: 'none',
    color: 'inherit'
  },
  chip: {
    marginBottom: '2rem'
  }
});

const Menu = props => {
  const {classes, location, isOpen, misc} = props;

  const logout = () => {
    props.history.push('/')
    firebase.auth().signOut();
  };

  const defaultAvatarURL = "https://firebasestorage.googleapis.com/v0/b/greener-roots.appspot.com/o/avatars%2Fdefault-avatar.jpg?alt=media&token=cb7449fe-370b-4ab9-bf37-410b035b1ce2";
  const hasLowInventoryAlert = misc.lowInventory && misc.lowInventory.length > 0;
  return (
    <UserContext.Consumer>
      {({user}) => 
        <Drawer
          variant="persistent"
          open={isOpen}
          anchor="left"
          >
          <Grid container justify='center' alignContent='center' style={{minHeight: 'max-content'}}>
            <Grid item xs={10} className={classes.logoContainer}>
              <img className={classes.logo} src="/images/greener_roots_logo.gif" alt="Greener Roots Farms" />
            </Grid>
          </Grid>
  
          <Grid container justify="center" style={{minHeight: 'max-content'}}>
            <Typography color="primary" variant="body2">Current User:</Typography>
          </Grid>
          <Grid container justify="center" style={{minHeight: 'max-content'}}>
            {user && <Chip
              avatar={<Avatar alt={`Logged in: ${user.firstName} ${user.lastName}`} src={user.avatarURL || defaultAvatarURL} />}
              label={`${user.firstName} ${user.lastName}`}
              className={classes.chip}
              onClick={() => props.history.push(`/users/edit/${user.id}`)}
            />}
          </Grid>
  
          <List>
            {MENU_ITEMS.map((item, i) => {
              if (item === null) {
                return <div className={classes.spacer} key={i}></div>;
              } else {
                const linkTo = item.name === 'User Info' && user && user.role !== USER_ROLES.ADMIN ? `${item.link}/edit/${user.id}` : item.link;
                return (
                  <Link to={linkTo} key={i} className={classes.menuLink}>
                  {(item.name === 'Inventory' && hasLowInventoryAlert) ? 
                    <ListItem className={classNames(classes.listItem, classes.alertListItem)} button selected={location.pathname === item.link}>
                      <ListItemIcon className={classNames(classes.listIcon, classes.red)}>
                        <i className="fas fa-exclamation-circle" style={{color: 'red'}}></i>
                      </ListItemIcon>
                      <ListItemText>
                        <Typography variant="h6">{item.name}</Typography>
                      </ListItemText>
                    </ListItem>
                    :
                    <ListItem className={classes.listItem} button selected={location.pathname === item.link}>
                      <ListItemIcon className={classes.listIcon}>
                        <i className={item.icon}></i>
                      </ListItemIcon>
                      <ListItemText>
                        <Typography variant="h6">{item.name}</Typography>
                      </ListItemText>
                    </ListItem>
                    }
                  </Link>
                );
              }
            })}
  
            <div className={classes.spacer}></div>
            
            <ListItem className={classes.listItem} button onClick={logout}>
              <ListItemIcon className={classes.listIcon}>
                <i className="fas fa-sign-out-alt"></i>
              </ListItemIcon>
              <ListItemText>
                <Typography variant="h6">Logout</Typography>
              </ListItemText>
            </ListItem>
          </List>
  
        </Drawer>
      }
    </UserContext.Consumer>
  );
}

Menu.propTypes = {
  classes: PropTypes.object,
  isOpen: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return ({
    misc: state.misc
  })
}


export default withStyles(styles)(withRouter(connect(mapStateToProps)(Menu)));
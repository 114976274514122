import React from 'react';
import PropTypes from 'prop-types';
import Title from '../components/Title';
import FullScreenLoader from '../components/FullScreenLoader';
import BasicSortBar from '../components/BasicSortBar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Masonry from 'react-masonry-component';
import CircularProgress from '@material-ui/core/CircularProgress';
import InventoryCard from '../components/InventoryCard';
import blue from '@material-ui/core/colors/blue';
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';
import { addEditItem, pushLowInventoryItem } from '../store/actions/adders';
import { deleteAllLowStockAlerts, deleteLowInventoryItem } from '../store/actions/deleters';
import { connect } from 'react-redux';
import { sortFunction, deepCopyObject } from '../utils/utils';
import moment from 'moment';
import APP_SETTINGS from '../utils/appSettings';

const { COLLECTIONS } = APP_SETTINGS;

const styles = (theme) => ({
  sortBar: {
    marginBottom: '3rem'
  },
  lowStockAlert: {
    height: 0,
    backgroundColor: theme.palette.grey.A400,
    width: '100%',
    marginTop: '-2rem',
    marginBottom: '2rem',
    transition: theme.transitions.create(['height'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  lowStockAlertOpen: {
    height: 'inherit',
    padding: '0.5rem 0.5rem 0.5rem 1rem',
    transition: theme.transitions.create(['height'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  lowStockItem: {
    color: blue[300]
  },
  whiteText: {
    color: 'white'
  },
  cropContainer: {
    [theme.breakpoints.up('xs')]: {
      width: '100%'
    },
    [theme.breakpoints.up('lg')]: {
      width: '50%'
    }
  },
  inventoryCard: {
    marginBottom: '1rem',
    paddingLeft: '1.5rem'
  },
  cropTitle: {
    marginBottom: '0.5rem',
    display: 'inline',
    paddingRight: '1rem',
    width: 'max-content'
  },
  varietyContainer: {
    paddingLeft: '1.5rem',
    marginBottom: '1rem'
  },
  varietyTitle: {
    display: 'inline',
    paddingRight: '1rem',
    width: 'max-content',
    color: theme.palette.grey[700]
  },
  buttonLoader: {
    color: 'white'
  },
});

class Inventory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      cropSort: 'asc',
      varietySort: 'asc',
      search: '',
      showLowStockAlert: false,
      lowStockItems: [],
      isDismissingAlerts: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.dismissLowStockAlert = this.dismissLowStockAlert.bind(this);
    this.handleSearchSelect = this.handleSearchSelect.bind(this);
    this.handleStockTransaction = this.handleStockTransaction.bind(this);
  }

  promisedSetState = (newState) => {
    return new Promise((resolve) => {
      this.setState(newState, () => {
        resolve();
      });
    });
  }

  async componentDidMount() {
    // await this.props.loadInventoryPage();
    if (this.props.misc.lowInventory && this.props.misc.lowInventory.length > 0) {
      this.setState({ showLowStockAlert: true, lowStockItems: this.props.misc.lowInventory });
    }
    setTimeout(() => {
      this.setState({ isLoaded: true });
    },1);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.misc.lowInventory !== this.props.misc.lowInventory) {
      if (this.props.misc.lowInventory && this.props.misc.lowInventory.length > 0) {
        this.setState({ showLowStockAlert: true, lowStockItems: this.props.misc.lowInventory });
      } else {
        this.setState({ showLowStockAlert: false, lowStockItems: [] });
      }
    }
  }

  async dismissLowStockAlert() {
    await this.promisedSetState({ isDismissingAlerts: true });
    await deleteAllLowStockAlerts();
    this.setState({ showLowStockAlert: false, lowStockItems: [], isDismissingAlerts: false });
  }

  handleChange = name => e => {
    this.setState({
      [name]: e.target.value
    });
  };

  handleSearchSelect(selection) {
    this.setState({ search: selection });
  }

  handleStockTransaction = varID => async (quant, action) => {
    const variety = this.props.varieties[varID];
    const stockTransactions = variety.stockTransactions ? deepCopyObject(variety.stockTransactions) : [];
    const transaction = { date: moment().format('YYYY-MM-DD'), action, quant };
    stockTransactions.push(transaction);

    if(variety.trackByCells === false){
      var currentStockWeight = variety.currentStockWeight + quant;
      const item = { currentStockWeight, stockTransactions };
      await addEditItem({ collection: COLLECTIONS.VARIETIES, id: varID, item });

      if (currentStockWeight < variety.minStock) {
        pushLowInventoryItem(varID);
      } else {
        deleteLowInventoryItem(varID);
      }
    }
    else{
      var currentStock = variety.currentStock + quant;
      const item = { currentStock, stockTransactions };
      await addEditItem({ collection: COLLECTIONS.VARIETIES, id: varID, item });

      if (currentStock < variety.minStock) {
        pushLowInventoryItem(varID);
      } else {
        deleteLowInventoryItem(varID);
      }
    }
  
    
    return;
  }

  render() {
    const { classes, crops, varieties } = this.props;
    return (
      this.state.isLoaded ?
        <div>
          <Title title="Inventory" />

          <BasicSortBar
            cropSort={this.state.cropSort}
            varietySort={this.state.varietySort}
            handleChange={this.handleChange}
            className={classes.sortBar}
            varieties={this.props.varieties}
            onSearchSelect={this.handleSearchSelect}
          />

          {this.state.showLowStockAlert &&
            <Paper className={classNames(classes.lowStockAlert, { [classes.lowStockAlertOpen]: this.state.showLowStockAlert })}>
              <Grid container alignItems="center" justify="space-between">
                <Grid item xs={10}>
                  <Typography variant="body1" className={classes.whiteText}>
                    The following are low on stock:&nbsp;

                    {this.state.lowStockItems.map((varID, ind) => {
                      if (varieties[varID]) {
                        if (ind !== this.state.lowStockItems.length - 1) {
                          return (
                            <span key={varID}><span className={classes.lowStockItem}>{varieties[varID].name}</span>, </span>
                          );
                        } else {
                          return (
                            <span key={varID}><span className={classes.lowStockItem}>{varieties[varID].name}</span></span>
                          );
                        }
                      }
                      else{
                        return(<></>)
                      }
                    })}
                  </Typography>
                </Grid>
                <Button color="primary" onClick={this.dismissLowStockAlert}>
                  {this.state.isDismissingAlerts ?
                    <CircularProgress className={classes.buttonLoader} size={21} />
                    :
                    'Dismiss'
                  }
                </Button>

              </Grid>
            </Paper>
          }
          <Masonry> {/* contains all crop-blocks */}
            {crops && Object.keys(crops).sort((a, b) => sortFunction(crops[a], crops[b], this.state.cropSort, 'name')).map(cropID => {


              const crop = crops[cropID];

              if (crop.isArchived) { return null; }



              let hasVarieties = false;
              let hasSelectedVariety = false;
              crop.varieties && crop.varieties.forEach(varID => {
                if (varieties[varID]) {
                  if (varieties[varID].name === this.state.search) { hasSelectedVariety = true; }
                  if (varieties[varID].trackStock) { hasVarieties = true; }
                }
              })
              if (!hasVarieties || (this.state.search && !hasSelectedVariety)) { return null; }


              return (
                <Grid key={cropID} item xs={12} md={6} className={classes.cropContainer}>
                  <Typography variant="h4" className={classes.cropTitle}>{crop.name}</Typography>
                  {crop.varieties && crop.varieties.sort((a, b) => sortFunction(varieties[a], varieties[b], this.state.varietySort, 'name')).map(varID => {
                               
                    const variety = varieties[varID];
                    if (varieties[varID]) {
                      if (variety.isArchived) { return null; }
                      if (!variety.trackStock) { return null; }
                      if (this.state.search && this.state.search !== variety.name) { return null; }
                    }
                    return (
                      variety &&
                      <InventoryCard
                        key={varID}
                        className={classes.inventoryCard}
                        variety={variety}
                        id={varID}
                        varieties={this.props.varieties}
                        handleStockTransaction={this.handleStockTransaction(varID)}
                      />
                    )
                  })}
                </Grid>
              )
            })}
          </Masonry>
        </div>
        :
        <FullScreenLoader />
    );
  }
};

Inventory.propTypes = {
  classes: PropTypes.object.isRequired,
  crops: PropTypes.object.isRequired,
  varieties: PropTypes.object.isRequired,
  growthCycles: PropTypes.object.isRequired,
  misc: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    crops: state.crops,
    varieties: state.varieties,
    growthCycles: state.growthCycles,
    misc: state.misc
  }
}

export default withStyles(styles)(connect(mapStateToProps)(Inventory));
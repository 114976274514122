import {
  ADD_EDIT_CROP,
  ADD_EDIT_FARM,
  ADD_EDIT_VARIETY,
  ADD_EDIT_GROWTH_CYCLE,
  ADD_EDIT_RAFT,
  ADD_EDIT_BATCH,
  ADD_TASKS,
  EDIT_TASK,
  ADD_INVENTORY_ALERT,
  ADD_CROP_TYPE,
  ADD_USER,
  ADD_PLOTS,
  ADD_EDIT_PERPETUAL_BATCH,
  SET_HAS_INITIALLY_LOADED,
  SET_SHOULD_CALCULATE_PLOTS,
  SET_PLOT_CALC_AFTER_NUMBER,
  ADD_BATCHES,
  SET_PLOT_CALC_SUSPEND_COUNTER,
  ADD_VARIETY_LEGEND
} from '../actionTypes';
import firebase from 'firebase/app';
import 'firebase/firestore';
import APP_SETTINGS from '../../utils/appSettings';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment'
import { deepCopyObject } from '../../utils/utils';

const { COLLECTIONS } = APP_SETTINGS;


export const pushCrop = crop => ({
  type: ADD_EDIT_CROP,
  crop
});


export const pushFarm = farm => ({
  type: ADD_EDIT_FARM,
  farm
});

export const pushVariety = variety => ({
  type: ADD_EDIT_VARIETY,
  variety
});

export const pushGrowthCycle = growthCycle => ({
  type: ADD_EDIT_GROWTH_CYCLE,
  growthCycle
});

export const pushRaft = raft => ({
  type: ADD_EDIT_RAFT,
  raft
});

export const pushBatch = batch => ({
  type: ADD_EDIT_BATCH,
  batch
});

export const pushBatches = batches => ({
  type: ADD_BATCHES,
  batches
});

export const pushPerpetualBatch = perpetualBatch => ({
  type: ADD_EDIT_PERPETUAL_BATCH,
  perpetualBatch
});

export const pushTasks = tasks => ({
  type: ADD_TASKS,
  tasks
});

export const pushTaskEdit = task => ({
  type: EDIT_TASK,
  task
});

export const pushPlots = plots => ({
  type: ADD_PLOTS,
  plots
});

export const pushVarietyLegend = legend => ({
  type: ADD_VARIETY_LEGEND,
  legend
});

export const pushInventoryAlert = varID => ({
  type: ADD_INVENTORY_ALERT,
  varID
});

export const pushCropType = cropType => ({
  type: ADD_CROP_TYPE,
  cropType
});

export const pushUser = user => ({
  type: ADD_USER,
  user
});

export const setHasInitiallyLoaded = state => ({
  type: SET_HAS_INITIALLY_LOADED,
  state
});

export const setShouldCalculatePlots = state => ({
  type: SET_SHOULD_CALCULATE_PLOTS,
  state
});

export const startPlotCalcAfterNumber = number => ({
  type: SET_PLOT_CALC_AFTER_NUMBER,
  number
});

export const setPlotCalcCounter = number => ({
  type: SET_PLOT_CALC_SUSPEND_COUNTER,
  number
});

const parentMap = {
  [COLLECTIONS.VARIETIES]: COLLECTIONS.CROPS,
  [COLLECTIONS.GROWTH_CYCLES]: COLLECTIONS.VARIETIES
};

function getCanvasBlob(canvas) {
  return new Promise(function (resolve, reject) {
    canvas.toBlob(function (blob) {
      resolve(blob)
    })
  })
}

export const addEditItem = async ({ collection, item, id, parentID }) => {
  const db = firebase.firestore();
  if (id) { // update existing item
    // Send new image to storage if necessary
    if (collection === COLLECTIONS.VARIETIES && item.newAvatarObject) {
      await getCanvasBlob(item.newAvatarObject)
        .then(blob => firebase.storage().ref().child(`varietyAvatars/${id}-avatar.png`).put(blob))
        .then(snapshot => snapshot.ref.getDownloadURL())
        .then(url => {
          delete item.newAvatarObject;
          return item.avatarURL = url;
        });
    }
    item.lastUsedSeedLot = item.lastUsedSeedLot || '';
    db.collection(collection).doc(id).update(item)
      .catch(err => console.log(err));

    return;

  } else {  // add new item
    let itemObj = {};

    await db.collection(collection).add(item)
      .then(async docRef => {
        itemObj[docRef.id] = item;
        // Send new image to storage if necessary
        if (collection === COLLECTIONS.VARIETIES && item.newAvatarObject) {
          await getCanvasBlob(item.newAvatarObject)
            .then(blob => firebase.storage().ref().child(`varietyAvatars/${docRef.id}-avatar.png`).put(blob))
            .then(snapshot => snapshot.ref.getDownloadURL())
            .then(url => {
              delete itemObj[docRef.id].newAvatarObject;
              return itemObj[docRef.id].avatarURL = url;
            });
        }

        // parentID is only passed if a parent document needs updated as well
        if (parentID) {
          // get the relevant parent document, push new child ID to the list of children
          return db.collection(parentMap[collection]).doc(parentID).get()
            .then(doc => {
              let children = doc.data()[collection] || [];
              children.push(docRef.id);
              doc.ref.set({ [collection]: children }, { merge: true })
                .catch(err => console.log(err));
            })
            .catch(err => console.log(err));
        }
      })
      .catch(err => console.log(err));

    return (itemObj);
  }
};

export const addEditUser = async ({ user, id }) => {
  // this will always be called with an id

  const db = firebase.firestore();

  try {
    await db.collection('users').doc(id).set(user, { merge: true });
  } catch (err) {
    console.log(err);
  }
  return;
};

// export const alotTask = async ({ title, id, taskDetails, completeByDate, type ,task }) => {
// this will always be called with an id
export const alotTask = async (taskList) => {
  if (!taskList) return
  const db = firebase.firestore();
  try {
    db.collection(COLLECTIONS.USERS).doc(taskList.id).set({ 'allotedTask': { [uuidv4()]: taskList } }, { merge: true })
  } catch (err) {
    console.log(err);
  }
};



export const changeTaskStatus = async (task) => {
  // this will always be called with an id

  if (!task) return
  const db = firebase.firestore();
  try {
    db.collection(COLLECTIONS.USERS).doc(task.user).set({ 'allotedTask': { [task.groupId]: { status: task.newStatus , completeTime : task.completeTime } } }, { merge: true })
    return true
  } catch (err) {
    console.log(err);
    return false
  }
};


export const getUserTask = async (user) => {

  if (!user) return
  const db = firebase.firestore();
  try {
    let { allotedTask } = (await db.collection(COLLECTIONS.USERS).doc(user).get()).data();

    if (allotedTask) {
      return allotedTask;
    }

  } catch (err) {
    console.log(err);
  }
  return;
};


export const getAllUsers = async () => {
  // this will always be called with an id

  const db = firebase.firestore();

  try {
    let allUsers = db.collection(COLLECTIONS.USERS).get().then((users) => {
      let allUsers = [];
      users.forEach((doc) => {
        let user = doc.data()
        user.id = doc.id
        allUsers.push(user)
      });

      return allUsers
    });
    return allUsers
  } catch (err) {
    console.log(err);
  }
  return;
};

export const saveFarm = async (farm, id) => {
  // this will always be called with an id
  const db = firebase.firestore();
  try {
    let found = false
    await db.collection(COLLECTIONS.FARMS).get().then((farms) => {
      farms.forEach(async (doc) => {
        if (doc.id === id) {
          found = true
          await db.collection(COLLECTIONS.FARMS).doc(id).set(farm)
        }
      })
    })
    if (!found) {
      await db.collection(COLLECTIONS.FARMS).add(farm)
    }
    return
  } catch (err) {
    console.log(err);
  }
  return;
};

export const addEditButton = async (button, id) => {

  const db = firebase.firestore();

  try {
    if (id) return db.collection(COLLECTIONS.BUTTON).doc(id).update(button).catch(ex => {
      throw new Error(ex)
    })
    return db.collection(COLLECTIONS.BUTTON).add(button).catch(ex => {
      throw new Error(ex)
    })

  } catch (error) {
    console.log(error);
    throw new Error(error)
  }
}

export const getButtonList = async (button, id) => {

  const db = firebase.firestore();
  try {
    let allButtons = [];
    await db.collection(COLLECTIONS.BUTTON).get()
      .then(button => {
        button.forEach((doc) => {
          let button = doc.data();
          button.id = doc.id
          allButtons.push(button)
        })
      })
    return allButtons

  } catch (error) {
    console.log(error);
    throw new Error(error)
  }
}

export const deleteButton = async (id) => {
  // this will always be called with an id
  const db = firebase.firestore();
  try {
    return db.collection(COLLECTIONS.BUTTON).doc(id).delete().then(function () {
      console.log("Button successfully deleted!");
    }).catch(function (error) {
      console.error("Error removing button: ", error);
    });
  } catch (err) {
    console.log(err);
    throw new Error(err)
  }
  return;
};


export const deleteFarm = async (id) => {
  // this will always be called with an id
  const db = firebase.firestore();
  try {
    db.collection(COLLECTIONS.FARMS).doc(id).delete().then(function () {
      console.log("Document successfully deleted!");
    }).catch(function (error) {
      console.error("Error removing document: ", error);
    });
    return
  } catch (err) {
    console.log(err);
  }
  return;
};

export const getFarms = async () => {
  // this will always be called with an id

  const db = firebase.firestore();
  try {
    let allFarms = [];
    await db.collection(COLLECTIONS.FARMS).get()
      .then(farms => {

        farms.forEach((doc) => {
          let farmData = doc.data();
          farmData.id = doc.id
          allFarms.push(farmData)
        })
      })

    return allFarms
  } catch (err) {
    console.log(err);
  }
  return;
};

export const getPerpitualBatches = async () => {
  // this will always be called with an id

  const db = firebase.firestore();
  try {
    let perpitualBatches = [];
    await db.collection(COLLECTIONS.PERPETUAL_BATCHES).get()
      .then(farms => {

        farms.forEach((doc) => {
          let farmData = doc.data();
          farmData.id = doc.id
          perpitualBatches.push(farmData)
        })
      })

    return perpitualBatches
  } catch (err) {
    console.log(err);
  }
  return;
};

export const getGrowBeds = async () => {
  // this will always be called with an id

  const db = firebase.firestore();
  try {
    let allGrowBeds = [];
    await db.collection(COLLECTIONS.GROW_BEDS).get()
      .then(growbeds => {

        growbeds.forEach((doc) => {
          let farmData = doc.data();
          farmData.id = doc.id
          allGrowBeds.push(farmData)
        })
      })

    return allGrowBeds
  } catch (err) {
    console.log(err);
  }
  return;
};

export const getRafts = async () => {
  const db = firebase.firestore();
  try {
    let allRafts = [];
    await db.collection(COLLECTIONS.RAFTS).get()
      .then(rafts => {

        rafts.forEach((doc) => {
          let raftData = doc.data();
          raftData.id = doc.id
          allRafts.push(raftData)
        })
      })

    return allRafts
  } catch (err) {
    console.log(err);
  }
  return;
};



export const saveGrowBed = async (growBed, id) => {
  // this will always be called with an id
  const db = firebase.firestore();
  try {
    if (id) await db.collection(COLLECTIONS.GROW_BEDS).doc(id).update(growBed)
    else await db.collection(COLLECTIONS.GROW_BEDS).add(growBed)
    return
  } catch (err) {
    console.log(err);
  }
  return;
};

export const deleteGrowBed = async (id) => {
  // this will always be called with an id
  const db = firebase.firestore();
  try {
    if (id) await db.collection(COLLECTIONS.GROW_BEDS).doc(id).delete().then(function () {
      console.log("Document successfully deleted!");
    }).catch(function (error) {
      console.error("Error removing document: ", error);
    });
    return
  } catch (err) {
    console.log(err);
  }
  return;
};


export const approveUser = async (id) => {
  // this will always be called with an id

  const db = firebase.firestore();
  try {
    db.collection("users").get().then((users) => {
      users.forEach((doc) => {
        if (doc.id === id) {
          let currentUser = doc.data();
          if (!currentUser.approved) {
            currentUser.approved = true
            db.collection("users").doc(id).set(currentUser)
          }
        }
      });
    });
    return
  } catch (err) {
    console.log(err);
  }
  return;
};


export const removeUser = async (id) => {
  // this will always be called with an id

  const db = firebase.firestore();
  try {
    db.collection(COLLECTIONS.USERS).doc(id).delete().then(function () {
      console.log(id, "User successfully deleted!");
    }).catch(function (error) {
      console.error("Error removing document: ", error);
    });;
    return
  } catch (err) {
    console.log(err);
  }
  return;
};

export const addCropType = async (cropType, index) => {
  const db = firebase.firestore();
  db.collection(COLLECTIONS.MISC).doc("cropTypes").get()
    .then(doc => {
      let list = doc.data().list;
      if (index !== undefined) { list[index] = cropType }
      else list.push(cropType);
      doc.ref.set({ list })
        .catch(err => console.log(err));
    })
    .catch(err => console.log(err));
};


export const removeCropType = async (index) => {
  const db = firebase.firestore();
  db.collection(COLLECTIONS.MISC).doc("cropTypes").get()
    .then(doc => {
      let list = doc.data().list;
      list.splice(index, 1);
      doc.ref.set({ list })
        .catch(err => console.log(err));
    })
    .catch(err => console.log(err));
};

export const addPestType = async (pestType, index) => {
  const db = firebase.firestore();
  db.collection(COLLECTIONS.MISC).doc("pestList").get()
    .then(doc => {
      let list = doc.data().list;
      if (index !== undefined) { list[index] = pestType }
      else list.push(pestType);
      doc.ref.set({ list })
        .catch(err => console.log(err));
    })
    .catch(err => console.log(err));
};


export const removePestType = async (index) => {
  const db = firebase.firestore();
  db.collection(COLLECTIONS.MISC).doc("pestList").get()
    .then(doc => {
      let list = doc.data().list;
      list.splice(index, 1);
      doc.ref.set({ list })
        .catch(err => console.log(err));
    })
    .catch(err => console.log(err));
};

export const addVarietyCode = async (varietyCode, index, alreadyUsed) => {



  const db = firebase.firestore();


  db.collection(COLLECTIONS.MISC).doc("varietyCodes").get()
    .then(doc => {
      let list = doc.data().list;

      if (index !== undefined && alreadyUsed) {
        let copyCode = deepCopyObject(list[index])
        copyCode.alreadyUsed = true;
        list[index] = copyCode
      }
      else if (index !== undefined) { list[index] = varietyCode }
      else list.push(varietyCode);
      doc.ref.set({ list })
        .catch(err => console.log(err));
    })
    .catch(err => console.log(err));
};


export const removeVarietyCode = async (index) => {
  const db = firebase.firestore();
  db.collection(COLLECTIONS.MISC).doc("varietyCodes").get()
    .then(doc => {
      let list = doc.data().list;
      list.splice(index, 1);
      doc.ref.set({ list })
        .catch(err => console.log(err));
    })
    .catch(err => console.log(err));
};



export const addReason = async (reason) => {
  const db = firebase.firestore();
  db.collection(COLLECTIONS.MISC).doc("reasons").get()
    .then(doc => {
      let list = doc.data().list;
      list.push(reason);
      doc.ref.set({ list })
        .catch(err => console.log(err));
    })
    .catch(err => console.log(err));
};

export const addEditBatch = async ({ batch, id }) => {
  console.log(JSON.stringify(batch));

  const db = firebase.firestore();

  console.log({ batch, id });

  if (!id) {
    // add new batch to db
    let docRef;
    try {
      docRef = await db.collection(COLLECTIONS.BATCHES).add(batch);
    } catch (err) {
      console.log(err);
    }
    const newID = docRef.id

    // set 'hasBeenUsed' on the relevant crop, variety, and growth cycle
    db.collection(COLLECTIONS.CROPS).doc(batch.crop).set({ hasBeenUsed: true }, { merge: true })
      .catch(err => console.log(err));
    db.collection(COLLECTIONS.VARIETIES).doc(batch.variety).set({ hasBeenUsed: true }, { merge: true })
      .catch(err => console.log(err));
    db.collection(COLLECTIONS.GROWTH_CYCLES).doc(batch.growthCycleID).set({ hasBeenUsed: true }, { merge: true })
      .catch(err => console.log(err));

    return newID;

  } else {
    // push edited batch to db
    console.log({ batch });
    await db.collection(COLLECTIONS.BATCHES).doc(id).update(batch)
      .catch(err => console.log(err));

    return;
  }
};

export const updateMultipleBatches = async (batches) => {
  const db = firebase.firestore();
  var tables = db.batch();
  for (let batchID in batches) {
    const batch = batches[batchID];
    // push edited batch to db
    console.log("multitask5555Batch",batch);
    const batchRef =  db.collection(COLLECTIONS.BATCHES).doc(batchID);
    tables.update(batchRef,batch);
  }
  await tables.commit();
  console.table("multitask5555complete", moment().format("HH:mm:ss"),tables);
  return;
};

export const updateMultipleVarieties = async (varietys) => {
  const db = firebase.firestore();
  var tables = db.batch();
  for (let varietyID in varietys) {
    const variety = varietys[varietyID];
    // push edited variety to db
    console.log("multitask5555variety",variety);
    const varietyRef =  db.collection(COLLECTIONS.VARIETIES).doc(varietyID);
    tables.update(varietyRef,variety);
  }
  await tables.commit();
  console.table("multitask5555varietycomplete", moment().format("HH:mm:ss"),tables);
  return;
};

export const addEditPerpetualBatch = async ({ perpetualBatch, id }) => {
  const db = firebase.firestore();

  // new perpetual batches use an uuid generated on front end

  console.log({ perpetualBatch });
  db.collection(COLLECTIONS.PERPETUAL_BATCHES).doc(id).set(perpetualBatch, { merge: true })
    .catch(err => console.log(err));

  return;
};

export const batchTransaction = async (batchID, edits) => {
  console.log('edits',edits);
  // edits is an object with all necessary changes, ex.: { propDate: <Date>, current: {stage: 'seeded'} }
  const db = firebase.firestore();
  const batchDocRef = db.collection(COLLECTIONS.BATCHES).doc(batchID);
  return db.runTransaction(transaction => {
    return transaction.get(batchDocRef).then(batchDoc => {
      if (!batchDoc.exists) { throw new Error("Document does not exist.") }
      transaction.update(batchDocRef, edits);
    })
  })
    .catch(err => console.log(err));
};

export const pushLowInventoryItem = async varID => {
  const db = firebase.firestore();
  const lowInventoryDocRef = db.collection(COLLECTIONS.MISC).doc('lowInventory');
  return db.runTransaction(transaction => {
    return transaction.get(lowInventoryDocRef).then(lowInventoryDoc => {
      if (!lowInventoryDoc.exists) { throw new Error("Document does not exist.") }
      let list = lowInventoryDoc.data().list.slice();
      if (!list.includes(varID)) {
        list.push(varID);
      }
      transaction.update(lowInventoryDocRef, { list });
    });
  })
    .catch(err => console.log(err));
}

import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core';

const styles = () => ({
  card: {
    padding: '1.5rem 2rem'
  },
  avatar: {
    width: 60,
    height: 60
  },
  link: {
    textDecoration: 'none',
    color: 'inherit'
  }
});

const UserCard = props => {
  const {classes, fullName, role, email, avatarSrc} = props;
  return (
    <Card>
      <CardActionArea>
        <CardContent className={classes.card}>
          <Grid container alignItems="center">
            <Grid item xs={3}>
              <Avatar alt="some employee" src={avatarSrc} className={classes.avatar} />  
            </Grid>
            <Grid item xs={9}>
              <Grid container direction="column" justify="space-evenly">
                <Typography variant="h6">{fullName}</Typography>
                {role && <Typography variant="body1">{role.replace(/\b\w/g, l => l.toUpperCase())}</Typography>}
                <Typography variant="body1">{email}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

UserCard.propTypes = {
  classes: PropTypes.object.isRequired,
  fullName: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  avatarSrc: PropTypes.string.isRequired
};

export default withStyles(styles)(UserCard);
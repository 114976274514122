import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import red from '@material-ui/core/colors/red';
import orange from '@material-ui/core/colors/orange';
import GrowthCycleCard from './GrowthCycleCard';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import { DatePicker } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import { Button, IconButton, Modal, Snackbar, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import { objectsAreEqual, sortFunction } from '../utils/utils';
import APP_SETTINGS from '../utils/appSettings';
import { getAllUsers } from '../store/actions/adders';
import FinishingPonds from './FinishingPonds';

const { SEED_DAYS, REPEAT_TYPE } = APP_SETTINGS;


const styles = theme => ({
  formContainer: {
    position: 'relative',
    padding: '1rem',
    paddingTop: '2rem'
  },
  capacityError: {
    position: 'absolute',
    top: 14,
    left: 16,
    fontSize: '0.9rem',
    color: red[500]
  },
  formRow: {
    minHeight: 'max-content'
  },
  flex: {
    display: "flex",
    justifyContent: "end",
  },
  perpetualRow: {
    marginTop: '0.5rem'
  },
  growthCycleMenuItem: {
    minHeight: 'max-content',
    height: 'auto !important'
    // chandan yadav @ 4 August 
  },
  infoContainer: {
    position: 'relative',
    marginBottom: '0.2rem',
    padding: '0.5rem 0.75rem',
    border: '1px solid',
    borderColor: theme.palette.grey[300],
    borderRadius: '5px',
    minHeight: 45
  },
  infoItem: {
    marginRight: '1.5rem'
  },
  infoBtm: {
    marginBottom: '15px',
    marginRight: '20px',
    fontWeight: 500
  },
  info: {
    color: theme.palette.grey[700],
    marginLeft: '0.5rem'
  },
  widthFull:{
    width: "100%"
  },
  cellError: {
    color: orange[600]
  },
  deleteRowButton: {
    position: 'absolute',
    top: 11,
    right: 15,
    fontSize: '1.7rem',
    cursor: 'pointer',
    color: red[300]
  },
  warningLabel: {
    color: `${orange[600]} !important`,
    '&:after': {
      borderBottomColor: `${orange[600]} !important`
    }
  },
  warningInput: {
    '&:after': {
      borderBottomColor: `${orange[600]} !important`
    }
  },
  formControl: {
    margin: 13,
    minWidth: 165,
  },
  snackbarOpen: true, snackbarMessage: '', snackBarUndo: false,
  buttonContainer: {
    marginTop: '0.5rem',
    paddingRight: '0.5rem',
    marginBottom: '0',
    justifyContent: 'end'
  },
  button: {
    position: 'relative',
    marginLeft: '1.5rem'
  },
});

const rowTemplate = {
  id: '',
  quantity: ''
}

class NewBatchFormRow extends React.Component {
  constructor(props) {
    super(props);
    this.state =
    {
      menuOpen: false,
      userList: [],
      seedingPonds: [],
      modalOpen: false,
      currentModal: '',
      errors: {
        hd: false,
        fs: false
      },
      errorMessages: {
        hd: 'Please enter valid details.',
        fs: 'Please enter valid details.',
      },
    };
    this.onOpen = this.onOpen.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  componentDidMount() {
    this.getData();
  }
  onOpen(e) {
    this.setState({ menuOpen: true });
  }

  getData = () => {
    getAllUsers().then(users => {
      let userList = [];
      Object.keys(users).sort((a, b) => sortFunction(users[a], users[b], 'asc', 'lastName'))
        .forEach(userID => {
          const userObject = {
            ...users[userID],
            // id: userID
          };
          userList.push(userObject);
        });
      this.setState({ userList });
    })
  }
  onClose(e) {
    this.setState({ menuOpen: false });
  }





  openFinishingPond = () => {(this.props.allFarms.find(each => {
    let pondType = this.props.data.growthCycleObj.growMedia === 'Rockwool' ? 'finishingLocation' :'propLocation1';
    if(each.id === this.props.data[pondType].farm){
      return each
    }
  }))?this.setState({ modalOpen: !this.state.modalOpen, currentModal: 'finishingPonds' }):this.setState({snackbarOpen: true, snackbarMessage: 'Please select finishing location', snackBarUndo: false})} 

  validateFinishingPonds = (dataVal) => {


    const farmLocation = dataVal.growthCycleObj.growMedia === 'Rockwool' ? 'finishingLocation' :'propLocation1'

    const ponds = dataVal[farmLocation].pond;
    const { errorMessages } = this.state;

    let errors = {
      hd: false,
      fs: false,
      prop : false
    }

    if(dataVal.growthCycleObj.hd.days === 0){
      delete errors.hd;
      delete ponds.hd;

    }
    if(dataVal.growthCycleObj.growMedia !== 'Rockwool'){
      delete errors.hd;
      delete errors.fs;
    }

    if (!ponds) return
    let valid = true;

    for (const pond in ponds) {

      // eslint-disable-next-line no-loop-func
      ponds[pond].forEach((each, ind) => {
        if (!each.pond && !each.noOfRaft) {
          if (ind === 0) {
            errors[pond] = true;
            errorMessages[pond] = 'Please enter details.';
            valid = false;
            return
          }
        }
        else if (!each.pond || !each.noOfRaft) {
          errors[pond] = true;
          errorMessages[pond] = 'Please enter valid details.';
          valid = false;
          return
        }
      })
    }
    this.setState({ errors, errorMessages })
    if (!valid) {
      console.log('invalid')
    }
    else {
      console.log('valid')
      this.closeModal();
      this.props.handleCompleteRowChange(dataVal)
    }

  }


  closeModal = () => {
    this.setState({ modalOpen: false, currentModal: '' });
  }

  closeSnackBar = () => {
    this.setState({ snackbarOpen: false, snackbarMessage: '', snackBarUndo: false });
  }


  renderModal() {
    const { currentModal, errors, errorMessages } = this.state;
    const { data, growthCycles } = this.props;
    // eslint-disable-next-line default-case
    switch (currentModal) {
      case 'finishingPonds':
        return (
          <FinishingPonds
            active={1}
            handleSubmit={this.validateFinishingPonds}
            data={data}
            errors={errors}
            checkAvailabilityPond={this.props.checkAvailabilityPond}
            errorMessages={errorMessages}
            handleChangePond={this.props.handleChangePond}
            deletePond={this.props.deletePond}
            growthCycles={growthCycles}
            growthCyclesType={data.growthCycle && growthCycles[data.growthCycle].growMedia}
            addRowInPond={this.props.addRowInPond}
            finishingFarm={this.props.allFarms.find(each => {
              let pondType = data.growthCycleObj.growMedia === 'Rockwool' ? 'finishingLocation' :'propLocation1';
              if(each.id === data[pondType].farm){
                return each
              }
            })}
            handleCancel={this.closeModal}
          />
        )
    }
  }

  render() {
    const { classes, rowInd, data, crops, varieties, growthCycles, onChange, deleteRow, className, allFarms } = this.props;
    const days = Object.keys(SEED_DAYS).map(key => SEED_DAYS[key]);
    const propFarm = allFarms.find(each => each.id === data.propLocation.farm);
    const renderName = (id) => {
      if (!this.props.users || !id) {
        return (
          <></>
        )
      }
      else return (
        <div>{this.props.users[id].firstName + " " + this.props.users[id].lastName}</div>
      )
    }
    return (
      <Paper elevation={1} className={classNames(className, classes.formContainer)}>

        {/* <div className={classes.capacityError}>{data.errorMessages.capacity}</div> */}
        <form onSubmit={(e) => e.preventDefault()}>
          <Grid container className={classes.formRow} spacing={16}>


            <Grid item xs={4} md={2}>
              <TextField
                required
                select
                id={`crop-${rowInd}`}
                label="Crop"
                value={data.crop}
                onChange={onChange('crop')}
                // onBlur={onChange('crop',true)}
                margin="dense"
                fullWidth
              >
                {Object.keys(crops).sort((a, b) => sortFunction(crops[a], crops[b], 'asc', 'name')).map(cropID => {
                  const crop = crops[cropID];
                  if (!crop.isArchived) return (
                    <MenuItem key={`${rowInd}-${cropID}`} value={cropID}>{crop.name}</MenuItem>
                  );
                })}
              </TextField>
            </Grid>
            <Grid item xs={4} md={2}>
              <TextField
                required
                select
                disabled={!data.crop}
                id={`variety-${rowInd}`}
                label="Variety"
                value={data.variety}
                onChange={onChange('variety')}
                // onBlur={onChange('variety',true)}
                margin="dense"
                fullWidth
              >
                {data.crop ?
                  crops[data.crop].varieties ?
                    crops[data.crop].varieties.filter((p) => varieties?.[p]?.isArchived !== true).sort((a, b) => sortFunction(varieties[a], varieties[b], 'asc', 'name')).map(varID => {
                      const variety = varieties[varID];
                      return (
                        <MenuItem key={`${rowInd}-${varID}`} value={varID}>{variety.name}</MenuItem>
                      );
                    })
                    :
                    <MenuItem>n/a</MenuItem>
                  :
                  <MenuItem>n/a</MenuItem>
                }
              </TextField>
            </Grid>

            {data.variety && varieties[data.variety].lotNo && <Grid item xs={2} md={1}>
              <TextField
                id={`lotNo-${rowInd}`}
                label="Lot No."
                value={varieties[data.variety].lotNo}
                disabled
                // onChange={onChange('tag')}
                margin="dense"
                fullWidth
              />
            </Grid>}
            <Grid item xs={4} md={2}>
              <FormControl
                required
                disabled={!data.variety}
                margin="dense"
                fullWidth
              >
                <InputLabel htmlFor="growthCycle">Growth Cycle</InputLabel>
                <Select
                  value={this.state.menuOpen ? '' : (data.growthCycleID || data.growthCycle)}
                  onChange={onChange('growthCycle')}
                  // onBlur={onChange('growthCycle',true)}
                  open={this.state.menuOpen}
                  onOpen={this.onOpen}
                  onClose={this.onClose}
                  inputProps={{
                    id: `growthCycle-${rowInd}`,
                    name: 'growthCycle'
                  }}
                >
                  {data.variety ? varieties[data.variety].growthCycles ? varieties[data.variety].growthCycles.map(gcID => {
                    const growthCycle = growthCycles[gcID];
                   if(growthCycle && !growthCycle.isArchived) return (
                      <MenuItem key={gcID} value={gcID} className={classes.growthCycleMenuItem}>
                        {this.state.menuOpen ?
                          <GrowthCycleCard
                            growthCycle={growthCycle}
                          />
                          :
                          growthCycle.name
                        }
                      </MenuItem>
                    );
                  })
                    :
                    <MenuItem>n/a</MenuItem>
                    :
                    <MenuItem>n/a</MenuItem>
                  }
                </Select>
              </FormControl>
            </Grid>

            {growthCycles[data.growthCycle] && growthCycles[data.growthCycle].growMedia !== 'Rockwool' && <Grid item xs={3} md={2}>
              <TextField
                required
                id={`Qty-to-Seed-${rowInd}`}
                label="Number of Trays"
                value={data.QtyToSeed || ""}
                onChange={onChange('QtyToSeed')}
                // onBlur={onChange('QtyToSeed',true)}
                margin="dense"
                type="number"
              />

            </Grid>}

            <Grid item xs={4} md={2}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  disabled={!data.growthCycle}
                  required
                  label="Seed Date"
                  name="seedDate"
                  error={data.errors.seedDate}
                  helperText={data.errorMessages.seedDate}
                  value={data.seedDate}
                  format="MM/DD/YYYY"
                  // mask={value =>
                  //   value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : []
                  // }
                  onChange={(date) => onChange('seedDate')({ target: { value: date } })}
                  // onBlur={(date) => onChange('seedDate',true)({ target: { value: date } })}
                  // onBlur={(e) => formatDate(e, 'seedDate')({ target: { value: data.seedDate } })}
                  animateYearScrolling
                  autoOk
                  clearable
                  margin="dense"
                  fullWidth
                // keyboard
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={4} md={2}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  required
                  label="Start Date"
                  error={data.errors.initiationDate}
                  helperText={data.errorMessages.initiationDate}
                  value={data.initiationDate}
                  format="MM/DD/YYYY"
                  mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                  onChange={(date) => onChange('initiationDate')({ target: { value: date } })}
                  // onBlur={(date) => onChange('initiationDate',true)({ target: { value: date } })}
                  animateYearScrolling
                  autoOk
                  clearable
                  margin="dense"
                  fullWidth
                  // keyboard
                  InputLabelProps={{
                    classes: {
                      error: classes.warningLabel
                    }
                  }}
                  InputProps={{
                    classes: {
                      error: classes.warningInput
                    }
                  }}
                  FormHelperTextProps={{
                    classes: {
                      error: classes.warningLabel
                    }
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            
            
            <Grid item xs={4} md={2}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  required
                  label="Harvest Date"
                  value={data.harvestDate}
                  format="MM/DD/YYYY"
                  mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                  onChange={(date) => onChange('harvestDate')({ target: { value: date } })}
                  // onBlur={(date) => onChange('harvestDate',true)({ target: { value: date } })}
                  animateYearScrolling
                  autoOk
                  clearable
                  margin="dense"
                  fullWidth
                // keyboard
                />
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid item xs={4} md={2} style={{ marginTop: data.assignUser ? 3 : 0 }}>
              {/* <FormControl className={classes.formControl}> */}
              {data.assignUser ?
                <div>
                  <InputLabel id="demo-simple-select-label" style={{ fontSize: 12 }}>Assign User</InputLabel>
                  <Select
                    // labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    renderValue={() => renderName(data.assignUser)}
                    value={data.assignUser.firstName + " " + data.assignUser.lastName}
                    style={{ minWidth: '100%' }}
                    onChange={onChange('assignUser')}
                    // onBlur={onChange('assignUser',true)}
                  >
                    {this.state.userList.map(user => (
                      <MenuItem key={user.id} value={user}>{user.firstName + ' ' + user.lastName}</MenuItem>
                    ))}
                  </Select>
                </div> :

                <FormControl
                  required
                  margin="dense"
                  fullWidth
                >
                  <InputLabel id="demo-simple-select-label">Assign User</InputLabel>
                  <Select
                    // labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={data.assignUser}
                    onChange={onChange('assignUser')}
                    // onBlur={onChange('assignUser',true)}

                  >
                    {this.state.userList.map(user => (
                      <MenuItem key={user.id} value={user}>{user.firstName + ' ' + user.lastName}</MenuItem>
                    ))}
                  </Select>
                </FormControl>}
            </Grid>

            {data.growthCycle && growthCycles[data.growthCycle].growMedia === 'Rockwool' ? <FormControl required className={classes.formControl}>
              <InputLabel id="demo-simple-select-label">Prop Location</InputLabel>
              <Select
                // labelId="demo-simple-select-label"
                id="demo-simple-select"
                disabled={!data.seedDate}
                value={data.propLocation.farm}
                onChange={onChange('PropFarm')}
                // onBlur={onChange('PropFarm',true)}
              >
                {(allFarms || []).map((resp) => (
                  <MenuItem value={resp.id}>{resp.name}</MenuItem>
                ))}
              </Select>
            </FormControl> : null}

            {data.growthCycle && growthCycles[data.growthCycle].growMedia === 'Rockwool' ? <FormControl required className={classes.formControl}>
              <InputLabel id="demo-simple-select-label">Prop Pond</InputLabel>
              <Select
                // labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={data.propLocation.pond}
                disabled={!data.propLocation.farm}
                onChange={onChange('PropPond', false,propFarm ? propFarm.ponds : [])}
                // onBlur={onChange('PropPond', true,propFarm ? propFarm.ponds : [])}
                >
                {propFarm && (propFarm.ponds || []).map((each) => (
                  <MenuItem value={each.id}>{each.name}</MenuItem>
                ))}
              </Select>
            </FormControl> : null}

            {data.growthCycle && growthCycles[data.growthCycle].growMedia !== 'Rockwool' ? <FormControl required className={classes.formControl}>
              <InputLabel id="demo-simple-select-label">Finishing Location</InputLabel>
              <Select
                // labelId="demo-simple-select-label"
                id="demo-simple-select"
                disabled={!data.seedDate}
                value={growthCycles[data.growthCycle].growMedia === 'Rockwool' ? data.finishingLocation.farm : data.propLocation1.farm}
                onChange={onChange('FinishingLocation')}
                // onBlur={onChange('FinishingLocation',true)}
              >
                {(allFarms || []).map((resp) => (
                  <MenuItem value={resp.id}>{resp.name}</MenuItem>
                ))}
              </Select>
            </FormControl> : null}
            {data.growthCycle && growthCycles[data.growthCycle].growMedia !== 'Rockwool' && <Grid style={{ marginTop: 25, marginLeft: 10 }}>
              <Button onClick={this.openFinishingPond} variant="outlined" style={{ height: 35 }}>Set Finishing Ponds</Button>
            </Grid>}

          </Grid> {/* end first row */}

          {growthCycles[data.growthCycle] && growthCycles[data.growthCycle].growMedia === 'Rockwool' && (
            <>
              <Grid container className={classes.formRow} spacing={16}>
                <Grid item xs={4} md={2}>
                  <TextField
                    required
                    id={`germCells-${rowInd}`}
                    label="Cells"
                    value={data.germCells || ""}
                    onChange={onChange('germCells')}
                    // onBlur={onChange('germCells',true)}
                    margin="dense"
                    type="number"
                    className={classes.widthFull}
                  />
                </Grid>
                <Grid container xs={1} md={1} className={classes.flex}  alignItems="flex-end">
                <Typography
                    variant="body2"
                    className={classNames(classes.infoBtm, { [classes.cellError]: data.errors.cells })}
                  >
                    HD : 
                  </Typography>
                </Grid>
                <Grid item xs={4} md={2}>
                  <TextField
                    required
                    id={`hdRafts-${rowInd}`}
                    label="Rafts"
                    value={data.hdRafts || ""}
                    margin="dense"
                    type="number"
                    className={classes.widthFull}
                    // onChange={onChange('hdRafts')}
                  />
                </Grid>
                <Grid item xs={4} md={2}>
                  <TextField
                    required
                    id={`hdRemainder-${rowInd}`}
                    label="Remainder."
                    value={data.hdRemainder}
                    margin="dense"
                    type="number"
                    className={classes.widthFull}
                  />
                </Grid>
                <Grid item xs={4} md={2}>
                  <TextField
                    required
                    id={`hdEndRafts-${rowInd}`}
                    label="Expected Rafts"
                    value={data.hdEndRafts || ""}
                    margin="dense"
                    type="number"
                    className={classes.widthFull}
                    onChange={onChange('hdEndRafts')}
                    // onBlur={onChange('hdEndRafts',true)}
                  />
                </Grid>
              </Grid>
              <Grid container className={classes.formRow} spacing={16}>
                <Grid item xs={4} md={2}>
                </Grid>
                <Grid xs={1} md={1} className={classes.flex}  alignItems="flex-end">
                <Typography
                    variant="body2"
                    className={classNames(classes.infoBtm, { [classes.cellError]: data.errors.cells })}
                  >
                    FS : 
                  </Typography>
                </Grid>
                <Grid item xs={4} md={2}>
                  <TextField
                    required
                    id={`fsRafts-${rowInd}`}
                    label="Rafts"
                    value={data.fsRafts || ""}
                    margin="dense"
                    className={classes.widthFull}
                    type="number"
                    // onChange={onChange('fsRafts')}
                  />
                </Grid>
                <Grid item xs={4} md={2}>
                  <TextField
                    required
                    id={`fsRemainder-${rowInd}`}
                    label="Remainder."
                    value={data.fsRemainder}
                    margin="dense"
                    className={classes.widthFull}
                    type="number"
                  />
                </Grid>
                <Grid item xs={4} md={2}>
                  <TextField
                    required
                    id={`fsEndRafts-${rowInd}`}
                    label="Expected Rafts"
                    value={data.fsEndRafts || ""}
                    margin="dense"
                    className={classes.widthFull}
                    type="number"
                    onChange={onChange('fsEndRafts')}
                    // onBlur={onChange('fsEndRafts',true)}
                  />
                </Grid>
              </Grid>
              <Grid container className={classes.formRow} spacing={16}>
              {data.growthCycle ? <FormControl required className={classes.formControl}>
              <InputLabel id="demo-simple-select-label">Finishing Location</InputLabel>
              <Select
                // labelId="demo-simple-select-label"
                id="demo-simple-select"
                disabled={!data.seedDate}
                value={growthCycles[data.growthCycle].growMedia === 'Rockwool' ? data.finishingLocation.farm : data.propLocation1.farm}
                onChange={onChange('FinishingLocation')}
                // onBlur={onChange('FinishingLocation',true)}
              >
                {(allFarms || []).map((resp) => (
                  <MenuItem value={resp.id}>{resp.name}</MenuItem>
                ))}
              </Select>
            </FormControl> : null}
            {data.growthCycle && <Grid style={{ marginTop: 25, marginLeft: 10 }}>
              <Button onClick={this.openFinishingPond} variant="outlined" style={{ height: 35 }}>Set Finishing Ponds</Button>
            </Grid>}
              </Grid>
            </>
          )}  

          <Grid container className={classes.formRow} spacing={16} alignItems="flex-end">
            <Grid item xs={12} md={3}>
              <TextField
                id={`tag-${rowInd}`}
                label="Tag"
                value={data.tag}
                onChange={onChange('tag')}
                // onBlur={onChange('tag',true)}
                margin="dense"
                fullWidth
              />

            </Grid>
            <Grid item xs={12} md={9}>
              <Grid container className={classes.infoContainer} alignItems="center">
                <Tooltip title={data.errorMessages.cells}>
                  <Typography
                    variant="body2"
                    className={classNames(classes.infoItem, { [classes.cellError]: data.errors.cells })}
                  >
                    Start Cells:<span className={classes.info}>{data.cells || 'n/a'}</span>
                  </Typography>
                </Tooltip>
                <Typography variant="body2" className={classes.infoItem}>Batch Weight:<span className={classes.info}>{data.totalBatchWeight || 'n/a'}</span></Typography>
                <Typography variant="body2" className={classes.infoItem}>Final Rafts:<span className={classes.info}>{data.endRafts || 'n/a'}</span></Typography>
                {data.isPerpetual &&
                  <Typography variant="body2" className={classes.infoItem}>Total Batches:<span className={classes.info}>{data.totalBatches || 'n/a'}</span></Typography>
                }
                {data.isPerpetual &&
                  <Typography variant="body2" className={classes.infoItem}>Total Projected Weight:<span className={classes.info}>{data.totalProjectedWeight || 'n/a'}</span></Typography>
                }
              </Grid> {/* end info row */}
            </Grid>
          </Grid> {/* end middle row */}

          <Grid container className={classNames(classes.formRow, classes.perpetualRow)} spacing={16} alignItems="center">
            <Grid item xs={4} sm={4} md={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={data.isPerpetual}
                    onChange={onChange('isPerpetual')}
                    // onBlur={onChange('isPerpetual',true)}
                    value="isPerpetual"
                  />
                }
                label="Perpetual"
              />
            </Grid>

            {data.isPerpetual &&
              <Grid item xs={4} sm={4} md={2} style={{ marginTop: '-1rem', marginLeft: '2rem' }}>
                <FormControl required style={{ minWidth: 165 }}>
                  <InputLabel id="demo-simple-select-label">Seed Date</InputLabel>
                  <Select
                    // labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={data.RepeatType}
                    // disabl ed={!data.HDFarm}
                    onChange={onChange('RepeatType')}
                    // onBlur={onChange('RepeatType',true)}
                  >
                    {REPEAT_TYPE.map(each => (
                      <MenuItem value={each.value}>{each.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>}

            {/* Do not Remove urgent code */}

            {/* {data.isPerpetual && data.RepeatType !== 1 &&
              <Grid item xs={12} sm={12} md={6}>
                {days.map(day => (
                  <FormControlLabel
                    key={`${rowInd}-${day}`}
                    control={
                      <Checkbox
                        checked={data.perpetualSeedDays[day]}
                        onChange={onChange(day, 'perpetualSeedDays')}
                        value="isPerpetual"
                      />
                    }
                    label={day}
                  />
                ))}
              </Grid>
            } */}
            {data.isPerpetual &&
              <Grid item xs={4} sm={4} md={2}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DatePicker
                    required
                    label="End Date"
                    value={data.perpetualEndDate}
                    format="MM/DD/YYYY"
                    mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                    onChange={(date) => onChange('perpetualEndDate')({ target: { value: date } })}
                    // onBlur={(date) => onChange('perpetualEndDate',true)({ target: { value: date } })}
                    animateYearScrolling
                    autoOk
                    clearable
                    margin="dense"
                    style={{ marginTop: '-0.6rem', width: '80%' }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            }
            {data.isPerpetual &&
              <Grid item xs={12} sm={12} md={6}>
                {days.map(day => (
                  <FormControlLabel
                    key={`${rowInd}-${day}`}
                    control={
                      <Checkbox
                        checked={data.perpetualStartDays[day]}
                        onChange={onChange(day, false,'perpetualStartDays')}
                        // onBlur={onChange(day, true,'perpetualStartDays')}
                        value="isPerpetual"
                      />
                    }
                    label={day}
                  />
                ))}
              </Grid>
            }
          </Grid> {/* end final row */}

          <Grid container className={classes.buttonContainer} spacing={16} alignItems="end">
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={()=>{
                const newClone = {...data, isClone:true};
                this.props.addRow(newClone);
              }}
            >
              Clone
            </Button>
          </Grid>

        </form>

        <Modal
          aria-labelledby="modal-title"
          open={this.state.modalOpen}
          onClose={this.closeModal}
        >
          {this.renderModal()}
        </Modal>

        <Tooltip placement="top" title="Delete Row">
          <i className={`far fa-times-circle fa-2x ${classes.deleteRowButton}`} onClick={deleteRow}></i>
        </Tooltip>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={this.state.snackbarOpen}
          autoHideDuration={3000}
          onClose={this.closeSnackBar}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{this.state.snackbarMessage}</span>}
          action={this.state.snackBarUndo ?
            [
              <Button key="undo" color="primary" size="small" onClick={this.handleUndo}>UNDO</Button>,
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                className={classes.close}
                onClick={this.closeSnackBar}
              >
                <i className="fas fa-times"></i>
              </IconButton>
            ]
            :
            [
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                className={classes.close}
                onClick={this.closeSnackBar}
              >
                <i className="fas fa-times"></i>
              </IconButton>
            ]
          }
        />
      </Paper>
    );
  }
}

NewBatchFormRow.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  data: PropTypes.object.isRequired,
  rowInd: PropTypes.number.isRequired,
  crops: PropTypes.object.isRequired,
  varieties: PropTypes.object.isRequired,
  growthCycles: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  deleteRow: PropTypes.func.isRequired,
  users: PropTypes.object.isRequired,

};

export default withStyles(styles)(NewBatchFormRow);

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { getRGBFromHex, getBrightnessFromHex, lightenDarkenColor, deepCopyObject, fix2 } from '../utils/utils';
import classNames from 'classnames';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';
import APP_SETTINGS from '../utils/appSettings';
import { Typography } from '@material-ui/core';

const borderWidth = 0.25;
const columns = APP_SETTINGS.PLOTS.COLUMNS;
const rows = APP_SETTINGS.PLOTS.ROWS;

const stages = APP_SETTINGS.STAGES;

const styles = theme => {
  const borderColor = theme.palette.grey[500];
  return ({
    poolContainer: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    squareContainer: {
      position: 'relative',
      border: `${borderWidth}px solid ${borderColor}`,
      fontSize: '0.8rem',
      overflow: 'hidden',
    },
    square: {
      position: 'absolute',
      height: '100%',
      width: '100%',
      top: 0,
      left: 0,
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      padding: 3
    },
    white: {
      color: '#fff'
    }
  });
};



const PoolMapSquare = props => {

  const { className, date, data, seedDate } = props;

  console.log('PoolMapSquare', props)

  let newArr = new Array();
  let arrDetails = new Array();


  if (data) {
    data.forEach((each, i) => {
      const { color, variety } = each.graphic;
      const ceil = Math.ceil(each.occupy / 0.25);
      const floor = Math.floor(each.occupy / 0.25);
      const remaining = fix2(each.occupy % 0.25);

      if (each.occupy) {
        arrDetails.push({ variety, occupy: fix2(each.occupy) })
      }

      for (let j = 1; j <= ceil; j++) {
        if (j <= floor) {
          newArr.push({ color, variety, occupy: 0.25 })
        }
        else {
          let foundIndex = ''
          for (let index = 0; index < newArr.length; index++) {
            if (fix2(0.25 - newArr[index].occupy) >= remaining) {
              if (!foundIndex) foundIndex = index;
            }
          }
          if (foundIndex) {
            newArr[foundIndex] = {
              ...newArr[foundIndex],
              occupy: fix2(0.25 - each.occupy) - remaining
            }
          }
          else newArr.push({ color: '#404040', variety: '', occupy: remaining })
        }
      }



    })
  }

  return (
    <Tooltip title={
      <>
        <Typography style={{ color: '#fff', fontSize: 12 }}>Seed date : {seedDate ? moment(seedDate).format('YYYY-MM-DD') : ''}</Typography>
        <Typography style={{ color: '#fff', fontSize: 12 }}>Harvest date : {date}</Typography>
        <div className="tooltip_content">
          <ul className='tooltip_ul'>
            {
              new Array(4).fill(1).map((each, i) => {
                if (newArr[i]) return (
                  <>
                    <li style={{ backgroundColor: newArr[i].color }} />
                  </>
                )
                else {
                  return (<li />)
                }
              })
            }
          </ul>

          <div className="right_content">
            {[...(arrDetails || [])].map(each => {
              return (<p>{each.variety} {fix2(fix2(each.occupy) * 100)}%</p>)
            })}
          </div>
        </div>
      </>
    }>
      <div className={className} >
      </div>
    </Tooltip>
  );
};

PoolMapSquare.propTypes = {
  className: PropTypes.string,
  date: PropTypes.string,
  variety: PropTypes.string,
  size: PropTypes.string.isRequired
};

class PoolMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      containerHeight: 0
    };
    this.containerRef = React.createRef();
    this.updateContainerHeight = this.updateContainerHeight.bind(this);
  }

  componentDidMount() {

    if (this.props.orientation === 'horizontal' && this.containerRef) {
      this.updateContainerHeight();
      window.addEventListener("resize", this.updateContainerHeight);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.orientation !== this.props.orientation) {
      if (this.props.orientation === 'horizontal') {
        this.updateContainerHeight();
        window.addEventListener("resize", this.updateContainerHeight);
      } else {
        window.removeEventListener("resize", this.updateContainerHeight);
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateContainerHeight);
  }

  updateContainerHeight() {
    const containerHeight = columns / rows * this.containerRef.current.clientWidth;
    this.setState({ containerHeight });
  }

  compare = (a, b) => {
    // Use toUpperCase() to ignore character casing
    const bandA = Number(a.props.arr)
    const bandB = Number(b.props.arr)

    let comparison = 0;
    if (bandA > bandB) {
      comparison = 1;
    } else if (bandA < bandB) {
      comparison = -1;
    }
    return comparison;
  }

  render() {
    const { classes, className, plots, poolNumber, date, size, orientation, currentPond, currentFarm, allPerpitualBatches } = this.props;
    const plot = plots[moment(date).format()];

    const length = parseInt(Number(currentPond.pondType === 'landscape' ? currentPond.width : currentPond.length) / 12)
    const width = parseInt(Number(currentPond.pondType === 'landscape' ? currentPond.length : currentPond.width) / 12)

    // const totalSquares = columns * rows;
    const totalSquares = (parseInt(length / 4)) * (parseInt(width / 2))
    let columns = currentPond.pondType === 'landscape' ? parseInt(length / 4) : parseInt(width / 2)
    const plotsInPool = plot ? plot : [];
    let set = new Array();
    for (let i = 0; i < (plotsInPool.length > totalSquares ? plotsInPool.length : totalSquares); i++) {
      if (plotsInPool[i]) {
        const { finishingLocation, propLocation, propLocation1 } = plotsInPool[i].graphic;
        const { stage, data } = plotsInPool[i];
        let endRaft = 0;

        if (data.growthCycle.growMedia !== 'Rockwool') {
          propLocation1.pond[stage].forEach((each, k) => {
            if ((each.pond === currentPond.id)) {
              endRaft += each.noOfRaft ? fix2(Number(each.noOfRaft) / 4 || 0) : 0;
            }
          })
        }
        else if (stage === stages.PROP) {
          if (propLocation && (propLocation.pond === currentPond.id)) {
            endRaft = fix2((data.growthCycle.germ.cells / 200) / 4 || 0);
          }
        }
        else if (finishingLocation && finishingLocation.pond && finishingLocation.pond[stage]) {
          finishingLocation.pond[stage].forEach((each, k) => {
            if ((each.pond === currentPond.id)) {
              endRaft += fix2(each.noOfRaft || 0);
            }
          })
        }

        if (endRaft) {
          const toLoop = Math.floor(endRaft);
          const difference = fix2(endRaft - toLoop);

          let sum = 1;
          if (set[set.length - 1] && set[set.length - 1].length) {
            sum = set[set.length - 1].reduce((acc, a) => {
              return acc + parseFloat(a.occupy || 0)
            }, 0)
          }
          if (sum === 1) {
            //last one is full
            for (let j = 0; j < toLoop; j++) {
              set.push([{
                batchID: plotsInPool[i].batchID,
                graphic: plotsInPool[i].graphic,
                stage: plotsInPool[i].stage,
                occupy: 1
              }])
            }
            if (difference) {
              set.push([{
                batchID: plotsInPool[i].batchID,
                graphic: plotsInPool[i].graphic,
                stage: plotsInPool[i].stage,
                occupy: difference
              }])
            }

          }
          else {
            // last one have some space
            if (sum && sum < 1) {
              if ((set[set.length - 1].occupy + difference) >= 1) {
                // last one is overfilled with difference
                let copyData = [...set[set.length - 1]];
                copyData.push({
                  batchID: plotsInPool[i].batchID,
                  graphic: plotsInPool[i].graphic,
                  stage: plotsInPool[i].stage,
                  occupy: fix2(1 - set[set.length - 1][0].occupy)
                });
                set[set.length - 1] = copyData;
                //
                for (let j = 0; j < toLoop; j++) {
                  set.push([{
                    batchID: plotsInPool[i].batchID,
                    graphic: plotsInPool[i].graphic,
                    stage: plotsInPool[i].stage,
                    occupy: 1
                  }])
                }
                //
                set.push([{
                  batchID: plotsInPool[i].batchID,
                  graphic: plotsInPool[i].graphic,
                  stage: plotsInPool[i].stage,
                  occupy: (set[set.length - 1].occupy + difference) - 1
                }])
              }
              else {
                let copyData = [...set[set.length - 1]];
                let newToLoop = parseFloat(parseFloat(endRaft) - fix2(1 - sum)).toFixed(2);
                //set[set.length - 1][0].occupy
                //fill previous space
                if ((1 - sum) >= fix2(endRaft || 0)) {
                  copyData.push({
                    batchID: plotsInPool[i].batchID,
                    graphic: plotsInPool[i].graphic,
                    stage: plotsInPool[i].stage,
                    occupy: fix2(endRaft || 0)
                  });
                  set[set.length - 1] = copyData;
                }
                else {
                  copyData.push({
                    batchID: plotsInPool[i].batchID,
                    graphic: plotsInPool[i].graphic,
                    stage: plotsInPool[i].stage,
                    occupy: fix2(1 - sum)
                  });
                  set[set.length - 1] = copyData;


                  let newDifference = parseFloat(parseFloat(newToLoop) - Math.floor(newToLoop)).toFixed(2)
                  for (let j = 0; j < Math.floor(newToLoop); j++) {
                    set.push([{
                      batchID: plotsInPool[i].batchID,
                      graphic: plotsInPool[i].graphic,
                      stage: plotsInPool[i].stage,
                      occupy: 1
                    }])
                  }
                  parseFloat(newDifference) && set.push([{
                    batchID: plotsInPool[i].batchID,
                    graphic: plotsInPool[i].graphic,
                    stage: plotsInPool[i].stage,
                    occupy: parseFloat(newDifference).toFixed(2)
                  }])
                }
              }
            }
          }
        }
      }
    }
    // for same batch merge
    set = set.map(raft => {
      if (raft && raft.length) {
        // check valid raft
        if (raft.length === 1) {//for single batch in raft 
          return raft;
        }

        let same = true;
        let batchID = raft[0].batchID
        let newSum = 0;
        raft.forEach(each => {
          if (each.batchID !== batchID) {
            same = false;
          }
          newSum += each.occupy
        })

        if (same) {
          // for merge multiple batch merge
          let clone = { ...raft[0] };
          clone.occupy = newSum;
          return [clone]
        }
        else {
          // for defferent batches
          return raft
        }
      }

    })

    let setQuares = new Array();

    for (let i = 0; i < totalSquares; i++) {
      let raft = set[i];

      if (raft) {

        const multiple = raft.length > 1;
        const { isCommitted, varietyCode: name, harvestDate } = raft[0].graphic;
        let background = (multiple || (!multiple && raft[0].occupy < 1)) ? '#404040' : raft[0].graphic.color;
        let rgbColor;
        let maskColor;

        rgbColor = getRGBFromHex(background);
        maskColor = isCommitted ? `rgba(${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b}, 1)` : `rgba(${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b}, 0.4)`;
        background = `linear-gradient(${maskColor}, ${maskColor}), url("/images/45-degree-fabric-light.png") no-repeat center center`;

        const brightness = getBrightnessFromHex(background);
        const textColor = brightness > 125 ? 'black' : 'white';

        console.log('setQuares', raft)

        setQuares.push(
          <div
            key={i}
            sort={0}
            className={classes.squareContainer}
            style={{
              background,
              color: textColor,
              paddingBottom: `${1 / columns * 100}%`,
              flexBasis: `${1 / columns * 100}%`,
            }}
          >
            <PoolMapSquare
              date={raft[0].graphic.harvestDate || ''}
              seedDate={raft[0].graphic.seedDate || ''}
              variety={raft[0].graphic.variety || ''}
              className={classes.square}
              size={size}
              background={background}
              data={raft}
            />
            {size === 'large' && <p style={{ position: 'absolute', zIndex: 2, marginTop: 7 }}>{name}</p>}
          </div>
        );

      }
      else {
        setQuares.push(
          <div
            key={i}
            sort={1}
            className={classes.squareContainer}
            style={{
              paddingBottom: `${1 / columns * 100}%`,
              flexBasis: `${1 / columns * 100}%`,
            }}
          >
            <PoolMapSquare
              className={classes.square}
              size={size}
            />
          </div>
        )
      }
    }
    return (
      <div
        ref={this.containerRef}
        className={classNames(classes.poolContainer, className)}
        style={{
          flexDirection: 'row',
          height: 'auto',
        }}
      >
        {setQuares}
      </div>
    );
  }
}

PoolMap.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  plots: PropTypes.object.isRequired,
  allPerpitualBatches: PropTypes.object.isRequired,
  poolNumber: PropTypes.number.isRequired,
  date: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  orientation: PropTypes.oneOf(['horizontal', 'vertical']).isRequired
};

export default withStyles(styles)(PoolMap);

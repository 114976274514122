import React from 'react';
import { connect } from 'react-redux';
import { Collapse, Divider, Grid, ListItem, Modal, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { addEditBatch } from '../store/actions/adders';
import RatingModal from '../components/RatingModal';
import APP_SETTINGS from '../utils/appSettings';

const {TASK_NAMES} = APP_SETTINGS

const styles = theme => ({
  paper: {
    width: '100%',
    marginBottom: '2rem'
  },
  container: {
    width: '100%',
  },
  paper: {

  },
  modal: {

    width: '30%',
    // height: '30%',
    marginTop: '15%',
    marginLeft: '35%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    '&:focus': {
      outline: 'none'
    },
    overflow: 'auto',
  },
  rate: {
    height: 50,
    width: 50,
    borderRadius: 25,
    backgroundColor: '#42b883',
    cursor: 'pointer',
  },
  rateNumber: {
    color: '#fff',
    marginTop: 3
  },
})

class Ratings extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      batches: new Array(),
      collapse: -1,
      openRatingModal: false,
      currentBatch: null,
      currentTask: null
    }
  }

  componentDidMount() {
    const { batches } = this.props;
    this.setState({ batches: Object.keys(batches) })
  }

  componentWillReceiveProps(nextProps, nextState) {

    // if (Object.keys(nextProps.batches).length !== Object.keys(this.props.batches).length) {
      setTimeout(() => {
        this.setState({ batches: Object.keys(nextProps.batches) })
      }, 1);
    // }

  }

  getStartBatchDate = (batch, showSeedDate = false) => {
    let res = '';
    if (batch && batch.tasks && batch.tasks.seedAndStart && batch.tasks.seedAndStart.date) {
      res = batch.tasks.seedAndStart.date;
    } else if (showSeedDate && batch && batch.tasks && batch.tasks.seed && batch.tasks.seed.date) {
      res = batch.tasks.seed.date;
    } else if (showSeedDate === false && batch && batch.tasks && batch.tasks.start && batch.tasks.start.date) {
      res = batch.tasks.start.date;
    }
    return res;
  }

  submitRating = async (rate) => {
    let { currentBatch, currentTask } = this.state;
    this.setState({ openRatingModal: false }, async () => {
      currentBatch.tasks[currentTask].rating = rate;
      await addEditBatch({ id: currentBatch.id, batch: currentBatch });
    })
  }



  render() {
    const { batches, collapse, openRatingModal, currentBatch, currentTask } = this.state;
    const { crops, varieties, users, classes } = this.props;

    const handleClose = () => this.setState({ openRatingModal: false })
    return (
      <Grid xs={12}>
        <Paper>
          <ListItem>
            <Grid container direction={'row'}>
              <Typography variant={'subtitle1'} style={{ marginLeft: '2.5%', width: 80 }}>GRF Lot</Typography>
              <Typography variant={'subtitle1'} style={{ marginLeft: '7.5%', width: 80 }}>Crop</Typography>
              <Typography variant={'subtitle1'} style={{ marginLeft: '7.5%', width: 120 }}>Variety</Typography>
              <Typography variant={'subtitle1'} style={{ marginLeft: '7.5%', width: 80 }}>Seed Date</Typography>
              <Typography variant={'subtitle1'} style={{ marginLeft: '7.5%', width: 80 }}>Start Date</Typography>
              <Typography variant={'subtitle1'} style={{ marginLeft: '7.5%', width: 60 }}>Seed Lot</Typography>
              <Typography variant={'subtitle1'} style={{ marginLeft: '7.5%', width: 60 }}>Rating</Typography>
            </Grid>
          </ListItem>
          <Divider />
          {(batches && Object.keys(users).length) ? batches.map((each, i) => {
            let batch = this.props.batches[each];
            batch.id = each;
            let count = 0
            const overallRating = Object.keys(batch.tasks).reduce((acc, curr) => {
              if (batch.tasks[curr].rating) {
                ++count;
              }
              return acc + batch.tasks[curr].rating
            }, 0);

            return (
              <Grid xs={12}>
                <ListItem button key={i} onClick={() => this.setState({ collapse: collapse === i ? -1 : i })}>
                  <Grid container direction={'row'}>
                    <Typography style={{ marginLeft: '2.5%', width: 80 }} variant={'subtitle2'}>{batch.GRFlot}</Typography>
                    <Typography style={{ marginLeft: '7.5%', width: 80 }} variant={'subtitle2'}>{crops[batch.crop].name}</Typography>
                    <Typography style={{ marginLeft: '7.5%', width: 120 }} variant={'subtitle2'}>{varieties[batch.variety].name}</Typography>
                    <Typography style={{ marginLeft: '7.5%', width: 80 }} variant={'subtitle2'}>{this.getStartBatchDate(batch, true)}</Typography>
                    <Typography style={{ marginLeft: '7.5%', width: 80 }} variant={'subtitle2'}>{this.getStartBatchDate(batch)}</Typography>
                    <Typography style={{ marginLeft: '7.5%', width: 40 }} variant={'subtitle2'}>{batch.seedLot}</Typography>
                    <Typography style={{ marginLeft: '10%', width: 40 }} variant={'subtitle2'}>{count ? Number(overallRating / count).toFixed(1): 'N/A'}</Typography>
                  </Grid>
                </ListItem>
                <Collapse in={i == collapse}>
                  <Typography style={{ marginLeft: '3.75%', marginTop: 15, fontWeight: 'bolder' }} variant={'subtitle2'}>Ratings</Typography>

                  {Object.keys(batch.tasks).map((task, j) => {
                    const individualTask = batch.tasks[task];
                    console.log('individualTaskindividualTask',batch.tasks)
                    let disable = false
                    if((task === TASK_NAMES.SEED_AND_START) || (task === TASK_NAMES.START) || (task === TASK_NAMES.SEED)) disable = true; 
                    return (
                      <TableRow style={{ marginTop: j === 0 ? '2%' : 0}} >
                        <ListItem onClick={() => {!disable && this.setState({ openRatingModal: true, currentBatch: batch, currentTask: task })}} button style={{ display: 'flex', flexDirection: 'row', marginLeft: '12%', }}>
                          <Typography style={{ width: 100 ,marginLeft:'1rem' }}>{task}</Typography>
                          <Typography style={{ marginLeft: 50 }}>{individualTask.rating || 'N/A'}</Typography>
                        </ListItem>
                      </TableRow>
                    )
                  })}
                </Collapse>
              </Grid>
            )

          })
            : null
          }
        </Paper>

        <Modal
          open={openRatingModal}
          onClose={handleClose}
          aria-labelledby="modal-title"
        >
          <RatingModal
            {...this.props}
            currentBatch={currentBatch}
            currentTask={currentTask}
            closeModal={() => this.setState({ openRatingModal: false })} />
        </Modal>

      </Grid>
    )

  }
}


Ratings.propTypes = {
  classes: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    crops: state.crops,
    varieties: state.varieties,
    growthCycles: state.growthCycles,
    batches: state.batches,
    plots: state.plots,
    varietyLegend: state.varietyLegend,
    misc: state.misc,
    users: state.users
  }
}

export default withStyles(styles)(connect(mapStateToProps)(Ratings));

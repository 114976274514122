export function checkHasBeenUsed({collectionSingular, collectionItemID, batches, currentBatchID}) {
  let itemHasBeenUsed = false;
  
  if (collectionSingular === 'growthCycle') { collectionSingular = 'growthCycleID'; }
  Object.keys(batches).forEach(batchID => {
    if (batchID === currentBatchID) {return;}; // exclude the batch passed in arguments
    if (batches[batchID][collectionSingular] === collectionItemID) {
      itemHasBeenUsed = true;
    }
  });

  return itemHasBeenUsed;
}
import React from 'react';
import { connect } from 'react-redux';
import { Button, CircularProgress, FormControl, Grid, Input, ListItemIcon, MenuItem, Modal, Paper, Select, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs, TextField, Tooltip, Typography, withStyles } from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import { checkNaN, fix2, sortFunction } from '../utils/utils';
import moment from 'moment';
import { CSVLink } from "react-csv";
import { green, red } from '@material-ui/core/colors';
import PestListModal from '../components/PestListModal';
import { addPestType, removePestType } from '../store/actions/adders';
import APP_SETTINGS from '../utils/appSettings';

const { SENSOR_URL, SENSOR_APIS, token, STAGES } = APP_SETTINGS;

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    // marginLeft: theme.spacing.unit,
    // marginRight: theme.spacing.unit,
    width: '175px',
  },
  grid: {
    marginTop: '25px',
    marginLeft: '15px'
  },
  grid1: {
    marginTop: '20px'
  },
  formControl: {
    // margin: theme.spacing.unit,
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    width: '175px',
  },
  topButton: {
    marginRight: '1rem',
    maxHeight: '2.25rem'
  },
  buttonIcon: {
    marginRight: '0.6rem'
  },

  modal: {
    width: '40%',
    marginLeft: '30%',
    marginTop: '4%',
    marginBottom: '1%',
    minHeight: '80%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    '&:focus': {
      outline: 'none'
    },
    overflow: 'auto',
    maxHeight: '80%',
  },
  container1: {
    width: '100%',
  },
  sectionTitle: {
    marginBottom: '0.5rem'
  },
  actionIcons: {
    marginRight: '0.5rem',
    cursor: 'pointer',
    '&:last-child': {
      marginRight: 0
    },
    fontSize: '1.5rem'
  },
  red: {
    color: red[400]
  },
  green: {
    color: green[400]
  },
  space: {
    width: '175px',
    marginLeft: '15px'
  }
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
class Analytics extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date(),
      endDate: new Date(),
      startDate1: new Date(),
      endDate1: new Date(),
      dateRange: '1',
      isSelected: false,
      isSelected1: false,
      isSeperateByLoc: false,
      crop: '',
      variety: [],
      varietyOptVal: [],
      isVarietySelectAll: false,
      selectedFarm: [],
      sensorData: [],
      reportFormat: '1',
      ipmNote: '',
      table: [],
      tab: 0,
      averageData: {},
      loading: false,
      seedsPerWeek: '',
      errorMessage: ''
    }
  }

  componentDidMount() {
    // this.averageSensorData()
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevState.crop !== this.state.crop){
      this.renderVarietyVal(this.state.crop);
      this.setState({variety: [], isVarietySelectAll:false});
    }
  }

  csvLink = React.createRef();

  handleCheckBox = e => {
    this.setState({ isSelected: !this.state.isSelected });
  }
  handleCheckBox1 = e => {
    this.setState({ isSelected1: !this.state.isSelected1 });
  }
  handleSeperateByLoc = e => {
    this.setState({ isSeperateByLoc: !this.state.isSeperateByLoc });
  }


  handleChangeTab = (event, tab) => {
    this.setState({ tab });
  };

  matchExact(r, str) {
    var match = str ? str.toLowerCase().indexOf(r.toLowerCase()) : -1;
    return match !== -1;
  }

  SensorApi = async (data) => {


    return fetch(SENSOR_URL + SENSOR_APIS.aggregate, {
      method: 'POST',
      headers: {
        "Authorization": token,
        "Content-type": "application/json"
      },
      body: JSON.stringify(data)
    })
      .then((res) => res.json())
      .then((averageData = {}) => (averageData))
  }

  SensorApiMode1 = async ({ payload = {}, startRange, endRange, statisticType = 'averages' }) => {

    let url = SENSOR_URL + SENSOR_APIS.fromto
    url += moment(startRange).toISOString() + '/until/' + moment(endRange).toISOString() + `?statisticType=${statisticType}&intervalSize=1d`;
    return fetch(url, {
      method: 'POST',
      headers: {
        "Authorization": token,
        "Content-type": "application/json"
      },
      body: JSON.stringify(payload)
    })
      .then((res) => res.json())
      .then((allData = {}) => (allData))
  }


  averageSensorData = async () => {


    const { dateRange, startDate, endDate, tab, selectedFarm, seedsPerWeek } = this.state;

    if (seedsPerWeek === '' && !tab) {
      return this.setState({ errorMessage: '*Please enter mandatory feilds.' })
    }

    this.setState({ loading: true })

    let startRange = '';
    let endRange = dateRange === '4' ? moment(endDate) : moment();

    if (dateRange === '4') startRange = moment(startDate);
    else if (dateRange === '1') startRange = moment().subtract(3, 'months');
    else if (dateRange === '2') startRange = moment().subtract(6, 'months');
    else if (dateRange === '3') startRange = moment().subtract(1, 'year');


    // Do not delete

    // const data1 = {
    //   "checkIds": [
    //     "5fe51add-b394-4a25-91d5-14e805722bef"
    //   ],
    //   "statisticType": "averages",
    //   "intervalFrom": moment(startRange).toISOString() || "2021-04-04T00:00:00Z",
    //   "intervalTo": moment(endRange).toISOString() || "2022-02-02T00:00:00Z",
    //   "intervalSize": "1d",
    //   "cumulative": true,
    //   "sensorType": "PTH_pmc_MLX90614_SHT35",
    //   "organizationId": "hortamericas01"
    // }

    // const data2 = {
    //   "checkIds": [
    //     "ce2b4843-4226-49ca-bda2-349c14d2a470"
    //   ],
    //   "statisticType": "averages",
    //   "intervalFrom": moment(startRange).toISOString() || "2021-04-04T00:00:00Z",
    //   "intervalTo": moment(endRange).toISOString() || "2022-02-02T00:00:00Z",
    //   "intervalSize": "1d",
    //   "cumulative": true,
    //   "sensorType": "PAR_SQ500_a",
    //   "organizationId": "hortamericas01"
    // }
    // full data apiReponses

    let checkIds1 = [];
    let checkIds2 = [];

    if (selectedFarm.length) {
      selectedFarm.forEach(eachFarm => {
        this.props.farm[eachFarm].sensor1 && checkIds1.push(this.props.farm[eachFarm].sensor1);
        this.props.farm[eachFarm].sensor2 && checkIds2.push(this.props.farm[eachFarm].sensor2);
      })
    }
    else {
      for (const key3 in this.props.farm) {
        const eachFarm = this.props.farm[key3];
        eachFarm.sensor1 && checkIds1.push(eachFarm.sensor1);
        eachFarm.sensor2 && checkIds2.push(eachFarm.sensor2);
      }
    }

    let payload1 = {
      "checkIds": checkIds1,
      "organizationId": "hortamericas01"
    }

    let payload2 = {
      "checkIds": checkIds2,
      "organizationId": "hortamericas01"
    }

    let newAllData = await Promise.all([this.SensorApiMode1({ payload: payload1, startRange, endRange }), this.SensorApiMode1({ payload: payload2, startRange, endRange })])
    let minMaxData = await Promise.all([this.SensorApiMode1({ payload: payload1, startRange, endRange, statisticType: 'maxs' }), this.SensorApiMode1({ payload: payload1, startRange, endRange, statisticType: 'mins' })])

    // merging both responses
    let allData = {};
    for (const key in newAllData[0]) {
      if (newAllData[0][key] || newAllData[1][key]) {
        allData[key] = {
          ...newAllData[0][key],
          ...newAllData[1][key],
          maxTemp: minMaxData[0][key]['PTH_pmc_MLX90614_SHT35.SHT.temperature'],
          minTemp: minMaxData[1][key]['PTH_pmc_MLX90614_SHT35.SHT.temperature'],
          maxHumid: minMaxData[0][key]['PTH_pmc_MLX90614_SHT35.SHT.humidity'],
          minHumid: minMaxData[1][key]['PTH_pmc_MLX90614_SHT35.SHT.humidity']
        }
      }
    }


    if (allData.code) {
      // if apis fails anyhow (sensor id is not provided)
      allData = {}
    }

    //aggregate apis 
    // let apiReponses = await Promise.all([this.SensorApi(data1), this.SensorApi(data2)])
    // let averageData = { ...apiReponses[0], ...apiReponses[1] }
    // console.log('averageData123', averageData)
    let json = {}
    // let noOfDays = moment(endRange).diff(startRange, 'days');
    // for (const key in averageData) {
    //   json[key] = averageData[key] / noOfDays
    // }

    this.setState({
      averageData: json,
      allData,
      DataisLoaded: true
    }, () => {
      !tab ? this.harvestReport(startRange, endRange) : this.ipmReport(startRange, endRange)
    });

  }

  filter = async () => {
    const { batches } = this.props;
    const { crop, variety, tab, ipmNote, selectedFarm, dateRange, startDate, endDate, startDate1, endDate1, isSelected, isSelected1 } = this.state;

    let newBatches = [];
    let lastYearBatches = [];
    let customBatches = [];

    let startRange = '';
    let endRange = dateRange === '4' ? moment(endDate) : moment();

    if (dateRange === '4') startRange = moment(startDate);
    else if (dateRange === '1') startRange = moment().subtract(3, 'months');
    else if (dateRange === '2') startRange = moment().subtract(6, 'months');
    else if (dateRange === '3') startRange = moment().subtract(1, 'year');

    let lastYearStartDate = moment(startRange).subtract(1, 'year');
    let lastYearEndDate = moment(endRange).subtract(1, 'year');
    console.log('filteredBatch', batches)

    for (const key in batches) {
      let valid = true;
      const batch = batches[key];
      const isRockWool = batch.growthCycle.growMedia === 'Rockwool'

      // filter crop
      if (crop && (batch.crop !== crop)) valid = false;

      // filter varity
      if (variety.length && (variety.filter(p=>p!=="All").indexOf(batch.variety) === -1)) valid = false;

      // filter farm
      if (selectedFarm.length) {
        if (isRockWool) {
          if (selectedFarm.indexOf(batch.finishingLocation.farm) === -1) valid = false;
        }
        else {
          if (selectedFarm.indexOf(batch.propLocation1.farm) === -1) valid = false;
        }
      }

      // custom date range filter
      if (!tab) {
        if (moment(batch.harvestDate).isBefore(startRange) || moment(batch.harvestDate).isAfter(endRange)) {
          valid = false;
        }
      }

      // compare to last Year
      if (isSelected) {
        if (moment(batch.harvestDate).isSameOrAfter(lastYearStartDate) && moment(batch.harvestDate).isSameOrBefore(lastYearEndDate)) {
          lastYearBatches.push(batch)
        }
      }

      // compare date batches
      if (isSelected1) {
        if (moment(batch.harvestDate).isSameOrAfter(startDate1) && moment(batch.harvestDate).isSameOrBefore(endDate1)) {
          customBatches.push(batch)
        }
      }



      // IPMreport
      if (tab) {
        let found = ipmNote ? false : true;
        if (ipmNote) {
          let matchedStage = []
          for (const key1 in batch.tasks) {
            let task = batch.tasks[key1];
            if (this.matchExact(ipmNote, task.notes ? task.notes.toLowerCase() : '')) {
              found = true;
              matchedStage.push(key1)
            }
          }
        }
        valid = found;
      }

      if (valid) newBatches.push(batch)
    }

    //sorting
    if (this.state.reportFormat === '1') {

      newBatches = newBatches.sort((a, b) => sortFunction(a, b, 'dec', 'estTotalBatchWeight'));
      lastYearBatches = lastYearBatches.sort((a, b) => sortFunction(a, b, 'dec', 'estTotalBatchWeight'))
      customBatches = customBatches.sort((a, b) => sortFunction(a, b, 'dec', 'estTotalBatchWeight'))
    }
    else if (this.state.reportFormat === '2') {
      newBatches = newBatches.sort((a, b) => sortFunction(a, b, 'asc', 'estTotalBatchWeight'));
      lastYearBatches = lastYearBatches.sort((a, b) => sortFunction(a, b, 'asc', 'estTotalBatchWeight'))
      customBatches = customBatches.sort((a, b) => sortFunction(a, b, 'asc', 'estTotalBatchWeight'))
    }

    return {
      mainData: newBatches,
      previousData: lastYearBatches,
      customData: customBatches
    };

  }

  ipmReport = async (startRange, endRange) => {
    // return
    const {
      crops,
      varieties,
      farm
    } = this.props;

    const { ipmNote, allData, sensorData } = this.state;

    const filteredBatch = await this.filter();

    let arr = [
      ["Crop", 'Variety', 'Harvest Date', "Lot Number", "Batch Ranking", "Prop Location", "HD Location", "FS Location", 'Notes', 'Date Noted', 'AVG Daily Humidity', 'AVG DLI']
    ];

    console.log('filteredBatch67', filteredBatch)
    filteredBatch.mainData.forEach(each => {

      const isRockWool = each.growthCycle.growMedia === 'Rockwool'

      // rating calculation
      let count = 0
      const overallRating = Object.keys(each.tasks).reduce((acc, curr) => {
        if (each.tasks[curr].rating) {
          ++count;
        }
        return acc + each.tasks[curr].rating
      }, 0);

      // prop location

      let propName = [];
      let hdName = [];
      let fsName = [];

      if (isRockWool) {
        for (const key in farm) {
          if (farm[key].id === each.finishingLocation.farm) {
            const farmPonds = farm[key].ponds;
            //hd
            each.finishingLocation.pond.hd.forEach(eachUsedPond => {
              farmPonds.forEach(eachFarmPond => {
                if (eachFarmPond.id === eachUsedPond.pond) {
                  if (hdName.indexOf(eachFarmPond.name) === -1) hdName.push(eachFarmPond.name);
                }
              })
            })
            //fs
            each.finishingLocation.pond.fs.forEach(eachUsedPond => {
              farmPonds.forEach(eachFarmPond => {
                if (eachFarmPond.id === eachUsedPond.pond) {
                  if (fsName.indexOf(eachFarmPond.name) === -1) fsName.push(eachFarmPond.name);
                }
              })
            })
          }

          //prop
          if (farm[key].id === each.propLocation.farm) {
            const farmPonds1 = farm[key].ponds;
            farmPonds1.forEach(eachFarmPond => {
              if (eachFarmPond.id === each.propLocation.pond) {
                if (propName.indexOf(eachFarmPond.name) === -1) propName.push(eachFarmPond.name);
              }
            })
          }
        }
      }
      else {
        for (const key in farm) {
          if (farm[key].id === each.propLocation1.farm) {
            const farmPonds2 = farm[key].ponds;
            each.propLocation1.pond.prop.forEach(eachUsedPond => {
              farmPonds2.forEach(eachFarmPond => {
                if (eachFarmPond.id === eachUsedPond.pond) {
                  if (propName.indexOf(eachFarmPond.name) === -1) propName.push(eachFarmPond.name);
                }
              })
            })
          }
        }
      }
      let _notesDate = [];
      for (const key2 in each.tasks) {
        let task = each.tasks[key2];
        if (this.matchExact(ipmNote, task.notes)) {
          // _notesTask.push(key2);
          _notesDate.push(moment(task.notesDate).format('YYYY-MM-DD') + ' ( ' + key2 + ' )');
        }
      }


      // calculate aggregate 
      let from = moment(each.seedDate).isSameOrAfter(startRange) ? each.seedDate : startRange;
      let to = moment(each.harvestDate).isSameOrBefore(endRange) ? each.harvestDate : endRange;


      let totalHumidity = 0;
      let totalTemprature = 0;
      let totalPar = 0;
      let totalData = 0;
      let minTemp = 0;
      let maxTemp = 0;
      let minHumid = 0;
      let maxHumid = 0;

      for (const timestamp in allData) {
        if (moment.unix(timestamp / 1000).isSameOrAfter(from) && moment.unix(timestamp / 1000).isSameOrBefore(to)) {

          const { initiationDate, growthCycle: { germ, prop, hd, growMedia }, harvestDate, finishingLocation, propLocation, propLocation1 } = each;
          const isRockWool = growMedia === 'Rockwool';
          if (isRockWool) {
            if (moment.unix(timestamp / 1000).isSameOrAfter(prop.date) && moment.unix(timestamp / 1000).isBefore(hd.date)) {
              //prop stage 
              let found = false
              if (this.props.farm[propLocation.farm].sensor1) {
                // temp and humidity sensor foundIndex
                found = true
                totalHumidity += allData[timestamp]['PTH_pmc_MLX90614_SHT35.SHT.humidity'] || 0;
                totalTemprature += allData[timestamp]['PTH_pmc_MLX90614_SHT35.SHT.temperature'] || 0;
              }
              if (this.props.farm[propLocation.farm].sensor2) {
                // PAR sensor foundIndex
                found = true
                totalPar += allData[timestamp]['PAR_SQ500_a.par'];

              }
              if (found) totalData += 1;
            }
            else if (moment.unix(timestamp / 1000).isSameOrAfter(hd.date) && moment.unix(timestamp / 1000).isBefore(harvestDate)) {
              // hd+ fs
              let found = false
              if (this.props.farm[finishingLocation.farm].sensor1) {
                // temp and humidity sensor foundIndex
                found = true
                totalHumidity += allData[timestamp]['PTH_pmc_MLX90614_SHT35.SHT.humidity'] || 0;
                totalTemprature += allData[timestamp]['PTH_pmc_MLX90614_SHT35.SHT.temperature'] || 0;
              }
              if (this.props.farm[finishingLocation.farm].sensor2) {
                // PAR sensor foundIndex
                found = true
                totalPar += allData[timestamp]['PAR_SQ500_a.par'];
              }
              if (found) totalData += 1;
            }
          }
          else {
            if (moment.unix(timestamp / 1000).isSameOrAfter(prop.date) && moment.unix(timestamp / 1000).isBefore(harvestDate)) {
              // hd+ fs
              let found = false
              if (this.props.farm[propLocation1.farm].sensor1) {
                // temp and humidity sensor foundIndex
                found = true
                totalHumidity += allData[timestamp][this.props.farm[propLocation1.farm].sensor1];
                totalTemprature += allData[timestamp][this.props.farm[propLocation1.farm].sensor1];
              }
              if (this.props.farm[propLocation1.farm].sensor2) {
                // PAR sensor foundIndex
                found = true
                totalPar += allData[timestamp][this.props.farm[propLocation1.farm].sensor2];
              }
              if (found) totalData += 1;
            }
          }




          // max temp
          if (maxTemp < allData[timestamp].maxTemp) {
            maxTemp = allData[timestamp].maxTemp;
          }

          //min temp
          if (minTemp === 0) {
            minTemp = allData[timestamp].minTemp;
          }
          else if (minTemp > allData[timestamp].minTemp) {
            minTemp = allData[timestamp].minTemp;
          }

          //max Humid
          if (maxHumid < allData[timestamp].maxHumid) {
            maxHumid = allData[timestamp].maxHumid;
          }

          // min humid
          if (minHumid === 0) {
            minHumid = allData[timestamp].minHumid;
          }
          else if (minHumid > allData[timestamp].minHumid) {
            minHumid = allData[timestamp].minHumid;
          }
        }
      }

      arr.push([
        // each.id,
        crops[each.crop].name,
        // each.crop,
        varieties[each.variety].name,
        // each.variety,
        each.harvestDate,
        each.GRFlot,
        count ? parseFloat(overallRating / count).toFixed(1) : 'N/A',
        propName.toString(),
        hdName.toString(),
        fsName.toString(),
        ipmNote,
        _notesDate.toString(),
        sensorData.findIndex(x => x === '2') !== -1 ? allData ? checkNaN(parseFloat(totalTemprature / (totalData || 1)).toFixed(4)) : 'N/A' : '-',
        sensorData.findIndex(x => x === '4') !== -1 ? allData ? checkNaN(minTemp, true) : 'N/A' : '-',
        sensorData.findIndex(x => x === '4') !== -1 ? allData ? checkNaN(maxTemp, true) : 'N/A' : '-',
        // sensorData.findIndex(x => x === '1') !== -1 ? allData ? checkNaN(parseFloat(totalPar / (totalData || 1)).toFixed(4)) + ' (mol)' : 'N/A' : '-',
        sensorData.findIndex(x => x === '1') !== -1 ? allData ? checkNaN(parseFloat(totalPar).toFixed(4)) + ' (mol)' : 'N/A' : '-',
        sensorData.findIndex(x => x === '3') !== -1 ? allData ? checkNaN(parseFloat(totalHumidity / (totalData || 1)).toFixed(4)) : 'N/A' : '-',
        sensorData.findIndex(x => x === '5') !== -1 ? allData ? checkNaN(minHumid) : 'N/A' : '-',
        sensorData.findIndex(x => x === '5') !== -1 ? allData ? checkNaN(maxHumid) : 'N/A' : '-',
      ])
    })

    this.setState({ table: arr, loading: false }, () => {
      setTimeout(() => {
        this.csvLink.current.link.click()
      }, 1000);
    })

  }


  harvestReport = async (startRange, endRange) => {
    // return
    const {
      crops,
      varieties,
      farm,
      growthCycles
    } = this.props;
    const filteredBatch = await this.filter();

    console.log('filteredBatch', filteredBatch)
    const { allData = {}, sensorData, seedsPerWeek } = this.state;

    console.log('sensorData', sensorData)

    if (this.state.isSeperateByLoc) {
      let arr = [
        ["Crop", 'Variety', 'Harvest Date', "Lot Number", "Batch Ranking", "Harvest Location", "Weight", "Rafts", 'AVG Weight/Raft (lb)', ' ', 'AVG Weight/Head (oz)', ' ', 'AVG Daily Temp', 'Min. Temp.', 'Max. Temp.', 'AVG DLI', 'AVG Humidity', 'Min. Humid.', 'Max. Humid.', 'AVG Weight/Square Foot/Week (lb)',"Notes"]
      ];


      for (const key3 in filteredBatch) {

        const singleData = filteredBatch[key3];
        singleData.forEach((each, i) => {

          const isRockWool = each.growthCycle.growMedia === 'Rockwool'

          // rating calculation
          let count = 0
          const overallRating = Object.keys(each.tasks).reduce((acc, curr) => {
            if (each.tasks[curr].rating) {
              ++count;
            }
            return acc + each.tasks[curr].rating
          }, 0);

          // prop location

          let propName = [];
          let hdName = [];
          let fsName = [];
          if (isRockWool) {
            for (const key in farm) {
              if (farm[key].id === each.finishingLocation.farm) {
                const farmPonds = farm[key].ponds;
                //hd
                each.finishingLocation.pond.hd.forEach(eachUsedPond => {
                  farmPonds.forEach(eachFarmPond => {
                    if (eachFarmPond.id === eachUsedPond.pond) {
                      if (hdName.indexOf(eachFarmPond.name) === -1) hdName.push(eachFarmPond.name);
                    }
                  })
                })
                //fs
                each.finishingLocation.pond.fs.forEach(eachUsedPond => {
                  farmPonds.forEach(eachFarmPond => {
                    if (eachFarmPond.id === eachUsedPond.pond) {
                      if (fsName.indexOf(eachFarmPond.name) === -1) fsName.push(eachFarmPond.name);
                    }
                  })
                })
              }

              //prop
              if (farm[key].id === each.propLocation.farm) {
                const farmPonds1 = farm[key].ponds;
                farmPonds1.forEach(eachFarmPond => {
                  if (eachFarmPond.id === each.propLocation.pond) {
                    if (propName.indexOf(eachFarmPond.name) === -1) propName.push(eachFarmPond.name);
                  }
                })
              }
            }
          }
          else {
            for (const key in farm) {
              if (farm[key].id === each.propLocation1.farm) {
                const farmPonds2 = farm[key].ponds;
                each.propLocation1.pond.prop.forEach(eachUsedPond => {
                  farmPonds2.forEach(eachFarmPond => {
                    if (eachFarmPond.id === eachUsedPond.pond) {
                      if (propName.indexOf(eachFarmPond.name) === -1) propName.push(eachFarmPond.name);
                    }
                  })
                })
              }
            }
          }

          // calculate aggregate 
          let from = moment(each.seedDate).isSameOrAfter(startRange) ? each.seedDate : startRange;
          let to = moment(each.harvestDate).isSameOrBefore(endRange) ? each.harvestDate : endRange;

          let totalHumidity = 0;
          let totalTemprature = 0;
          let totalPar = 0;
          let totalData = 0;
          let minTemp = 0;
          let maxTemp = 0;
          let minHumid = 0;
          let maxHumid = 0;

          for (const timestamp in allData) {
            // console.log("allData-timestamp",allData[timestamp]);
            if (moment.unix(timestamp / 1000).isSameOrAfter(from) && moment.unix(timestamp / 1000).isSameOrBefore(to)) {

              const { initiationDate, growthCycle: { germ, prop, hd, growMedia }, harvestDate, finishingLocation, propLocation, propLocation1 } = each;
              const isRockWool = growMedia === 'Rockwool';
              if (isRockWool) {
                if (moment.unix(timestamp / 1000).isSameOrAfter(prop.date) && moment.unix(timestamp / 1000).isBefore(hd.date)) {
                  console.log('liesIn>>', i, 'prop', each)
                  //prop stage 
                  let found = false
                  if (this.props.farm[propLocation.farm].sensor1) {
                    // temp and humidity sensor foundIndex
                    found = true
                    totalHumidity += allData[timestamp]['PTH_pmc_MLX90614_SHT35.SHT.humidity'] || 0;
                    totalTemprature += allData[timestamp]['PTH_pmc_MLX90614_SHT35.SHT.temperature'] || 0;
                  }
                  if (this.props.farm[propLocation.farm].sensor2) {
                    // PAR sensor foundIndex
                    found = true
                    totalPar += allData[timestamp]['PAR_SQ500_a.par'];
                  }
                  if (found) totalData += 1;
                }
                else if (moment.unix(timestamp / 1000).isSameOrAfter(hd.date) && moment.unix(timestamp / 1000).isBefore(harvestDate)) {
                  // hd+ fs
                  let found = false
                  if (this.props.farm[finishingLocation.farm].sensor1) {
                    // temp and humidity sensor foundIndex
                    found = true
                    totalHumidity += allData[timestamp]['PTH_pmc_MLX90614_SHT35.SHT.humidity'] || 0;
                    totalTemprature += allData[timestamp]['PTH_pmc_MLX90614_SHT35.SHT.temperature'] || 0;
                  }
                  if (this.props.farm[finishingLocation.farm].sensor2) {
                    // PAR sensor foundIndex
                    found = true
                    totalPar += allData[timestamp]['PAR_SQ500_a.par'];
                  }
                  if (found) totalData += 1;
                }
              }
              else {
                if (moment.unix(timestamp / 1000).isSameOrAfter(prop.date) && moment.unix(timestamp / 1000).isBefore(harvestDate)) {
                  // hd+ fs
                  let found = false
                  if (this.props.farm[propLocation1.farm].sensor1) {
                    // temp and humidity sensor foundIndex
                    found = true
                    totalHumidity += allData[timestamp][this.props.farm[propLocation1.farm].sensor1];
                    totalTemprature += allData[timestamp][this.props.farm[propLocation1.farm].sensor1];
                  }
                  if (this.props.farm[propLocation1.farm].sensor2) {
                    // PAR sensor foundIndex
                    found = true
                    totalPar += allData[timestamp][this.props.farm[propLocation1.farm].sensor2];
                  }
                  if (found) totalData += 1;
                }
              }

              // max temp
              if (maxTemp < allData[timestamp].maxTemp) {
                maxTemp = allData[timestamp].maxTemp;
              }

              //min temp
              if (minTemp === 0) {
                minTemp = allData[timestamp].minTemp;
              }
              else if (minTemp > allData[timestamp].minTemp) {
                minTemp = allData[timestamp].minTemp;
              }

              //max Humid
              if (maxHumid < allData[timestamp].maxHumid) {
                maxHumid = allData[timestamp].maxHumid;
              }

              // min humid
              if (minHumid === 0) {
                minHumid = allData[timestamp].minHumid;
              }
              else if (minHumid > allData[timestamp].minHumid) {
                minHumid = allData[timestamp].minHumid;
              }
            }
          }

          // calculation seeds / week;

          console.log('finalBatch', each)

          let avgWeightPerSqFtPerWeek = 0;
          let cycleFsRafts = 0;
          let totalRaftPerTray = 0;

          if (isRockWool) {
            const seedsFromGC = each.cycleDetail?.germCells || each.growthCycle.germ.cells;
            const noOfHDRaft = each.cycleDetail?.hdRafts || each.growthCycle.hd.calcRafts;
            const daysInHD = each.growthCycle.hd.days;
            const noOfFSRaft = each.cycleDetail?.fsRafts || each.growthCycle.fs.calcRafts;
            const daysInFS = each.growthCycle.fs.days;


            const setsOfHDRafts = parseFloat(((daysInHD / 7) * seedsPerWeek) || 0).toFixed(2);
            const noOfHDRafts = noOfHDRaft * setsOfHDRafts;

            const setsOfFSRafts = parseFloat(((daysInFS / 7) * seedsPerWeek) || 0).toFixed(2);
            const noOfFSRafts = noOfFSRaft * setsOfFSRafts;

            const totalRaftRequiredInSystem = noOfHDRafts + noOfFSRafts;
            const totalSqFtRequiredInSystem = totalRaftRequiredInSystem * 8;

            // const totalPondsPerWeek = 105;
            const totalPondsPerWeek = each.harvest.reduce((acc, curr) => {
              return acc + parseFloat(curr.rafts || 0);
            }, 0);

            const totalPondPerSfPerWeek = totalPondsPerWeek / totalSqFtRequiredInSystem
            const totalPondPerSfPerDay = totalPondPerSfPerWeek / 7;

            cycleFsRafts = parseFloat(each?.cycleDetail?.fsRafts || each?.growthCycle.fs.calcRafts || 0);
            const avgWeightPerTray = each.harvest.reduce((acc, curr) => {
              // return acc + parseFloat(curr?.weight || 0);
              return acc + (parseFloat(curr?.weight || 0)/(cycleFsRafts>0?cycleFsRafts:1));
            }, 0);
            totalRaftPerTray = (noOfHDRafts + noOfFSRafts)*8;

            // const avgWeightPerTray = parseFloat(totalHarvested || 0) / parseFloat(QtyToSeed1 || 1);

            avgWeightPerSqFtPerWeek = parseFloat((avgWeightPerTray * cycleFsRafts * seedsPerWeek) / totalRaftPerTray);

            // avgWeightPerSqFtPerWeek = totalPondPerSfPerDay;


          }
          else {

            // const QtyToSeed1 = each.QtyToSeed;
            // const germWeight = each.growthCycle.germ.weight;
            cycleFsRafts = parseFloat(each?.growthCycle?.prop?.days || 0);

            const avgWeightPerTray = each.harvest.reduce((acc, curr) => {
              // return acc + parseFloat(curr?.weight || 0);
              return acc + (parseFloat(curr?.weight || 0)/(cycleFsRafts>0?cycleFsRafts:1));
            }, 0);
            totalRaftPerTray = (each.rafts ? each.rafts : 0) * 8;

            // const avgWeightPerTray = parseFloat(totalHarvested || 0) / parseFloat(QtyToSeed1 || 1);

            avgWeightPerSqFtPerWeek = parseFloat((avgWeightPerTray * cycleFsRafts * seedsPerWeek) / totalRaftPerTray);

          }
          if(each.harvest && each.harvest.length > 0){
            each.harvest.map((eachPond)=> {
              const pondName = eachPond.pond && eachPond.pond.name ? eachPond.pond.name : "";
              const pondWeight = eachPond.weight ? eachPond.weight : 0;
              const pondRaft = eachPond.rafts ? eachPond.rafts : 0;
              const newAvgWeightPerSqFtPerWeek = parseFloat((pondWeight * cycleFsRafts * seedsPerWeek) / totalRaftPerTray);
              arr.push([
                // each.id,
                crops[each.crop].name,
                // each.crop,
                varieties[each.variety].name,
                // each.variety,
                each.harvestDate,
                each.GRFlot,
                count ? parseFloat(overallRating / count).toFixed(1) : 'N/A',
                pondName, //harvest location
                pondWeight, //harvest weight
                pondRaft, //harvest rafts
                fix2(each.estTotalBatchWeight / (parseFloat((each.cycleDetail?.fsEndRafts || each.growthCycle.fs.endRafts) || 0) || 1)),
                'lbs',
                (each.avgWeightPerHead || 0)*16,
                'oz',
                sensorData.findIndex(x => x === '2') !== -1 ? allData ? checkNaN(parseFloat(totalTemprature / totalData),true) : 'N/A' : '-',
                sensorData.findIndex(x => x === '4') !== -1 ? allData ? checkNaN(minTemp, true) : 'N/A' : '-',
                sensorData.findIndex(x => x === '4') !== -1 ? allData ? checkNaN(maxTemp, true) : 'N/A' : '-',
                // sensorData.findIndex(x => x === '1') !== -1 ? allData ? checkNaN(parseFloat(totalPar / totalData).toFixed(4)) + ' (mol)' : 'N/A' : '-',
                sensorData.findIndex(x => x === '1') !== -1 ? allData ? checkNaN(parseFloat(totalPar).toFixed(4)) + ' (mol)' : 'N/A' : '-',
                sensorData.findIndex(x => x === '3') !== -1 ? allData ? checkNaN(parseFloat(totalHumidity / totalData).toFixed(4)) : 'N/A' : '-',
                sensorData.findIndex(x => x === '5') !== -1 ? allData ? checkNaN(minHumid) : 'N/A' : '-',
                sensorData.findIndex(x => x === '5') !== -1 ? allData ? checkNaN(maxHumid) : 'N/A' : '-',
                newAvgWeightPerSqFtPerWeek,
                each?.tasks?.harvest?.notes || ""
              ]);
            })
          }
        })
        arr.push([
          ' ',
          ' ',
          ' ',
          ' ',
          ' ',
          ' ',
          ' ',
          ' ',
          ' ',
          ' ',
          ' ',
          ' ',
          ' ',
          ' ',
          ' ',
          ' ',
        ])
      }

      this.setState({ table: arr, loading: false }, () => {
        setTimeout(() => {
          this.csvLink.current.link.click()
        }, 500);
      })
    }
    else if (this.state.reportFormat === '1' || this.state.reportFormat === '2') {
      let arr = [
        // [
        //   '', '', '', '', 'Date Range', moment(startDate).format('YYYY-MM-DD'), '-', moment(endDate).format('YYYY-MM-DD'), '', '', '', ''
        // ],
        ["Crop", 'Variety', 'Harvest Date', "Lot Number", "Batch Ranking", "Prop Location", "HD Location", "FS Location", 'AVG Weight/Raft (lb)', ' ', 'AVG Weight/Head (oz)', ' ', 'AVG Daily Temp', 'Min. Temp.', 'Max. Temp.', 'AVG DLI', 'AVG Humidity', 'Min. Humid.', 'Max. Humid.', 'AVG Weight/Square Foot/Week (lb)','Notes']
      ];


      for (const key3 in filteredBatch) {

        const singleData = filteredBatch[key3];
        singleData.forEach((each, i) => {

          const isRockWool = each.growthCycle.growMedia === 'Rockwool'

          // rating calculation
          let count = 0
          const overallRating = Object.keys(each.tasks).reduce((acc, curr) => {
            if (each.tasks[curr].rating) {
              ++count;
            }
            return acc + each.tasks[curr].rating
          }, 0);

          // prop location
          console.log('eachbatch', each)

          let propName = [];
          let hdName = [];
          let fsName = [];
          if (isRockWool) {
            for (const key in farm) {
              if (farm[key].id === each.finishingLocation.farm) {
                const farmPonds = farm[key].ponds;
                //hd
                each.finishingLocation.pond.hd.forEach(eachUsedPond => {
                  farmPonds.forEach(eachFarmPond => {
                    if (eachFarmPond.id === eachUsedPond.pond) {
                      if (hdName.indexOf(eachFarmPond.name) === -1) hdName.push(eachFarmPond.name);
                    }
                  })
                })
                //fs
                each.finishingLocation.pond.fs.forEach(eachUsedPond => {
                  farmPonds.forEach(eachFarmPond => {
                    if (eachFarmPond.id === eachUsedPond.pond) {
                      if (fsName.indexOf(eachFarmPond.name) === -1) fsName.push(eachFarmPond.name);
                    }
                  })
                })
              }

              //prop
              if (farm[key].id === each.propLocation.farm) {
                const farmPonds1 = farm[key].ponds;
                farmPonds1.forEach(eachFarmPond => {
                  if (eachFarmPond.id === each.propLocation.pond) {
                    if (propName.indexOf(eachFarmPond.name) === -1) propName.push(eachFarmPond.name);
                  }
                })
              }
            }
          }
          else {
            for (const key in farm) {
              if (farm[key].id === each.propLocation1.farm) {
                const farmPonds2 = farm[key].ponds;
                each.propLocation1.pond.prop.forEach(eachUsedPond => {
                  farmPonds2.forEach(eachFarmPond => {
                    if (eachFarmPond.id === eachUsedPond.pond) {
                      if (propName.indexOf(eachFarmPond.name) === -1) propName.push(eachFarmPond.name);
                    }
                  })
                })
              }
            }
          }

          // calculate aggregate 
          let from = moment(each.seedDate).isSameOrAfter(startRange) ? each.seedDate : startRange;
          let to = moment(each.harvestDate).isSameOrBefore(endRange) ? each.harvestDate : endRange;

          let totalHumidity = 0;
          let totalTemprature = 0;
          let totalPar = 0;
          let totalData = 0;
          let minTemp = 0;
          let maxTemp = 0;
          let minHumid = 0;
          let maxHumid = 0;

          console.log('allData668',allData)

          for (const timestamp in allData) {
            // console.log("allData-timestamp222",allData[timestamp]);
            if (moment.unix(timestamp / 1000).isSameOrAfter(from) && moment.unix(timestamp / 1000).isSameOrBefore(to)) {

              const { initiationDate, growthCycle: { germ, prop, hd, growMedia }, harvestDate, finishingLocation, propLocation, propLocation1 } = each;
              const isRockWool = growMedia === 'Rockwool';
              if (isRockWool) {
                if (moment.unix(timestamp / 1000).isSameOrAfter(prop.date) && moment.unix(timestamp / 1000).isBefore(hd.date)) {
                  console.log('liesIn>>', i, 'prop', each)
                  //prop stage 
                  let found = false
                  if (this.props.farm[propLocation.farm].sensor1) {
                    // temp and humidity sensor foundIndex
                    found = true
                    totalHumidity += allData[timestamp]['PTH_pmc_MLX90614_SHT35.SHT.humidity'] || 0;
                    totalTemprature += allData[timestamp]['PTH_pmc_MLX90614_SHT35.SHT.temperature'] || 0;
                  }
                  if (this.props.farm[propLocation.farm].sensor2) {
                    // PAR sensor foundIndex
                    found = true
                    totalPar += allData[timestamp]['PAR_SQ500_a.par'];
                  }
                  if (found) totalData += 1;
                }
                else if (moment.unix(timestamp / 1000).isSameOrAfter(hd.date) && moment.unix(timestamp / 1000).isBefore(harvestDate)) {
                  // hd+ fs
                  let found = false
                  if (this.props.farm[finishingLocation.farm].sensor1) {
                    // temp and humidity sensor foundIndex
                    found = true
                    totalHumidity += allData[timestamp]['PTH_pmc_MLX90614_SHT35.SHT.humidity'] || 0;
                    totalTemprature += allData[timestamp]['PTH_pmc_MLX90614_SHT35.SHT.temperature'] || 0;
                  }
                  if (this.props.farm[finishingLocation.farm].sensor2) {
                    // PAR sensor foundIndex
                    found = true
                    totalPar += allData[timestamp]['PAR_SQ500_a.par'];
                  }
                  if (found) totalData += 1;
                }
              }
              else {
                if (moment.unix(timestamp / 1000).isSameOrAfter(prop.date) && moment.unix(timestamp / 1000).isBefore(harvestDate)) {
                  // hd+ fs
                  let found = false
                  if (this.props.farm[propLocation1.farm].sensor1) {
                    // temp and humidity sensor foundIndex
                    found = true
                    totalHumidity += allData[timestamp][this.props.farm[propLocation1.farm].sensor1];
                    totalTemprature += allData[timestamp][this.props.farm[propLocation1.farm].sensor1];
                  }
                  if (this.props.farm[propLocation1.farm].sensor2) {
                    // PAR sensor foundIndex
                    found = true
                    totalPar += allData[timestamp][this.props.farm[propLocation1.farm].sensor2];
                  }
                  if (found) totalData += 1;
                }
              }

              // max temp
              if (maxTemp < allData[timestamp].maxTemp) {
                maxTemp = allData[timestamp].maxTemp;
              }

              //min temp
              if (minTemp === 0) {
                minTemp = allData[timestamp].minTemp;
              }
              else if (minTemp > allData[timestamp].minTemp) {
                minTemp = allData[timestamp].minTemp;
              }

              //max Humid
              if (maxHumid < allData[timestamp].maxHumid) {
                maxHumid = allData[timestamp].maxHumid;
              }

              // min humid
              if (minHumid === 0) {
                minHumid = allData[timestamp].minHumid;
              }
              else if (minHumid > allData[timestamp].minHumid) {
                minHumid = allData[timestamp].minHumid;
              }
            }
            console.log('finalBatchallData', allData[timestamp])
          }

          // calculation seeds / week;

          let avgWeightPerSqFtPerWeek = 0;
          if (isRockWool) {

            const seedsFromGC = each.cycleDetail?.germCells || each.growthCycle.germ.cells;
            const noOfHDRaft = each.cycleDetail?.hdRafts || each.growthCycle.hd.calcRafts;
            const daysInHD = each.growthCycle.hd.days;
            const noOfFSRaft = each.cycleDetail?.fsRafts || each.growthCycle.fs.calcRafts;
            const daysInFS = each.growthCycle.fs.days;


            const setsOfHDRafts = parseFloat(((daysInHD / 7) * seedsPerWeek) || 0).toFixed(2);
            const noOfHDRafts = noOfHDRaft * setsOfHDRafts;

            const setsOfFSRafts = parseFloat(((daysInFS / 7) * seedsPerWeek) || 0).toFixed(2);
            const noOfFSRafts = noOfFSRaft * setsOfFSRafts;

            const totalRaftRequiredInSystem = noOfHDRafts + noOfFSRafts;
            const totalSqFtRequiredInSystem = totalRaftRequiredInSystem * 8;

            // const totalPondsPerWeek = 105;
            const totalPondsPerWeek = each.harvest.reduce((acc, curr) => {
              return acc + parseFloat(curr.rafts || 0);
            }, 0);

            const totalPondPerSfPerWeek = totalPondsPerWeek / totalSqFtRequiredInSystem
            const totalPondPerSfPerDay = totalPondPerSfPerWeek / 7;

            const cycleFsRafts = parseFloat(each?.cycleDetail?.fsRafts || each?.growthCycle.fs.calcRafts || 0);
            const avgWeightPerTray = each.harvest.reduce((acc, curr) => {
              // return acc + parseFloat(curr?.weight || 0);
              return acc + (parseFloat(curr?.weight || 0)/(cycleFsRafts>0?cycleFsRafts:1));
            }, 0);
            const totalRaftPerTray = (noOfHDRafts + noOfFSRafts)*8;

            // const avgWeightPerTray = parseFloat(totalHarvested || 0) / parseFloat(QtyToSeed1 || 1);

            avgWeightPerSqFtPerWeek = parseFloat((avgWeightPerTray * cycleFsRafts * seedsPerWeek) / totalRaftPerTray);

            // avgWeightPerSqFtPerWeek = totalPondPerSfPerDay;

            console.log('finalBatch>>1', totalPondPerSfPerDay)

          }
          else {

            // const QtyToSeed1 = each.QtyToSeed;
            // const germWeight = each.growthCycle.germ.weight;

            const cycleFsRafts = parseFloat(each?.cycleDetail?.fsRafts || each?.growthCycle.fs.calcRafts || 0);
            const avgWeightPerTray = each.harvest.reduce((acc, curr) => {
              // return acc + parseFloat(curr?.weight || 0);
              return acc + (parseFloat(curr?.weight || 0)/(cycleFsRafts>0?cycleFsRafts:1));
            }, 0);
            const totalRaftPerTray = (each.rafts ? each.rafts : 0) * 8;

            // const avgWeightPerTray = parseFloat(totalHarvested || 0) / parseFloat(QtyToSeed1 || 1);
            const daysInProp = parseFloat(each?.growthCycle?.prop?.days || 0);

            avgWeightPerSqFtPerWeek = parseFloat((avgWeightPerTray * daysInProp * seedsPerWeek) / totalRaftPerTray);


          }


          console.log("eachhhhhh",each);
          arr.push([
            // each.id,
            crops[each.crop].name,
            // each.crop,
            varieties[each.variety].name,
            // each.variety,
            each.harvestDate,
            each.GRFlot,
            count ? parseFloat(overallRating / count).toFixed(1) : 'N/A',
            propName.toString(),
            hdName.toString(),
            fsName.toString(),
            fix2(each.estTotalBatchWeight / (parseFloat((each.cycleDetail?.fsEndRafts || each.growthCycle.fs.endRafts) || 0) || 1)),
            'lbs',
            (each.avgWeightPerHead || 0)*16,
            'oz',
            sensorData.findIndex(x => x === '2') !== -1 ? allData ? checkNaN(parseFloat(totalTemprature / totalData),true) : 'N/A' : '-',
            sensorData.findIndex(x => x === '4') !== -1 ? allData ? checkNaN(minTemp, true) : 'N/A' : '-',
            sensorData.findIndex(x => x === '4') !== -1 ? allData ? checkNaN(maxTemp, true) : 'N/A' : '-',
            sensorData.findIndex(x => x === '1') !== -1 ? allData ? checkNaN(parseFloat(totalPar / totalData).toFixed(4)) + ' (mol)' : 'N/A' : '-',
            sensorData.findIndex(x => x === '3') !== -1 ? allData ? checkNaN(parseFloat(totalHumidity / totalData).toFixed(4)) : 'N/A' : '-',
            sensorData.findIndex(x => x === '5') !== -1 ? allData ? checkNaN(minHumid) : 'N/A' : '-',
            sensorData.findIndex(x => x === '5') !== -1 ? allData ? checkNaN(maxHumid) : 'N/A' : '-',
            avgWeightPerSqFtPerWeek,
            each?.tasks?.harvest?.notes || ""
          ]);
        })
        arr.push([
          ' ',
          ' ',
          ' ',
          ' ',
          ' ',
          ' ',
          ' ',
          ' ',
          ' ',
          ' ',
          ' ',
          ' ',
          ' ',
          ' ',
          ' ',
          ' ',
        ])
      }

      this.setState({ table: arr, loading: false }, () => {
        setTimeout(() => {
          this.csvLink.current.link.click()
        }, 500);
      })
    }
    else if (this.state.reportFormat === '3') {
      let arr = [
        ["Crop", 'Variety', 'Growth Cycle Name', "Lot Number", "Prop Location", "HD Location", "FS Location", 'Germ', 'Prop', 'HD', 'FS', '#Days Seed to Harvest', 'Days in Finishing', 'AVG Weight/Raft (lb)', ' ', 'AVG Weight/Head (oz)', ' ', 'AVG Daily Temp', 'Min. Temp.', 'Max. Temp.', 'AVG DLI', 'AVG Humidity', 'Min. Humid.', 'Max. Humid.', 'AVG Weight/Square Foot/Week (lb)']
      ];

      for (const key4 in filteredBatch) {

        const singleData = filteredBatch[key4];
        singleData.forEach(each => {
          let propName = [];
          let hdName = [];
          let fsName = [];

          const isRockWool = each.growthCycle.growMedia === 'Rockwool'

          if (isRockWool) {
            for (const key in farm) {
              if (farm[key].id === each.finishingLocation.farm) {
                const farmPonds = farm[key].ponds;
                //hd
                each.finishingLocation.pond.hd.forEach(eachUsedPond => {
                  farmPonds.forEach(eachFarmPond => {
                    if (eachFarmPond.id === eachUsedPond.pond) {
                      if (hdName.indexOf(eachFarmPond.name) === -1) hdName.push(eachFarmPond.name);
                    }
                  })
                })
                //fs
                each.finishingLocation.pond.fs.forEach(eachUsedPond => {
                  farmPonds.forEach(eachFarmPond => {
                    if (eachFarmPond.id === eachUsedPond.pond) {
                      if (fsName.indexOf(eachFarmPond.name) === -1) fsName.push(eachFarmPond.name);
                    }
                  })
                })
              }

              //prop
              if (farm[key].id === each.propLocation.farm) {
                const farmPonds1 = farm[key].ponds;
                farmPonds1.forEach(eachFarmPond => {
                  if (eachFarmPond.id === each.propLocation.pond) {
                    if (propName.indexOf(eachFarmPond.name) === -1) propName.push(eachFarmPond.name);
                  }
                })
              }
            }
          }
          else {
            for (const key in farm) {
              if (farm[key].id === each.propLocation1.farm) {
                const farmPonds2 = farm[key].ponds;
                each.propLocation1.pond.prop.forEach(eachUsedPond => {
                  farmPonds2.forEach(eachFarmPond => {
                    if (eachFarmPond.id === eachUsedPond.pond) {
                      if (propName.indexOf(eachFarmPond.name) === -1) propName.push(eachFarmPond.name);
                    }
                  })
                })
              }
            }
          }

          // calculate aggregate 
          let from = moment(each.seedDate).isSameOrAfter(startRange) ? each.seedDate : startRange;
          let to = moment(each.harvestDate).isSameOrBefore(endRange) ? each.harvestDate : endRange;

          let totalHumidity = 0;
          let totalTemprature = 0;
          let totalPar = 0;
          let totalData = 0;
          let minTemp = 0;
          let maxTemp = 0;
          let minHumid = 0;
          let maxHumid = 0;

          for (const timestamp in allData) {
            if (moment.unix(timestamp / 1000).isSameOrAfter(from) && moment.unix(timestamp / 1000).isSameOrBefore(to)) {
              const { initiationDate, growthCycle: { germ, prop, hd, growMedia }, harvestDate, finishingLocation, propLocation, propLocation1 } = each;
              const isRockWool = growMedia === 'Rockwool';
              if (isRockWool) {
                if (moment.unix(timestamp / 1000).isSameOrAfter(prop.date) && moment.unix(timestamp / 1000).isBefore(hd.date)) {
                  //prop stage 
                  let found = false
                  if (this.props.farm[propLocation.farm].sensor1) {
                    // temp and humidity sensor foundIndex
                    found = true
                    totalHumidity += allData[timestamp]['PTH_pmc_MLX90614_SHT35.SHT.humidity'] || 0;
                    totalTemprature += allData[timestamp]['PTH_pmc_MLX90614_SHT35.SHT.temperature'] || 0;
                  }
                  if (this.props.farm[propLocation.farm].sensor2) {
                    // PAR sensor foundIndex
                    found = true
                    totalPar += allData[timestamp]['PAR_SQ500_a.par'];
                  }
                  if (found) totalData += 1;
                }
                else if (moment.unix(timestamp / 1000).isSameOrAfter(hd.date) && moment.unix(timestamp / 1000).isBefore(harvestDate)) {
                  // hd+ fs
                  let found = false
                  if (this.props.farm[finishingLocation.farm].sensor1) {
                    // temp and humidity sensor foundIndex
                    found = true
                    totalHumidity += allData[timestamp]['PTH_pmc_MLX90614_SHT35.SHT.humidity'] || 0;
                    totalTemprature += allData[timestamp]['PTH_pmc_MLX90614_SHT35.SHT.temperature'] || 0;
                  }
                  if (this.props.farm[finishingLocation.farm].sensor2) {
                    // PAR sensor foundIndex
                    found = true
                    totalPar += allData[timestamp]['PAR_SQ500_a.par'];
                  }
                  if (found) totalData += 1;
                }
              }
              else {
                if (moment.unix(timestamp / 1000).isSameOrAfter(prop.date) && moment.unix(timestamp / 1000).isBefore(harvestDate)) {
                  // hd+ fs
                  let found = false
                  if (this.props.farm[propLocation1.farm].sensor1) {
                    // temp and humidity sensor foundIndex
                    found = true
                    totalHumidity += allData[timestamp][this.props.farm[propLocation1.farm].sensor1];
                    totalTemprature += allData[timestamp][this.props.farm[propLocation1.farm].sensor1];
                  }
                  if (this.props.farm[propLocation1.farm].sensor2) {
                    // PAR sensor foundIndex
                    found = true
                    totalPar += allData[timestamp][this.props.farm[propLocation1.farm].sensor2];
                  }
                  if (found) totalData += 1;
                }
              }
              // max temp
              if (maxTemp < allData[timestamp].maxTemp) {
                maxTemp = allData[timestamp].maxTemp;
              }

              //min temp
              if (minTemp === 0) {
                minTemp = allData[timestamp].minTemp;
              }
              else if (minTemp > allData[timestamp].minTemp) {
                minTemp = allData[timestamp].minTemp;
              }

              //max Humid
              if (maxHumid < allData[timestamp].maxHumid) {
                maxHumid = allData[timestamp].maxHumid;
              }

              // min humid
              if (minHumid === 0) {
                minHumid = allData[timestamp].minHumid;
              }
              else if (minHumid > allData[timestamp].minHumid) {
                minHumid = allData[timestamp].minHumid;
              }
            }
          }
          console.log('germIssue', each)
          arr.push([
            crops[each.crop].name,
            varieties[each.variety].name,
            growthCycles[each.growthCycleID].name,
            each.GRFlot,
            propName.toString(),
            hdName.toString(),
            fsName.toString(),
            each.growthCycle.germ.days,
            each.growthCycle.prop.days,
            each.growthCycle.hd.days,
            each.growthCycle.fs.days,
            moment(each.harvestDate).diff(each.seedDate, 'days'),
            each.growthCycle.hd.days + each.growthCycle.fs.days,
            fix2(each.estTotalBatchWeight / (parseFloat((each.cycleDetail?.fsEndRafts || each.growthCycle.fs.endRafts) || 0) || 1)),
            'lbs',
            each.avgWeightPerHead || 0,
            'oz',
            sensorData.findIndex(x => x === '2') !== -1 ? allData ? checkNaN(parseFloat(totalTemprature / totalData).toFixed(4)) : 'N/A' : '-',
            sensorData.findIndex(x => x === '4') !== -1 ? allData ? checkNaN(minTemp , true) : 'N/A' : '-',
            sensorData.findIndex(x => x === '4') !== -1 ? allData ? checkNaN(maxTemp , true) : 'N/A' : '-',
            sensorData.findIndex(x => x === '1') !== -1 ? allData ? checkNaN(parseFloat(totalPar / totalData).toFixed(4)) + ' (mol)' : 'N/A' : '-',
            sensorData.findIndex(x => x === '3') !== -1 ? allData ? checkNaN(parseFloat(totalHumidity / totalData).toFixed(4)) : 'N/A' : '-',
            sensorData.findIndex(x => x === '5') !== -1 ? allData ? checkNaN(minHumid) : 'N/A' : '-',
            sensorData.findIndex(x => x === '5') !== -1 ? allData ? checkNaN(maxHumid) : 'N/A' : '-',
            'N/A'
            // propLocation: farm[each.propLocation1.farm || each.propLocation.farm].name,
            // farmId: each.propLocation1.farm || each.propLocation.farm
          ])
        })
        this.setState({ table: arr, loading: false }, () => {
          setTimeout(() => {
            this.csvLink.current.link.click()
          }, 1000);
        })

      }
    }
  }
  // table.push = {
  //   id: each.id,
  //   crop: crops[each.crop].name,
  //   cropId: each.crop,
  //   variety: varieties[each.variety].name,
  //   varietyId: each.variety,
  //   harvestDate: each.harvestDate,
  //   grfLot: each.GRFlot,
  //   ranking: count ? parseFloat(overallRating / count).toFixed(1) : 'N/A',
  //   hdLocation: hdName.toString(),
  //   fsLocation: fsName.toString(),
  //   propLocation: propName.toString(),
  //   avgWeightPerHead: each.avgWeightPerHead || 0,
  //   estTotalBatchWeight : fix2(each.estTotalBatchWeight / (parseFloat(each.growthCycle.fs.endRafts || 0)||1))
  //   // propLocation: farm[each.propLocation1.farm || each.propLocation.farm].name,
  //   // farmId: each.propLocation1.farm || each.propLocation.farm
  // }

  renderVariety = (cropVal) => {
    const {
      crops,
      varieties
    } = this.props;
    let varietyList = [];
    let varietyOptList = [];
    if(cropVal === '1'){
      for (const key in crops) {
        crops[key].varieties.forEach(key1 => {
          varietyOptList.push(key1);
          varietyList.push(<MenuItem key={key1} value={key1}>{varieties[key1].name}</MenuItem>)
        })
      }
    }else if(cropVal && crops[cropVal]){
      const newVarietyList =  crops[cropVal].varieties.filter((p) => varieties?.[p]?.isArchived !== true).map(key => {
        varietyOptList.push(key);
        return <MenuItem key={key} value={key}>{varieties[key].name}</MenuItem>
      });
      varietyList = [...varietyList,...newVarietyList];
    }
    if(varietyList.length > 0){
      const selectAll = <MenuItem value={'All'}>All</MenuItem>;
      varietyList.unshift(selectAll)
    }
    return varietyList;

  }
  renderVarietyVal = (cropVal) => {
    const {
      crops,
      varieties
    } = this.props;
    let varietyOptList = [];
    if(cropVal === '1'){
      for (const key in crops) {
        crops[key].varieties.forEach(key1 => {
          varietyOptList.push(key1);
        })
      }
    }else if(cropVal && crops[cropVal]){
      varietyOptList = crops[cropVal].varieties.map(key => {
        return key
      });
    }
    this.setState({varietyOptVal: varietyOptList})
    return varietyOptList;
  }

  savePestList(pestType) {
    addPestType(pestType);
  }
  onEditPestList(pestType, index) {
    addPestType(pestType, index);
  }
  deletePestType(index) {
    removePestType(index)
  }

  


  render() {
    const {
      classes,
      crops,
      varieties,
      farm,
      growthCycles,
      misc
    } = this.props;

    const { dateRange,
      startDate,
      endDate,
      startDate1,
      endDate1,
      isSelected,
      isSelected1,
      crop,
      variety,
      varietyOptVal,
      isVarietySelectAll,
      selectedFarm,
      sensorData,
      reportFormat,
      ipmNote,
      table,
      tab,
      loading,
      seedsPerWeek,
      errorMessage,
      isSeperateByLoc } = this.state;

    return (
      <div>
        <CSVLink
          data={table}
          filename={moment().format('YYYY-MM-DD') + '.csv'}
          className="hidden"
          ref={this.csvLink}
          target="_blank"
        />
        <Grid>
          <Grid xs={12} container direction='row' justify='space-between'>
            <Grid xs={4} container direction='row' alignItems='center'>
              <Paper square >
                <Tabs
                  value={tab}
                  indicatorColor="primary"
                  textColor="primary"
                  onChange={this.handleChangeTab}
                  style={{ width: '100%' }}
                >
                  <Tab label="Harvest Report" />
                  <Tab label="IPM Report" />
                </Tabs>
              </Paper>
            </Grid>
            <Button variant="outlined" size="small" color="secondary" className={classes.topButton} onClick={() => this.setState({ modalOpen: true })}>
              <i className={`${classes.buttonIcon} fas fa-cog fa-2x`}></i>
              Pest List
            </Button>
          </Grid>

          <Grid style={{ marginLeft: '15px', marginTop: '20px' }}>
            <TextField
              required
              select
              value={dateRange}
              label={'Date range'}
              onChange={(e) => {
                console.log(e.target)
                this.setState({ dateRange: e.target.value })
              }}
              margin="dense"
            >
              <MenuItem key={`1`} value={'1'}>Last 3 Months</MenuItem>
              <MenuItem key={`2`} value={'2'}>Last 6 Months</MenuItem>
              <MenuItem key={`3`} value={'3'}>Last Year</MenuItem>
              <MenuItem key={`4`} value={'4'}>Custom</MenuItem>
            </TextField>
          </Grid>

          {dateRange === '4' ? <Grid xs={12} container direction='row' className={classes.grid}>
            <Grid xs={3}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <Grid container >
                  <DatePicker
                    // margin="normal"
                    label="Start Date"
                    name='startDate'
                    value={startDate}
                    onChange={(startDate) => this.setState({ startDate })}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid xs={3}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <Grid container >
                  <DatePicker
                    // margin="normal"
                    label="End Date"
                    name='endDate'
                    value={endDate}
                    onChange={(endDate) => this.setState({ endDate })}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid> : null}

          <Grid xs={8} container direction='row' alignItems='center' className={classes.grid1}>
            <Grid xs={6} container direction='row' alignItems='center'>
              <Checkbox checked={isSelected} onChange={this.handleCheckBox} />
              <Typography variant="body2">
                Compare to same data in previous year
              </Typography>
            </Grid>
            {/* <Grid xs={6} container direction='row' alignItems='center'>
              <Checkbox checked={isSeperateByLoc} onChange={this.handleSeperateByLoc} />
              <Typography variant="body2">
                Separate data by same location
              </Typography>
            </Grid> */}
          </Grid>
          {!tab ?
            <Grid xs={4} container direction='row' alignItems='center' >
              <Checkbox checked={isSelected1} onChange={this.handleCheckBox1} />
              <Typography variant="body2">
                Compare to custom date range
              </Typography>
            </Grid> : null}

          {isSelected1 && !tab ? <Grid xs={12} container direction='row' className={classes.grid}>
            <Grid xs={3}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <Grid container >
                  <DatePicker
                    // margin="normal"
                    label="Start Date"
                    name='startDate'
                    value={startDate1}
                    // maxDate={new Date()}
                    onChange={(startDate1) => {
                      if (moment(startDate1).isAfter(endDate1)) {
                        this.setState({ endDate1: startDate1 })
                      }
                      this.setState({ startDate1 })
                    }}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid xs={3}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <Grid container >
                  <DatePicker
                    // margin="normal"
                    label="End Date"
                    name='endDate'
                    // maxDate={new Date()}
                    minDate={startDate1}
                    value={endDate1}
                    onChange={(endDate1) => this.setState({ endDate1 })}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid> : null}

          <Grid className={classes.grid} container xs={12} direction={'row'}>
            <Grid className={classes.space}>
              <TextField
                select
                value={crop}
                label={'Crop'}
                fullWidth
                onChange={(e) => {
                  console.log(e.target)
                  this.setState({ crop: e.target.value })
                }}
                margin="dense"
              >
                {tab && <MenuItem key={'1'} value={'1'}>Select All</MenuItem>}
                {Object.keys(crops).map(key => (
                  <MenuItem key={key} value={key}>{crops[key].name}</MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid className={classes.space} >
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="select-multiple-variety">Variety</InputLabel>
                <Select
                  multiple
                  value={variety}
                  input={<Input id="select-multiple-variety" />}
                  fullWidth
                  onChange={(e) => {
                    if(e.target.value.indexOf("All") > -1 || (variety.indexOf("All") == -1 && e.target.value.indexOf("All") == -1 && e.target.value.length == varietyOptVal.length)){
                      console.log('testtttttttttttt111111111111111111',e.target.value);
                      this.setState({ variety: [...['All'],...varietyOptVal], isVarietySelectAll:true })
                    }else{
                    console.log('testtttttttttttt22222222222222222',e.target.value,variety);
                      if(variety.indexOf("All") > -1){
                        this.setState({ variety: [], isVarietySelectAll:false })
                      }else{
                        this.setState({ variety: e.target.value, isVarietySelectAll:false })
                      }
                    }
                  }}
                  MenuProps={MenuProps}
                  margin="dense"
                >{this.renderVariety(crop)}
                </Select>
              </FormControl>
            </Grid>
            <Grid className={classes.space}>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="select-multiple-farm">Farm</InputLabel>
                <Select
                  multiple
                  value={selectedFarm}
                  input={<Input id="select-multiple-farm" />}
                  label={'Farm'}
                  fullWidth
                  onChange={(e) => {
                    this.setState({ selectedFarm: e.target.value })
                  }}
                  MenuProps={MenuProps}
                  margin="dense"
                >
                  {Object.keys(farm).map(key => (
                    <MenuItem key={key} value={key}>{farm[key].name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid className={classes.space}>
              <TextField
                required
                label="Seeds/Week"
                type="number"
                value={seedsPerWeek}
                style={{ marginTop: 5 }}
                onChange={(e) => {
                  this.setState({ seedsPerWeek: e.target.value, errorMessage: '' })
                }}
              />
            </Grid>

            <Grid className={classes.space}>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="select-multiple-sensor">Sensor Data</InputLabel>
                <Select
                  multiple
                  value={sensorData}
                  input={<Input id="select-multiple-sensor" />}
                  fullWidth
                  onChange={(e) => {
                    this.setState({ sensorData: e.target.value })
                  }}
                  MenuProps={MenuProps}
                  margin="dense"
                >
                  <MenuItem key={`1`} value={`1`}>AVG DLI</MenuItem>
                  <MenuItem key={`2`} value={`2`}>AVG Daily Temp</MenuItem>
                  <MenuItem key={`3`} value={`3`}>AVG Humidity</MenuItem>
                  <MenuItem key={`4`} value={`4`}>High/Low Temp</MenuItem>
                  <MenuItem key={`5`} value={`5`}>High/Low Humidity</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* <Grid xs={2} style={{ marginLeft: '5px' }}>
              <TextField
                required
                select
                value={sensorData}
                label={'Sensor Data'}
                fullWidth
                onChange={(e) => {
                  this.setState({ sensorData: e.target.value })
                }}
                margin="dense"
              >
                <MenuItem key={`1`} value={`1`}>AVG DLI</MenuItem>
                <MenuItem key={`2`} value={`2`}>AVG Daily Temp</MenuItem>
                <MenuItem key={`3`} value={`3`}>AVG Humidity</MenuItem>
                <MenuItem key={`4`} value={`4`}>High/Low Temp</MenuItem>
                <MenuItem key={`5`} value={`5`}>High/Low Humidity</MenuItem>

              </TextField>
            </Grid> */}

            <Grid className={classes.space}>
              {tab ?
                <TextField
                  required
                  select
                  value={ipmNote}
                  label={'IPM Notes to Include'}
                  fullWidth
                  onChange={(e) => {
                    this.setState({ ipmNote: e.target.value })
                  }}
                  margin="dense"
                >
                  {misc.pestList && misc.pestList.map((each, i) => (
                    <MenuItem key={i} value={each}>{each}</MenuItem>
                  ))}
                </TextField>
                : <TextField
                  required
                  select
                  value={reportFormat}
                  label={'Report Format'}
                  fullWidth
                  onChange={(e) => {
                    this.setState({ reportFormat: e.target.value })
                  }}
                  margin="dense"
                >
                  <MenuItem key={`1`} value={`1`}>Highest Batch Weight</MenuItem>
                  <MenuItem key={`2`} value={`2`}>Lowest Batch Weight</MenuItem>
                  <MenuItem key={`3`} value={`3`}>Growth Cycle Comparison</MenuItem>
                </TextField>}
            </Grid>

          </Grid>

          <Grid xs={12} container direction='row' justify='space-between' style={{ paddingRight: '40px', marginTop: '40px' }}>
            <Grid />
            <Grid >
              <Button color='primary' variant="contained" onClick={() => { this.averageSensorData() }}>
                {loading ?
                  <CircularProgress size={20} color={'#fff'} />
                  : 'Submit'}
              </Button>
            </Grid>
          </Grid>
          <Grid container direction='row' justify='space-between' style={{ paddingRight: '40px', marginTop: '10px' }}>
            <Grid />
            {errorMessage && <Typography variant="body2" style={{ color: 'red' }}>{errorMessage}</Typography>}
          </Grid>

        </Grid>

        <Modal
          aria-labelledby="modal-title"
          open={this.state.modalOpen}
          onClose={() => this.setState({ modalOpen: false })}
        >
          <PestListModal
            pestList={misc.pestList}
            onSave={this.savePestList}
            onEdit={this.onEditPestList}
            deletePestType={this.deletePestType}
          />
        </Modal>

      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    crops: state.crops,
    varieties: state.varieties,
    growthCycles: state.growthCycles,
    farm: state.farm,
    batches: state.batches,
    plots: state.plots,
    varietyLegend: state.varietyLegend,
    misc: state.misc,
    users: state.users
  }
}

export default withStyles(styles)(connect(mapStateToProps)(Analytics));

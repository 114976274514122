import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import AvatarEditor from 'react-avatar-editor';
import Slider from '@material-ui/lab/Slider';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core';
import red from '@material-ui/core/colors/red';

const styles = {
  avatar: {
    marginTop: '1rem'
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    marginTop: '1rem',
    borderRadius: '50%',
    opacity: 0,
    background: `rgba(33, 33, 33, 0.7)`,
    zIndex: 5,
    cursor: 'pointer',
    transition: 'opacity 200ms',
    '&:hover': {
      opacity: 1
    }
  },
  overlayButton: {
    color: 'white'
  },
  avatarEdit: {
    
  },
  slider: {
    margin: '1rem 0'
  },
  avatarSave: {
    marginTop: '1.2rem'
  },
  avatarCancel: {
    marginTop: '1.2rem',
    color: red[500]
  },
  input: {
    display: 'none'
  }
};

class AvatarInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
      scale: 1,
      rawImg: null
    };
    this.inputRef = React.createRef();
    this.handleSave = this.handleSave.bind(this);
    this.openFileSelect = this.openFileSelect.bind(this);
    this.handleSlider = this.handleSlider.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  openFileSelect() {
    this.setState({isEditing: true});
    const input = this.inputRef.current;
    input.click();
  }

  handleChange(e) {
    this.setState({rawImg: e.target.files[0]});
  }

  handleSave() {
    if (this.editorRef) {
      const canvas = this.editorRef.getImageScaledToCanvas();
      this.props.onSave(canvas);
      this.setState({isEditing: false});
    }
  }

  handleSlider(e, val) {
    this.setState({scale: val});
  }

  setEditorRef = (editor) => this.editorRef = editor;

  render() {
    const {classes, avatarURL, alt, dimensionSize, editBorder} = this.props;
    
    return (
      <Grid container justify="center">
        <input
          type="file"
          id="avatar-input"
          name="avatar-input"
          accept="image/png, image/jpeg"
          className={classes.input}
          ref={this.inputRef}
          onChange={this.handleChange}
        />

          {this.state.isEditing ? 
            <div>
              <AvatarEditor
                image={this.state.isEditing ? this.state.rawImg : avatarURL}
                width={dimensionSize}
                height={dimensionSize}
                border={editBorder}
                borderRadius={dimensionSize / 2}
                scale={this.state.scale}
                rotate={0}
                className={classes.avatarEdit}
                style={{marginTop: `calc(1rem - ${editBorder}px)`}}
                ref={this.setEditorRef}
              />
              <Slider
                value={this.state.scale}
                onChange={this.handleSlider}
                max={3}
                min={0.75}
                step={0.1}
                className={classes.slider}
                style={{width: dimensionSize + 20}}
              />
              <div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                <Button
                  variant="text" 
                  className={classes.avatarCancel}
                  size="small"
                  onClick={() => this.setState({isEditing: false})}
                  >
                  Cancel
                </Button>
                <Button
                  variant="text" 
                  color="primary"
                  size="small"
                  className={classes.avatarSave}
                  onClick={this.handleSave}
                  >
                  Save
                </Button>
              </div>
              
            </div>
            :
            <div style={{position: 'relative'}}>
              <Avatar
                alt={alt}
                src={avatarURL}
                className={classes.avatar}
                style={{height: dimensionSize, width: dimensionSize}}
              />
              <Grid container className={classes.overlay} style={{height: dimensionSize, width: dimensionSize}} direction="column" justify="center" alignItems="center">
                <Button variant="text" size="large" className={classes.overlayButton} onClick={this.openFileSelect}>Upload</Button>
              </Grid>
            </div>
          }
      </Grid>
      
    );
  }
}

AvatarInput.proptype = {
  classes: PropTypes.object.isRequired,
  avatarURL: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  dimensionSize: PropTypes.number.isRequired,
  editBorder: PropTypes.number.isRequired,
  onSave: PropTypes.func.isRequired
};

export default withStyles(styles)(AvatarInput);
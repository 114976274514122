import {
  DELETE_CROP,
  DELETE_VARIETY,
  DELETE_GROWTH_CYCLE,
  DELETE_RAFT,
  DELETE_TASK,
  DELETE_TASKS,
  DELETE_BATCH,
  DELETE_INVENTORY_ALERT,
  DELETE_ALL_INVENTORY_ALERTS,
  DELETE_PERPETUAL_BATCH,
  DELETE_FARM
} from '../actionTypes';
import firebase from 'firebase/app';
import 'firebase/firestore';
import APP_SETTINGS from '../../utils/appSettings';
import { loadDB } from './loaders';
import { configureStore } from '../../store';

const { COLLECTIONS } = APP_SETTINGS;

export const removeCrop = id => ({
  type: DELETE_CROP,
  id
});

export const removeFarm = id => ({
  type: DELETE_FARM,
  id
});

export const removeVariety = id => ({
  type: DELETE_VARIETY,
  id
});

export const removeGrowthCycle = id => ({
  type: DELETE_GROWTH_CYCLE,
  id
});

export const removeRaft = id => ({
  type: DELETE_RAFT,
  id
});

export const removeBatch = id => ({
  type: DELETE_BATCH,
  id
});

export const removePerpetualBatch = id => ({
  type: DELETE_PERPETUAL_BATCH,
  id
});

export const removeInventoryAlert = varID => ({
  type: DELETE_INVENTORY_ALERT,
  varID
});

export const removeAllInventoryAlerts = () => ({
  type: DELETE_ALL_INVENTORY_ALERTS,
});

export const removeSingleTask = (taskID) => ({
  type: DELETE_TASK,
  taskID
});

export const removeTasks = (batchID) => ({
  type: DELETE_TASKS,
  batchID
});

const parentMap = {
  [COLLECTIONS.VARIETIES]: COLLECTIONS.CROPS,
  [COLLECTIONS.GROWTH_CYCLES]: COLLECTIONS.VARIETIES
};

export const removeBatchThunk = (id) => {
  return dispatch => {
    return dispatch(removeBatch(id));
  };
};

export const archiveItem = async ({ collection, id }) => {
  const db = firebase.firestore();
  return db.collection(collection).doc(id).set({ isArchived: true }, { merge: true })
    .catch(err => console.log(err));
};

export const unArchiveItem = async ({ collection, id }) => {
  const db = firebase.firestore();
  return db.collection(collection).doc(id).set({ isArchived: false }, { merge: true })
    .catch(err => console.log(err));
};

export const deleteItem = async ({ collection, id, parentID }) => {
  const db = firebase.firestore();
  console.log({ collection, id, parentID });
  return db.collection(collection).doc(id).delete()
    .then(() => {
      // parentID is only passed if a parent document needs updated as well
      if (parentID) {
        // get relevant parent document
        return db.collection(parentMap[collection]).doc(parentID).get()
      }
    })
    .then(doc => {
      if (doc) {
        // remove id from list of children
        let children = doc.data()[collection];
        if (!children) { return; }  // parent's list of children should not be empty; this is just in case
        children = children.filter(item => item !== id);
        return doc.ref.set({ [collection]: children }, { merge: true })
          .catch(err => console.log(err));
      }
    })
    .catch(err => console.log(err));
};

export const deleteBatch = async id => {
  const db = firebase.firestore();
  return db.collection(COLLECTIONS.BATCHES).doc(id).set({ isDeleted: true }, { merge: true }).then(() => {
  }).catch(err => console.log(err));

  // return db.collection(COLLECTIONS.BATCHES).doc(id).delete()
  //   .catch(err => console.log(err));
};

export const deletePerpetualBatch = async id => {
  const db = firebase.firestore();
  return db.collection(COLLECTIONS.PERPETUAL_BATCHES).doc(id).delete()
    .catch(err => console.log(err));
};

export const deleteLowInventoryItem = async varID => {
  const db = firebase.firestore();
  const lowInventoryDocRef = db.collection(COLLECTIONS.MISC).doc('lowInventory');
  return db.runTransaction(transaction => {
    return transaction.get(lowInventoryDocRef).then(lowInventoryDoc => {
      if (!lowInventoryDoc.exists) { throw new Error("Document does not exist.") }
      let list = lowInventoryDoc.data().list.slice();
      if (list.includes(varID)) {
        list.splice(list.indexOf(varID), 1);
      }
      transaction.update(lowInventoryDocRef, { list });
    });
  })
    .catch(err => console.log(err));
};

export const deleteAllLowStockAlerts = async () => {
  const db = firebase.firestore();
  return db.collection(COLLECTIONS.MISC).doc('lowInventory').set({ list: [] })
    .catch(err => console.log(err));
};
import moment from 'moment';

export function deepCopyObject(obj) {
  return obj ? JSON.parse(JSON.stringify(obj)) : {};
};

export function objectIsEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key))
      return false;
  }
  return true;
}

export function fix2(number) {
  return parseFloat(parseFloat(number || 0).toFixed(2))
}

export function isNumberic(num) {
  let res = null;
  if (typeof num == "number" || typeof num == "string") {
    let stringNum = typeof num == "number" ? num.toString() : num;
    res = parseFloat(stringNum);
  }
  return res;
}

export function objectsAreEqual(obj1, obj2) {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
}

export function fillNumberStringToLength(item, length) {
  if (!length) { return item; }

  item = item.toString();
  while (item.length < length) {
    item = '0'.concat(item);
  }
  return item;
}

export function celciusToFahrenheit(celsius) 
{ 

  let cTemp = celsius;
  let cToFahr = cTemp * 9 / 5 + 32;
  return cToFahr;
}

function fahrenheitToCelcius(fahrenheit) 
{
  let fTemp = fahrenheit;
  let fToCel = (fTemp - 32) * 5 / 9;
  return fToCel;
} 

export function checkArray(arr, res = true, val = false) {
  return arr && Array.isArray(arr) && arr.length > 0 ? res : val;
}

export function capitalize(str) {
  return str.replace(/\b\w/g, l => l.toUpperCase());
}

export function roundToHalf(value) {
  // from https://www.breakingpar.com/bkp/home.nsf/0/87256b280015193f87257124007e0011
  if (value === '' || value === null) {
    return '';
  }
  var converted = parseFloat(value);
  var decimal = (converted - parseInt(converted, 10));
  decimal = Math.round(decimal * 10);
  if (decimal === 5) { return (parseInt(converted, 10) + 0.5); }
  if ((decimal < 3) || (decimal > 7)) {
    return Math.round(converted);
  } else {
    return (parseInt(converted, 10) + 0.5);
  }
}

export function sortFunction(a = {}, b = {}, direction = '', attribute = '') {
  // handle undefined objects
  if (!a || !b) {
    return 0;
  }
  const multiplier = direction === 'asc' ? 1 : -1;

  if (attribute.toLowerCase().includes('date')) {
    if (moment(a[attribute]).isBefore(b[attribute])) { return -1 * multiplier; }
    if (moment(a[attribute]).isAfter(b[attribute])) { return 1 * multiplier; }
    return 0;
  } else {
    if (a[attribute] < b[attribute]) { return -1 * multiplier; }
    if (a[attribute] > b[attribute]) { return 1 * multiplier; }
    return 0;
  }
}

export function getRGBFromHex(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  const rgbColor = result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
  return rgbColor;
}

export function getBrightnessFromHex(hex) {
  const rgbColor = getRGBFromHex(hex);
  if (rgbColor) {
    const brightness = ((rgbColor.r * 299) + (rgbColor.g * 587) + (rgbColor.b * 114)) / 1000;
    return brightness;
  } else {
    return 0;
  }
}

export function checkNaN(number, isTemp) {
  if (!isNaN(number)) return (isTemp ? parseFloat((number * 1.8) + 32).toFixed(2) : number);
  else return ('0')
}

export function lightenDarkenColor(col, amt) {
  // from https://css-tricks.com/snippets/javascript/lighten-darken-color/
  var usePound = false;

  if (col[0] === "#") {
    col = col.slice(1);
    usePound = true;
  }

  var num = parseInt(col, 16);

  var r = (num >> 16) + amt;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  var b = ((num >> 8) & 0x00FF) + amt;

  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  var g = (num & 0x0000FF) + amt;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
}

export function checkNull(string, val = "") {
  return string && string !== "" && string !== null
    ? typeof string == "number"
      ? string.toString()
      : string
    : val;
}

export function groupByArray(objectArray, property) {
  return objectArray.reduce((acc, obj) => {
    const key = obj[property];
    if (!acc[key]) {
      acc[key] = [];
    }
    // Add object to list for given key's value
    acc[key].push(obj);
    return acc;
  }, {});
}
import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import { DatePicker } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import GrowthCycleCard from './GrowthCycleCard';
import { generateTasks } from '../utils/appFunctions/tasks';
import { recalcDatesInGrowthCycle } from '../utils/appFunctions/growthCycles';
import { getGRFlot } from '../utils/appFunctions/batches';
import moment from 'moment';
import APP_SETTINGS from '../utils/appSettings';
import { sortFunction, deepCopyObject } from '../utils/utils';

const {HARVEST_TYPES} = APP_SETTINGS;


const styles = theme => ({
  modal: {
    position: 'absolute',
    top: '47%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    maxHeight: '80%',
    minWidth: 650,
    maxWidth: 800,
    overflow: 'scroll',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    '&:focus': {
      outline: 'none'
    }
  },
  container: {
    width: '100%'
  },
  buttonContainer: {
    marginTop: '2rem',
    width: '100%'
  },
  button: {
    margin: '0.5rem 1rem'
  },
  menuItem: {
    '& li': {
      minHeight: 'max-content',
      padding: '0.5rem'
    }
  },
  submitButtonLoader: {
    color: 'white'
  },
  iconPositionFix: {
    marginTop: '1rem'
  }
});

class StartBatchModal extends React.Component { // NOT IN USE
  constructor(props){
    super(props);
    this.state = {
      crop: '',
      variety: '',
      growthCycle: '',
      growthCycleName: '',
      seedDate: moment().format('YYYY-MM-DD'),
      initiationDate: moment().format('YYYY-MM-DD'),
      anchorEl: null,
      errors: {
        crop: false,
        variety: false,
        initiationDate: false,
        seedDate: false
      },
      isSaving: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.openGrowthCycleMenu = this.openGrowthCycleMenu.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }

  handleChange = name => e => {
    // const value = (name === 'initiationDate' || name === 'seedDate') ? moment(e.target.value).format('YYYY-MM-DD') : e.target.value;
    this.setState({
      [name]: e.target.value
    }, () => {
      if (name === 'crop') {
        this.setState({variety: '', growthCycle: ''});
      }
      if (name === 'variety') {
        this.setState({growthCycle: ''});
      }
    });
  };

  formatDate = (e,name) => {
    let oldDate = e.target.value;
    if(!moment(oldDate).isValid()){
        return this.setState({[name]:moment().format('YYYY-MM-DD')})
    }
    let date = oldDate.split('/')
    if (date.length === 2) {
        if (date[0].length < 2) {
            oldDate = '0' + date[0]
        }
    }
    else if (date.length === 3) {
        var test0 = date[0] + '/';
        var test1 = date[1] + '/';
        if (date[0].length < 2) {
             test0 = '0' + test0
        }
        if (date[1].length < 2) {
             test1 = '0' + test1
        }
        oldDate = test0 + test1 +  date[2]
    }
    this.setState({[name]:oldDate})
}

  openGrowthCycleMenu(e) {
    this.setState({anchorEl: e.currentTarget});
  }

  closeMenu(gcID) {
    if (gcID) {
      this.setState({growthCycle: gcID});
    }
    this.setState({anchorEl: null});
  }

  async handleSubmit(e) {
    e.preventDefault();


    const requiredFields = ['crop', 'variety','growthCycle'];
    let errorsFound = false;
    let errors = deepCopyObject(this.state.errors);

    requiredFields.forEach((name) => {
      if (!this.state[name] || this.state[name] === '') {
        errorsFound = true;
        errors[name] = true;
      }
    });

    if (errorsFound) {
      this.setState({ errors });
      return;
    }    

    this.setState({isSaving: true});
    const {crop, variety, growthCycle, initiationDate, seedDate} = this.state;
    let growthCycleObj = this.props.growthCycles[growthCycle];

    // calculate all planned dates using growth cycle info and initiationDate
    growthCycleObj = recalcDatesInGrowthCycle(growthCycleObj, initiationDate, seedDate);

    // get new GRF lot #    
    const GRFlot = getGRFlot({initiationDate, varietyCode: this.props.varieties[variety].varietyCode});

    // generate all tasks
    // NOT IN USE
    const tasks = generateTasks({seedDate, GRFlot, crop, variety, growthCycleObj});

    let batch = {
      crop, variety,
      growthCycle: growthCycleObj,
      growthCycleID: growthCycle,
      isCommitted: true,
      GRFlot,
      tasks,
      harvest: [],
      disposal: [],
      harvestDate: growthCycleObj.harvestType === HARVEST_TYPES.REPEAT ? [] : null,
      current: {stage: 'planned', cells: growthCycleObj.germ.cells}
    };

    this.props.onSave(batch);
  }

  render() {
    const {classes, crops, varieties, growthCycles} = this.props;
    return (
      <div className={classes.modal} tabIndex={-1}>
        <Grid container className={classes.container} spacing={16}>
          <Grid item xs={12}>
            <Typography variant="h4" id="modal-title">Start New Batch</Typography>
          </Grid>
        </Grid>
        <form onSubmit={this.handleSubmit}>
          <Grid container className={classes.container} spacing={16}>

            <Grid item xs={3}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  label="Seed Date"
                  error={this.state.errors.seedDate}
                  variant="filled"
                  value={this.state.seedDate}
                  format="MM/DD/YYYY"
                  // mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                  onChange={(date) => this.handleChange('seedDate')({ target: {value: date} })}
                  onBlur={(e) => this.formatDate(e,'seedDate')}
                  animateYearScrolling
                  autoOk
                  keyboard
                  margin="normal"
                  fullWidth
                  InputAdornmentProps={{
                    classes: {
                      root: classes.iconPositionFix
                    }
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={3}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  label="Start Date"
                  error={this.state.errors.initiationDate}
                  variant="filled"
                  value={this.state.initiationDate}
                  format="MM/DD/YYYY"
                  // mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                  onChange={(date) => this.handleChange('initiationDate')({ target: {value: date} })}
                  onBlur={(e) => this.formatDate(e,'initiationDate')}
                  animateYearScrolling
                  autoOk
                  keyboard
                  margin="normal"
                  fullWidth
                  InputAdornmentProps={{
                    classes: {
                      root: classes.iconPositionFix
                    }
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={3}>
              <TextField
                required
                select
                error={this.state.errors.crop}
                variant="filled"
                id="crop"
                label="Crop"
                value={this.state.crop}
                onChange={this.handleChange('crop')}
                margin="normal"
                fullWidth
              >
                {Object.keys(crops).sort((a, b) => sortFunction(crops[a], crops[b], 'asc', 'name')).map(cropID => {
                  const crop = crops[cropID];
                  return (
                    <MenuItem key={cropID} value={cropID}>{crop.name}</MenuItem>
                  );
                })}
              </TextField>
            </Grid>
            <Grid item xs={3}>
              <TextField
                required
                select
                disabled={!this.state.crop}
                error={this.state.errors.variety}
                variant="filled"
                id="variety"
                label="Variety"
                value={this.state.variety}
                onChange={this.handleChange('variety')}
                margin="normal"
                fullWidth
              >
                {this.state.crop ?
                  crops[this.state.crop].varieties.sort((a, b) => sortFunction(varieties[a], varieties[b], 'asc', 'name')).map(varID => {
                    const variety = varieties[varID];
                    if(variety) return (
                      <MenuItem key={varID} value={varID}>{variety.name}</MenuItem>
                    );
                  })
                  :
                  <MenuItem></MenuItem>
              }
              </TextField>
            </Grid>
          </Grid>
          <Grid container className={classes.container} spacing={16}>
            <Grid item xs={3}>
              <Typography variant="h6" style={{fontSize: '1.2rem', fontWeight: 400}}>Growth Cycle:</Typography>
            </Grid>
            <Grid item xs={7}>
              {this.state.growthCycle ? 
                <GrowthCycleCard
                  growthCycle={this.props.growthCycles[this.state.growthCycle]}
                />
                :
                <Button
                  onClick={this.openGrowthCycleMenu}
                  disabled={!this.state.variety}
                  style={{color:this.state.errors.growthCycle ? 'red' : 'black'}}
                >
                  Click to choose
                </Button>
              }
            </Grid>
            <Grid item xs={2}>
            {this.state.growthCycle && 
              <Button
                onClick={this.openGrowthCycleMenu}
                disabled={!this.state.variety}
              >
                Select New
              </Button>
            }
            </Grid>
            <Menu
              id="duplicateMenu"
              open={!!this.state.anchorEl}
              anchorEl={this.state.anchorEl}
              onClose={() => this.closeMenu()}
              className={classes.menuItem}
            >
              {this.state.variety && varieties[this.state.variety].growthCycles && varieties[this.state.variety].growthCycles.map(gcID => {
                const growthCycle = growthCycles[gcID];
                return (
                  <MenuItem key={gcID} onClick={() => this.closeMenu(gcID)}>
                    <GrowthCycleCard
                      growthCycle={growthCycle}
                    />
                  </MenuItem>
                );
              })}
            </Menu>
          </Grid>
          <Grid container className={classes.buttonContainer} justify="flex-end">
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              className={classes.button}
              onClick={this.handleSubmit}
              disabled={(!this.state.crop && !this.state.variety && !this.state.growthCycle && !this.state.initiationDate) || this.state.isSaving}
            >
              {this.state.isSaving ?
                <CircularProgress className={classes.submitButtonLoader} size={21} />
                :
                'Save'
              }
            </Button>
          </Grid>
        </form>

      </div>
    );
  }
}

StartBatchModal.propTypes = {
  classes: PropTypes.object.isRequired,
  crops: PropTypes.object.isRequired,
  varieties: PropTypes.object.isRequired,
  growthCycles: PropTypes.object.isRequired,
  reasons: PropTypes.array.isRequired,
  onSave: PropTypes.func.isRequired
};

export default withStyles(styles)(StartBatchModal);
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';
import DevContext from '../context_providers/DevContext';
import Title from '../components/Title';
import FullScreenLoader from '../components/FullScreenLoader';
import GrowCurrent from './GrowCurrent';
import GrowTodo from './GrowTodo';
import StartBatchModal from '../components/StartBatchModal';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Modal from '@material-ui/core/Modal';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core';
import { CSVLink } from "react-csv";

import { addEditBatch, addEditItem } from '../store/actions/adders';
import { deleteBatch, deletePerpetualBatch } from '../store/actions/deleters';
import { sortFunction, objectsAreEqual, checkArray } from '../utils/utils';
import { connect } from 'react-redux';
import APP_SETTINGS from '../utils/appSettings';
import BatchModal from '../components/BatchModal';
import TodayPredHarvest from '../components/TodayPredHarvest';

const { COLLECTIONS, STAGES } = APP_SETTINGS;

const styles = {
  topButton: {
    marginRight: '1.5rem'
  },
  buttonIcon: {
    marginRight: '0.6rem'
  },
  tabContainer: {
    margin: '3rem 0 1rem'
  },
  tabs: {
    width: '60%',
    minWidth: 400,
    '& button': {
      width: '50%',
      maxWidth: 'none'
    }
  },
  tab: {
    fontSize: '1.1rem'
  },
  divider: {
    width: '100%',
    marginTop: '-1px'
  }
};

class Grow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      tabValue: 0,
      pageNoTodo: 1,
      pageNoCurrent: 1,
      pageSize: 5,
      modalOpen: false,
      currentModal: null,
      currentBatch: "",
      snackbarOpen: false,
      snackbarMessage: '',
      snackBarUndo: false,
      todoStartDate: moment().format('YYYY-MM-DD'),
      todoEndDate: moment().add(2, 'days').format('YYYY-MM-DD'),
      todoFilter: 'all',
      todoDateFilter: 'seeall',
      todoSort: 'asc',
      todoUser: '',
      earliestTask: null,
      latestTask: null,
      showCompleted: false,
      currentFilter: 'current',
      currentSort: 'initiationDate-asc',
      currentSearch: '',
      currentStartDate: moment(),
      currentEndDate: null,
      CSVData: []
    };

    this.inputRef = React.createRef();

    this.handleSeedNewClick = this.handleSeedNewClick.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.createNewBatch = this.createNewBatch.bind(this);
    this.renderModal = this.renderModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.closeSnackBar = this.closeSnackBar.bind(this);
    this.handleToDoChange = this.handleToDoChange.bind(this);
    this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
    this.handleTodayClick = this.handleTodayClick.bind(this);
    this.handleThisWeekClick = this.handleThisWeekClick.bind(this);
    this.handleSeeAllClick = this.handleSeeAllClick.bind(this);
    this.handleSearchSelect = this.handleSearchSelect.bind(this);
    this.handleCurrentChange = this.handleCurrentChange.bind(this);
    this.dangerouslyDeleteAllBatches = this.dangerouslyDeleteAllBatches.bind(this);
    this.handleFileSelect = this.handleFileSelect.bind(this);
    this.handlePageNoChange = this.handlePageNoChange.bind(this);
    this.handlePageSizeChange = this.handlePageSizeChange.bind(this);

  }

  csvLink = React.createRef();


  async componentDidMount() {
    await Promise.all([this.findTodoDateBoundaries(), this.findBatchDateBoundaries()]).then(resp => {
      if(resp[0] && resp[1]){
        this.setState({ isLoaded: true, earliestTask:resp[0].earliestTask, latestTask:resp[0].latestTask, currentStartDate: resp[1].earliestBatch, currentEndDate: resp[1].latestBatch });
      }else{
        this.setState({ isLoaded: true});
        alert("unable to load data");
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (!objectsAreEqual(this.props.tasks, prevProps.tasks)) {
      const { earliestTask, latestTask } = this.findTodoDateBoundaries();
      this.setState({ earliestTask, latestTask });
    }

    if (!objectsAreEqual(this.props.batches, prevProps.batches)) {
      const { earliestBatch, latestBatch } = this.findBatchDateBoundaries();
      this.setState({ currentStartDate: earliestBatch, currentEndDate: latestBatch });
    }
  }

  findTodoDateBoundaries(showCompleted = this.state.showCompleted) {
    const { tasks } = this.props;
    const taskIDList = Object.keys(tasks)
      .filter(taskID => showCompleted ? true : !tasks[taskID].isComplete)
      .sort((a, b) => sortFunction(tasks[a], tasks[b], this.state.todoSort, 'date'));

    let earliestTask = moment().format('YYYY-MM-DD');
    let latestTask = moment().format('YYYY-MM-DD');
    if (taskIDList.length > 0) {
      earliestTask = moment.min(moment(tasks[taskIDList[0]].date), moment()).format('YYYY-MM-DD');
      latestTask = tasks[taskIDList[taskIDList.length - 1]].date;
    }

    return { earliestTask, latestTask };
  }

  findBatchDateBoundaries() {
    const { batches } = this.props;
    const batchIDList = Object.keys(batches)
      .filter(batchID => batches[batchID].current ? (batches[batchID].current.stage !== STAGES.COMPLETED) : false)
      .sort((a, b) => sortFunction(batches[a].growthCycle.germ, batches[b].growthCycle.germ, 'asc', 'date'));

    let earliestBatch = moment().format('YYYY-MM-DD');
    let latestBatch = moment().format('YYYY-MM-DD');
    if (batchIDList.length > 0) {
      earliestBatch = moment.min(moment(batches[batchIDList[0]].growthCycle.germ.date), moment(earliestBatch)).format('YYYY-MM-DD');
      latestBatch = moment.max(moment(batches[batchIDList[batchIDList.length - 1]].growthCycle.germ.date), moment(latestBatch)).format('YYYY-MM-DD');
    }

    return { earliestBatch, latestBatch };
  }

  handleSeedNewClick() {
    this.setState({ modalOpen: true, currentModal: 'newBatch' });
  }

  async createNewBatch(batch) {
    await addEditBatch({ batch });
    this.setState({ modalOpen: false, currentModal: '', snackbarOpen: true, snackbarMessage: 'Batch created!', snackBarUndo: false });
  }

  handleTabChange = (e, tabValue) => {
    this.setState({ tabValue });
  };

  handlePageNoChange = (pageType, num) => {
    if(pageType === "current"){
      this.setState({ pageNoCurrent: num });
    }else{
      this.setState({ pageNoTodo: num });
    }
  };
  handlePageSizeChange = (num) => {
    this.setState({ pageSize: num });
  };

  handleToDoChange = name => e => {
    console.log('handleToDoChange', name, e.target.value)
    // // check if date is after endDate, if so set to endDate
    if (name === 'startDate') {
      if (moment(e.target.value).isAfter(this.state.todoEndDate)) {
        this.setState({ modalOpen: false, currentModal: '', snackbarOpen: true, snackbarMessage: 'Start date must be before End date', snackBarUndo: false });
        // this.setState({ todoStartDate: this.state.todoEndDate });
        return;
      }
    }

    // check if date is before startDate, if so set to startDate
    if (name === 'endDate') {
      if (moment(e.target.value).isBefore(this.state.todoStartDate)) {
        this.setState({ modalOpen: false, currentModal: '', snackbarOpen: true, snackbarMessage: 'End date must be after Start date', snackBarUndo: false });
        return;
      }
    }

    // const value = name.toLowerCase().includes('date') ? moment(e.target.value).format('YYYY-MM-DD') : e.target.value;
    const key = `todo${name.replace(/\b\w/g, l => l.toUpperCase())}`;
    this.setState({
      [key]: e.target.value
    });
  };

  showSnakbar = () => {
    this.setState({ modalOpen: false, currentModal: '', snackbarOpen: true, snackbarMessage: 'Batch updated successfully', snackBarUndo: false });
  }

  formatDate = (e) => {
    let name = e.target.name
    let oldDate = e.target.value;
    if (!moment(oldDate).isValid()) {
      return this.setState({ [name]: moment().format('YYYY-MM-DD') })
    }
    let date = oldDate.split('/')
    if (date.length === 2) {
      if (date[0].length < 2) {
        oldDate = '0' + date[0]
      }
    }
    else if (date.length === 3) {
      var test0 = date[0] + '/';
      var test1 = date[1] + '/';
      if (date[0].length < 2) {
        test0 = '0' + test0
      }
      if (date[1].length < 2) {
        test1 = '0' + test1
      }
      oldDate = test0 + test1 + date[2]
    }
    this.setState({ [name]: moment(oldDate).format('YYYY-MM-DD') })
  }

  handleCheckBoxChange = name => e => {
    if (name === 'showCompleted') {
      const { earliestTask, latestTask } = this.findTodoDateBoundaries(e.target.checked);
      this.setState({ earliestTask, latestTask, [name]: e.target.checked });
    }
    else if (name === 'selectAll') {
      this.setState({ [name]: e.target.checked });
    } else {
      this.setState({ [name]: e.target.checked });
    }
  }

  handleTodayClick() {
    const date = moment().format('YYYY-MM-DD');
    this.setState({ todoStartDate: date, todoEndDate: date, todoDateFilter: 'today' });
  }

  handleDateRangeClick = () => {
    this.setState({ todoDateFilter: 'daterange' })
  }

  handleThisWeekClick() {
    const today = moment();
    const dayOfWeekDiff = 1 - moment(today).day();
    const weekStartDate = moment(today).add(dayOfWeekDiff, 'days').format('YYYY-MM-DD');
    const weekEndDate = moment(weekStartDate).add(6, 'days').format('YYYY-MM-DD');
    this.setState({ todoStartDate: weekStartDate, todoEndDate: weekEndDate, todoDateFilter: 'thisweek' });
  }

  handleSeeAllClick() {
    this.setState({ todoStartDate: this.state.earliestTask, todoEndDate: this.state.latestTask, todoDateFilter: 'seeall' });
  }

  handleSearchSelect(selection) {
    this.setState({ currentSearch: selection });
  }

  handleCurrentChange = name => e => {
    const key = `current${name.replace(/\b\w/g, l => l.toUpperCase())}`;
    this.setState({
      [key]: e.target.value
    });
  }

  renderModal() {
    switch (this.state.currentModal) {
      case 'newBatch':
        return (
          <StartBatchModal
            crops={this.props.crops}
            varieties={this.props.varieties}
            growthCycles={this.props.growthCycles}
            farm={this.props.farm}
            onSave={this.createNewBatch}
            reasons={this.props.misc.reasons}
          />
        );
      case 'deleteBatch':
        return (
          <BatchModal
            // addEditItem={addEditItem}
            deleteItem={this.deleteBatch}
            showSnakbar={this.showSnakbar}
            batches={this.props.batches}
            tasks={this.props.tasks}
            crops={this.props.crops}
            farm={this.props.farm}
            users={this.props.users}
            varieties={this.props.varieties}
          />
        );
      case 'todayHarvest':
        return (
          <TodayPredHarvest
            batches={this.props.batches}
            tasks={this.props.tasks}
            crops={this.props.crops}
            farm={this.props.farm}
            users={this.props.users}
            varieties={this.props.varieties}
            closeModal={() => this.setState({ modalOpen: false, currentModal: '' })}
          />
        );
      default:
        return <div>This is not the modal you're looking for.</div>
    }
  }

  closeModal() {
    this.setState({ modalOpen: false, currentModal: null, currentBatch: null });
  }

  closeSnackBar() {
    this.setState({ snackbarOpen: false, snackbarMessage: '', snackBarUndo: false });
  }

  async dangerouslyDeleteAllBatches() {
    // INTENDED ONLY FOR DEVELOPMENT USE
    const { batches, crops, varieties, growthCycles, perpetualBatches } = this.props;
    for (let batchID in batches) {
      await deleteBatch(batchID);
    }
    for (let perpID in perpetualBatches) {
      deletePerpetualBatch(perpID);
    }
    for (let cropID in crops) {
      addEditItem({ collection: COLLECTIONS.CROPS, id: cropID, item: { hasBeenUsed: false } });
    }
    for (let varID in varieties) {
      addEditItem({ collection: COLLECTIONS.VARIETIES, id: varID, item: { hasBeenUsed: false } });
    }
    for (let gcID in growthCycles) {
      addEditItem({ collection: COLLECTIONS.GROWTH_CYCLES, id: gcID, item: { hasBeenUsed: false } });
    }
  }

  deleteBatch = () => {
    const { batches, crops, varieties, growthCycles, perpetualBatches } = this.props;

  }

  handleFileSelect(e) {
    const fileReader = new FileReader();
    let jsonFile = {};
    fileReader.onload = async (evt) => {
      jsonFile = JSON.parse(evt.target.result);

      await this.props.handleDataImport(jsonFile);
    };
    fileReader.readAsText(e.target.files[0]);
  }


  exportCSV = (newList) => {

    let list = newList.map(each => each.split('-')[0])

    let arr = [
      ["Grow Media Type", "Seed Date", 'Start Date', 'Lot No.', "Variety", "Number to be seeded", "date it is trxfed to prop", "Date it is split to HD", "Date it is split to FS", "Harvest date", 'Finishing Location', "Weight of seed / unit", "Total weight of seed needed"]
    ];

    list.forEach(batchId => {


      const batch = this.props.batches[batchId];
      const { growthCycle } = batch;
      const isRockWool = growthCycle.growMedia === 'Rockwool';

      let finishingLocation = ''
      let weightOfSeed = ''
      let trayXweightOfSeed = ''
      const totalTrays = (batch?.propLocation1?.pond?.prop && checkArray(batch?.propLocation1?.pond?.prop))?batch?.propLocation1?.pond?.prop.reduce((acc, curr) => {
        return acc + parseFloat(curr.noOfRaft || 0);
      }, 0): 0;

      if (isRockWool) {
        batch.finishingLocation.pond.fs.forEach(eachPond => {
          // finishingLocation += eachPond.name +', '
          finishingLocation += + `${eachPond.noOfRaft} rafts in ${eachPond.name}, `;
        })
      }
      else {
        batch.propLocation1.pond.prop.forEach(eachPond => {
          // finishingLocation += eachPond.name +', '
          finishingLocation += + `${eachPond.noOfRaft} rafts in ${eachPond.name}, `;
        })
        weightOfSeed = `${(growthCycle?.germ?.weight || '')} ${(growthCycle?.germ?.unit || '')}`;
        trayXweightOfSeed = `${totalTrays * parseFloat(growthCycle?.germ?.weight || 0)}`;
      }

      arr.push([
        growthCycle.growMedia,
        growthCycle.seed.date,
        moment(batch.initiationDate).format('YYYY-MM-DD'),
        batch.seedLot,
        this.props.varieties[batch.variety].name,
        isRockWool ? batch.current.cells + ' cells' : batch.QtyToSeed + ' trays',
        growthCycle.prop.date,
        isRockWool ? growthCycle.hd.date : '-',
        isRockWool ? growthCycle.fs.date : '-',
        growthCycle.harvest.date,
        finishingLocation,
        weightOfSeed,
        trayXweightOfSeed
      ])
    });
    this.setState({ CSVData: arr }, () => {
      setTimeout(() => {
        this.csvLink.current.link.click()
      }, 1000);
    })
  };

  render() {
    const { classes } = this.props;
    return (
      this.state.isLoaded ?
        <div>
          <CSVLink
            data={this.state.CSVData}
            filename={moment().format('YYYY-MM-DD') + '.csv'}
            className="hidden"
            ref={this.csvLink}
            target="_blank"
          />
          <Title title="Grow" />

          <DevContext.Consumer>
            {devMode =>
              <div>
                {/* <Button variant="outlined" size="small" color="secondary" className={classes.topButton} onClick={this.handleSeedNewClick}>
                  <i className={`${classes.buttonIcon} fas fa-plus-circle fa-2x`}></i>
                Seed New Batch
              </Button> */}

                {devMode &&
                  <Button variant="outlined" size="small" color="secondary" className={classes.topButton} onClick={this.dangerouslyDeleteAllBatches}>
                    <i className={`${classes.buttonIcon} fas fa-plus-circle fa-2x`}></i>
                    Delete all batches
                  </Button>
                }
                <Button variant="outlined" size="small" color="secondary" className={classes.topButton} onClick={() => { this.setState({ modalOpen: true, currentModal: 'deleteBatch' }) }}>
                  {/* <i className={`${classes.buttonIcon} fas fa-plus-circle fa-2x`}></i> */}
                  batches
                </Button>
                {devMode &&
                  <Button
                    variant="outlined"
                    size="small"
                    color="secondary"
                    className={classes.topButton}
                    component={Link}
                    to="/export"
                  >
                    <i className={`${classes.buttonIcon} fas fa-plus-circle fa-2x`}></i>
                    Export data
                  </Button>
                }
                {devMode &&
                  <span>
                    <input
                      type="file"
                      id="data-import"
                      name="data-import"
                      accept=".json"
                      style={{ display: 'none' }}
                      ref={this.inputRef}
                      onChange={this.handleFileSelect}
                    />
                    <Button variant="outlined" size="small" color="secondary" className={classes.topButton} onClick={() => this.inputRef.current.click()}>
                      <i className={`${classes.buttonIcon} fas fa-plus-circle fa-2x`}></i>
                      Import data
                    </Button>
                  </span>
                }
                <Button variant="outlined" size="small" color="secondary" className={classes.topButton} onClick={() => { this.setState({ modalOpen: true, currentModal: 'todayHarvest' }) }}>
                    Today's Predicted Harvest 
                </Button>
              </div>
            }
          </DevContext.Consumer>

          <Grid container className={classes.tabContainer} justify="flex-start">
            <Tabs
              value={this.state.tabValue}
              onChange={this.handleTabChange}
              indicatorColor="secondary"
              textColor="secondary"
              centered
              className={classes.tabs}
            >
              <Tab label="To Do" className={classes.tab} />
              <Tab label="Current Growth" className={classes.tab} />
            </Tabs>
            <Divider className={classes.divider} />
          </Grid>
          {/* {console.log('this.state', this.state)} */}
          {this.state.tabValue === 0 &&
            <GrowTodo
              filterState={this.state}
              handleChange={this.handleToDoChange}
              handleCheckBoxChange={this.handleCheckBoxChange}
              handleTodayClick={this.handleTodayClick}
              handleDateRangeClick={this.handleDateRangeClick}
              handleThisWeekClick={this.handleThisWeekClick}
              handleSeeAllClick={this.handleSeeAllClick}
              formatDate={this.formatDate}
              csvDownload={this.exportCSV}
              pageNo={this.state.pageNoTodo}
              pageSize={this.state.pageSize}
              handlePageNoChange={this.handlePageNoChange}
              handlePageSizeChange={this.handlePageSizeChange}
            />

          }
          {this.state.tabValue === 1 &&
            <GrowCurrent
              filterState={this.state}
              handleChange={this.handleCurrentChange}
              handleSearchSelect={this.handleSearchSelect}
              formatDate={this.formatDate}
              pageNo={this.state.pageNoCurrent}
              pageSize={this.state.pageSize}
              handlePageNoChange={this.handlePageNoChange}
              handlePageSizeChange={this.handlePageSizeChange}
            />
          }

          <Modal
            aria-labelledby="modal-title"
            open={this.state.modalOpen}
            onClose={this.closeModal}
          >
            {this.renderModal()}
          </Modal>

          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={this.state.snackbarOpen}
            autoHideDuration={3000}
            onClose={this.closeSnackBar}
            ContentProps={{
              'aria-describedby': 'message-id',
            }}
            message={<span id="message-id">{this.state.snackbarMessage}</span>}
            action={this.state.snackBarUndo ?
              [
                <Button key="undo" color="primary" size="small" onClick={this.handleUndo}>UNDO</Button>,
                <IconButton
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  className={classes.close}
                  onClick={this.closeSnackBar}
                >
                  <i className="fas fa-times"></i>
                </IconButton>
              ]
              :
              [
                <IconButton
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  className={classes.close}
                  onClick={this.closeSnackBar}
                >
                  <i className="fas fa-times"></i>
                </IconButton>
              ]
            }
          />
        </div>
        :
        <FullScreenLoader />
    );
  }
};

Grow.propTypes = {
  classes: PropTypes.object.isRequired,
  users: PropTypes.object.isRequired,
  crops: PropTypes.object.isRequired,
  varieties: PropTypes.object.isRequired,
  growthCycles: PropTypes.object.isRequired,
  misc: PropTypes.object.isRequired,
  batches: PropTypes.object.isRequired,
  perpetualBatches: PropTypes.object.isRequired,
  tasks: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  handleDataImport: PropTypes.func.isRequired,
  farm: PropTypes.object
};

function mapStateToProps(state) {
  return {
    crops: state.crops,
    users: state.users,
    varieties: state.varieties,
    growthCycles: state.growthCycles,
    misc: state.misc,
    farm: state.farm,
    batches: state.batches,
    perpetualBatches: state.perpetualBatches,
    tasks: state.tasks,
  }
}

export default withStyles(styles)(connect(mapStateToProps, null)(Grow));
import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { FormControl, InputLabel, MenuItem, Select, withStyles, Typography, Checkbox, ListItemIcon, ListItemText, Snackbar, CircularProgress } from '@material-ui/core';
import { connect } from 'react-redux';
import APP_SETTINGS from '../utils/appSettings';
import moment from 'moment';
import FullScreenLoader from '../components/FullScreenLoader';
import { getFarms, getPerpitualBatches } from '../store/actions/adders';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Modal from '@material-ui/core/Modal';

import BatchModal from '../components/BatchModal';
import PoolMap from '../components/PoolMap';
import PlotLegend from '../components/PlotLegend';
import NewBatchForm from '../components/NewBatchForm';
import Title from '../components/Title';
import DateSelector from '../components/DateSelector';
import { checkArray, deepCopyObject, fix2 } from '../utils/utils';


const { PLOTS, STAGES } = APP_SETTINGS;

const styles = {
  poolMapContainer: {
    marginBottom: '3rem',
  },
  poolMap: {
  },
  plotLegend: {
    padding: '0.5rem 1.5rem !important'
  },
  dateSelectorContainer: {
    marginBottom: '2rem'
  },
  expandButtonContainer: {
    marginTop: '3rem',
    textAlign: 'center'
  },
  formControl: {
    margin: 10,
    minWidth: 120,
  },
};
class Planning extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: moment().format('YYYY-MM-DD'),
      showLargePools: false,
      currentVarities: [],
      isLoaded: false,
      allFarms: [],
      allPerpitualBatches: [],
      currentFarm: '',
      currentPond: [],
      snackbarOpen: false,
      snackbarMessage: '',
      openPrompt: false,
      openPrompt1: false,
      modalOpen: false,
      currentModal: null,
      shownBatches: {},
    };
    this.onDateChange = this.onDateChange.bind(this);
    this.onArrowClick = this.onArrowClick.bind(this);
    this.togglePoolSize = this.togglePoolSize.bind(this);
  }

  componentDidMount() {
    this.getAllFarms();
    // setTimeout(() => {
    //   this.setVarities(this.state.date)
    // }, 500);
  }


  async getAllFarms() {
    Promise.all([getFarms(), getPerpitualBatches()]).then(resp => {
      this.setState({ allFarms: resp[0], allPerpitualBatches: resp[1], isLoaded: true })
    })
  }
  onDateChange(date) {

    this.setState({ date: moment(date).format('YYYY-MM-DD') }, () => {
      // this.setVarities(this.state.date);
      // this.state.currentPond.length && this.onDateChangeCheckOverFilled()
    });
  }


  closeSnackBar = () => {
    this.setState({ snackbarOpen: false, snackbarMessage: '' });
  }

  handleClose = (type) => {

    this.setState({ openPrompt: type, openPrompt1: type, pondUnavailable: [], dateUnavailable: [] })
  }

  checkAvailabilityPond = async ({ batchData, pondData, mode }) => {
    let SpaceAvailable = true;
    let OverfilledDate = '';
    let OverfilledPond = '';
    const { initiationDate, growthCycleObj: { germ, prop, hd, growMedia }, harvestDate, finishingLocation, propLocation1 } = batchData;
    const germDays = germ.days;
    const propDays = prop.days;
    const hdDays = (hd && hd.days) || 0;

    const isRockWool = growMedia === 'Rockwool'

    const farmData = isRockWool ? finishingLocation : propLocation1
    const pondType = isRockWool ? 'finishingLocation' : 'propLocation1'

    let pondContent;
    this.state.allFarms.forEach(farm => {
      if (farm.id === farmData.farm) {
        farm.ponds.forEach(pond => {
          if (pond.id === pondData.pond) {
            pondContent = pond;
            OverfilledPond = pond.name;
          }
        })
      }
    })

    if (!pondContent) return;
    const pondWidth = parseFloat(pondContent['widthF'] || 0);
    const pondLength = parseFloat(pondContent['lengthF'] || 0);
    const totalPondSquares = (pondWidth / 2) * (pondLength / 4);
    let fromDate, toDate;
    if (mode === STAGES.PROP) {
      fromDate = moment(initiationDate).add(germDays, 'days');
      toDate = moment(initiationDate).add(germDays + propDays, 'days');
    }
    else if (mode === STAGES.HD) {
      fromDate = moment(initiationDate).add(germDays + propDays, 'days');
      toDate = moment(initiationDate).add(germDays + propDays + hdDays, 'days');
    }
    else {
      fromDate = moment(initiationDate).add(germDays + propDays + hdDays, 'days');
      toDate = moment(harvestDate);
    }
    let newFromDate = new Date(fromDate);
    newFromDate.setHours(0);
    newFromDate.setMinutes(0);
    newFromDate = moment(newFromDate);

    let newToDate = new Date(toDate);
    newToDate.setHours(0);
    newToDate.setMinutes(0);
    newToDate = moment(newToDate);


    //day wise loop to check vacant space
    while (moment(newFromDate).isBefore(newToDate)) {
      let totalOccupy = 0;
      let currentData = deepCopyObject(this.props.plots[newFromDate.format()]);
      currentData.length && currentData.forEach(each => {
        let stage = each.stage;
        // todo
        console.log("totalPondSquaresssss1111",each)
        if ((stage === STAGES.PROP) && isRockWool) {
          const germCells = parseFloat((each?.data?.cycleDetail?.germCells || 0) || (each?.data?.growthCycle?.germ.cells || 0));
          totalOccupy += fix2((germCells / 200) / 4 || 0) || 0
        }
        else if (farmData.farm === each.graphic[pondType].farm) {
          each.graphic[pondType].pond[stage].forEach(each1 => {
            if (each1.pond === pondData.pond) {
              totalOccupy += isRockWool ? fix2(each1.noOfRaft) : each1.noOfRaft ? fix2((Number(each1.noOfRaft) / 4) || 0) : 0
            }
          })
        }
      })
      console.log("totalPondSquaresssss:",totalPondSquares,totalOccupy)
      if (totalPondSquares < totalOccupy) {
        SpaceAvailable = false;
        if (!OverfilledDate) {
          OverfilledDate = newFromDate.format('DD MM YYYY');
        }
      }

      newFromDate.add(1, 'days');
    }

    return { SpaceAvailable, OverfilledDate, OverfilledPond }
  }
  


  onDateChangeCheckOverFilled = async () => {
    const { plots } = this.props;
    let currentData = deepCopyObject(plots[moment(this.state.date).format()] || []);
    let unique = new Array();

    for (let i = 0; i < currentData.length; i++) {
      const currentBatch = currentData[i];
      if (currentBatch && unique.indexOf(currentBatch.batch) === -1) {
        const currentDay = moment(this.state.date);
        if (currentDay.isSameOrAfter(moment(currentBatch.growthCycle.prop.date)) && currentDay.isSameOrBefore(moment(currentBatch.growthCycle.harvest.date))) {
          unique.push(currentBatch.batch)
        }
      }
    }
    this.setState({ shownBatches: unique })
  }

  showPlotLegends = async () => {
    const plots = this.props.plots;
    const {currentPond,currentFarm} = this.state;
    let setObj = {};

    let newDate = new Date(this.state.date);
    newDate.setHours(0);
    newDate.setMinutes(0);
    const plot = plots[moment(newDate).format()];
    currentPond && currentPond.length > 0 && plot && plot.forEach((each) => {
      const { variety, color } = each.graphic;
      const plotFarm =
        each?.data?.finishingLocation?.farm &&
        each?.data?.finishingLocation?.farm !== ""
          ? each?.data?.finishingLocation?.farm
          : each?.data?.propLocation1?.farm;
      // console.log("plottttttt", plot, currentPond);
      let isPondValid = false;
      if (!setObj[each.batchID] && plotFarm == currentFarm) {
        if (
          each?.data?.finishingLocation?.farm &&
          each?.data?.finishingLocation?.farm !== ""
        ) {
          if (
            (each?.data?.finishingLocation?.pond?.fs &&
            each?.data?.finishingLocation?.pond?.fs.findIndex(
              (pondObj) => currentPond.indexOf(pondObj?.pond) > -1
            ) > -1) || (each?.data?.finishingLocation?.pond?.hd &&
              each?.data?.finishingLocation?.pond?.hd.findIndex(
                (pondObj) => currentPond.indexOf(pondObj?.pond) > -1
              ) > -1)
          ) {
            isPondValid = true;
          }
        } else if (
          each?.data?.propLocation1?.farm &&
          each?.data?.propLocation1?.farm !== ""
        ) {
          if (
            each?.data?.propLocation1?.pond?.prop &&
            each?.data?.propLocation1?.pond?.prop.findIndex(
              (pondObj) => currentPond.indexOf(pondObj?.pond) > -1
            ) > -1
          ) {
            isPondValid = true;
          }
        }
        if(isPondValid){
          setObj[each.batchID] = { variety, color };
        }
      }
    });


    this.setState({ shownBatches: setObj })

  }

  onArrowClick(direction) {


    const date = direction === 'left' ? moment(this.state.date).subtract(1, 'days') : moment(this.state.date).add(1, 'days');
    this.setState({ date: date.format('YYYY-MM-DD') }, () => {
      // this.setVarities(date)
      // this.state.currentPond.length && this.onDateChangeCheckOverFilled()
    });
  }

  togglePoolSize() {
    this.setState({ showLargePools: !this.state.showLargePools });
  }

  

  closeModal = () => {
    this.setState({ modalOpen: false, currentModal: null, loader: false });
  }

  renderValue = () => {

    let value = ''
    let count = 0
    this.state.allPonds && this.state.allPonds.map(re => {
      var avail = this.state.currentPond.indexOf(re.id);

      if (avail !== -1) {
        ++count;
        value = value ? (value + ', ' + re.name) : re.name
      }

    })
    return (count ? <div>{count} {count === 1 ? 'pond' : 'ponds'} selected</div> : <></>)
  }

  renderModal() {
    switch (this.state.currentModal) {

      case 'cloneBatch':
        return (
          <BatchModal
            batches={this.props.batches}
            crops={this.props.crops}
            users={this.props.users}
            tasks={this.props.tasks}
            varieties={this.props.varieties}
            clone
            onClickClone={(data) => {
              console.log(data)
            }}
          />
        );
      default:
        return <div>This is not the modal you're looking for.</div>
    }
  }


  render() {
    const { classes, crops, varieties, growthCycles, batches, plots, varietyLegend, users } = this.props;
    const orientation = this.state.showLargePools ? 'horizontal' : 'vertical';

    return (
      this.state.isLoaded ?
        <div>
          <Title title="Planning" />
          <Grid container justify="center" className={classes.dateSelectorContainer}>

            <DateSelector
              value={this.state.date}
              onChange={this.onDateChange}
              onArrowClick={this.onArrowClick}
            />

          </Grid>

          <Grid container justify="flex-end" className={classes.dateSelectorContainer}>
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-label">Select Farm</InputLabel>
              <Select
                // labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={this.state.currentFarm}
                onChange={(e) => this.setState({ currentFarm: e.target.value, currentPond: [] }, () => {

                  this.state.allFarms && this.state.allFarms.map(resp => {
                    if (this.state.currentFarm === resp.id) {
                      this.setState({ allPonds: resp.ponds })
                    }
                  })
                })}
              >
                {this.state.allFarms.map((resp) => (
                  <MenuItem value={resp.id}>{resp.name}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-label">Select Pond</InputLabel>
              <Select
                // labelId="demo-simple-select-label"
                id="demo-simple-select"
                multiple
                value={this.state.currentPond}
                displayEmpty
                renderValue={this.renderValue}
                native={false}
                disabled={!this.state.currentFarm}
                onChange={(e) => this.setState({ currentPond: e.target.value }, () => {
                  // this.onDateChangeCheckOverFilled()
                  this.showPlotLegends()
                })}
              >
                {this.state.allPonds && this.state.allPonds.map((resp) => {
                  // Checking current condition
                  var checked = this.state.currentPond.indexOf(resp.id);

                  return (
                    <MenuItem value={resp.id}>
                      {/* Individual check box */}
                      <Checkbox
                        checked={checked !== -1}
                        name="checkedB"
                        color="primary"
                      />
                      {/* All ponds name */}
                      {resp.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>

          </Grid>
          <Grid>
            {Object.keys(this.state.shownBatches).length ?
              <PlotLegend
                legend={this.state.shownBatches}
                orientation={orientation}
                batches={batches}
                varieties={varieties}
              /> : null}
          </Grid>
          {<Grid container direction={this.state.showLargePools ? 'column' : 'row'} className={classes.poolMapContainer} spacing={16}>

            {this.state.allPonds && this.state.allPonds.filter(p => this.state.currentPond.indexOf(p.id) > -1).map(pond => {
              // let index = this.state.currentPond.indexOf(pond.id)
              let dynamicHeight = (Number(pond.length) / 2)
              let dynamicWidth = (Number(pond.width) / 12) / 4
              return (
                <>
                  {this.state.showLargePools ? <Grid style={{ margin: 10, width: pond.pondType === 'landscape' ? (dynamicWidth * 30) : ((dynamicWidth * 2) * 30), height: 'auto', }}>
                    <Typography variant="body2" className={classes.pondName}>{pond.name} ({pond.width}x{pond.length})</Typography>

                    <PoolMap
                      varieties={this.props.varieties}
                      plots={plots}
                      allPerpitualBatches={this.state.allPerpitualBatches}
                      plotSettings={PLOTS}
                      currentFarm={this.state.currentFarm}
                      currentPond={pond}
                      poolNumber={1}
                      date={this.state.date}
                      size={this.state.showLargePools ? 'large' : 'small'}
                      orientation={orientation}
                      className={classes.poolMap}
                    />
                  </Grid> :
                    <Grid style={{ margin: 10 }}>
                      <Typography variant="body2" className={classes.pondName}>{pond.name}</Typography>
                      <Grid style={{ width: pond.pondType === 'landscape' ? (dynamicWidth * 15) : ((dynamicWidth * 2) * 15), height: 'auto' }}>
                        <PoolMap
                          varieties={this.props.varieties}
                          plots={plots}
                          allPerpitualBatches={this.state.allPerpitualBatches}
                          plotSettings={PLOTS}
                          currentFarm={this.state.currentFarm}
                          currentPond={pond}
                          poolNumber={1}
                          date={this.state.date}
                          size={this.state.showLargePools ? 'large' : 'small'}
                          orientation={orientation}
                          className={classes.poolMap}
                        />
                      </Grid>
                    </Grid>}
                </>
              )
            })}
            <Grid
              item
              xs={12}
              sm={this.state.showLargePools ? 10 : 3}
              className={classes.plotLegend}
              style={{ margin: orientation === 'vertical' ? 0 : '1rem 0' }}
            >
            </Grid>
            {this.state.currentPond.length ? <Grid item xs={12} className={classes.expandButtonContainer}>
              <Button variant="outlined" color="primary" onClick={this.togglePoolSize}>
                {this.state.showLargePools ?
                  <span>Collapse <i className="fas fa-chevron-up"></i></span>
                  :
                  <span>Expand <i className="fas fa-chevron-down"></i></span>
                }
              </Button>
            </Grid> : null}
          </Grid>}
          <NewBatchForm
            allFarms={this.state.allFarms}
            crops={crops}
            users={users}
            farm={this.props.farm}
            rafts={this.props.rafts}
            varieties={varieties}
            growthCycles={growthCycles}
            batches={batches}
            checkAvailabilityPond={this.checkAvailabilityPond}
            refreshVarieties={(data, type) => {
              // setTimeout(() => {
              //   this.onDateChangeCheckOverFilled()
              // }, 1);
              // this.setVarities(this.state.date)
            }}
          />
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={this.state.snackbarOpen}
            autoHideDuration={7000}
            onClose={this.closeSnackBar}
            ContentProps={{
              'aria-describedby': 'message-id',
            }}
            message={<span id="message-id">{this.state.snackbarMessage}</span>}
            action={
              [
                <IconButton
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  className={classes.close}
                  onClick={this.closeSnackBar}
                >
                  <i className="fas fa-times"></i>
                </IconButton>
              ]
            }
          />
          <Dialog
            open={this.state.openPrompt}
            onClose={() => this.handleClose(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Overfilled</DialogTitle>
            <DialogContent>
              {this.state.dateUnavailable && this.state.dateUnavailable.map(resp => (
                <DialogContentText id="alert-dialog-description">
                  {resp}
                </DialogContentText>
              ))}
              <DialogContentText style={{ color: '#000', marginTop: 15 }} id="alert-dialog-description">Are you sure you want to proceed with this overfull condition?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.handleClose(false)} color="primary" autoFocus>
                OK
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={this.state.openPrompt1}
            onClose={() => this.handleClose(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Overfilled</DialogTitle>
            <DialogContent>
              {this.state.pondUnavailable && this.state.pondUnavailable.map((resp, i) => (
                <DialogContentText id="alert-dialog-description">
                  {resp} is overfull on {moment(this.state.dateUnavailable[i]).format('DD MMM YYYY')}
                </DialogContentText>
              ))}
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.handleClose(false)} color="primary" autoFocus>
                OK
              </Button>
            </DialogActions>
          </Dialog>

          <Modal
            aria-labelledby="modal-title"
            open={this.state.modalOpen}
            onClose={this.closeModal}
          >
            {this.renderModal()}
          </Modal>
        </div>
        :
        <FullScreenLoader />
    );
  }
};

Planning.propTypes = {
  classes: PropTypes.object.isRequired,
  crops: PropTypes.object.isRequired,
  varieties: PropTypes.object.isRequired,
  growthCycles: PropTypes.object.isRequired,
  batches: PropTypes.object.isRequired,
  tasks: PropTypes.object.isRequired,
  plots: PropTypes.object.isRequired,
  misc: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    crops: state.crops,
    varieties: state.varieties,
    growthCycles: state.growthCycles,
    batches: state.batches,
    plots: state.plots,
    rafts: state.rafts,
    farm: state.farm,
    varietyLegend: state.varietyLegend,
    misc: state.misc,
    users: state.users
  }
}

export default withStyles(styles)(connect(mapStateToProps)(Planning));

import { LOAD_BATCHES, ADD_EDIT_BATCH, DELETE_BATCH, ADD_BATCHES } from '../actionTypes';
import { deepCopyObject } from '../../utils/utils';

export default (state = {}, action) => {
  switch (action.type) {
    case LOAD_BATCHES:
      return { ...action.batches };

    case ADD_BATCHES:
      return { ...state, ...action.batches };

    case ADD_EDIT_BATCH:
      // merges received key-val pairs with existing ones for the item in question
      const key = Object.keys(action.batch)[0];
      let currBatchObj = state[key] ? deepCopyObject(state[key]) : {};
      currBatchObj = { ...currBatchObj, ...action.batch[key] };
      return { ...state, [key]: currBatchObj };

    case DELETE_BATCH:
      let newState = deepCopyObject(state);
      for (let key in newState) {
        let [id] = key.split('-');
        if (id.toString() === action.id.toString()) {
          delete newState[key];
        }
      }
      console.log('newState',newState)
      return newState;

    default:
      return state;
  }
};
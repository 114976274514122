import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core';

const styles = () => ({
  root: {
    width: '100%',
    height: '50vh',
  },
  loader: {
    flexGrow: 0,
  }
});

const FullScreenLoader = ({classes}) => (
  <Grid container className={classes.root} justify="center" alignItems="center">
    <CircularProgress size={100} />
  </Grid>
);

FullScreenLoader.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(FullScreenLoader);
import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import Downshift from 'downshift';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import { DatePicker } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import { FormControl, InputLabel, Select, withStyles } from '@material-ui/core';
import APP_SETTINGS from '../utils/appSettings';

const {STAGES} = APP_SETTINGS;

const styles = theme => ({
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2
  },
  input: {
    width: '100%'
  },
  menuDiv: {
    outline: 'none',
    '&:focus': {
      outline: 'none'
    }
  },
  button: {
    marginTop: '0.5rem'
  },
  buttonContainer: {
    textAlign: 'center'
  },
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  downshiftPaper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  searchInput : {
    width: "48%"
  }
});

function renderSuggestion({ suggestion, index, itemProps, highlightedIndex, selectedItem }) {
  const isHighlighted = highlightedIndex === index;
  const isSelected = (selectedItem || '').indexOf(suggestion.label) > -1;

  return (
    <MenuItem
      {...itemProps}
      key={suggestion.label}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
    >
      {suggestion.label}
    </MenuItem>
  );
}

function getSuggestions(value, suggestionList) {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;

  return inputLength === 0
    ? []
    : suggestionList.filter(item => item.label.toLowerCase().includes(inputValue));
}

const GrowCurrentSort = props => {
  const {
    classes,
    handleChange,
    startDate,
    endDate,
    sort,
    filter,
    handleExport,
    className,
    onSearchSelect,
    suggestionList,
    formatDate,
    handlePageSizeChange,
    pageSize
  } = props;
  
  return (
    <Grid container alignItems="stretch" spacing={16} className={className || ""}>
      <Grid item xs={12} md={4}>
        <Paper className={classes.paper} elevation={1}>
          <Grid container alignItems="flex-end" spacing={16}>
            <Grid item xs={5} md={6}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  label="Start Date"
                  name="currentStartDate"
                  value={startDate}
                  format="MM/DD/YYYY"
                  // mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                  onChange={(date) => handleChange('startDate')({ target: {value: date} })}
                  onBlur={(e) => formatDate(e)}
                  animateYearScrolling
                  autoOk
                  margin="dense"
                  keyboard
                />
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid item xs={5} md={6}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  label="End Date"
                  name="currentEndDate"
                  value={endDate}
                  format="MM/DD/YYYY"
                  // mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                  onChange={(date) => handleChange('endDate')({ target: {value: date} })}
                  onBlur={(e) => formatDate(e)}
                  animateYearScrolling
                  autoOk
                  margin="dense"
                  keyboard
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <FormControl item xs={6} md={6} className={classes.searchInput}>
              <InputLabel id="demo-simple-select-label">Select length</InputLabel>
              <Select
                // labelId="demo-simple-select-label"
                id="demo-simple-select"
                name={'selectedPond'}
                onChange={(e)=>handlePageSizeChange(e.target.value)}
                value={pageSize}
              >
                {[5,10,15,20].map((size) => (
                  <MenuItem value={size}>{size}</MenuItem>
                ))}
              </Select>
            </FormControl>
            
          </Grid>
        </Paper>
      </Grid>

      <Grid item xs={12} md={8}>
        <Paper className={`${classes.paper} ${className || ''}`} elevation={1}>
          <Grid container alignItems="flex-end" spacing={16}>
            <Grid item xs={6} md={3}>
              <TextField
                select
                id="sort"
                label="Sort"
                value={sort}
                onChange={handleChange('sort')}
                margin="dense"    
                className={classes.input}
              >
                <MenuItem value={"initiationDate-asc"}>Start Date &nbsp;&nbsp;<i className="fas fa-caret-up"></i></MenuItem>
                <MenuItem value={"initiationDate-desc"}>Start Date &nbsp;&nbsp;<i className="fas fa-caret-down"></i></MenuItem>
                <Divider />
                <MenuItem value={"GRFlot-asc"}>Lot Number &nbsp;&nbsp;<i className="fas fa-caret-up"></i></MenuItem>
                <MenuItem value={"GRFlot-desc"}>Lot Number &nbsp;&nbsp;<i className="fas fa-caret-down"></i></MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={6} md={3}>
              <TextField
                select
                id="filter"
                label="Filter"
                value={filter}
                onChange={handleChange('filter')}
                margin="dense"    
                className={classes.input}
              >
                <MenuItem value={"all"}>All</MenuItem>
                <MenuItem value={STAGES.PLANNED}>Planned</MenuItem>
                <MenuItem value={"current"}>Current</MenuItem>
                <MenuItem value={STAGES.COMPLETED}>Completed</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={6} md={3}>
              <Downshift
                id="search"
                onChange={selection => onSearchSelect(selection)}
              >
              {({
                getInputProps,
                getItemProps,
                getMenuProps,
                highlightedIndex,
                inputValue,
                isOpen,
                selectedItem,
                clearSelection
              }) => (
                <div className={classes.container}>
                  <TextField
                    className={classes.input}
                    type="search"
                    margin="dense"
                    label="Search"
                    inputProps={
                      getInputProps({
                        placeholder: 'Search',
                        onChange: clearSelection
                      })
                    }
                  />
                  <div {...getMenuProps()}>
                    {isOpen ? (
                      <Paper className={classes.downshiftPaper} square>
                        {getSuggestions(inputValue, suggestionList).map((suggestion, index) =>
                          renderSuggestion({
                            suggestion,
                            index,
                            itemProps: getItemProps({ item: suggestion.label }),
                            highlightedIndex,
                            selectedItem
                          }),
                        )}
                      </Paper>
                    ) : null}
                  </div>
                </div>
              )}
              </Downshift>
            </Grid>
            {/* <Grid item xs={6} md={3} className={classes.buttonContainer}>
              <Button variant="outlined" color="secondary" onClick={handleExport} className={classes.button}>Export</Button>
            </Grid> */}
          </Grid>
        </Paper>
      </Grid>

    </Grid>
  );
};

GrowCurrentSort.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  sort: PropTypes.string.isRequired,
  filter: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  suggestionList: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleExport: PropTypes.func.isRequired,
  onSearchSelect: PropTypes.func.isRequired
};

export default withStyles(styles)(GrowCurrentSort);
import moment from 'moment';
import { deepCopyObject } from '../utils';
import { checkHasBeenUsed } from './setup';
import { addEditItem } from '../../store/actions/adders';
import APP_SETTINGS from '../appSettings';

const {
  COLLECTIONS,
} = APP_SETTINGS;

export function createNewGrowthCycleObj(growthCycleData) {
  return {...growthCycleData, isPrimary: false}
  const {
    name,
    harvestType,
    germ,
    prop,
    hd,
    fs,
    harvests,
    harvestInterval,
    avgWeightPerRaft,
    isArchived,
    hasBeenUsed,
    notes
  } = growthCycleData;

  return {
    name, harvestType,
    notes: notes || '',
    isArchived: isArchived || false,
    hasBeenUsed: hasBeenUsed || false,
    harvests: harvests || 1,
    harvestInterval: harvestInterval || '',
    avgWeightPerRaft: avgWeightPerRaft || '',
    germ: { days: germ.days, cells: germ.cells },
    prop: { days: prop.days },
    hd: { days: hd.days, raftType: hd.raftType, calcRafts: hd.calcRafts, remainder: hd.remainder, endRafts: hd.endRafts },
    fs: { days: fs.days, raftType: fs.raftType, calcRafts: fs.calcRafts, remainder: fs.remainder, endRafts: fs.endRafts }
  };
}

export function recalcDatesInGrowthCycle(growthCycle, startDate, seedDate) {
  let newGrowthCycle = deepCopyObject(growthCycle);
  if (!startDate) {
    if (!newGrowthCycle.germ.date) {
      throw new Error('Recalc Dates in Growth Cycle: must have a startdate');
    }
    startDate = newGrowthCycle.germ.date;
  }

  if (!newGrowthCycle) return

  // set seedDate
  if (!newGrowthCycle.seed) { newGrowthCycle.seed = {}; }
  if (seedDate) {
    newGrowthCycle.seed.date = moment(seedDate).format('YYYY-MM-DD');
  } else {
    newGrowthCycle.seed.date = moment(startDate).format('YYYY-MM-DD');
  }

  // set germ and prop date
  newGrowthCycle.germ.date = moment(startDate).format('YYYY-MM-DD');
  newGrowthCycle.prop.date = moment(newGrowthCycle.germ.date).add(newGrowthCycle.germ.days, 'days').format('YYYY-MM-DD');

  // allow for no HD stage
  if (newGrowthCycle.hd.days && newGrowthCycle.hd.days > 0) {
    newGrowthCycle.hd.date = moment(newGrowthCycle.prop.date).add(newGrowthCycle.prop.days, 'days').format('YYYY-MM-DD');
    newGrowthCycle.fs.date = moment(newGrowthCycle.hd.date).add(newGrowthCycle.hd.days, 'days').format('YYYY-MM-DD');
  } else {
    newGrowthCycle.fs.date = moment(newGrowthCycle.prop.date).add(newGrowthCycle.prop.days, 'days').format('YYYY-MM-DD');
  }

  // calc harvest dates, allowing for multiple harvests
  if (!newGrowthCycle.harvest) { newGrowthCycle.harvest = {} }
  if (newGrowthCycle.harvestType === 'once') {
    newGrowthCycle.harvest.date = moment(newGrowthCycle.fs.date).add(newGrowthCycle.fs.days, 'days').format('YYYY-MM-DD');
  } else { // harvestType === 'repeat'
    if (!newGrowthCycle.harvest.date) { newGrowthCycle.harvest.date = []; }

    const firstHarvestDate = moment(newGrowthCycle.fs.date).add(newGrowthCycle.fs.days, 'days').format('YYYY-MM-DD');
    for (let i = 0; i < newGrowthCycle.harvests; i++) {
      if (!newGrowthCycle.harvest.date[i] || i === 0) {
        newGrowthCycle.harvest.date[i] = i === 0 ? firstHarvestDate : moment(newGrowthCycle.harvest.date[i - 1]).add(newGrowthCycle.harvestInterval, 'days').format('YYYY-MM-DD');
      } else {
        // recalc the date if: it exists but diff between it and prior date is less than harvestInterval
        if (moment(newGrowthCycle.harvest.date[i]).diff(newGrowthCycle.harvest.date[i - 1], 'days') < newGrowthCycle.harvestInterval) {
          newGrowthCycle.harvest.date[i] = moment(newGrowthCycle.harvest.date[i - 1]).add(newGrowthCycle.harvestInterval, 'days').format('YYYY-MM-DD');
        }
      }
    }
    // remove any existing harvestDate items past the number of harvests
    newGrowthCycle.harvest.date.splice(newGrowthCycle.harvests);

  }
  return newGrowthCycle;
};

export const getMinDateInterval = (dateArr) => {
  if (!Array.isArray(dateArr) || dateArr.length < 2) { return ''; }
  let minInterval = null;
  let startDate = dateArr[0];
  for (let i = 1; i < dateArr.length; i++) {
    const diff = moment(dateArr[i]).diff(startDate, 'days');
    if (diff < minInterval || !minInterval) {
      minInterval = diff;
    }
    startDate = dateArr[i];
  }
  return minInterval;
};

export const growthCyclesAreEqual = (cycle1, cycle2) => {
  if (
    cycle1.harvestType === cycle2.harvestType
    && cycle1.germ.days === cycle2.germ.days
    && cycle1.germ.cells === cycle2.germ.cells
    && cycle1.prop.days === cycle2.prop.days
    && cycle1.hd.days === cycle2.hd.days
    && cycle1.hd.raftType === cycle2.hd.raftType
    && cycle1.fs.days === cycle2.fs.days
    && cycle1.fs.raftType === cycle2.fs.raftType
    && cycle1.harvestType === cycle2.harvestType
    && cycle1.harvestInterval === cycle2.harvestInterval
  ) {
    return true;
  } else {
    return false;
  }
};

export const findGrowthCycleIDInVariety = ({ variety, growthCycles = {}, compareCycle = {} }) => {
  const IDList = variety.growthCycles;
  if (IDList.length === 0) {
    return null;
  }

  let id = null;
  IDList.find(gcID => {
    const growthCycle = growthCycles[gcID];
    if (growthCyclesAreEqual(growthCycle, compareCycle)) {
      id = gcID;
      return true;
    } else {
      return false;
    }
  })
  return id;
};

export const createNewName = (startName, nameList) => {
  let success = false;
  let i = 0;
  let name = '';

  while (!success) {
    const candidate = i === 0 ? startName : `${startName}(${i})`;
    let foundMatch = false;
    nameList.forEach(item => {
      if (item === candidate) {
        foundMatch = true;
      }
    });
    if (foundMatch) {
      i++;
    } else {
      name = candidate;
      success = true;
    }
  }

  return name;
};

export async function getNewOrExistingCycleAndID({ growthCycle, growthCycleID, growthCycles, variety, varietyID, batchID, batches }) {
  // compare returned cycle to all others under this variety
  const existingID = findGrowthCycleIDInVariety({ variety, growthCycles: growthCycles, compareCycle: growthCycle });
  let returnedGrowthCycle = deepCopyObject(growthCycle);
  let returnedGrowthCycleID = '';
  let usedExisting = false;

  if (!existingID) {
    // create growthCycle object with no planned dates populated, get a new unique name, set isPrimary and hasBeenUsed
    const nameList = variety.growthCycles.map(gcID => growthCycles[gcID].name);
    const newName = createNewName(growthCycle.name, nameList);
    returnedGrowthCycle.name = newName;
    returnedGrowthCycle.isPrimary = false;
    returnedGrowthCycle.hasBeenUsed = true;

    // save new growthCycle to database
    let growthCycleNoDates = createNewGrowthCycleObj(returnedGrowthCycle); // removes dates
    const itemObj = await addEditItem({
      collection: COLLECTIONS.GROWTH_CYCLES,
      item: growthCycleNoDates,
      id: null,
      parentID: varietyID
    });
    const gcID = Object.keys(itemObj)[0];

    console.log({ returnedGrowthCycle, gcID });
    returnedGrowthCycleID = gcID;

  } else {
    // set new growthCycle to hasBeenUsed = true in database
    addEditItem({
      collection: COLLECTIONS.GROWTH_CYCLES,
      item: {
        hasBeenUsed: true
      },
      id: existingID
    });

    // populate returnedGrowthCycle with data from received growthCycle
    returnedGrowthCycle = {
      ...returnedGrowthCycle,
      seed: growthCycle.seed,
      germ: growthCycle.germ,
      prop: growthCycle.prop,
      hd: growthCycle.hd,
      fs: growthCycle.fs,
      harvest: growthCycle.harvest,
      hasBeenUsed: true
    };
    returnedGrowthCycleID = existingID;
    usedExisting = true;
  }

  // run check for hasBeenUsed on old growth cycle
  const hasBeenUsed = checkHasBeenUsed({
    collectionSingular: 'growthCycle',
    collectionItemID: growthCycleID,
    batches,
    currentBatchID: batchID
  });
  if (!hasBeenUsed) {
    addEditItem({ collection: COLLECTIONS.GROWTH_CYCLES, item: { hasBeenUsed }, id: growthCycleID });
  }

  return { growthCycle: returnedGrowthCycle, growthCycleID: returnedGrowthCycleID, usedExisting };
}

export function calcHarvestDate({ initiationDate, growthCycle }) {
  let days = 0;
  days += growthCycle.germ.days;
  days += growthCycle.prop.days;
  days += growthCycle.hd.days;
  days += growthCycle.fs.days;
  return moment(initiationDate).add(days, 'days').format('YYYY-MM-DD');
}

export function calcInitiationDate({ harvestDate, growthCycle }) {
  let days = 0;
  days += growthCycle.germ.days;
  days += growthCycle.prop.days;
  days += growthCycle.hd.days;
  days += growthCycle.fs.days;
  return moment(harvestDate).subtract(days, 'days').format('YYYY-MM-DD');
}
import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';
import red from '@material-ui/core/colors/red';
import blue from '@material-ui/core/colors/blue';
import APP_SETTINGS from '../utils/appSettings';
import { sortFunction } from '../utils/utils';

const {COLLECTIONS} = APP_SETTINGS;


const styles = theme => ({
  modal: {
    // position: 'absolute',
    // top: '50%',
    // left: '50%',
    // transform: 'translate(-50%, -50%)',
    width: '100%',
    // minWidth: 580,
    // maxWidth: 800,
    backgroundColor: theme.palette.background.paper,
    // boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    '&:focus': {
      outline: 'none'
    }
  },
  container: {
    width: '100%'
  },
  input: {
    width: '100%'
  },
  buttonContainer: {
    textAlign: 'right',
    marginTop: '1.5rem'
  },
  button: {
    margin: '0.5rem 1rem'
  },
  paper: {
    width: '100%',
    marginBottom: '2rem'
  },
  sectionTitle: {
    marginTop: '2rem'
  },
  actionIcons: {
    marginRight: '1.5rem',
    cursor: 'pointer',
    '&:last-child': {
      marginRight: 0
    },
    fontSize: '1.5rem'
  },
  blue: {
    color: blue[500]
  },
  red: {
    color: red[500]
  },
  grey: {
    color: theme.palette.grey[300]
  },
  editingCell: {
    color: theme.palette.grey[300]
  }
});

class RaftModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      sites: '',
      squareFeet: '',
      deleteRaft: null,
      editIsActive: false,
      editRaft: null,
      dialogOpen: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.saveRaft = this.saveRaft.bind(this);
    this.editRaft = this.editRaft.bind(this);
    this.deleteRaft = this.deleteRaft.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
  }

  handleChange = name => e => {
    this.setState({
      [name]: e.target.value
    });
  };
  
  saveRaft(e) {
    e.preventDefault();
    const {name, sites, squareFeet} = this.state;
    const item = {name, sites, squareFeet};
    this.props.addEditItem({collection: COLLECTIONS.RAFTS, item, id: this.state.editRaft})
    this.setState({editIsActive: false, editRaft: null, name: '', sites: '', squareFeet: ''});
  }

  editRaft(id) {
    const raft = this.props.rafts[id];
    const {name, sites, squareFeet} = raft;
    console.log(raft);
    this.setState({name, sites, squareFeet, id, editRaft: id, editIsActive: true});
  }

  deleteRaft() {
    this.props.deleteItem({collection: COLLECTIONS.RAFTS, id: this.state.deleteRaft});
  }

  openDialog(id) {
    this.setState({dialogOpen: true, deleteRaft: id});
  }

  closeDialog(userAnswer) {
    if (userAnswer && userAnswer === 'yes') {
      this.deleteRaft();
    }
    this.setState({dialogOpen: false});
  }

  render() {
    const {classes, rafts} = this.props;

    return (
      <div className={classes.modal} tabIndex={-1}>
      
        <Grid container className={classes.container} spacing={16}>
          <Grid item xs={12}>
            <Typography variant="h4" id="modal-title">Manage Rafts</Typography>
          </Grid>
        </Grid>
        <form onSubmit={this.saveRaft}>
          <Grid container className={classes.container} spacing={16}>
            <Grid item xs={6}>
              <TextField
                required
                variant="filled"
                id="name"
                label="Name"
                value={this.state.name}
                onChange={this.handleChange('name')}
                margin="normal"
                className={classes.input}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                required
                variant="filled"
                type="number"
                id="sites"
                label="Sites on Raft"
                value={this.state.sites}
                onChange={this.handleChange('sites')}
                margin="normal"
                className={classes.input}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                required
                variant="filled"
                type="number"
                id="squareFeet"
                label="Sq. Ft."
                value={this.state.squareFeet}
                onChange={this.handleChange('squareFeet')}
                margin="normal"
                className={classes.input}
              />
            </Grid>
          </Grid>
          <Grid container className={classes.container} justify="flex-end">
            <Button variant="outlined" type="submit" color="primary" className={classes.button} onClick={this.saveRaft}>
              {this.state.editIsActive ? 'Save' : 'Add'}
            </Button>
          </Grid>
        </form>

        <Grid container className={classes.container} spacing={16}>
          <Grid item xs={12}>
            <Typography variant="h6" className={classes.sectionTitle}>Current Rafts</Typography>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell numeric>Num. Sites</TableCell>
                    <TableCell numeric>Sq. Ft.</TableCell>
                    <TableCell>Edit/Delete</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(rafts).sort((a, b) => sortFunction(rafts[a], rafts[b], 'asc', 'name')).map(raftID => {
                    const raft = rafts[raftID];
                    if (raft.isArchived) {return null;}
                    return (
                      <TableRow key={raftID}>
                        <TableCell component="th" scope="raft" className={classNames({ [classes.editingCell]: this.state.editRaft === raftID })}>
                          {raft.name}
                        </TableCell>
                        <TableCell numeric className={classNames({ [classes.editingCell]: this.state.editRaft === raftID })}>{raft.sites}</TableCell>
                        <TableCell numeric className={classNames({ [classes.editingCell]: this.state.editRaft === raftID })}>{raft.squareFeet}</TableCell>
                        <TableCell>
                          <i
                            className={`far fa-edit fa-2x ${classNames(classes.actionIcons, classes.blue, { [classes.grey]: this.state.editRaft === raftID })}`}
                            onClick={() => this.editRaft(raftID)}
                          >
                          </i>
                          <i
                            className={`far fa-times-circle fa-2x ${classNames(classes.actionIcons, classes.red, { [classes.grey]: this.state.editRaft === raftID })}`}
                            onClick={() => this.openDialog(raftID)}
                          >
                          </i>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Paper>

            <Dialog
              open={this.state.dialogOpen}
              onClose={this.closeDialog}
              aria-describedby="confirm-delete-dialog"
            >
              <DialogContent>
                <DialogContentText id="confirm-delete-dialog">
                  Delete this raft? This cannot be undone.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => this.closeDialog('no')} color="primary">
                  No
                </Button>
                <Button onClick={() => this.closeDialog('yes')} color="primary" autoFocus>
                  Yes
                </Button>
              </DialogActions>
            </Dialog>

          </Grid>
        </Grid>
        
        <Grid container className={classes.container} justify="flex-end">
          <Button variant="contained" color="secondary" className={classes.button} onClick={this.props.onDone}>Done</Button>
        </Grid>
      </div>
    )
  }
};

RaftModal.propTypes = {
  classes: PropTypes.object.isRequired,
  rafts: PropTypes.object.isRequired,
  addEditItem: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired
};

export default withStyles(styles)(RaftModal);
import { LOAD_USERS, LOAD_CURRENT_USER, ADD_USER } from '../actionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    case LOAD_USERS:
      return {...action.users};

    case ADD_USER:
      const key = Object.keys(action.user)[0];
      return {...state, [key]: action.user[key]};

    case LOAD_CURRENT_USER:
      return {...state, currentUser: action.user}

    default:
      return state;
  }
};
import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import UserCard from '../components/UserCard';
import Title from '../components/Title';
import FullScreenLoader from '../components/FullScreenLoader';
import red from '@material-ui/core/colors/red';
import { withStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import UserContext from '../context_providers/UserContext';
import { connect } from 'react-redux';
import APP_SETTINGS from '../utils/appSettings';
import { sortFunction, objectsAreEqual } from '../utils/utils';
import { approveUser, getAllUsers, removeUser } from '../store/actions/adders';

const { USER_ROLES } = APP_SETTINGS;

const styles = () => ({
  topButton: {
    color: red[400],
    borderColor: red[400],
    '&:hover': {
      backgroundColor: red[50]
    }
  },
  buttonIcon: {
    marginRight: '0.6rem'
  },
  spacer: {
    height: '3rem'
  },
  sectionTitle: {
    marginBottom: '1rem',
  },
  cardContainer: {
    marginBottom: '2rem'
  },
  link: {
    textDecoration: 'none',
    color: 'inherit'
  }
});

class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = { adminList: [], userList: [], isLoaded: false };
  }


  getData = () =>{
    getAllUsers().then(users=>{
      let userList = [];
      let adminList = [];
      Object.keys(users).sort((a, b) => sortFunction(users[a], users[b], 'asc', 'lastName'))
      .forEach(userID => {
        const userObject = {
          ...users[userID],
          // id: userID
        };
        if (userObject.role === USER_ROLES.ADMIN) {
          adminList.push(userObject);
        } else {
          userList.push(userObject);
        }
      });
      this.setState({ adminList, userList, isLoaded: true });
    })
  }

  async componentDidMount() {

    this.getData()
   
    const user = this.context.user;
    const users = this.props.users
    if (user.role !== USER_ROLES.ADMIN) {
      this.props.history.push(`/users/edit/${user.id}`);
    }

    return

    let userList = [];
    let adminList = [];

    Object.keys(users).sort((a, b) => sortFunction(users[a], users[b], 'asc', 'lastName'))
      .forEach(userID => {
        const userObject = {
          ...users[userID],
          id: userID
        };
        if (userObject.role === USER_ROLES.ADMIN) {
          adminList.push(userObject);
        } else {
          userList.push(userObject);
        }
      });

    this.setState({ adminList, userList, isLoaded: true });

  }

  componentWillReceiveProps(nextProps, nextState) {
  

    return
    if (!objectsAreEqual(this.props, nextProps)) {
      let userList = [];
      let adminList = [];
      let users = nextProps.users
      Object.keys(users).sort((a, b) => sortFunction(users[a], users[b], 'asc', 'lastName'))
        .forEach(userID => {
          const userObject = {
            ...users[userID],
            id: userID
          };
          if (userObject.role === USER_ROLES.ADMIN) {
            adminList.push(userObject);
          } else {
            userList.push(userObject);
          }
        });

      this.setState({ adminList, userList, isLoaded: true });
    }
  }



  render() {
    const { classes } = this.props;
    const defaultAvatarURL = "https://firebasestorage.googleapis.com/v0/b/greener-roots.appspot.com/o/avatars%2Fdefault-avatar.jpg?alt=media&token=cb7449fe-370b-4ab9-bf37-410b035b1ce2";
    const user = this.context.user;

    const adminList = this.state.adminList.map((person, i) => (
      <Grid key={`admins-${i}`} item xs={12} md={6}>
        <Link to={`/users/edit/${person.id}`} className={classes.link}>
          <UserCard
            fullName={person.firstName + ' ' + person.lastName}
            role={person.position}
            email={person.email}
            avatarSrc={person.avatarURL || defaultAvatarURL}
          />
        </Link>
      </Grid>
    )
    );

    const userList = this.state.userList.map((person, i) => (
      <Grid key={`users-${i}`} item xs={12} md={6}>
        <Link to={`/users/edit/${person.id}`} className={classes.link}>
          <UserCard
            fullName={person.firstName + ' ' + person.lastName}
            role={person.position}
            email={person.email}
            avatarSrc={person.avatarURL || defaultAvatarURL}
          />
        </Link>
        {!person.approved &&
          <Grid justify="flex-end">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{ marginTop: 10 }}
              onClick={() => {
                approveUser(person.id)
                setTimeout(() => {
                  this.getData()
                }, 200);
              }}
            >
              Approve
          </Button>
          </Grid>}

          <Grid justify="flex-end">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{ marginTop: 10 }}
              onClick={() => {
                removeUser(person.id)
                setTimeout(() => {
                  this.getData()
                }, 200);
              }}
            >
              Remove User
          </Button>
          </Grid>
      </Grid>
    )
    );

    return (
      this.state.isLoaded ?
        <div>
          <Grid container justify="space-between">
            <Grid item xs={12} md={9}>
              <Title title="Users" />
            </Grid>
            {user.role === USER_ROLES.ADMIN &&
              <Grid item xs={12} md={3} style={{ textAlign: 'right' }}>
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.topButton}
                  style={{ minWidth: 135 }}
                  href='https://console.firebase.google.com/u/0/project/greener-roots-playground/authentication/users'
                  target='_blank'
                >
                  Manage in Console
                </Button>
              </Grid>
            }
          </Grid>

          <Link to="/users/new" className={classes.link}>
            <Button variant="contained" size="small" color="secondary">
              <i className={`${classes.buttonIcon} fas fa-plus-circle fa-2x`}></i>
              Add User
            </Button>
          </Link>

          <div className={classes.spacer}></div>

          <Typography variant="h5" className={classes.sectionTitle}>Admin</Typography>


          <Grid container spacing={32} className={classes.cardContainer}> {/* container for admin cards */}

            {adminList}
          </Grid>

          {userList.length > 0 &&
            <React.Fragment>
              <Typography variant="h5" className={classes.sectionTitle}>Users</Typography>

              <Grid container spacing={32} className={classes.cardContainer}> {/* container for user cards */}
                {userList}
              </Grid>
            </React.Fragment>
          }

        </div>
        :
        <FullScreenLoader />
    );

  }
};

Users.propTypes = {
  classes: PropTypes.object.isRequired,
  users: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

Users.contextType = UserContext;

function mapStateToProps(state) {
  return {
    users: state.users
  }
}

export default withStyles(styles)(connect(mapStateToProps)(Users));
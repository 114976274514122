import React from "react";
import PropTypes from "prop-types";
import GrowToDoSort from "../components/GrowToDoSort";
import TaskCard from "../components/TaskCard";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import CircularProgress from "@material-ui/core/CircularProgress";
import SlideAndCollapse from "../components/SlideAndCollapse";
import moment from "moment";
import { withStyles } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import MomentUtils from "@date-io/moment";
import { DatePicker } from "material-ui-pickers";
import { Pagination } from "@material-ui/lab";

import {
  batchTransaction,
  pushTaskEdit,
  addEditItem,
  addEditBatch,
  pushLowInventoryItem,
  updateMultipleBatches,
  updateMultipleVarieties,
} from "../store/actions/adders";
import { deleteItem, deleteLowInventoryItem } from "../store/actions/deleters";
import { connect } from "react-redux";
import {
  sortFunction,
  deepCopyObject,
  objectIsEmpty,
  objectsAreEqual,
  fillNumberStringToLength,
  checkNull,
} from "../utils/utils";
import { recalcDatesInGrowthCycle } from "../utils/appFunctions/growthCycles";
import { calculateBatchMetrics } from "../utils/appFunctions/batches";
import {
  validateTasksForCompletion,
  updateBatchesForTaskCompletion,
  updateVarietiesForTaskCompletion,
  updateGrowthCyclesForTaskCompletion,
} from "../utils/appFunctions/tasks";
import UserContext from "../context_providers/UserContext";
import APP_SETTINGS from "../utils/appSettings";

const {
  HARVEST_TYPES,
  TASK_NAMES,
  TASK_TYPES,
  STAGES,
  COLLECTIONS,
  USER_ROLES,
} = APP_SETTINGS;

const styles = {
  sortBar: {
    marginBottom: "2rem",
  },
  title: {
    marginBottom: "1rem",
  },
  taskCardContainer: {
    width: "100%",
    marginBottom: "1rem",
    maxHeight: "max-content",
    overflow: "visible",
  },
  taskCardContainerExiting: {
    height: 0,
    overflow: "hidden",
    transition: "height 0ms ease-out 400ms",
  },
  taskCardEntered: {
    transition: "unset",
    transform: "scale(1)",
  },
  taskCardExiting: {
    transform: "scale(0)",
    transition: "transform 300ms ease-out 400ms",
  },
};

class GrowTodo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dateList: [],
      dateListObj: {},
      selectedTasks: [],
      expandedTasks: [],
      snackbarOpen: false,
      snackbarMessage: "",
      snackBarUndo: false,
      undoState: {},
      dialogOpen: false,
      dialogMessage: "",
      dialogButtons: [],
      completionType: "",
      currentDialog: "",
      currentTask: "",
      usePlannedDate: false,
      seedLot: "",
      harvest: null,
      disposal: null,
      shouldUpdate: true,
      hideTasks: [],
      tasksToComplete: 0,
      isAssigningUsers: false,
      isMultiCompleting: false,
      selectAll: false,
      markedTasks: [],
      seedLots: {},
      showDatePicker: false,
      taskCompletedDatePicker: new Date(),
      ListTask: [],
      selectedFarm: "",
      selectedPond: "",
      allPonds: new Array(),
    };
    this.handleCompleteOne = this.handleCompleteOne.bind(this);
    this.handleCompleteMultiple = this.handleCompleteMultiple.bind(this);
    this.openSnackBar = this.openSnackBar.bind(this);
    this.handleTaskExit = this.handleTaskExit.bind(this);
    this.handleUndo = this.handleUndo.bind(this);
    this.handleUserSelect = this.handleUserSelect.bind(this);
    this.assignUsertoMultiple = this.assignUsertoMultiple.bind(this);
    this.handleDelayClick = this.handleDelayClick.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handlePondSave = this.handlePondSave.bind(this);
    this.expandTaskCard = this.expandTaskCard.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.closeSnackBar = this.closeSnackBar.bind(this);
    this.handleExport = this.handleExport.bind(this);
  }

  promisedSetState = (newState) => {
    return new Promise((resolve) => {
      this.setState(newState, () => {
        resolve();
      });
    });
  };

  componentDidMount() {
    this.createTaskIDList(this.props.tasks, this.props.filterState);
  }

  componentDidUpdate(prevProps) {
    if (
      !objectsAreEqual(prevProps.tasks, this.props.tasks) ||
      !objectsAreEqual(prevProps.filterState, this.props.filterState) ||
      !objectsAreEqual(prevProps.batches, this.props.batches)
    ) {
      this.createTaskIDList(this.props.tasks, this.props.filterState);
    }
  }

  getPaginationContainer = () => {
    let res = null;
    const lastPage =
      Object.keys(this.state.ListTask).length > 0
        ? Math.ceil(
            Object.keys(this.state.ListTask).length / this.props.pageSize
          )
        : 1;
    // console.log("lastPage", lastPage, Object.keys(this.state.ListTask).length);
    if (checkNull(lastPage, false) && lastPage > 1) {
      const pagesArr = Array.from({ length: lastPage }, (_, i) => i + 1);
      res = (
        <nav aria-label="Page navigation example">
          <ul class="pagination">
            {pagesArr.map((pageNo) => {
              return (
                <li
                  class={`page-item${
                    pageNo == this.props.pageNo ? " active" : ""
                  }`}
                  onClick={() => {
                    if (!(pageNo == this.props.pageNo)) {
                      this.props.handlePageNoChange("todo", pageNo);
                    }
                  }}
                >
                  <a class="page-link">{pageNo}</a>
                </li>
              );
            })}
          </ul>
        </nav>
      );
    }
    return res;
  };

  createTaskIDList = (tasks, filterState) => {
    // sort Tasks into date buckets
    let dateList = []; // used as an ordering index for the dates
    let dateListObj = {}; // keys are date strings, values are [taskID]
    let json = {};

    Object.keys(tasks).forEach((taskID) => {
      const idArr = taskID.split("-");
      const batch = this.props.batches[idArr[0]];
      if (!filterState.showCompleted) {
        if (!tasks[taskID].isNext) {
          return;
        }
      }

      // apply filters
      const filterResult = this.filterTodos(
        tasks[taskID],
        filterState.showCompleted,
        filterState.todoStartDate,
        filterState.todoEndDate,
        filterState.todoFilter,
        filterState.todoDateFilter,
        filterState.todoUser
      );

      const dateString = moment(tasks[taskID].date).format("YYYY-MM-DD");
      if (!dateList.includes(dateString)) {
        dateList.push(dateString);
        dateListObj[dateString] = [];
      }
      dateListObj[dateString].push(taskID);

      if (filterResult) {
        json[taskID] = { seedLot: batch ? batch.seedLot : {} };
      }
    });

    dateList.sort((a, b) => this.dateSort(a, b, filterState.todoSort));

    if (Object.keys(json).length) {
      let found = true;
      Object.keys(json).forEach((each) => {
        if (Object.keys(this.state.ListTask)[each]) {
        } else {
          found = false;
          return;
        }
      });

      if (found === false) this.setState({ ListTask: json });
    } else {
      this.setState({ ListTask: {} });
    }
    this.setState({ dateList, dateListObj });
  };

  filterTodos(
    task,
    showCompleted,
    todoStartDate,
    todoEndDate,
    todoFilter,
    todoDateFilter,
    todoUser
  ) {
    if(todoDateFilter == "today"){
      console.log("filterTodossssss:",task,
      showCompleted,
      todoStartDate,
      todoEndDate,
      todoFilter,
      todoDateFilter)
    }
    let showCompletedFilter = true;
    let dateFilter = true;
    let typeFilter = true;
    let userFilter = true;

    // showCompletedFilter = showCompleted ? task.isComplete : true ;

    if (showCompleted) {
      showCompletedFilter = task.isComplete ? true : false;
    } else {
      showCompletedFilter = task.isComplete ? false : true;
    }

    if (todoUser) {
      userFilter = todoUser === task.user;
    }

    switch (todoFilter) {
      case TASK_NAMES.SEED:
      case TASK_NAMES.START:
        typeFilter =
          task.type === todoFilter || task.type === TASK_NAMES.SEED_AND_START;
        break;

      case TASK_NAMES.TRANSFER_PROP:
        typeFilter = task.transferTo === STAGES.PROP;
        break;

      case TASK_NAMES.TRANSFER_HD:
        typeFilter = task.transferTo === STAGES.HD;
        break;

      case TASK_NAMES.TRANSFER_FS:
        typeFilter = task.transferTo === STAGES.FS;
        break;

      case TASK_NAMES.HARVEST:
        typeFilter = task.type === todoFilter;
        break;

      case "all":
        typeFilter = true;
        break;

      default:
        typeFilter = task.user === todoFilter;
        break;
    }

    switch (todoDateFilter) {
      case "seeall":
        dateFilter = true;
        break;

      case "today":
        dateFilter = moment(task.date).isSame(moment().format("YYYY-MM-DD"));
        break;
      case "thisweek":
      case "daterange":
        dateFilter =
          moment(task.date).isSameOrAfter(todoStartDate, "day") &&
          moment(task.date).isSameOrBefore(todoEndDate, "day");
        break;

      default:
        dateFilter = true;
        break;
    }

    return showCompletedFilter && dateFilter && typeFilter && userFilter;
  }

  dateSort(a, b, direction = "") {
    // handle undefined objects
    if (!a || !b) {
      return 0;
    }
    const multiplier = direction === "asc" ? 1 : -1;

    if (moment(a).isBefore(b)) {
      return -1 * multiplier;
    }
    if (moment(a).isAfter(b)) {
      return 1 * multiplier;
    }
    return 0;
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!this.state.shouldUpdate && !nextState.shouldUpdate) {
      return false;
    }
    return true;
  }

  handleUndoOne = (id) => async ({
    taskCompletedDate = "",
    seedLot = null,
    harvest = null,
    disposal = null,
    usePlannedDate = false,
    adjustGrowthCycle = false,
  }) => {
    const [batchID, taskName] = id.split("-");
    const task = this.props.tasks[id];

    let selectedTasks = this.state.selectedTasks.slice();

    setTimeout(async () => {
      // update batch, variety, and growthCycles as needed
      let updatedBatch = updateBatchesForTaskCompletion({
        taskList: [id],
        taskCompletedDate,
        user: this.context.user,
        seedLotList: [seedLot],
        harvest,
        disposal,
        adjustGrowthCycle,
        growthCycles: this.props.growthCycles,
        varieties: this.props.varieties,
        batches: this.props.batches,
        rafts: this.props.rafts,
        undo: true,
      })[batchID];

      let updatedVariety;
      if (
        taskName === TASK_NAMES.SEED ||
        taskName === TASK_NAMES.SEED_AND_START
      ) {
        updatedVariety = updateVarietiesForTaskCompletion({
          taskList: [id],
          taskCompletedDate,
          seedLot,
          tasks: this.props.tasks,
          varieties: this.props.varieties,
        })[task.variety];
      }

      let newAndModifiedGrowthCycles;
      if (adjustGrowthCycle) {
        newAndModifiedGrowthCycles = updateGrowthCyclesForTaskCompletion({
          batch: updatedBatch,
          batchID,
          variety: updatedVariety || this.props.varieties[task.variety],
          growthCycles: this.props.growthCycles,
          batches: this.props.batches,
        });
      }
      // set undoState
      let undoState = {
        batches: {
          [batchID]: deepCopyObject(this.props.batches[batchID]),
        },
        varieties: updatedVariety
          ? {
              [task.variety]: deepCopyObject(
                this.props.varieties[task.variety]
              ),
            }
          : {},
        growthCycles:
          newAndModifiedGrowthCycles &&
          newAndModifiedGrowthCycles.modifiedGrowthCycles
            ? {
                ...newAndModifiedGrowthCycles.modifiedGrowthCycles,
              }
            : {},
        newGrowthCycle: {
          id: null,
          parentID: null,
        },
      };

      // update batch, variety, and growthCycles in database as needed
      if (adjustGrowthCycle) {
        const {
          modifiedGrowthCycles,
          newGrowthCycle,
        } = newAndModifiedGrowthCycles;
        for (let gcID in modifiedGrowthCycles) {
          addEditItem({
            collection: COLLECTIONS.GROWTH_CYCLES,
            id: gcID,
            item: modifiedGrowthCycles[gcID],
          });
        }
        if (newGrowthCycle) {
          const newCycleObj = await addEditItem({
            collection: COLLECTIONS.GROWTH_CYCLES,
            item: newGrowthCycle,
            parentID: task.variety,
          });
          const gcID = Object.keys(newCycleObj)[0];

          // assign new id to batch and undoState
          updatedBatch.growthCycleID = gcID;
          undoState.newGrowthCycle = {
            id: gcID,
            parentID: task.variety,
          };
        }
      }

      await addEditBatch({ id: batchID, batch: updatedBatch });

      if (updatedVariety) {
        addEditItem({
          collection: COLLECTIONS.VARIETIES,
          id: task.variety,
          item: updatedVariety,
        });

        if (
          updatedVariety.trackStock &&
          updatedVariety.currentStock < updatedVariety.minStock
        ) {
          pushLowInventoryItem(task.variety);
        } else {
          deleteLowInventoryItem(task.variety);
        }
      }

      // reset component state
      selectedTasks = this.state.selectedTasks.slice();
      selectedTasks.splice(selectedTasks.indexOf(id), 1);
      let expandedTasks = this.state.expandedTasks.slice();
      expandedTasks.splice(expandedTasks.indexOf(id), 1);

      this.setState({
        selectedTasks,
        expandedTasks,
        currentTask: "",
        seedLot: "",
        harvest: null,
        disposal: null,
        undoState,
        snackbarOpen: true,
        snackbarMessage: "Task UNDO successfully!",
        snackBarUndo: false,
      });
    }, 20);
  };

  handleCompleteOne = (id) => async (
    e,
    {
      seedLot = null,
      harvest = null,
      disposal = null,
      usePlannedDate = false,
      adjustGrowthCycle = false,
    }
  ) => {
    const [batchID, taskName] = id.split("-");
    const task = this.props.tasks[id];
    const taskCompletedDate = usePlannedDate
      ? task.date
      : moment().format("YYYY-MM-DD");

    // validate the task
    const validationResult = validateTasksForCompletion({
      taskList: [id],
      seedLot,
      tasks: this.props.tasks,
      varieties: this.props.varieties,
      ListTask: this.state.ListTask,
    });

    // handle task validation results
    if (
      validationResult.isNextError ||
      (validationResult.seedLotError && !seedLot) ||
      (validationResult.priorDateError && !usePlannedDate) ||
      (validationResult.dateIsDifferent &&
        !usePlannedDate &&
        !adjustGrowthCycle)
    ) {
      await this.promisedSetState({
        currentTask: id,
        seedLot,
        harvest,
        disposal,
      });
      this.presentValidationNotifications({
        validationResult,
        type: "single",
      });
      return;
    }

    // set checkbox state
    let selectedTasks = this.state.selectedTasks.slice();
    if (e.target.checked) {
      selectedTasks.push(id);
    }
    await this.promisedSetState({ selectedTasks, shouldUpdate: false });

    setTimeout(async () => {
      // update batch, variety, and growthCycles as needed
      let updatedBatch = updateBatchesForTaskCompletion({
        taskList: [id],
        taskCompletedDate,
        user: this.context.user,
        seedLotList: [seedLot],
        harvest,
        disposal,
        adjustGrowthCycle,
        growthCycles: this.props.growthCycles,
        varieties: this.props.varieties,
        batches: this.props.batches,
        rafts: this.props.rafts,
      })[batchID];

      let updatedVariety;
      if (
        taskName === TASK_NAMES.SEED ||
        taskName === TASK_NAMES.SEED_AND_START
      ) {
        updatedVariety = updateVarietiesForTaskCompletion({
          taskList: [id],
          taskCompletedDate,
          seedLot,
          tasks: this.props.tasks,
          varieties: this.props.varieties,
        })[task.variety];
      }

      let newAndModifiedGrowthCycles;
      if (adjustGrowthCycle) {
        newAndModifiedGrowthCycles = updateGrowthCyclesForTaskCompletion({
          batch: updatedBatch,
          batchID,
          variety: updatedVariety || this.props.varieties[task.variety],
          growthCycles: this.props.growthCycles,
          batches: this.props.batches,
        });
      }

      // set undoState
      let undoState = {
        batches: {
          [batchID]: deepCopyObject(this.props.batches[batchID]),
        },
        varieties: updatedVariety
          ? {
              [task.variety]: deepCopyObject(
                this.props.varieties[task.variety]
              ),
            }
          : {},
        growthCycles:
          newAndModifiedGrowthCycles &&
          newAndModifiedGrowthCycles.modifiedGrowthCycles
            ? {
                ...newAndModifiedGrowthCycles.modifiedGrowthCycles,
              }
            : {},
        newGrowthCycle: {
          id: null,
          parentID: null,
        },
      };

      // update batch, variety, and growthCycles in database as needed
      if (adjustGrowthCycle) {
        const {
          modifiedGrowthCycles,
          newGrowthCycle,
        } = newAndModifiedGrowthCycles;
        for (let gcID in modifiedGrowthCycles) {
          addEditItem({
            collection: COLLECTIONS.GROWTH_CYCLES,
            id: gcID,
            item: modifiedGrowthCycles[gcID],
          });
        }
        if (newGrowthCycle) {
          const newCycleObj = await addEditItem({
            collection: COLLECTIONS.GROWTH_CYCLES,
            item: newGrowthCycle,
            parentID: task.variety,
          });
          const gcID = Object.keys(newCycleObj)[0];

          // assign new id to batch and undoState
          updatedBatch.growthCycleID = gcID;
          undoState.newGrowthCycle = {
            id: gcID,
            parentID: task.variety,
          };
        }
      }

      await addEditBatch({ id: batchID, batch: updatedBatch });

      if (updatedVariety) {
        addEditItem({
          collection: COLLECTIONS.VARIETIES,
          id: task.variety,
          item: updatedVariety,
        });

        if (
          updatedVariety.trackStock &&
          updatedVariety.currentStock < updatedVariety.minStock
        ) {
          pushLowInventoryItem(task.variety);
        } else {
          deleteLowInventoryItem(task.variety);
        }
      }

      // reset component state
      selectedTasks = this.state.selectedTasks.slice();
      selectedTasks.splice(selectedTasks.indexOf(id), 1);
      let expandedTasks = this.state.expandedTasks.slice();
      expandedTasks.splice(expandedTasks.indexOf(id), 1);

      this.setState({
        selectedTasks,
        expandedTasks,
        currentTask: "",
        seedLot: "",
        harvest: null,
        disposal: null,
        undoState,
        snackbarOpen: true,
        snackbarMessage: "Task Completed!",
        snackBarUndo: true,
      });
    }, 20);
  };

  resetAllTask = () => {
    console.table("multitask6666000", moment().format("HH:mm:ss"))
    let undoState = {
      batches: {},
      varieties: {},
      growthCycles: {},
      newGrowthCycle: {
        id: null,
        parentID: null,
      },
    };
    const newState = {
      selectedTasks: [],
      expandedTasks: [],
      currentTask: "",
      seedLot: "",
      harvest: null,
      disposal: null,
      undoState,
      snackbarOpen: true,
      snackbarMessage: "Task Completed!",
      snackBarUndo: true,
      isMultiCompleting: false,
      markedTasks: [],
    }
    this.setState(newState);
    console.table("multitask6666111", moment().format("HH:mm:ss"),newState);

  } 

   handleCompleteMultiple = async ({
    seedLot,
    usePlannedDate = false,
    usePickedDate = false,
    taskCompletedDatePicker = "",
  }) => {
    let taskCompletedDate = usePickedDate
      ? moment(taskCompletedDatePicker).format("YYYY-MM-DD")
      : null;
    /**
     *  NOTE: multiple task completion does not currently allow adjustment of growthCycles
     *  multiple task completion cannot be used on harvest tasks, thus we don't worry about 'harvest' or 'disposal' arrays here
     */

    const taskList = this.state.markedTasks;

    // for complete all task complete un comment below line

    const seedTasksList = taskList.filter(
      (id) =>
        this.props.tasks[id].type === TASK_TYPES.SEED ||
        this.props.tasks[id].type === TASK_TYPES.SEED_AND_START
    );
    console.table("multitask1111", moment().format("HH:mm:ss"))
    // validate the task
    const validationResult = validateTasksForCompletion({
      taskList,
      seedLot,
      tasks: this.props.tasks,
      varieties: this.props.varieties,
      ListTask: this.state.ListTask,
    });
    console.table("multitask2222", moment().format("HH:mm:ss"))

    console.log("validationResult", validationResult);
    // return
    // handle task validation results
    if (
      validationResult.isNextError ||
      (validationResult.seedLotError && !seedLot) ||
      (validationResult.priorDateError && !usePlannedDate && !usePickedDate) ||
      (validationResult.dateIsDifferent && !usePlannedDate && !usePickedDate)
    ) {
      this.presentValidationNotifications({
        validationResult,
        type: "multiple",
      });
      return;
    }
    if (usePickedDate) {
      const clearedDialogState = {
        dialogOpen: false,
        dialogMessage: "",
        currentDialog: "",
        dialogButtons: [],
        completionType: "",
        currentTask: null,
        seedLot: "",
        harvest: null,
        disposal: null,
      };
      this.setState({ ...clearedDialogState });
    }
    console.table("multitask3333", moment().format("HH:mm:ss"))

    // set checkbox state
    await this.promisedSetState({
      selectedTasks: taskList,
      shouldUpdate: false,
      tasksToComplete: taskList.length,
      isMultiCompleting: true,
    });

    console.table("multitask4444", moment().format("HH:mm:ss"))

    // setTimeout(async () => {
      console.table("multitask5555", moment().format("HH:mm:ss"))

      // update batch and variety
      let updatedBatches = updateBatchesForTaskCompletion({
        taskList,
        user: this.context.user,
        taskCompletedDate,
        seedLotList: validationResult.lastUsedSeedLot,
        adjustGrowthCycle: usePickedDate,
        growthCycles: this.props.growthCycles,
        varieties: this.props.varieties,
        batches: this.props.batches,
        rafts: this.props.rafts,
      });

      let updatedVarieties = updateVarietiesForTaskCompletion({
        taskList: seedTasksList,
        tasks: this.props.tasks,
        varieties: this.props.varieties,
      });
      // set undoState
      let undoState = {
        batches: {},
        varieties: {},
        growthCycles: {},
        newGrowthCycle: {
          id: null,
          parentID: null,
        },
      };
      Object.keys(updatedBatches).forEach((batchID) => {
        undoState.batches[batchID] = this.props.batches[batchID];
      });
      Object.keys(updatedVarieties).forEach((varID) => {
        undoState.varieties[varID] = this.props.varieties[varID];
      });
      for (const key in this.state.ListTask) {
        const [batchID, task] = key.split("-");
        if (updatedBatches[batchID] && this.props.tasks[key].rating) {
          updatedBatches[batchID].tasks[task].rating = this.props.tasks[
            key
          ].rating;
        }
      }
      console.table("multitask555566666", moment().format("HH:mm:ss"))

      

      // update batch and variety in database
      for (let batchID in updatedBatches) {
        const batch = updatedBatches[batchID];
        // create new lifecycle because new date is picked
          if (usePickedDate && Object.keys(this.props.growthCycles).length > 0) {
            const newCycleObj = await addEditItem({
              collection: COLLECTIONS.GROWTH_CYCLES,
              item: batch.growthCycle,
              parentID: batch.variety,
            });
            const growthCycleID = Object.keys(newCycleObj)[0];
            batch.growthCycleID = growthCycleID;
          }
        // create new lifecycle because new date is picked END
        // await addEditBatch({
        //   id: batchID,
        //   batch,
        // });
      }
      await updateMultipleBatches(updatedBatches);
      console.table("multitask55557777", moment().format("HH:mm:ss"),updatedVarieties);


      for (let varID in updatedVarieties) {
        const variety = updatedVarieties[varID];
        // await addEditItem({
        //   collection: COLLECTIONS.VARIETIES,
        //   id: varID,
        //   item: variety,
        // });

        if (variety.trackStock && variety.currentStock < variety.minStock) {
          await pushLowInventoryItem(varID);
        } else {
          await deleteLowInventoryItem(varID);
        }
      }

      await updateMultipleVarieties(updatedVarieties);

      console.table("multitask55558888", moment().format("HH:mm:ss"));


      // reset component state
      this.resetAllTask();
      console.table("multitask6666222", moment().format("HH:mm:ss"))

    // }, 10);

    console.table("multitask7777", moment().format("HH:mm:ss"))
  }

  presentValidationNotifications({ validationResult = {}, type = "single" }) {
    if (validationResult.isNextError) {
      this.setState({
        dialogOpen: true,
        dialogMessage:
          type === "single"
            ? "Cannot complete this task because there is a prior task for this batch that is not completed."
            : "Cannot complete: one or more selected tasks have a prior task in that batch that is not complete.",
        currentDialog: "notIsNext",
        dialogButtons: [{ label: "Okay", color: "primary" }],
        completionType: type,
      });
      return;
    }

    if (validationResult.seedLotError) {
      if (validationResult.hasLastUsedSeedLot) {
        this.setState({
          dialogOpen: true,
          dialogMessage:
            type === "single"
              ? `Use the last used seed lot: ${
                  validationResult.lastUsedSeedLot[0]
                }?`
              : "Use prior entered seed lots for all tasks to be completed?",
          currentDialog: "useLastSeedLot",
          dialogButtons: [
            { label: "Cancel", answer: "no", color: "primary" },
            { label: "Okay", answer: "yes", color: "primary" },
          ],
          seedLot:
            type === "single"
              ? validationResult.lastUsedSeedLot[0]
              : validationResult.lastUsedSeedLot,
          completionType: type,
        });
      } else {
        this.setState({
          dialogOpen: true,
          dialogMessage:
            type === "single"
              ? "You must enter a Seed Lot."
              : "One or more tasks do not have a prior seed lot to use. These must be entered manually.",
          currentDialog: "requireSeedLot",
          dialogButtons: [{ label: "Okay", color: "primary" }],
          completionType: type,
        });
      }
      return;
    }

    if (validationResult.priorDateError) {
      this.setState({
        dialogOpen: true,
        dialogMessage:
          type === "single"
            ? "You are trying to complete a task on a date earlier than the prior task was completed. Use the planned date as the completion date?"
            : "Today is prior to the date one or more earlier tasks were completed. Use planned date for all tasks?",
        currentDialog: "priorDate",
        dialogButtons: [
          { label: "Cancel", answer: "no", color: "primary" },
          { label: "Pick Date", answer: "pick", color: "primary" },
          { label: "Use Planned", answer: "yes", color: "primary" },
        ],
        completionType: type,
      });
      return;
    }

    if (validationResult.dateIsDifferent) {
      this.setState({
        dialogOpen: true,
        dialogMessage:
          type === "single"
            ? "You are trying to complete a date on a different day than planned. Use today's date or use the planned date?"
            : "Use today's date or use the planned date?",
        //One or more of the tasks has a planned date that is different from today.
        //  + '(If you want to use today for a task completion date, select that task individually).',
        currentDialog: "differentDate",
        dialogButtons:
          type === "single"
            ? [
                { label: "Cancel", answer: "no", color: "primary" },
                { label: "Use Today", answer: "today", color: "primary" },
                { label: "Use Planned", answer: "planned", color: "primary" },
              ]
            : [
                { label: "Cancel", answer: "no", color: "primary" },
                { label: "Pick Date", answer: "pick", color: "primary" },
                { label: "Use Today", answer: "today", color: "primary" },
                { label: "Use Planned", answer: "planned", color: "primary" },
              ],
        completionType: type,
      });
      return;
    }
  }

  async handleTaskExit(id) {
    let hideTasks = this.state.hideTasks.slice();
    hideTasks.push(id);
    if (this.state.tasksToComplete > 1) {
      this.setState((state) => ({
        tasksToComplete: state.tasksToComplete - 1,
        hideTasks,
      }));
    } else {
      await this.promisedSetState({
        hideTasks,
        tasksToComplete: 0,
        shouldUpdate: true,
      });
      this.createTaskIDList(this.props.tasks, this.props.filterState);
      setTimeout(() => {
        this.setState({ hideTasks: [] });
      }, 1000);
    }
  }

  async handleUndo() {
    // restores batches, varieties, and growthCycles to prior state before task completion
    const {
      batches,
      varieties,
      growthCycles,
      newGrowthCycle,
    } = this.state.undoState;

    for (let batchID in batches) {
      const batch = batches[batchID];
      await addEditBatch({
        id: batchID,
        batch,
      });
    }

    for (let varID in varieties) {
      const variety = varieties[varID];
      await addEditItem({
        collection: COLLECTIONS.VARIETIES,
        item: variety,
        id: varID,
      });
      if (variety.currentStock < variety.minStock) {
        pushLowInventoryItem(varID);
      } else {
        deleteLowInventoryItem(varID);
      }
    }

    for (let gcID in growthCycles) {
      const growthCycle = growthCycles[gcID];
      await addEditItem({
        collection: COLLECTIONS.GROWTH_CYCLES,
        item: growthCycle,
        id: gcID,
      });
    }

    if (newGrowthCycle.id) {
      await deleteItem({
        collection: COLLECTIONS.GROWTH_CYCLES,
        id: newGrowthCycle.id,
        parentID: newGrowthCycle.parentID,
      });
    }

    this.setState({
      snackbarOpen: true,
      snackbarMessage: "Undo successful",
      snackBarUndo: false,
      undoState: {},
      hideTask: "",
    });
  }

  handleUserSelect = (id) => async (userID) => {
    // pull tasks from Batch, set user on current one
    const [batchID, taskName, harvestNum] = id.split("-");
    const batch = this.props.batches[batchID];
    const isMultipleHarvest =
      batch.growthCycle.harvestType === HARVEST_TYPES.REPEAT;
    const taskID = !isMultipleHarvest
      ? taskName
      : harvestNum === undefined
      ? taskName
      : taskName.concat("-", harvestNum);

    let tasks = deepCopyObject(this.props.batches[batchID].tasks);
    let task = tasks[taskID];
    task.user = userID;

    // update database and redux state
    await batchTransaction(batchID, { tasks });
    return;
  };

  handleTaskSeedLot = (id) => async (seedLot) => {
    // pull tasks from Batch, set user on current one
    // const [batchID, taskName, harvestNum] = id.split('-');
    // let batch = this.props.batches[batchID];
    // batch.seedLot = seedLot;

    // const isMultipleHarvest = batch.growthCycle.harvestType === HARVEST_TYPES.REPEAT;
    // const taskID = !isMultipleHarvest ? taskName : taskName.concat('-', harvestNum);

    // let tasks = deepCopyObject(this.props.batches[batchID].tasks);
    // let task = tasks[taskID];

    // // update database and redux state
    // await batchTransaction(batchID, { tasks });
    return;
  };

  assignUsertoMultiple(userID) {
    this.promisedSetState({ isAssigningUsers: true });

    const dateList = this.state.dateListObj;
    const taskList = Object.keys(dateList).reduce(
      (acc, curr) => acc.concat(dateList[curr]),
      []
    );

    let batchTasks = {};
    for (let i = 0; i < taskList.length; i++) {
      const [batchID, taskName, harvestNum] = taskList[i].split("-");
      const batch = this.props.batches[batchID];
      const isMultipleHarvest =
        batch.growthCycle.harvestType === HARVEST_TYPES.REPEAT;
      const taskID = !isMultipleHarvest
        ? taskName
        : harvestNum === undefined
        ? taskName
        : taskName.concat("-", harvestNum);

      if (!batchTasks[batchID]) {
        batchTasks[batchID] = batch.tasks;
      }

      if (this.state.markedTasks && this.state.markedTasks.length) {
        let checked = this.state.markedTasks.includes(`${batchID}-${taskID}`);

        if (checked) {
          batchTasks[batchID][taskID].user = userID;
        }
      }
    }

    setTimeout(async () => {
      for (let batchID in batchTasks) {
        const tasks = batchTasks[batchID];
        await addEditBatch({
          batch: { tasks },
          id: batchID,
        });
      }

      this.setState({ isAssigningUsers: false });

      // this.onClickSelectAll()
    }, 50);
  }

  async handleDelayClick(id, delayDays) {
    await this.promisedSetState({ currentTask: id });

    // update growthcycle
    const [batchID, taskName, harvestNum] = id.split("-");
    let batch = this.props.batches[batchID];
    const isMultipleHarvest =
      batch.growthCycle.harvestType === HARVEST_TYPES.REPEAT;
    const taskID = !isMultipleHarvest
      ? taskName
      : harvestNum === undefined
      ? taskName
      : taskName.concat("-", harvestNum);
    let tasks = batch.tasks;
    let task = tasks[taskID];

    let growthCycle = batch.growthCycle;

    let copyGrowthCycle = deepCopyObject(growthCycle);

    let growthCycleID = batch.growthCycleID;

    let modifiedGrowthCycles = {};
    let newGrowthCycle = null;

    if (!isMultipleHarvest || harvestNum === "0") {
      batch.growthCycle.fs.days =
        parseInt(growthCycle.fs.days) + parseInt(delayDays);
      const newAndModifiedGrowthCycles = updateGrowthCyclesForTaskCompletion({
        batch,
        batchID,
        variety: this.props.varieties[task.variety],
        growthCycles: this.props.growthCycles,
        batches: this.props.batches,
      });
      modifiedGrowthCycles = newAndModifiedGrowthCycles.modifiedGrowthCycles;
      newGrowthCycle = newAndModifiedGrowthCycles.newGrowthCycle;
      growthCycle = recalcDatesInGrowthCycle(
        growthCycle,
        growthCycle.germ.date
      );
    } else {
      // push new date to growthCycle
      growthCycle.harvest.date[harvestNum] = moment(
        growthCycle.harvest.date[harvestNum]
      )
        .add(delayDays, "days")
        .format("YYYY-MM-DD");
      growthCycle = recalcDatesInGrowthCycle(
        growthCycle,
        batch.growthCycle.germ.date
      );
    }

    // update task

    let newUpdatedDate = moment(
      isMultipleHarvest
        ? copyGrowthCycle.harvest.date[harvestNum]
        : copyGrowthCycle.harvest.date
    )
      .add(delayDays, "days")
      .format("YYYY-MM-DD");

    // task.date = isMultipleHarvest ? growthCycle.harvest.date[harvestNum] : growthCycle.harvest.date;
    task.updatedDate = newUpdatedDate;
    // update new growth cycle data 
    newGrowthCycle.harvest.date = newUpdatedDate;

    let grfLot = task.GRFlot;
    grfLot =
      grfLot.length > 7 ? grfLot.substring(0, grfLot.length - 3) : grfLot;
    const julianDate = fillNumberStringToLength(
      moment(newUpdatedDate).dayOfYear(),
      3
    );

    grfLot = grfLot.concat(julianDate);
    task.updatedGRFlot = grfLot;

    // update further harvest tasks if multipleHarvest
    if (isMultipleHarvest) {
      let ind = parseInt(harvestNum) + 1;

      while (growthCycle.harvest.date[ind]) {
        // tasks[`${TASK_NAMES.HARVEST}-${ind}`].date = growthCycle.harvest.date[ind];
        tasks[`${TASK_NAMES.HARVEST}-${ind}`].updatedDate = newUpdatedDate;

        let _grfLot = tasks[`${TASK_NAMES.HARVEST}-${ind}`].GRFlot;

        const julianDate = fillNumberStringToLength(
          moment(newUpdatedDate).dayOfYear(),
          3
        );
        _grfLot = grfLot.concat(julianDate);

        tasks[`${TASK_NAMES.HARVEST}-${ind}`].updatedGRFlot = _grfLot;

        ind++;
      }
    }

    for (let gcID in modifiedGrowthCycles) {
      addEditItem({
        collection: COLLECTIONS.GROWTH_CYCLES,
        item: modifiedGrowthCycles[gcID],
        id: gcID,
      });
    }

    if (newGrowthCycle) {
      const newCycleObj = await addEditItem({
        collection: COLLECTIONS.GROWTH_CYCLES,
        item: newGrowthCycle,
        parentID: batch.variety,
      });
      growthCycleID = Object.keys(newCycleObj)[0];
    }

    // update batch in database
    await addEditBatch({
      batch: { tasks, growthCycle, growthCycleID },
      id: batchID,
    });

    // close card
    let expandedTasks = this.state.expandedTasks.slice();
    expandedTasks.splice(expandedTasks.indexOf(id), 1);
    this.setState({
      expandedTasks,
      snackbarOpen: true,
      snackbarMessage: "Task Delayed",
    });
    return;
  }

  async handleSave({ id, harvest, disposal, saveType = true }) {
    await this.setState({ currentTask: id }, async () => {
      // save harvest, disposal, and metrics to batch
      const batchID = id.split("-")[0];
      const batch = this.props.batches[batchID];

      const isRockWool = batch.growthCycle.growMedia === "Rockwool";

      const raftSites = isRockWool
        ? this.props.rafts[batch.growthCycle.fs.raftType].sites
        : parseFloat(batch.QtyToSeed);

      await batchTransaction(batchID, {
        harvest,
        disposal,
        ...calculateBatchMetrics({
          isRockWool,
          QtyToSeed: batch.QtyToSeed,
          harvest: harvest,
          disposal: disposal,
          growthCycle: batch.growthCycle,
          raftSites: raftSites,
          isComplete: batch.current.stage === STAGES.COMPLETED,
          cycleDetail: batch.cycleDetail || {}
        }),
      });

      // close card
      let expandedTasks = this.state.expandedTasks.slice();
      expandedTasks.splice(expandedTasks.indexOf(id), 1);
      this.setState({
        snackbarOpen: true,
        snackbarMessage: saveType
          ? "Task Saved (but not completed)"
          : "Successfully deleted",
      }); //expandedTasks
      return;
    });
    return;
  }

  async handlePondSave({ id, propLocation1, finishingLocation }) {
    await this.setState({ currentTask: id }, async () => {
      // save harvest, disposal, and metrics to batch
      const batchID = id.split("-")[0];
      await batchTransaction(batchID, {
        propLocation1,
        finishingLocation,
      });

      // close card
      let expandedTasks = this.state.expandedTasks.slice();
      expandedTasks.splice(expandedTasks.indexOf(id), 1);
      this.setState({
        snackbarOpen: true,
        snackbarMessage: "Task Saved",
      }); //expandedTasks
      return;
    });
    return;
  }

  expandTaskCard(taskID) {
    let expandedTasks = this.state.expandedTasks.slice();
    if (expandedTasks.includes(taskID)) {
      expandedTasks.splice(expandedTasks.indexOf(taskID), 1);
    } else {
      expandedTasks.push(taskID);
    }
    this.setState({ expandedTasks });
  }

  async closeDialog(answer) {
    const clearedDialogState = {
      dialogOpen: false,
      dialogMessage: "",
      currentDialog: "",
      dialogButtons: [],
      completionType: "",
      currentTask: null,
      seedLot: "",
      harvest: null,
      disposal: null,
    };
    console.log("closeDialog", this.state.currentDialog);
    switch (this.state.currentDialog) {
      case "notIsNext":
        this.setState({ ...clearedDialogState });
        break;

      case "useLastSeedLot":
        if (answer === "yes") {
          await this.promisedSetState({
            dialogOpen: false,
            dialogMessage: "",
            currentDialog: "",
            dialogButtons: [],
          });
          if (this.state.completionType === "single") {
            this.handleCompleteOne(this.state.currentTask)(
              {
                target: {
                  checked: true,
                },
              },
              {
                seedLot: this.state.seedLot,
              }
            );
          } else if (this.state.completionType === "multiple") {
            this.handleCompleteMultiple({
              seedLot: this.state.seedLot,
            });
          }
        } else {
          this.setState({ ...clearedDialogState });
        }
        break;

      case "requireSeedLot":
        this.setState({ ...clearedDialogState });
        break;

      case "priorDate":
        if (answer === "yes") {
          const currentTask =
            this.state.currentTask && this.state.currentTask.slice();
          await this.promisedSetState({
            dialogOpen: false,
            dialogMessage: "",
            currentDialog: "",
            dialogButtons: [],
          });
          if (this.state.completionType === "single") {
            this.handleCompleteOne(currentTask)(
              {
                target: {
                  checked: true,
                },
              },
              {
                seedLot: this.state.seedLot,
                harvest: this.state.harvest,
                disposal: this.state.disposal,
                usePlannedDate: true,
              }
            );
          } else {
            this.handleCompleteMultiple({
              seedLot: this.state.seedLot,
              usePlannedDate: true,
            });
          }
        } else if (answer === "pick") {
          this.handleCompleteMultiple({
            seedLot: this.state.seedLot,
            usePlannedDate: false,
            usePickedDate: true,
            taskCompletedDatePicker: this.state.taskCompletedDatePicker,
          });
        } else {
          this.setState({ ...clearedDialogState });
        }
        break;

      case "differentDate":
        console.log("differentDate", answer);
        if (answer === "planned") {
          await this.promisedSetState({
            dialogOpen: false,
            dialogMessage: "",
            currentDialog: "",
            dialogButtons: [],
          });
          if (this.state.completionType === "single") {
            this.handleCompleteOne(this.state.currentTask)(
              {
                target: {
                  checked: true,
                },
              },
              {
                seedLot: this.state.seedLot,
                harvest: this.state.harvest,
                disposal: this.state.disposal,
                usePlannedDate: true,
              }
            );
          } else {
            this.handleCompleteMultiple({
              seedLot: this.state.seedLot,
              usePlannedDate: true,
            });
          }
        } else if (answer === "pick") {
          this.handleCompleteMultiple({
            seedLot: this.state.seedLot,
            usePlannedDate: false,
            usePickedDate: true,
            taskCompletedDatePicker: this.state.taskCompletedDatePicker,
          });
        } else if (answer === "today") {
          this.handleCompleteMultiple({
            seedLot: this.state.seedLot,
            usePlannedDate: false,
            usePickedDate: true,
            taskCompletedDatePicker: this.state.taskCompletedDatePicker,
          });
          // await this.promisedSetState({
          //   dialogOpen: false,
          //   dialogMessage: "",
          //   currentDialog: "",
          //   dialogButtons: [],
          // });
          // this.handleCompleteOne(this.state.currentTask)(
          //   {
          //     target: {
          //       checked: true,
          //     },
          //   },
          //   {
          //     seedLot: this.state.seedLot,
          //     harvest: this.state.harvest,
          //     disposal: this.state.disposal,
          //     usePlannedDate: false,
          //     adjustGrowthCycle: true,
          //   }
          // );
        } else {
          this.setState({ ...clearedDialogState });
        }
        break;

      default:
        this.setState({ ...clearedDialogState });
        break;
    }
    return;
  }

  openSnackBar(msg="Successfully") {
    console.log("tessadasdasd",msg)
    this.setState({
      snackbarOpen: true,
      snackbarMessage: msg,
    });
  }
  closeSnackBar() {
    this.setState({
      snackbarOpen: false,
      snackbarMessage: "",
      snackBarUndo: false /* , undoState: {} */,
    });
  }

  handleExport() {
    // convert displayed data to csv, download file
  }

  markTask = (taskID) => {
    let index = this.state.markedTasks.indexOf(taskID);
    if (index == -1) {
      this.state.markedTasks.push(taskID);
    } else {
      this.state.markedTasks.splice(index, 1);
    }
    this.setState({ markedTasks: this.state.markedTasks });
  };

  onClickSelectAll = () => {
    const { tasks } = this.props;

    if (this.state.selectAll) {
      this.setState({ markedTasks: [] });
    } else {
      let arr = [];
      Object.keys(this.state.ListTask).map((re) => arr.push(re));
      this.setState({ markedTasks: arr });
    }
    this.setState({ selectAll: !this.state.selectAll });
  };

  handleChangeTaskCardData = (id) => (value) => {
    this.state.ListTask[id].seedLot = value;

    this.setState({ ListTask: this.state.ListTask });
  };

  handleChangeRating = (id) => (value) => {
    // this.state.ListTask[id].rating = value;
    const [batch, task] = id.split("-");
    // this.setState({ ListTask: this.state.ListTask }, () => {
    let copyBatch = this.props.batches[batch];
    copyBatch.tasks[task].rating = value;
    addEditBatch({ id: batch, batch: copyBatch });
    // })
  };

  saveNotes = (id) => (value) => {
    // this.state.ListTask[id].notes = value;
    const [batch, task] = id.split("-");
    this.setState({ ListTask: this.state.ListTask }, () => {
      let copyBatch = this.props.batches[batch];
      copyBatch.tasks[task].notes = value;
      copyBatch.tasks[task].notesDate = moment().format();

      addEditBatch({ id: batch, batch: copyBatch });
    });
  };

  editLocation = (id) => (value) => {
    // this.state.ListTask[id].notes = value;
    const [batch, task] = id.split("-");
    this.setState({ ListTask: this.state.ListTask }, () => {
      let copyBatch = this.props.batches[batch];
      if (copyBatch.QtyToSeed) {
        copyBatch.propLocation1.farm = value;
      } else {
        copyBatch.finishingLocation.farm = value;
      }
      addEditBatch({ id: batch, batch: copyBatch });
    });
  };

  // submitRating = async (rate) => {
  //   let { currentBatch, currentTask } = this.state;
  //   this.setState({ openRatingModal: false }, async () => {
  //     currentBatch.tasks[currentTask].rating = rate;
  //     await addEditBatch({ id: currentBatch.id, batch: currentBatch });
  //   })
  // }

  // handleChangeTaskCardData= e =>{
  // }
  RevertTask = (id) => {
    console.log(id);
    this.handleUndoOne(id)({});
  };

  changeLocation = (e) => {
    console.log("changeLocation", e.target);
    this.setState({ [e.target.name]: e.target.value });

    if (e.target.value === "") {
      this.setState({ selectedPond: "" });
      return;
    } else if (e.target.name === "selectedFarm") {
      this.setState(
        { allPonds: this.props.farm[e.target.value].ponds, selectedPond: "" },
        () => {
          this.createTaskIDList(this.props.tasks, this.props.filterState);
        }
      );
    }
  };

  dateSortFunction = (a, b, direction = "asc") => {
    // console.log("dateSortFunction", a, b);
    if (!a || !b) return 0;
    const multiplier = direction === "asc" ? 1 : -1;
    if (moment(a.date).isBefore(b.date)) {
      return -1 * multiplier;
    }
    if (moment(a.date).isAfter(b.date)) {
      return 1 * multiplier;
    }
    if (moment(a.date).isSame(b.date)) {
      return a.GRFlot.localeCompare(b.GRFlot);
    }
    return 0;
  };
  render() {
    const {
      classes,
      tasks,
      users,
      crops,
      varieties,
      misc,
      rafts,
      filterState,
      handleChange,
      handleCheckBoxChange,
      handleTodayClick,
      handleThisWeekClick,
      handleSeeAllClick,
      formatDate,
      handleDateRangeClick,
      farm,
      csvDownload,
      pageNo,
      pageSize,
      handlePageSizeChange,
    } = this.props;
    const user = this.context.user;
    let ListTaskArr = Object.keys(this.state.ListTask).sort((a, b) =>
      this.dateSortFunction(tasks[a], tasks[b], filterState.todoSort)
    );
    // console.log("ListTaskArr", ListTaskArr);
    //  ListTaskArr.sort((a, b) => this.dateSortFunction(tasks[a], tasks[b], filterState.todoSort)))

    return (
      <div>
        {console.log('multitaskFINALLLL',this.state)}
        <GrowToDoSort
          className={classes.sortBar}
          users={users}
          farm={farm}
          startDate={filterState.todoStartDate}
          endDate={filterState.todoEndDate}
          filter={filterState.todoFilter}
          filterDate={filterState.todoDateFilter}
          selectedFarm={this.state.selectedFarm}
          selectedPond={this.state.selectedPond}
          changeLocation={this.changeLocation}
          allPonds={this.state.allPonds}
          user={filterState.todoUser}
          sort={filterState.todoSort}
          varieties={varieties}
          crops={crops}
          batches={this.props.batches}
          rafts={this.props.rafts}
          openSnackBar={this.openSnackBar}
          showCompleted={filterState.showCompleted}
          selectAll={this.state.selectAll}
          showCompleteAll={this.state.dateList.length > 0}
          isAssigningUsers={this.state.isAssigningUsers}
          isMultiCompleting={this.state.isMultiCompleting}
          markedTasks={this.state.markedTasks}
          pageSize={pageSize}
          handlePageSizeChange={handlePageSizeChange}
          handleChange={handleChange}
          formatDate={formatDate}
          handleCheckBoxChange={handleCheckBoxChange}
          handleTodayClick={handleTodayClick}
          handleThisWeekClick={handleThisWeekClick}
          handleDateRangeClick={handleDateRangeClick}
          handleSeeAllClick={handleSeeAllClick}
          onSelectAll={() => this.onClickSelectAll()}
          handleCompleteAllClick={this.handleCompleteMultiple}
          handleUserSelect={this.assignUsertoMultiple}
          csvDownload={() => csvDownload(this.state.markedTasks)}
        />
        {ListTaskArr.slice((pageNo - 1) * pageSize, pageNo * pageSize).map(
          (taskID) => {
            const task = tasks[taskID];
            if (!task || objectIsEmpty(task)) {
              return null;
            }
            if (taskID === this.state.hideTask) {
              return null;
            }
            const idArr = taskID.split("-");
            const batch = this.props.batches[idArr[0]];
            let forceHide = false;
            if (
              idArr[1] === TASK_NAMES.SEED_AND_START ||
              idArr[1] === TASK_NAMES.START ||
              idArr[1] === TASK_NAMES.SEED
            )
              forceHide = true;

            const isSelected = this.state.selectedTasks.includes(taskID);
            const isSelectedAssign = this.state.markedTasks.includes(taskID);
            const expanded = this.state.expandedTasks.includes(taskID);
            const seedLot = this.state.ListTask[taskID].seedLot;

            const { selectedFarm, selectedPond } = this.state;

            const tempPropLocation = batch.QtyToSeed
              ? "propLocation1"
              : "propLocation";

            let poolName = "";

            if (selectedFarm) {
              if (idArr[1] === TASK_NAMES.TRANSFER_PROP) {
                if (!(batch[tempPropLocation].farm === selectedFarm)) {
                  return null;
                }
              } else if (idArr[1] === TASK_NAMES.TRANSFER_HD) {
                if (!(batch["finishingLocation"].farm === selectedFarm)) {
                  return null;
                }
              } else if (idArr[1] === TASK_NAMES.TRANSFER_FS) {
                if (!(batch["finishingLocation"].farm === selectedFarm)) {
                  return null;
                }
              } else if (idArr[1] === TASK_NAMES.HARVEST) {
                if (!(batch["finishingLocation"].farm === selectedFarm)) {
                  return null;
                }
              }
            }

            if (selectedPond) {
              if (idArr[1] === TASK_NAMES.TRANSFER_PROP) {
                if (batch.QtyToSeed) {
                  let found = false;
                  batch[tempPropLocation].pond.prop.forEach((each2) => {
                    if (each2.pond === selectedPond) {
                      found = true;
                    }
                  });
                  if (!found) return null;
                } else {
                  if (!(batch[tempPropLocation].pond === selectedPond)) {
                    return null;
                  }
                }
              } else if (idArr[1] === TASK_NAMES.TRANSFER_HD) {
                let found = false;
                batch["finishingLocation"].pond.hd.forEach((each2) => {
                  if (each2.pond === selectedPond) {
                    found = true;
                  }
                });

                if (!found) return null;
              } else if (idArr[1] === TASK_NAMES.TRANSFER_FS) {
                let found = false;
                batch["finishingLocation"].pond.fs.forEach((each2) => {
                  if (each2.pond === selectedPond) {
                    found = true;
                  }
                });

                if (!found) return null;
              } else if (idArr[1] === TASK_NAMES.HARVEST) {
                let found = false;
                batch["finishingLocation"].pond.fs.forEach((each2) => {
                  if (each2.pond === selectedPond) {
                    found = true;
                  }
                });

                if (!found) return null;
              }
            }
            const finishingFarmLocationId = batch.QtyToSeed
              ? batch.propLocation1.farm
              : batch.finishingLocation.farm;
            const currentFarmLocationId =
              batch.current.stage === STAGES.PROP
                ? batch.QtyToSeed
                  ? batch.propLocation1.farm
                  : batch.propLocation.farm
                : batch.current.stage === STAGES.HD ||
                  batch.current.stage === STAGES.FS
                ? batch.finishingLocation.farm
                : "";

            // const poolName = batch['finishingLocation'].reduce((acc, a) => {
            //   return acc+ a.name
            // },'');
            // console.log("batchbatch", batch, tempPropLocation);

            if (idArr[1] === TASK_NAMES.TRANSFER_PROP) {
              if (batch.QtyToSeed) {
                batch[tempPropLocation].pond.prop.forEach((each2) => {
                  if (each2.name) {
                    poolName += (each2.name || "") + ", ";
                  }
                });
              } else {
                poolName += batch[tempPropLocation].name;
              }
            } else if (idArr[1] === TASK_NAMES.TRANSFER_HD) {
              batch["finishingLocation"].pond.hd.forEach((each2) => {
                if (each2.name) {
                  poolName += (each2.name || "") + ", ";
                }
              });
            } else if (idArr[1] === TASK_NAMES.TRANSFER_FS) {
              batch["finishingLocation"].pond.fs.forEach((each2) => {
                if (each2.name) {
                  poolName += (each2.name || "") + ", ";
                }
              });
            }
            // console.log("batchbatch", poolName);

            return (
              <SlideAndCollapse
                key={taskID}
                in={!isSelected}
                direction="left"
                timeout={{
                  enter: 0,
                  exit: 1000,
                }}
                onExit={() => console.log("onExit")}
                onExiting={() => console.log("onExiting")}
                onExited={() => this.handleTaskExit(taskID)}
              >
                <div className={classes.taskCardContainer}>
                  <TaskCard
                    id={taskID}
                    showCurrentLocation={
                      !(
                        idArr[1] === TASK_NAMES.SEED ||
                        idArr[1] === TASK_NAMES.START ||
                        idArr[1] === TASK_NAMES.SEED_AND_START
                      )
                    }
                    task={task}
                    batch={batch}
                    users={users}
                    seedLot={seedLot}
                    forceHide={forceHide}
                    user={user}
                    reasons={misc.reasons}
                    rafts={rafts}
                    plots={this.props.plots}
                    poolName={poolName}
                    farm={farm}
                    finishingLocationDetails={farm[finishingFarmLocationId]}
                    currentLocationDetails={farm[currentFarmLocationId]}
                    showCompleted={filterState.showCompleted}
                    selectAll={this.state.selectAll}
                    cropName={crops[task.crop] ? crops[task.crop].name : ""}
                    varietyName={
                      varieties[task.variety]
                        ? varieties[task.variety].name
                        : ""
                    }
                    userIsAdmin={user.role === USER_ROLES.ADMIN}
                    isExpanded={expanded}
                    isSelected={isSelected}
                    isSelectedAssign={isSelectedAssign}
                    handleChange={this.handleChangeTaskCardData}
                    handleChangeRating={(rating) =>
                      this.handleChangeRating(taskID)(rating)
                    }
                    saveNotes={(notes) => this.saveNotes(taskID)(notes)}
                    editLocation={(newlocationId) =>
                      this.editLocation(taskID)(newlocationId)
                    }
                    RevertTask={this.RevertTask}
                    markTask={(taskID) => {
                      this.markTask(taskID);
                    }}
                    handleTaskSelect={this.handleCompleteOne(taskID)}
                    handleUserSelect={this.handleUserSelect(taskID)}
                    handleDelayClick={this.handleDelayClick}
                    onSave={this.handleSave}
                    onPondSave={this.handlePondSave}
                    // handleUserSelectMultiple={this.assignUsertoMultiple}
                    onClick={
                      task.type === TASK_TYPES.HARVEST
                        ? this.expandTaskCard
                        : null
                    }
                  />
                </div>
              </SlideAndCollapse>
            );
          }
        )}
        {this.getPaginationContainer()}
        <Dialog
          open={this.state.dialogOpen}
          onClose={this.closeDialog}
          aria-describedby="confirm-action-dialog"
        >
          <DialogContent>
            <DialogContentText id="confirm-action-dialog">
              {this.state.dialogMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {this.state.dialogButtons.map((button) => (
              <Button
                key={button.label}
                onClick={() => {
                  button.answer === "pick"
                    ? this.setState({ showDatePicker: true })
                    : this.closeDialog(button.answer);
                }}
                color={button.color}
                autoFocus={button.autoFocus}
              >
                {button.showLoader ? (
                  button.showLoader ? (
                    <CircularProgress
                      className={classes.submitButtonLoader}
                      size={21}
                    />
                  ) : (
                    button.label
                  )
                ) : (
                  button.label
                )}
              </Button>
            ))}
            {this.state.showDatePicker ? (
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  label="Task Complete Date"
                  name="todoStartDate"
                  style={{ marginLeft: 15 }}
                  value={this.state.taskCompletedDatePicker}
                  format="MM/DD/YYYY"
                  // mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                  onChange={(date) =>
                    this.setState(
                      { taskCompletedDatePicker: date, showDatePicker: false },
                      () => {
                        this.closeDialog("pick");
                      }
                    )
                  }
                  animateYearScrolling
                  autoOk
                  margin="dense"
                />
              </MuiPickersUtilsProvider>
            ) : null}
          </DialogActions>
        </Dialog>

        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={this.state.snackbarOpen}
          autoHideDuration={3000}
          onClose={this.closeSnackBar}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">{this.state.snackbarMessage}</span>}
          action={
            this.state.snackBarUndo
              ? [
                  <Button
                    key="undo"
                    color="primary"
                    size="small"
                    onClick={this.handleUndo}
                  >
                    UNDO
                  </Button>,
                  <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    className={classes.close}
                    onClick={this.closeSnackBar}
                  >
                    <i className="fas fa-times" />
                  </IconButton>,
                ]
              : [
                  <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    className={classes.close}
                    onClick={this.closeSnackBar}
                  >
                    <i className="fas fa-times" />
                  </IconButton>,
                ]
          }
        />
      </div>
    );
  }
}

GrowTodo.contextType = UserContext;

GrowTodo.propTypes = {
  classes: PropTypes.object.isRequired,
  filterState: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleCheckBoxChange: PropTypes.func.isRequired,
  handleTodayClick: PropTypes.func.isRequired,
  handleThisWeekClick: PropTypes.func.isRequired,
  handleSeeAllClick: PropTypes.func.isRequired,
  crops: PropTypes.object.isRequired,
  varieties: PropTypes.object.isRequired,
  growthCycles: PropTypes.object.isRequired,
  batches: PropTypes.object.isRequired,
  tasks: PropTypes.object.isRequired,
  users: PropTypes.object.isRequired,
  misc: PropTypes.object.isRequired,
  rafts: PropTypes.object.isRequired,
  pushTaskEdit: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    crops: state.crops,
    varieties: state.varieties,
    growthCycles: state.growthCycles,
    batches: state.batches,
    tasks: state.tasks,
    users: state.users,
    misc: state.misc,
    rafts: state.rafts,
    plots: state.plots,
    farm: state.farm,
  };
}

export default withStyles(styles)(
  connect(
    mapStateToProps,
    { pushTaskEdit }
  )(GrowTodo)
);

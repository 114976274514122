import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

import { Button, MenuItem, TextField, Typography, withStyles } from '@material-ui/core';
import red from '@material-ui/core/colors/red';
import blue from '@material-ui/core/colors/blue';
import Divider from '@material-ui/core/Divider';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import classNames from 'classnames';

import FinishingPondCard from './FinishingPondCard'
import { deepCopyObject } from '../utils/utils';

const styles = theme => ({
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    minWidth: 580,
    maxWidth: 800,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    '&:focus': {
      outline: 'none'
    },
    overflow: 'auto',
    maxHeight: 600,
    minHeight: 500,
  },
  container: {
    width: '100%',
  },
  button: {
    margin: '0.5rem 0rem'
  },
  paper: {
    width: '100%',
    marginBottom: '2rem'
  },
  actionIcons: {
    marginRight: '0.5rem',
    cursor: 'pointer',
    '&:last-child': {
      marginRight: 0
    },
    fontSize: '1.5rem',
    marginTop: '1.25rem'
  },
  blue: {
    color: blue[500]
  },
  red: {
    color: red[500]
  },
  tabContainer: {
    margin: '0 0 1rem'
  },
  tabs: {
    width: '90%',
    minWidth: 400,
    '& button': {
      width: '35%',
      maxWidth: 'none'
    },
    alignSelf: 'center',
    marginLeft: '5%'
  },
  tab: {
    fontSize: '1.1rem'
  },
  divider: {
    width: '60%',
    marginTop: '-1px',
    marginLeft: '20%'
  }
});

class FinishingPonds extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: props.data.growthCycleObj.hd.days ? 0 : 1,
      dataVal : props.data,
      tempHdEndRafts : props?.data?.hdEndRafts || 0,
      tempFsEndRafts : props?.data?.fsEndRafts || 0,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  rowTemplate1 = {
    pond: '',
    noOfRaft: '',
    name: ''
  }

  handleTabChange = (e, tabValue) => {
    this.setState({ tabValue });
  };

  handleInputChange = (ind, pondType, finalPond) => e => {
    console.log("yetshasda0000",Date.now());
    const stateDataVal = deepCopyObject(this.state.dataVal)
    const farmLocation = pondType === 'prop' ? 'propLocation1' : 'finishingLocation'

    if (finalPond) {
      let ponddataVal = finalPond.find(element => element.id === e.target.value);
      stateDataVal[farmLocation].pond[pondType][ind].name = ponddataVal.name;
      stateDataVal[farmLocation].pond[pondType][ind][e.target.name] = e.target.value;

    }
    else {
      stateDataVal[farmLocation].pond[pondType][ind][e.target.name] = e.target.value;
    }

    const selectedPond = stateDataVal?.[farmLocation]?.pond?.[pondType];
    if(selectedPond && e.target.name == "noOfRaft"){
      if(pondType == "hd"){
        let newHdRafts = stateDataVal.hdRafts;
        if(selectedPond.length > 0){
          newHdRafts = selectedPond.reduce(function (sum,current) {
            return sum + parseFloat(current?.[e.target.name] || 0)
          },0)
        }
        stateDataVal.hdEndRafts = newHdRafts;
        const hdNewRem = stateDataVal.hdRafts - newHdRafts;
        stateDataVal.hdRemainder = parseFloat(hdNewRem > 0 ? hdNewRem : 0).toFixed(2);
      }
      if(pondType == "fs"){
        let newFsRafts = stateDataVal.fsRafts;
        if(selectedPond.length > 0){
          newFsRafts = selectedPond.reduce(function (sum,current) {
            return sum + parseFloat(current?.[e.target.name] || 0)
          },0)
        }
        stateDataVal.fsEndRafts = newFsRafts;
        const fsNewRem = stateDataVal.fsRafts - newFsRafts;
        stateDataVal.fsRemainder = parseFloat(fsNewRem > 0 ? fsNewRem : 0).toFixed(2);
      }
    }
    console.log("yetshasda1111",stateDataVal)

    this.setState({ dataVal: stateDataVal })

    console.log("yetshasda2222",Date.now())

    console.log("yetshasda3333",Date.now())

  }

  // UNSAFE_componentWillReceiveProps = (newProps) =>{
  //   const { errors } = newProps;

  //   if(errors.hd){
  //     this.setState({tabValue:0})
  //   }
  //   else if(errors.fs){
  //     this.setState({tabValue:1})
  //   }

  // }

  deletePond = (ind, pondType) => {
    const stateDataVal = deepCopyObject(this.state.dataVal)
    const farmLocation = pondType === 'prop' ? 'propLocation1' : 'finishingLocation';
    let arr = [...stateDataVal[farmLocation].pond[pondType]];
    arr[ind] = null;
    stateDataVal[farmLocation].pond[pondType] = arr.filter(Boolean);
    this.setState({ dataVal: stateDataVal })
  }
  addRowInPond = (pondType) => {
    const stateDataVal = deepCopyObject(this.state.dataVal)
    const farmLocation = pondType === 'prop' ? 'propLocation1' : 'finishingLocation';
    let arr = [...stateDataVal[farmLocation].pond[pondType]];
    arr.push(this.rowTemplate1);
    stateDataVal[farmLocation].pond[pondType] = arr;
    this.setState({ dataVal: stateDataVal })
  }




  render() {
    const { classes, finishingFarm, data, handleChangePond, checkAvailabilityPond, handleSubmit, errors, errorMessages, growthCyclesType, growthCycles } = this.props;
    const { tabValue, dataVal,tempHdEndRafts, tempFsEndRafts } = this.state;
    const showHd = !dataVal.QtyToSeed;
    const pondType = growthCyclesType === 'Rockwool' ? tabValue ? 'fs' : 'hd' : 'prop';
    const farmLocation = pondType === 'prop' ? 'propLocation1' : 'finishingLocation';
    const sum = dataVal[farmLocation].pond[pondType].reduce((acc, a) => {
      return acc + parseFloat(a.noOfRaft > 0 ? a.noOfRaft : 0 || 0)
    }, 0);

    let totalRaft = 0;
    console.log("dataValaaaaaaaaaaa", dataVal);
    if (dataVal.QtyToSeed) {
      totalRaft = parseInt(dataVal.QtyToSeed);
    }
    else {
      if(pondType == "fs"){
        totalRaft = tempFsEndRafts || growthCycles?.[dataVal.growthCycle]?.[pondType]?.endRafts;
      }else if(pondType == "hd"){
        totalRaft = tempHdEndRafts || growthCycles?.[dataVal.growthCycle]?.[pondType]?.endRafts;
      }else if(pondType == "prop"){
        totalRaft = growthCycles[dataVal.growthCycle][pondType]?.endRafts;
      }
    }

    return (
      <div>
        <div className={classes.modal} tabIndex={-1}>

          <Grid container className={classes.tabContainer} justify="flex-start">

            {growthCyclesType === 'Rockwool' ?
              <Tabs
                value={this.state.tabValue}
                onChange={this.handleTabChange}
                indicatorColor="secondary"
                textColor="secondary"
                centered
                className={classes.tabs}
              >
                {dataVal.growthCycleObj.hd.days && <Tab label="HD Pond" className={classes.tab} />}
                <Tab label="FS Pond" className={classes.tab} />
              </Tabs> :
              <Tabs
                value={this.state.tabValue}
                onChange={this.handleTabChange}
                indicatorColor="secondary"
                textColor="secondary"
                centered
                className={classes.tabs}
              >
                <Tab label="Propogation Pond" className={classes.tab} />
              </Tabs>}

            <Divider className={classes.divider} />
          </Grid>

          <div>
            <Typography variant="h6">Total {showHd ? 'Rafts' : 'Trays'} : {parseFloat(totalRaft || 0).toFixed(2) }</Typography>
            <Typography variant="body1">Total Remaining {showHd ? 'Rafts' : 'Trays'} : {parseFloat(parseFloat(totalRaft || 0) - (sum || 0)).toFixed(2)}</Typography>
          </div>

          <div style={{ flex: 1 }}>
            {dataVal[farmLocation].pond[pondType].map((each, ind) => (
              <Grid container xs={12} direction={'row'}>
                <Grid item xs={1} style={{ marginTop: 25 }}>
                  <Typography variant="h5">{ind + 1}. </Typography>
                </Grid>
                <Grid item xs={4} md={3}>
                  <TextField
                    required
                    select
                    id={`selct-pond`}
                    label="Select Pond"
                    name={'pond'}
                    value={each.pond}
                    margin="dense"
                    onChange={this.handleInputChange(ind, pondType ,finishingFarm.ponds)}
                    fullWidth
                    //  let finalPond = finishingFarm.pondsfinalPond

                  >
                    {finishingFarm && finishingFarm.ponds.map(each => (
                      <MenuItem key={each.id} value={each.id}>{each.name}</MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={4} md={3} style={{ marginLeft: 20 }}>
                  <TextField
                    type="number"
                    margin="dense"
                    label={showHd ? 'No of Rafts' : 'No of Trays'}
                    name='noOfRaft'
                    onChange={this.handleInputChange(ind, pondType)}
                    value={each.noOfRaft}
                  />
                </Grid>

                {ind ? <Grid>
                  <i
                    className={`far fa-times-circle fa-2x ${classNames(classes.actionIcons, classes.red, { [classes.grey]: false })}`}
                    onClick={() => this.deletePond(ind, pondType)}>
                  </i>
                </Grid> : null}

                <FinishingPondCard {...{ each, checkAvailabilityPond, pondType, data: dataVal }} />


              </Grid>
            ))}
            {parseFloat(parseFloat(totalRaft) - sum).toFixed(2) > 0 && <Button onClick={() => this.addRowInPond(pondType)} variant={'contained'} style={{ marginTop: 25 }}>Add Pond</Button>}
          </div>
          {errors[pondType] && <Typography style={{ color: 'red', marginTop: 15 }}>{errorMessages[pondType]}</Typography>}

          <div style={{ width: '100%' }}>
            <Button color="primary" variant={'contained'} onClick={()=>handleSubmit(this.state.dataVal)} style={{ marginTop: 25, }}>Submit</Button>
          </div>
        </div>
      </div>
    )
  }
};

FinishingPonds.propTypes = {
  classes: PropTypes.object.isRequired,
  rafts: PropTypes.object.isRequired,
  addEditItem: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
  checkAvailabilityPond: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default withStyles(styles)(FinishingPonds);

import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Downshift from 'downshift';

const styles = theme => ({
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2
  },
  sortBarContainer: {
    marginBottom: '0rem'
  },
  textField: {
    width: '100%'
  },
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  downshiftPaper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
});

function renderSuggestion({ suggestion, index, itemProps, highlightedIndex, selectedItem }) {
  const isHighlighted = highlightedIndex === index;
  const isSelected = (selectedItem || '').indexOf(suggestion.name) > -1;

  return (
    <MenuItem
      {...itemProps}
      key={suggestion.name}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
    >
      {suggestion.name}
    </MenuItem>
  );
}

function getSuggestions(value, suggestionList) {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;

  return inputLength === 0
    ? []
    : suggestionList.filter(suggestion => {
        const keep =
          count < 5 && suggestion.name.slice(0, inputLength).toLowerCase() === inputValue;

        if (keep) {
          count += 1;
        }

        return keep;
      });
}

const BasicSortBar = props => {
  const {classes, className, cropSort, varietySort, handleChange, varieties, onSearchSelect} = props;
  const suggestionList = Object.keys(varieties).map(varID => ({name: varieties[varID].name, id: varID}));
  return (
    <Paper className={`${classes.paper} ${className || ''}`} elevation={1}>
    <Grid container spacing={16} className={classes.sortBarContainer}>
      
        <Grid item xs={6} md={3}>
          <Downshift
            id="search"
            onChange={selection => onSearchSelect(selection)}
          >
          {({
            getInputProps,
            getItemProps,
            getMenuProps,
            highlightedIndex,
            inputValue,
            isOpen,
            selectedItem,
            clearSelection
          }) => (
            <div className={classes.container}>
              <TextField
                className={classes.textField}
                type="search"
                margin="dense"
                label="Search Varieties"
                inputProps={
                  getInputProps({
                    placeholder: 'Search varieties',
                    onChange: clearSelection
                  })
                }
              />
              <div {...getMenuProps()}>
                {isOpen ? (
                  <Paper className={classes.downshiftPaper} square>
                    {getSuggestions(inputValue, suggestionList).map((suggestion, index) =>
                      renderSuggestion({
                        suggestion,
                        index,
                        itemProps: getItemProps({ item: suggestion.name }),
                        highlightedIndex,
                        selectedItem
                      }),
                    )}
                  </Paper>
                ) : null}
              </div>
            </div>
          )}
          </Downshift>
        </Grid>

        <Grid item xs={6} md={3}>
          <TextField
            select
            id="cropSort"
            label="Crop Sort"
            value={cropSort}
            onChange={handleChange('cropSort')}
            margin="dense"
            className={classes.textField}
          >
            <MenuItem value={"asc"}>Alphabetical &nbsp;&nbsp;<i className="fas fa-caret-up"></i></MenuItem>
            <MenuItem value={"desc"}>Alphabetical &nbsp;&nbsp;<i className="fas fa-caret-down"></i></MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={6} md={3}>
          <TextField
            select
            id="varietySort"
            label="Variety Sort"
            value={varietySort}
            onChange={handleChange('varietySort')}
            margin="dense"    
            className={classes.textField}
          >
            <MenuItem value={"asc"}>Alphabetical &nbsp;&nbsp;<i className="fas fa-caret-up"></i></MenuItem>
            <MenuItem value={"desc"}>Alphabetical &nbsp;&nbsp;<i className="fas fa-caret-down"></i></MenuItem>
          </TextField>
        </Grid>
      
    </Grid>
    </Paper>
  );
}

BasicSortBar.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  varieties: PropTypes.object.isRequired,
  cropSort: PropTypes.string.isRequired,
  varietySort: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  onSearchSelect: PropTypes.func.isRequired
};

export default withStyles(styles)(BasicSortBar);
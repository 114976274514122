import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Divider } from '@material-ui/core';

import { withStyles } from '@material-ui/core';

const styles = theme => ({
    modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        minWidth: 350,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
        '&:focus': {
            outline: 'none'
        }
    },
    table: {
        minWidth: 650,
    },
});

class LotNoModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            notes: '',
            action: 'new',
            isSaving: false,
            errors: {
                name: false
            }
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSave = this.handleSave.bind(this);
    }

    componentDidMount() {
        // if a crop object is received, assign to state, set action as 'edit'
        if (this.props.crop) {
            const { name, notes } = this.props.crop;
            this.setState({ name, notes, action: 'edit' });
        }
    }

    handleChange = name => e => {
        if (this.state.errors[name]) {
            this.setState({ errors: { ...this.state.error, [name]: false } });
        }
        this.setState({
            [name]: e.target.value
        });
    };

    async handleSave(e) {
        e.preventDefault();

        // Check for required fields
        const requiredFields = ['name'];
        let errorsFound = false;
        requiredFields.forEach((name, i) => {
            if (!this.state[name] || this.state[name] === '') {
                errorsFound = true;
                this.setState({ errors: { ...this.state.errors, [name]: true } });
            }
        });
        if (errorsFound) {
            this.setState({ isSaving: false });
            return;
        }

        // Save information
        this.setState({ isSaving: true });
        const crop = { name: this.state.name, notes: this.state.notes };
        await this.props.onSave(crop);
    }

    render() {
        const { classes, closeModal, allLotNo } = this.props;
        const action = this.state.action.replace(/\b\w/g, l => l.toUpperCase());
        let arr1 = [];
        let arr2 = [];
        allLotNo.map(variety => {
            if (variety.currentStock > 0) {
                arr1.push(variety)
            }
            else {
                arr2.push(variety)
            }
        })

        return (
            <div className={classes.modal} tabIndex={-1}>
                <Typography variant="h6" id="modal-title">Lot Number Details</Typography>
                <Divider />
                <Grid container className={classes.container} justify="center">
                    <Grid item xs={4}>
                        <Typography variant="body1" id="modal-title">AVAILABLE</Typography>
                        {arr1.map(resp => (
                            <Typography variant="body2" id="modal-title">{resp.name} ({resp.lotNo})</Typography>
                        ))}
                    </Grid>
                    <Grid item xs={0.025} style={{ backgroundColor: 'lightgray', width: 2 }} />
                    <Grid item xs={2} />
                    <Grid item xs={4}>
                        <Typography variant="body1" id="modal-title">EXHAUSTED</Typography>
                        {arr2.map(resp => (
                            <Typography variant="body2" id="modal-title">{resp.name} ({resp.lotNo})</Typography>
                        ))}
                    </Grid>
                </Grid>


            </div>
        )
    }
};

LotNoModal.propTypes = {
    classes: PropTypes.object.isRequired,
    allLotNo: PropTypes.object,
    onSave: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired
};

export default withStyles(styles)(LotNoModal);
/** State.errors */
export const ADD_ERROR = "ADD_ERROR";
export const REMOVE_ERROR = "REMOVE_ERROR";

/** State.crops */
export const LOAD_CROPS = "LOAD_CROPS";
export const ADD_EDIT_CROP = "ADD_EDIT_CROP";
export const DELETE_CROP = "DELETE_CROP";

/** State.farms */
export const LOAD_FARMS = "LOAD_FARMS";
export const ADD_EDIT_FARM = "ADD_EDIT_FARM";
export const DELETE_FARM = "DELETE_FARM";

/** State.varieties */
export const LOAD_VARIETIES = "LOAD_VARIETIES";
export const ADD_EDIT_VARIETY = "ADD_EDIT_VARIETY";
export const DELETE_VARIETY = "DELETE_VARIETY";

/** State.growthCycles */
export const LOAD_GROWTH_CYCLES = "LOAD_GROWTH_CYCLES";
export const ADD_EDIT_GROWTH_CYCLE = "ADD_EDIT_GROWTH_CYCLE";
export const DELETE_GROWTH_CYCLE = "DELETE_GROWTH_CYCLE";

/** State.rafts */
export const LOAD_RAFTS = "LOAD_RAFTS";
export const ADD_EDIT_RAFT = "ADD_EDIT_RAFT";
export const DELETE_RAFT = "DELETE_RAFT";

/** State.batches */
export const LOAD_BATCHES = "LOAD_BATCHES";
export const ADD_BATCHES = "ADD_BATCHES";
export const ADD_EDIT_BATCH = "ADD_EDIT_BATCH";
export const DELETE_BATCH = "DELETE_BATCH";

/** State.perpetualBatches */
export const LOAD_PERPETUAL_BATCHES = "LOAD_PERPETUAL_BATCHES";
export const ADD_EDIT_PERPETUAL_BATCH = "ADD_EDIT_PERPETUAL_BATCH";
export const DELETE_PERPETUAL_BATCH = "DELETE_PERPETUAL_BATCH";

/** State.tasks */
export const LOAD_TASKS = "LOAD_TASKS";
export const ADD_TASKS = "ADD_TASKS";
export const EDIT_TASK = "EDIT_TASK";
export const DELETE_TASK = "DELETE_TASK";
export const DELETE_TASKS = "DELETE_TASKS";

/** State.plots */
export const ADD_PLOTS = "ADD_PLOTS";
export const DELETE_PLOTS = "DELETE_PLOTS";

/* State.varietyLegend */
export const ADD_VARIETY_LEGEND = "ADD_VARIETY_LEGEND";

/** State.misc */
export const LOAD_CROP_TYPES = "LOAD_CROP_TYPES";
export const LOAD_VARIETY_CODES = "LOAD_VARIETY_CODES";

export const ADD_CROP_TYPE = "ADD_CROP_TYPE";
export const LOAD_REASONS = "LOAD_REASONS";
export const LOAD_PEST_LIST = "LOAD_PEST_LIST";

export const ADD_REASON = "ADD_REASON";
export const LOAD_INVENTORY_ALERTS = "LOAD_INVENTORY_ALERTS";
export const ADD_INVENTORY_ALERT = "ADD_INVENTORY_ALERT";
export const DELETE_INVENTORY_ALERT = "DELETE_INVENTORY_ALERT";
export const DELETE_ALL_INVENTORY_ALERTS = "DELETE_ALL_INVENTORY_ALERTS";
export const SET_HAS_INITIALLY_LOADED = "SET_HAS_INITIALLY_LOADED";
export const SET_SHOULD_CALCULATE_PLOTS = "SET_SHOULD_CALCULATE_PLOTS";
export const SET_PLOT_CALC_AFTER_NUMBER = "SET_PLOT_CALC_AFTER_NUMBER";
export const SET_PLOT_CALC_SUSPEND_COUNTER = "SET_PLOT_CALC_SUSPEND_COUNTER";
export const SET_SHOULD_UPDATE_ON_DATABASE_CHANGE = "SET_SHOULD_UPDATE_ON_DATABASE_CHANGE";

/** State.users */
export const LOAD_USERS = "LOAD_USERS";
export const ADD_USER = "ADD_USER";
export const LOAD_CURRENT_USER = "LOAD_CURRENT_USER";
import {
  LOAD_CROPS,
  LOAD_VARIETIES,
  LOAD_GROWTH_CYCLES,
  LOAD_RAFTS,
  LOAD_CROP_TYPES,
  LOAD_VARIETY_CODES,
  LOAD_REASONS,
  LOAD_BATCHES,
  LOAD_TASKS,
  LOAD_USERS,
  LOAD_CURRENT_USER,
  LOAD_INVENTORY_ALERTS,
  SET_SHOULD_UPDATE_ON_DATABASE_CHANGE,
  LOAD_PEST_LIST
} from '../actionTypes';
import firebase from 'firebase/app';
import 'firebase/firestore';
import {
  pushCrop,
  pushFarm,
  pushVariety,
  pushGrowthCycle,
  pushRaft,
  pushBatch,
  pushUser,
  pushPerpetualBatch,
  pushPlots,
  pushVarietyLegend,
  setShouldCalculatePlots,
  setPlotCalcCounter
} from './adders';
import {
  removeTasks,
  removeCrop,
  removeFarm,
  removeRaft,
  removeVariety,
  removeGrowthCycle,
  removeBatch,
  removePerpetualBatch
} from './deleters';
import { createPlotsFromBatches } from '../../utils/appFunctions/plots';
import APP_SETTINGS from '../../utils/appSettings';
const { COLLECTIONS } = APP_SETTINGS;


export const loadCrops = crops => ({
  type: LOAD_CROPS,
  crops
});

export const loadVarieties = varieties => ({
  type: LOAD_VARIETIES,
  varieties
});

export const loadGrowthCycles = growthCycles => ({
  type: LOAD_GROWTH_CYCLES,
  growthCycles
});

export const loadRafts = rafts => ({
  type: LOAD_RAFTS,
  rafts
});

export const loadCropTypes = list => ({
  type: LOAD_CROP_TYPES,
  list
});

export const loadVarietyCodes = list => ({
  type: LOAD_VARIETY_CODES,
  list
});

export const loadReasons = list => ({
  type: LOAD_REASONS,
  list
});
export const loadPestList = list => ({
  type: LOAD_PEST_LIST,
  list
});

export const loadBatches = batches => ({
  type: LOAD_BATCHES,
  batches
});

export const loadTasks = (tasks, batchID) => ({
  type: LOAD_TASKS,
  tasks,
  batchID
});

export const loadUsers = users => ({
  type: LOAD_USERS,
  users
});

export const loadLowInventory = list => ({
  type: LOAD_INVENTORY_ALERTS,
  list
});

export const pushCurrentUser = user => ({
  type: LOAD_CURRENT_USER,
  user
});

export const setShouldUpdateOnDatabaseChange = state => ({
  type: SET_SHOULD_UPDATE_ON_DATABASE_CHANGE,
  state
});

export function loadDB(store) {
  const db = firebase.firestore();

  const unSubCrops = db.collection(COLLECTIONS.CROPS).onSnapshot(snapshot => {
    snapshot.docChanges().forEach(change => {
      if (change.type === 'added' || change.type === 'modified') {
        const crop = { [change.doc.id]: change.doc.data() };
        store.dispatch(pushCrop(crop));
      }
      if (change.type === 'removed') {
        store.dispatch(removeCrop(change.doc.id));
      }
    })
  });

  const unSubFarms = db.collection(COLLECTIONS.FARMS).onSnapshot(snapshot => {
    snapshot.docChanges().forEach(change => {
      if (change.type === 'added' || change.type === 'modified') {
        let farm = { [change.doc.id]: change.doc.data() };
        farm[change.doc.id].id = change.doc.id;
        store.dispatch(pushFarm(farm));
      }
      if (change.type === 'removed') {
        store.dispatch(removeFarm(change.doc.id));
      }
    })
  });

  const unSubVarieties = db.collection(COLLECTIONS.VARIETIES).onSnapshot(snapshot => {
    snapshot.docChanges().forEach(change => {
      if (change.type === 'added' || change.type === 'modified') {
        const variety = { [change.doc.id]: change.doc.data() };
        store.dispatch(pushVariety(variety));
      }
      if (change.type === 'removed') {
        store.dispatch(removeVariety(change.doc.id));
      }
    })
  });

  const unSubGrowthCycles = db.collection(COLLECTIONS.GROWTH_CYCLES).onSnapshot(snapshot => {
    snapshot.docChanges().forEach(change => {
      if (change.type === 'added' || change.type === 'modified') {
        const newGrowthCycle = { [change.doc.id]: change.doc.data() };
        store.dispatch(pushGrowthCycle(newGrowthCycle));
      }
      if (change.type === 'removed') {
        store.dispatch(removeGrowthCycle(change.doc.id));
      }
    })
  });

  const unSubRafts = db.collection(COLLECTIONS.RAFTS).onSnapshot(snapshot => {
    snapshot.docChanges().forEach(change => {
      if (change.type === 'added' || change.type === 'modified') {
        const newRaft = { [change.doc.id]: change.doc.data() };
        store.dispatch(pushRaft(newRaft));
      }
      if (change.type === 'removed') {
        store.dispatch(removeRaft(change.doc.id));
      }
    })
  });

  const unSubBatches = db.collection(COLLECTIONS.BATCHES).onSnapshot(snapshot => {
    snapshot.docChanges().forEach(change => {
      // console.log('unSubBatches', change.type, change.doc.data().isDeleted, change.doc.id, change.doc.data().tasks)
      if (change.type === 'added') {
        const newBatch = { [change.doc.id]: change.doc.data() };
        if (!(change.doc.data().isDeleted)) {
          store.dispatch(pushBatch(newBatch));
          store.dispatch(loadTasks(change.doc.data().tasks, change.doc.id));
        }
      }
      if (change.type === 'modified') {
        // if (!(change.doc.data().isDeleted)) {
        store.dispatch(loadTasks(change.doc.data().isDeleted ? {} : change.doc.data().tasks, change.doc.id));
        store.dispatch(pushBatch({ [change.doc.id]: change.doc.data() }));
        // }
      }
      if (change.type === 'removed') {
        if (!(change.doc.data().isDeleted)) {
          store.dispatch(removeBatch(change.doc.id));
          store.dispatch(removeTasks(change.doc.id));
        }
      }
    });

    // allow pause of calculation of plots when multiple individual batches are being added
    const { shouldCalculatePlots, calcPlotAfterNumber, plotCalcSuspendCounter } = store.getState().misc;
    if (!shouldCalculatePlots) {
      store.dispatch(setPlotCalcCounter(plotCalcSuspendCounter + 1));
      if (plotCalcSuspendCounter === calcPlotAfterNumber) {
        store.dispatch(setShouldCalculatePlots(true));
        store.dispatch(setPlotCalcCounter(0));
      }
    }
    if (shouldCalculatePlots) {
      const { batches, varieties } = store.getState();


      let plotsFromBatches = createPlotsFromBatches({ batches, varieties });

      if (plotsFromBatches) {
        const { plots, varietyLegend } = plotsFromBatches;
        store.dispatch(pushPlots(plots));
        store.dispatch(pushVarietyLegend(varietyLegend));
      }

    }
  });

  const unSubPerpetualBatches = db.collection(COLLECTIONS.PERPETUAL_BATCHES).onSnapshot(snapshot => {
    snapshot.docChanges().forEach(change => {
      if (change.type === 'added' || change.type === 'modified') {
        const newPerpetualBatch = { [change.doc.id]: change.doc.data() };
        store.dispatch(pushPerpetualBatch(newPerpetualBatch));
      }
      if (change.type === 'removed') {
        store.dispatch(removePerpetualBatch(change.doc.id));
      }
    })
  });

  const unSubUsers = db.collection(COLLECTIONS.USERS).onSnapshot(snapshot => {
    snapshot.docChanges().forEach(change => {
      if (change.type === 'added' || change.type === "modified") {
        const newUser = { [change.doc.id]: change.doc.data() };
        store.dispatch(pushUser(newUser));
      }
    })
  });

  const miscMap = {
    cropTypes: loadCropTypes,
    lowInventory: loadLowInventory,
    reasons: loadReasons,
    varietyCodes: loadVarietyCodes,
    pestList : loadPestList
  };

  const unSubMisc = db.collection(COLLECTIONS.MISC).onSnapshot(snapshot => {
    snapshot.docChanges().forEach(change => {
      // push whole list, except for lastUsedLotID
      if (change.doc.id !== 'lastUsedLotID') {
        store.dispatch(miscMap[change.doc.id](change.doc.data().list));
      }
    })
  });

  return {
    unSubCrops,
    unSubFarms,
    unSubVarieties,
    unSubGrowthCycles,
    unSubRafts,
    unSubBatches,
    unSubPerpetualBatches,
    unSubUsers,
    unSubMisc
  };
}
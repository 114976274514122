import { ADD_VARIETY_LEGEND } from '../actionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    case ADD_VARIETY_LEGEND:
      return action.legend;
  
    default:
      return state;
  }
};
import { ADD_PLOTS } from '../actionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    case ADD_PLOTS:
      return action.plots;
  
    default:
      return state;
  }
};
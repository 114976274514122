import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import { DatePicker } from "material-ui-pickers";
import MomentUtils from "@date-io/moment";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import {
  Button,
  Fab,
  Icon,
  Modal,
  Tooltip,
  withStyles,
} from "@material-ui/core";
import classNames from "classnames";
import blue from "@material-ui/core/colors/blue";
import moment from "moment";
import APP_SETTINGS from "../utils/appSettings";

const { STAGES, HARVEST_TYPES } = APP_SETTINGS;

const styles = (theme) => ({
  fullContainer: {
    display: "flex",
  },
  checkboxContainer: {
    width: "35px",
    display: "flex",
    alignItems: "center",
    "& span": {
      padding: 0,
    },
  },
  card: {
    maxWidth: "600px",
    width: "calc(100% - 43px)",
    marginLeft: "0.5rem",
  },
  cardContent: {
    paddingBottom: "1rem !important",
  },
  avatar: {
    textAlign: "center",
    width: 60,
    height: 60,
  },
  iconContainer: {
    textAlign: "center",
    width: "100%",
    color: theme.palette.grey[700],
    "& i": {
      color: theme.palette.secondary.main,
      lineHeight: "45px",
    },
  },
  lotNumber: {
    marginTop: "-0.3rem",
  },
  title: {
    display: "inline-block",
    height: "2rem",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    marginBottom: "0.1rem",
    fontSize: "1.2rem",
  },
  row: {
    marginBottom: "0.3rem",
    "&:last-child": {
      marginBottom: 0,
    },
  },
  dateRow: {
    marginTop: "-0.7rem",
    marginBottom: "-0.2rem",
  },
  startDate: {
    color: "black !important",
    "&:after": {
      borderBottomColor: "black !important",
    },
  },
  startDateLabel: {
    color: "rgba(0, 0, 0, 0.54) !important",
  },
  datePickerIcon: {
    "& button": {
      paddingLeft: 0,
    },
  },
  info: {
    marginLeft: "0.4rem",
    color: theme.palette.grey[700],
  },
  blue: {
    color: blue[500],
  },
  fab: {
    maxWidth: 36,
    maxHeight: 36,
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
    marginTop: "13%",
    marginLeft: "40%",
  },
  button: {
    marginRight: "0.5rem",
  },
});

const stagesMap = {
  [STAGES.PLANNED]: "Planned",
  [STAGES.SEED]: "Seed",
  [STAGES.GERM]: "Germ",
  [STAGES.PROP]: "Prop",
  [STAGES.HD]: "HD",
  [STAGES.FS]: "FS",
  [STAGES.HARVEST]: "Harvest",
  [STAGES.COMPLETED]: "Complete",
};
class BatchCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openNoteModal: false,
      notes: false,
    };
  }

  handleClose = () => {
    this.setState({ openNoteModal: false });
  };

  render() {
    const {
      classes,
      batch,
      growthCycleName,
      varietyName,
      avatarURL,
      handleClick,
      handleDateChange,
      isSelected,
      isCheckDisabled,
      handleCheckBox,
      className,
      farm,
      batchID,
    } = this.props;
    const { growthCycle } = batch;
    const nextHarvestDate =
      growthCycle.harvestType === HARVEST_TYPES.ONCE
        ? growthCycle.harvest.date
        : growthCycle.harvest.date[0];
    const lastHarvestDate =
      batch.current.stage !== STAGES.COMPLETED
        ? null
        : growthCycle.harvestType === HARVEST_TYPES.ONCE
        ? batch.harvestDate
        : batch.harvestDate[batch.harvestDate.length - 1];

    function stopPropagation(e) {
      if (!isCheckDisabled) {
        e.stopPropagation();
      }
    }
    const finishingLocationId = batch.QtyToSeed
      ? batch.propLocation1.farm
      : batch.finishingLocation.farm;
    return (
      <div className={classNames(classes.fullContainer, className)}>
        <div className={classes.checkboxContainer}>
          <Checkbox
            checked={isSelected}
            disabled={isCheckDisabled}
            onChange={handleCheckBox}
          />
        </div>
        <Card className={classNames(classes.card)}>
          <CardActionArea component="div">
            <CardContent className={classes.cardContent}>
              <Grid container alignItems="center" spacing={16}>
                <Grid container xs={10} onClick={handleClick}>
                  <Grid item xs={4}>
                    <Grid
                      container
                      justify="center"
                      alignItems="center"
                      direction="column"
                      style={{ height: "100%" }}
                    >
                      {avatarURL ? (
                        <Avatar
                          alt={varietyName}
                          src={avatarURL}
                          className={classes.avatar}
                        />
                      ) : (
                        <div className={classes.iconContainer}>
                          <i className={`fas fa-leaf fa-3x`} />
                        </div>
                      )}
                      <Typography
                        variant="h6"
                        className={classNames(
                          classes.iconContainer,
                          classes.title
                        )}
                      >
                        {varietyName}
                      </Typography>
                      <Typography
                        variant="body1"
                        className={classNames(
                          classes.iconContainer,
                          classes.lotNumber
                        )}
                      >
                        Lot: {batch.GRFlot}
                      </Typography>
                      {batch.isPerpetual && (
                        <Typography
                          variant="body2"
                          className={classNames(
                            classes.iconContainer,
                            classes.blue
                          )}
                        >
                          Perpetual
                        </Typography>
                      )}
                    </Grid>
                  </Grid>

                  <Grid item xs={8}>
                    <Grid
                      container
                      justify="space-between"
                      className={classes.dateRow}
                      spacing={16}
                    >
                      <Grid item xs={6} onClick={stopPropagation}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <DatePicker
                            label="Seed Date"
                            variant="standard"
                            disabled={isCheckDisabled}
                            value={batch.growthCycle.seed.date}
                            format="MM/DD/YYYY"
                            mask={[
                              /\d/,
                              /\d/,
                              "/",
                              /\d/,
                              /\d/,
                              "/",
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                            ]}
                            onChange={(date) => handleDateChange(date)}
                            animateYearScrolling
                            autoOk
                            margin="dense"
                            fullWidth
                            keyboard
                            InputLabelProps={{
                              classes: {
                                disabled: classes.startDateLabel,
                              },
                            }}
                            InputProps={{
                              classes: {
                                disabled: classes.startDate,
                              },
                            }}
                            InputAdornmentProps={{
                              classes: {
                                root: classes.datePickerIcon,
                              },
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          disabled
                          label="Start Date"
                          variant="standard"
                          value={moment(batch.growthCycle.germ.date).format(
                            "MM/DD/YYYY"
                          )}
                          margin="dense"
                          fullWidth
                          InputLabelProps={{
                            classes: {
                              disabled: classes.startDateLabel,
                            },
                          }}
                          InputProps={{
                            classes: {
                              disabled: classes.startDate,
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid container className={classes.row}>
                      <Typography variant="body1">
                        Current:
                        <span className={classes.info}>
                          {stagesMap[batch.current.stage]}
                          &nbsp;
                          {batch.current.stage !== STAGES.COMPLETED && (
                            <React.Fragment>
                              <span>(</span>
                              {batch.current.rafts
                                ? `${batch.current.rafts} rafts`
                                : batch.current.cells
                                ? `${batch.current.cells} cells`
                                : ""}
                              <span>)</span>
                            </React.Fragment>
                          )}
                        </span>
                      </Typography>
                    </Grid>
                    <Grid container className={classes.row}>
                      <Typography variant="body1">
                        Finishing Location:
                        <span className={classes.info}>
                          {farm[finishingLocationId]
                            ? farm[finishingLocationId].name
                            : "" || ""}
                        </span>
                      </Typography>
                    </Grid>
                    <Grid container className={classes.row}>
                      {/* {console.log("currentBatch>>", batch)} */}
                      <Typography variant="body1">
                        Harvest:
                        <span className={classes.info}>
                          {batch.current.stage === STAGES.COMPLETED
                            ? moment(lastHarvestDate).format("MM/DD/YYYY")
                            : moment(nextHarvestDate).format("MM/DD/YYYY")}
                          &nbsp; (
                          {batch.current.stage === STAGES.COMPLETED
                            ? batch.totalRaftsHarvested
                            : batch.QtyToSeed || batch.growthCycle.fs.endRafts}
                          &nbsp;{batch.QtyToSeed ? "trays" : "rafts"})
                        </span>
                      </Typography>
                    </Grid>
                    <Grid container className={classes.row}>
                      <Typography variant="body1">
                        Growth Cycle:
                        <span className={classes.info}>{growthCycleName}</span>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                {/* <Grid
                  xs={2}
                  onClick={() =>
                    this.setState({
                      openNoteModal: true,
                      notes: batch.notes,
                    })
                  }
                >
                  {batch.notes ? (
                    <Typography
                      variant={"subtitle2"}
                      style={{ marginLeft: "15px", marginRight: "10px" }}
                    >
                      {batch.notes}
                    </Typography>
                  ) : (
                    <Tooltip
                      title="Add Notes"
                      aria-label="Add Notes"
                      style={{ marginLeft: "15px" }}
                    >
                      <Fab
                        color="secondary"
                        aria-label="Edit"
                        className={classes.fab}
                      >
                        <Icon>edit_icon</Icon>
                      </Fab>
                    </Tooltip>
                  )}
                </Grid> */}
              </Grid>
            </CardContent>
          </CardActionArea>
          <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={this.state.openNoteModal}
            onClose={this.handleClose}
          >
            <div className={classes.paper}>
              <Typography variant="h6" id="modal-title">
                Notes
              </Typography>
              <Grid container style={{ marginTop: "15px" }}>
                <TextField
                  id="outlined-textarea"
                  label="Add Notes"
                  value={this.state.notes}
                  placeholder="Notes"
                  multiline
                  fullWidth
                  autoFocus
                  onChange={(e) => this.setState({ notes: e.target.value })}
                />
              </Grid>
              <Grid container direction="row" justify="space-between">
                <Grid />
                <Button
                  onClick={() => {
                    const copyBatch = {...batch};
                    copyBatch.notes = this.state.notes
                    this.props.saveBatchNotes(
                      copyBatch,
                      batchID,
                    );
                    this.setState({ openNoteModal: false });
                  }}
                  variant="contained"
                  component="span"
                  className={classes.button}
                  color="primary"
                  style={{ marginTop: "15px" }}
                >
                  Submit
                </Button>
              </Grid>
            </div>
          </Modal>
        </Card>
      </div>
    );
  }
}

BatchCard.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  batch: PropTypes.object.isRequired,
  growthCycleName: PropTypes.string.isRequired,
  varietyName: PropTypes.string.isRequired,
  avatarURL: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  isCheckDisabled: PropTypes.bool.isRequired,
  handleCheckBox: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  farm: PropTypes.object.isRequired,
};

export default withStyles(styles)(BatchCard);
